import * as Colors from "@mui/material/colors";
import { alpha } from "@mui/material/styles";
import { darken } from "@mui/material/styles";
import { spacing } from "@mui/material/styles";
import 'roboto-fontface';


let palette = {
    primary1Color: Colors.lightBlue[900], // <- p1  primary color
    primary2Color: Colors.blueGrey[700],
    primary3Color: Colors.grey[400],
    primary4Color: "#FFFFFF",
    accent1Color: Colors.blue[500], // <- p2 secondary color
    accent2Color: Colors.grey[500], // <- p1  primary color --> the toggle disable
    accent3Color: Colors.grey[500],
    textColor: "#010203", //  (darkBlack) button text default
    alternateTextColor: "#FFFFFF", //  button primary default
    canvasColor: "#FEFEFE", // <- card background
    borderColor: Colors.grey[700],
    linkColor: Colors.blue[500],
    warningColor: Colors.orange[500],
    disabledColor: alpha("#010203", 0.3),
    pickerHeaderColor: Colors.blue[500],
    clockCircleColor: alpha("#010203", 0.07),
    background: Colors.grey[200],
    primaryTextColorDark: "rgba(0, 0, 0, 0.87)", // <- Text on a light background
    secondaryTextColorDark: "rgba(0, 0, 0, 0.54 )",
    disabledTextColorDark: "rgba(0, 0, 0, 0.38)",
    dividerColorDark: "rgba(0, 0, 0, 0.12)",
    primaryTextColorLight: "rgba(255, 255, 255, 1)", // <- Text on a dark background
    secondaryTextColorLight: "rgba(255, 255, 255, 0.7)",
    disabledTextColorLight: "rgba(255, 255, 255, 0.5)",
    dividerColorLight: "rgba(255, 255, 255, 0.12)",
    // From https://mui.com/material-ui/customization/palette/
    muiDefaultPalettePrimaryLight: "#42a5f5",
    muiDefaultPalettePrimaryMain: "#1976d2",
    muiDefaultpalettePrimaryDark: "#1565c0",
    muiDefaultPaletteSecondaryLight: "#ba68c8",
    muiDefaultPaletteSecondaryMain: "#9c27b0",
    muiDefaultPaletteSecondaryDark: "#7b1fa2",
    muiDefaultpaletteErrorLight: "#ef5350",
    muiDefaultPaletteErrorMain: "#d32f2f",
    muiDefaultPaletteErrorDark: "#c62828",
    muiDefaultPaletteWarningLight: "#ff9800",
    muiDefaultPaletteWarningMain: "#ed6c02",
    muiDefaultPaletteWarningDark: "#e65100",
    muiDefaultPaletteInfoLight: "#03a9f4",
    muiDefaultPaletteInfoMain: "#0288d1",
    muiDefaultPaletteInfoDark: "#01579b",
    muiDefaultPaletteSuccessLight: "#4caf50",
    muiDefaultPaletteSuccessMain: "#2e7d32",
    muiDefaultPaletteSuccessDark: "#1b5e20",
    adminTheme: "#FFFFFF"
};

let zIndex = {
    menu: 8,
    appBar: 4,
    drawerOverlay: 16,
    drawer: 16,
    dialogOverlay: 24,
    dialog: 25,
    layer: 2000,
    popover: 2100,
    snackbar: 6,
    tooltip: 3000
};

const  blueTheme = {
    spacing: 2, //spacing,
    zIndex: zIndex,
    fontFamily: 'Roboto, sans-serif',
    palette: palette,
    paper: {
        backgroundColor: "white"
    },
    leftNav: {
        width: "280px"
    },
    button: {
        variant: "contained",
        color: palette.alternateTextColor,
        textColor: palette.textColor,
        primaryColor: palette.accent1Color,
        primaryTextColor: palette.alternateTextColor,
        secondaryColor: palette.primary1Color,
        secondaryTextColor: palette.alternateTextColor,
        disabledColor: darken(palette.alternateTextColor, 0.1),
        disabledTextColor: alpha(palette.textColor, 0.3),
    },
    toolbar: {
        color: "#FFFFFF", // fullWhite
        backgroundColor: palette.primary1Color,
        iconColor: "#FFFFFF"

    },
    tableRow: {
        hoverColor: Colors.grey[100],
        borderColor: palette.dividerColorDark,
    },
    rightNav: {
        width: 480,
        zIndex: 20,
    },
    tabs: {
        backgroundColor: palette.primary1Color,
        textColor: darken("#FFFFFF", 0.2),
        selectedTextColor: "#FFFFFF",
    },
    inkBar: {
        backgroundColor: "#FFFFFF",
    },
    datePicker: {
        textColor: "#FFFFFF",
        headerColor: palette.accent1Color,
    },
    floatingActionButton: {
        color: palette.accent1Color,
    },
    tableGrid: {
        wrapperStyle: {
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            width: "100%",
        },
        bodystyle: {
            maxWidth: "95%",
        },
        headerStyle: {
            maxWidth: "95%",
        },
        linkStyle: {
            color: palette.linkColor,
        },
        warningIcon: {
            color: palette.warningColor,
        }
    }
};

export default blueTheme;
