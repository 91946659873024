import React, {Component} from "react";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import InfoIcon from "@mui/icons-material/Info";
import {addQuestion, removeQuestion, reorderQuestion} from "../../../actions/questionnaireEditorActions";
import {getQuestionTemplate, createQuestionTemplate} from "../../../actions/questionTemplateActions";
import {getTemplateListForSelectFilter} from "../../../actions/templateListActions";
import QuestionViewerExpandable from "./QuestionViewerExpandable";
import QuestionEditor from "./QuestionEditor";
import ArgoSelectExisting from "../../common/ArgoSelectExisting";
import ArgoToolbarHook from "../../common/ArgoToolbarHook";
import ArgoRadioGroupHook from "../../common/ArgoRadioGroupHook";
import * as questionTypes from "../../../constants/questionTypeConstants";
import * as TEMPLATE from "../../../constants/templateConstants";
import style from "../../../blueTheme";


const QUESTION_DEPTH = 0;

class QuestionSearchAdd extends Component {
    constructor(props) {
        super(props);
        this.state = {
            createMode: false,
            questionTemplateSearch: "",
            questionDetail: null,
            questionList: [],
            questionsLoaded: true
        };
    }

    componentDidMount() {
        this.props.getTemplateListForSelectFilter(TEMPLATE.TYPE.QUESTION, TEMPLATE.MODE.CURRENT);
        this.props.questionKeys.forEach(key => {
            if (!this.props.questionTemplate[key]){
                this.props.getQuestionTemplate(key);
            }
        });
    }

    componentWillReceiveProps(nextProps) {
        // Do not allow the user to add individual Likert questions to a questionnaire
        let questionList = nextProps.questionList.filter((question) => {
            return question.questionType !== questionTypes.LIKERT_RADIO;
        });
        this.setState({questionList: questionList});
    }

    handleToolbarButtonClick = (event, fieldName, buttonTitle) => {
        switch (buttonTitle) {
            case "REVIEW":
                this.setState({review: true});
                event.preventDefault(); // Fix for the enter key propagating to the next focusable element
                break;
            case "CANCEL":
                this.props.cancel();
                break;
            case "DONE":
                this.props.cancel();
                break;
            case "SAVE":
                this.save();
                break;
            case "BACK":
                if (fieldName === "question-info") {
                    this.closeQuestionDetail();
                }
                else {
                    this.exitReview();
                }
                break;
            // no default
        }
    }

    onUpdateQuestionInput = (searchText) => {
        this.setState({questionTemplateSearch: searchText});
    };

    openQuestionDetail = (templateKey) => {
        this.setState({questionDetail: templateKey});
    };

    closeQuestionDetail = () => {
        this.setState({questionDetail: null});
    };

    openQuestionViewer = () => {

        return (
            <div style={{height: "100%"}}>

                <ArgoToolbarHook
                    fileName="question-search-add"
                    fieldName="back"
                    buttonTitles={["BACK"]}
                    onClick={this.handleToolbarButtonClick}
                />

                <div style={{height: "100%", width: "100%", overflowY: "scroll"}}>
                    <QuestionViewerExpandable
                        templateKey={this.state.questionDetail}
                    />;
                </div>
            </div>
        );
    };

    pickQuestion = (questionTemplate) => {
        if (!this.props.questionKeys.includes(questionTemplate.templateKey)) {
            this.setState({questionTemplateSearch: ""});
            this.props.addQuestion(questionTemplate.templateKey);
            this.props.getQuestionTemplate(questionTemplate.templateKey);
        }
    };

    removeQuestion = (index) => {
        this.props.removeQuestion(index);
    };

    saveNewQuestion = (template) => {
        this.props.createQuestionTemplate(template, QUESTION_DEPTH);
        this.setState({createMode: false, questionTemplateSearch: ""});
    };

    closeNewQuestion = () => {
        this.setState({createMode: false, questionTemplateSearch: ""});
    };

    toggleCreate = (event, value) => {
        let newState = {
            createMode: value,
            questionTemplateSearch: (value)? this.state.questionTemplateSearch : ""
        };
        this.setState(newState);
    };

    createModeSelector = () => {
        return (<ArgoRadioGroupHook
                    fileName="question-search-add"
                    fieldName="createMode"
                    label="Is this an existing question?"
                    value={this.state.createMode}
                    onChange={this.toggleCreate}
                    fontSize="18px"
                />
            // <div className="layout horizontal center" style={{width: "100%", height: "64px"}}>
            //     <div style={{fontSize: "18px"}}>Is this an existing question?</div>
            //     <RadioGroup defaultSelected={this.state.createMode} className="layout horizontal center" name="isQuestionCreate" onChange={this.toggleCreate}>
            //         <Radio id="question-search-add-existing-question-yes" name="question-search-add-existing-question-yes" style={{paddingLeft: "32px"}} label="Yes" value={false} tabIndex={1} autoFocus/>
            //         <Radio id="question-search-add-existing-question-no" name="question-search-add-existing-question-no" style={{paddingLeft: "32px"}} label="No" value tabIndex={1}/>
            //     </RadioGroup>
            // </div>
        );
    };

    questionDisplayRow = (templateKey) => {
        let name = this.props.questionTemplate[templateKey] ? this.props.questionTemplate[templateKey].name : "";

        let questionList = [];
        let rowRemoveFlag = false;
        for (let i = 0; i < this.state.questionList.length; i++) {
            if (this.state.questionList[i].templateKey !== templateKey) {
                questionList.push(this.state.questionList[i]);  // CLONE this.state.questionList minus selected question
            }
            else
            {
                rowRemoveFlag = true;
            }
        }

        // Only set if there was a change to keep from getting react errors
        if (rowRemoveFlag) {
            this.setState({questionList: questionList});  // If there was a row removed then setState with new array of questions
        }

        return (
            <div>{name}</div>
        );
    };

    render() {
        if (this.state.createMode) {
            let template = {
                name: this.state.questionTemplateSearch
            };

            return <QuestionEditor save={this.saveNewQuestion}
                                   cancel={this.closeNewQuestion}
                                   showCreateToggle={this.createModeSelector}
                                   createInitialTemplate={template}
                                   questionDepth={QUESTION_DEPTH}
            />;
        }

        if (this.state.questionDetail) {
            return this.openQuestionViewer();
        }

        let self = this;

        return (<div style={{height: "100%"}}>

            <ArgoToolbarHook
                fileName="question-search-add"
                fieldName="done"
                buttonTitles={["DONE"]}
                onClick={this.handleToolbarButtonClick}
            />

            <div style={{height: "100%", overflowY: "scroll"}}>
                <div style={{justifyContent: "space-between"}}>
                    <div style={{flexGrow: "2", paddingLeft: "32px", paddingRight: "32px"}}>

                        {this.createModeSelector()}

                        <ArgoSelectExisting
                            mode={TEMPLATE.TYPE.QUESTION}
                            useTypeFilter={true}
                            onAddTemplate={this.pickQuestion.bind(this)
                            }/>

                        <div style={{
                            overflow: "hidden",
                            display: "flex",
                            alignSelf: "flex-start",
                            flexDirection: "column",
                            width: "100%",
                            minHeight: "min-content"
                        }}>

                            <div>
                                {this.props.questionKeys.map(function (questionKey, index) {
                                    return (
                                        <div key={questionKey} style={{paddingTop: "16px", paddingLeft: "16px"}}>
                                            <div>
                                                <div>

                                                    {(index !== 0) &&
                                                    <IconButton id={"question-search-add-" + index + "-arrow-up"} name={"question-search-add-" + index + "-arrow-up"}
                                                        onClick={self.props.reorderQuestion.bind(self, true, index)}
                                                        iconStyle={{
                                                            backgroundColor: style.palette.primary2Color,
                                                            borderRadius: "50%"
                                                        }}
                                                        tabIndex={1}
                                                    >
                                                        <ArrowUpwardIcon color={style.palette.alternateTextColor}/>
                                                    </IconButton>}
                                                </div>
                                                <div>
                                                    {!(self.props.questionKeys.length - 1 === index) &&
                                                    <IconButton id={"question-search-add-" + index + "-arrow-down"} name={"question-search-add-" + index + "-arrow-down"}
                                                        onClick={self.props.reorderQuestion.bind(self, false, index)}
                                                        iconStyle={{
                                                            backgroundColor: style.palette.primary2Color,
                                                            borderRadius: "50%"
                                                        }}>
                                                        <ArrowDownwardIcon color={style.palette.alternateTextColor}
                                                                   tabIndex={1}/>
                                                    </IconButton>}
                                                </div>
                                            </div>
                                            <div style={{
                                                display: "flex",
                                                alignSelf: "center",
                                                maxWidth: "70%",
                                                flexGrow: "2"
                                            }}>
                                                {self.questionDisplayRow(questionKey)}
                                            </div>
                                            <div style={{justifySelf: "flex-end", alignSelf: "center"}}>
                                                <IconButton id={"question-search-add-" + index + "-close"} name={"question-search-add-" + index + "-close"} onClick={self.removeQuestion.bind(self, index)} tabIndex={1}>
                                                    <CloseIcon/>
                                                </IconButton>
                                                <IconButton id={"question-search-add-" + index + "-info"} name={"question-search-add-" + index + "-info"} onClick={self.openQuestionDetail.bind(self, questionKey)} tabIndex={1}>
                                                    <InfoIcon/>
                                                </IconButton>
                                            </div>
                                        </div>);
                                })}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>);
    }
}

QuestionEditor.defaultProps = {

};

QuestionSearchAdd.propTypes = {
    questionKeys: PropTypes.array,
    addQuestion: PropTypes.func,
    removeQuestion: PropTypes.func,
    reorderQuestion: PropTypes.func,
    getQuestionTemplate: PropTypes.func,
    createQuestionTemplate: PropTypes.func,
    cancel: PropTypes.func,
    questionList: PropTypes.array,
    questionTemplate: PropTypes.object,
    selectFilter: PropTypes.object
};

function mapStateToProps(state) {
    return {
        questionKeys: state.questionnaireEditor.questionKeys,
        questionList: state.questionTemplateList,
        questionTemplate: state.questionTemplate,
        selectFilter: state.filter["selectFilter"]
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        addQuestion,
        removeQuestion,
        reorderQuestion,
        getQuestionTemplate,
        createQuestionTemplate,
        getTemplateListForSelectFilter
    }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(QuestionSearchAdd);
