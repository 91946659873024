// ADMIN Override reference Key ----------------------------------------------------------------------------------------

//import {styled} from "@mui/material/styles";
//import OutlinedInput from '@mui/material/OutlinedInput';

export const NON_BREAKING_SPACE = "\u00A0";
export const NON_BREAKING_SPACE2 = "\u00A0\u00A0";

// Survey Default Colors -------=---------------------------------------------------------------------------------------
export const DEFAULT_WHITE = "white";
export const DEFAULT_BLACK = "black";
export const DEFAULT_GRAY = "gray";
export const DEFAULT_UNSELECTED_COLOR = DEFAULT_GRAY;
export const DEFAULT_SELECTED_COLOR = "#2497F3"; // dodger blue


export const DEFAULT_TEXT_FIELD_LABEL_COLOR = DEFAULT_SELECTED_COLOR;
export const DEFAULT_TEXT_FIELD_PLACEHOLDER_COLOR = DEFAULT_GRAY;
export const DEFAULT_TEXT_FIELD_TEXT_COLOR = DEFAULT_SELECTED_COLOR;
export const DEFAULT_TEXT_FIELD_TEXT_COLOR_UNFOCUSED = DEFAULT_GRAY;
export const DEFAULT_TEXT_FIELD_TEXT_COLOR_FOCUSED = DEFAULT_SELECTED_COLOR;
export const DEFAULT_TEXT_FIELD_TEXT_COLOR_HOVERED = DEFAULT_BLACK;

export const DEFAULT_TEXT_UNSELECTED_COLOR = "lightgray";
export const DEFAULT_TEXT_HOVER_COLOR = "lightgray";
export const DEFAULT_TEXT_FOCUSED_COLOR = "gray";
export const DEFAULT_TEXT_SELECTED_COLOR = "black";

export const DEFAULT_TEXT_BORDER_UNSELECTED_COLOR = "lightgray";
export const DEFAULT_TEXT_BORDER_HOVER_COLOR = "black";
export const DEFAULT_TEXT_BORDER_FOCUSED_COLOR = DEFAULT_SELECTED_COLOR;
export const DEFAULT_TEXT_BORDER_SELECTED_COLOR = "lightgray";

// Single Select Radios --------=---------------------------------------------------------------------------------------

export const DEFAULT_UNSELECTED_RADIO_CONTROL_COLOR = DEFAULT_UNSELECTED_COLOR;
export const DEFAULT_UNSELECTED_RADIO_TEXT_COLOR = DEFAULT_UNSELECTED_COLOR;
export const DEFAULT_SELECTED_RADIO_CONTROL_COLOR = DEFAULT_SELECTED_COLOR;
export const DEFAULT_SELECTED_RADIO_TEXT_COLOR = DEFAULT_SELECTED_COLOR;
export const DEFAULT_HOVER_RADIO_CONTROL_COLOR = DEFAULT_SELECTED_COLOR;


export const DEFAULT_BUTTON_HEIGHT = 38;

// Single Select Outlined Radios --------=------------------------------------------------------------------------------

export const DEFAULT_UNSELECTED_OUTLINED_RADIO_CONTROL_COLOR = DEFAULT_UNSELECTED_COLOR;
export const DEFAULT_UNSELECTED_OUTLINED_RADIO_TEXT_COLOR = DEFAULT_UNSELECTED_COLOR;
export const DEFAULT_UNSELECTED_OUTLINED_RADIO_BORDER_COLOR = DEFAULT_UNSELECTED_COLOR;
export const DEFAULT_UNSELECTED_OUTLINED_RADIO_BACKGROUND_COLOR = DEFAULT_WHITE;

export const DEFAULT_SELECTED_OUTLINED_RADIO_CONTROL_COLOR = DEFAULT_WHITE;
export const DEFAULT_SELECTED_OUTLINED_RADIO_TEXT_COLOR = DEFAULT_WHITE;
export const DEFAULT_SELECTED_OUTLINED_RADIO_BORDER_COLOR = DEFAULT_SELECTED_COLOR;
export const DEFAULT_SELECTED_OUTLINED_RADIO_BACKGROUND_COLOR = DEFAULT_SELECTED_COLOR;

// Single Select Buttons --------=--------------------------------------------------------------------------------------

export const DEFAULT_UNSELECTED_BUTTON_TEXT_COLOR = DEFAULT_UNSELECTED_COLOR;
export const DEFAULT_UNSELECTED_BUTTON_BORDER_COLOR = DEFAULT_UNSELECTED_COLOR;
export const DEFAULT_UNSELECTED_BUTTON_BACKGROUND_COLOR = DEFAULT_UNSELECTED_COLOR;
export const DEFAULT_SELECTED_BUTTON_TEXT_COLOR = DEFAULT_WHITE;
export const DEFAULT_SELECTED_BUTTON_BORDER_COLOR = DEFAULT_SELECTED_COLOR;
export const DEFAULT_SELECTED_BUTTON_BACKGROUND_COLOR = DEFAULT_SELECTED_COLOR;
export const DEFAULT_HOVER_BUTTON_TEXT_COLOR = DEFAULT_SELECTED_COLOR;
export const DEFAULT_HOVER_BUTTON_BORDER_COLOR = DEFAULT_SELECTED_COLOR;
export const DEFAULT_HOVER_BUTTON_BACKGROUND_COLOR = DEFAULT_WHITE;
export const DEFAULT_HOVER_SELECTED_BUTTON_TEXT_COLOR = DEFAULT_WHITE;
export const DEFAULT_HOVER_SELECTED_BUTTON_BORDER_COLOR = DEFAULT_SELECTED_COLOR;
export const DEFAULT_HOVER_SELECTED_BUTTON_BACKGROUND_COLOR = DEFAULT_SELECTED_COLOR;

export const DEFAULT_BUTTON_BORDER_WIDTH = "2";
export const DEFAULT_BUTTON_BORDER_RADIUS = "0";
export const DEFAULT_BUTTON_BOX_SHADOW = "none";

// ---------------------------------------------------------------------------------------------------------------------
// SYSTEM BUTTONS
// ---------------------------------------------------------------------------------------------------------------------

export const SYSTEM_BUTTON_VARIANT = "display-def-system-buttons-variant"     // outlined or contained, default variant is text
export const SYSTEM_BUTTON_SIZE = "display-def-system-buttons-size"  // small, medium, large DEFAULT size is large
export const SYSTEM_BUTTON_CUSTOM = "display-def-system-buttons-custom"
export const SYSTEM_BUTTON_CUSTOM_TEXT_COLOR = "display-def-system-buttons-custom-text-color"
export const SYSTEM_BUTTON_CUSTOM_TEXT_FONT = "display-def-system-buttons-custom-text-font"
export const SYSTEM_BUTTON_CUSTOM_WIDTH = "display-def-system-buttons-custom-width"
export const SYSTEM_BUTTON_CUSTOM_HEIGHT = "display-def-system-buttons-custom-height"
export const SYSTEM_BUTTON_CUSTOM_BORDER = "display-def-system-buttons-custom-border"   // Makes button have a border ie.. solid, DEFAULT none
export const SYSTEM_BUTTON_CUSTOM_BORDER_RADIUS = "display-def-system-buttons-custom-border-radius"
export const SYSTEM_BUTTON_CUSTOM_BORDER_WIDTH = "display-def-system-buttons-custom-border-width"
export const SYSTEM_BUTTON_CUSTOM_BORDER_COLOR = "display-def-system-buttons-custom-border-color"
export const SYSTEM_BUTTON_CUSTOM_BACKGROUND_COLOR = "display-def-system-buttons-custom-background-color"
export const SYSTEM_BUTTON_CUSTOM_HOVER_TEXT_COLOR = "display-def-system-buttons-custom-hover-text-color"
export const SYSTEM_BUTTON_CUSTOM_HOVER_BORDER_WIDTH = "display-def-system-buttons-custom-hover-border-width"
export const SYSTEM_BUTTON_CUSTOM_HOVER_BORDER_COLOR = "display-def-system-buttons-custom-hover-border-color"
export const SYSTEM_BUTTON_CUSTOM_HOVER_BACKGROUND_COLOR = "display-def-system-buttons-custom-hover-background-color"
export const SYSTEM_BUTTON_CUSTOM_HOVER_FONT_WEIGHT = "display-def-system-buttons-custom-hover-font-weight"  //ie.. bold

// -----------------------------=---------------------------------------------------------------------------------------

export const PORTAL_PREVIEW_QUESTIONNAIRE_GRAPH  = "questionnaire_graph";
export const PORTAL_PREVIEW_QUESTIONNAIRE  = "questionnaire";
export const PORTAL_PREVIEW_CATEGORY  = "category";
export const PORTAL_PREVIEW_GROUP  = "group";
export const PORTAL_PREVIEW_QUESTION  = "question";

export const SHOW_ANSWERED_FIRST_QUESTION  = "display-def-show-answered-first-question";  // (Questionnaire) Used to show the first question if being answered from an email and passed in

export const NO_SUBMIT  = "display-def-no-submit";  // (Questionnaire) Used to suppress submit button on real-time template mode (NOT FOR PUBLIC USE)

// Survey Presentation Overrides ---------------------------------------------------------------------------------------

export const FORMAT_IS_METADATA  = "display-def-value-is-format";  // (Questionnaire) Actual metadata value is the ddo (TBD)
export const FORMAT_ONE_QUESTION_PER_PAGE  = "display-def-one-question-per-page"; // (Questionnaire) Force question by question
export const FORMAT_FULL_PAGE  = "display-def-full-page";  // (Questionnaire) Force full page

export const HIDE_READONLY_HEADER  = "display-def-hide-readonly-header";
export const SHOW_READONLY_HEADER  = "display-def-show-readonly-header";
export const FILTER_READONLY_HEADER  = "display-def-filter-readonly-header";  // This is the default left it here in case they change their minds

export const ALIGN_ALL_ANSWERS_RIGHT  = "align-all-answers-right";  // (Questionnaire) Align all answers right

export const ALERT_TEXT_TYPE  = "alert-text-type";  // (Questionnaire) Alerts
export const ALERT_TEXT_INVALID_KEY  = "alert-text-invalid-key";  // (Questionnaire) Alerts
export const ALERT_TEXT_COMPLEATED  = "alert-text-compleated";  // (Questionnaire) Alerts
export const ALERT_TEXT_DEACTIVATED  = "alert-text-deactivated";  // (Questionnaire) Alerts
export const ALERT_TEXT_EXPIRED  = "alert-text-expired";  // (Questionnaire) Alerts
export const ALERT_TEXT_CANCELED  = "alert-text-canceled";  // (Questionnaire) Alerts

// Good article on question numbers: https://help.alchemer.com/help/question-numbering
export const SHOW_QUESTION_NUMBERS  = "display-def-show-question-numbers";  // (Questionnaire) . or ) default just the number
export const SUBNUMBER_LIKERT_QUESTIONS  = "display-def-subnumber-likert-questions";  // (Questionnaire) a or a) or (a) or Xa or X.#   ...by default none
export const SUBNUMBER_SHOWGROUP_QUESTIONS  = "display-def-subnumber-showgroup-questions";  // (Questionnaire) a or a) or (a) or Xa or X.#  ...by default none
export const RESTART_QUESTION_NUMBERS_EACH_PAGE  = "display-def-restart-numbers-each-page";  // (Questionnaire)

export const USE_SECONDARY_THEME = "use-secondary-theme"; // (Questionnaire)

// Start/Close Page Required -------------------------------------------------------------------------------------------

export const HAS_START_PAGE  = "display-def-has-start-page"; // (Questioniare) text or default ddo.TITLE.NAME.DEFAULT1 in body1
export const CANCEL_TEXT_OVERRIDE  = "cancel-text-override"; // (Questioniare) ovverride cancel text

export const NO_CLOSE_PAGE  = "display-def-no-close-page";

export const CLOSE_PAGE_TEXT1  = "display-def-close-page-text1"; // (Questionnaire)
export const CLOSE_PAGE_TEXT2  = "display-def-close-page-text2"; // (Questionnaire)
export const CLOSE_PAGE_TEXT3  = "display-def-close-page-text3"; // (Questionnaire)

export const CLOSE_PAGE_NO_GREEN_CHECK_ICON  = "display-def-close-page-no-green-check-icon";  // (Questionnaire) no green check icon on close
export const CLOSE_PAGE_NO_DEFAULT_TEXT  = "display-def-close-page-hide-default-text";  // (Questionnaire) hide default close page text
export const CLOSE_PAGE_REPLACE_DEFAULT_TEXT  = "display-def-close-page-replace-default-text";  // (Questionnaire) hide default close page text

// =====================================================================================================================
// ---------------------------------------------------------------------------------------------------------------------
// metadata for TITLE_BAR headers and footers, Questionnaire, Categor, Group and Question

// Application Title Bar -----------------------------------------------------------------------------------------------
export const APPBAR  = "display-def-appbar";
export const APPBAR_TITLE_LEFT  = "display-def-appbar-title-left";  //(Questionnaire) scr= ot Text or #pageNum
export const APPBAR_TITLE_CENTER  = "display-def-appbar-title-center"; //(Questionnaire) scr= ot Text or #pageNum
export const APPBAR_TITLE_RIGHT  = "display-def-appbar-title-right";  //(Questionnaire) scr= ot Text or #pageNum

export const APPBAR_IMAGE_LEFT = "image-app-title-bar-left"; // (Questionnaire)
export const APPBAR_IMAGE_CENTER = "image-app-title-bar-center"; // (Questionnaire)
export const APPBAR_IMAGE_RIGHT = "image-app-title-bar-right"; // (Questionnaire)

// Page Header or Footer -----------------------------------------------------------------------------------------------

export const PAGE_HEADER         = "display-def-page-header"; // (Questioniare) Object will use metadata for text **REQUIRED to use header text
export const PAGE_HEADER_LEFT    = "display-def-page-header-left";  // (Questioniare) scr= or Text or #pageNum
export const PAGE_HEADER_CENTER  = "display-def-page-header-center"; // (Questioniare) scr= or Text or #pageNum
export const PAGE_HEADER_RIGHT   = "display-def-page-header-right";  // (Questioniare) scr= or Text or #pageNum

export const HEADER_IMAGE_LEFT   = "image-page-header-left"; // (Questioniare)  NOTE: Not currently implemented
export const HEADER_IMAGE_CENTER = "image-page-header-center"; // (Questioniare) NOTE: Not currently implemented
export const HEADER_IMAGE_RIGHT  = "image-page-header-right"; // (Questioniare) NOTE: Not currently implemented

export const PAGE_FOOTER         = "display-def-page-footer"; // (Questioniare) Object will use metadata for text **REQUIRED to use footer text
export const PAGE_FOOTER_LEFT    = "display-def-page-footer-left";  // (Questioniare) scr= or Text or #pageNum
export const PAGE_FOOTER_CENTER  = "display-def-page-footer-center"; // (Questioniare) scr= or Text or #pageNum
export const PAGE_FOOTER_RIGHT   = "display-def-page-footer-right";  // (Questioniare) scr= or Text or #pageNum

export const FOOTER_IMAGE_LEFT   = "image-page-footer-left"; // (Questioniare) NOTE: Not currently implemented
export const FOOTER_IMAGE_CENTER = "image-page-footer-center"; // (Questioniare) NOTE: Not currently implemented
export const FOOTER_IMAGE_RIGHT  = "image-page-footer-right"; // (Questioniare) NOTE: Not currently implemented

export const PAGE_FOOTER2         = "display-def-page-footer2"; // (Questioniare) Object will use metadata for text **REQUIRED to use footer text
export const PAGE_FOOTER_LEFT2    = "display-def-page-footer-left2";  // (Questioniare) scr= or Text or #pageNum
export const PAGE_FOOTER_CENTER2  = "display-def-page-footer-center2"; // (Questioniare) scr= or Text or #pageNum
export const PAGE_FOOTER_RIGHT2   = "display-def-page-footer-right2";  // (Questioniare) scr= or Text or #pageNum

export const FOOTER_IMAGE_LEFT2   = "image-page-footer-left2"; // (Questioniare) NOTE: Not currently implemented
export const FOOTER_IMAGE_CENTER2 = "image-page-footer-center2"; // (Questioniare) NOTE: Not currently implemented
export const FOOTER_IMAGE_RIGHT2  = "image-page-footer-right2"; // (Questioniare) NOTE: Not currently implemented


// Category title bar Left/Center/Right Text
export const CATEGORY_TITLE_BAR  = "display-def-category-title-bar"; // (Category)
export const CATEGORY_TITLE_BAR_LEFT  = "display-def-category-title-bar-left";  // (Category)
export const CATEGORY_TITLE_BAR_CENTER  = "display-def-category-title-bar-center"; // (Category)
export const CATEGORY_TITLE_BAR_RIGHT  = "display-def-category-title-bar-right";  // (Category)

// ---------------------------------------------------------------------------------------------------------------------
// =====================================================================================================================

// Text overrides ------------------------------------------------------------------------------------------------------
// Array[0] = text: hide or show | left | center | right
// Array[1] = icon: none | info | comment | default  --- comment and icon can only be used with text show or left
// Array[2] = variant: default | 'h1' | 'h2' | 'h3'  | 'h4'  | 'h5'  | 'h6'  | 'subtitle1'  | 'subtitle2'  | 'body1'  | 'body2'  | 'caption'  | 'button'  | 'overline'

export const BLANK_NAME  = "display-def-blank-name";  // still used to blank name may need to keep

export const DISPLAY_DEF_NAME  = "display-def-name";                    // (All Templates)
export const DISPLAY_DEF_DESCRIPTION  = "display-def-description";      // (All Templates)
export const DISPLAY_DEF_SCRIPT_BEFORE  = "display-def-script-before";  // (Questionnaire) Will only be displayed on a START page
export const DISPLAY_DEF_SCRIPT_AFTER  = "display-def-script-after";    // (Questionnaire) Will only be displayed on a CLOSE page
export const DISPLAY_DEF_SCRIPT  = "display-def-script";                // (Category) only

export const SCRIPT_BEFORE  = "user-script";  // (Questionnaire) Will only be displayed on a START page
export const SCRIPT_AFTER  = "user-script-after";    // (Questionnaire) Will only be displayed on a CLOSE page
export const CATEGORY_SCRIPT  = "category-script";                // (Category) only


export const DISPLAY_DEF_DESCRIPTION_ICON_NONE  = "display-def-description-icon-none";      // (All Templates)
export const DISPLAY_DEF_DESCRIPTION_ICON_SIZE  = "display-def-description-icon-size";      // (All Templates)
export const DISPLAY_DEF_DESCRIPTION_ICON_COLOR  = "display-def-description-icon-color";    // (All Templates)
export const DISPLAY_DEF_DESCRIPTION_ICON_PADDING  = "display-def-description-icon-padding";    // (All Templates)


export const DISPLAY_DEF_SCRIPT_ICON_NONE  = "display-def-script-icon-none";      // (All Templates)
export const DISPLAY_DEF_SCRIPT_ICON_SIZE  = "display-def-script-icon-size";      // (All Templates)
export const DISPLAY_DEF_SCRIPT_ICON_COLOR  = "display-def-script-icon-color";    // (All Templates)
export const DISPLAY_DEF_SCRIPT_ICON_PADDING  = "display-def-script-icon-padding";    // (All Templates)


// ---------------------------------------------------------------------------------------------------------------------

export const NEW_PAGE  = "display-def-new-page";   // (Category) Page Number 1..x || close  (Quesion on SIMPLE questionnaires)
export const PAGE_OF  = "display-def-page-of";   // (Category) Page Number 1...

// ---------------------------------------------------------------------------------------------------------------------

export const DO_NOT_USE_SHOW_GROUP  = "display-def-do-not-use-show-group"; //(Question), Likert groups share answers so questions with this tag will suppress show groups (NO UI IMPLEMENTATION)
export const DO_NOT_USE_ACTIONS  = "display-def-do-not-use-actions"; // (Question), Likert groups share answers so questions with this tag will suppress show groups (NO UI IMPLEMENTATION)

export const ALLOW_NEGATIVES  = "display-def-allow-negatives"; // (Question) - Text Entry (Numberic/Currency)
export const NO_DECIMAL  = "display-def-no-decimal"; // (Question) - Text Entry (Numberic/Currency) Currency defaults to 2,
export const ALLOW_DECIMAL  = "display-def-allow-decimal"; // (Question) - Text Entry (Numberic) value is number of decimals
export const IS_PERCENTAGE  = "display-def-is-percentage"; // (Question) - Text Entry (Numberic)
export const NO_THOUSAND_SEPARATOR  = "display-def-no-thousand-separator"; // (Question) - Text Entry (Numberic)
export const FIVE_DIGIT_ZIP  = "display-def-five-digit-zip"; // (Question) - Text Entry (Numberic)
export const CUSTOM_MASK  = "display-def-custom-mask"; // (Question) - Text Entry (Numberic)

export const TEXT_FLOATING_LABEL  = "display-def-floating-label"; // (Question) - Text Entry
export const TEXT_PLACEHOLDER  = "display-def-placeholder"; // (Question) - Text Entry
export const TEXT_VARIANT  = "display-def-entry-variant"; // (Question) - Text Entry: 'standard' | 'outlined' | 'filled' default = outlined
export const TEXT_SIZE  = "display-def-entry-size"; // (Question) - Text Entry small | medium, default = small
export const TEXT_WIDTH  = "display-def-entry-width"; // (Question) - Text Entry ###% default = 100%
export const TEXT_MAXLENGTH  = "display-def-maxlength"; // (Question) - Text Entry Default 80
export const TEXT_MULTILINE  = "display-def-multiline"; // (Question) - Text Entry Default false
export const TEXT_MAXROW  = "display-def-maxrow"; // (Question) - Text Entry Default 1
export const TEXT_LABEL_ABOVE  = "display-def-label-above"; // (Question) - Text Entry
export const TEXT_LABEL_BELOW  = "display-def-label-below"; // (Question) - Text Entry

export const TEXT_FLOATING_LABEL2  = "display-def-floating-label2"; // (Question) - Text Entry
export const TEXT_PLACEHOLDER2  = "display-def-placeholder2"; // (Question) - Text Entry
export const TEXT_VARIANT2  = "display-def-entry-variant2"; // (Question) - Text Entry: 'standard' | 'outlined' | 'filled' default = outlined
export const TEXT_SIZE2  = "display-def-entry-size2"; // (Question) - Text Entry small | medium, default = small
export const TEXT_WIDTH2  = "display-def-entry-width2"; // (Question) - Text Entry ###% default = 100%
export const TEXT_MAXLENGTH2  = "display-def-maxlength"; // (Question) - Text Entry
export const TEXT_LABEL_ABOVE2  = "display-def-label-above2"; // (Question) - Text Entry
export const TEXT_LABEL_BELOW2  = "display-def-label-below2"; // (Question) - Text Entry

export const TEXT_FLOATING_LABEL3  = "display-def-floating-label3"; // (Question) - Text Entry
export const TEXT_PLACEHOLDER3  = "display-def-placeholder3"; // (Question) - Text Entry
export const TEXT_VARIANT3  = "display-def-entry-variant3"; // (Question) - Text Entry: 'standard' | 'outlined' | 'filled' default = outlined
export const TEXT_SIZE3  = "display-def-entry-size3"; // (Question) - Text Entry small | medium, default = small
export const TEXT_WIDTH3  = "display-def-entry-width3"; // (Question) - Text Entry ###% default = 100%
export const TEXT_MAXLENGTH3  = "display-def-maxlength"; // (Question) - Text Entry
export const TEXT_LABEL_ABOVE3  = "display-def-label-above3"; // (Question) - Text Entry
export const TEXT_LABEL_BELOW3  = "display-def-label-below3"; // (Question) - Text Entry

export const TEXT_FLOATING_LABEL4  = "display-def-floating-label4"; // (Question) - Text Entry
export const TEXT_PLACEHOLDER4 = "display-def-placeholder4"; // (Question) - Text Entry
export const TEXT_VARIANT4  = "display-def-entry-variant4"; // (Question) - Text Entry: 'standard' | 'outlined' | 'filled' default = outlined
export const TEXT_SIZE4  = "display-def-entry-size4"; // (Question) - Text Entry small | medium, default = small
export const TEXT_WIDTH4  = "display-def-entry-width4"; // (Question) - Text Entry ###% default = 100%
export const TEXT_MAXLENGTH4  = "display-def-maxlength"; // (Question) - Text Entry
export const TEXT_LABEL_ABOVE4  = "display-def-label-above4"; // (Question) - Text Entry
export const TEXT_LABEL_BELOW4  = "display-def-label-below4"; // (Question) - Text Entry

export const TEXT_FLOATING_LABEL5  = "display-def-floating-label5"; // (Question) - Text Entry
export const TEXT_PLACEHOLDER5 = "display-def-placeholder5"; // (Question) - Text Entry
export const TEXT_VARIANT5  = "display-def-entry-variant5"; // (Question) - Text Entry: 'standard' | 'outlined' | 'filled' default = outlined
export const TEXT_SIZE5  = "display-def-entry-size5"; // (Question) - Text Entry small | medium, default = small
export const TEXT_WIDTH5  = "display-def-entry-width5"; // (Question) - Text Entry ###% default = 100%
export const TEXT_MAXLENGTH5  = "display-def-maxlength"; // (Question) - Text Entry
export const TEXT_LABEL_ABOVE5  = "display-def-label-above5"; // (Question) - Text Entry
export const TEXT_LABEL_BELOW5  = "display-def-label-below5"; // (Question) - Text Entry


export const DISPLAY_DATE_RANGE_AS_SIDE_BY_SIDE  = "display-def-date-range-side-by-side";
export const DISPLAY_DATE_RANGE_AS_ONE_PER_ROW  = "display-def-date-range-one-per-row";

// ---------------------------------------------------------------------------------------------------------------------

export const METADATA_VALUE_PREFIX_PAGENUM  = "#pageNum";  // "#pageNum"
export const METADATA_VALUE_PREFIX_SRC  = "#src=";  // "#pageNum"

// ---------------------------------------------------------------------------------------------------------------------
// Single Select Question Display Overrides (Non-Likert)
// Default display is radio buttons if < 6 answers, otherwise DDLB
// Default position if 2 answers is side-by-side, otherwise one-per-row
// ---------------------------------------------------------------------------------------------------------------------

export const DISPLAY_SINGLE_SELECT_AS_RADIOS_LABEL_RIGHT  = "display-def-as-radios-right";
export const DISPLAY_SINGLE_SELECT_AS_RADIOS_LABEL_TOP  = "display-def-as-radios-top";
export const DISPLAY_SINGLE_SELECT_AS_RADIOS_LABEL_BOTTOM  = "display-def-as-radios-bottom";

export const DISPLAY_SINGLE_SELECT_AS_RADIOS  = "display-def-as-radios"; // Question Type Yes/No radio is DEFAULT, Single Select
export const DISPLAY_SINGLE_SELECT_AS_OUTLINED_RADIOS  = "display-def-as-outlined-radios"; // Question Type Yes/No, Single Select
export const DISPLAY_SINGLE_SELECT_AS_STANDARD_BUTTONS  = "display-def-as-standard-buttons"; // Question Type Yes/No, Single Select
export const DISPLAY_SINGLE_SELECT_AS_CLEAR_BUTTONS  = "display-def-as-clear-buttons"; // Question Type Yes/No, Single Select
export const DISPLAY_SINGLE_SELECT_AS_DDLB  = "display-def-as-ddlb"; // Question Type Yes/No, Single Select
export const DISPLAY_SINGLE_SELECT_AS_BUTTONGROUP  = "display-def-as-buttongroup";

export const DISPLAY_SINGLE_SELECT_AS_ICON_ONLY  = "display-def-radio-as-icon-only"; // For single select with icons hide the radio button and use the icon as the button

export const DISPLAY_SINGLE_SELECT_AS_SIDE_BY_SIDE_WRAP  = "display-def-side-by-side-wrap";
export const DISPLAY_SINGLE_SELECT_AS_SIDE_BY_SIDE  = "display-def-side-by-side"; // Question Type Yes/No defulat is radio, side by side , Single Select
export const DISPLAY_SINGLE_SELECT_AS_ONE_PER_ROW  = "display-def-one-per-row"; // Question Type Yes/No, Single Select
export const DISPLAY_SINGLE_SELECT_SIDE_BY_SIDE_SEPARATOR  = "display-def-side-by-side-separator"; // Question Type Yes/No, Single Select

// ---------------------------------------------------------------------------------------------------------------------
// Multi-Select Checkboxes
// ---------------------------------------------------------------------------------------------------------------------

// export const CB_SELECTED_COLOR_BACKGROUND  = "display-def-cb-selected-color-background"; // (Question) CB = checkbox - Outlined radios and checkboxes
// export const CB_SELECTED_COLOR_CONTROL  = "display-def-cb-selected-color-control"; // (group/question)
// export const CB_SELECTED_COLOR_TEXT  = "display-def-cb-selected-color-text";
// export const CB_UNSELECTED_COLOR_BACKGROUND  = "display-def-cb-unselected-color-background";
// export const CB_UNSELECTED_COLOR_CONTROL  = "display-def-cb-unselected-color-control"; // (group/question)
// export const CB_UNSELECTED_COLOR_TEXT  = "display-def-cb-unselected-color-text";


export const UNSELECTED_COLOR_BORDER  = "display-def-unselected-color-border";
export const UNSELECTED_COLOR_BACKGROUND  = "display-def-unselected-color-background";
export const UNSELECTED_COLOR_CONTROL  = "display-def-unselected-color-control"; // (group/question)
export const UNSELECTED_COLOR_TEXT  = "display-def-unselected-color-text";
export const SELECTED_COLOR_BORDER  = "display-def-selected-color-border"; // (group/question)
export const SELECTED_COLOR_BACKGROUND  = "display-def-selected-color-background"; // (group/question)
export const SELECTED_COLOR_CONTROL  = "display-def-selected-color-control"; // (group/question)
export const SELECTED_COLOR_TEXT  = "display-def-selected-color-text";
export const HOVER_COLOR_BORDER  = "display-def-hover-color-border"; // (group/question)
export const HOVER_COLOR_BACKGROUND  = "display-def-hover-color-background"; // (group/question)
export const HOVER_COLOR_CONTROL  = "display-def-hover-color-control"; // (group/question)
export const HOVER_COLOR_TEXT  = "display-def-hover-color-text";
export const HOVER_SELECTED_COLOR_BORDER  = "display-def-hover-selected-color-border"; // (group/question)
export const HOVER_SELECTED_COLOR_BACKGROUND  = "display-def-hover-selected-color-background"; // (group/question)
export const HOVER_SELECTED_COLOR_CONTROL  = "display-def-hover-selected-color-control"; // (group/question)
export const HOVER_SELECTED_COLOR_TEXT  = "display-def-hover-selected-color-text";

export const BUTTON_BORDER_WIDTH  = "display-def-button-border-width";
export const BUTTON_BORDER_RADIUS  = "display-def-button-border-radius";
export const BUTTON_BOX_SHADOW  = "display-def-button-box-shadow";
export const BUTTON_HEIGHT  = "display-def-button-height";

export const DEFAULT_WIDTH  = "365px"; // (question) radios, outlined buttons, outlined checkboxes
export const OVERRIDE_WIDTH  = "display-def-override-width"; // (question) radios, outlined buttons, outlined checkboxes value = "12px" pixel width
export const CENTER_ANSWERS  = "display-def-center-answers"; // (question) radios, outlined buttons, outlined checkboxes value = "12px" pixel width

export const DISPLAY_CHECKBOXES_AS_DDLB  = "display-def-checkboxes-as-ddlb";       // (Question) Default display for checkboxes is checkboxes when < 5 answers, otherwise ddlb, this overrides to force DDLB
export const DISPLAY_CHECKBOXES_AS_CHECKBOXES  = "display-def-checkboxes-as-checkboxes";       // (Question) Default display for checkboxes is checkboxes when < 5 answers, otherwise ddlb, this overrides to force checkboxes
export const DISPLAY_CHECKBOXES_AS_OUTLINED  = "display-def-checkboxes-as-outlined";       // (Question) Default display for checkboxes is checkboxes when < 5 answers, otherwise ddlb, this overrides to force checkboxes

// ---------------------------------------------------------------------------------------------------------------------
//
// ---------------------------------------------------------------------------------------------------------------------


// ---------------------------------------------------------------------------------------------------------------------
// Single Select Question Display Overrides (Likert)
// ---------------------------------------------------------------------------------------------------------------------


export const USE_LIKERT_GRAYBAR_HEADER  = "display-def-use-graybar-header"; // (Group/Question)
export const USE_LIKERT_STANDARD_HEADER  = "display-def-use-standard-header"; // (Group/Question)
export const USE_LIKERT_ANSWERS_HEADER  = "display-def-use-answers-header"; // (Group/Question)  // Answer Text is titles, like for radios and circles only

export const DEFAULT_GRAYBAR_COLOR  = "#EEEEEE"; // (Group/Question)
export const DEFAULT_GRAYBAR_HEIGHT  = "40px"; // (Group/Question)

export const USE_LIKERT_GRAYBAR_COLOR  = "display-def-use-graybar-color"; // (Group, Question) graybar title backgrond color
export const USE_LIKERT_GRAYBAR_HEIGHT  = "display-def-use-graybar-height"; // (Group, Question) graybar title height
export const USE_CUSTOM_QUESTION_FOR_TITLE  = "display-def-use-custom-question-for-title"; // (Group, Question) graybar title overrides
export const USE_CUSTOM_DESC_FOR_TITLE  = "display-def-use-custom-desc-for-title"; // (Group, Question) graybar title overrides

export const LIKERT_HEADER_TITLE_LEFT  = "display-def-likert-title-left"; // (Group) Title headers
export const LIKERT_HEADER_TITLE_CENTER  = "display-def-likert-title-center"; // (Group) Title headers
export const LIKERT_HEADER_TITLE_RIGHT  = "display-def-likert-title-right"; // (Group) Title headers

export const HIDE_BUTTONGROUP_NAME  = "display-def-hide-buttongroup-name";  // (Answer) Buttongroup - Suppress the display of the number in the button of a buttongroup // (Answer) Radios - don't show title above

export const USE_LIKERT_RESPONSIVE_LABEL  = "display-def-use-responsive-label"; // (Answer) Buttongroup - Display value when selected beneath button group square or circle (Responsive Labels)
export const USE_LIKERT_DEFAULT_RESPONSIVE_LABEL  = "display-def-use-default-responsive-label"; // (Group/Question) Buttongroup - Display DEFAULT value when selected beneath button group square or circle (Responsive Labels)

export const INDENT_NAME  = "display-def-indent-name";  // Indent any name, value = pixel indent ie.. "20px"

export const ALIGN_QUESTION_ANSWER_RIGHT  = "align-question-answer-right";  // (Questionnaire)(Group)(Question) Align question answers right
export const ALIGN_ANSWERS_WITH_QUESTION  = "display-def-likert-question-align-with-answers";  // (Questionnaire)(Group)(Question) Align question answers right

export const DISPLAY_SINGLE_SELECT_AS_LIKERT  = "display-def-as-likert";  // Format as if it were a LIKERT, this is a workaround for show groups not currently developed

export const DISPLAY_BUTTONS_AS_CIRCLES  = "display-def-button-as-circles"; // (Question) Buttongroup
export const DISPLAY_BUTTONS_AS_CIRCLES_COLORS  = "display-def-button-as-circles-colors"; // (Question) Buttongroup
export const DISPLAY_BUTTONS_AS_CIRCLES_COLORS_ALT  = "display-def-button-as-circles-colors-alt"; // (Question) Buttongroup
export const DISPLAY_BUTTONS_AS_CIRCLES_PYRAMID  = "display-def-button-as-circles-pyramid"; // (Question) Buttongroup
export const DISPLAY_BUTTONS_AS_CIRCLES_PYRAMID_COLORS  = "display-def-button-as-circles-pyramid-colors"; // (Question) Buttongroup
export const DISPLAY_BUTTONS_AS_CIRCLES_PYRAMID_COLORS_ALT  = "display-def-button-as-circles-pyramid-colors-alt"; // (Question) Buttongroup


export const DISPLAY_AS_LIKERT_LABELS  = "display-def-label-placement";  // (Question) - display labels on bottom or start or end

export const LIKERT_FORMAT_WIDTHS  = "display-def-likert-widths"; // (Likert Group Widths in numbers the % is added later question|answers
export const LIKERT_QUESTION_ABOVE  = "display-def-likert-question-above"; // (Likert Group Widths in numbers the % is added later question|answers
export const LIKERT_LABELS_ALL_QUESTIONS  = "display-def-likert-labels-all-questions"; // (Likert Group Widths in numbers the % is added later question|answers
export const LIKERT_LABELS_BOTTOM  = "display-def-likert-labels-bottom"; // (Likert Group Widths in numbers the % is added later question|answers
export const LIKERT_QUESTION_ALIGN_ADJUSTMENT  = "display-def-likert-question-align-adjustment"; // (Likert adjust vertical alignment of question name when inline or above
export const LIKERT_QUESTION_ALIGN_WITH_ANSWERS  = "display-def-likert-question-align-with-answers"; // (Likert align question text with answers


export const LIKERT_QUESTION_SPACE_AFTER  = "display-def-likert-question-space-after"; // (Likert space after question default is 10px
export const LIKERT_ANSWERS_SPACE_AFTER  = "display-def-likert-answers-apace-after"; // (Likert space after answers default is 10px


export const DISPLAY_LIKERT_TITLE_COLOR  = "display-def-likert-title-color"; // (Group/Question) Likert title text overrides
export const DISPLAY_LIKERT_TITLE_SIZE  = "display-def-likert-title-size"; // (Group/Question) Likert title text overrides
export const DISPLAY_LIKERT_TITLE_ALIGN  = "display-def-likert-title-align"; // (Group/Question) Likert title text overrides
export const DISPLAY_LIKERT_TITLE_WEIGHT  = "display-def-likert-title-weight"; // (Group/Question) Likert title text overrides
export const DISPLAY_LIKERT_TITLE_STYLE  = "display-def-likert-title-style"; // (Group/Question) Likert title text overrides
export const DISPLAY_LIKERT_TITLE_OPACITY  = "display-def-likert-title-opacity"; // (Group/Question) Likert title text overrides
export const DISPLAY_LIKERT_TITLE_FONT  = "display-def-likert-title-font"; // (Group/Question) Likert title text overrides

export const DISPLAY_LIKERT_TITLEBAR_AFTERSPACE_HEIGHT  = "display-def-likert-titlebar-afterspace-height"; // (Group, Question) responsive label override

export const DISPLAY_DEFAULT_RESPONSIVE_LABEL_COLOR  = "display-def-responsive-label-color"; // (Group, Question, Answer) responsive label override
export const DISPLAY_DEFAULT_RESPONSIVE_LABEL_SIZE  = "display-def-responsive-label-size"; // (Group, Question, Answer) responsive label override
export const DISPLAY_DEFAULT_RESPONSIVE_LABEL_ALIGN  = "display-def-responsive-label-align"; // (Group, Question, Answer) responsive label override
export const DISPLAY_DEFAULT_RESPONSIVE_LABEL_WEIGHT  = "display-def-responsive-label-weight"; // (Group, Question, Answer) responsive label override
export const DISPLAY_DEFAULT_RESPONSIVE_LABEL_STYLE  = "display-def-responsive-label-style"; // (Group, Question, Answer) responsive label override
export const DISPLAY_DEFAULT_RESPONSIVE_LABEL_OPACITY  = "display-def-responsive-label-opacity"; // (Group, Question, Answer) responsive label override
export const DISPLAY_DEFAULT_RESPONSIVE_LABEL_FONT  = "display-def-responsive-label-font"; // (Group, Question, Answer) responsive label override

export const USE_CUSTOM_QUESTION_FOR_RESPONISVE_LABEL  = "display-def-use-custom-question-for-responsive-label"; // (Group, Question)
export const USE_CUSTOM_DESC_FOR_RESPONISVE_LABEL  = "display-def-use-custom-desc-for-responsive-label"; // (Group, Question)

// ---------------------------------------------------------------------------------------------------------------------
// TEXT ENTRIES
// ---------------------------------------------------------------------------------------------------------------------

export const DISPLAY_DEF_TEXT_COLOR  = "display-def-color-text";  // (Question) TEXT ENTRY
export const DISPLAY_DEF_FLOATING_LABEL_COLOR  = "display-def-floating-label-color";  // (Question) TEXT ENTRY
export const DISPLAY_DEF_PLACEHOLDER_COLOR  = "display-def-placeholder-color";  // (Question) TEXT ENTRY
export const DISPLAY_DEF_COLOR_ANSWERED_BORDER  = "display-def-color-answered-border";  // (Question) TEXT ENTRY
export const DISPLAY_DEF_BORDER_COLOR  = "display-def-color-unfocused";  // (Question) TEXT ENTRY
export const DISPLAY_DEF_BORDER_COLOR_HOVERED  = "display-def-color-hover";  // (Question) TEXT ENTRY
export const DISPLAY_DEF_BORDER_COLOR_FOCUSED  = "display-def-color-focused";  // (Question) TEXT ENTRY

// ---------------------------------------------------------------------------------------------------------------------
// SELECT DDLB
// ---------------------------------------------------------------------------------------------------------------------

export const DISPLAY_DEF_SELECT_WIDTH  = "display-def-select-width";  // (Question SELECT (DDLB))

export const DISPLAY_DEF_SELECT_LABEL_TEXT  = "display-def-select-label-text";  // (Question SELECT (DDLB))
export const DISPLAY_DEF_SELECT_NO_QUESTION_NUMBER  = "display-def-select-no-question-number";  // (Question SELECT (DDLB))

export const DISPLAY_DEF_SELECT_LABEL_TEXT_COLOR  = "display-def-select-label-text-color";  // (Question SELECT (DDLB))

export const DISPLAY_DEF_SELECT_TEXT_COLOR  = "display-def-select-text-color";  // (Question SELECT (DDLB))
export const DISPLAY_DEF_SELECT_ANSWERED_TEXT_COLOR  = "display-def-select-answered-text-color";  // (Question SELECT (DDLB))
export const DISPLAY_DEF_SELECT_FLOATED_TEXT_COLOR  = "display-def-select-floated-text-color";  // (Question SELECT (DDLB))
export const DISPLAY_DEF_SELECT_BASE_BORDER  = "display-def-select-base-border";  // (Question SELECT (DDLB))
export const DISPLAY_DEF_SELECT_HOVER_BORDER  = "display-def-select-hover-border";  // (Question SELECT (DDLB))
export const DISPLAY_DEF_SELECT_FOCUS_BORDER  = "display-def-select-focus-border";  // (Question SELECT (DDLB))
export const DISPLAY_DEF_SELECT_ANSWERED_BORDER  = "display-def-select-answered-border";  // (Question SELECT (DDLB))
export const DISPLAY_DEF_SELECT_NO_FLOAT  = "display-def-select-no-float";  // (Question SELECT (DDLB))
export const DISPLAY_DEF_SELECT_DDLB_LABEL  = "display-def-select-ddlb-label";  // (Question SELECT (DDLB))
//export const DISPLAY_DEF_SELECT_TEXT_COLOR  = "display-def-select-text-color";  // (Question SELECT (DDLB))

// ---------------------------------------------------------------------------------------------------------------------
// ICON Overrides
// ---------------------------------------------------------------------------------------------------------------------

export const ICON_POSITION  = "icon-position";
export const ICON_UNSELECTED_COLOR  = "icon-unselected-color";
export const ICON_SELECTED_COLOR  = "icon-selected-color";

export const OVERRIDE_ICON_POSITION  = "display-def-icon-position";

export const OVERRIDE_ICON_UNSELECTED_COLOR  = "display-def-icon-unselected-color"; // (Group/Question) level icon color override
export const OVERRIDE_ICON_UNSELECTED_OPACITY  = "display-def-icon-unselected-opacity"; // (Group/Question) level icon opacity override

export const OVERRIDE_ICON_UNSELECTED_BACKGROUND_COLOR  = "display-def-icon-unselected-background-color"; // (Group/Question) level icon color override
export const OVERRIDE_ICON_UNSELECTED_BACKGROUND_OPACITY  = "display-def-icon-unselected-background-opacity"; // (Group/Question) level icon opacity override

export const OVERRIDE_ICON_UNSELECTED_TEXT_COLOR  = "display-def-icon-unselected-text-color"; // (Group/Question) level icon color override
export const OVERRIDE_ICON_UNSELECTED_TEXT_COPACITY  = "display-def-icon-unselected-text-opacity"; // (Group/Question) level icon opacity override

export const OVERRIDE_ICON_SELECTED_BORDER_COLOR  = "display-def-icon-selected-border-color"; // (Group/Question) level icon color override
export const OVERRIDE_ICON_SELECTED_BORDER_OPACITY  = "display-def-icon-selected-border-opacity"; // (Group/Question) level icon opacity override

export const OVERRIDE_ICON_SELECTED_COLOR  = "display-def-icon-selected-color"; // (Group/Question) level icon color override
export const OVERRIDE_ICON_SELECTED_OPACITY  = "display-def-icon-selected-opacity"; // (Group/Question) level icon opacity override

export const OVERRIDE_ICON_SELECTED_TEXT_COLOR  = "display-def-icon-selected-text-color"; // (Group/Question) level icon color override
export const OVERRIDE_ICON_SELECTED_TEXT_OPACITY  = "display-def-icon-selected-text-opacity"; // (Group/Question) level icon opacity override

export const OVERRIDE_ICON_TEXT_FONT  = "display-def-icon-text-font"; // (Group/Question) level icon font override

export const OVERRIDE_ICON_SQUARE_BORDER_WIDTH  = "display-def-icon-square-border-width"; // (Group/Question) level icon font override


// ---------------------------------------------------------------------------------------------------------------------
// ANSWSERS
// ---------------------------------------------------------------------------------------------------------------------

export const ANSWER_LABEL_TEXT_COLOR  = "display-def-answer-label-text-color"; // (question) label text color
export const ANSWER_TEXT_MATCH_QUESTION_NAME  = "display-def-answer-match-question-name"; // (question) match custom question (value can be font size pt override ie.. "10pt"
export const ANSWER_TEXT_MATCH_QUESTION_DESC  = "display-def-answer-match-question-desc"; // (question) match custom question (value can be font size pt override ie.. "10pt"

// ---------------------------------------------------------------------------------------------------------------------
// ---------------------------------------------------------------------------------------------------------------------

// UI Generated metadata...
export const CUSTOM_DDO_QNR_FORMAT_THEME  = "custom-ddo-questionnaire-format-theme"; // (Questionnaire)
export const CUSTOM_DDO_QNR_FORMAT_APP_HEADER  = "custom-ddo-questionnaire-format-app-header"; // (Questionnaire)
export const CUSTOM_DDO_QNR_FORMAT_START_PAGE  = "custom-ddo-questionnaire-format-start-page"; // (Questionnaire)
export const CUSTOM_DDO_QNR_FORMAT_MAIN_PAGES  = "custom-ddo-questionnaire-format-main-pages"; // (Questionnaire) Inclueds HEADER and FOOTER details that will override and metadata Header/Footer Entered
export const CUSTOM_DDO_QNR_FORMAT_CLOSE_PAGE  = "custom-ddo-questionnaire-format-close-page"; // (Questionnaire)
export const CUSTOM_DDO_QNR_FORMAT_ALERTS  = "custom-ddo-questionnaire-format-alerts"; // (Questionnaire)

export const CUSTOM_DDO_CAT_FORMAT  = "custom-ddo-category-format"; // (Cataegory)
export const CUSTOM_DDO_GRP_FORMAT  = "custom-ddo-group-format"; // (Group)
export const CUSTOM_DDO_QUE_FORMAT  = "custom-ddo-question-format"; // (Question)
export const CUSTOM_DDO_ANSWER_FORMAT  = "custom-ddo-answer-format"; // (Answer)

export const CUSTOM_DDO_NAME  = "custom-ddo-name"; // (Questionnaire, Question, Group, Category)
export const CUSTOM_DDO_DESC  = "custom-ddo-desc"; // (Questionnaire, Question, Group, Category)
export const CUSTOM_DDO_DEFAULTS  = "custom-ddo-defaults"; // (Questionnaire) custom overrides for all default text
export const CUSTOM_DDO_BUTTONS_RAISED  = "custom-ddo-buttons-raised"; // (Questionnaire) custom overrides for all raised buttons
export const CUSTOM_DDO_BUTTONS_PLAIN  = "custom-ddo-buttons-plain"; // (Questionnaire) custom overrides for all plain buttons

export const CUSTOM_DDO_LABEL  = "custom-ddo-label"; // (Question)
export const CUSTOM_DDO_SCRIPT_BEFORE  = "custom-ddo-script-before"; // (Questionnaire)
export const CUSTOM_DDO_SCRIPT_AFTER  = "custom-ddo-script-after"; // (Questionnaire)
export const CUSTOM_DDO_SCRIPT  = "custom-ddo-script"; // (Category)
export const CUSTOM_DDO_CLOSE_PAGE_TEXT1  = "custom-ddo-close-text1"; // (Questionnaire)
export const CUSTOM_DDO_CLOSE_PAGE_TEXT2  = "custom-ddo-close-text2"; // (Questionnaire)
export const CUSTOM_DDO_CLOSE_PAGE_TEXT3  = "custom-ddo-close-text3"; // (Questionnaire)

// Category title bar Left/Center/Right Text
export const CUSTOM_DDO_CATEGORY_TITLE_BAR_LEFT  = "custom-ddo-category-title-bar-left"; // (Category)
export const CUSTOM_DDO_CATEGORY_TITLE_BAR_CENTER  = "custom-ddo-category-title-bar-center"; // (Category)
export const CUSTOM_DDO_CATEGORY_TITLE_BAR_RIGHT  = "custom-ddo-category-title-bar-right"; // (Category)

// ---------------------------------------------------------------------------------------------------------------------
// ---------------------------------------------------------------------------------------------------------------------


export function metadataExists(templateObj, metadataName, prefixStr = "", custom = false) {

    // if (metadataName === CUSTOM_DDO_QNR_FORMAT_MAIN_PAGES) {
    //     debugger;
    // }
    let metadataExists = false;

    if (templateObj !== null && templateObj !== undefined) {

        if (templateObj.metadata !== undefined && templateObj.metadata.metadataItems !== undefined && templateObj.metadata.metadataItems.hasOwnProperty(metadataName)) {
            if (prefixStr !== "") {

                // This check is for when specific overrides in the metadata value like "src=" for displaying an image inplace of a string
                if (templateObj.metadata.metadataItems[metadataName].substr(0,prefixStr.length) === prefixStr) {
                    metadataExists = true;
                }
            }
            else {
                metadataExists = true;
            }
        }
        else if (templateObj.metadata !== undefined && templateObj.metadata.hasOwnProperty(metadataName)) {
            if (prefixStr !== "") {

                // This check is for when specific overrides in the metadata value like "src=" for displaying an image inplace of a string
                if (templateObj.metadata[metadataName].substr(0,prefixStr.length) === prefixStr) {
                    metadataExists = true;
                }
            }
            else {
                metadataExists = true;
            }
        }

    }

    if ((metadataName === DISPLAY_BUTTONS_AS_CIRCLES_PYRAMID_COLORS)||(metadataName === DISPLAY_BUTTONS_AS_CIRCLES_COLORS)) {
        // debugger; ZZZZZ
    }

    // Check for a UI created metadata
    if (!custom) {
        let returnObj = customMetadataValue(templateObj, metadataName);
        metadataExists = returnObj.exists ? true : metadataExists;
    }

    return metadataExists;
}


export function metadataValue(templateObj, metadataName, prefixStr = "", defaultValue = "", custom = false) {

    let metadataValue = defaultValue;

    if (templateObj !== null && templateObj !== undefined) {

        if (templateObj.metadata !== undefined && templateObj.metadata.metadataItems !== undefined && templateObj.metadata.metadataItems.hasOwnProperty(metadataName)) {

            // If there is a prefix string then parse it out
            if (prefixStr !== "") {
                metadataValue = templateObj.metadata.metadataItems[metadataName].substr(prefixStr.length);
            }
            else {
                metadataValue = templateObj.metadata.metadataItems[metadataName];
            }
        }
        if (templateObj.metadata !== undefined && templateObj.metadata.hasOwnProperty(metadataName)) {

            // If there is a prefix string then parse it out
            if (prefixStr !== "") {
                metadataValue = templateObj.metadata[metadataName].substr(prefixStr.length);
            }
            else {
                metadataValue = templateObj.metadata[metadataName];
            }
        }
    }

    if ((metadataName === DISPLAY_BUTTONS_AS_CIRCLES_PYRAMID_COLORS)||(metadataName === DISPLAY_BUTTONS_AS_CIRCLES_COLORS)) {
        // debugger; ZZZZZ
    }

    // Check for a UI created metadata
    if (!custom) {
        let returnObj = customMetadataValue(templateObj, metadataName);
        if (returnObj.exists) {
            metadataValue = returnObj.value;
        }
    }

    return metadataValue;
}


export function customMetadataValue(templateObj, metadataName) {

    let returnObj = {"exists": false, "tag": "", "value": ""};

    switch (metadataName) {

        // ---------------------------------------------------------------------------------------------- Questionnaires
        case USE_SECONDARY_THEME:
            checkMetadataQuestionnaireValueObject(templateObj, returnObj, metadataName, CUSTOM_DDO_QNR_FORMAT_THEME);
            break;

        case CUSTOM_DDO_DEFAULTS:
        case CUSTOM_DDO_BUTTONS_RAISED:
        case CUSTOM_DDO_BUTTONS_PLAIN:
            checkMetadataThemeObject(templateObj, returnObj, metadataName);
            break;

        case APPBAR:
        case APPBAR_TITLE_LEFT:
        case APPBAR_TITLE_CENTER:
        case APPBAR_TITLE_RIGHT:
            // case APPBAR_IMAGE_LEFT:
            // case APPBAR_IMAGE_CENTER:
            // case APPBAR_IMAGE_RIGHT:
            checkMetadataQuestionnaireValueObject(templateObj, returnObj, metadataName, CUSTOM_DDO_QNR_FORMAT_APP_HEADER);
            break;

        case HAS_START_PAGE:
            checkMetadataQuestionnaireValueObject(templateObj, returnObj, metadataName, CUSTOM_DDO_QNR_FORMAT_START_PAGE);
            break;

        case FORMAT_FULL_PAGE:
        case FORMAT_ONE_QUESTION_PER_PAGE:
        case RESTART_QUESTION_NUMBERS_EACH_PAGE:
        case SHOW_QUESTION_NUMBERS:
        case SUBNUMBER_LIKERT_QUESTIONS:
        case SUBNUMBER_SHOWGROUP_QUESTIONS:
        case PAGE_HEADER:
        case PAGE_HEADER_LEFT:
        case PAGE_HEADER_CENTER:
        case PAGE_HEADER_RIGHT:
        case PAGE_FOOTER:
        case PAGE_FOOTER_LEFT:
        case PAGE_FOOTER_CENTER:
        case PAGE_FOOTER_RIGHT:
        case PAGE_FOOTER2:
        case PAGE_FOOTER_LEFT2:
        case PAGE_FOOTER_CENTER2:
        case PAGE_FOOTER_RIGHT2:

            checkMetadataQuestionnaireValueObject(templateObj, returnObj, metadataName, CUSTOM_DDO_QNR_FORMAT_MAIN_PAGES);
            break;

        case NO_CLOSE_PAGE:
            checkMetadataQuestionnaireValueObject(templateObj, returnObj, metadataName, CUSTOM_DDO_QNR_FORMAT_CLOSE_PAGE);
            returnObj.exists = !returnObj.exists;  // Inverted becasue if CUSTOM_DDO_QNR_FORMAT_CLOSE_PAGE does not exisit then there should be no close page
            break;
        case CLOSE_PAGE_NO_GREEN_CHECK_ICON:
        case CLOSE_PAGE_NO_DEFAULT_TEXT:
            checkMetadataQuestionnaireValueObject(templateObj, returnObj, metadataName, CUSTOM_DDO_QNR_FORMAT_CLOSE_PAGE);
            break;

        case ALERT_TEXT_TYPE:
        case ALERT_TEXT_INVALID_KEY:
        case ALERT_TEXT_COMPLEATED:
        case ALERT_TEXT_DEACTIVATED:
        case ALERT_TEXT_EXPIRED:
        case ALERT_TEXT_CANCELED:
        case CANCEL_TEXT_OVERRIDE:
            checkMetadataQuestionnaireValueObject(templateObj, returnObj, metadataName, CUSTOM_DDO_QNR_FORMAT_ALERTS);
            break;


        // ---------------------------------------------------------------------------------------------------- Category
        case NEW_PAGE:
        case CATEGORY_TITLE_BAR:
            checkMetadataCategoryValueObject(templateObj, returnObj, metadataName, CUSTOM_DDO_CAT_FORMAT);
            break;

        case CATEGORY_TITLE_BAR_LEFT:
            checkMetadataCategoryValueObject(templateObj, returnObj, metadataName, CUSTOM_DDO_CATEGORY_TITLE_BAR_LEFT);
            break;
        case CATEGORY_TITLE_BAR_CENTER:
            checkMetadataCategoryValueObject(templateObj, returnObj, metadataName, CUSTOM_DDO_CATEGORY_TITLE_BAR_CENTER);
            break;
        case CATEGORY_TITLE_BAR_RIGHT:
            checkMetadataCategoryValueObject(templateObj, returnObj, metadataName, CUSTOM_DDO_CATEGORY_TITLE_BAR_RIGHT);
            break;


        // ------------------------------------------------------------------------------------------------------ Titles
        case DISPLAY_DEF_NAME:
            checkMetadataTitleObject(templateObj, CUSTOM_DDO_NAME, returnObj);
            break;
        case DISPLAY_DEF_DESCRIPTION:
            checkMetadataTitleObject(templateObj, CUSTOM_DDO_DESC, returnObj);
            break;
        case SCRIPT_BEFORE:
            //case DISPLAY_DEF_SCRIPT_BEFORE:
            checkMetadataTitleObject(templateObj, CUSTOM_DDO_SCRIPT_BEFORE, returnObj);
            break;
        case SCRIPT_AFTER:
            //case DISPLAY_DEF_SCRIPT_AFTER:
            checkMetadataTitleObject(templateObj, CUSTOM_DDO_SCRIPT_AFTER, returnObj);
            break;
        case CATEGORY_SCRIPT:
            checkMetadataTitleObject(templateObj, CUSTOM_DDO_SCRIPT, returnObj);
            break;
        case CLOSE_PAGE_TEXT1:
            checkMetadataTitleObject(templateObj, CUSTOM_DDO_CLOSE_PAGE_TEXT1, returnObj);
            break;
        case CLOSE_PAGE_TEXT2:
            checkMetadataTitleObject(templateObj, CUSTOM_DDO_CLOSE_PAGE_TEXT2, returnObj);
            break;
        case CLOSE_PAGE_TEXT3:
            checkMetadataTitleObject(templateObj, CUSTOM_DDO_CLOSE_PAGE_TEXT3, returnObj);
            break;
        // -------------------------------------------------------------------------------- Question Type: Free Text

        case TEXT_FLOATING_LABEL:
        case TEXT_PLACEHOLDER:
        case TEXT_VARIANT:
        case TEXT_SIZE:
        case TEXT_WIDTH:
        case TEXT_MAXLENGTH:
        case TEXT_MULTILINE:
        case TEXT_MAXROW:
        case TEXT_LABEL_ABOVE:
        case TEXT_LABEL_BELOW:
        case TEXT_FLOATING_LABEL2:
        case TEXT_PLACEHOLDER2:
        case TEXT_VARIANT2:
        case TEXT_SIZE2:
        case TEXT_WIDTH2:
        case TEXT_MAXLENGTH2:
        case TEXT_LABEL_ABOVE2:
        case TEXT_LABEL_BELOW2:
        case TEXT_FLOATING_LABEL3:
        case TEXT_PLACEHOLDER3:
        case TEXT_VARIANT3:
        case TEXT_SIZE3:
        case TEXT_WIDTH3:
        case TEXT_MAXLENGTH3:
        case TEXT_LABEL_ABOVE3:
        case TEXT_LABEL_BELOW3:
        case TEXT_FLOATING_LABEL4:
        case TEXT_PLACEHOLDER4:
        case TEXT_VARIANT4:
        case TEXT_SIZE4:
        case TEXT_WIDTH4:
        case TEXT_MAXLENGTH4:
        case TEXT_LABEL_ABOVE4:
        case TEXT_LABEL_BELOW4:
        case TEXT_FLOATING_LABEL5:
        case TEXT_PLACEHOLDER5:
        case TEXT_VARIANT5:
        case TEXT_SIZE5:
        case TEXT_WIDTH5:
        case TEXT_MAXLENGTH5:
        case TEXT_LABEL_ABOVE5:
        case TEXT_LABEL_BELOW5:
        case DISPLAY_DATE_RANGE_AS_SIDE_BY_SIDE:
        case DISPLAY_DATE_RANGE_AS_ONE_PER_ROW:

            checkMetadataQuestionTextValueObject(templateObj, returnObj, metadataName);
            break;

        // -------------------------------------------------------------------------------- Question Type: Single Select
        // case DISPLAY_AS:
        //     checkMetadataValueObject(templateObj, CUSTOM_DDO_QUE_FORMAT, "RADIO", "displayAs", "radios", returnObj);
        //     break;

        case DISPLAY_SINGLE_SELECT_AS_LIKERT:
        case DISPLAY_SINGLE_SELECT_AS_RADIOS:
        case DISPLAY_SINGLE_SELECT_AS_OUTLINED_RADIOS:
        case DISPLAY_SINGLE_SELECT_AS_DDLB:
        case DISPLAY_SINGLE_SELECT_AS_STANDARD_BUTTONS:
        case DISPLAY_SINGLE_SELECT_AS_CLEAR_BUTTONS:
        case DISPLAY_SINGLE_SELECT_AS_BUTTONGROUP:
        case DISPLAY_BUTTONS_AS_CIRCLES:
        case DISPLAY_BUTTONS_AS_CIRCLES_PYRAMID:
        case DISPLAY_BUTTONS_AS_CIRCLES_COLORS:
        case DISPLAY_BUTTONS_AS_CIRCLES_COLORS_ALT:
        case DISPLAY_BUTTONS_AS_CIRCLES_PYRAMID_COLORS:
        case DISPLAY_BUTTONS_AS_CIRCLES_PYRAMID_COLORS_ALT:
        case INDENT_NAME:
        case ALIGN_QUESTION_ANSWER_RIGHT:
        case ALIGN_ANSWERS_WITH_QUESTION:
        case LIKERT_HEADER_TITLE_LEFT:
        case LIKERT_HEADER_TITLE_CENTER:
        case LIKERT_HEADER_TITLE_RIGHT:
        case USE_LIKERT_GRAYBAR_HEADER:
        case USE_LIKERT_STANDARD_HEADER:
        case USE_LIKERT_RESPONSIVE_LABEL:
        case USE_LIKERT_DEFAULT_RESPONSIVE_LABEL:
        case DISPLAY_SINGLE_SELECT_AS_SIDE_BY_SIDE_WRAP:
        case DISPLAY_SINGLE_SELECT_AS_SIDE_BY_SIDE:
        case DISPLAY_SINGLE_SELECT_AS_ONE_PER_ROW:
        case LIKERT_QUESTION_ALIGN_WITH_ANSWERS:
            if ((metadataName === DISPLAY_BUTTONS_AS_CIRCLES_PYRAMID_COLORS)||(metadataName === DISPLAY_BUTTONS_AS_CIRCLES_COLORS)) {
                // debugger; ZZZZZ
            }

            checkMetadataQuestionRadioValueObject(templateObj, returnObj, metadataName);
            break;

        case DISPLAY_CHECKBOXES_AS_OUTLINED:

            checkMetadataQuestionCheckboxValueObject(templateObj, returnObj, metadataName);
            break;

        case SELECTED_COLOR_BORDER:
        case SELECTED_COLOR_BACKGROUND:
        case SELECTED_COLOR_CONTROL:
        case SELECTED_COLOR_TEXT:
        case UNSELECTED_COLOR_BORDER:
        case UNSELECTED_COLOR_BACKGROUND:
        case UNSELECTED_COLOR_CONTROL:
        case UNSELECTED_COLOR_TEXT:

            checkMetadataQuestionOutlinedValueObject(templateObj, returnObj, metadataName);
            break;

        // no default
    }

    return returnObj;
}


export function checkMetadataTitleObject(templateObj, metadataTag, returnObj) {

    returnObj.tag = metadataTag;
    if (metadataExists(templateObj, returnObj.tag, "", true)) {
        returnObj.exists = true;
        returnObj.value = metadataValue(templateObj, returnObj.tag, "", "", true);

        if (returnObj.value !== "") {
            if (returnObj.value !== "hide") {
                returnObj.value = JSON.parse((returnObj.value === "") ? "{}" : returnObj.value);
            }
        }
    }
}


// --------------------------------------------------------------------------------------------------- Theme

export function checkMetadataThemeObject(templateObj, returnObj, metadataTag) {

    if (metadataExists(templateObj, CUSTOM_DDO_QNR_FORMAT_THEME, "", true)) {

        returnObj.value = JSON.parse(metadataValue(templateObj, CUSTOM_DDO_QNR_FORMAT_THEME, "", "", true));

        returnObj.exists = true;

        switch (metadataTag) {
            case CUSTOM_DDO_DEFAULTS:
                if (returnObj.value.defaultText === "custom") {
                    returnObj.value = returnObj.value.defaultTextCustomObj;
                }
                else {
                    returnObj.value = "";
                }
                break;
            case CUSTOM_DDO_BUTTONS_RAISED:
                if (returnObj.value.defaultButton1 === "custom") {
                    returnObj.value = returnObj.value.defaultButton1CustomObj;
                }
                else {
                    returnObj.value = "";
                }
                break;
            case CUSTOM_DDO_BUTTONS_PLAIN:
                if (returnObj.value.defaultButton2 === "custom") {
                    returnObj.value = returnObj.value.defaultButton2CustomObj;
                }
                else {
                    returnObj.value = "";
                }
                break;

            // no default
        }

    }
    else {
        returnObj.exists = false;
        returnObj.value = "";
    }
}


export function checkMetadataQuestionnaireValueObject(templateObj, returnObj, metadataTag, customTag) {

    returnObj.tag = customTag;

    let tempExists = false;
    let tempValue = "";

    if (metadataExists(templateObj, returnObj.tag, "", true)) {
        // Get the value object

        returnObj.value = metadataValue(templateObj, returnObj.tag, "", "", true);
        returnObj.value = JSON.parse((returnObj.value === "") ? "{}" : returnObj.value);

        switch (metadataTag) {

            // --------------------------------------------------------------------------------------------------- Theme

            case USE_SECONDARY_THEME:
                tempExists = returnObj.value.theme === "secondary";
                tempValue = "";
                break;

            // -------------------------------------------------------------------------------------- Application Header

            case APPBAR:
                tempExists = true;
                tempValue = "";
                break;
            case APPBAR_TITLE_LEFT:
                tempExists = returnObj.value.textLeftType === "text" && returnObj.value.textLeft !== ""
                tempValue = returnObj.value.textLeftFormat === "default" ? returnObj.value.textLeft : returnObj.value.textLeftCustomObj;
                if (returnObj.value.textLeftFormat === "custom") {
                    tempValue.title = returnObj.value.textLeft;
                }
                break;
            case APPBAR_TITLE_CENTER:
                tempExists = returnObj.value.textCenterType === "text" && returnObj.value.textCenter !== ""
                tempValue = returnObj.value.textCenterFormat === "default" ? returnObj.value.textCenter : returnObj.value.textCenterCustomObj;
                if (returnObj.value.textCenterFormat === "custom") {
                    tempValue.title = returnObj.value.textCenter;
                }
                break;
            case APPBAR_TITLE_RIGHT:
                tempExists = returnObj.value.textRightType === "text" && returnObj.value.textRight !== ""
                tempValue = returnObj.value.textRightFormat === "default" ? returnObj.value.textRight : returnObj.value.textRightCustomObj;
                if (returnObj.value.textRightFormat === "custom") {
                    tempValue.title = returnObj.value.textRight;
                }
                break;
            // case APPBAR_IMAGE_LEFT:
            //     tempExists = returnObj.value.textLeftType === "image";
            //     tempValue = "";
            //     break;
            // case APPBAR_IMAGE_CENTER:
            //     tempExists = returnObj.value.textCenterType === "image";
            //     tempValue = "";
            //     break;
            // case APPBAR_IMAGE_RIGHT:
            //     tempExists = returnObj.value.textRightType === "image";
            //     tempValue = "";
            //     break;

            // ---------------------------------------------------------------------------------------------- Start Page

            case HAS_START_PAGE:
                tempExists = true;
                tempValue = "";
                break;


            // ---------------------------------------------------------------------------------------------- Main Pages

            case FORMAT_FULL_PAGE:
                tempExists = returnObj.value.pagingMode === "full";
                tempValue = "";
                break;
            case FORMAT_ONE_QUESTION_PER_PAGE:
                tempExists = returnObj.value.pagingMode === "one";
                tempValue = "";
                break;
            case RESTART_QUESTION_NUMBERS_EACH_PAGE:
                tempExists = returnObj.value.questionNumbering === "page";
                tempValue = "";
                break;
            case SHOW_QUESTION_NUMBERS:
                tempExists = returnObj.value.questionNumbering === "all" || returnObj.value.questionNumbering === "page";
                tempValue = returnObj.value.questionNums !== undefined ? returnObj.value.questionNums.substr(1) : "";
                break;
            case SUBNUMBER_LIKERT_QUESTIONS:
                tempExists = returnObj.value.questionNumbering === "all" || returnObj.value.questionNumbering === "page";
                tempValue = returnObj.value.questionNumsLikert;
                break;
            case SUBNUMBER_SHOWGROUP_QUESTIONS:
                tempExists = returnObj.value.questionNumbering === "all" || returnObj.value.questionNumbering === "page";
                tempValue = returnObj.value.questionNumsShowGroup;
                break;
            case PAGE_HEADER:
                tempExists = (returnObj.value.pageHeader !== "no" &&
                    (returnObj.value.pageHeaderTextLeft !== "" ||
                        returnObj.value.pageHeaderTextCenter !== "" ||
                        returnObj.value.pageHeaderTextRight !== ""))
                tempValue = tempExists ? returnObj.value : "";
                break;
            case PAGE_HEADER_LEFT:
                tempExists = (returnObj.value.pageHeader !== "no" && returnObj.value.pageHeaderTextLeft !== "");
                tempValue = returnObj.value.pageHeaderTextLeftFormat === "default" ? returnObj.value.pageHeaderTextLeft : returnObj.value.pageHeaderTextLeftCustomObj;
                if (returnObj.value.pageHeaderTextLeftFormat === "custom") {
                    tempValue.title = returnObj.value.pageHeaderTextLeft;
                }
                break;
            case PAGE_HEADER_CENTER:
                tempExists = (returnObj.value.pageHeader !== "no" && returnObj.value.pageHeaderTextCenter !== "");
                tempValue = returnObj.value.pageHeaderTextCenterFormat === "default" ? returnObj.value.pageHeaderTextCenter : returnObj.value.pageHeaderTextCenterCustomObj;
                if (returnObj.value.pageHeaderTextCenterFormat === "custom") {
                    tempValue.title = returnObj.value.pageHeaderTextCenter;
                }
                break;
            case PAGE_HEADER_RIGHT:
                tempExists = (returnObj.value.pageHeader !== "no" && returnObj.value.pageHeaderTextRight !== "");
                tempValue = returnObj.value.pageHeaderTextRightFormat === "default" ? returnObj.value.pageHeaderTextRight : returnObj.value.pageHeaderTextRightCustomObj;
                if (returnObj.value.pageHeaderTextRightFormat === "custom") {
                    tempValue.title = returnObj.value.pageHeaderTextRight;
                }
                break;
            case PAGE_FOOTER:
                tempExists = (returnObj.value.pageFooter !== "no" &&
                    (returnObj.value.pageFooterTextLeft !== "" ||
                        returnObj.value.pageFooterTextCenter !== "" ||
                        returnObj.value.pageFooterTextRight !== ""))
                tempValue = tempExists ? returnObj.value : "";
                break;
            case PAGE_FOOTER_LEFT:
                tempExists = (returnObj.value.pageFooter !== "no" && returnObj.value.pageFooterTextLeft !== "");
                tempValue = returnObj.value.pageFooterTextLeftFormat === "default" ? returnObj.value.pageFooterTextLeft : returnObj.value.pageFooterTextLeftCustomObj;
                if (returnObj.value.pageFooterTextLeftFormat === "custom") {
                    tempValue.title = returnObj.value.pageFooterTextLeft;
                }
                break;
            case PAGE_FOOTER_CENTER:
                tempExists = (returnObj.value.pageFooter !== "no" && returnObj.value.pageFooterTextCenter !== "");
                tempValue = returnObj.value.pageFooterTextCenterFormat === "default" ? returnObj.value.pageFooterTextCenter : returnObj.value.pageFooterTextCenterCustomObj;
                if (returnObj.value.pageFooterTextCenterFormat === "custom") {
                    tempValue.title = returnObj.value.pageFooterTextCenter;
                }
                break;
            case PAGE_FOOTER_RIGHT:
                tempExists = (returnObj.value.pageFooter !== "no" && returnObj.value.pageFooterTextRight !== "");
                tempValue = returnObj.value.pageFooterTextRightFormat === "default" ? returnObj.value.pageFooterTextRight : returnObj.value.pageFooterTextRightCustomObj;
                if (returnObj.value.pageFooterTextRightFormat === "custom") {
                    tempValue.title = returnObj.value.pageFooterTextRight;
                }
                break;
            case PAGE_FOOTER2:
                tempExists = (returnObj.value.pageFooter2 !== "no" &&
                    (returnObj.value.pageFooter2TextLeft !== "" ||
                        returnObj.value.pageFooter2TextCenter !== "" ||
                        returnObj.value.pageFooter2TextRight !== ""))
                tempValue = tempExists ? returnObj.value : "";
                break;
            case PAGE_FOOTER_LEFT2:
                tempExists = (returnObj.value.pageFooter2 !== "no" && returnObj.value.pageFooter2TextLeft !== "");
                tempValue = returnObj.value.pageFooter2TextLeftFormat === "default" ? returnObj.value.pageFooter2TextLeft : returnObj.value.pageFooter2TextLeftCustomObj;
                if (returnObj.value.pageFooter2TextLeftFormat === "custom") {
                    tempValue.title = returnObj.value.pageFooter2TextLeft;
                }
                break;
            case PAGE_FOOTER_CENTER2:
                tempExists = (returnObj.value.pageFooter2 !== "no" && returnObj.value.pageFooter2TextCenter !== "");
                tempValue = returnObj.value.pageFooter2TextCenterFormat === "default" ? returnObj.value.pageFooter2TextCenter : returnObj.value.pageFooter2TextCenterCustomObj;
                if (returnObj.value.pageFooter2TextCenterFormat === "custom") {
                    tempValue.title = returnObj.value.pageFooter2TextCenter;
                }
                break;
            case PAGE_FOOTER_RIGHT2:
                tempExists = (returnObj.value.pageFooter2 !== "no" && returnObj.value.pageFooter2TextRight !== "");
                tempValue = returnObj.value.pageFooter2TextRightFormat === "default" ? returnObj.value.pageFooter2TextRight : returnObj.value.pageFooter2TextRightCustomObj;
                if (returnObj.value.pageFooter2TextRightFormat === "custom") {
                    tempValue.title = returnObj.value.pageFooter2TextRight;
                }
                break;

            // ---------------------------------------------------------------------------------------------- Close Page

            case NO_CLOSE_PAGE:
                tempExists = true;
                tempValue = "";
                break;
            case CLOSE_PAGE_NO_GREEN_CHECK_ICON:
                tempExists = returnObj.value.image === "none";
                tempValue = "";
                break;
            case CLOSE_PAGE_NO_DEFAULT_TEXT:
                tempExists = returnObj.value.text === "none";
                tempValue = "";
                break;

            // -------------------------------------------------------------------------------------------------- Alerts

            case ALERT_TEXT_TYPE:
                tempExists = true;
                tempValue = "";
                break;
            case ALERT_TEXT_INVALID_KEY:
                tempExists = returnObj.value.text === "none";
                tempValue = returnObj.value.invalidKeyText;
                break;
            case ALERT_TEXT_COMPLEATED:
                tempExists = returnObj.value.text === "none";
                tempValue = returnObj.value.compleatedText;
                break;
            case ALERT_TEXT_DEACTIVATED:
                tempExists = returnObj.value.text === "none";
                tempValue = returnObj.value.deactivatedText;
                break;
            case ALERT_TEXT_EXPIRED:
                tempExists = returnObj.value.text === "none";
                tempValue = returnObj.value.expiredText;
                break;
            case ALERT_TEXT_CANCELED:
                tempExists = returnObj.value.text === "none";
                tempValue = returnObj.value.canceledText;
                break;
            case CANCEL_TEXT_OVERRIDE:
                tempExists = returnObj.value.text === "none";
                tempValue = "";
                break;

            // no default
        }

        returnObj.exists = tempExists ? tempExists : returnObj.exists;
        returnObj.value = tempExists ? tempValue : returnObj.value;
    }
    else {

        // if does not exist may be a default
        switch (metadataTag) {

            case FORMAT_FULL_PAGE:
                tempExists = true;
                tempValue = "";
                break;

            // no default
        }


        returnObj.exists = tempExists ? tempExists : returnObj.exists;
        returnObj.value = tempExists ? tempValue : returnObj.value;
    }
}


export function checkMetadataCategoryValueObject(templateObj, returnObj, metadataTag, customTag) {

    returnObj.tag = customTag;

    let tempExists = false;
    let tempValue = "";

    if (metadataExists(templateObj, returnObj.tag, "", true)) {
        // Get the value object

        returnObj.value = metadataValue(templateObj, returnObj.tag, "", "", true);
        returnObj.value = JSON.parse((returnObj.value === "") ? "{}" : returnObj.value);

        switch (metadataTag) {

            case NEW_PAGE:
                tempExists = (returnObj.value.portalPageBreak);
                tempValue = "";
                break;
            case CATEGORY_TITLE_BAR:
                tempExists = (returnObj.value.titlebar);
                tempValue = "";
                break;
            case CATEGORY_TITLE_BAR_LEFT:
            case CATEGORY_TITLE_BAR_CENTER:
            case CATEGORY_TITLE_BAR_RIGHT:
                tempExists = (returnObj.value.title !== ""); ///YYYYYY
                tempValue = returnObj.value.title;
                break;

            // no default
        }

        returnObj.exists = tempExists ? tempExists : returnObj.exists;
        returnObj.value = tempExists ? tempValue : returnObj.value;
    }
}


export function checkMetadataQuestionTextValueObject(templateObj, returnObj, metadataTag) {

    returnObj.tag = CUSTOM_DDO_QUE_FORMAT;

    let tempExists = false;
    let tempValue = "";

    if (metadataExists(templateObj, returnObj.tag, "", true)) {
        // Get the value object

        returnObj.value = metadataValue(templateObj, returnObj.tag, "", "", true);
        returnObj.value = JSON.parse((returnObj.value === "") ? "{}" : returnObj.value);

        switch (metadataTag) {

            case TEXT_FLOATING_LABEL:
                tempExists = (returnObj.value.floatingLabel !== "");
                tempValue = tempExists ? returnObj.value.floatingLabel : "";
                break;
            case TEXT_PLACEHOLDER:
                tempExists = (returnObj.value.placeHolder !== "");
                tempValue = tempExists ? returnObj.value.placeHolder : "";
                break;
            case TEXT_VARIANT:
                tempExists = (returnObj.value.style !== "");
                tempValue = tempExists ? returnObj.value.style : "";
                break;
            case TEXT_SIZE:
                tempExists = (returnObj.value.size !== "");
                tempValue = tempExists ? returnObj.value.size : "";
                break;
            case TEXT_WIDTH:
                tempExists = (returnObj.value.width !== "");
                tempValue = tempExists ? returnObj.value.width : "";
                break;
            case TEXT_MULTILINE:
                tempExists = (returnObj.value.multiline);
                break;
            case TEXT_MAXLENGTH:
                tempExists = (returnObj.value.max !== "");
                tempValue = tempExists ? returnObj.value.max : "";
                break;
            case TEXT_MAXROW:
                tempExists = (returnObj.value.maxRows !== "");
                tempValue = tempExists ? returnObj.value.maxRows : "";
                break;
            case TEXT_LABEL_ABOVE:
                tempExists = (returnObj.value.labelPosition === "above");
                tempValue = tempExists ? returnObj.value.label : "";
                break;
            case TEXT_LABEL_BELOW:
                tempExists = (returnObj.value.labelPosition === "below");
                tempValue = tempExists ? returnObj.value.label : "";
                break;
            // ---------------------------------------------------------------------------------------------------------
            case TEXT_FLOATING_LABEL2:
                tempExists = (returnObj.value.floatingLabel2 !== "");
                tempValue = tempExists ? returnObj.value.floatingLabel2 : "";
                break;
            case TEXT_PLACEHOLDER2:
                tempExists = (returnObj.value.placeHolder2 !== "");
                tempValue = tempExists ? returnObj.value.placeHolder2 : "";
                break;
            case TEXT_VARIANT2:
                tempExists = (returnObj.value.style2 !== "");
                tempValue = tempExists ? returnObj.value.style2 : "";
                break;
            case TEXT_SIZE2:
                tempExists = (returnObj.value.size2 !== "");
                tempValue = tempExists ? returnObj.value.size2 : "";
                break;
            case TEXT_WIDTH2:
                tempExists = (returnObj.value.width2 !== "");
                tempValue = tempExists ? returnObj.value.width2 : "";
                break;
            case TEXT_MAXLENGTH2:
                tempExists = (returnObj.value.max2 !== "");
                tempValue = tempExists ? returnObj.value.max2 : "";
                break;
            case TEXT_LABEL_ABOVE2:
                tempExists = (returnObj.value.labelPosition2 === "above");
                tempValue = tempExists ? returnObj.value.label2 : "";
                break;
            case TEXT_LABEL_BELOW2:
                tempExists = (returnObj.value.labelPosition2 === "below");
                tempValue = tempExists ? returnObj.value.label2 : "";
                break;
            // ---------------------------------------------------------------------------------------------------------
            case TEXT_FLOATING_LABEL3:
                tempExists = (returnObj.value.floatingLabel3 !== "");
                tempValue = tempExists ? returnObj.value.floatingLabel3 : "";
                break;
            case TEXT_PLACEHOLDER3:
                tempExists = (returnObj.value.placeHolder3 !== "");
                tempValue = tempExists ? returnObj.value.placeHolder3 : "";
                break;
            case TEXT_VARIANT3:
                tempExists = (returnObj.value.style3 !== "");
                tempValue = tempExists ? returnObj.value.style3 : "";
                break;
            case TEXT_SIZE3:
                tempExists = (returnObj.value.size3 !== "");
                tempValue = tempExists ? returnObj.value.size3 : "";
                break;
            case TEXT_WIDTH3:
                tempExists = (returnObj.value.width3 !== "");
                tempValue = tempExists ? returnObj.value.width3 : "";
                break;
            case TEXT_MAXLENGTH3:
                tempExists = (returnObj.value.max3 !== "");
                tempValue = tempExists ? returnObj.value.max3 : "";
                break;
            case TEXT_LABEL_ABOVE3:
                tempExists = (returnObj.value.labelPosition3 === "above");
                tempValue = tempExists ? returnObj.value.label3 : "";
                break;
            case TEXT_LABEL_BELOW3:
                tempExists = (returnObj.value.labelPosition3 === "below");
                tempValue = tempExists ? returnObj.value.label3 : "";
                break;
            // ---------------------------------------------------------------------------------------------------------
            case TEXT_FLOATING_LABEL4:
                tempExists = (returnObj.value.floatingLabel4 !== "");
                tempValue = tempExists ? returnObj.value.floatingLabel4 : "";
                break;
            case TEXT_PLACEHOLDER4:
                tempExists = (returnObj.value.placeHolder4 !== "");
                tempValue = tempExists ? returnObj.value.placeHolder4 : "";
                break;
            case TEXT_VARIANT4:
                tempExists = (returnObj.value.style4 !== "");
                tempValue = tempExists ? returnObj.value.style4 : "";
                break;
            case TEXT_SIZE4:
                tempExists = (returnObj.value.size4 !== "");
                tempValue = tempExists ? returnObj.value.size4 : "";
                break;
            case TEXT_WIDTH4:
                tempExists = (returnObj.value.width4 !== "");
                tempValue = tempExists ? returnObj.value.width4 : "";
                break;
            case TEXT_MAXLENGTH4:
                tempExists = (returnObj.value.max4 !== "");
                tempValue = tempExists ? returnObj.value.max4 : "";
                break;
            case TEXT_LABEL_ABOVE4:
                tempExists = (returnObj.value.labelPosition4 === "above");
                tempValue = tempExists ? returnObj.value.label4 : "";
                break;
            case TEXT_LABEL_BELOW4:
                tempExists = (returnObj.value.labelPosition4 === "below");
                tempValue = tempExists ? returnObj.value.label4 : "";
                break;
            // ---------------------------------------------------------------------------------------------------------
            case TEXT_FLOATING_LABEL5:
                tempExists = (returnObj.value.floatingLabel5 !== "");
                tempValue = tempExists ? returnObj.value.floatingLabel5 : "";
                break;
            case TEXT_PLACEHOLDER5:
                tempExists = (returnObj.value.placeHolder5 !== "");
                tempValue = tempExists ? returnObj.value.placeHolder5 : "";
                break;
            case TEXT_VARIANT5:
                tempExists = (returnObj.value.style5 !== "");
                tempValue = tempExists ? returnObj.value.style5 : "";
                break;
            case TEXT_SIZE5:
                tempExists = (returnObj.value.size5 !== "");
                tempValue = tempExists ? returnObj.value.size5 : "";
                break;
            case TEXT_WIDTH5:
                tempExists = (returnObj.value.width5 !== "");
                tempValue = tempExists ? returnObj.value.width5 : "";
                break;
            case TEXT_MAXLENGTH5:
                tempExists = (returnObj.value.max5 !== "");
                tempValue = tempExists ? returnObj.value.max5 : "";
                break;
            case TEXT_LABEL_ABOVE5:
                tempExists = (returnObj.value.labelPosition5 === "above");
                tempValue = tempExists ? returnObj.value.label5 : "";
                break;
            case TEXT_LABEL_BELOW5:
                tempExists = (returnObj.value.labelPosition5 === "below");
                tempValue = tempExists ? returnObj.value.label5 : "";
                break;

            // ---------------------------------------------------------------------------------------------------------
            case DISPLAY_DATE_RANGE_AS_SIDE_BY_SIDE:
                tempExists = (returnObj.value.arrange === "side");
                tempValue = tempExists ? returnObj.value.arrange : "";
                break;
            case DISPLAY_DATE_RANGE_AS_ONE_PER_ROW:
                tempExists = (returnObj.value.arrange === "row");
                tempValue = tempExists ? returnObj.value.arrange : "";
                break;

            // no default
        }

        returnObj.exists = tempExists ? tempExists : returnObj.exists;
        returnObj.value = tempExists ? tempValue : returnObj.value;

    }
}


export function checkMetadataQuestionRadioValueObject(templateObj, returnObj, metadataTag) {

    returnObj.tag = CUSTOM_DDO_QUE_FORMAT;

    let tempExists = false;
    let tempValue = "";

    if (metadataExists(templateObj, returnObj.tag, "", true)) {
        // Get the value object

        returnObj.value = metadataValue(templateObj, returnObj.tag, "", "", true);
        returnObj.value = JSON.parse((returnObj.value === "") ? "{}" : returnObj.value);

        switch (metadataTag) {
            case DISPLAY_SINGLE_SELECT_AS_LIKERT:
                tempExists = (returnObj.value.displayAs==="likert");
                break;

            case DISPLAY_SINGLE_SELECT_AS_RADIOS:
                tempExists = (returnObj.value.displayAs==="single")&&(returnObj.value.style === "radios");
                break;
            case DISPLAY_SINGLE_SELECT_AS_OUTLINED_RADIOS:
                tempExists = (returnObj.value.displayAs==="single")&&(returnObj.value.style === "outlined");
                break;
            case DISPLAY_SINGLE_SELECT_AS_DDLB:
                tempExists = (returnObj.value.displayAs==="single")&&(returnObj.value.style === "ddlb");
                break;
            case DISPLAY_SINGLE_SELECT_AS_STANDARD_BUTTONS:
                tempExists = (returnObj.value.displayAs==="single")&&(returnObj.value.style === "standard");
                break;
            case DISPLAY_SINGLE_SELECT_AS_CLEAR_BUTTONS:
                tempExists = (returnObj.value.displayAs==="single")&&(returnObj.value.style === "clear");
                break;
            case DISPLAY_SINGLE_SELECT_AS_BUTTONGROUP:
                tempExists = (returnObj.value.displayAs==="likert")&&(returnObj.value.likertStyle === "buttongroup");
                break;
//debugger; // XXXXXYYYYYZZZZZ
            // case DISPLAY_BUTTONS_AS_CIRCLES:
            //     tempExists = (returnObj.value.displayAs==="likert")&&(returnObj.value.likertStyle === "circle");
            //     break;
            // case DISPLAY_BUTTONS_AS_CIRCLES_COLORS:
            //     tempExists = (returnObj.value.displayAs==="likert")&&(returnObj.value.likertStyle === "circle")&&(returnObj.value.circleType === "Multi-colored Standard");
            //     break;
            // case DISPLAY_BUTTONS_AS_CIRCLES_COLORS_ALT:
            //     tempExists = (returnObj.value.displayAs==="likert")&&(returnObj.value.likertStyle === "circle")&&(returnObj.value.circleType === "Multi-colored Alternate");
            //     break;
            //
            // case DISPLAY_BUTTONS_AS_CIRCLES_PYRAMID:
            //     tempExists = (returnObj.value.displayAs==="likert")&&(returnObj.value.likertStyle === "pyramid");
            //     break;
            // case DISPLAY_BUTTONS_AS_CIRCLES_PYRAMID_COLORS:
            //     tempExists = (returnObj.value.displayAs==="likert")&&(returnObj.value.likertStyle === "pyramid")&&(returnObj.value.circleType === "Multi-colored Standard");
            //     break;
            // case DISPLAY_BUTTONS_AS_CIRCLES_PYRAMID_COLORS_ALT:
            //     tempExists = (returnObj.value.displayAs==="likert")&&(returnObj.value.likertStyle === "pyramid")&&(returnObj.value.circleType === "Multi-colored Alternate");
            //     break;

            case DISPLAY_SINGLE_SELECT_AS_SIDE_BY_SIDE_WRAP:
                tempExists = (returnObj.value.arrange === "wrap");
                break;
            case DISPLAY_SINGLE_SELECT_AS_SIDE_BY_SIDE:
                tempExists = (returnObj.value.arrange === "side");
                break;
            case DISPLAY_SINGLE_SELECT_AS_ONE_PER_ROW:
                tempExists = (returnObj.value.arrange === "row");
                break;

            case INDENT_NAME:
                tempExists = ((returnObj.value.displayAs==="likert")||(returnObj.value.questionType==="LIKERT_RADIO")) && (returnObj.value.indentEntry !== "");
                tempValue = tempExists ? returnObj.value.indent : "";
                break;

            case ALIGN_QUESTION_ANSWER_RIGHT:
                tempExists = (returnObj.value.displayAs==="likert")&&(returnObj.value.alignRight === true);
                break;

            case ALIGN_ANSWERS_WITH_QUESTION:
                tempExists = returnObj.value.alignQuestionsAndAnswers;
                break;

            case LIKERT_QUESTION_ALIGN_WITH_ANSWERS:
                tempExists = (returnObj.value.displayAs==="likert")&&(returnObj.value.alignQuestionsAndAnswers === true);
                break;

            case LIKERT_HEADER_TITLE_LEFT:
                tempExists = (returnObj.value.displayAs==="likert")&&(returnObj.value.titleLeft !== "");
                tempValue = tempExists ? returnObj.value.titleLeft : "";
                break;
            case LIKERT_HEADER_TITLE_CENTER:
                tempExists = (returnObj.value.displayAs==="likert")&&(returnObj.value.titleCenter !== "");
                tempValue = tempExists ? returnObj.value.titleCenter : "";
                break;
            case LIKERT_HEADER_TITLE_RIGHT:
                tempExists = (returnObj.value.displayAs==="likert")&&(returnObj.value.titleRight !== "");
                tempValue = tempExists ? returnObj.value.titleRight : "";
                break;

            case USE_LIKERT_GRAYBAR_HEADER:
                tempExists = (returnObj.value.displayAs==="likert")&&(returnObj.value.titleType === "graybar");
                break;

            case USE_LIKERT_STANDARD_HEADER:
                tempExists = (returnObj.value.displayAs==="likert")&&(returnObj.value.titleType === "standard");
                break;

            case USE_LIKERT_RESPONSIVE_LABEL:
                tempExists = (returnObj.value.displayAs==="likert")&&(returnObj.value.showSelectedLabel === "show");
                break;

            case USE_LIKERT_DEFAULT_RESPONSIVE_LABEL:
                tempExists = (returnObj.value.displayAs==="likert")&&(returnObj.value.showSelectedLabel === "show");
                break;

            // no default
        }

        returnObj.exists = tempExists ? tempExists : returnObj.exists;
        returnObj.value = tempExists ? tempValue : returnObj.value;

    }
}


export function checkMetadataQuestionCheckboxValueObject(templateObj, returnObj, metadataTag) {

    returnObj.tag = CUSTOM_DDO_QUE_FORMAT;

    let tempExists = false;
    let tempValue = "";

    if (metadataExists(templateObj, returnObj.tag, "", true)) {
        // Get the value object

        returnObj.value = metadataValue(templateObj, returnObj.tag, "", "", true);
        returnObj.value = JSON.parse((returnObj.value === "") ? "{}" : returnObj.value);

        switch (metadataTag) {
            case DISPLAY_CHECKBOXES_AS_OUTLINED:
                tempExists = (returnObj.value.style==="outlined");
                break;

            // no default
        }

        returnObj.exists = tempExists ? tempExists : returnObj.exists;
        returnObj.value = tempExists ? tempValue : returnObj.value;

    }
}


export function checkMetadataQuestionOutlinedValueObject(templateObj, returnObj, metadataTag) {

    returnObj.tag = CUSTOM_DDO_QUE_FORMAT;

    let tempExists = false;
    let tempValue = "";

    if (metadataExists(templateObj, returnObj.tag, "", true)) {
        // Get the value object

        returnObj.value = metadataValue(templateObj, returnObj.tag, "", "", true);
        returnObj.value = JSON.parse((returnObj.value === "") ? "{}" : returnObj.value);

        switch (metadataTag) {
            case SELECTED_COLOR_BORDER:
                if (returnObj.value.selectedBorder !== undefined) {
                    tempExists = (returnObj.value.selectedBorder.colorType !== "default");
                    tempValue = (returnObj.value.selectedBorder.colorType !== "select") ? returnObj.value.selectedBorder.colorEntry : returnObj.value.selectedBorder.color;
                }
                break;
            case SELECTED_COLOR_BACKGROUND:
                if (returnObj.value.selectedBackground !== undefined) {
                    tempExists = (returnObj.value.selectedBackground.colorType !== "default");
                    tempValue = (returnObj.value.selectedBackground.colorType !== "select") ? returnObj.value.selectedBackground.colorEntry : returnObj.value.selectedBackground.color;
                }
                break;
            case SELECTED_COLOR_CONTROL:
                if (returnObj.value.selectedControl !== undefined) {
                    tempExists = (returnObj.value.selectedControl.colorType !== "default");
                    tempValue = (returnObj.value.selectedControl.colorType !== "select") ? returnObj.value.selectedControl.colorEntry : returnObj.value.selectedControl.color;
                }
                break;
            case SELECTED_COLOR_TEXT:
                if (returnObj.value.selectedText !== undefined) {
                    tempExists = (returnObj.value.selectedText.colorType !== "default");
                    tempValue = (returnObj.value.selectedText.colorType !== "select") ? returnObj.value.selectedText.colorEntry : returnObj.value.selectedText.color;
                }
                break;
            case UNSELECTED_COLOR_BORDER:
                if (returnObj.value.unselectedBorder !== undefined) {
                    tempExists = (returnObj.value.unselectedBorder.colorType !== "default");
                    tempValue = (returnObj.value.unselectedBorder.colorType !== "select") ? returnObj.value.unselectedBorder.colorEntry : returnObj.value.unselectedBorder.color;
                }
                break;
            case UNSELECTED_COLOR_BACKGROUND:
                if (returnObj.value.unselectedBackground !== undefined) {
                    tempExists = (returnObj.value.unselectedBackground.colorType !== "default");
                    tempValue = (returnObj.value.unselectedBackground.colorType !== "select") ? returnObj.value.unselectedBackground.colorEntry : returnObj.value.unselectedBackground.color;
                }
                break;
            case UNSELECTED_COLOR_CONTROL:
                if (returnObj.value.unselectedControl !== undefined) {
                    tempExists = (returnObj.value.unselectedControl.colorType !== "default");
                    tempValue = (returnObj.value.unselectedControl.colorType !== "select") ? returnObj.value.unselectedControl.colorEntry : returnObj.value.unselectedControl.color;
                }
                break;
            case UNSELECTED_COLOR_TEXT:
                if (returnObj.value.unselectedText !== undefined) {
                    tempExists = (returnObj.value.unselectedText.colorType !== "default");
                    tempValue = (returnObj.value.unselectedText.colorType !== "select") ? returnObj.value.unselectedText.colorEntry : returnObj.value.unselectedText.color;
                }
                break;
            // no default
        }

        returnObj.exists = tempExists ? tempExists : returnObj.exists;
        returnObj.value = tempExists ? tempValue : returnObj.value;

    }
}


// tempObj = {
//     questionType: this.props.questionType,
//     displayAs: "single",        // single, likert
//     style: "radios",            // radios, ddlb, standard, clear, outlined
//     likertStyle: "radios",      // radios, buttongroup, circle, pyramid
//     circleType: "standard",     // standard, primary, alternate
//     arrange: "",                // row, side, wrap
//     state: "",
//     property: "",
//     color: "default",           // default, custom
//     selectedBackground: "",
//     selectedControl: "",
//     selectedText: "",
//     unselectedBackground: "",
//     unselectedControl: "",
//     unselectedText: ""
// };




// returnObj.tag = CUSTOM_DDO_QUE_FORMAT;
// if (metadataExists(templateObj, returnObj.tag, "", true)) {
//     returnObj.value = metadataValue(templateObj, returnObj.tag, "", "", true);
//     returnObj.value = JSON.parse((returnObj.value === "") ? "{}" : returnObj.value);
//     if (returnObj.value.questionType === "") {
//         if (returnObj.value.zzzz === "radios") {
//             returnObj.exists = true;
//         }
//     }
// }



// export const YES_NO = "YES_NO";
// export const CHECKBOX = "CHECKBOX";
// export const RADIO = "RADIO";
// export const LIKERT_CHECKBOX = "LIKERT_CHECKBOX";
// export const LIKERT_RADIO = "LIKERT_RADIO";
// export const DYNAMIC_CHECKBOX = "DYNAMIC_CHECKBOX";
// export const DYNAMIC_RADIO = "DYNAMIC_RADIO";
// export const NUMBER = "NUMBER";
// export const CURRENCY = "CURRENCY";
// export const PHONE = "PHONE";
// export const DATE = "DATE";
// export const DATE_RANGE = "DATE_RANGE";
// export const EMAIL = "EMAIL";
// export const FREE_TEXT = "FREE_TEXT";
// export const IMAGE = "IMAGE";
// export const INFO = "INFO";
// export const MAP = "MAP";
// export const ADDRESS_FULL = "ADDRESS_FULL";
// export const ZIPPOSTAL_ONLY = "ZIPPOSTAL_ONLY";
// export const STATE_ONLY = "STATE_ONLY";
// export const LIKERT = "LIKERT";
