import React, {Component} from "react";
import PropTypes from "prop-types";
import ArgoColorInput from "./ArgoColorInput";
import ArgoTextDisplayDefination from "../common/ArgoTextDisplayDefination";
import ArgoTextDisplayCustom from "../common/ArgoTextDisplayCustom";
import ArgoCheckboxHook from "./ArgoCheckboxHook";
import ArgoTextFieldHook from "./ArgoTextFieldHook";
import ArgoTextFieldSelectHook from "./ArgoTextFieldSelectHook";
import ArgoLikertAnswerGenerator from "./ArgoLikertAnswerGenerator";
import * as portal from "../../utilities/portal";
import * as questionTypes from "../../constants/questionTypeConstants";
import * as TEMPLATE from "../../constants/templateConstants";
import Checkbox from "@mui/material/Checkbox";
import TextField from "@mui/material/TextField";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import ArgoQuestionFormatCustomEntry from "./ArgoQuestionFormatCustomEntry";
//import * as icon from "../../constants/iconConstants";


const customColorTitles = [
    "Deselected Text",
    "Deselected Control",
    "Deselected Border",
    "Deselected Background",
    "Selected Text",
    "Selected Control",
    "Selected Border",
    "Selected Background"
];

const customColorProps = [
    "unselectedText",
    "unselectedControl",
    "unselectedBorder",
    "unselectedBackground",
    "selectedText",
    "selectedControl",
    "selectedBorder",
    "selectedBackground",
];

const customColorTitlesNoControl = [
    "Deselected Text",
    "Deselected Border",
    "Deselected Background",
    "Selected Text",
    "Selected Border",
    "Selected Background"
];

const customColorPropsNoControl = [
    "unselectedText",
    "unselectedBorder",
    "unselectedBackground",
    "selectedText",
    "selectedBorder",
    "selectedBackground",
];

const styleList = [
    {value: "outlined", text: "Outlined"},
    {value: "standard", text: "Standard"},
    {value: "filled", text: "Filled"}
];

const sizeList = [
    {value: "small", text: "Small"},
    {value: "medium", text: "Medium"}
];

const widthFormatList = [
    {value: "percentage", text: "Percentage"},
    {value: "pixels", text: "Pixels"}
];

const labelPositionList = [
    {value: "none", text: "None"},
    {value: "above", text: "Above"},
    {value: "below", text: "Below"}
];

const arrangeList = [
    {value: "row", text: "One per Row"},
    {value: "side", text: "Side by Side"}
];

const colorTypeList = [
    {value: "default", text: "Default"},
    {value: "custom", text: "Custom"}
];

const titleTypeList = [
    {value: "none", text: "None"},
    {value: "standard", text: "Standard"},
    {value: "graybar", text: "Graybar"}
];


class ArgoQuestionFormatCustom extends Component {

    constructor(props) {
        super(props);

        this.state = {
            formatObj: props.initToObj,
            likertTitles: "none"
        };
    }


    // Display As...
    //  -Single Select
    //  -Likert

    // Single Select Entry Style
    //  -Radios  (Default depends on number of answers)
    //  -DDLB
    //  -Clear Buttons
    //  -Outlined

    // Likert
    //  -Buttongroup
    //  -Circles
    //  -Circle Pyramid

    // Circles
    //  -Standard
    //  -Colors (Primary)
    //  -Colors (Alternate)


    componentWillReceiveProps(nextProps) {

    }


    onTextChange = (event, fieldName) => {

        let value = event.target.value;

        let tempObj = {...this.state.formatObj};  // Get the state object associated with the question type

        tempObj[fieldName] = value; // set the changed value

        // Check to see if any related properties need to be updated
        switch (fieldName) {
            case "widthEntry":
                tempObj["width"] =  value + ((tempObj.widthType === "pixels") ? "px" : "%");
                break;
            case "widthEntry2":
                tempObj["width2"] =  value + ((tempObj.widthType2 === "pixels") ? "px" : "%");
                break;
            case "widthEntry3":
                tempObj["width3"] =  value + ((tempObj.widthType3 === "pixels") ? "px" : "%");
                break;
            case "widthEntry4":
                tempObj["width4"] =  value + ((tempObj.widthType4 === "pixels") ? "px" : "%");
                break;
            case "widthEntry5":
                tempObj["width5"] =  value + ((tempObj.widthType5 === "pixels") ? "px" : "%");
                break;
            case "indentEntry":
                tempObj["indent"] =  value + "px";
                break;
            // no default
        }

        this.setState({formatObj: tempObj}, () => {this.setMetadataValue(tempObj)});
    };


    onSelectChange = (event, fieldName, menuItemText) => {

        let selected = event.target.value;

        let tempObj = {...this.state.formatObj};

        tempObj[fieldName] = selected;

        switch (this.props.questionType) {
            case questionTypes.FREE_TEXT:
            case questionTypes.EMAIL:
            case questionTypes.PHONE:
            case questionTypes.NUMBER:
            case questionTypes.CURRENCY:
            case questionTypes.ADDRESS_FULL:
            case questionTypes.DATE:
            case questionTypes.DATE_RANGE:
                switch (fieldName) {
                    case "labelPosition":
                        if (selected === "none") {
                            tempObj.label = ""
                        }
                        break;
                    case "widthType":
                        tempObj["width"] =  tempObj["widthEntry"] + ((selected === "pixels") ? "px" : "%");
                        break;
                    case "widthType2":
                        tempObj["width2"] =  tempObj["widthEntry2"] + ((selected === "pixels") ? "px" : "%");
                        break;
                    case "widthType3":
                        tempObj["width3"] =  tempObj["widthEntry3"] + ((selected === "pixels") ? "px" : "%");
                        break;
                    case "widthType4":
                        tempObj["width4"] =  tempObj["widthEntry4"] + ((selected === "pixels") ? "px" : "%");
                        break;
                    case "widthType5":
                        tempObj["width5"] =  tempObj["widthEntry5"] + ((selected === "pixels") ? "px" : "%");
                        break;
                    default:
                        console.log("ArgoQuestionFormatCustom.js  Switch default E01"); // eslint-disable-line
                }
                break;
            case questionTypes.RADIO:
                switch (fieldName) {
                    case "displayAs":
                        // Single Select ie.. NOT Likert
                        if (selected === "single") {
                            // If single reset Style back to the default radios
                            tempObj.style = tempObj.style === "" ? "radios" : tempObj.style
                        }
                        else {
                            tempObj.style = ""; // If likert then style is set to ""
                        }
                        tempObj[fieldName] = selected;
                        break;
                    case "titleType":
                        if (selected === "none") {
                            tempObj.titleLeft = "";
                            tempObj.titleCenter = "";
                            tempObj.titleRight = "";
                        }
                        break;
                    default:
                        console.log("ArgoQuestionFormatCustom.js  Switch default E02"); // eslint-disable-line
                }
                break;
            default:
                console.log("ArgoQuestionFormatCustom.js  Switch default E03"); // eslint-disable-line
        }

        this.setState({formatObj: tempObj}, () => {this.setMetadataValue(tempObj)});
    };


    selectChangeTitle = (fieldName, e, index, selected) => {
        this.setState({[fieldName]: selected});
    }


    onCheckBoxChange = (event, fieldName) => {

        let checked = event.target.checked;

        let tempObj = {...this.state.formatObj};

        tempObj[fieldName] = checked;

        this.setState({formatObj: tempObj}, () => {this.setMetadataValue(tempObj)});
    };


    setColor = (fieldName, colorObj) => {

        let tempObj = {...this.state.formatObj};

        tempObj[fieldName]["backgroundColor"] = colorObj.backgroundColor;
        tempObj[fieldName]["backgroundImage"] = colorObj.backgroundImage;
        tempObj[fieldName]["colorType"] = colorObj.colorType;
        tempObj[fieldName]["colorIndex"] = colorObj.colorIndex;
        tempObj[fieldName]["colorEntry"] = colorObj.colorEntry;
        tempObj[fieldName]["color"] = colorObj.color;

        this.setState({formatObj: tempObj}, () => {this.setMetadataValue(tempObj)});
    };


    setMetadataValue = (tempObj) => {

        let mdTempObj = null;

        switch(this.props.questionType) {
            case questionTypes.FREE_TEXT:
            case questionTypes.EMAIL:
            case questionTypes.PHONE:
            case questionTypes.NUMBER:
            case questionTypes.CURRENCY:
            case questionTypes.DATE:
            case questionTypes.ZIPPOSTAL_ONLY:
                mdTempObj = {
                    questionType: this.props.questionType,
                    style: this.state.formatObj.style,
                    size: this.state.formatObj.size,
                    widthType: this.state.formatObj.widthType,
                    widthEntry: this.state.formatObj.widthEntry,
                    width: this.state.formatObj.width,
                    max: this.state.formatObj.max,
                    multiline: this.state.formatObj.multiline,
                    maxRows: this.state.formatObj.maxRows,
                    labelPosition: this.state.formatObj.labelPosition,
                    label: this.state.formatObj.label,
                    labelDisplay: this.state.formatObj.labelDisplay,
                    floatingLabel: this.state.formatObj.floatingLabel,
                    placeHolder: this.state.formatObj.placeHolder,
                };
                break;
            case questionTypes.ADDRESS_FULL:
                mdTempObj = {
                    questionType: this.props.questionType,
                    style: this.state.formatObj.style,
                    size: this.state.formatObj.size,
                    widthType: this.state.formatObj.widthType,
                    widthEntry: this.state.formatObj.widthEntry,
                    width: this.state.formatObj.width,
                    max: this.state.formatObj.max,
                    multiline: this.state.formatObj.multiline,
                    maxRows: this.state.formatObj.maxRows,
                    labelPosition: this.state.formatObj.labelPosition,
                    label: this.state.formatObj.label,
                    labelDisplay: this.state.formatObj.labelDisplay,
                    floatingLabel: this.state.formatObj.floatingLabel,
                    placeHolder: this.state.formatObj.placeHolder,

                    style2: this.state.formatObj.style2,
                    size2: this.state.formatObj.size2,
                    widthType2: this.state.formatObj.widthType2,
                    widthEntry2: this.state.formatObj.widthEntry2,
                    width2: this.state.formatObj.width2,
                    max2: this.state.formatObj.max2,
                    labelPosition2: this.state.formatObj.labelPosition2,
                    label2: this.state.formatObj.label2,
                    labelDisplay2: this.state.formatObj.labelDisplay2,
                    floatingLabel2: this.state.formatObj.floatingLabel2,
                    placeHolder2: this.state.formatObj.placeHolder2,

                    style3: this.state.formatObj.style3,
                    size3: this.state.formatObj.size3,
                    widthType3: this.state.formatObj.widthType3,
                    widthEntry3: this.state.formatObj.widthEntry3,
                    width3: this.state.formatObj.width3,
                    max3: this.state.formatObj.max3,
                    labelPosition3: this.state.formatObj.labelPosition3,
                    label3: this.state.formatObj.label3,
                    labelDisplay3: this.state.formatObj.labelDisplay3,
                    floatingLabel3: this.state.formatObj.floatingLabel3,
                    placeHolder3: this.state.formatObj.placeHolder3,

                    style4: this.state.formatObj.style4,
                    size4: this.state.formatObj.size4,
                    widthType4: this.state.formatObj.widthType4,
                    widthEntry4: this.state.formatObj.widthEntry4,
                    width4: this.state.formatObj.width4,
                    max4: this.state.formatObj.max4,
                    labelPosition4: this.state.formatObj.labelPosition4,
                    label4: this.state.formatObj.label4,
                    labelDisplay4: this.state.formatObj.labelDisplay4,
                    floatingLabel4: this.state.formatObj.floatingLabel4,
                    placeHolder4: this.state.formatObj.placeHolder4,

                    style5: this.state.formatObj.style5,
                    size5: this.state.formatObj.size5,
                    widthType5: this.state.formatObj.widthType5,
                    widthEntry5: this.state.formatObj.widthEntry5,
                    width5: this.state.formatObj.width5,
                    max5: this.state.formatObj.max5,
                    labelPosition5: this.state.formatObj.labelPosition5,
                    label5: this.state.formatObj.label5,
                    labelDisplay5: this.state.formatObj.labelDisplay5,
                    floatingLabel5: this.state.formatObj.floatingLabel5,
                    placeHolder5: this.state.formatObj.placeHolder5,
                };
                break;
            case questionTypes.STATE_ONLY:
                mdTempObj = {};
                break;
            case questionTypes.YES_NO:
            case questionTypes.RADIO:
            case questionTypes.LIKERT_RADIO:

                mdTempObj = {
                    questionType: this.props.questionType,
                    displayAs: this.state.formatObj.displayAs,
                    style: this.state.formatObj.style,
                    likertStyle: this.state.formatObj.likertStyle,
                    circleType: this.state.formatObj.circleType,
                    alignRight: this.state.formatObj.alignRight,
                    alignQuestionsAndAnswers: this.state.formatObj.alignQuestionsAndAnswers,
                    showSelectedLabel: this.state.formatObj.showSelectedLabel,
                    title: this.state.formatObj.title,
                    titleType: this.state.formatObj.titleType,
                    titleLeft: this.state.formatObj.titleLeft,
                    titleCenter: this.state.formatObj.titleCenter,
                    titleRight: this.state.formatObj.titleRight,
                    indentEntry: this.state.formatObj.indentEntry,
                    indent: this.state.formatObj.indent,
                    arrange: this.state.formatObj.arrange,
                    state: this.state.formatObj.state,
                    property: this.state.formatObj.property,
                    color: this.state.formatObj.color,
                    selectedBorder: { ...this.setColorObj(tempObj.color, this.state.formatObj.selectedBorder)},
                    selectedBackground: { ...this.setColorObj(tempObj.color, this.state.formatObj.selectedBackground)},
                    selectedControl: { ...this.setColorObj(tempObj.color, this.state.formatObj.selectedControl)},
                    selectedText: { ...this.setColorObj(tempObj.color, this.state.formatObj.selectedText)},
                    unselectedBorder: { ...this.setColorObj(tempObj.color, this.state.formatObj.unselectedBorder)},
                    unselectedBackground: { ...this.setColorObj(tempObj.color, this.state.formatObj.unselectedBackground)},
                    unselectedControl: { ...this.setColorObj(tempObj.color, this.state.formatObj.unselectedControl)},
                    unselectedText: { ...this.setColorObj(tempObj.color, this.state.formatObj.unselectedText)}
                };
                break;
            case questionTypes.CHECKBOX:
                mdTempObj = {
                    questionType: this.props.questionType,
                    style: this.state.formatObj.style,
                    arrange: this.state.formatObj.arrange,
                    color: this.state.formatObj.color,
                    selectedBorder: { ...this.setColorObj(tempObj.color, this.state.formatObj.selectedBorder)},
                    selectedBackground: { ...this.setColorObj(tempObj.color, this.state.formatObj.selectedBackground)},
                    selectedControl: { ...this.setColorObj(tempObj.color, this.state.formatObj.selectedControl)},
                    selectedText: { ...this.setColorObj(tempObj.color, this.state.formatObj.selectedText)},
                    unselectedBorder: { ...this.setColorObj(tempObj.color, this.state.formatObj.unselectedBorder)},
                    unselectedBackground: { ...this.setColorObj(tempObj.color, this.state.formatObj.unselectedBackground)},
                    unselectedControl: { ...this.setColorObj(tempObj.color, this.state.formatObj.unselectedControl)},
                    unselectedText: { ...this.setColorObj(tempObj.color, this.state.formatObj.unselectedText)}
                };
                break;
            case questionTypes.LIKERT_RADIO:
                mdTempObj = {};
                break;
            case questionTypes.DATE_RANGE:
                mdTempObj = {
                    questionType: this.props.questionType,

                    arrange: this.state.formatObj.arrange,

                    style: this.state.formatObj.style,
                    size: this.state.formatObj.size,
                    widthType: this.state.formatObj.widthType,
                    widthEntry: this.state.formatObj.widthEntry,
                    width: this.state.formatObj.width,
                    max: this.state.formatObj.max,
                    labelPosition: this.state.formatObj.labelPosition,
                    label: this.state.formatObj.label,
                    labelDisplay: this.state.formatObj.labelDisplay,
                    floatingLabel: this.state.formatObj.floatingLabel,
                    placeHolder: this.state.formatObj.placeHolder,

                    style2: this.state.formatObj.style2,
                    size2: this.state.formatObj.size2,
                    widthType2: this.state.formatObj.widthType2,
                    widthEntry2: this.state.formatObj.widthEntry2,
                    width2: this.state.formatObj.width2,
                    max2: this.state.formatObj.max2,
                    labelPosition2: this.state.formatObj.labelPosition2,
                    label2: this.state.formatObj.label2,
                    labelDisplay2: this.state.formatObj.labelDisplay2,
                    floatingLabel2: this.state.formatObj.floatingLabel2,
                    placeHolder2: this.state.formatObj.placeHolder2,
                };
                break;
            // no default
        }

        let mpo = this.props.metadataPropsObj;

        this.setState({formatObj: mdTempObj}, () => {mpo.setCustomMetadataFunction(mpo.template, mpo.templateUpdateFunction, mpo.setStateCallbackFunction, this.props.fieldName, "custom", mpo.metadataTag, mdTempObj, mpo.depth)});
    };

    setColorObj = (selectedMode, savedObj) => {

        let isDefault = (selectedMode === "default");

        let colorObj = {};

        colorObj.backgroundColor = isDefault ?  "" : savedObj.backgroundColor;
        colorObj.backgroundImage = isDefault ?  "" : savedObj.backgroundImage;
        colorObj.colorType = isDefault ?  "default" : savedObj.colorType;
        colorObj.colorIndex = isDefault ?  null : savedObj.colorIndex;
        colorObj.colorEntry = isDefault ?  "black" : savedObj.colorEntry;
        colorObj.color = isDefault ?  "black" : savedObj.color;

        return colorObj;
    }


    setLabelMetadataValue = (fieldName, selected, metadataTag, metadataValueObj) => {

        let mpo = this.props.metadataPropsObj;

        mpo.setCustomMetadataFunction(mpo.template, mpo.templateUpdateFunction, mpo.setStateCallbackFunction, this.props.fieldName, "custom", mpo.metadataTag, {}, mpo.depth); // Ankoor HERE !!!!!
    };


    formatCustomOptions = () => {

        switch(this.props.questionType) {

            case questionTypes.FREE_TEXT:
            case questionTypes.EMAIL:
            case questionTypes.PHONE:
            case questionTypes.NUMBER:
            case questionTypes.CURRENCY:
            case questionTypes.ZIPPOSTAL_ONLY:
                return (
                    <React.Fragment>

                        <div style={{display: "flex", alignItems: "left", width: '100%', backgroundColor: '#F8F8F8'}}>
                            <div style={{width: "20px"}}></div>

                            <ArgoTextFieldSelectHook
                                fileName="ArgoQuestionFormatCustom"
                                fieldName="style"
                                label="Style"
                                value={this.state.formatObj.style}
                                onChange={this.onSelectChange}
                                width="150px"
                                menuItems={styleList}
                            />

                            <div style={{width: "20px"}}></div>

                            <ArgoTextFieldSelectHook
                                fileName="ArgoQuestionFormatCustom"
                                fieldName="size"
                                label="Size"
                                value={this.state.formatObj.size}
                                onChange={this.onSelectChange}
                                width="150px"
                                menuItems={sizeList}
                            />

                            <div style={{width: "20px"}}></div>

                            <ArgoTextFieldSelectHook
                                fileName="ArgoQuestionFormatCustom"
                                fieldName="widthType"
                                label="Width Format"
                                value={this.state.formatObj.widthType}
                                onChange={this.onSelectChange}
                                width="150px"
                                menuItems={widthFormatList}
                            />

                            <div style={{width: "20px"}}></div>

                            <ArgoTextFieldHook
                                fileName="ArgoQuestionFormatCustom"
                                fieldName="widthEntry"
                                label={this.state.formatObj.widthType === "pixels" ? "Width (px)" : "Width (%)"}
                                value={this.state.formatObj.widthEntry}
                                type="number"
                                onChange={this.onTextChange}
                                width="150px"
                            />

                            <div style={{width: "20px"}}></div>

                            <ArgoTextFieldHook
                                fileName="ArgoQuestionFormatCustom"
                                fieldName="max"
                                label="Max Characters"
                                value={this.state.formatObj.max}
                                type="number"
                                onChange={this.onTextChange}
                                width="100px"
                            />

                            <div style={{width: "20px"}}></div>

                            {this.props.questionType === questionTypes.FREE_TEXT ?
                                <React.Fragment>

                                    <ArgoCheckboxHook
                                        fileName="ArgoQuestionFormatCustom"
                                        fieldName="multiline"
                                        label={"Multiline"}
                                        onChange={this.onCheckBoxChange}
                                        checked={this.state.formatObj.multiline}
                                        paddingTop="15px"
                                        width="110px"
                                    />

                                    {/*<Checkbox id="question-format-text-entry-multiline" name="question-format-text-entry-multiline"*/}
                                    {/*          style={{width: "150px", paddingTop: "38px"}}*/}
                                    {/*          labelStyle={{color: '#C0C0C0'}}*/}
                                    {/*          iconStyle={{fill: '#888888'}}*/}
                                    {/*          checked={this.state.formatObj.multiline}*/}
                                    {/*          label={"Multiline"}*/}
                                    {/*          onCheck={this.checkboxChange.bind(this, "multiline")}*/}
                                    {/*/>*/}

                                    <div style={{width: "20px"}}></div>

                                    <ArgoTextFieldHook
                                        fileName="ArgoQuestionFormatCustom"
                                        fieldName="maxRows"
                                        label="Max Rows"
                                        value={this.state.formatObj.maxRows}
                                        type="number"
                                        onChange={this.onTextChange}
                                        width="150px"
                                        disabled={!this.state.formatObj.multiline}
                                    />

                                    <div style={{width: "20px"}}></div>

                                </React.Fragment> : ""
                            }

                            <ArgoTextFieldHook
                                fileName="ArgoQuestionFormatCustom"
                                fieldName="floatingLabel"
                                label="Floating Label"
                                value={this.state.formatObj.floatingLabel}
                                onChange={this.onTextChange}
                                width="230px"
                            />

                            <div style={{width: "20px"}}></div>

                            <ArgoTextFieldHook
                                fileName="ArgoQuestionFormatCustom"
                                fieldName="placeHolder"
                                label="Place Holder"
                                value={this.state.formatObj.placeHolder}
                                onChange={this.onTextChange}
                                width="230px"
                            />

                        </div>

                        <div style={{display: "flex", alignItems: "left", width: '100%', backgroundColor: '#F8F8F8'}}>

                            <div style={{width: "20px"}}></div>

                            <ArgoTextFieldSelectHook
                                fileName="ArgoQuestionFormatCustom"
                                fieldName="labelPosition"
                                label="Label Position"
                                value={this.state.formatObj.labelPosition}
                                onChange={this.onSelectChange}
                                width="150px"
                                menuItems={labelPositionList}
                            />

                            <div style={{width: "20px"}}></div>

                            <ArgoTextFieldHook
                                fileName="ArgoQuestionFormatCustom"
                                fieldName="label"
                                label="Label"
                                value={this.state.formatObj.label}
                                onChange={this.onTextChange}
                                width="200px"
                                disabled={this.state.formatObj.labelPosition==="none"}
                            />

                            {(this.state.formatObj.labelPosition !== "none") ?
                                <ArgoTextDisplayDefination fieldName={this.props.labelFieldName}
                                                           labelText={"Label Display"}
                                                           hideOption={false}
                                                           initTo={this.props.labelInitTo}
                                                           templateType={TEMPLATE.TYPE.QUESTION}
                                                           titleType="label"
                                                           metadataTag={this.props.labelMetadataTag}
                                                           metadataPropsObj={this.props.metadataPropsObj}
                                                           setCustomMetadata={this.setLabelMetadataValue.bind(this)}/>
                                :
                                <div></div>
                            }

                        </div>

                        {((this.props.labelInitTo === 'custom')&&(this.state.formatObj.labelPosition !== "none")) ?
                            <ArgoTextDisplayCustom
                                fieldName="labelDisplayDefination"
                                initToObj={this.props.labelInitToObj}
                                metadataTag={portal.CUSTOM_DDO_LABEL}
                                metadataPropsObj={this.props.metadataPropsObj}
                                setCustomMetadata={this.setLabelMetadataValue.bind(this)}/>
                            :
                            <div></div>
                        }



                    </React.Fragment>
                );


            case questionTypes.DATE:
            case questionTypes.DATE_RANGE:

                return (
                    <React.Fragment>

                        {this.props.questionType === questionTypes.DATE_RANGE ?
                            <div style={{
                                display: "flex",
                                alignItems: "left",
                                width: '100%',
                                backgroundColor: '#F8F8F8'
                            }}>
                                <div style={{width: "20px"}}></div>

                                <ArgoTextFieldSelectHook
                                    fileName="ArgoQuestionFormatCustom"
                                    fieldName="arrange"
                                    label="Arrange"
                                    value={this.state.formatObj.arrange}
                                    onChange={this.onSelectChange}
                                    width="150px"
                                    menuItems={arrangeList}
                                />
                            </div> : ""
                        }

                        <div style={{display: "flex", alignItems: "left", width: '100%', backgroundColor: '#F8F8F8'}}>
                            <div style={{width: "20px"}}></div>

                            <ArgoTextFieldSelectHook
                                fileName="ArgoQuestionFormatCustom"
                                fieldName="style"
                                label="Style (From Date)"
                                value={this.state.formatObj.style}
                                onChange={this.onSelectChange}
                                width="150px"
                                menuItems={styleList}
                            />

                            <div style={{width: "20px"}}></div>

                            <ArgoTextFieldSelectHook
                                fileName="ArgoQuestionFormatCustom"
                                fieldName="size"
                                label="Size"
                                value={this.state.formatObj.size}
                                onChange={this.onSelectChange}
                                width="150px"
                                menuItems={sizeList}
                            />

                            <div style={{width: "20px"}}></div>

                            <ArgoTextFieldSelectHook
                                fileName="ArgoQuestionFormatCustom"
                                fieldName="widthType"
                                label="Width Format"
                                value={this.state.formatObj.widthType}
                                onChange={this.onSelectChange}
                                width="150px"
                                menuItems={widthFormatList}
                            />

                            <div style={{width: "20px"}}></div>

                            <ArgoTextFieldHook
                                fileName="ArgoQuestionFormatCustom"
                                fieldName="widthEntry"
                                label={this.state.formatObj.widthType === "pixels" ? "Width (px)" : "Width (%)"}
                                value={this.state.formatObj.widthEntry}
                                type="number"
                                onChange={this.onTextChange}
                                width="150px"
                            />

                            <div style={{width: "20px"}}></div>

                            <ArgoTextFieldHook
                                fileName="ArgoQuestionFormatCustom"
                                fieldName="floatingLabel"
                                label="Floating Label"
                                value={this.state.formatObj.floatingLabel}
                                onChange={this.onTextChange}
                                width="250px"
                            />

                            <div style={{width: "20px"}}></div>

                            <ArgoTextFieldSelectHook
                                fileName="ArgoQuestionFormatCustom"
                                fieldName="labelPosition"
                                label="Label Position"
                                value={this.state.formatObj.labelPosition}
                                onChange={this.onSelectChange}
                                width="150px"
                                menuItems={labelPositionList}
                            />

                            <div style={{width: "20px"}}></div>

                            <ArgoTextFieldHook
                                fileName="ArgoQuestionFormatCustom"
                                fieldName="label"
                                label="Label"
                                value={this.state.formatObj.label}
                                onChange={this.onTextChange}
                                width="200px"
                                disabled={this.state.formatObj.labelPosition==="none"}
                            />

                        </div>

                        {this.props.questionType === questionTypes.DATE_RANGE ?
                            <div style={{display: "flex", alignItems: "left", width: '100%', backgroundColor: '#F8F8F8'}}>
                                <div style={{width: "20px"}}></div>

                                <ArgoTextFieldSelectHook
                                    fileName="ArgoQuestionFormatCustom"
                                    fieldName="style2"
                                    label="Style (To Date)"
                                    value={this.state.formatObj.style2}
                                    onChange={this.onSelectChange}
                                    width="150px"
                                    menuItems={styleList}
                                />

                                <div style={{width: "20px"}}></div>

                                <ArgoTextFieldSelectHook
                                    fileName="ArgoQuestionFormatCustom"
                                    fieldName="size2"
                                    label="Size"
                                    value={this.state.formatObj.size2}
                                    onChange={this.onSelectChange}
                                    width="150px"
                                    menuItems={sizeList}
                                />

                                <div style={{width: "20px"}}></div>

                                <ArgoTextFieldSelectHook
                                    fileName="ArgoQuestionFormatCustom"
                                    fieldName="widthType2"
                                    label="Width Format"
                                    value={this.state.formatObj.widthType2}
                                    onChange={this.onSelectChange}
                                    width="150px"
                                    menuItems={widthFormatList}
                                />

                                <div style={{width: "20px"}}></div>

                                <ArgoTextFieldHook
                                    fileName="ArgoQuestionFormatCustom"
                                    fieldName="widthEntry2"
                                    label={this.state.formatObj.widthType === "pixels" ? "Width (px)" : "Width (%)"}
                                    value={this.state.formatObj.widthEntry2}
                                    type="number"
                                    onChange={this.onTextChange}
                                    width="150px"
                                />

                                <div style={{width: "20px"}}></div>

                                <ArgoTextFieldHook
                                    fileName="ArgoQuestionFormatCustom"
                                    fieldName="floatingLabel2"
                                    label="Floating Label"
                                    value={this.state.formatObj.floatingLabel2}
                                    onChange={this.onTextChange}
                                    width="250px"
                                />

                                <div style={{width: "20px"}}></div>

                                <ArgoTextFieldSelectHook
                                    fileName="ArgoQuestionFormatCustom"
                                    fieldName="labelPosition2"
                                    label="Label Position"
                                    value={this.state.formatObj.labelPosition2}
                                    onChange={this.onSelectChange}
                                    width="150px"
                                    menuItems={labelPositionList}
                                />

                                <div style={{width: "20px"}}></div>

                                <ArgoTextFieldHook
                                    fileName="ArgoQuestionFormatCustom"
                                    fieldName="label2"
                                    label="Label"
                                    value={this.state.formatObj.label2}
                                    onChange={this.onTextChange}
                                    width="200px"
                                    disabled={this.state.formatObj.labelPosition2==="none"}
                                />

                            </div>
                            :
                            ""
                        }

                        <div style={{display: "flex", alignItems: "left", width: '100%', backgroundColor: '#F8F8F8'}}>

                            {(this.state.formatObj.labelPosition !== "none" || ((this.state.formatObj.labelPosition2 !== undefined) && (this.state.formatObj.labelPosition2 !== "none"))) ?
                                <ArgoTextDisplayDefination fieldName={this.props.labelFieldName}
                                                           labelText={"Labels Display"}
                                                           hideOption={false}
                                                           initTo={this.props.labelInitTo}
                                                           templateType={TEMPLATE.TYPE.QUESTION}
                                                           titleType="label"
                                                           metadataTag={this.props.labelMetadataTag}
                                                           metadataPropsObj={this.props.metadataPropsObj}
                                                           setCustomMetadata={this.setLabelMetadataValue.bind(this)}/>
                                :
                                <div></div>
                            }

                        </div>

                        {((this.props.labelInitTo === 'custom') && ((this.state.formatObj.labelPosition !== "none") || ((this.state.formatObj.labelPosition2 !== undefined) && (this.state.formatObj.labelPosition2 !== "none"))) ) ?
                            <ArgoTextDisplayCustom
                                fieldName="labelDisplayDefination"
                                initToObj={this.props.labelInitToObj}
                                metadataTag={portal.CUSTOM_DDO_LABEL}
                                metadataPropsObj={this.props.metadataPropsObj}
                                setCustomMetadata={this.setLabelMetadataValue.bind(this)}/>
                            :
                            <div></div>
                        }



                    </React.Fragment>
                );


            case questionTypes.ADDRESS_FULL:

                return (
                    <React.Fragment>

                        <div style={{display: "flex", alignItems: "left", width: '100%', backgroundColor: '#F8F8F8'}}>
                            <div style={{width: "20px"}}></div>

                            <ArgoTextFieldSelectHook
                                fileName="ArgoQuestionFormatCustom"
                                fieldName="style"
                                label="Style (Street 1)"
                                value={this.state.formatObj.style}
                                onChange={this.onSelectChange}
                                width="150px"
                                menuItems={styleList}
                            />

                            <div style={{width: "20px"}}></div>

                            <ArgoTextFieldSelectHook
                                fileName="ArgoQuestionFormatCustom"
                                fieldName="size"
                                label="Size"
                                value={this.state.formatObj.size}
                                onChange={this.onSelectChange}
                                width="150px"
                                menuItems={sizeList}
                            />

                            <div style={{width: "20px"}}></div>

                            <ArgoTextFieldSelectHook
                                fileName="ArgoQuestionFormatCustom"
                                fieldName="widthType"
                                label="Width Format"
                                value={this.state.formatObj.widthType}
                                onChange={this.onSelectChange}
                                width="150px"
                                menuItems={widthFormatList}
                            />

                            <div style={{width: "20px"}}></div>

                            <ArgoTextFieldHook
                                fileName="ArgoQuestionFormatCustom"
                                fieldName="widthEntry"
                                label={this.state.formatObj.widthType === "pixels" ? "Width (px)" : "Width (%)"}
                                value={this.state.formatObj.widthEntry}
                                type="number"
                                onChange={this.onTextChange}
                                width="150px"
                            />

                            <div style={{width: "20px"}}></div>

                            <ArgoTextFieldHook
                                fileName="ArgoQuestionFormatCustom"
                                fieldName="max"
                                label="Max Characters"
                                value={this.state.formatObj.max}
                                type="number"
                                onChange={this.onTextChange}
                                width="150px"
                            />

                            <div style={{width: "20px"}}></div>

                            <ArgoTextFieldHook
                                fileName="ArgoQuestionFormatCustom"
                                fieldName="floatingLabel"
                                label="Floating Label"
                                value={this.state.formatObj.floatingLabel}
                                onChange={this.onTextChange}
                                width="250px"
                            />

                            <div style={{width: "20px"}}></div>

                            <ArgoTextFieldHook
                                fileName="ArgoQuestionFormatCustom"
                                fieldName="placeHolder"
                                label="Place Holder"
                                value={this.state.formatObj.placeHolder}
                                onChange={this.onTextChange}
                                width="250px"
                            />

                            <div style={{width: "20px"}}></div>

                            <ArgoTextFieldSelectHook
                                fileName="ArgoQuestionFormatCustom"
                                fieldName="labelPosition"
                                label="Label Position"
                                value={this.state.formatObj.labelPosition}
                                onChange={this.onSelectChange}
                                width="150px"
                                menuItems={labelPositionList}
                            />

                            <div style={{width: "20px"}}></div>

                            <ArgoTextFieldHook
                                fileName="ArgoQuestionFormatCustom"
                                fieldName="label"
                                label="Label"
                                value={this.state.formatObj.label}
                                onChange={this.onTextChange}
                                width="200px"
                                disabled={this.state.formatObj.labelPosition==="none"}
                            />

                        </div>

                        <div style={{display: "flex", alignItems: "left", width: '100%', backgroundColor: '#F8F8F8'}}>
                            <div style={{width: "20px"}}></div>

                            <ArgoTextFieldSelectHook
                                fileName="ArgoQuestionFormatCustom"
                                fieldName="style2"
                                label="Style (Street 2)"
                                value={this.state.formatObj.style2}
                                onChange={this.onSelectChange}
                                width="150px"
                                menuItems={styleList}
                            />

                            <div style={{width: "20px"}}></div>

                            <ArgoTextFieldSelectHook
                                fileName="ArgoQuestionFormatCustom"
                                fieldName="size2"
                                label="Size"
                                value={this.state.formatObj.size2}
                                onChange={this.onSelectChange}
                                width="150px"
                                menuItems={sizeList}
                            />

                            <div style={{width: "20px"}}></div>

                            <ArgoTextFieldSelectHook
                                fileName="ArgoQuestionFormatCustom"
                                fieldName="widthType2"
                                label="Width Format"
                                value={this.state.formatObj.widthType2}
                                onChange={this.onSelectChange}
                                width="150px"
                                menuItems={widthFormatList}
                            />

                            <div style={{width: "20px"}}></div>

                            <ArgoTextFieldHook
                                fileName="ArgoQuestionFormatCustom"
                                fieldName="widthEntry2"
                                label={this.state.formatObj.widthType === "pixels" ? "Width (px)" : "Width (%)"}
                                value={this.state.formatObj.widthEntry2}
                                type="number"
                                onChange={this.onTextChange}
                                width="150px"
                            />

                            <div style={{width: "20px"}}></div>

                            <ArgoTextFieldHook
                                fileName="ArgoQuestionFormatCustom"
                                fieldName="max2"
                                label="Max Characters"
                                value={this.state.formatObj.max2}
                                type="number"
                                onChange={this.onTextChange}
                                width="150px"
                            />

                            <div style={{width: "20px"}}></div>

                            <ArgoTextFieldHook
                                fileName="ArgoQuestionFormatCustom"
                                fieldName="floatingLabel2"
                                label="Floating Label"
                                value={this.state.formatObj.floatingLabel2}
                                onChange={this.onTextChange}
                                width="250px"
                            />

                            <div style={{width: "20px"}}></div>

                            <ArgoTextFieldHook
                                fileName="ArgoQuestionFormatCustom"
                                fieldName="placeHolder2"
                                label="Place Holder"
                                value={this.state.formatObj.placeHolder2}
                                onChange={this.textChange}
                                width="250px"
                            />

                            <div style={{width: "20px"}}></div>

                            <ArgoTextFieldSelectHook
                                fileName="ArgoQuestionFormatCustom"
                                fieldName="labelPosition2"
                                label="Label Position"
                                value={this.state.formatObj.labelPosition2}
                                onChange={this.onSelectChange}
                                width="150px"
                                menuItems={labelPositionList}
                            />

                            <div style={{width: "20px"}}></div>

                            <ArgoTextFieldHook
                                fileName="ArgoQuestionFormatCustom"
                                fieldName="label2"
                                label="Label"
                                value={this.state.formatObj.label2}
                                onChange={this.onTextChange}
                                width="200px"
                                disabled={this.state.formatObj.labelPosition2==="none"}
                            />

                        </div>

                        <div style={{display: "flex", alignItems: "left", width: '100%', backgroundColor: '#F8F8F8'}}>
                            <div style={{width: "20px"}}></div>

                            <ArgoTextFieldSelectHook
                                fileName="ArgoQuestionFormatCustom"
                                fieldName="style3"
                                label="Style (City)"
                                value={this.state.formatObj.style3}
                                onChange={this.onSelectChange}
                                width="150px"
                                menuItems={styleList}
                            />

                            <div style={{width: "20px"}}></div>

                            <ArgoTextFieldSelectHook
                                fileName="ArgoQuestionFormatCustom"
                                fieldName="size3"
                                label="Size"
                                value={this.state.formatObj.size3}
                                onChange={this.onSelectChange}
                                width="150px"
                                menuItems={sizeList}
                            />

                            <div style={{width: "20px"}}></div>

                            <ArgoTextFieldSelectHook
                                fileName="ArgoQuestionFormatCustom"
                                fieldName="widthType3"
                                label="Width Format"
                                value={this.state.formatObj.widthType3}
                                onChange={this.onSelectChange}
                                width="150px"
                                menuItems={widthFormatList}
                            />

                            <div style={{width: "20px"}}></div>

                            <ArgoTextFieldHook
                                fileName="ArgoQuestionFormatCustom"
                                fieldName="widthEntry3"
                                label={this.state.formatObj.widthType === "pixels" ? "Width (px)" : "Width (%)"}
                                value={this.state.formatObj.widthEntry3}
                                type="number"
                                onChange={this.onTextChange}
                                width="150px"
                            />

                            <div style={{width: "20px"}}></div>

                            <ArgoTextFieldHook
                                fileName="ArgoQuestionFormatCustom"
                                fieldName="max3"
                                label="Max Characters"
                                value={this.state.formatObj.max3}
                                type="number"
                                onChange={this.onTextChange}
                                width="150px"
                            />

                            <div style={{width: "20px"}}></div>

                            <ArgoTextFieldHook
                                fileName="ArgoQuestionFormatCustom"
                                fieldName="floatingLabel3"
                                label="Floating Label"
                                value={this.state.formatObj.floatingLabel3}
                                onChange={this.textChange}
                                width="250px"
                            />

                            <div style={{width: "20px"}}></div>

                            <ArgoTextFieldHook
                                fileName="ArgoQuestionFormatCustom"
                                fieldName="placeHolder3"
                                label="Place Holder"
                                value={this.state.formatObj.placeHolder3}
                                onChange={this.onTextChange}
                                width="250px"
                            />

                            <div style={{width: "20px"}}></div>

                            <ArgoTextFieldSelectHook
                                fileName="ArgoQuestionFormatCustom"
                                fieldName="labelPosition3"
                                label="Label Position"
                                value={this.state.formatObj.labelPosition3}
                                onChange={this.onSelectChange}
                                width="150px"
                                menuItems={labelPositionList}
                            />

                            <div style={{width: "20px"}}></div>

                            <ArgoTextFieldHook
                                fileName="ArgoQuestionFormatCustom"
                                fieldName="label3"
                                label="Label"
                                value={this.state.formatObj.label3}
                                onChange={this.onTextChange}
                                width="200px"
                                disabled={this.state.formatObj.labelPosition3==="none"}
                            />

                        </div>

                        <div style={{display: "flex", alignItems: "left", width: '100%', backgroundColor: '#F8F8F8'}}>
                            <div style={{width: "20px"}}></div>

                            <ArgoTextFieldSelectHook
                                fileName="ArgoQuestionFormatCustom"
                                fieldName="style4"
                                label="Style (State)"
                                value={this.state.formatObj.style4}
                                onChange={this.onSelectChange}
                                width="150px"
                                menuItems={styleList}
                            />

                            <div style={{width: "20px"}}></div>

                            <ArgoTextFieldSelectHook
                                fileName="ArgoQuestionFormatCustom"
                                fieldName="size4"
                                label="Size"
                                value={this.state.formatObj.size4}
                                onChange={this.onSelectChange}
                                width="150px"
                                menuItems={sizeList}
                            />

                            <div style={{width: "20px"}}></div>

                            <ArgoTextFieldSelectHook
                                fileName="ArgoQuestionFormatCustom"
                                fieldName="widthType4"
                                label="Width Format"
                                value={this.state.formatObj.widthType4}
                                onChange={this.onSelectChange}
                                width="150px"
                                menuItems={widthFormatList}
                            />

                            <div style={{width: "20px"}}></div>

                            <ArgoTextFieldHook
                                fileName="ArgoQuestionFormatCustom"
                                fieldName="widthEntry4"
                                label={this.state.formatObj.widthType === "pixels" ? "Width (px)" : "Width (%)"}
                                value={this.state.formatObj.widthEntry4}
                                type="number"
                                onChange={this.onTextChange}
                                width="150px"
                            />

                            <div style={{width: "20px"}}></div>

                            <ArgoTextFieldHook
                                fileName="ArgoQuestionFormatCustom"
                                fieldName="max4"
                                label="Max Characters"
                                value={this.state.formatObj.max4}
                                type="number"
                                onChange={this.onTextChange}
                                width="150px"
                            />

                            <div style={{width: "20px"}}></div>

                            <ArgoTextFieldHook
                                fileName="ArgoQuestionFormatCustom"
                                fieldName="floatingLabel4"
                                label="Floating Label"
                                value={this.state.formatObj.floatingLabel4}
                                onChange={this.onTextChange}
                                width="250px"
                            />

                            <div style={{width: "290px"}}></div>

                            <ArgoTextFieldSelectHook
                                fileName="ArgoQuestionFormatCustom"
                                fieldName="labelPosition4"
                                label="Label Position"
                                value={this.state.formatObj.labelPosition4}
                                onChange={this.onSelectChange}
                                width="150px"
                                menuItems={labelPositionList}
                            />

                            <div style={{width: "20px"}}></div>

                            <ArgoTextFieldHook
                                fileName="ArgoQuestionFormatCustom"
                                fieldName="label4"
                                label="Label"
                                value={this.state.formatObj.label4}
                                onChange={this.onTextChange}
                                width="200px"
                                disabled={this.state.formatObj.labelPosition4==="none"}
                            />

                        </div>

                        <div style={{display: "flex", alignItems: "left", width: '100%', backgroundColor: '#F8F8F8'}}>
                            <div style={{width: "20px"}}></div>

                            <ArgoTextFieldSelectHook
                                fileName="ArgoQuestionFormatCustom"
                                fieldName="style5"
                                label="Style (Zip)"
                                value={this.state.formatObj.style5}
                                onChange={this.onSelectChange}
                                width="150px"
                                menuItems={styleList}
                            />

                            <div style={{width: "20px"}}></div>

                            <ArgoTextFieldSelectHook
                                fileName="ArgoQuestionFormatCustom"
                                fieldName="size5"
                                label="Size"
                                value={this.state.formatObj.size5}
                                onChange={this.onSelectChange}
                                width="150px"
                                menuItems={sizeList}
                            />

                            <div style={{width: "20px"}}></div>

                            <ArgoTextFieldSelectHook
                                fileName="ArgoQuestionFormatCustom"
                                fieldName="widthType5"
                                label="Width Format"
                                value={this.state.formatObj.widthType5}
                                onChange={this.onSelectChange}
                                width="150px"
                                menuItems={widthFormatList}
                            />

                            <div style={{width: "20px"}}></div>

                            <ArgoTextFieldHook
                                fileName="ArgoQuestionFormatCustom"
                                fieldName="widthEntry5"
                                label={this.state.formatObj.widthType === "pixels" ? "Width (px)" : "Width (%)"}
                                value={this.state.formatObj.widthEntry5}
                                type="number"
                                onChange={this.onTextChange}
                                width="150px"
                            />

                            <div style={{width: "20px"}}></div>

                            <ArgoTextFieldHook
                                fileName="ArgoQuestionFormatCustom"
                                fieldName="max5"
                                label="Max Characters"
                                value={this.state.formatObj.max5}
                                type="number"
                                onChange={this.onTextChange}
                                width="150px"
                            />

                            <div style={{width: "20px"}}></div>

                            <ArgoTextFieldHook
                                fileName="ArgoQuestionFormatCustom"
                                fieldName="floatingLabel5"
                                label="Floating Label"
                                value={this.state.formatObj.floatingLabel5}
                                onChange={this.onTextChange}
                                width="250px"
                            />

                            <div style={{width: "20px"}}></div>

                            <ArgoTextFieldHook
                                fileName="ArgoQuestionFormatCustom"
                                fieldName="placeHolder5"
                                label="Place Holder"
                                value={this.state.formatObj.placeHolder5}
                                onChange={this.onTextChange}
                                width="250px"
                            />

                            <div style={{width: "20px"}}></div>

                            <ArgoTextFieldSelectHook
                                fileName="ArgoQuestionFormatCustom"
                                fieldName="labelPosition5"
                                label="Label Position"
                                value={this.state.formatObj.labelPosition5}
                                onChange={this.onSelectChange}
                                width="150px"
                                menuItems={labelPositionList}
                            />

                            <div style={{width: "20px"}}></div>

                            <ArgoTextFieldHook
                                fileName="ArgoQuestionFormatCustom"
                                fieldName="label5"
                                label="Label"
                                value={this.state.formatObj.label5}
                                onChange={this.onTextChange}
                                width="200px"
                                disabled={this.state.formatObj.labelPosition5==="none"}
                            />

                        </div>

                        <div style={{display: "flex", alignItems: "left", width: '100%', backgroundColor: '#F8F8F8'}}>

                            {(this.state.formatObj.labelPosition !== "none" || this.state.formatObj.labelPosition2 !== "none" || this.state.formatObj.labelPosition3 !== "none" || this.state.formatObj.labelPosition4 !== "none" || this.state.formatObj.labelPosition5 !== "none") ?
                                <ArgoTextDisplayDefination fieldName={this.props.labelFieldName}
                                                           labelText={"Labels Display"}
                                                           hideOption={false}
                                                           initTo={this.props.labelInitTo}
                                                           templateType={TEMPLATE.TYPE.QUESTION}
                                                           titleType="label"
                                                           metadataTag={this.props.labelMetadataTag}
                                                           metadataPropsObj={this.props.metadataPropsObj}
                                                           setCustomMetadata={this.setLabelMetadataValue.bind(this)}/>
                                :
                                <div></div>
                            }

                        </div>

                        {((this.props.labelInitTo === 'custom')&&(this.state.formatObj.labelPosition !== "none" || this.state.formatObj.labelPosition2 !== "none" || this.state.formatObj.labelPosition3 !== "none" || this.state.formatObj.labelPosition4 !== "none" || this.state.formatObj.labelPosition5 !== "none")) ?
                            <ArgoTextDisplayCustom
                                fieldName="labelDisplayDefination"
                                initToObj={this.props.labelInitToObj}
                                metadataTag={portal.CUSTOM_DDO_LABEL}
                                metadataPropsObj={this.props.metadataPropsObj}
                                setCustomMetadata={this.setLabelMetadataValue.bind(this)}/>
                            :
                            <div></div>
                        }



                    </React.Fragment>
                );


            case questionTypes.STATE_ONLY:

                return (
                    <div>
                        State Only
                    </div>
                );

            case questionTypes.CHECKBOX:

                return (
                    <React.Fragment>

                        <div style={{display: "flex", alignItems: "left", width: '100%', backgroundColor: '#F8F8F8'}}>

                            <div style={{width: "20px"}}></div>

                            <ArgoTextFieldSelectHook
                                fileName="ArgoQuestionFormatCustom"
                                fieldName="style"
                                label="Style"
                                value={this.state.formatObj.style}
                                onChange={this.onSelectChange}
                                width="150px"
                                menuItems={[{value: "checkboxes", text: "Checkboxes"}, {value: "outlined", text: "Outlined Checkboxes"}]}
                            />

                            <div style={{width: "20px"}}></div>

                            <ArgoTextFieldSelectHook
                                fileName="ArgoQuestionFormatCustom"
                                fieldName="arrange"
                                label="Arrange"
                                value={this.state.formatObj.arrange}
                                onChange={this.onSelectChange}
                                width="150px"
                                menuItems={arrangeList}
                            />

                            {this.state.formatObj.style === "outlined" ?
                                <React.Fragment>

                                    <div style={{width: "20px"}}></div>

                                    <ArgoTextFieldSelectHook
                                        fileName="ArgoQuestionFormatCustom"
                                        fieldName="color"
                                        label="Color"
                                        value={this.state.formatObj.color}
                                        onChange={this.onSelectChange}
                                        width="150px"
                                        menuItems={colorTypeList}
                                    />

                                </React.Fragment>
                                :
                                <div></div>
                            }

                        </div>

                        {((this.state.formatObj.style === 'outlined') && (this.state.formatObj.color === "custom")) ?
                            <React.Fragment>
                                {customColorTitles.map((title, index) => (
                                    <div style={{display: "flex", alignItems: "left", width: '100%', backgroundColor: '#F8F8F8'}}>

                                        <div style={{width: "40px"}}></div>
                                        <ArgoColorInput
                                            mode="questionSingleSelect"
                                            colorLabel={title}
                                            fieldName={customColorProps[index]}
                                            index={this.props.index}
                                            initToObj={this.state.formatObj[customColorProps[index]]}
                                            setColor={this.setColor.bind(this)}
                                        />
                                    </div>))
                                }
                            </React.Fragment>
                            : ""
                        }

                    </React.Fragment>
                );


            // export const DISPLAY_CHECKBOXES_AS_DDLB  = "display-def-checkboxes-as-ddlb";       // (Question) Default display for checkboxes is checkboxes when < 5 answers, otherwise ddlb, this overrides to force DDLB
            // export const DISPLAY_CHECKBOXES_AS_CHECKBOXES  = "display-def-checkboxes-as-checkboxes";       // (Question) Default display for checkboxes is checkboxes when < 5 answers, otherwise ddlb, this overrides to force checkboxes
            // export const DISPLAY_CHECKBOXES_AS_OUTLINED  = "display-def-checkboxes-as-outlined";       // (Question) Default display for checkboxes is checkboxes when < 5 answers, otherwise ddlb, this overrides to force checkboxes

            // Note: Checkbox properties: https://stackoverflow.com/questions/41332701/how-can-i-customize-the-color-of-a-checkbox-in-material-ui
            // boxColor
            // checkedColor
            // requiredColor
            // disabledColor
            // labelColor
            // labelDisabledColor
            //


            case questionTypes.RADIO:
            case questionTypes.YES_NO:

                let customColorsArray = (this.state.formatObj.style === "outlined") ? customColorTitles : customColorTitlesNoControl;
                let customColorPropsArray = (this.state.formatObj.style === "outlined") ? customColorProps : customColorPropsNoControl;

                // let iconType = "";
                //
                // if (this.props.answers.length !== 0) {
                //     if (this.props.answers[0].metadata.hasOwnProperty(icon.ICON_TYPE)) {
                //
                //         iconType = this.props.answers[0].metadata[icon.ICON_TYPE];
                //     }
                // }


                return (
                    <React.Fragment>

                        <div style={{display: "flex", alignItems: "left", width: '100%', backgroundColor: '#F8F8F8', paddingTop: '20px'}}>

                            <div style={{width: "20px"}}></div>

                            {this.props.questionType !== questionTypes.YES_NO ?
                                <React.Fragment>

                                    <ArgoTextFieldSelectHook
                                        fileName="ArgoQuestionFormatCustom"
                                        fieldName="displayAs"
                                        label="Display As"
                                        value={this.state.formatObj.displayAs}
                                        onChange={this.onSelectChange}
                                        width="150px"
                                        menuItems={[{value: "single", text: "Single Select"}, {value: "likert", text: "Likert"}]}
                                        disabled={this.props.answerCount > 0}
                                    />

                                    <div style={{width: "20px"}}></div>
                                </React.Fragment>
                                : ""
                            }

                            {this.state.formatObj.displayAs === "single" ?

                                <ArgoTextFieldSelectHook
                                    fileName="ArgoQuestionFormatCustom"
                                    fieldName="style"
                                    label="Style"
                                    value={this.state.formatObj.style}
                                    onChange={this.onSelectChange}
                                    width="250px"
                                    menuItems={
                                        [
                                            {value: "radios", text: "Radios"},
                                            {value: "outlined", text: "Outlined Radios"},
                                            {value: "ddlb", text: "DDLB"},
                                            {value: "standard", text: "Standard Buttons"},
                                            {value: "clear", text: "Clear Buttons"}
                                        ]
                                    }
                                />
                                : ""
                            }

                            <div style={{width: "20px"}}></div>

                            {this.state.formatObj.displayAs === "single" && this.state.formatObj.style !== "ddlb" ?
                                <React.Fragment>

                                    <ArgoTextFieldSelectHook
                                        fileName="ArgoQuestionFormatCustom"
                                        fieldName="arrange"
                                        label="Arrange"
                                        value={this.state.formatObj.arrange}
                                        onChange={this.onSelectChange}
                                        width="150px"
                                        menuItems={arrangeList}
                                    />

                                    {(this.state.formatObj.style === 'outlined') ?
                                        <React.Fragment>
                                            <div style={{width: "20px"}}></div>

                                            <ArgoTextFieldSelectHook
                                                fileName="ArgoQuestionFormatCustom"
                                                fieldName="color"
                                                label="Color"
                                                value={this.state.formatObj.color}
                                                onChange={this.onSelectChange}
                                                width="150px"
                                                menuItems={colorTypeList}
                                            />

                                            {/*<div style={{paddingTop: "26px"}}>*/}
                                            {/*<RaisedButton id="question-format-ss-entry-set" name="question-format-ss-entry-set" label="Set" onClick={this.setColorProperty.bind(this)}/>*/}
                                            {/*</div>*/}

                                        </React.Fragment>
                                        :
                                        <div></div>
                                    }

                                </React.Fragment>
                                :
                                <div></div>
                            }

                            {(this.state.formatObj.displayAs === "likert") ?
                                <ArgoLikertAnswerGenerator
                                    fileName="ArgoQuestionFormatCustom"
                                    componentMode="likert-titles"
                                    template={this.props.metadataPropsObj.template}
                                    templateUpdateFunction={this.props.metadataPropsObj.templateUpdateFunction}
                                    isLikert={true}
                                    answerOptionTemplates={this.props.metadataPropsObj.answerOptionTemplates}
                                    answerErrorCallback={function () {}}
                                    questionDepth={this.props.questionDepth}
                                    setMetadataErrorText={this.setMetadataErrorText}
                                />
                                :
                                <div></div>
                            }

                        </div>


                        {(this.state.formatObj.color === "custom") ?
                            <React.Fragment>
                                {customColorsArray.map((title, index) => (
                                    <div style={{display: "flex", alignItems: "left", width: '100%', backgroundColor: '#F8F8F8'}}>

                                        <div style={{width: "40px"}}></div>
                                        <ArgoColorInput mode="questionSingleSelect"
                                                        colorLabel={title}
                                                        fieldName={customColorPropsArray[index]}
                                                        index={this.props.index}
                                                        initToObj={this.state.formatObj[customColorPropsArray[index]]}
                                                        setColor={this.setColor.bind(this)}
                                        />
                                    </div>))
                                }
                            </React.Fragment>
                            : ""
                        }

                    </React.Fragment>
                );


            case questionTypes.LIKERT_RADIO:
                return (
                    <React.Fragment>
                        <div style={{display: "flex", alignItems: "left", width: '100%', backgroundColor: '#F8F8F8'}}>

                            <ArgoTextFieldHook
                                fileName="ArgoQuestionFormatCustom"
                                fieldName="indentEntry"
                                label="Indent Question Text"
                                value={this.state.formatObj.indentEntry}
                                onChange={this.onTextChange}
                                width="150px"
                            />

                        </div>
                    </React.Fragment>
                );

            // no default
        }
    };


    render() {

        return (
            <React.Fragment>
                {this.formatCustomOptions()}
            </React.Fragment>
        );
    }
}

ArgoQuestionFormatCustom.defaultProps = {
    answerCount: 0,
    index: 0,
};

ArgoQuestionFormatCustom.propTypes = {
    questionType: PropTypes.string,
    fieldName: PropTypes.string,
    index: PropTypes.number,
    initToObj: PropTypes.object,
    metadataTag: PropTypes.string,
    answerCount: PropTypes.number,
    setCustomMetadata: PropTypes.func,
    labelFieldName: PropTypes.string,
    labelInitTo: PropTypes.string,
    labelInitToObj: PropTypes.object,
    labelMetadataTag: PropTypes.string,
    answers: PropTypes.array,
    addAnswerFunction: PropTypes.func,
    metadataPropsObj: PropTypes.object
};

export default ArgoQuestionFormatCustom;

