import * as React from "react";

function SvgUnselectedDoubleNegative(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 24 24" {...props}>
      <defs>
        <path id="unselected_doubleNegative_svg__c" d="M4.8 1.2H0V0h4.8z" />
        <path id="unselected_doubleNegative_svg__e" d="M4.8 1.2H0V0h4.8z" />
        <rect
          id="unselected_doubleNegative_svg__a"
          x={0}
          y={0}
          width={21.6}
          height={21.6}
          rx={10.8}
        />
      </defs>
      <g transform="translate(1.2 1.2)" fill="none" fillRule="evenodd">
        <mask id="unselected_doubleNegative_svg__b" fill="#fff">
          <use xlinkHref="#unselected_doubleNegative_svg__a" />
        </mask>
        <use fill="#FFF" xlinkHref="#unselected_doubleNegative_svg__a" />
        <g
          mask="url(#unselected_doubleNegative_svg__b)"
          fill="#000"
          fillOpacity={1}
        >
          <path d="M0 0h21.6v21.6H0z" />
        </g>
        <rect
          fill="#FFF"
          fillRule="nonzero"
          mask="url(#unselected_doubleNegative_svg__b)"
          x={1.08}
          y={1.08}
          width={19.44}
          height={19.44}
          rx={9.72}
        />
        <g mask="url(#unselected_doubleNegative_svg__b)">
          <g transform="translate(4.2 10.2)">
            <mask id="unselected_doubleNegative_svg__d" fill="#fff">
              <use xlinkHref="#unselected_doubleNegative_svg__c" />
            </mask>
            <g
              mask="url(#unselected_doubleNegative_svg__d)"
              fill="#000"
              fillOpacity={1}
            >
              <path d="M0-3h4.8v7.2H0z" />
            </g>
          </g>
        </g>
        <g mask="url(#unselected_doubleNegative_svg__b)">
          <g transform="translate(12.6 10.2)">
            <mask id="unselected_doubleNegative_svg__f" fill="#fff">
              <use xlinkHref="#unselected_doubleNegative_svg__e" />
            </mask>
            <g
              mask="url(#unselected_doubleNegative_svg__f)"
              fill="#000"
              fillOpacity={1}
            >
              <path d="M0-3h4.8v7.2H0z" />
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}

export default SvgUnselectedDoubleNegative;
