import React from 'react';
import PropTypes from 'prop-types';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import {bindActionCreators} from "redux";
import {getToken, initializeAuthorization} from "../actions/responseActions";
import {connect} from "react-redux";
import Typography from '@mui/material/Typography';
//import * as system from "../../constants/systemConstants";
import * as tag from "../constants/customMetadataConstants";


// based on https://stackoverflow.com/a/51771466
class ErrorPage extends React.Component {

    constructor(props) {
        super(props);
        this.state = {hide : true};
    }

    // https://stackoverflow.com/a/56559162
    componentDidMount() {
        // this.myTimer = setTimeout(() => {
        //     this.setState({hidden: false});
        // }, this.props.waitBeforeShow);
    }

    UNSAFE_componentWillReceiveProps(nextProps) {

        if (this.state.hide) {
            this.setState({hide: nextProps.hide});  // Set the Display Definition Object
        }
    }

    render() {

        let alertText = tag.ALERT_TEXT_CANCELED;
        if (tag.metadataExists(this.props.qnr, tag.ALERT_TEXT_CANCELED)) {
            alertText = tag.metadataValue(this.props.qnr, tag.ALERT_TEXT_CANCELED);
        }
        // tag.metadataValue(this.props.qnr, tag.CANCEL_TEXT_OVERRIDE, "", system.ALERT_MESSAGE_QUESTIONNAIRE_CANCELED;) I think I will remove this option

        return this.state.hide ? ""
            :
            <div>
                <div style={{height: '30px'}}></div>
                <div style={{display: "flex", alignItems: "center", justifyContent: "center"}}>
                    <Card style={{width: "300px", alignSelf: "center"}}
                          raised={true}
                          width="150px"
                    >
                        <CardContent>
                            {this.props.canceled ?
                                <Typography variant={"h4"}
                                            style={{textAlign: 'center'}}>{alertText}</Typography>
                                :
                                <Typography variant={"h4"}
                                            style={{textAlign: 'center'}}>{this.props.message}</Typography>
                            }
                        </CardContent>
                    </Card>
                </div>
                <div style={{height: '30px'}}></div>
            </div>
    }
}

ErrorPage.defaultProps = {
    hide: true,
};

ErrorPage.propTypes = {
    hide: PropTypes.bool.isRequired,
    message: PropTypes.string,
    qnr: PropTypes.object,
    canceled: PropTypes.bool
};

function mapDispatchToProps(dispatch) {
    return bindActionCreators({getToken, initializeAuthorization}, dispatch);
}

function mapStateToProps(state, props) {
    return {
        message: state.errorResponse.message,
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(ErrorPage);
