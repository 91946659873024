import React, { useState, useEffect } from 'react';
import TextField from "@mui/material/TextField";
import Select from "@mui/material/Select";
import MenuItem from '@mui/material/MenuItem';
import { createTheme } from '@mui/material/styles';
import { ThemeProvider } from '@mui/material/styles';

// This fixes the DDLB transparency issue
const theme = createTheme({
    backgroundColor: "white"
});


export default function ArgoSelectHook2(props) {

    const fileName = props.fileName === undefined ? "file-name" : props.fileName;
    const fieldName = props.fieldName === undefined ? "field-name" : props.fieldName;

    const index = props.index === undefined ? "" : props.index; // If field is built in a loop index is required to know which field to match up with

    const uniqueIdentifier = fileName + "-text-field-select-" + fieldName + index;

    const variant = props.variant === undefined ? "standard" : props.variant;
    const label = props.label === undefined ? "" : props.label;
    const value = props.value === undefined ? "" : props.value; // Value is the index of the list select menuitem
    const width = props.width === undefined ? "" : props.width;
    const paddingBottom = props.paddingBottom === undefined ? "0px" : props.paddingBottom;
    const fullWidth = props.fullWidth === undefined ? ((width !== "") ? false : true) : false;
    const autoFocus = props.autoFocus === undefined ? false : false;
    const onChange = props.onChange === undefined ? function () {} : props.onChange;
    const display = props.display === undefined ? "inline" : "none";
    const addAll = props.addAll === undefined ? false : true;

    const menuItems = props.menuItems === undefined ? [] : (addAll ? props.menuItems.unshift("All") : props.menuItems);

    const menuItemsIsStrings = menuItems.length > 0 ? (typeof menuItems[0] === "string" ? true : false) : false; // if not array of strings, must be objects {value: "", text: ""}
    const useTextForValue = props.useTextForValue === undefined ? false : true;
    const valueProperty = props.valueProperty === undefined ? "value" : props.valueProperty;
    const textProperty = props.textProperty === undefined ? "text" : props.textProperty;

    //const multiple = props.multiple === undefined ? false : props.multiple;


    const handleChange = (event) => {

        let menuItemText = "";
        if (menuItemsIsStrings) {
            if (useTextForValue) {
                menuItemText = event.target.value;
            }
            else {
                menuItemText = menuItems[event.target.value]; // NOTE: event.target.value on a select is the menuItem value
            }
        }
        else {
            menuItemText = menuItems.find(x => x.value === event.target.value)[textProperty];
        }

        onChange(event, fieldName, menuItemText, index);
    };

    return (menuItems.length > 0 ?
            <ThemeProvider theme={theme}>
                <Select
                    id={uniqueIdentifier}
                    name={uniqueIdentifier}
                    select
                    variant={variant}
                    label={label}
                    value={value !== undefined ? value : ""}
                    onChange={handleChange}
                    fullWidth={fullWidth}
                    sx={{width: fullWidth ? "100%" : width, paddingBottom: paddingBottom}}
                >
                    {menuItems.map((item, index) => {
                        return (<MenuItem key={uniqueIdentifier + index} value={(menuItemsIsStrings && !useTextForValue) ? index : (useTextForValue ? item : item[valueProperty])}>{menuItemsIsStrings ? item : item[textProperty]}</MenuItem>);
                    })}
                </Select>
            </ThemeProvider>
            :
            <div></div>
    );
}
