import React from "react";
import ArgoButtonHook from "../components/common/ArgoButtonHook";
import PortalContainer from "../portal/PortalContainer";
import * as TEMPLATE from "../constants/templateConstants";
import Box from '@mui/material/Box';


// export function createCategoryTemplateObjForPortal(updatedTemplate, id, groupKeys, groupTemplates, questionTemplates, getQuestionTemplate) {
//
//     // Build list of all question keys needed
//     let questionKeys = [];
//     for (let i = 0; i < groupKeys.length; i++) {
//
//         if (groupTemplates.hasOwnProperty(groupKeys[i])) {
//             for (let j = 0; j < groupTemplates[groupKeys[i]].questionKeys.length; j++) {
//
//                 questionKeys.push(groupTemplates[groupKeys[i]].questionKeys[j]);
//                 getQuestionTemplate(groupTemplates[groupKeys[i]].questionKeys[j], null);
//             }
//         }
//     }
//
//     // -------------------------------------------------------------------------------------------------------------
//     // -------------------------------------------------------------------------------------------------------------
//
//
//     let questionCount = 0;
//     let tempCategoryTemplate = {...updatedTemplate};
//
//     tempCategoryTemplate.groupTemplates = [];
//
//     if (groupTemplates.hasOwnProperty(groupKeys[0])) {
//
//         for (let i = 0; i < groupKeys.length; i++) {
//
//             tempCategoryTemplate.groupTemplates.push({});
//             tempCategoryTemplate.groupTemplates[i] = {...groupTemplates[groupKeys[i]]};
//
//             tempCategoryTemplate.groupTemplates[i].visible = true;
//             tempCategoryTemplate.groupTemplates[i].questionTemplates = [];
//
//             if (tempCategoryTemplate.groupTemplates[i].hasOwnProperty("questionKeys")) {
//
//                 for (let j = 0; j < tempCategoryTemplate.groupTemplates[i].questionKeys.length; j++) {
//
//                     if (tempCategoryTemplate.groupTemplates[i].questionKeys[j] !== undefined) {
//
//                         if (questionTemplates.hasOwnProperty(tempCategoryTemplate.groupTemplates[i].questionKeys[j])) {
//                             questionCount += 1;
//                             tempCategoryTemplate.groupTemplates[i].questionTemplates.push({});
//                             tempCategoryTemplate.groupTemplates[i].questionTemplates[j] = {...questionTemplates[tempCategoryTemplate.groupTemplates[i].questionKeys[j]]};
//                         }
//                     }
//                 }
//             }
//         }
//     }
//
//     if (questionKeys.length === (questionCount)) {
//         return(tempCategoryTemplate);
//     }
//
// }
//
//
// export function createGroupTemplateObjForPortal(updatedTemplate, id, answerOptionTemplates, updatedQuestionTemplate, updatedQuestionKeys) {
//
//     let tempGroupTemplate = {...updatedTemplate};
//
//     tempGroupTemplate.answerOptionTemplates = answerOptionTemplates === undefined ? [] : answerOptionTemplates
//
//     tempGroupTemplate.questionKeys = updatedQuestionKeys;
//
//     tempGroupTemplate.questionTemplates = [];
//
//     // Loop thru the question keys to get the question templates - NOTE: templates can contain more than Keys, so loop to only send the associated templates
//     for (let j = 0; j < updatedQuestionKeys.length; j++) {
//         for (let key in updatedQuestionTemplate) {
//             if (updatedQuestionKeys[j] === key) {
//                 tempGroupTemplate.questionTemplates.push(updatedQuestionTemplate[key]);
//                 break;
//             }
//         }
//     }
//
//     return(tempGroupTemplate);
// }
//
//
// export function createQuestionTemplateObjForPortal(questionTemplate, id, answerTemplates = []) {
//     debugger;
//     // Make sure there is a question template
//     if (questionTemplate) {
//
//         let tempQuestionTemplate = {...questionTemplate};
//
//         tempQuestionTemplate.answerOptionTemplates = answerTemplates; // Add answer templates to question template
//
//        return(tempQuestionTemplate);
//     }
// }
//
//
// export function createPortalPreview(props, fileName, templateType, templateId, showButton = true) {
// debugger;
//     let height = (templateType === TEMPLATE.TYPE.QUESTIONNAIRE) ? "100%" : "1000px";
//
//     let portalPreviewUniqueIdentifier = "portalPreview-" + templateType + "-" + templateId + "-container-div";
//
//     let templateObj = null;
//
//     switch(templateType) {
//         case TEMPLATE.TYPE.CATEGORY:
//             templateObj = createCategoryTemplateObjForPortal(props.categoryTemplate, props.id, props.groupKeys, props.groupTemplates, props.questionTemplates, props.getQuestionTemplate);
//             break;
//         case TEMPLATE.TYPE.GROUP:
//             templateObj = createGroupTemplateObjForPortal(props.groupTemplate, props.id, props.answerOptionTemplates, props.questionTemplates, props.questionKeys)
//             break;
//         case TEMPLATE.TYPE.QUESTION:
//             templateObj = createQuestionTemplateObjForPortal(props.questionTemplate, props.id, props.answerOptionTemplates);
//             break;
//         // no default
//     }
//
//     return (
//         <div id="PortalPreview-container-div" style={{paddingLeft: "20px", height: "100%", overflow: "hidden"}}>
//
//             <div style={{height: '20px'}}>&nbsp;</div>
//
//             <div style={{width: '900px', height: height}}>
//
//                 <div id={portalPreviewUniqueIdentifier}>
//                     {showButton ?
//                         <ArgoButtonHook
//                             fileName={fileName}
//                             fieldName="sendTemplateToPortal"
//                             variant="contained"
//                             label="Portal Preview"
//                             width="170px"
//                             //onClick={onClickSendToPortalFunction}
//                         /> : ""
//                     }
//                 </div>
//
//
//                 <div  style={{height: "3000px", width: "900px", overflowY: "auto"}}>
//                     <div>&nbsp;</div>
//                     <PortalContainer
//                         portalPreview={true}
//                         templateType={templateType}
//                         templateObj={templateObj}
//                     />
//                 </div>
//
//                 <div>&nbsp;</div>
//                 <div>&nbsp;</div>
//                 <div>&nbsp;</div>
//
//                 {/*<iframe*/}
//                 {/*    id={iFrameUniqueIdentifier}*/}
//                 {/*    name={iFrameUniqueIdentifier}*/}
//                 {/*    title={"Portal Preview " + templateSuffix}*/}
//                 {/*    src={portalPath}*/}
//                 {/*    height="100%"*/}
//                 {/*    width="900px"*/}
//                 {/*    style={{overflow: "hidden"}}*/}
//                 {/*    scrolling={templateSuffix === QUESTIONNAIRE_URL_SUFFIX ? "No" : "Yes"}>*/}
//                 {/*    onLoad={sendJson(iFrameUniqueIdentifier)}*/}
//                 {/*</iframe>*/}
//
//             </div>
//
//             <div style={{height: '20px'}}>&nbsp;</div>
//
//         </div>
//     );
// }


// ---------------------------------------------------------------------------------------------------------------------
// ---------------------------------------------------------------------------------------------------------------------
// ---------------------------------------------------------------------------------------------------------------------

export const CUSTOM_DDO_NAME = "custom-ddo-name";
export const CUSTOM_DDO_DESC = "custom-ddo-desc";
export const CUSTOM_DDO_LABEL = "custom-ddo-label";

export const CUSTOM_DDO_QNR_FORMAT_THEME  = "custom-ddo-questionnaire-format-theme"; // (Questionnaire)
export const CUSTOM_DDO_QNR_FORMAT_APP_HEADER  = "custom-ddo-questionnaire-format-app-header"; // (Questionnaire)
export const CUSTOM_DDO_QNR_FORMAT_START_PAGE  = "custom-ddo-questionnaire-format-start-page"; // (Questionnaire)
export const CUSTOM_DDO_QNR_FORMAT_MAIN_PAGES  = "custom-ddo-questionnaire-format-main-pages"; // (Questionnaire)
export const CUSTOM_DDO_QNR_FORMAT_CLOSE_PAGE  = "custom-ddo-questionnaire-format-close-page"; // (Questionnaire)
export const CUSTOM_DDO_QNR_FORMAT_ALERTS  = "custom-ddo-questionnaire-format-alerts"; // (Questionnaire)

export const USER_SCRIPT_BEFORE  = "user-script"; // (Questionnaire)
export const CUSTOM_DDO_QNR_SCRIPT_BEFORE  = "custom-ddo-script-before"; // (Questionnaire)

export const USER_SCRIPT_AFTER  = "user-script-after"; // (Questionnaire)
export const CUSTOM_DDO_QNR_SCRIPT_AFTER  = "custom-ddo-script-after"; // (Questionnaire)

export const IMAGE_APP_TITLE_BAR_LEFT  = "image-app-title-bar-left"; // (Questionnaire)
export const IMAGE_APP_TITLE_BAR_CENTER  = "image-app-title-bar-center"; // (Questionnaire)
export const IMAGE_APP_TITLE_BAR_RIGHT  = "image-app-title-bar-right"; // (Questionnaire)

export const CUSTOM_DDO_CAT_FORMAT  = "custom-ddo-category-format"; // (Cataegory)
export const CUSTOM_DDO_CAT_SCRIPT = "custom-ddo-script";
export const CAT_SCRIPT = "category-script";
export const CUSTOM_DDO_CAT_TITLE_BAR_LEFT = "custom-ddo-category-title-bar-left";
export const CUSTOM_DDO_CAT_TITLE_BAR_CENTER = "custom-ddo-category-title-bar-center";
export const CUSTOM_DDO_CAT_TITLE_BAR_RIGHT = "custom-ddo-category-title-bar-right";

export const CUSTOM_DDO_GRP_FORMAT  = "custom-ddo-group-format"; // (Group)

export const CUSTOM_DDO_QUE_FORMAT  = "custom-ddo-question-format"; // (Question)
export const DISPLAY_AS  = "display-as"; // (Question)
export const ALIGN_QUESTION_ANSWER_RIGHT  = "align-question-answer-right"; // (Question)
export const DISPLAY_DEF_LIKERT_QUESTION_ALIGN_WITH_ANSWERS  = "display-def-likert-question-align-with-answers"; // (Question)
export const DISPLAY_DEF_SHOW_GROUP_STANDARD  = "display-def-show-group-standard"; // (Question)

export const CUSTOM_DDO_ANSWER_FORMAT  = "custom-ddo-answer-format"; // (Answer)

export const USE_LIKERT_GRAYBAR_HEADER  = "display-def-use-graybar-header"; // (Group/Question)
export const USE_LIKERT_STANDARD_HEADER  = "display-def-use-standard-header"; // (Group/Question)
export const USE_LIKERT_ANSWERS_HEADER  = "display-def-use-answers-header"; // (Group/Question)  // Answer Text is titles, like for radios and circles only

export const USE_LIKERT_RESPONSIVE_LABEL  = "display-def-use-responsive-label"; // (Answer) Buttongroup - Display value when selected beneath button group square or circle (Responsive Labels)
export const USE_LIKERT_DEFAULT_RESPONSIVE_LABEL  = "display-def-use-default-responsive-label"; // (Question) Buttongroup - Display DEFAULT value when selected beneath button group square or circle (Responsive Labels)

export const DISPLAY_DEF_RADIO_AS_BUTTON_GROUP  = "display-def-radio-as-buttongroup";
export const DISPLAY_DEF_BUTTON_AS_CIRCLE  = "display-def-button-as-circles";
export const DISPLAY_DEF_BUTTON_AS_CIRCLE_COLORS  = "display-def-button-as-circles-colors";
export const DISPLAY_DEF_BUTTON_AS_CIRCLE_COLORS_ALT  = "display-def-button-as-circles-colors-alt";

export const DISPLAY_DEF_HIDE_BUTTONGROUP_NAME  = "display-def-hide-buttongroup-name";

export const DISPLAY_DEF_SHOW_QUESTION_GRAYBAR  = "display-def-show-question-graybar"

export const DISPLAY_DEF_LIKERT_TITLE_LEFT  = "display-def-likert-title-left"
export const DISPLAY_DEF_LIKERT_TITLE_CENTER  = "display-def-likert-title-center"
export const DISPLAY_DEF_LIKERT_TITLE_RIGHT  = "display-def-likert-title-right"


export const customTextObj = {
    title: "",
    hide: false,
    font: "roboto",
    fontFamily: 'Roboto, "Helvetica Neue", Helvetica, Arial, sans-serif',
    fontWeight: "normal",
    colorType: "default",
    colorEntry: 'black',
    color: "#000000",
    backgroundColor: "",
    backgroundImage: "",
    fontSizing: "points",
    fontSizeEntry: "",
    fontSize: "",
    variant: "",
    fontStyle: "normal",
    textAlign: "left",  // left center right
    opacityEntry: "100",
    opacity: "100%",
    icon: "",
    //backgroundColor: 'red',
};


export function initCustomTextObj(titleType) {
    let obj = {...customTextObj};

    let defaultFont = getDefaultVariant(titleType);

    obj.fontSizeEntry = defaultFont.fontSizeEntry;
    obj.fontSize = defaultFont.fontSize;
    obj.variant = defaultFont.variant;

    return obj;
}


export function getDefaultVariant(templateType = "questionnaire", titleType = "appHeaderText") {

    // NOTE: taken from QD Portal: getTitleDefaultVariant() in displayDefObjConstants.js

    let fontDefault = {
        variant: "",
        fontSizeEntry: "",
        fontSize: ""
    };

    // const h1FontSize = '36px';  // Questionnaire
    // const h2FontSize = '31px';  // Category
    // const h3FontSize = '26px';  // Group
    // const h4FontSize = '21px';  // Question
    // const h5FontSize = '16px';  // Answer
    // const h6FontSize = '16px';  // Info/Comment Gray
    // const captionFontSize = '12px'; // Label
    // const subtitle1FontSize = '12px';  // Label Gray
    // const subtitle2FontSize = '12px';  // Label Secondary Color
    // const body1FontSize = '14px';
    // const body2FontSize = '14px'; // Italic
    // const overlineFontSize = '10px';
    // const buttonFontSize = '32px';

    switch(templateType) {
        case "questionnaire":
            switch(titleType) {
                case "name":
                    fontDefault.variant = "h1";
                    fontDefault.fontSizeEntry = "36";
                    fontDefault.fontSize = "36px";
                    break;
                case "description":
                    fontDefault.variant = "h5";
                    fontDefault.fontSizeEntry = "16";
                    fontDefault.fontSize = "16px";
                    break;
                case "script-before":
                    fontDefault.variant = "h5";
                    fontDefault.fontSizeEntry = "16";
                    fontDefault.fontSize = "16px";
                    break;
                case "script-after":
                    fontDefault.variant = "h5";
                    fontDefault.fontSizeEntry = "16";
                    fontDefault.fontSize = "16px";
                    break;
                case "appHeaderText":
                    fontDefault.variant = "h3";
                    fontDefault.fontSizeEntry = "26";
                    fontDefault.fontSize = "26px";
                    break;
                case "pageHeaderText":
                    fontDefault.variant = "h3";
                    fontDefault.fontSizeEntry = "26";
                    fontDefault.fontSize = "26px";
                    break;
                case "pageFooterText":
                    fontDefault.variant = "h3";
                    fontDefault.fontSizeEntry = "26";
                    fontDefault.fontSize = "26px";
                    break;
                case "close-text1":
                    fontDefault.variant = "h3";
                    fontDefault.fontSizeEntry = "26";
                    fontDefault.fontSize = "26px";
                    break;
                case "close-text2":
                    fontDefault.variant = "h4";
                    fontDefault.fontSizeEntry = "21";
                    fontDefault.fontSize = "21px";
                    break;
                case "close-text3":
                    fontDefault.variant = "h5";
                    fontDefault.fontSizeEntry = "16";
                    fontDefault.fontSize = "16px";
                    break;
                // no default
            }
            break;
        case "category":
            switch(this.props.titleType) {
                case "name":
                    fontDefault.variant = "h2";
                    fontDefault.fontSizeEntry = "31";
                    fontDefault.fontSize = "31px";
                    break;
                case "description":
                    fontDefault.variant = "h5";
                    fontDefault.fontSizeEntry = "16";
                    fontDefault.fontSize = "16px";
                    break;
                case "script":
                    fontDefault.variant = "h5";
                    fontDefault.fontSizeEntry = "16";
                    fontDefault.fontSize = "16px";
                    break;
                case "header-left":
                    fontDefault.variant = "h4";
                    fontDefault.fontSizeEntry = "21";
                    fontDefault.fontSize = "21px";
                    break;
                case "header-center":
                    fontDefault.variant = "h4";
                    fontDefault.fontSizeEntry = "21";
                    fontDefault.fontSize = "21px";
                    break;
                case "header-right":
                    fontDefault.variant = "h4";
                    fontDefault.fontSizeEntry = "21";
                    fontDefault.fontSize = "21px";
                    break;
                // no default
            }
            break;
        case "group":
            switch(this.props.titleType) {
                case "name":
                    fontDefault.variant = "h3";
                    fontDefault.fontSizeEntry = "26";
                    fontDefault.fontSize = "26px";
                    break;
                case "description":
                    fontDefault.variant = "h5";
                    fontDefault.fontSizeEntry = "16";
                    fontDefault.fontSize = "16px";
                    break;
                // no default
            }
            break;
        case "question":
            switch(this.props.titleType) {
                case "name":
                    fontDefault.variant = "h6";
                    fontDefault.fontSizeEntry = "16";
                    fontDefault.fontSize = "16px";
                    break;
                case "description":
                    fontDefault.variant = "body1";
                    fontDefault.fontSizeEntry = "14";
                    fontDefault.fontSize = "14px";
                    break;
                case "label":
                    fontDefault.variant = "caption";
                    fontDefault.fontSizeEntry = "12";
                    fontDefault.fontSize = "12px";
                    break;
                // no default
            }
            break;
        // no default
    }

    return fontDefault;
}

export function getQuestionnaireThemeObj(whichTheme = "primary") {

    let tempObj = {
        theme: whichTheme,
        defaultText: "default",
        defaultTextCustomObj: "",
        defaultButton1: "default",
        defaultButton1CustomObj: "",
        defaultButton2: "default",
        defaultButton2CustomObj: "",
    };

    return tempObj;
}

export function getQuestionnaireAppHeaderObj() {

    let tempObj = {
        format: 'default',
        border: "",
        padding: "",
        backgroundColor: "",
        backgroundImage: "",
        boxShaadow: "",
        textLeftType: "none", // text, icon
        textLeft: "",
        textLeftFormat: "default",
        textLeftCustomObj: "",
        textCenterType: "none", // text, icon
        textCenter: "",
        textCenterFormat: "default",
        textCenterCustomObj: "",
        textRightType: "none", // text, icon
        textRight: "",
        textRightFormat: "default",
        textRightCustomObj: "",
    };

    return tempObj;
}

export function getQuestionnaireStartPageObj() {

    let tempObj = {
        format: "default",
    };

    return tempObj;
}

export function getQuestionnaireMainPagesObj() {

    let tempObj = {
        pagingMode: "full",
        answeredFirstQuestion: "hide", // show
        questionNumbering: "none",
        questionNums: "#",
        questionNumsLikert: "none",
        questionNumsShowGroup: "none",
        pageHeader: "no",
        pageHeaderTextLeft: "",
        pageHeaderTextLeftFormat: "default",
        pageHeaderTextLeftCustomObj: "",
        pageHeaderTextCenter: "",
        pageHeaderTextCenterFormat: "default",
        pageHeaderTextCenterCustomObj: "",
        pageHeaderTextRight: "",
        pageHeaderTextRightFormat: "default",
        pageHeaderTextRightCustomObj: "",
        pageFooter: "no",
        pageFooterTextLeft: "",
        pageFooterTextLeftFormat: "default",
        pageFooterTextLeftCustomObj: "",
        pageFooterTextCenter: "",
        pageFooterTextCenterFormat: "default",
        pageFooterTextCenterCustomObj: "",
        pageFooterTextRight: "",
        pageFooterTextRightFormat: "default",
        pageFooterTextRightCustomObj: "",
        pageFooter2: "no",
        pageFooter2TextLeft: "",
        pageFooter2TextLeftFormat: "default",
        pageFooter2TextLeftCustomObj: "",
        pageFooter2TextCenter: "",
        pageFooter2TextCenterFormat: "default",
        pageFooter2TextCenterCustomObj: "",
        pageFooter2TextRight: "",
        pageFooter2TextRightFormat: "default",
        pageFooter2TextRightCustomObj: "",
    };

    return tempObj;
}

export function getQuestionnaireClosePageObj() {

    let tempObj = {
        format: "default",
        image: "greencheck",
        text: "default1",
    };

    return tempObj;
}

// export function getQuestionnaireAlertsObj(alertType) {
//
//     let tempObj = {
//         alerts: alertType,
//         //invalidKeyText: getInvalidKeyAlertText(alertType),
//         compleatedText: getCompleatedAlertText(alertType),
//         deactivatedText: getDeactivatedAlertText(alertType),
//         expiredText: getExpiredAlertText(alertType),
//         canceledText: getCanceledAlertText(alertType),
//     };
//
//     return tempObj;
// }



export function getDefaultCloseText(whichDefault) {

    let selectedDefault = "";
    switch(whichDefault)
    {
        case "default1":
            selectedDefault = "Thank you for providing your feedback to us, we greatly appreciate it. Your answers have been recorded, please close your browser window.";
            break;
        case "default2":
            selectedDefault = "";
            break;
        // no default
    }
    return selectedDefault;
}


// export function getInvalidKeyAlertText(type) {
//
//     let alertText = "";
//     switch(type)
//     {
//         case "survey":
//             alertText = "This survey key is invalid.";
//             break;
//         case "questionnaire":
//             alertText = "This questionnaire key is invalid.";
//             break;
//         case "poll":
//             alertText = "This poll key is invalid.";
//             break;
//         case "custom":
//             alertText = "This key is invalid.";
//             break;
//     }
//     return alertText;
// }


export function getCompleatedAlertText(type) {

    let alertText = "";
    switch(type)
    {
        case "survey":
            alertText = "This survey has already been completed and is no longer available.";
            break;
        case "questionnaire":
            alertText = "This questionnaire has already been completed and is no longer available.";
            break;
        case "poll":
            alertText = "This poll has already been completed and is no longer available.";
            break;
        case "custom":
            alertText = "Previously completed and is no longer available.";
            break;
        // no default
    }
    return alertText;
}


export function getDeactivatedAlertText(type) {

    let alertText = "";
    switch(type)
    {
        case "survey":
            alertText = "This survey has been deactivated.";
            break;
        case "questionnaire":
            alertText = "This questionnaire has been deactivated.";
            break;
        case "poll":
            alertText = "This poll has been deactivated.";
            break;
        case "custom":
            alertText = "Previously deactivated.";
            break;
        // no default
    }
    return alertText;
}


export function getExpiredAlertText(type) {

    let alertText = "";
    switch(type)
    {
        case "survey":
            alertText = "This survey has expired and is no longer available.";
            break;
        case "questionnaire":
            alertText = "This questionnaire has expired and is no longer available.";
            break;
        case "poll":
            alertText = "This poll has expired and is no longer available.";
            break;
        case "custom":
            alertText = "Expired and is no longer available.";
            break;
        // no default
    }
    return alertText;
}


export function getCanceledAlertText(type) {

    let alertText = "";
    switch(type)
    {
        case "survey":
            alertText = "Survey canceled. The link remains active until the expiration date.";
            break;
        case "questionnaire":
            alertText = "Questionnaire canceled. The link remains active until the expiration date.";
            break;
        case "poll":
            alertText = "Poll canceled. The link remains active until the expiration date.";
            break;
        case "custom":
            alertText = "Canceled. The link remains active until the expiration date.";
            break;
        // no default
    }
    return alertText;
}


export function getTextFormatCustomObj() {

    let defaultFont = getDefaultVariant();

    let obj = {
        title: "",
        hide: false,
        font: "roboto",
        fontFamily: 'Roboto, "Helvetica Neue", Helvetica, Arial, sans-serif',
        fontWeight: "normal",
        colorType: "default",
        colorEntry: 'black',
        color: "#000000",
        backgroundColor: "",
        backgroundImage: "",
        fontSizing: "points",
        fontSizeEntry: defaultFont.fontSizeEntry,
        fontSize: defaultFont.fontSize,
        variant: defaultFont.variant,
        fontStyle: "normal",
        textAlign: "left",  // left center right
        opacityEntry: "100",
        opacity: "100%",
        icon: ""
        //backgroundColor: 'red',
    };

    return obj;
}


export function getButtonFormatCustomObj() {

    //let defaultFont = getDefaultVariant();

    let obj = {
        variant: "text",
        background: 'white',
        backgroundImage: "",
        borderColor: '#ff0000',
        borderRadius: 3,
        border: 2,
        lineHeight: 1.5,
        color: 'red',
        height: 48,
        width: 100,
        padding: '0 30px',
        boxShadow: '0 3px 5px 2px rgba(255, 105, 135, .3)',
        fontColor: 'red',
        fontSize: 16,
        fontFamily: 'Roboto, "Helvetica Neue", Helvetica, Arial, sans-serif',
        textOverride: "",
        lebelTextTransform: 'capitalize',
        hoverBackground: 'red',
        hoverBackgroundImage: "",
        hoverBorderColor: '#0000ff',
        hoverBorderRadius: 3,
        hoverBorder: 2,
        hoverLineHeight: 1.5,
        hoverColor: 'yellow',
        hoverHeight: 48,
        hoverWidth: 100,
        hoverPadding: '0 30px',
        hoverBoxShadow: '0 3px 5px 2px rgba(255, 105, 135, .3)',
        hoverFontColor: 'green',
        hoverFontSize: 16,
        hoverFontFamily: 'Roboto, "Helvetica Neue", Helvetica, Arial, sans-serif',
    };

    return obj;
}


export function getFontFamily(font = "roboto") {

    let fontFamily = 'Roboto, "Helvetica Neue", Helvetica, Arial, sans-serif';

    switch (font) {
        case "arial":
            fontFamily = "Arial, Arial, Helvetica, sans-serif";
            break;
        case "arialblack":
            fontFamily = "Arial Black, Arial Black, Gadget, sans-serif";
            break;
        case "comic":
            fontFamily = "Comic Sans MS, Comic Sans MS5, cursive";
            break;
        case "courier":
            fontFamily = "Courier New, Courier New, monospace";
            break;
        case "georgia":
            fontFamily = "Georgia1, Georgia, serif";
            break;
        case "impact":
            fontFamily = "Impact, Impact5, Charcoal6";
            break;
        case "lucida":
            fontFamily = "Lucida Console, Monaco5, monospace";
            break;
        case "lucidasans":
            fontFamily = "Lucida Sans Unicode, Lucida Grande, sans-serif";
            break;
        case "palatino":
            fontFamily = "Palatino Linotype, Book Antiqua3, Palatino, serif";
            break;
        case "roboto":
            fontFamily = 'Roboto, "Helvetica Neue", Helvetica, Arial, sans-serif';
            break;
        case "tahoma":
            fontFamily = "Tahoma, Geneva, sans-serif";
            break;
        case "times":
            fontFamily = "Times New Roman, Times New Roman, Times, serif";
            break;
        case "trebuchet":
            fontFamily = "Trebuchet MS1, Trebuchet MS, sans-serif";
            break;
        case "verdana":
            fontFamily = "Verdana, Verdana, Geneva, sans-serif";
            break;
        // case "mssans":
        //     fontFamily = "MS Sans Serif4, Geneva, sans-serif";
        //     break;
        // case "msserif":
        //     fontFamily = "MS Serif4, New York6, serif";
        //     break;
        // no default
    }

    return fontFamily;
};



// export const ALERT_MESSAGE_QUESTIONNAIRE_INVALID_KEY = "This questionnaire key is invalid.";
// export const ALERT_MESSAGE_QUESTIONNAIRE_COMPLETED = "This questionnaire has already been completed and is no longer available.";
// export const ALERT_MESSAGE_QUESTIONNAIRE_DEACTIVATED = "This questionnaire has been deactivated.";
// export const ALERT_MESSAGE_QUESTIONNAIRE_EXPIRED = "This questionnaire has expired and is no longer available.";
// export const ALERT_MESSAGE_QUESTIONNAIRE_CANCELED = "Questionnaire canceled. The link remains active until the expiration date.";







