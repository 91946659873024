import * as types from "../constants/actionConstants";
import * as portalTypes from "../portal/constants/actionConstants";


const addAnswer = (isGroupMode, answer, depth = 0) => {

    let answerOption = {
        templateKey: "",
        optionText: "",
        importActionKeys: [],
        importCategoryKeys: [],
        importGroupKeys: [],
        metadata: {},
        ...answer
    };

    return (dispatch) => {
        dispatch({type: isGroupMode ? types.ANSWER_EDITOR_ADD_GROUP_ANSWER : types.ANSWER_EDITOR_ADD_QUESTION_ANSWER,
            answer: answerOption,
            depth: depth
        });
        dispatch({type: portalTypes.INCREMENT_PORTAL_COUNT})
    };
};

const updateAnswer = (isGroupMode, answer, index, depth = 0) => {
    return (dispatch) => {
        dispatch({type: isGroupMode ? types.ANSWER_EDITOR_UPDATE_GROUP_ANSWER : types.ANSWER_EDITOR_UPDATE_QUESTION_ANSWER,
            answer: answer,
            index: index,
            depth: depth
        });
        dispatch({type: portalTypes.INCREMENT_PORTAL_COUNT});
    };
};

const removeAnswer = (isGroupMode, index, depth = 0) => {
    return (dispatch) => {
        dispatch({type: isGroupMode ? types.ANSWER_EDITOR_REMOVE_GROUP_ANSWER : types.ANSWER_EDITOR_REMOVE_QUESTION_ANSWER,
            index: index,
            depth: depth
        });
        dispatch({type: portalTypes.INCREMENT_PORTAL_COUNT});
    };
};

const reorderAnswer = (isGroupMode, isUp, currentIndex, depth = 0) => {
    return (dispatch) => {
        dispatch({type: isGroupMode ? types.ANSWER_EDITOR_REORDER_GROUP_ANSWER : types.ANSWER_EDITOR_REORDER_QUESTION_ANSWER,
            isUp: isUp,
            currentIndex: currentIndex,
            depth: depth
        });
        dispatch({type: portalTypes.INCREMENT_PORTAL_COUNT});
    };
};

const setShowGroup = (newShowGroupAdded, answerIndex) => {
    return {
        type: types.ANSWER_SHOW_GROUP_SUCCESS,
        newShowGroupAdded,
        answerIndex,
    };
};

const clearShowGroup = (newShowGroupAdded, answerIndex) => {
    return {
        type: types.ANSWER_SHOW_GROUP_CLEAR,
        newShowGroupAdded,
        answerIndex,
    };
}


export {
    addAnswer,
    updateAnswer,
    removeAnswer,
    reorderAnswer,
    setShowGroup,
    clearShowGroup
};
