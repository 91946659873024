import * as types from "../constants/actionConstants";


const initialState = {};

function questionnaireTemplateGraphReducer(state = initialState, action) {
    switch (action.type) {
        case types.GET_QUESTIONNAIRE_GRAPH_SUCCESS:
            return {...action.questionnaireTemplateGraph};
        case types.QUESTIONNAIRE_SCORING_TEMPLATE_CLEAR:
            return {
                ...initialState
            };
        default:
            return state;
    }
}

export default questionnaireTemplateGraphReducer;
