import React from 'react';
import PropTypes from 'prop-types';
import {connect} from "react-redux";
import {useNavigate} from "react-router-dom";
import {bindActionCreators} from "redux";
import {Outlet} from 'react-router-dom'; //NOTE: Outlet Required see: https://reactrouter.com/en/main/components/outlet
import "es6-string-polyfills";
import Drawer from "@mui/material/Drawer";
import Divider from "@mui/material/Divider";
import {QD_HELP_URL, QD_HELP_URL_QUESTION, QD_PORTAL} from "./constants/systemConstants";
import {openDrawer} from './actions/drawerActions';
import {getSystemSetting} from "./actions/systemSettingActions";
import {logout} from "./actions/loginActions";
import pageNames from "./utilities/helpMap"; // An array of main menu page names
import HelpDrawer, {helpProps} from "../src/components/drawers/HelpDrawer";
import ArgoAppBar from "./components/common/ArgoAppBar"
import ArgoAboutDialogHook from "./components/common/ArgoAboutDialogHook"
import ArgoLeftNavLinkHook from "./components/common/ArgoLeftNavLinkHook"
import SetPortalUrlHook from "./portal/utilities/SetPortalUrlHook"
import './App.css';
import style from "./adminTheme";
import ClickAwayListener from '@mui/material/ClickAwayListener';
import {QD_URI_PREFIX, REACT_APP_PORTAL_MODE} from "./constants/systemConstants";


class App extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            leftNavDrawerOpen: false,
            helpDrawerOpen: false,
            helpDrawerPath: "",
            aboutDialogOpen: false
        };
    }

    componentWillMount() {
        if (REACT_APP_PORTAL_MODE === "false") {
            this.props.getSystemSetting();
        }
    }

    toggleLeftNavDrawer = () => {
        this.setState({leftNavDrawerOpen: !this.state.leftNavDrawerOpen});
    };

    closeLeftNavDrawer = (event) => {
        this.setState({leftNavDrawerOpen: false});
    };

    openHelpTab = (event) => {
        window.open(QD_HELP_URL);
    };

    openAboutDialog = (event) => {
        this.setState({aboutDialogOpen: true});
    };

    closeAboutDialog = () => {
        this.setState({aboutDialogOpen: false});
    };

    openHelpDrawer = () => {

        let questionHelp = QD_HELP_URL_QUESTION;
        let helpMapPages = pageNames; // An array of main menu page names
        let pathName = this.props.location.pathname;

        // remove trailing slash
        if (pathName.charAt( pathName.length-1 ) === "/") {
            pathName = pathName.slice(0, -1);
        }

        let page = pathName.substr(pathName.lastIndexOf('/') +1);
        if (!page) page = "";
        let path = questionHelp + helpMapPages[page]; // Find correct parent page name Example: Questionnaire List Page

        this.props.openDrawer(helpProps, <HelpDrawer src={path}/>);
        //this.setState({helpDrawerOpen: true, helpDrawerPath: path});
    };

    closeHelpDrawer = () => {
        this.setState({helpDrawerOpen: false});
    };

    closeVersionsPage = (pathname) => {

        let path = "";

        if (pathname.startsWith("/qd2admin/questionnaire-versions")) {
            path = "/qd2admin/questionnaires";
        } else if (pathname.startsWith("/qd2admin/category-versions")) {
            path = "/qd2admin/categories";
        } else if (pathname.startsWith("/qd2admin/group-versions")) {
            path = "/qd2admin/groups";
        } else if (pathname.startsWith("/qd2admin/question-versions")) {
            path = "/qd2admin/questions";
        } else if (pathname.startsWith("/qd2admin/action-versions")) {
            path = "/qd2admin/actions";
        } else if (pathname.startsWith("/qd2admin/integration-logs")) {
            path = "/qd2admin/integrations";
        }

        this.props.navHook(path, {replace: true});
    }

    displayCorrectArgoAppBar = () => {

        let pathname = this.props.location.pathname;

        // // Add the correct style app bar depending on current location
        if ((pathname.startsWith("/qd2admin/questionnaire-versions")) ||
            (pathname.startsWith("/qd2admin/category-versions")) ||
            (pathname.startsWith("/qd2admin/group-versions")) ||
            (pathname.startsWith("/qd2admin/question-versions")) ||
            (pathname.startsWith("/qd2admin/action-versions")) ||
            (pathname.startsWith("/qd2admin/integration-logs")))
        {
            return <ArgoAppBar onLeftIconButtonTouchTap={this.closeVersionsPage.bind(this, pathname)} showMenuArrowButton="LEFT" childPage={true}/>;
        }
        else if (pathname.startsWith("/qd2admin/questionnaire-editor")) {
            let slashCount = pathname.split("/").length - 1; // NOTE: In create mode there will only be 3 slashes in the URL
            if (pathname.slice(-1) !== "1") {
                return <ArgoAppBar title={slashCount === 3 ? "Create Questionnaire" : "Edit Questionnaire"} showMenuIconButton={false} noIcon={true} isDrawer={true}/> // ArgoAppBar in QuestionnaireContainer.js
            }
            else {
                return <ArgoAppBar title={"Clone Questionnaire"} showMenuIconButton={false} noIcon={true} isDrawer={true}/> // ArgoAppBar in QuestionnaireContainer.js
            }
        }
        else {
            return <ArgoAppBar showMenuIconButton={true} onLeftIconButtonTouchTap={this.toggleLeftNavDrawer.bind(this)} showHelpIconButton={true} onRightIconButtonTouchTap={this.openHelpDrawer.bind(this)}/>;
        }
    };


    render() {

        return (
            <div className="App">
                {this.props.portalMode === "true" ?
                    <div>
                        <SetPortalUrlHook url={QD_PORTAL}/>
                    </div>
                    :
                    <div>
                        {this.displayCorrectArgoAppBar()}
                        {this.state.leftNavDrawerOpen ?
                            <Drawer
                                open={this.state.leftNavDrawerOpen}
                                PaperProps={{sx: {width: style.leftNav.width, backgroundColor: style.paper.backgroundColor}}}
                                sx={{width: style.leftNav.width}}
                            >
                                <ClickAwayListener onClickAway={this.closeLeftNavDrawer}>
                                    <div>
                                        <ArgoAppBar showMenuArrowButton="LEFT" onLeftIconButtonTouchTap={this.toggleLeftNavDrawer}
                                                    isDrawer={true}/>
                                        <div style={{position: "fixed", top: "75px", width: style.leftNav.width}}>
                                            <ArgoLeftNavLinkHook linkTo="/questionnaires" title="Questionnaires"
                                                                 onClick={this.closeLeftNavDrawer.bind(this)}/>
                                            <ArgoLeftNavLinkHook linkTo="/categories" title="Categories"
                                                                 onClick={this.closeLeftNavDrawer.bind(this)}/>
                                            <ArgoLeftNavLinkHook linkTo="/groups" title="Groups"
                                                                 onClick={this.closeLeftNavDrawer.bind(this)}/>
                                            <ArgoLeftNavLinkHook linkTo="/questions" title="Questions"
                                                                 onClick={this.closeLeftNavDrawer.bind(this)}/>
                                            <ArgoLeftNavLinkHook linkTo="/actions" title="Actions"
                                                                 onClick={this.closeLeftNavDrawer.bind(this)}/>
                                            <ArgoLeftNavLinkHook linkTo="/search-tags" title="Tags"
                                                                 onClick={this.closeLeftNavDrawer.bind(this)}/>
                                            <ArgoLeftNavLinkHook linkTo="/import" title="Import Questionnaire"
                                                                 onClick={this.closeLeftNavDrawer.bind(this)}/>
                                            <ArgoLeftNavLinkHook linkTo="/integrations" title="Integrations"
                                                                 onClick={this.closeLeftNavDrawer.bind(this)}/>
                                            <ArgoLeftNavLinkHook title="Help" onClick={this.openHelpTab.bind(this)}/>
                                            <ArgoLeftNavLinkHook title="About" onClick={this.openAboutDialog.bind(this)}/>
                                        </div>
                                        <div style={{position: "fixed", bottom: "0", width: style.leftNav.width}}>
                                            <Divider/>
                                            <ArgoLeftNavLinkHook onClick={this.props.logout} title="Log out"/>
                                        </div>
                                    </div>
                                </ClickAwayListener>

                            </Drawer> : "" }

                        {/*<HelpDrawer open={this.state.helpDrawerOpen} closeDrawer={this.closeHelpDrawer} src={this.state.helpDrawerPath}/>*/}

                        <ArgoAboutDialogHook fileName="App" open={this.state.aboutDialogOpen} close={this.closeAboutDialog}/>
                    </div>
                }

                <Outlet/>

            </div>

        );
    }
}

App.defaultProps = {
    portalMode: "false"
};

App.propTypes = {
    getSystemSetting: PropTypes.func,
    openDrawer: PropTypes.func,
    location: PropTypes.object,
    history: PropTypes.object,
    logout: PropTypes.func,
    navHook: PropTypes.func,
    portalMode: PropTypes.string
};

function mapDispatchToProps(dispatch) {
    return bindActionCreators({getSystemSetting, openDrawer, logout}, dispatch);

}

function myParams(Component,props) {
    return props => <Component {...props} navHook={useNavigate()} />;
}

export default connect(null, mapDispatchToProps)(myParams(App));























// import React from "react";
// import Toolbar from "@mui/material//Toolbar";
// import AppBar from "@mui/material//AppBar";
// import Typography from "@mui/material//Typography";
// import IconButton from "@mui/material//IconButton";
// import Button from "@mui/material//Button";
// import MenuIcon from "@mui/material/Menu";
//
// const App = () => {
//   return (
//       <div>
//         <AppBar position="static">
//           <Toolbar>
//             <IconButton
//                 edge="start"
//                 style={{
//                   marginRight: 20,
//                 }}
//                 color="primary"
//                 aria-label="menu"
//             >
//               <MenuIcon />
//             </IconButton>
//             <Typography
//                 variant="h6"
//                 style={{
//                   flexGrow: 1,
//                 }}
//             >
//               Dashboard
//             </Typography>
//             <Button color="inherit">Logout</Button>
//           </Toolbar>
//         </AppBar>
//       </div>
//   );
// };
//
// export default App;





// import logo from './logo.svg';
// import './App.css';
//
//
// function App() {
//   return (
//     <div className="App">
//       <header className="App-header">
//         <img src={logo} className="App-logo" alt="logo" />
//         <p>
//           Edit <code>src/App.js</code> and save to reload.
//         </p>
//         <a
//           className="App-link"
//           href="https://reactjs.org"
//           target="_blank"
//           rel="noopener noreferrer"
//         >
//           Learn React
//         </a>
//       </header>
//     </div>
//   );
// }
//
// export default App;