import * as React from "react";

function SvgDoublePositive(props) {
  return (
    <svg width={40} height={40} {...props}>
      <defs>
        <path
          id="doublePositive_svg__c"
          d="M5 0v2.999L8 3v2H5v3H3V5H0V3h3V0h2z"
        />
        <path
          id="doublePositive_svg__e"
          d="M5 0v2.999L8 3v2H5v3H3V5H0V3h3V0h2z"
        />
        <rect
          id="doublePositive_svg__a"
          x={0}
          y={0}
          width={36}
          height={36}
          rx={18}
        />
      </defs>
      <g transform="translate(2 2)" fill="none" fillRule="evenodd">
        <mask id="doublePositive_svg__b" fill="#fff">
          <use xlinkHref="#doublePositive_svg__a" />
        </mask>
        <use fill="#FFF" xlinkHref="#doublePositive_svg__a" />
        <g mask="url(#doublePositive_svg__b)" fill={props.fill} fillOpacity={props.opacity}>
          <path d="M0 0h36v36H0z" />
        </g>
        <g mask="url(#doublePositive_svg__b)">
          <g transform="translate(7 14)">
            <mask id="doublePositive_svg__d" fill="#fff">
              <use xlinkHref="#doublePositive_svg__c" />
            </mask>
            <use fill="#F4F4F4" xlinkHref="#doublePositive_svg__c" />
            <g mask="url(#doublePositive_svg__d)" fill="#FFF">
              <path d="M-2-2h12v12H-2z" />
            </g>
          </g>
        </g>
        <g mask="url(#doublePositive_svg__b)">
          <g transform="translate(21 14)">
            <mask id="doublePositive_svg__f" fill="#fff">
              <use xlinkHref="#doublePositive_svg__e" />
            </mask>
            <use fill="#F4F4F4" xlinkHref="#doublePositive_svg__e" />
            <g mask="url(#doublePositive_svg__f)" fill="#FFF">
              <path d="M-2-2h12v12H-2z" />
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}

export default SvgDoublePositive;
