import {createTheme} from '@mui/material/styles';
import {lightBlue, blue, blueGrey, red} from '@mui/material/colors';
import * as font from "./utilities/fontUtilities";


// Based on http://argowiki/display/ux/Color+schemes
const primaryColorMain = lightBlue[900];
const secondaryColorMain = blue[500];

const textSecondaryMain = '#FFFFFF'; // White ZZZZZ

const warningColor = '#f57c00'; // RGB 245, 124, 0
const errorColor   = '#e43835'; // RGB 228, 57, 53
const successColor = '#4caf50'; // RGB 76, 175, 80

const largeToolbarHeight = 64/*px*/;
const mediumToolbarHeight = 60/*px*/;

//const largeFontSize = '20pt';
//const mediumFontSize = '16pt';
//const smallFontSize = '14pt';
const tinyFontSize = '12pt';

const h1FontSize = font.FONTSIZE.H1;  // Questionnaire
const h2FontSize = font.FONTSIZE.H2;  // Category
const h3FontSize = font.FONTSIZE.H3;  // Group
const h4FontSize = font.FONTSIZE.H4;  // Question
const h5FontSize = font.FONTSIZE.H5;  // Answer
const h6FontSize = font.FONTSIZE.H6;  // Info/Comment Gray
const captionFontSize = font.FONTSIZE.CAPTION; // Label
const subtitle1FontSize = font.FONTSIZE.SUBTITLE1;  // Label Gray
const subtitle2FontSize = font.FONTSIZE.SUBTITLE2;  // Label Secondary Color
const body1FontSize = font.FONTSIZE.BODY1;
const body2FontSize = font.FONTSIZE.BODY2; // Italic
const overlineFontSize = font.FONTSIZE.OVERLINE;
//const buttonFontSize = font.FONTSIZE.BUTTON;

const h1FontWeight = font.FONTWEIGHT.H1;  // Questionnaire
const h2FontWeight = font.FONTWEIGHT.H2;  // Category
const h3FontWeight = font.FONTWEIGHT.H3;  // Group
const h4FontWeight = font.FONTWEIGHT.H4;  // Question
const h5FontWeight = font.FONTWEIGHT.H5;  // Answer
const h6FontWeight = font.FONTWEIGHT.H6;  // Info/Comment Gray
const captionFontWeight = font.FONTWEIGHT.CAPTION; // Label
const subtitle1FontWeight = font.FONTWEIGHT.SUBTITLE1;  // Label Gray
const subtitle2FontWeight = font.FONTWEIGHT.SUBTITLE2;  // Label Secondary Color
const body1FontWeight = font.FONTWEIGHT.BODY1;
const body2FontWeight = font.FONTWEIGHT.BODY2; // Italic
const overlineFontWeight = font.FONTWEIGHT.OVERLINE;
//const buttonFontWeight = font.FONTWEIGHT.BUTTON;

const h1FontStyle = font.FONTSTYLE.H1;  // Questionnaire
const h2FontStyle = font.FONTSTYLE.H2;  // Category
const h3FontStyle= font.FONTSTYLE.H3;  // Group
const h4FontStyle = font.FONTSTYLE.H4;  // Question
const h5FontStyle = font.FONTSTYLE.H5;  // Answer
const h6FontStyle = font.FONTSTYLE.H6;  // Info/Comment Gray
const captionFontStyle = font.FONTSTYLE.CAPTION; // Label
const subtitle1FontStyle = font.FONTSTYLE.SUBTITLE1;  // Label Gray
const subtitle2FontStyle = font.FONTSTYLE.SUBTITLE2;  // Label Secondary Color
const body1FontStyle = font.FONTSTYLE.BODY1;
const body2FontStyle = font.FONTSTYLE.BODY2; // Italic
const overlineFontStyle = font.FONTSTYLE.OVERLINE;
//const buttonFontStyle = font.FONTSTYLE.BUTTON;

const h1FontColor = font.FONTCOLOR.H1;  // Questionnaire
const h2FontColor = font.FONTCOLOR.H2;  // Category
const h3FontColor = font.FONTCOLOR.H3;  // Group
const h4FontColor = font.FONTCOLOR.H4;  // Question
const h5FontColor = font.FONTCOLOR.H5;  // Answer
const h6FontColor = font.FONTCOLOR.H6;  // Info/Comment Gray
const captionFontColor = font.FONTCOLOR.CAPTION; // Label
const subtitle1FontColor = font.FONTCOLOR.SUBTITLE1;  // Label Gray
const subtitle2FontColor = font.FONTCOLOR.SUBTITLE2;  // Label Secondary Color
const body1FontColor = font.FONTCOLOR.BODY1;
const body2FontColor= font.FONTCOLOR.BODY2; // Italic
const overlineFontColor = font.FONTCOLOR.OVERLINE;
//const buttonFontColor = font.FONTCOLOR.BUTTON;

export function createCustomTheme(customColor, useDarkMode = false) {

    // We need a reference to a "default" theme object so that we can access breakpoints correctly.
    let baseTheme = createTheme();

    let useColor = primaryColorMain;

    const _FormControlLabel = '& .MuiFormControlLabel-root';
    const _xsColumn = '& .MuiGrid-direction-xs-column';
    const _xsButtonFullWidth = '& .MuiButton-fullWidth';
    const _IconButton = '& .MuiIconButton-root';
    const _ButtonOutlined = '& .MuiButton-outlined';

    // Changed from 960 to match responsiveness from the mdl website.
    baseTheme.breakpoints.values.md = 1024;


    if(customColor !== "") {
        if(customColor === 'red') {
            useColor = red[800]
        }
    }

    if(useDarkMode === true) {
        useColor = blueGrey[800];
    }

    let result = createTheme({
        breakpoints: baseTheme.breakpoints,
        palette: {
            // Based on https://material-ui.com/customization/color/#color-tool
            // and https://material-ui.com/customization/palette/#example
            type: useDarkMode ? "dark" : "light",
            primary: {
                main: useColor
                // main: green[800]
                // main: primaryColorMain
            },
            secondary: {
                main: secondaryColorMain
            },
            textSecondary: {
                main: textSecondaryMain
            },
            error: {
                main: errorColor
            }
        },
        shape: {
            // Slightly rounded corners for our containers (dialogs, cards, paper, etc.)
            borderRadius: 2,
        },
        mixins: {
            // By default, this controls how the top application bar grows/shrinks
            // as the window resizes.  This is removed here and defined below.
            toolbar: {}
        },
        typography: {
            // Note that "Roboto" is a font that lives in the assets/fonts folder.
            // We're not using the "typeface-roboto" npm package.
            // This matches the exact font of the mdl website.
            fontFamily: font.DEFAULT_FONT_FAMILY,
            h1: {
                fontSize: h1FontSize,
                fontWeight: h1FontWeight,
                lineHeight: 'normal',
                fontStyle: h1FontStyle,
                letterSpacing: 'normal',
                color: h1FontColor,
            },
            h2: {
                fontSize: h2FontSize,
                fontWeight: h2FontWeight,
                lineHeight: 'normal',
                fontStyle: h2FontStyle,
                letterSpacing: 'normal',
                color: h2FontColor,
            },
            h3: {
                fontSize: h3FontSize,
                fontWeight: h3FontWeight,
                lineHeight: 'normal',
                fontStyle: h3FontStyle,
                letterSpacing: 'normal',
                color: h3FontColor,
            },
            h4: {
                fontSize: h4FontSize,
                fontWeight: h4FontWeight,
                lineHeight: 'normal',
                fontStyle: h4FontStyle,
                letterSpacing: 'normal',
                color: h4FontColor,
            },
            h5: {
                fontSize: h5FontSize,
                fontWeight: h5FontWeight,
                lineHeight: 'normal',
                fontStyle: h5FontStyle,
                letterSpacing: 'normal',
                color: h5FontColor,
            },
            h6: {
                fontSize: h6FontSize,
                fontWeight: h6FontWeight,
                lineHeight: 'normal',
                fontStyle: h6FontStyle,
                letterSpacing: 'normal',
                color: h6FontColor,
            },
            caption: {
                fontSize: captionFontSize,
                fontWeight: captionFontWeight,
                lineHeight: 'normal',
                fontStyle: captionFontStyle,
                letterSpacing: 'normal',
                color: captionFontColor,
            },
            subtitle1: {
                fontSize: subtitle1FontSize,
                fontWeight: subtitle1FontWeight,
                lineHeight: 'normal',
                fontStyle: subtitle1FontStyle,
                letterSpacing: 'normal',
                color: subtitle1FontColor,
            },
            subtitle2: {
                fontSize: subtitle2FontSize,
                fontWeight: subtitle2FontWeight,
                lineHeight: 'normal',
                fontStyle: subtitle2FontStyle,
                letterSpacing: 'normal',
                color: subtitle2FontColor,
            },
            body1: {
                fontSize: body1FontSize,
                fontWeight: body1FontWeight,
                lineHeight: 'normal',
                fontStyle: body1FontStyle,
                letterSpacing: 'normal',
                color: body1FontColor,
            },
            body2: {
                fontSize: body2FontSize,
                fontWeight: body2FontWeight,
                lineHeight: 'normal',
                fontStyle: body2FontStyle,
                letterSpacing: 'normal',
                color: body2FontColor,
            },
            overline: {
                fontSize: overlineFontSize,
                fontWeight: overlineFontWeight,
                lineHeight: 'normal',
                fontStyle: overlineFontStyle,
                letterSpacing: 'normal',
                color: overlineFontColor,
            },
            // MuiAppBar: createTopAppBarStyles(baseTheme),
            MuiToolbar: createGrowAndShrinksRulesForToolbar(baseTheme),
            MuiGrid: {
                root: {
                    [_xsColumn]: {
                       /* [baseTheme.breakpoints.down('xs')]: {
                            flexDirection: 'unset',
                        }*/
                    },
                    [_xsButtonFullWidth]: {
                        minWidth: '360px',
                        maxWidth: '360px',
                    /*    [baseTheme.breakpoints.down('xs')]: {
                            minWidth: '300px',
                        }*/
                    },
                    [_IconButton]: {
                        // padding: '0px',
                        // [baseTheme.breakpoints.down('xs')] : {
                        //     padding: '0px'
                        // }
                    }
                }
            },
            MuiFormControl: {
                root: {
                    [_FormControlLabel]: {
                    /*    [baseTheme.breakpoints.down('xs')]: {
                            marginRight: '5px',
                            marginLeft: '5px'
                        }*/
                    },
                    marginBottom: '20px'
                }
            },
            MuiFormLabel: {
                root: {
                    '&.Mui-focused:not(.Mui-error)': {
                        // When an input control is focused, make sure the label is light blue.
                        color: secondaryColorMain
                    }
                }
            },
            MuiInputLabel: {
                shrink: {
                    // Make the shrunken labels a bit thicker to match the mdl site.
                    fontWeight: 500
                },
                formControl: {
                    // Make the normal-sized labels a bit closer to the underline to match the mdl site.
                    transform: 'translate(0, 19px) scale(1)'
                }
            },
            MuiInputBase: {
                input: {
                    // Make the input elements a bit smaller
                    padding: '0px 0 5px'
                }
            },
            MuiInput: {
                formControl: {
                    // Read this as "label, then the next descendant of the form control"
                    'label + &': {
                        // We need to shrink this from 16px to 10px since the mdl website
                        // had the labels a bit closer to the controls
                        //marginTop: '10px'
                    }
                },
                underline: {
                    '&:before': {
                        // This is the gray line under the input - make it a little bit lighter
                        borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
                    },
                    '&:after': {
                        // When we click an input, a blue line appears in an animation.
                        // Make the animation look like the one used in the mdl site.
                        transition: 'transform 200ms cubic-bezier(0.4, 0, 0.2, 1)',
                        borderBottom: `2px solid ${secondaryColorMain}`
                    },
                    '&:hover:not(.Mui-disabled):before': {
                        // Material UI shows a special animation when hovering over an input.
                        // The mdl site didn't have this, so get rid of it.
                        borderBottom: '1px solid rgba(0, 0, 0, 0.12)'
                    }
                }
            },
            MuiFormHelperText: {
                root: {
                    // Make the helper/error text smaller
                    marginTop: '8px',
                    fontSize: tinyFontSize,
                    fontWeight: 500
                }
            },
            MuiTouchRipple: {
                ripplePulsate: {
                    // Don't display the "pulsate" animation when a button is focused.
                    display: 'none'
                }
            },
            MuiButtonBase: {
                root: {
                    // Make buttons reuse the "hover" styling when they are focused.
                    // This is needed since we disabled the "pulsate" animation above.
                    '&.Mui-focusVisible': {
                        backgroundColor: 'rgba(158, 158, 158, 0.2)'
                    },
                    '&:hover': {
                        backgroundColor: 'rgba(158, 158, 158, 0.2)'
                    }
                }
            },
            MuiButton: {
                contained: {
                    // Make "contained" buttons (Yes/No questions, ButtonList questions) have a wider dropshadow
                    // and a darker background when focused.
                    '&.Mui-focusVisible': {
                        ...getFocusedContainedButtonShadow(),
                        backgroundColor: 'rgba(158, 158, 158, 0.4)'
                    }
                },
                containedPrimary: {
                    '&.Mui-focusVisible': {
                        backgroundColor: primaryColorMain
                    }
                },
                outlined: {
                    [_ButtonOutlined]: {
                    /*    [baseTheme.breakpoints.down('xs')]: {
                            // padding: '0px'
                        }*/
                    },
                }
            },
            MuiButtonGroup: {
                root: {
                    // float: 'right',
                    marginRight: '20px',
                    padding: '10px',
                 /*   [baseTheme.breakpoints.down('xs')]: {
                        float: 'unset',
                        padding: '0px',
                        marginRight: '0px',
                    }*/
                },
                grouped: {
               /*     [baseTheme.breakpoints.down('xs')]: {
                        minWidth: '32px',
                        minHeight: '32px',
                        padding: '0px',
                    }*/
                }
            }
        }
    });

    return result;
}


export function createDefaultTheme() {

    // We need a reference to a "default" theme object so that we can access breakpoints correctly.
    let baseTheme = createTheme();

    const _FormControlLabel = '& .MuiFormControlLabel-root';
    const _xsColumn = '& .MuiGrid-direction-xs-column';
    const _xsButtonFullWidth = '& .MuiButton-fullWidth';
    const _IconButton = '& .MuiIconButton-root';
    const _ButtonOutlined = '& .MuiButton-outlined';

    // Changed from 960 to match responsiveness from the mdl website.
    baseTheme.breakpoints.values.md = 1024;

    let result = createTheme({
        breakpoints: baseTheme.breakpoints,
        palette: {
            // Based on https://material-ui.com/customization/color/#color-tool
            // and https://material-ui.com/customization/palette/#example
            type: "light",
            primary: {
                main: primaryColorMain
            },
            secondary: {
                main: secondaryColorMain
            },
            textSecondary: {
                main: textSecondaryMain
            },
            error: {
                main: errorColor
            }
        },
        shape: {
            // Slightly rounded corners for our containers (dialogs, cards, paper, etc.)
            borderRadius: 2,
        },
        mixins: {
            // By default, this controls how the top application bar grows/shrinks
            // as the window resizes.  This is removed here and defined below.
            toolbar: {}
        },
        typography: {
            // Note that "Roboto" is a font that lives in the assets/fonts folder.
            // We're not using the "typeface-roboto" npm package.
            // This matches the exact font of the mdl website.
            fontFamily: font.DEFAULT_FONT_FAMILY,
            h1: {
                fontSize: h1FontSize,
                fontWeight: 'normal',
                lineHeight: 'normal',
                fontStyle: 'normal',
                letterSpacing: 'normal'
            },
            h2: {
                fontSize: h2FontSize,
                fontWeight: 'normal',
                lineHeight: 'normal',
                fontStyle: 'normal',
                letterSpacing: 'normal'
            },
            h3: {
                fontSize: h3FontSize,
                fontWeight: 'normal',
                lineHeight: 'normal',
                fontStyle: 'normal',
                letterSpacing: 'normal'
            },
            h4: {
                fontSize: h4FontSize,
                fontWeight: 'normal',
                lineHeight: 'normal',
                fontStyle: 'normal',
                letterSpacing: 'normal'
            },
            h5: {
                fontSize: h5FontSize,
                fontWeight: 'normal',
                lineHeight: 'normal',
                fontStyle: 'normal',
                letterSpacing: 'normal'
            },
            h6: {
                fontSize: h6FontSize,
                fontWeight: 'normal',
                lineHeight: 'normal',
                fontStyle: 'normal',
                letterSpacing: 'normal',
                color: 'rgba(0, 0, 0, 0.54)'
            },
            caption: {
                fontSize: captionFontSize,
                fontWeight: 'normal',
                lineHeight: 'normal',
                fontStyle: 'normal',
                letterSpacing: 'normal'
            },
            subtitle1: {
                fontSize: subtitle1FontSize,
                fontWeight: 'normal',
                lineHeight: 'normal',
                fontStyle: 'normal',
                letterSpacing: 'normal',
                color: 'rgba(0, 0, 0, 0.54)'
            },
            subtitle2: {
                fontSize: subtitle2FontSize,
                fontWeight: 'normal',
                lineHeight: 'normal',
                fontStyle: 'normal',
                letterSpacing: 'normal',
                color: primaryColorMain
            },
            body1: {
                fontSize: body1FontSize,
                fontWeight: 'normal',
                lineHeight: 'normal',
                fontStyle: 'normal',
                letterSpacing: 'normal'
            },
            body2: {
                fontSize: body2FontSize,
                fontWeight: 'normal',
                lineHeight: 'normal',
                fontStyle: 'italic',
                letterSpacing: 'normal'
            },
            overline: {
                fontSize: overlineFontSize,
                fontWeight: 'normal',
                lineHeight: 'normal',
                fontStyle: 'normal',
                letterSpacing: 'normal',
                color: 'rgba(0, 0, 0, 0.54)'
            },
        },
        // MuiAppBar: createTopAppBarStyles(baseTheme),
        MuiToolbar: createGrowAndShrinksRulesForToolbar(baseTheme),
        MuiGrid: {
            root: {
                [_xsColumn]: {
                   /* [baseTheme.breakpoints.down('xs')]: {
                        flexDirection: 'unset',
                    }*/
                },
                [_xsButtonFullWidth]: {
                    minWidth: '360px',
                    maxWidth: '360px',
                 /*   [baseTheme.breakpoints.down('xs')]: {
                        minWidth: '300px',
                    }*/
                },
                [_IconButton]: {
                    // padding: '0px',
                    // [baseTheme.breakpoints.down('xs')] : {
                    //     padding: '0px'
                    // }
                }
            }
        },
        MuiFormControl: {
            root: {
                [_FormControlLabel]: {
               /*     [baseTheme.breakpoints.down('xs')]: {
                        marginRight: '5px',
                        marginLeft: '5px'
                    }*/
                },
                marginBottom: '20px'
            }
        },
        MuiFormLabel: {
            root: {
                '&.Mui-focused:not(.Mui-error)': {
                    // When an input control is focused, make sure the label is light blue.
                    color: secondaryColorMain
                }
            }
        },
        MuiInputLabel: {
            shrink: {
                // Make the shrunken labels a bit thicker to match the mdl site.
                fontWeight: 500
            },
            formControl: {
                // Make the normal-sized labels a bit closer to the underline to match the mdl site.
                transform: 'translate(0, 19px) scale(1)'
            }
        },
        MuiInputBase: {
            input: {
                // Make the input elements a bit smaller
                padding: '0px 0 5px'
            }
        },
        MuiInput: {
            formControl: {
                // Read this as "label, then the next descendant of the form control"
                'label + &': {
                    // We need to shrink this from 16px to 10px since the mdl website
                    // had the labels a bit closer to the controls
                    //marginTop: '10px'
                }
            },
            underline: {
                '&:before': {
                    // This is the gray line under the input - make it a little bit lighter
                    borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
                },
                '&:after': {
                    // When we click an input, a blue line appears in an animation.
                    // Make the animation look like the one used in the mdl site.
                    transition: 'transform 200ms cubic-bezier(0.4, 0, 0.2, 1)',
                    borderBottom: `2px solid ${secondaryColorMain}`
                },
                '&:hover:not(.Mui-disabled):before': {
                    // Material UI shows a special animation when hovering over an input.
                    // The mdl site didn't have this, so get rid of it.
                    borderBottom: '1px solid rgba(0, 0, 0, 0.12)'
                }
            }
        },
        MuiFormHelperText: {
            root: {
                // Make the helper/error text smaller
                marginTop: '8px',
                fontSize: tinyFontSize,
                fontWeight: 500
            }
        },
        MuiTouchRipple: {
            ripplePulsate: {
                // Don't display the "pulsate" animation when a button is focused.
                display: 'none'
            }
        },
        MuiButtonBase: {
            root: {
                // Make buttons reuse the "hover" styling when they are focused.
                // This is needed since we disabled the "pulsate" animation above.
                '&.Mui-focusVisible': {
                    backgroundColor: 'rgba(158, 158, 158, 0.2)'
                },
                '&:hover': {
                    backgroundColor: 'rgba(158, 158, 158, 0.2)'
                }
            }
        },
        MuiButton: {
            contained: {
                // Make "contained" buttons (Yes/No questions, ButtonList questions) have a wider dropshadow
                // and a darker background when focused.
                '&.Mui-focusVisible': {
                    ...getFocusedContainedButtonShadow(),
                    backgroundColor: 'rgba(158, 158, 158, 0.4)'
                }
            },
            containedPrimary: {
                '&.Mui-focusVisible': {
                    backgroundColor: primaryColorMain
                }
            },
            outlined: {
                [_ButtonOutlined]: {
               /*     [baseTheme.breakpoints.down('xs')]: {
                        // padding: '0px'
                    }*/
                },
            }
        },
        MuiButtonGroup: {
            root: {
                // float: 'right',
                marginRight: '20px',
                padding: '10px',
         /*       [baseTheme.breakpoints.down('xs')]: {
                    float: 'unset',
                    padding: '0px',
                    marginRight: '0px',
                }*/
            },
            grouped: {
        /*        [baseTheme.breakpoints.down('xs')]: {
                    minWidth: '32px',
                    minHeight: '32px',
                    padding: '0px',
                }*/
            }
        }
    });

    return result;
}

// function createTopAppBarStyles(baseTheme) {
//     // '&' allows us to use normal CSS selectors on the root element.
//     const helpButtonSelector = '& button > span';
//     const applicationTitleSelector = '& h1';
//
//     return {
//         // Here we're adding CSS styles to any MuiAppBar that exists in our application.
//         // Today the MuiAppBar exists within the render() function in Header.js
//         // Basically, we want to style the MuiAppBar so that it looks like the one from the
//         // original OAO application.
//         // To see what else is availabe, other than "root", see: https://material-ui.com/api/app-bar/#css
//         root: {
//             // Make the styling of the application title match the styling of the mdl site.
//             [applicationTitleSelector]: {
//                 fontSize: largeFontSize,
//                 fontWeight: 400,
//                 letterSpacing: '0.4px'
//             },
//
//             // Style the help button under the main app bar.
//             [helpButtonSelector]: {
//                 textTransform: 'none',
//                 fontSize: mediumFontSize,
//                 fontWeight: 400
//             }
//         }
//     };
// }

function createGrowAndShrinksRulesForToolbar(baseTheme) {
    // Define how the top application bar grows/shrinks as the window resizes.
    // This matches the behavior of the mdl website.
    return {
        gutters: {
/*            [baseTheme.breakpoints.down('xs')] : {
                height: mediumToolbarHeight,
                paddingLeft: '24px',
                paddingRight: '24px'
            },
            [baseTheme.breakpoints.up('sm')] : {
                height: mediumToolbarHeight,
                paddingLeft: '24px',
                paddingRight: '24px'
            },
            [baseTheme.breakpoints.up('md')] : {
                height: largeToolbarHeight,
                paddingLeft: '24px',
                paddingRight: '24px'
            },*/
        }
    };
}

// export const LayoutStyles = (theme) => createStyles({
//     provideFlexEnvironment: {
//         height: '100%',
//         width: '100%',
//         display: 'flex',
//         flexDirection: 'column'
//     },
//     flexToCenterContent: {
//         display: 'flex',
//
//         // Make sure the vertical scroll bar only appears under the header.
//         overflowY: 'auto',
//         overflowX: 'hidden',
//
//         // IE11 needs this or else the Content Paper won't be centered vertically
//         alignItems: 'center'
//     }
// });
//
// export const ContentStyles = (theme) => createStyles({
//     paper: {
//         width: '100%',
//         margin: 'auto',
//         maxWidth: '55rem',  // NOTE: This sets the PAPER width !!!!! ZZZZZ PAGEWIDTH PAGE WIDTH
//         overflow: 'hidden',
//         boxShadow: '0 4px 5px 0 rgba(0,0,0,.14), 0 2px 4px -1px rgba(0,0,0,.2)',
//
//         // We have rounded corners everywhere (due to shape.borderRadius set in the theme), but
//         // we don't need to have rounded corners for the progress since we want that to be rectangular.
//         borderTopLeftRadius: 0,
//         borderTopRightRadius: 0,
//
//         // [theme.breakpoints.down('xs')]: {
//         //     // In the mobile view, take up the entire vertical height.
//         //     maxWidth: '100%',
//         //     height: '100%',
//         //     display: 'flex',
//         //     flex: '1',
//         //     flexDirection: 'column',
//         //     overflow: 'auto'
//         // },
//     },
// });
//
// export const SubfeatureCardStyles = (theme) => {
//     const filledProgressSelector = '& div';
//
//     return createStyles({
//         progressBarContainer: {
//             backgroundColor: 'transparent',
//             height: '8px',
//             // Light green for the progress bar container.
//             backgroundImage: 'linear-gradient(to right, rgba(255,255,255,.7), rgba(255,255,255,.7)), linear-gradient(to right, #9CCC65, #9CCC65)',
//             [filledProgressSelector]: {
//                 backgroundColor: '#7CB342' // Dark green for the filled part of the progress bar.
//             }
//         },
//         spinnerContainer: {
//             height: '250px',
//             width: '100%',
//             display: 'flex',
//             alignItems: 'center',
//             justifyContent: 'center',
//             // [theme.breakpoints.down('xs')]: {
//             //     height: '-webkit-fill-available',
//             // }
//         },
//     });
// };
//
//
// export const CadencePageStyles = (theme) => {
//
//     //const descriptionTextSelector = '& .MuiTypography-body1:not(.MuiFormHelperText-root)';
//     //const captionTextSelector = '& .MuiTypography-caption';
//     //const readOnlyTextSelector = '& .MuiTypography-body2';
//     const linkSelector = '& a';
//     const leftButtonSelector = '& > :first-child button';
//     const rightButtonSelector = '& > :last-child button';
//     const gridItemSelector = '& > .MuiGrid-item';
//     const buttonSelector = '& button.MuiButton-contained';
//     const activeButtonSelector = '& button.MuiButton-containedPrimary';
//
//     // .MuiGrid-direction-xs-column, .MuiGrid-direction-sm-column, etc.
//     // const buttonListSelector =
//     //     '& ' +
//     //     theme.breakpoints.keys.map((x) => `.MuiGrid-direction-${x}-column`)
//     //         .join(', ');
//
//     const centeredIconSelector = `${gridItemSelector} > .MuiSvgIcon-root`;
//     const centeredButtonSelector = `${gridItemSelector} > .MuiButton-root:not(.MuiButton-contained)`;
//     const slideoutContentSelector = '& .MuiDrawer-paper';
//     const slideoutHeaderSelector = '& .MuiToolbar-root';
//     const slideoutBodySelector = `${slideoutContentSelector} > .MuiGrid-container`;
//     const backButtonSelector = '& .MuiButton-root';
//     const iconSelector = '& .MuiSvgIcon-root';
//
//     const listSelector = `${gridItemSelector} > ul,ol`;
//     const listItemSelector = '& li';
//
//     return createStyles({
//         formStyles: {
//             // [theme.breakpoints.down('xs')]: {
//             //     // In the mobile view, take up the entire vertical height.
//             //     display: 'flex',
//             //     flexDirection: 'column',
//             //     flex: 1
//             // },
//         },
//         controlGrid: {
//             padding: '8px 8px 8px 8px',
//             // [theme.breakpoints.down('xs')]: {
//             //     padding: '0px',
//             //     // padding: '2px 2px 0px 2px',
//             //
//             //     // In the mobile view, take up the entire vertical height, but leave some space for the footer.
//             //     // The footer looks like it is "fixed", but it is actually just taking up this gap we are leaving for it.
//             //     // maxHeight: 'calc(100vh - 113px)',
//             //
//             //     // Don't "flex" to fill the area since we want this section to be the scrollable section.
//             //     display: 'content',
//             //     justifyContent: "left",
//             //     overflowY: 'auto'
//             // },
//
//             // [descriptionTextSelector]: {
//             //     fontSize: smallFontSize,
//             //     fontWeight: 400,
//             //     lineHeight: '20px',
//             //     color: 'rgba(0, 0, 0, 0.54)'
//             // },
//             // [captionTextSelector]: {
//             //     color: 'rgba(0, 0, 0, 0.54)',
//             //     pointerEvents: 'none',
//             //     marginBottom: '-5px'
//             // },
//             // [readOnlyTextSelector]: {
//             //     fontSize: mediumFontSize,
//             //     lineHeight: '20px'
//             // },
//             [linkSelector]: {
//                 color: theme.palette.secondary.main,
//                 cursor: 'pointer',
//                 textDecoration: 'underline',
//                 fontWeight: 500
//             },
//             [gridItemSelector]: {
//                 // The Material UI "Grid Spacing" solution is nice, but it has some weird limitations.
//                 // For example, it will add a negative margin to the grid container, see https://material-ui.com/components/grid/#negative-margin
//                 // I don't want to add that kind of complexity/weirdness when all we need is a simple
//                 // padding around each grid item.
//                 padding: '8px'
//             },
//             [buttonSelector]: {
//                 ...getLongButtonHeight(),
//                 marginTop: '1em',
//                 color: theme.palette.secondary.main
//             },
//             [activeButtonSelector]: {
//                 color: theme.palette.primary.contrastText
//             },
//             // [buttonListSelector]: {
//             //     // See, now we can select buttons inside the button list using a selector we already defined.
//             //     [buttonSelector]: {
//             //         // ...getLongButtonWidth(),
//             //
//             //         // We don't need the extra margin space if we're in a button list.
//             //         marginTop: 0
//             //     },
//             //     [gridItemSelector]: {
//             //         padding: '8px'
//             //     },
//             //     "& :first-child": {
//             //         paddingTop: '0px'
//             //     },
//             //     "& :last-child": {
//             //         paddingBottom: '0px'
//             //     }
//             // },
//             [centeredIconSelector]: {
//                 margin: 'auto',
//                 display: 'flex',
//                 fontSize: '72px'
//             },
//             [centeredButtonSelector]: {
//                 margin: 'auto',
//                 display: 'flex',
//                 ...getLongButtonHeight(),
//                 ...getLongButtonWidth(),
//                 ...getUnfocusedContainedButtonShadow(),
//                 backgroundColor: theme.palette.secondary.main,
//                 color: 'white'
//             },
//             [listSelector]: {
//                 margin: '0',
//                 [listItemSelector]: {
//                     marginBottom: '16px'
//                 }
//             }
//         },
//         footerGrid: {
//             background: '#FFF', /* White background color */
//             padding: '10px 16px',
//             borderTop: 'solid rgba(0, 0, 0, 0.1) 1px',
//             // [theme.breakpoints.down('xs')]: {
//             //     /* In xs resolutions (mobile), the footer is fixed at the bottom of the view */
//             //     position: 'relative',
//             //     left: 0,
//             //     // bottom: 0,
//             //     padding: '6px 16px',
//             //     width: '100%',
//             //     marginTop: '20px',
//             //     background: '#EEE', /* Light grey background color */
//             //     borderTop: 'solid rgba(0, 0, 0, 0.12) 1px',
//             //     boxShadow: '0 4px 5px 0 rgba(0,0,0,.14), 0 1px 10px 0 rgba(0,0,0,.12), 0 2px 4px -1px rgba(0,0,0,.2)'
//             // },
//             [leftButtonSelector]: {
//                 // Any styling options for the left button should go here.
//             },
//             [rightButtonSelector]: {
//                 color: theme.palette.secondary.main
//             }
//         },
//         slideout: {
//             [slideoutContentSelector]: {
//                 // TODO: Need to responsively resize to 480px to really match the behavior of the old site...
//                 // TODO: Need to work on the transisition for the slideout to make sure it really matches the
//                 //       timing of the old site.
//                 width: '500px',
//
//                 [slideoutHeaderSelector]: {
//                     paddingLeft: '64px',
//
//                     [backButtonSelector]: {
//                         position: 'absolute',
//                         left: 0,
//                         top: 0,
//                         minWidth: '48px',
//                         width: '48px',
//                         height: '48px',
//                         marginTop: '8px',
//                         marginRight: '8px',
//                         marginBottom: '8px',
//                         marginLeft: '8px',
//                         padding: '0',
//                         color: 'white',
//                         borderRadius: '50%',
//
//                         [iconSelector]: {
//                             fontSize: '26px'
//                         }
//                     },
//                     // [titleTextSelector]: {
//                     //     fontSize: largeFontSize,
//                     //     fontWeight: 400
//                     // }
//                 },
//                 [slideoutBodySelector]: {
//                     // Nothing here today...
//                 }
//             }
//         }
//     });
// };
//
// export const ToggleButtonStyles = (theme) => createStyles({
//     contained: {
//         backgroundColor: 'red'
//     },
//     containedPrimary: {
//         backgroundColor: 'green'
//     }
// });
//
// export const IconStyles = (theme) => createStyles({
//     warning: {
//         color: warningColor
//     },
//     error: {
//         color: theme.palette.error.main
//     },
//     checkmark: {
//         color: successColor
//     },
//     email: {
//     },
//     phone: {
//     },
//     help: {
//     },
//     iconLastInASpan: {
//         marginLeft: '10px',
//     },
//     iconWithAction: {
//         '& :hover': {
//             color: `${theme.palette.secondary.main} !important`
//         }
//     }
// });
//
// export const SpanStyles = (theme)  => {
//     const iconSelector = '& .MuiSvgIcon-root';
//     return createStyles({
//         textWithIcon: {
//             display: 'flex',
//             alignItems: 'center',
//             [iconSelector]: {
//                 marginRight: '16px',
//                 color: 'rgba(0, 0, 0, 0.54)'
//             }
//         },
//         inlineIcons: {
//             [iconSelector]: {
//                 verticalAlign: 'text-bottom',
//                 color: 'rgba(0, 0, 0, 0.54)'
//             }
//         },
//     });
// };

function getUnfocusedContainedButtonShadow() {
    return {
        boxShadow: '0px 1px 5px 0px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 3px 1px -2px rgba(0,0,0,0.12)',
    };
}

function getFocusedContainedButtonShadow() {
    return {
        boxShadow: '0 0 8px rgba(0,0,0,.18), 0 8px 16px rgba(0,0,0,.36)',
    };
}

function getLongButtonWidth() {
    return {
        minWidth: '300px',
        maxWidth: '360px'
    };
}

function getLongButtonHeight() {
    return {
        height: '48px'
    };
}
