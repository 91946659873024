import React, {Component} from 'react';
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import HighlightOffOutlinedIcon from "@mui/icons-material/HighlightOffOutlined";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Typography from "@mui/material/Typography";
import AccordionDetails from "@mui/material/AccordionDetails";
import Accordion from "@mui/material/Accordion";
import {reorderQuestionnaireCategory, removeQuestionnaireCategory} from "../../../actions/questionnaireEditorActions";
import {getCategoryTemplate} from "../../../actions/categoryTemplateActions";
import {getGroupTemplate} from "../../../actions/groupTemplateActions";
import {getQuestionTemplate} from "../../../actions/questionTemplateActions";
import {openDrawer} from "../../../actions/drawerActions";
import CategoryCreateEditDrawer, {categoryCreateEditDrawerProps} from "../../drawers/CategoryCreateEditDrawer";
import GroupViewerExpandable from "../groups/GroupViewerExpandable";
import ArgoReorderArrowsHook from "../../common/ArgoReorderArrowsHook";
import ArgoIconButtonHook from "../../common/ArgoIconButtonHook";
import ArgoTemplateListDividerHook from "../../common/ArgoTemplateListDividerHook";
import ArgoExpandCollapseButtonHook from "../../common/ArgoExpandCollapseButtonHook";
import * as expander from "../../../utilities/expander";
import * as TEMPLATE from "../../../constants/templateConstants";
import statusFormatter from "../../../utilities/StatusFormatter";
import * as screenChangeSize from "../../../utilities/screenChangeSize";


class CatagoryViewerExpandable extends Component {
    constructor(props) {
        super(props);

        this.state = {
            expander: {},
            expandAll: true,
            expanderPreset: false,
            protalPreviewRendered: false,
            groupInfoDrawerOpen: false,
            expanderObjLoaded: false,
            widthChange: false
        };
    }

    // static contextType = UserContext;  // From blueTheme.js

    componentDidMount() {
        for (let i = 0; i < this.props.categoryKeys?.length; i++) {
            this.props.getCategoryTemplate(this.props.categoryKeys[i], null);
        }
    }

    componentWillReceiveProps(nextProps) {

        // All categories, groups and question loaded
        for (let i = 0; i < nextProps.categoryKeys?.length; i++) {

            // Is category loaded
            if (nextProps.categoryTemplates.hasOwnProperty(nextProps.categoryKeys[i])) {

                let groupKeys = nextProps.categoryTemplates[nextProps.categoryKeys[i]].groupKeys; // Get groupKeys from category template

                for (let ii = 0; ii < groupKeys.length; ii++) {

                    // If group is NOT loadeed then get the group
                    if (!nextProps.groupTemplates.hasOwnProperty(groupKeys[ii])) {
                        nextProps.getGroupTemplate(groupKeys[ii], null);
                    }
                    else {

                        let questionKeys = nextProps.groupTemplates[groupKeys[ii]].questionKeys; // Get questionKeys from group template

                        // Load all the questions for current group
                        for (let iii = 0; iii < questionKeys.length; iii++) {

                            // If question is NOT loadeed then get question
                            if (!nextProps.questionTemplates.hasOwnProperty(questionKeys[iii])) {
                                nextProps.getQuestionTemplate(questionKeys[iii], null);
                            }
                        }
                    }
                }
            }
            else {
                nextProps.getCategoryTemplate(nextProps.categoryKeys[i], null);
            }
        }

        if (this.allRequiredTemplatesLoaded(nextProps)) {
            this.setState({expanderPreset: true, expander: expander.presetExpander(nextProps.categoryKeys, nextProps.categoryTemplates)});
            nextProps.setStateCallback({categoriesLoaded: true, categoryTemplates: nextProps.categoryTemplates});
        }

    }


    setStateCallback = (stateObj) => {
        this.setState(stateObj);
    }


    allRequiredTemplatesLoaded = (props) => {

        let allRequiredTemplatesLoaded = true;

        let categoryKeys = props.categoryKeys;

        // All categories, groups and question loaded
        for (let i = 0; i < categoryKeys?.length; i++) {

            // Is category loaded
            if (!props.categoryTemplates.hasOwnProperty(categoryKeys[i])) {
                allRequiredTemplatesLoaded = false;
                break;
            }
            else {
                let groupKeys = props.categoryTemplates[categoryKeys[i]].groupKeys; // Get groupKeys from category template

                for (let ii = 0; ii < groupKeys.length; ii++) {

                    // If group is NOT loadeed
                    if (!props.groupTemplates.hasOwnProperty(groupKeys[ii])) {
                        allRequiredTemplatesLoaded = false;
                        break;
                    }
                    else {

                        let questionKeys = props.groupTemplates[groupKeys[ii]].questionKeys; // Get questionKeys from group template

                        // Load all the questions for current group
                        for (let iii = 0; iii < questionKeys.length; iii++) {

                            // If question is NOT loadeed
                            if (!props.questionTemplates.hasOwnProperty(questionKeys[iii])) {
                                allRequiredTemplatesLoaded = false;
                                break;
                            }
                        }
                    }
                }
            }
        }

        return allRequiredTemplatesLoaded;
    };

    // -----------------------------------------------------------------------------------------------------------------
    // EXPANDER FUNCTIONS ----------------------------------------------------------------------------------------------
    // -----------------------------------------------------------------------------------------------------------------

    onClickIconButtonReorder = (event, fieldName, index, depth, direction) => {
        event.stopPropagation(); // Keep accordion from expand/collapse
        this.props.reorderQuestionnaireCategory((direction === "up"), index);
    };

    onClickIconButtonEdit = (categoryTemplate, event) => {
        event.stopPropagation(); // Keep accordion from expand/collapse
        this.props.setStateCallback({openCategoryEditor: true, categoryTemplate: categoryTemplate});
        // this.props.openDrawer(categoryCreateEditDrawerProps, <CategoryCreateEditDrawer templateKey={categoryTemplateKey.templateKey} templateId={categoryTemplateKey.id} filter={this.props.filter} templateMode={TEMPLATE.MODE.CURRENT}/>);
    };

    onClickIconButtonRemove = (index, event) => {
        event.stopPropagation(); // Keep accordion from expand/collapse
        this.props.removeQuestionnaireCategory(index);
    };

    onClickIconButtonInfo = (categoryTemplateKey, event) => {
        event.stopPropagation(); // Keep accordion from expand/collapse
        this.props.setStateCallback({openCategoryViewer: true, selectedCategoryInfoKey: categoryTemplateKey, hidePortalPreview: true});
        //this.props.openDrawer(groupViewerDrawerProps, <GroupViewerDrawer templateKey={this.props.templateKey} templateId={this.props.template.id}/>);
    };

    onExpandChange = (categoryTemplateKey, event, open) => {
        expander.expandCollapseSelected(this.state.expander, categoryTemplateKey, this.setStateCallback);

        // Don't postMessage portal when expander is closing or NOT displayed
        if (open && !this.props.hidePortalPreview) {
            let groupTemplate = this.props.categoryTemplates[categoryTemplateKey];

            //portal.sendCategoryToPortal(groupTemplate, groupTemplate.id, groupTemplate.answerOptionTemplates, this.props.questionTemplates, groupTemplate.questionKeys);
        }
    };

    // -----------------------------------------------------------------------------------------------------------------
    // RENDER ----------------------------------------------------------------------------------------------------------
    // -----------------------------------------------------------------------------------------------------------------

    render() {
        window.addEventListener('resize', this.setStateCallback.bind(this, {widthChange: !this.state.widthChange}));

        if (this.state.openQuestionViewer) {
            return this.openQuestionViewer();
        }

        //const {palette} = this.context;  // From blueTheme.js
        const template = this.props.questionnaireTemplate;

        return (
            (template.hasOwnProperty("categoryKeys") && this.allRequiredTemplatesLoaded(this.props)) ?
                <div key={"CatagoryViewerExpandable-container-div-" + template.id} id={"CatagoryViewerExpandable-container-div-" + template.id} style={{width: "100%"}}>

                    <div>&nbsp;</div>

                    {/*// ===========================================================================================================*/}
                    {/*// CATEGORY LIST DIVIDER */}
                    {/*// ===========================================================================================================*/}

                    <ArgoTemplateListDividerHook
                        fileName="CatagoryViewerExpandable"
                        parentsLabel="Questionnaire"
                        childLabel={["category","categories"]}
                        arrayLength={this.props.categoryKeys.length}
                    />

                    {/*// ===========================================================================================================*/}
                    {/*// CATEGORY EXPAND/COLLAPSE BUTTON  - NOTE: No expand/collapse button if group is Likert ie.. has answers  */}
                    {/*// ===========================================================================================================*/}

                    {(this.props.categoryKeys.length > 1) ?
                        <div style={{float: "right", width: 140}}>
                            <ArgoExpandCollapseButtonHook
                                fileName="CatagoryViewerExpandable"
                                expandAll={this.state.expandAll}
                                onClick={expander.expandCollapseAll.bind(this, TEMPLATE.TYPE.CATEGORY, this.state.expander, this.state.expandAll, this.props.categoryKeys, this.props.hidePortalPreview, this.setStateCallback)}
                            />

                            <div>&nbsp;</div>
                        </div>
                        : ""
                    }

                    {/*// ===========================================================================================================*/}
                    {/*// ASSIGNED CATEGORIES LIST */}
                    {/*// ===========================================================================================================*/}

                    <div id="CatagoryViewerExpandable-question-list-container-div" style={{display: "flex", alignSelf: "flex-start", flexDirection: "column", width: "100%", minHeight: "min-content"}}>
                        {this.props.categoryKeys.map((categoryTemplateKey, index) => {
                            return (
                                <div key={categoryTemplateKey + index} id={"CatagoryViewerExpandable-question-container-div-" + categoryTemplateKey + index} style={{display: "flex", flexDirection: "row", alignItems: "center"}}>

                                    <Accordion key={"Accordion-" + categoryTemplateKey}
                                               id={"CatagoryViewerExpandable-Accordion-" + categoryTemplateKey}
                                               variant="outlined"
                                               disableGutters={true}
                                               onChange={this.onExpandChange.bind(this, categoryTemplateKey)}
                                               expanded={this.state.expander[categoryTemplateKey]?.open ? this.state.expander[categoryTemplateKey]?.open : false}
                                               sx={{width: "100%", minWidth: 940}}>
                                        <AccordionSummary key={"AccordionSumary-" + categoryTemplateKey}
                                                          id={"CatagoryViewerExpandable-AccordionSummary-" + categoryTemplateKey}
                                                          expandIcon={<ExpandMoreIcon style={{color: expander.ICON_COLOR}}/>}
                                                          sx={{height: "30px", backgroundColor: expander.ACCORDION_BG_COLOR, width: "100%", '&:hover': {bgcolor: expander.ACCORDION_BG_COLOR_HOVER}}}
                                        >
                                            <div style={{display: "flex", flexDirection: "row", alignItems: "center", width: "100%"}}>

                                                {this.props.showIconButtonReorder ?
                                                    <ArgoReorderArrowsHook
                                                        fileName="CatagoryViewerExpandable"
                                                        fieldName="container-div"
                                                        onClick={this.onClickIconButtonReorder}
                                                        index={index}
                                                        arrayLength={this.props.categoryKeys.length}
                                                        isFirst={index === 0}
                                                        isLast={index === (this.props.categoryKeys.length -1)}
                                                        upArrowColor={index === 0 ? expander.ICON_COLOR_HIDDEN : expander.ICON_COLOR}
                                                        downArrowColor={this.props.isLast ? expander.ICON_COLOR_HIDDEN : expander.ICON_COLOR}
                                                    /> : ""
                                                }

                                                <Typography>
                                                    {statusFormatter(this.props.categoryTemplates[categoryTemplateKey], 0, 0)}
                                                </Typography>

                                                <Typography variant="h6" component="div" sx={{color: expander.TEXT_COLOR_SECONDARY}}>
                                                    {"Category:"}
                                                </Typography>

                                                <Typography variant="h6" component="div"
                                                            sx={{paddingLeft: "5px",
                                                                color: expander.TEXT_COLOR_PRIMARY,
                                                                whiteSpace: 'nowrap',
                                                                wordWrap: 'break-word',
                                                                overflow: "hidden",
                                                                textOverflow: 'ellipsis',
                                                                minWidth: 50,
                                                                width: screenChangeSize.setWidth.call(this, 1020, [this.props.categoryTemplates[categoryTemplateKey].nameQualifier, this.props.showIconButtonReorder, this.props.showIconButtonEdit, this.props.showIconButtonRemove, this.props.showIconButtonInfo], this.setStateCallback),
                                                            }}>
                                                    {this.props.categoryTemplates[categoryTemplateKey].name}
                                                </Typography>

                                                {this.props.categoryTemplates[categoryTemplateKey].nameQualifier ?
                                                    <div style={{display: "flex"}}>
                                                        <Typography variant="subtitle1" component="div" sx={{paddingLeft: "5px", paddingTop: "3px", color: expander.TEXT_COLOR_SECONDARY}}>
                                                            {(this.props.categoryTemplates[categoryTemplateKey].nameQualifier ? "Qualifier:" : "")}
                                                        </Typography>

                                                        <Typography variant="subtitle1" component="div"
                                                                    sx={{paddingLeft: "5px",
                                                                        paddingTop: "3px",
                                                                        color: expander.TEXT_COLOR_PRIMARY,
                                                                        whiteSpace: 'nowrap',
                                                                        wordWrap: 'break-word',
                                                                        overflow: "hidden",
                                                                        textOverflow: 'ellipsis',
                                                                        maxWidth: 120,
                                                                        minWidth: 120
                                                                    }}>
                                                            {this.props.categoryTemplates[categoryTemplateKey].nameQualifier ? this.props.categoryTemplates[categoryTemplateKey].nameQualifier : ""}
                                                        </Typography>
                                                    </div> : ''
                                                }

                                                <Typography variant="subtitle1" component="div" sx={{paddingLeft: "5px", paddingTop: "2px", color: expander.TEXT_COLOR_SECONDARY, minWidth: 163}}>
                                                    {"(Groups Assigned: " + (this.props.categoryTemplates[categoryTemplateKey].groupKeys ? this.props.categoryTemplates[categoryTemplateKey].groupKeys.length : "0") + ")"}
                                                </Typography>

                                                <div id={"CatagoryViewerExpandable-icon-button-container-div" + index} style={{display: "flex", marginLeft: "auto"}}>

                                                    {this.props.showIconButtonEdit ?
                                                        <ArgoIconButtonHook
                                                            fileName="CatagoryViewerExpandable"
                                                            fieldName="edit"
                                                            onClick={this.onClickIconButtonEdit.bind(this, this.props.categoryTemplates[categoryTemplateKey])}
                                                            index={this.props.index}
                                                            icon={<ModeEditIcon style={{color: expander.ICON_COLOR}}/>}
                                                            display={this.props.showEditIconButton}
                                                        />  : ""
                                                    }

                                                    <div>&nbsp;</div>

                                                    {this.props.showIconButtonRemove ?
                                                        <ArgoIconButtonHook
                                                            fileName="CatagoryViewerExpandable"
                                                            fieldName="remove"
                                                            onClick={this.onClickIconButtonRemove.bind(this, index)}
                                                            index={this.props.index}
                                                            icon={<HighlightOffOutlinedIcon style={{color: expander.ICON_COLOR}}/>}
                                                            display={this.props.showIconButtonRemove}
                                                        />  : ""
                                                    }

                                                    <div>&nbsp;</div>

                                                    {this.props.showIconButtonInfo ?
                                                        <ArgoIconButtonHook
                                                            fileName="CatagoryViewerExpandable"
                                                            fieldName="info"
                                                            onClick={this.onClickIconButtonInfo.bind(this, categoryTemplateKey)}
                                                            index={this.props.index}
                                                            icon={<InfoOutlinedIcon style={{color: expander.ICON_COLOR}}/>}
                                                        /> : ""
                                                    }

                                                    <div>&nbsp;</div>

                                                </div>

                                            </div>
                                        </AccordionSummary>
                                        <AccordionDetails key={"AccordionDetails-" + categoryTemplateKey}>

                                            {/*// ===========================================================================================================*/}
                                            {/*// ASSIGNED QUESTIONS LIST */}
                                            {/*// ===========================================================================================================*/}

                                            <GroupViewerExpandable
                                                groupKeys={this.props.categoryTemplates[categoryTemplateKey].groupKeys}
                                                showIconButtonReorder={false}
                                                showIconButtonEdit={false}
                                                showIconButtonRemove={false}
                                                setStateCallback={this.props.setStateCallback}
                                                hidePortalPreview={true}
                                            />

                                            <div>&nbsp;</div>

                                            {/*// ===========================================================================================================*/}
                                            {/*// GROUP PORTAL PREVIEW */}
                                            {/*// ===========================================================================================================*/}

                                            {/*<ArgoPortalPreview*/}
                                            {/*    templateType={TEMPLATE.TYPE.GROUP}*/}
                                            {/*    id={this.props.categoryTemplates[categoryTemplateKey].id.toString()}*/}
                                            {/*    questionTemplate={this.props.questionTemplates[categoryTemplateKey]}*/}
                                            {/*    urlSuffix={portal.GROUP_URL_SUFFIX}*/}
                                            {/*/>*/}

                                        </AccordionDetails>
                                    </Accordion>

                                    {(this.state.expander[categoryTemplateKey]?.open) ? <div id={"spacer-div-" + index}>&nbsp;</div> : ""}

                                </div>
                            );
                        })}
                    </div>

                </div>
                : ""
        );
    }
}

CatagoryViewerExpandable.defaultProps = {
    questionnaireTemplate: {},
    categoryKeys: [],
    defaultExpanded: false,
    showIconButtonReorder: true,
    showIconButtonEdit: true,
    showIconButtonRemove: true,
    showIconButtonInfo: true,
    hidePortalPreview: false
};

CatagoryViewerExpandable.propTypes = {
    questionnaireTemplate: PropTypes.object,
    categoryKeys: PropTypes.array,
    showIconButtonReorder: PropTypes.bool,
    showIconButtonEdit: PropTypes.bool,
    showIconButtonRemove: PropTypes.bool,
    showIconButtonInfo: PropTypes.bool,
    setStateCallback: PropTypes.func,
    hidePortalPreview: PropTypes.bool
};

function mapStateToProps(state, props) {
    return {
        categoryTemplates: state.categoryTemplate,
        groupTemplates: state.groupTemplate,
        questionTemplates: state.questionTemplate,
        questionsFilter: state.filter[TEMPLATE.FILTER.current.group],
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({getCategoryTemplate, reorderQuestionnaireCategory, removeQuestionnaireCategory, getGroupTemplate, getQuestionTemplate, openDrawer}, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(CatagoryViewerExpandable);









// CategoryViewerExpandable.defaultProps = {
//     expander: {},
//     expanded: false,
//     showMenu: false,
//     category: {
//         categoriesCompleted: 0
//     }
// };
//
//
// CategoryViewerExpandable.propTypes = {
//     templateKey: PropTypes.string,
//     templateId: PropTypes.string,
//     showMenu: PropTypes.bool,
//     expander: PropTypes.object,
//     expanded: PropTypes.bool,
//     interactive: PropTypes.bool,
//     category: PropTypes.object,
//     filter: PropTypes.object,
//     index: PropTypes.number,
//     arrayLength: PropTypes.number,
//     isFirst: PropTypes.bool,
//     isLast: PropTypes.bool,
//     setStateCallback: PropTypes.func
// };
//
//
// function mapStateToProps(state, props) {
//     let mappedState = {
//         filter: state.filter[TEMPLATE.FILTER.current.category]
//     };
//
//     if (props.templateKey && state.categoryTemplate[props.templateKey]) {
//         mappedState.template = state.categoryTemplate[props.templateKey];
//         mappedState.categoryKeys = state.categoryTemplate[props.templateKey].categoryKeys || [];
//         mappedState.groupTemplate = state.groupTemplate;
//         mappedState.questionTemplate = state.questionTemplate;
//     }
//
//     return mappedState;
// }
//
// function mapDispatchToProps(dispatch) {
//     return bindActionCreators({getCategoryTemplate, reorderQuestionnaireCategory, removeQuestionnaireCategory, openDrawer, getGroupTemplate, getQuestionTemplate}, dispatch);
// }
//
// export default connect(mapStateToProps, mapDispatchToProps)(CategoryViewerExpandable);
