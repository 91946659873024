import React, {Component} from 'react';
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import {closeDrawer} from "../../actions/drawerActions";
import {getQuestionnaireStatistics, getQuestionnaireStatisticsAll} from "../../actions/questionnaireTemplateActions";
import ArgoAppBar from "../common/ArgoAppBar";
import ClickAwayListener from '@mui/material/ClickAwayListener';


class QuestionnaireStatisticsDrawer extends Component {
    constructor(props) {
        super(props);

        this.state = {
            statisticsArray: null
        };
    }


    componentDidMount() {
        // There is also a endpoint http://localhost:8081/qd2/api/v1//questionnaire-reference/statistic/{id}
        // this.props.getQuestionnaireStatisticsA(this.props.template.templateId);
        // That will only get the statistics the current published selected id
        // This endpoint gets an array of all published versions by templateKey
        this.props.getQuestionnaireStatisticsAll(this.props.template.templateKey);
    }


    componentWillReceiveProps(nextProps) {

    }


    closeDrawer = () => {
        this.props.closeDrawer();
    };


    render() {

        let statistics = null;
        let surveyCount = 0;
        let submittedCount = 0;
        let incompleteCount = 0;
        let expiredCount = 0;

        // Loop thru all published version statistics load the selected versions stats
        // Sum all the stats
        for (let i = 0; i < this.props.statisticsAll.length; i++) {

            surveyCount += this.props.statisticsAll[i].surveyCount;
            submittedCount += this.props.statisticsAll[i].submittedCount;
            incompleteCount += this.props.statisticsAll[i].incompleteCount;
            expiredCount += this.props.statisticsAll[i].expiredCount;

            if (this.props.statisticsAll[i].qid === this.props.templateId) {
                statistics = this.props.statisticsAll[i];
            }
        }


        return (
            <ClickAwayListener onClickAway={this.props.closeDrawer}>
                <div id="QuestionnaireStatisticsDrawer-baseDiv" id="QuestionnaireStatisticsDrawer-container-div" style={{height: "100%"}}>
                    <ArgoAppBar title={this.props.template.name} showMenuArrowButton="LEFT" onLeftIconButtonTouchTap={this.closeDrawer} isDrawer={true}/>

                    <div id="QuestionnaireStatisticsDrawer-container-div2" style={{height: "100%", overflowX: "hidden"}}>
                        {(statistics !== null) ?
                            <div>
                                <div id="QuestionnaireStatisticsDrawer-Statistics01" style={{height: "40px"}}>&nbsp;</div>
                                <div id="QuestionnaireStatisticsDrawer-Statistics02" style={{height: "40px", paddingLeft: "40px"}}><h2><b>Statistics</b></h2></div>
                                <div id="QuestionnaireStatisticsDrawer-Statistics03" style={{height: "30px"}}>&nbsp;</div>
                                <div id="QuestionnaireStatisticsDrawer-Statistics04" style={{display: "flex"}}>
                                    <div id="QuestionnaireStatisticsDrawer-Statistics05" style={{paddingLeft: "40px", width: "300px", textAlign: "left"}}>Survey Count</div>
                                    <div id="QuestionnaireStatisticsDrawer-Statistics06" style={{textAlign: "left"}}>{statistics["surveyCount"]}</div>
                                </div>
                                <div id="QuestionnaireStatisticsDrawer-spacer01" style={{height: "10px"}}>&nbsp;</div>
                                <div id="QuestionnaireStatisticsDrawer-Statistics08" style={{display: "flex"}}>
                                    <div id="QuestionnaireStatisticsDrawer-Statistics09" style={{paddingLeft: "40px", width: "300px", textAlign: "left"}}>Submitted Count</div>
                                    <div id="QuestionnaireStatisticsDrawer-Statistics10" style={{textAlign: "left"}}>{statistics["submittedCount"]}</div>
                                </div>
                                <div id="QuestionnaireStatisticsDrawer-spacer02" style={{height: "10px"}}>&nbsp;</div>
                                <div id="QuestionnaireStatisticsDrawer-Statistics11" style={{display: "flex"}}>
                                    <div id="QuestionnaireStatisticsDrawer-Statistics12" style={{paddingLeft: "40px", width: "300px", textAlign: "left"}}>Incomplete Count</div>
                                    <div id="QuestionnaireStatisticsDrawer-Statistics13" style={{textAlign: "left"}}>{statistics["incompleteCount"]}</div>
                                </div>
                                <div id="QuestionnaireStatisticsDrawer-spacer03" style={{height: "10px"}}>&nbsp;</div>
                                <div id="QuestionnaireStatisticsDrawer-Statistics14" style={{display: "flex"}}>
                                    <div id="QuestionnaireStatisticsDrawer-Statistics15" style={{paddingLeft: "40px", width: "300px", textAlign: "left"}}>Expired Count</div>
                                    <div id="QuestionnaireStatisticsDrawer-Statistics16" style={{textAlign: "left"}}>{statistics["expiredCount"]}</div>
                                </div>
                                <div id="QuestionnaireStatisticsDrawer-spacer04" style={{height: "10px"}}>&nbsp;</div>
                                <div id="QuestionnaireStatisticsDrawer-Statistics17" style={{display: "flex"}}>
                                    <div id="QuestionnaireStatisticsDrawer-Statistics18" style={{paddingLeft: "40px", width: "300px", textAlign: "left"}}>Last Sent</div>
                                    <div id="QuestionnaireStatisticsDrawer-Statistics19" style={{textAlign: "left"}}>{statistics["lastSent"] === null ? "" : (statistics["lastSent"].substring(5,7) + "/" + statistics["lastSent"].substring(8,10) + "/" + statistics["lastSent"].substring(0,4))}</div>
                                </div>
                                <div id="QuestionnaireStatisticsDrawer-spacer05" style={{height: "10px"}}>&nbsp;</div>
                                <div id="QuestionnaireStatisticsDrawer-Statistics20" style={{display: "flex"}}>
                                    <div id="QuestionnaireStatisticsDrawer-Statistics21" style={{paddingLeft: "40px", width: "300px", textAlign: "left"}}>Last Sent Key</div>
                                    <div id="QuestionnaireStatisticsDrawer-Statistics22" style={{textAlign: "left"}}>{statistics["lastSentKey"] === null ? "" : (statistics["lastSentKey"])}</div>
                                </div>
                                <div id="QuestionnaireStatisticsDrawer-spacer06" style={{height: "10px"}}>&nbsp;</div>
                                <div id="QuestionnaireStatisticsDrawer-Statistics23" style={{display: "flex"}}>
                                    <div id="QuestionnaireStatisticsDrawer-Statistics24" style={{paddingLeft: "40px", width: "300px", textAlign: "left"}}>Last Submitted</div>
                                    <div id="QuestionnaireStatisticsDrawer-Statistics25" style={{textAlign: "left"}}>{statistics["lastSubmitted"] === null ? "" : (statistics["lastSubmitted"].substring(5,7) + "/" + statistics["lastSubmitted"].substring(8,10) + "/" + statistics["lastSubmitted"].substring(0,4))}</div>
                                </div>
                                <div id="QuestionnaireStatisticsDrawer-spacer07" style={{height: "10px"}}>&nbsp;</div>
                                <div id="QuestionnaireStatisticsDrawer-Statistics26" style={{display: "flex"}}>
                                    <div id="QuestionnaireStatisticsDrawer-Statistics27" style={{paddingLeft: "40px", width: "300px", textAlign: "left"}}>Last Submitted Key</div>
                                    <div id="QuestionnaireStatisticsDrawer-Statistics28" style={{textAlign: "left"}}>{statistics["lastSubmittedKey"] === null ? "" : (statistics["lastSubmittedKey"])}</div>
                                </div>

                                <div id="QuestionnaireStatisticsDrawer-Statistics01" style={{height: "40px"}}>&nbsp;</div>
                                <div id="QuestionnaireStatisticsDrawer-Statistics02" style={{height: "40px", paddingLeft: "40px"}}><h2><b>{"Statistics Totals for " + this.props.statisticsAll.length + " Published Versions"}</b></h2></div>
                                <div id="QuestionnaireStatisticsDrawer-Statistics03" style={{height: "30px"}}>&nbsp;</div>
                                <div id="QuestionnaireStatisticsDrawer-Statistics04" style={{display: "flex"}}>
                                    <div id="QuestionnaireStatisticsDrawer-Statistics05" style={{paddingLeft: "40px", width: "300px", textAlign: "left"}}>Survey Count</div>
                                    <div id="QuestionnaireStatisticsDrawer-Statistics06" style={{textAlign: "left"}}>{surveyCount}</div>
                                </div>
                                <div id="QuestionnaireStatisticsDrawer-spacer01" style={{height: "10px"}}>&nbsp;</div>
                                <div id="QuestionnaireStatisticsDrawer-Statistics08" style={{display: "flex"}}>
                                    <div id="QuestionnaireStatisticsDrawer-Statistics09" style={{paddingLeft: "40px", width: "300px", textAlign: "left"}}>Submitted Count</div>
                                    <div id="QuestionnaireStatisticsDrawer-Statistics10" style={{textAlign: "left"}}>{submittedCount}</div>
                                </div>
                                <div id="QuestionnaireStatisticsDrawer-spacer02" style={{height: "10px"}}>&nbsp;</div>
                                <div id="QuestionnaireStatisticsDrawer-Statistics11" style={{display: "flex"}}>
                                    <div id="QuestionnaireStatisticsDrawer-Statistics12" style={{paddingLeft: "40px", width: "300px", textAlign: "left"}}>Incomplete Count</div>
                                    <div id="QuestionnaireStatisticsDrawer-Statistics13" style={{textAlign: "left"}}>{incompleteCount}</div>
                                </div>
                                <div id="QuestionnaireStatisticsDrawer-spacer03" style={{height: "10px"}}>&nbsp;</div>
                                <div id="QuestionnaireStatisticsDrawer-Statistics14" style={{display: "flex"}}>
                                    <div id="QuestionnaireStatisticsDrawer-Statistics15" style={{paddingLeft: "40px", width: "300px", textAlign: "left"}}>Expired Count</div>
                                    <div id="QuestionnaireStatisticsDrawer-Statistics16" style={{textAlign: "left"}}>{expiredCount}</div>
                                </div>

                            </div>
                            :
                            <div></div>
                        }


                    </div>
                </div>
            </ClickAwayListener>
        );
    }
}


QuestionnaireStatisticsDrawer.defaultProps = {
    close: () => {}
};


QuestionnaireStatisticsDrawer.propTypes = {
    closeDrawer: PropTypes.func,
    templateId: PropTypes.number,
    templateKey: PropTypes.string,
    template: PropTypes.object,
    login: PropTypes.object,
};


function mapStateToProps(state, props) {
    return {
        statistics: state.statistics,
        statisticsAll: state.statisticsAll,
        template: state.openCloseSecondaryDrawer.template,
        login: state.login
    };
}


function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        closeDrawer,
        getQuestionnaireStatistics,
        getQuestionnaireStatisticsAll
    }, dispatch);
}


const questionnaireStatisticsDrawerProps = {
    opensecondary: true,
    width: "50%",
    docked: false,
    anchor: "right"
};


export default connect(mapStateToProps, mapDispatchToProps)(QuestionnaireStatisticsDrawer);
export {questionnaireStatisticsDrawerProps};
