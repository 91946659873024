import React, { useState, useEffect } from 'react';
import Fab from "@mui/material/Fab";
import AddIcon from "@mui/icons-material/Add";


export default function ArgoTemplateListCreateFabHook(props) {

    const fileName = props.fileName === undefined ? "file-name" : props.fileName;
    const fieldName = props.fieldName === undefined ? "field-name" : props.fieldName;

    const uniqueIdentifier = fileName + "-fab-" + fieldName;

    const hoverText = props.hoverText === undefined ? "" : props.hoverText;

    const onClick = props.onClick === undefined ? function () {} : props.onClick;

    const handleOnClick = (event) => {
        onClick();
    };

    return (
        <div id={uniqueIdentifier + "-div"} title={hoverText} style={{bottom: "2.5rem", right: "2.5rem", position: "fixed"}}>
            <Fab id={uniqueIdentifier}
                 name={uniqueIdentifier}
                 onClick={handleOnClick}
                 color="primary"
                 sx={{float: "right"}}>
                <AddIcon color="white"/>
            </Fab>
        </div>
    );
}


