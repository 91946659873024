import React, {useContext} from 'react';
import Button from "@mui/material/Button";
import UserContext from "../../UserContext"
import promiseService from "../../services/PromiseService";


export default function ArgoButtonHook(props) {

    const theme = useContext(UserContext); // From blueTheme.js

    const fileName = props.fileName === undefined ? "NoFileName" : props.fileName;
    const fieldName = props.fieldName === undefined ? "NoFieldName" : props.fieldName;

    const uniqueIdentifier = fileName + "-ArgoButtonHook-" + fieldName;

    const canHaveErrorMessage = props.canHaveErrorMessage === undefined ? false : props.canHaveErrorMessage;
    const errorMessage = props.errorMessage === undefined ? "" : props.errorMessage;

    const variant = props.variant === undefined ? theme.button.variant.outlined : props.variant;
    const toolbar = props.toolbar === undefined ? false : props.toolbar;  // Toolbars have a gray background and need wome special color tweaking

    const btnTheme = toolbar ? theme.toolbarButton : theme.button;

    const label = props.label === undefined ? "Label" : props.label;

    const paddingTop = props.paddingTop === undefined ? "0px" : props.paddingTop;

    const width = props.width === undefined ? "160px" : props.width;

    const fullWidth = props.fullWidth === undefined ? ((width === 0)) : false;

    const disabled = props.disabled === undefined ? false : props.disabled;

    const autofocus = props.autofocus === undefined ? false : props.autofocus;

    const onClick = props.onClick === undefined ? function () {} : props.onClick;

    const buttonPadding = props.buttonPadding === undefined ? undefined : props.buttonPadding;

    // button variant = outlined ----------------------------------------------------------------------------------------------------------------

    const outlinedTextColor = props.outlinedTextColor === undefined ? btnTheme.outlined.normal.textColor : props.outlinedTextColor;
    const outlinedBgColor = props.outlinedBgColor === undefined ? btnTheme.outlined.normal.bgColor : props.outlinedBgColor;

    const outlinedDisabledTextColor = props.outlinedDisabledTextColor === undefined ? btnTheme.outlined.disabled.textColor : props.outlinedDisabledTextColor;
    const outlinedDisabledBgColor = props.outlinedDisabledBgColor === undefined ? btnTheme.outlined.disabled.bgColor : props.outlinedDisabledBgColor;

    const outlinedHoverTextColor = props.outlinedHoverTextColor === undefined ? btnTheme.outlined.hover.textColor : props.outlinedHoverTextColor;
    const outlinedHoverBgColor = props.outlinedHoverBgColor === undefined ? btnTheme.outlined.hover.bgColor : props.outlinedHoverBgColor;

    // button variant = text        ----------------------------------------------------------------------------------------------------------------

    const textTextColor = props.textTextColor === undefined ? btnTheme.text.normal.textColor : props.textTextColor;
    const textBgColor = props.textBgColor === undefined ? btnTheme.text.normal.bgColor : props.textBgColor;

    const textDisabledTextColor = props.textDisabledTextColor === undefined ? btnTheme.text.disabled.textColor : props.textDisabledTextColor;
    const textDisabledBgColor = props.textDisabledBgColor === undefined ? btnTheme.text.disabled.bgColor : props.textDisabledBgColor;

    const textHoverTextColor = props.textHoverTextColor === undefined ? btnTheme.text.hover.textColor : props.textHoverTextColor;
    const textdHoverBgColor = props.textdHoverBgColor === undefined ? btnTheme.text.hover.bgColor : props.textdHoverBgColor;

    // button variant = contained ----------------------------------------------------------------------------------------------------------------

    const containedTextColor = props.containedTextColor === undefined ? btnTheme.contained.normal.textColor : props.containedTextColor;
    const containedBgColor = props.containedBgColor === undefined ? btnTheme.contained.normal.bgColor : props.containedBgColor;

    const containedDisabledTextColor = props.containedDisabledTextColor === undefined ? btnTheme.contained.disabled.textColor : props.containedDisabledTextColor;
    const containedDisabledBgColor = props.containedDisabledBgColor === undefined ? btnTheme.contained.disabled.bgColor : props.containedDisabledBgColor;

    const containedHoverTextColor = props.containedHoverTextColor === undefined ? btnTheme.contained.hover.textColor : props.containedHoverTextColor;
    const containedHoverBgColor = props.containedHoverBgColor === undefined ? btnTheme.contained.hover.bgColor : props.containedHoverBgColor;


    const getColor = (mode, type, labelText) => {
        let color = "black";

        switch (variant) {
            case btnTheme.variant.outlined:
                switch (mode) {
                    case "normal":
                        switch (type) {
                            case "textColor":
                                color = outlinedTextColor;
                                break;
                            case "bgColor":
                                color = outlinedBgColor;
                                break;
                            // no default
                        }
                        break;
                    case "disabled":
                        switch (type) {
                            case "textColor":
                                color = outlinedDisabledTextColor;
                                break;
                            case "bgColor":
                                color = outlinedDisabledBgColor;
                                break;
                            // no default
                        }
                        break;
                    case "hover":
                        switch (type) {
                            case "textColor":
                                color = outlinedHoverTextColor;
                                break;
                            case "bgColor":
                                color = outlinedHoverBgColor;
                                break;
                            // no default
                        }
                    break;
                    // no default
                }
                break;
            case btnTheme.variant.text:
                switch (mode) {
                    case "normal":
                        switch (type) {
                            case "textColor":
                                color = textTextColor;
                                break;
                            case "bgColor":
                                color = textBgColor;
                                break;
                            // no default
                        }
                        break;
                    case "disabled":
                        switch (type) {
                            case "textColor":
                                color = textDisabledTextColor;
                                break;
                            case "bgColor":
                                color = textDisabledBgColor;
                                break;
                            // no default
                        }
                        break;
                    case "hover":
                        switch (type) {
                            case "textColor":
                                color = textHoverTextColor;
                                break;
                            case "bgColor":
                                color = textdHoverBgColor;
                                break;
                            // no default
                        }
                        break;
                    // no default
                }
                break;
            case btnTheme.variant.contained:
                switch (mode) {
                    case "normal":
                        switch (type) {
                            case "textColor":
                                color = containedTextColor;
                                break;
                            case "bgColor":
                                color = containedBgColor;
                                break;
                            // no default
                        }
                        break;
                    case "disabled":
                        switch (type) {
                            case "textColor":
                                color = containedDisabledTextColor;
                                break;
                            case "bgColor":
                                color = containedDisabledBgColor;
                                break;
                            // no default
                        }
                        break;
                    case "hover":
                        switch (type) {
                            case "textColor":
                                color = containedHoverTextColor;
                                break;
                            case "bgColor":
                                color = containedHoverBgColor;
                                break;
                            // no default
                        }
                        break;
                    // no default
                }
                break;
            // no default
        }

        return color;
    }


    const handleOnClick = (event) => {
        onClick(event, props.fieldName)
    };

    return (
        <div style={{paddingTop: paddingTop}}>
            <Button id={uniqueIdentifier}
                    name={uniqueIdentifier}
                    variant={variant}
                    sx={{
                        color: getColor(disabled ? "disabled" : "normal", "textColor", label),
                        backgroundColor: getColor(disabled ? "disabled" : "normal", "bgColor", label),
                        fontFamily: theme.fontFamily,
                        width: width,
                        "&:hover": {
                            color: getColor("hover", "textColor", label),
                            backgroundColor: getColor("hover", "bgColor", label),
                        },
                        padding: buttonPadding
                    }}
                    onClick={handleOnClick}
                    disabled={disabled}
                    fullWidth={fullWidth}
                    autoFocus={autofocus}
            >{label}</Button>
            <div style={{paddingLeft: "15px", display: canHaveErrorMessage ? "inline" : "none", color: theme.palette.errorMessage}}>{errorMessage}</div>
        </div>
    );
}
