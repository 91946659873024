import * as types from "../constants/actionConstants";
import IntegrationApi from "../api/IntegrationApi";


const initializeEditorCreate = (integration) => {
    if (!integration) {
        integration = {};
    }

    return {
        type: types.INTEGRATION_EDITOR_INITIALIZE,
        payload: {
            integration: {
                disabled: false,
                id: "",
                name: "",
                integrationType: "CUSTOMER_JOURNEY",
                integrationUrl: "",
                apiKey1: "",
                apiKey2: "",
            },
            options: {
                createMode: true,
                validate: [],
                isValidating: false
            }
        }
    };
};

const initializeEditorEdit = (integration) => {
    return {
        type: types.INTEGRATION_EDITOR_INITIALIZE,
        payload: {
            integration: integration,
            options: {
                createMode: false,
                validate: [],
                isValidating: false
            }
        }
    };
};

export function getIntegration(id) {
    return (dispatch) => {
        dispatch({type: types.INTEGRATION_EDITOR_GET_INIT, id});
        IntegrationApi.getIntegrationById(id).then((integration) => {
            dispatch({type: types.INTEGRATION_EDITOR_GET_SUCCESS, integration});
        });
    };
}

const updateIntegrationStore = (integration, options) => {
    return {
        type: types.INTEGRATION_EDITOR_UPDATE_STORE,
        payload:{
            integration,
            options
        }
    };
};

const clearEditor = () => {
    return {
        type: types.INTEGRATION_EDITOR_CLEAR
    };
};

const addIntegration = (question) => {
    return {
        type: types.INTEGRATION_EDITOR_ADD,
        question
    };
};

const checkIntegrationNameForDup = (name) => {
    return (dispatch) => {
        IntegrationApi.getIntegrationByName(name).then((integration) => {
            dispatch({type: types.INTEGRATION_EDITOR_CHECK_NAME_FOR_DUP, integration});
        });
    };
};

export {
    initializeEditorCreate,
    initializeEditorEdit,
    updateIntegrationStore,
    addIntegration,
    clearEditor,
    checkIntegrationNameForDup
};
