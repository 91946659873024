import React, {Component} from "react";
import PropTypes from "prop-types";
import ArgoTextFieldSelectHook from "./ArgoTextFieldSelectHook";
import * as metadataUtils from "../../utilities/metadata";
import * as TEMPLATE from "../../constants/templateConstants";


class ArgoTextDisplayDefination extends Component {

    constructor(props) {
        super(props);

        this.state = {
            textDisplay: props.initTo
        };
    }

    componentWillReceiveProps(nextProps) {
        this.setState({textDisplay: nextProps.initTo});
    }


    onSelectChange = (event, fieldName) => {

        let selected = event.target.value;

        let metadataValue = "";

        switch(selected) {
            case "hide":
                metadataValue = selected;
                break;
            case "default":
            case "custom":
                let defaultFont = this.getDefaultVariant();
                metadataValue =  JSON.stringify({
                    title: "",
                    hide: false,
                    font: "roboto",
                    fontFamily: 'Roboto, "Helvetica Neue", Helvetica, Arial, sans-serif',
                    fontWeight: "normal",
                    colorType: "default",
                    colorEntry: 'black',
                    color: "#000000",
                    backgroundColor: "",
                    backgroundImage: "",
                    fontSizing: "points",
                    fontSizeEntry: defaultFont.fontSizeEntry,
                    fontSize: defaultFont.fontSize,
                    variant: defaultFont.variant,
                    fontStyle: "normal",
                    textAlign: "left",  // left center right
                    opacityEntry: "100",
                    opacity: "100%",
                    icon: ""
                    //backgroundColor: 'red',
                });
                break;
            default:
                console.log("ArgoTextDisplayDefination.js Switch default E01"); // eslint-disable-line
        }

        let mpo = this.props.metadataPropsObj;

        this.setState({[fieldName]: selected}, () => {metadataUtils.setCustomMetadata(mpo.template, mpo.templateUpdateFunction, mpo.setStateCallbackFunction, this.props.fieldName, selected, mpo.metadataTag, (metadataValue !== "hide" ? JSON.parse(metadataValue) : "hide"), mpo.depth)});
    };


    getDefaultVariant = () => {

        // NOTE: taken from QD Portal: getTitleDefaultVariant() in displayDefObjConstants.js

        let fontDefault = {
            variant: "",
            fontSizeEntry: "",
            fontSize: ""
        };

        // const h1FontSize = '36px';  // Questionnaire
        // const h2FontSize = '31px';  // Category
        // const h3FontSize = '26px';  // Group
        // const h4FontSize = '21px';  // Question
        // const h5FontSize = '16px';  // Answer
        // const h6FontSize = '16px';  // Info/Comment Gray
        // const captionFontSize = '12px'; // Label
        // const subtitle1FontSize = '12px';  // Label Gray
        // const subtitle2FontSize = '12px';  // Label Secondary Color
        // const body1FontSize = '14px';
        // const body2FontSize = '14px'; // Italic
        // const overlineFontSize = '10px';
        // const buttonFontSize = '32px';

        switch(this.props.templateType) {
            case TEMPLATE.TYPE.QUESTIONNAIRE:
                switch(this.props.titleType) {
                    case "name":
                        fontDefault.variant = "h1";
                        fontDefault.fontSizeEntry = "36";
                        fontDefault.fontSize = "36pt";
                        break;
                    case "desc":
                        fontDefault.variant = "h5";
                        fontDefault.fontSizeEntry = "16";
                        fontDefault.fontSize = "16pt";
                        break;
                    case "script":
                    case "scriptBefore":
                        fontDefault.variant = "h5";
                        fontDefault.fontSizeEntry = "16";
                        fontDefault.fontSize = "16pt";
                        break;
                    case "scriptAfter":
                        fontDefault.variant = "h5";
                        fontDefault.fontSizeEntry = "16";
                        fontDefault.fontSize = "16pt";
                        break;
                    case "close-text1":
                        fontDefault.variant = "h3";
                        fontDefault.fontSizeEntry = "26";
                        fontDefault.fontSize = "26pt";
                        break;
                    case "close-text2":
                        fontDefault.variant = "h4";
                        fontDefault.fontSizeEntry = "21";
                        fontDefault.fontSize = "21pt";
                        break;
                    case "close-text3":
                        fontDefault.variant = "h5";
                        fontDefault.fontSizeEntry = "16";
                        fontDefault.fontSize = "16pt";
                        break;
                    default:
                        console.log("ArgoTextDisplayDefination.js Switch default E02"); // eslint-disable-line
                }
                break;
            case TEMPLATE.TYPE.CATEGORY:
                switch(this.props.titleType) {
                    case "name":
                        fontDefault.variant = "h2";
                        fontDefault.fontSizeEntry = "31";
                        fontDefault.fontSize = "31pt";
                        break;
                    case "description":
                        fontDefault.variant = "h5";
                        fontDefault.fontSizeEntry = "16";
                        fontDefault.fontSize = "16pt";
                        break;
                    case "script":
                        fontDefault.variant = "h5";
                        fontDefault.fontSizeEntry = "16";
                        fontDefault.fontSize = "16pt";
                        break;
                    case "header-left":
                        fontDefault.variant = "h4";
                        fontDefault.fontSizeEntry = "21";
                        fontDefault.fontSize = "21pt";
                        break;
                    case "header-center":
                        fontDefault.variant = "h4";
                        fontDefault.fontSizeEntry = "21";
                        fontDefault.fontSize = "21pt";
                        break;
                    case "header-right":
                        fontDefault.variant = "h4";
                        fontDefault.fontSizeEntry = "21";
                        fontDefault.fontSize = "21pt";
                        break;
                    default:
                        console.log("ArgoTextDisplayDefination.js Switch default E03"); // eslint-disable-line
                }
                break;
            case TEMPLATE.TYPE.GROUP:
                switch(this.props.titleType) {
                    case "name":
                        fontDefault.variant = "h3";
                        fontDefault.fontSizeEntry = "26";
                        fontDefault.fontSize = "26pt";
                        break;
                    case "description":
                        fontDefault.variant = "h5";
                        fontDefault.fontSizeEntry = "16";
                        fontDefault.fontSize = "16pt";
                        break;
                    default:
                        console.log("ArgoTextDisplayDefination.js Switch default E04"); // eslint-disable-line
                }
                break;
            case TEMPLATE.TYPE.QUESTION:
                switch(this.props.titleType) {
                    case "name":
                        fontDefault.variant = "h6";
                        fontDefault.fontSizeEntry = "16";
                        fontDefault.fontSize = "16pt";
                        break;
                    case "description":
                        fontDefault.variant = "body1";
                        fontDefault.fontSizeEntry = "14";
                        fontDefault.fontSize = "14pt";
                        break;
                    case "label":
                        fontDefault.variant = "caption";
                        fontDefault.fontSizeEntry = "12";
                        fontDefault.fontSize = "12pt";
                        break;
                    default:
                        console.log("ArgoTextDisplayDefination.js Switch default E05"); // eslint-disable-line
                }
                break;
            default:
                console.log("ArgoTextDisplayDefination.js Switch default E06"); // eslint-disable-line
        }

        return fontDefault;
    };


    render() {

        let textDisplayItems = [];

        if (!this.props.hideOnly) {
            textDisplayItems.push({value: "default", text: "Default"});
        }
        if (this.props.hideOption) {
            textDisplayItems.push({value: "hide", text: "Hide"});
        }
        if (!this.props.hideOnly) {
            textDisplayItems.push({value: "custom", text: "Custom"});
        }

        return (

            <React.Fragment>

                <div style={{width: "15px"}}></div>

                <ArgoTextFieldSelectHook
                    fileName="ArgoTextDisplayDefination"
                    fieldName="textDisplay"
                    index={this.props.index}
                    label={this.props.labelText}
                    value={this.state.textDisplay}
                    onChange={this.onSelectChange}
                    fullWidth={false}
                    width="130px"
                    menuItems={textDisplayItems}
                />

            </React.Fragment>
        );
    }
}


ArgoTextDisplayDefination.defaultProps = {
    labelText: "Portal Display",
    hideOption: true,
    hideOnly: false,
    index: 0
};


ArgoTextDisplayDefination.propTypes = {
    fieldName: PropTypes.string,
    labelText: PropTypes.string,
    hideOption: PropTypes.bool,
    hideOnly: PropTypes.bool,
    initTo: PropTypes.string,
    templateType: PropTypes.string,
    titleType: PropTypes.string,
    metadataPropsObj: PropTypes.object,
    index: PropTypes.number
};


export default ArgoTextDisplayDefination;
