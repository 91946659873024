import * as React from 'react';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import ListItemText from '@mui/material/ListItemText';
import Select from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

export default function ArgoMultipleSelectCheckmarksHook(props) {
    const [itemName, setitemName] = React.useState([]);
    const [itemId, setitemId] = React.useState([]);

    const fileName = props.fileName === undefined ? "NoFileName" : props.fileName;
    const fieldName = props.fieldName === undefined ? "NoFieldName" : props.fieldName;

    const uniqueIdentifier = fileName + "-ArgoMultipleSelectCheckmarksHook-" + fieldName;

    const label = props.label === undefined ? "" : props.label;

    const width = props.width === undefined ? 150 : props.width;

    const menuItems = props.menuItems === undefined ? [] : props.menuItems;

    const valueProperty = props.valueProperty === undefined ? "value" : props.valueProperty;
    const textProperty = props.textProperty === undefined ? "text" : props.textProperty;

    const onChange = props.onChange === undefined ? function () {} : props.onChange;

    const handleChange = (event, test, me) => {

        //returns an array of selected values
        const {
            target: { value },
        } = event;

        let selectedId = null;
        if (value.length !== 0) {
            let arrayRow = menuItems.find(obj => obj[textProperty] === value[value.length-1]);
            selectedId = arrayRow[valueProperty];
        }

        // Value is an array of selected answers so parse and add commas
        setitemName(
            // On autofill we get a stringified value.
            typeof value === 'string' ? value.split(',') : value,
        );

        let selectedIdCommaDelimitedString = ""
        for (let i = 0; i < menuItems.length; i++) {
            // If text is in selected string add ID, we need selected id's for API call
            if (value.includes(menuItems[i][textProperty])) {
                selectedIdCommaDelimitedString = selectedIdCommaDelimitedString + (selectedIdCommaDelimitedString === "" ? "" : ",") + menuItems[i][valueProperty];
            }
        }

        onChange(event, fieldName, selectedIdCommaDelimitedString);
    };

    return (

            <FormControl variant="standard" sx={{width: width}}>
                <InputLabel id={uniqueIdentifier}>{label}</InputLabel>
                <Select
                    labelId={uniqueIdentifier}
                    id={uniqueIdentifier + "checkbox"}
                    multiple
                    value={itemName}
                    onChange={handleChange}
                    renderValue={(selected) => selected.join(', ')}
                    MenuProps={MenuProps}
                >
                    {menuItems.map((row, index) => (
                        <MenuItem key={row[valueProperty]} value={row[textProperty]}>
                            <Checkbox checked={itemName.indexOf(row[textProperty]) > -1} />
                            <ListItemText primary={row[textProperty].toString()}/>
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>

    );
}
