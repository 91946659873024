import React, {useEffect} from 'react';

export default function ChangeAppTitleToPortalHook(props) {

    const mode = props.mode;

    // Similar to componentDidMount and componentDidUpdate:
    useEffect(() => {
        // Update the document title using the browser API
        if (mode === "yes") {
            document.title = `QD Portal Questionnaire`;
        }
    });

    // return (
    //     <div>
    //     </div>
    // );
}