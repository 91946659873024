import TemplateApi from "../api/TemplateApi";
import * as types from "../constants/actionConstants";
import {closeDrawer} from "./drawerActions";
import {initGetTemplateList} from "./templateListActions";
import * as TEMPLATE from "../constants/templateConstants";


export function getGroupTemplate(questionKey, id) {

    let thunk = (dispatch)=>{
        if (id) {
            TemplateApi.getGroupById(id)
                .then((groupTemplate) => {
                    dispatch({type: types.GROUP_TEMPLATE_GET_SUCCESS, groupTemplate});
                });
        }
        else {
            TemplateApi.getCurrentGroup(questionKey)
                .then((groupTemplate) => {
                    dispatch({type: types.GROUP_TEMPLATE_GET_SUCCESS, groupTemplate});
                });
        }
    };

    thunk.meta = {
        debounce: {
            time: 2000,
            key: id || questionKey,
            leading: true,
            trailing: false
        }
    };

    return thunk;
}

export function saveGroupTemplate(groupTemplateObject) {
    return (dispatch)=>{
        TemplateApi.saveGroupTemplate(groupTemplateObject)
            .then((groupTemplate) =>{
                dispatch({type: types.GROUP_TEMPLATE_UPDATE_SUCCESS , groupTemplate});
            });
    };
}

export function saveGroupTemplateAndCloseDrawer(groupTemplateObject, filter, versionsFilter, templateMode) {
    return (dispatch)=>{
        TemplateApi.saveGroupTemplate(groupTemplateObject)
            .then((groupTemplate) =>{
                dispatch({type: types.GROUP_TEMPLATE_UPDATE_SUCCESS , groupTemplate});
                dispatch(closeDrawer());

                // Best to load group list page in all cases
                dispatch(initGetTemplateList(filter, TEMPLATE.TYPE.GROUP, TEMPLATE.MODE.CURRENT));
                if (templateMode === TEMPLATE.MODE.VERSIONS) {
                    let tempVersionsFilter = {...versionsFilter};
                    tempVersionsFilter.templateName = groupTemplateObject.name;
                    tempVersionsFilter.templateKey = groupTemplateObject.templateKey;
                    dispatch(initGetTemplateList(tempVersionsFilter, TEMPLATE.TYPE.GROUP, TEMPLATE.MODE.VERSIONS));
                }
            });
    };
}

export function createGroupTemplate(groupTemplateObject) {
    return (dispatch) => {
        TemplateApi.createGroupTemplate(groupTemplateObject)
            .then((groupTemplate) => {
                dispatch({type: types.GROUP_TEMPLATE_CREATE_SUCCESS, groupTemplate});

                if (groupTemplateObject.answerIndex || groupTemplateObject.answerIndex === 0) {
                    dispatch({type: types.QUESTION_EDITOR_ADD_GROUP, groupTemplate, answerIndex: groupTemplateObject.answerIndex, depth: 0});
                }
            });
    };
}

export function createGroupTemplatAndCloseDrawer(groupTemplateObject, filter, onSuccess = ()=>{}) {
    return (dispatch) => {
        TemplateApi.createGroupTemplate(groupTemplateObject)
            .then((groupTemplate) => {
                dispatch({type: types.GROUP_TEMPLATE_CREATE_SUCCESS, groupTemplate});

                if (groupTemplateObject.answerIndex || groupTemplateObject.answerIndex === 0) {
                    dispatch({type: types.QUESTION_EDITOR_ADD_GROUP, groupTemplate, answerIndex: groupTemplateObject.answerIndex, depth: 0});
                }
                onSuccess();
                dispatch(closeDrawer());
                dispatch(initGetTemplateList(filter, TEMPLATE.TYPE.GROUP, TEMPLATE.MODE.CURRENT));
            });
    };
}





