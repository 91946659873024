import * as types from "../constants/actionConstants";
import * as TEMPLATE from "../constants/templateConstants";
import {initGetTemplateList} from "./templateListActions";
import {getOutstandingQuestionnaireTemplateCount} from "./questionnaireTemplateActions";
import TemplateAPI from "../api/TemplateApi";

const openDrawer = (props, view, template) => {
    return {
        type: types.OPEN_DRAWER,
        template,
        view,
        props
    };
};

const closeDrawer = () => {
    return {
        type: types.CLOSE_DRAWER
    };
};

// -----------------------------------------------------------------------------------------------------------------
// Publish !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!! <editor-fold>
// -----------------------------------------------------------------------------------------------------------------

const publish = (id, publishDTO, key, filter, versionsFilter) => {

    return (dispatch) => {
        TemplateAPI.publishQuestionnaire(id, publishDTO)
            .then((response) => {
                return dispatch(publishSuccess(key, response, filter, versionsFilter));
            })
            .catch((error) => {
                dispatch(publishFailure());
                console.log(error.message);// eslint-disable-line
            });
    };

};

// Publish actions
const publishSuccess = (key, response, filter, versionsFilter) => {
    return (dispatch) => {

        if (key !== undefined) {
            dispatch(initGetTemplateList(versionsFilter, TEMPLATE.TYPE.QUESTIONNAIRE, TEMPLATE.MODE.VERSIONS, key));
        }
        dispatch(getOutstandingQuestionnaireTemplateCount());
        dispatch(initGetTemplateList(filter, TEMPLATE.TYPE.QUESTIONNAIRE, TEMPLATE.MODE.CURRENT));
        dispatch(closeDrawer());
    };
};

const publishFailure = () => {
    return {
        type: types.PUBLISH_FAIL
    };
};



//</editor-fold>
// -----------------------------------------------------------------------------------------------------------------

// -----------------------------------------------------------------------------------------------------------------
// Change Date !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!! <editor-fold>
// -----------------------------------------------------------------------------------------------------------------

const changeDate = (id, changeDateDTO, key, filter, versionsFilter) => {

    return (dispatch) => {
        TemplateAPI.changeQuestionnaireDates(id, changeDateDTO)
            .then((response) => {
                return dispatch(changeDateSuccess(key, response, filter, versionsFilter));

            })
            .catch((error) => {
                dispatch(changeDateFailure());
                console.log(error.message);// eslint-disable-line

            });
    };

};

// Change Dates actions
const changeDateSuccess = (key, response, filter, versionsFilter) => {
    return (dispatch) => {
        if (key !== undefined) {
            dispatch(initGetTemplateList(versionsFilter, TEMPLATE.TYPE.QUESTIONNAIRE, TEMPLATE.MODE.VERSIONS, key));
        }
        dispatch(initGetTemplateList(filter, TEMPLATE.TYPE.QUESTIONNAIRE, TEMPLATE.MODE.CURRENT));
        dispatch(closeDrawer());
    };
};

const changeDateFailure = () => {
    return {
        type: types.CHANGE_DATE_FAIL,
    };
};

//</editor-fold>
// -----------------------------------------------------------------------------------------------------------------


// -----------------------------------------------------------------------------------------------------------------
// Scoring !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!! <editor-fold>
// -----------------------------------------------------------------------------------------------------------------

const scoringUpdate = (scoringUpdateObj, filter) => {

    return (dispatch) => {
        TemplateAPI.updateScoring(scoringUpdateObj)
            .then((response) => {
                dispatch(initGetTemplateList(filter, TEMPLATE.TYPE.QUESTIONNAIRE, TEMPLATE.MODE.CURRENT));
                return dispatch({type: types.SCORING_UPDATE_SUCCESS});
            })
            .catch((error) => {
                dispatch({type: types.SCORING_UPDATE_FAIL});
                console.log(error.message);// eslint-disable-line

            });
    };

};


//</editor-fold>
// -----------------------------------------------------------------------------------------------------------------

export {
    openDrawer,
    closeDrawer,
    publish,
    changeDate,
    scoringUpdate
};
