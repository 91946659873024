import * as React from "react";

function SvgUnselectedNeutral(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 24 24" {...props}>
      <defs>
        <rect
          id="unselected_neutral_svg__a"
          x={0}
          y={0}
          width={21.6}
          height={21.6}
          rx={10.8}
        />
      </defs>
      <g transform="translate(1.2 1.2)" fill="none" fillRule="evenodd">
        <mask id="unselected_neutral_svg__b" fill="#fff">
          <use xlinkHref="#unselected_neutral_svg__a" />
        </mask>
        <use fill="#FFF" xlinkHref="#unselected_neutral_svg__a" />
        <g
          mask="url(#unselected_neutral_svg__b)"
          fill="#000"
          fillOpacity={1}
        >
          <path d="M0 0h21.6v21.6H0z" />
        </g>
        <rect
          fill="#FFF"
          fillRule="nonzero"
          mask="url(#unselected_neutral_svg__b)"
          x={1.08}
          y={1.08}
          width={19.44}
          height={19.44}
          rx={9.72}
        />
      </g>
    </svg>
  );
}

export default SvgUnselectedNeutral;
