import * as style  from "../utilities/getStyle";
import * as tag from "./customMetadataConstants";
import * as font from "../utilities/fontUtilities";
import {
    HEADER_IMAGE_CENTER,
    HEADER_IMAGE_LEFT,
    HEADER_IMAGE_RIGHT,
    FOOTER_IMAGE_LEFT,
    FOOTER_IMAGE_CENTER,
    FOOTER_IMAGE_RIGHT,
    //SHOW_QUESTION_NUMBERS, metadataExists
} from "./customMetadataConstants";


// Display Definition Object contains an array of pages, Pages contains an array of sections, Sections contain an array of Items
// Section = MATERIAL UI GRID container

// Pages are an array of sections

export const OBJ_TYPE = {
    QUESTIONNAIRE: "QNR",
    CATEGORY: "CAT",
    GROUP: "GRP",
    GROUP_LIKERT: "GRP_LIKERT",
    QUESTION: "QUE",
    ANSWER: "ANSWER",
    BUTTON: "BUTTON",
    TITLE: "TITLE",
    DDLB: "DDLB",
};

export const OBJ_TEXT_TYPE = {
    NAME: "NAME",
    DESCRIPTION: "DESCRIPTION",
    SCRIPT_BEFORE: "SCRIPT_BEFORE",
    SCRIPT_AFTER: "SCRIPT_AFTER",
    CLOSE_TEXT1: "CLOSE_TEXT1",
    CLOSE_TEXT2: "CLOSE_TEXT2",
    CLOSE_TEXT3: "CLOSE_TEXT3",
    SCRIPT: "SCRIPT"
};

export const BASE = {
    TYPE: "type"
};

export const OBJ = {
    ITEM_ARRAY: "items",
    KEY: {
        DDO: {
            PAGE_ARRAY: "pages", // []
            PAGE_SECTION_ARRAY: "sections", // []
        },
        PAGE: {
            // Pages are an array of section
            SECTION: {
                TYPE: "type",
                CONTAINER: "container",
                DIRECTION: "direction",
                JUSTIFY: "justify",
                SIZE: "size",          // z values = lg, md, sm
                SIZE_DEF: "sizeDef",     // zd value = false | 'auto' | true | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12
                SPACING: "spacing",
                WRAP: "wrap",
                ALIGN_CONTENT: "alignContent",
                ALIGN_ITEMS: "alignItems",
                CLASS_TYPE: "classType",
                STYLE_TYPE: "styleType",
                SHOW_NUMBER: "showNumber",
                ITEM_ARRAY: "items", // []
                // Sections are an array of items...
                ITEM: {
                    TITLE: {
                        TYPE: "type",
                        FORMAT: "format",  // Plain, Info, Comment
                        SOURCE: "source",
                        ID: "id",
                        NAME: "name",
                        VARIANT: "variant",
                        SHOW_NUMBER: "showNumber",
                        SHOW_GROUP_KEY: "showGroupKey", // Only used for show group
                        SHOW_GROUP_OBJ_ARRAY: "showGroupObjArray", // Only used for show group
                        OBJ_HAS_DESCRIPTION: "hasDescription" // Groups and titles with descriotion need to render title and description before likert answer titles
                    },
                    BUTTON: {
                        TYPE: "type",
                        NAME: "name",
                        LOCATION: "location",
                        MODE: "mode",
                        SIZE: "size",
                        COLOR: "color",
                        VARIANT: "variant"
                    },
                    PAGE_NUM: {
                        TYPE: "type",
                    },
                    QUESTION: {
                        TYPE: "type",
                        ID: "id",
                        SHOW_GROUP_KEY: "showGroupKey", // Only used for show group
                        SHOW_GROUP_OBJ_ARRAY: "showGroupObjArray", // Only used for show group
                        SHOW_NUMBER: "showNumber",
                    },
                    LIKERT_GROUP: {
                        TYPE: "type",
                        ID: "id",
                        QUESTIONS: "questions" // []
                    },
                    LIKERT_QUESTION: {
                        TYPE: "type",
                        ID: "id",
                        SHOW_GROUP_KEY: "showGroupKey", // Only used for show group
                        SHOW_GROUP_OBJ_ARRAY: "showGroupObjArray", // Only used for show group
                        SHOW_NUMBER: "showNumber",
                    },
                    ICON: {
                        TYPE: "type",
                        IMAGE: "image"
                    }
                }
            }
        }
    },
    // Page object types:
    TYPE: {
        SECTION: "SECTION",
        SECTION_ITEM: {
            TITLE: "TITLE",
            BUTTON: "BUTTON",
            QUESTION: "QUESTION",
            LIKERT_GROUP: "LIKERT_GROUP",
            LIKERT_QUESTION: "LIKERT_QUESTION",
            PAGE_NUM: "PAGE_NUM",
            ICON: "ICON"
        },
    }
};

export const SECTION = {
    JUSTIFY: {
        FLEX_START: "flex-start",
        CENTER: "center",
        FLEX_END: "flex-end",
        SPACE_BETWEEN: "space-between",
        SPACE_AROUND: "space-around",
        SPACE_EVENLY: "space-evenly"
    },
    ALIGN_ITEMS: {
        FLEX_START: "flex-start",
        CENTER: "center",
        FLEX_END: "flex-end",
        STRETCH: "stretch",
        BASELINE: "baseline"
    },
    CLASS_TYPE: {
        NONE: "CLASS_NONE",
        CONTROL: "CLASS_CONTROL", // this.props.classes.controlGrid
        FOOTER: "CLASS_FOOTER", // this.props.classes.footerGrid
    },
    STYLE_TYPE: {
        NONE: "0",
        ONE: "1",
        TWO: "2",
    }
};


// Item Type TITLE definitions:
export const TITLE = {
    FORMAT: {
        CUSTOM: "TITLE_FORMAT_CUSTOM",
        PLAIN: "TITLE_FORMAT_PLAIN",
        PLAIN_CENTER: "TITLE_FORMAT_PLAIN_CENTER",
        PLAIN_RIGHT: "TITLE_FORMAT_PLAIN_RIGHT",
        INFO: "TITLE_FORMAT_INFO",
        COMMENT: "TITLE_FORMAT_COMMENT",
        APP_HEADER: "APP_HEADER",
        PAGE_HEADER: "TITLE_FORMAT_PAGE_HEADER",
        PAGE_FOOTER: "TITLE_FORMAT_PAGE_FOOTER",
        PAGE_FOOTER2: "TITLE_FORMAT_PAGE_FOOTER2",
        CATEGORY_TITLE_BAR: "CATEGORY_TITLE_BAR",
        // HEADER: "TITLE_FORMAT_HEADER",
        // FOOTER: "TITLE_FORMAT_FOOTER",
        SPACER: "TITLE_FORMAT_SPACER",
        METADATA: "TITLE_FORMAT_METADATA",
    },
    SOURCE: {
        NONE: "NONE",
        QNR_NAME: "QNR_NAME",
        QNR_DESC: "QNR_DESC",
        QNR_SCRIPT_BEFORE: "QNR_SCRIPT_BEFORE",
        QNR_SCRIPT_AFTER: "QNR_SCRIPT_AFTER",
        QNR_CLOSE_TEXT1: "QNR_CLOSE_TEXT1",
        QNR_CLOSE_TEXT2: "QNR_CLOSE_TEXT2",
        QNR_CLOSE_TEXT3: "QNR_CLOSE_TEXT3",
        QNR_METADATA_PAGE_HEADER: "QNR_METADATA_PAGE_HEADER", // Use metadata display-def-page-header...
        QNR_METADATA_PAGE_FOOTER: "QNR_METADATA_PAGE_FOOTER", // Use metadata display-def-page-footer...
        QNR_METADATA_PAGE_FOOTER2: "QNR_METADATA_PAGE_FOOTER2", // Use metadata display-def-page-footer...
        QNR_METADATA_HEADER: "QNR_METADATA_HEADER", // Use metadata display-def-header-title...
        QNR_METADATA_FOOTER: "QNR_METADATA_FOOTER", // Use metadata display-def-footer-title...
        QNR_METADATA_FOOTER2: "QNR_METADATA_FOOTER2", // Use metadata display-def-footer-title...
        CAT_NAME: "CAT_NAME",
        CAT_DESC: "CAT_DESC",
        CAT_SCRIPT: "CAT_SCRIPT",
        CAT_METADATA_TITLE_BAR: "CAT_METADATA_TITLE_BAR", // Use metadata display-def-header-title...
        // CAT_METADATA_FOOTER: "CAT_METADATA_FOOTER", // Use metadata display-def-footer-title...
        GRP_NAME: "GRP_NAME",
        GRP_DESC: "GRP_DESC",
        // GRP_METADATA_HEADER: "GRP_METADATA_HEADER", // Use metadata display-def-header-title...
        // GRP_METADATA_FOOTER: "GRP_METADATA_FOOTER", // Use metadata display-def-footer-title...
        QUE_NAME: "QUE_NAME",
        QUE_DESC: "QUE_DESC",
        // QUE_METADATA_HEADER: "QUE_METADATA_HEADER", // Use metadata display-def-header-title...
        // QUE_METADATA_FOOTER: "QUE_METADATA_FOOTER", // Use metadata display-def-footer-title...
        DEFAULT: "D"
    },
    ID: {
        NONE: null
    },
    NAME: {
        USE_SOURCE: "",
        DEFAULT1: "1",
        DEFAULT2: "2",
        DEFAULT3: "3",
        DEFAULT4: "4",
        REPLACE_DEFAULT4: "x4"
    }
};

export const BUTTON = {
    MODE: {
        START: "START", // YES
        CANCEL: "CANCEL",
        FINISH: "FINISH", // SUBMIT
        NEXT: "NEXT",
        PREVIOUS: "PREVIOUS",
        VIEW_PDF: "VIEF PDF",
        CLOSE: "CLOSE"
    },
    VARIANT: {
        CONTAINED: "contained",
        OUTLINED: "outlined",
        TEXT: "text"
    },
    COLOR: {
        NONE: "", // ""
        DEFAULT: "default",
        INHERIT: "inherit",
        PRIMARY: "primary",
        SECONDARY: "secondary",
    },
    NAME: {
        DEFAULT: ""
    },
    SIZE: {
        SMALL: "small",
        MEDIUM: "medium",
        LARGE: "large"
    }
};

export const ICON_TYPE = {
    GREEN_CHECK_ICON: "green-check-icon"
};


// ---------------------------------------------------------------------------------------------------------------------
// Function
// ---------------------------------------------------------------------------------------------------------------------

// Create Display Definition Object "ddo"
export function createDisplayDefObj() {

    let obj = {
        [OBJ.KEY.DDO.PAGE_ARRAY]: []
    };

    return obj;
}


export function createPage() {

    let obj = {[OBJ.KEY.DDO.PAGE_SECTION_ARRAY]: []};

    return obj;
}


export function addPageToDisplayDefObj(displayDefObj, pageObj) {

    displayDefObj[OBJ.KEY.DDO.PAGE_ARRAY].push(pageObj);
}


export function createPageSection(justify, alignItems, styleType, classType) {

    let obj = {[OBJ.ITEM_ARRAY]: []};  // Sections are divided into items

    obj[OBJ.KEY.PAGE.SECTION.TYPE] = OBJ.TYPE.SECTION;
    obj[OBJ.KEY.PAGE.SECTION.JUSTIFY] = justify;
    obj[OBJ.KEY.PAGE.SECTION.ALIGN_ITEMS] = alignItems;
    obj[OBJ.KEY.PAGE.SECTION.STYLE_TYPE] = styleType;
    obj[OBJ.KEY.PAGE.SECTION.CLASS_TYPE] = classType;

    return obj;
}


export function addSectionToPage(pageObj, sectionObj) {

    pageObj[OBJ.KEY.DDO.PAGE_SECTION_ARRAY].push(sectionObj);
}


// ---------------------------------------------------------------------------------------------------------------------
// ADD OBJECT TO PAGE
// ---------------------------------------------------------------------------------------------------------------------

export function addTitleToSection(sectionObj, format, source, id, name, variant, showNumber = "", showGroupKey = "", showGroupObjArray = [], hasDescription = false) {

    let obj = {};

    obj[OBJ.KEY.PAGE.SECTION.ITEM.TITLE.TYPE] =  OBJ.TYPE.SECTION_ITEM.TITLE;
    obj[OBJ.KEY.PAGE.SECTION.ITEM.TITLE.FORMAT] =  format;  // Plain, Info, Comment
    obj[OBJ.KEY.PAGE.SECTION.ITEM.TITLE.SOURCE] =  source;
    obj[OBJ.KEY.PAGE.SECTION.ITEM.TITLE.ID] =  id;
    obj[OBJ.KEY.PAGE.SECTION.ITEM.TITLE.NAME] =  name; // Text or (USE_SOURCE) for text
    obj[OBJ.KEY.PAGE.SECTION.ITEM.TITLE.VARIANT] =  variant;
    obj[OBJ.KEY.PAGE.SECTION.ITEM.TITLE.SHOW_NUMBER] =  showNumber;  // IF not blank then the number to display before name
    obj[OBJ.KEY.PAGE.SECTION.ITEM.TITLE.SHOW_GROUP_KEY] =  showGroupKey;  // Used for show group
    obj[OBJ.KEY.PAGE.SECTION.ITEM.TITLE.SHOW_GROUP_OBJ_ARRAY] =  showGroupObjArray;  // Used for show group
    obj[OBJ.KEY.PAGE.SECTION.ITEM.TITLE.OBJ_HAS_DESCRIPTION] =  hasDescription;  // Used for show group

    sectionObj[OBJ.ITEM_ARRAY].push(obj);
}


export function addIconToSection(sectionObj, image) {

    let obj = {};

    obj[OBJ.KEY.PAGE.SECTION.ITEM.ICON.TYPE] =  OBJ.TYPE.SECTION_ITEM.ICON;
    obj[OBJ.KEY.PAGE.SECTION.ITEM.ICON.IMAGE] =  image;

    sectionObj[OBJ.ITEM_ARRAY].push(obj);
}


export function addButtonToSection(sectionObj, mode, variant, color, name) {

    let obj = {};

    obj[OBJ.KEY.PAGE.SECTION.ITEM.BUTTON.TYPE] =  OBJ.TYPE.SECTION_ITEM.BUTTON;
    obj[OBJ.KEY.PAGE.SECTION.ITEM.BUTTON.MODE] =  mode;
    obj[OBJ.KEY.PAGE.SECTION.ITEM.BUTTON.VARIANT] =  variant;
    obj[OBJ.KEY.PAGE.SECTION.ITEM.BUTTON.COLOR] =  color;
    obj[OBJ.KEY.PAGE.SECTION.ITEM.BUTTON.NAME] =  name;

    sectionObj[OBJ.ITEM_ARRAY].push(obj);
}


export function addQuestionToSection(sectionObj, iObj, showGroupKey = "", showGroupObjArray = [], qNumObj) {

    let obj = {};
    let mdValue = "";


    // This code needed to accomodate DDLB question types that can have the question name hidden since it's in the DDLB
    if ((qNumObj.useQuestionNumbers)&&(tag.metadataExists(iObj, tag.DISPLAY_DEF_NAME))) {
        mdValue = tag.metadataValue(iObj, tag.DISPLAY_DEF_NAME);
        if (mdValue === "hide") {
            qNumObj.currQuestionNumber += 1;
        }
    }

    obj[OBJ.KEY.PAGE.SECTION.ITEM.QUESTION.TYPE] =  OBJ.TYPE.SECTION_ITEM.QUESTION;
    obj[OBJ.KEY.PAGE.SECTION.ITEM.QUESTION.ID] =  iObj.id;
    obj[OBJ.KEY.PAGE.SECTION.ITEM.QUESTION.SHOW_GROUP_KEY] =  showGroupKey;  // Used for show group
    obj[OBJ.KEY.PAGE.SECTION.ITEM.QUESTION.SHOW_GROUP_OBJ_ARRAY] =  showGroupObjArray;  // Used for show group
    obj[OBJ.KEY.PAGE.SECTION.ITEM.QUESTION.SHOW_NUMBER] =  qNumObj.currQuestionNumber + qNumObj.questionNumberAppend;  // Used for question numbers

    sectionObj[OBJ.ITEM_ARRAY].push(obj);
}


export function createLikertGroupObj(id) {

    let obj = {};

    obj[OBJ.KEY.PAGE.SECTION.ITEM.LIKERT_GROUP.TYPE] =  OBJ.TYPE.SECTION_ITEM.LIKERT_GROUP;
    obj[OBJ.KEY.PAGE.SECTION.ITEM.LIKERT_GROUP.ID] =  id;
    obj[OBJ.KEY.PAGE.SECTION.ITEM.LIKERT_GROUP.QUESTIONS] =  [];

    return obj;
}


export function addLikertQuestionToLikertGroup(likertGroupObj, id, qNumObj, showGroupKey = "", showGroupObjArray = []) {

    let obj = {};

    obj[OBJ.KEY.PAGE.SECTION.ITEM.LIKERT_QUESTION.TYPE] =  OBJ.TYPE.SECTION_ITEM.LIKERT_QUESTION;
    obj[OBJ.KEY.PAGE.SECTION.ITEM.LIKERT_QUESTION.ID] =  id;
    obj[OBJ.KEY.PAGE.SECTION.ITEM.LIKERT_QUESTION.SHOW_GROUP_KEY] =  showGroupKey;
    obj[OBJ.KEY.PAGE.SECTION.ITEM.LIKERT_QUESTION.SHOW_GROUP_OBJ_ARRAY] =  showGroupObjArray;
    obj[OBJ.KEY.PAGE.SECTION.ITEM.LIKERT_QUESTION.SHOW_NUMBER] =  questionSubNumber(qNumObj, qNumObj.subnumberLikertQuestions);

    likertGroupObj[OBJ.KEY.PAGE.SECTION.ITEM.LIKERT_GROUP.QUESTIONS].push(obj);
}


export function addLikertGroupToSection(sectionObj, likertGroupObj) {

    sectionObj[OBJ.ITEM_ARRAY].push(likertGroupObj);
}


export function getLikertGroupQuestions(likertGroupObj) {

    return likertGroupObj[OBJ.KEY.PAGE.SECTION.ITEM.LIKERT_GROUP.QUESTIONS];
}


export function getSectionItemType(obj) {

    return obj[BASE.TYPE];
}


export function getObjValue(obj, key) {

    let value = obj[key];

    return value;
}


export function getObjValueWithConversion(obj, key) {

    let value = "";

    switch (obj.t) {
        case OBJ.TYPE.SECTION:
            value = convertSectionValueToText(key, obj[key]);
            break;
        case OBJ.TYPE.SECTION_ITEM.TITLE:
            value = "";
            break;
        case OBJ.TYPE.SECTION_ITEM.BUTTON:
            value = "";
            break;
        default:
            value = obj[key];
            break;
    }

    return value;
}


export function convertSectionValueToText(key, storedValue) {

    let convertedValue = "";

    switch(key) {
        case OBJ.KEY.PAGE.SECTION.JUSTIFY:
            switch (storedValue) {
                case SECTION.JUSTIFY.FLEX_START:
                    convertedValue = "flex-start";
                    break;
                case SECTION.JUSTIFY.CENTER:
                    convertedValue = "center";
                    break;
                case SECTION.JUSTIFY.FLEX_END:
                    convertedValue = "flex-end";
                    break;
                case SECTION.JUSTIFY.SPACE_BETWEEN:
                    convertedValue = "space-between";
                    break;
                case SECTION.JUSTIFY.SPACE_AROUND:
                    convertedValue = "space-around";
                    break;
                case SECTION.JUSTIFY.SPACE_EVENLY:
                    convertedValue = "space-evenly";
                    break;
                default:
                    convertedValue = storedValue;
                    break;
            }
            break;
        case OBJ.KEY.PAGE.SECTION.ALIGN_ITEMS:
            switch (storedValue) {
                case SECTION.ALIGN_ITEMS.FLEX_START:
                    convertedValue = "flex-start";
                    break;
                case SECTION.ALIGN_ITEMS.CENTER:
                    convertedValue = "center";
                    break;
                case SECTION.ALIGN_ITEMS.FLEX_END:
                    convertedValue = "flex-end";
                    break;
                case SECTION.ALIGN_ITEMS.STRETCH:
                    convertedValue = "stretch";
                    break;
                case SECTION.ALIGN_ITEMS.BASELINE:
                    convertedValue = "baseline";
                    break;
                default:
                    convertedValue = storedValue;
                    break;
            }
            break;
        case OBJ.KEY.PAGE.SECTION.STYLE_TYPE:
            convertedValue = storedValue;
            break;
        case OBJ.KEY.PAGE.SECTION.CLASS_TYPE:
            convertedValue = storedValue;
            break;
        default:
            convertedValue = storedValue;
            break;
    }

    return convertedValue;
}


// Convert a source to the actual text from the instance
export function convertTitleSourceReturnDetailsObj(questionnaire, itemObj, currPageNum, numberOfPages, qualifierAppendedToId) {

    let qnr = questionnaire;

    let source = getObjValue(itemObj, OBJ.KEY.PAGE.SECTION.ITEM.TITLE.SOURCE);

    // TODO maybe rename to titlesObj
    let detailsObj = {
        source: source,
        currentTemplate: null,
        isDescription: false,
        isScript: false,
        text: "",
        textBase: "",
        textArray: null,
        textObj: null,
        textObj2: null,
        itemObj: itemObj,
        currPageNum: currPageNum,
        numberOfPages: numberOfPages,
        qNum: "",
        categoryId: "",
        category: null,
        groupId: "",
        group: null,
        questionId: "",
        question: null,
        objKey: "",
        objKey2: "",
        isLeftAligned: true,
        alignQuestionsWithAnswers: false,
        CustomStyle: null // NOT used yet, but if if source = TITLE.SOURCE.QNR_NAME and alignQuestionsWithAnswers could be used by singleSelect
    };

    switch(source) {
        case TITLE.SOURCE.NONE:
            detailsObj.text = itemObj[OBJ.KEY.PAGE.SECTION.ITEM.TITLE.NAME];  // If no source must be a freeform entry so use it
            detailsObj.objKey = "SourceNone" + qualifierAppendedToId;
            break;
        case TITLE.SOURCE.QNR_NAME:
            detailsObj.text = qnr.name;
            detailsObj.objKey = "QnrName-" + qnr.id + qualifierAppendedToId;
            detailsObj.currentTemplate = qnr;
            break;
        case TITLE.SOURCE.QNR_DESC:
            detailsObj.text = qnr.description;
            detailsObj.objKey = "QnrDesc-" + qnr.id + qualifierAppendedToId;
            detailsObj.currentTemplate = qnr;
            break;
        case TITLE.SOURCE.QNR_SCRIPT_BEFORE:
            detailsObj.text = tag.metadataValue(qnr, tag.SCRIPT_BEFORE, "", "");
            detailsObj.objKey = "QnrScriptBefore-" + qnr.id + qualifierAppendedToId;
            detailsObj.currentTemplate = qnr;
            break;
        case TITLE.SOURCE.QNR_SCRIPT_AFTER:
            detailsObj.text = tag.metadataValue(qnr, tag.SCRIPT_AFTER, "", "");
            detailsObj.objKey = "QnrScriptAfter-" + qnr.id + qualifierAppendedToId;
            detailsObj.currentTemplate = qnr;
            break;
        case TITLE.SOURCE.QNR_CLOSE_TEXT1:
            detailsObj.text = tag.metadataValue(qnr, tag.CLOSE_PAGE_TEXT1, "", "");
            detailsObj.objKey = "QnrClose1-" + qnr.id + qualifierAppendedToId;
            detailsObj.currentTemplate = qnr;
            break;
        case TITLE.SOURCE.QNR_CLOSE_TEXT2:
            detailsObj.text = tag.metadataValue(qnr, tag.CLOSE_PAGE_TEXT2, "", "");
            detailsObj.objKey = "QnrClose2-" + qnr.id + qualifierAppendedToId;
            detailsObj.currentTemplate = qnr;
            break;
        case TITLE.SOURCE.QNR_CLOSE_TEXT3:
            detailsObj.text = tag.metadataValue(qnr, tag.CLOSE_PAGE_TEXT3, "", "");
            detailsObj.objKey = "QnrClose3-" + qnr.id + qualifierAppendedToId;
            detailsObj.currentTemplate = qnr;
            break;
        case TITLE.SOURCE.QNR_METADATA_PAGE_HEADER:
            detailsObj.textObj = getPageHeaderObj(qnr, currPageNum, numberOfPages);
            detailsObj.objKey = "QnrPageHeader-" + qnr.id + qualifierAppendedToId;
            detailsObj.currentTemplate = qnr;
            break;
        case TITLE.SOURCE.QNR_METADATA_PAGE_FOOTER:
            detailsObj.textObj = getPageFooterObj(qnr, currPageNum, numberOfPages);
            detailsObj.objKey = "QnrPageFooter-" + qnr.id + qualifierAppendedToId;
            detailsObj.currentTemplate = qnr;
            break;
        case TITLE.SOURCE.QNR_METADATA_PAGE_FOOTER2:
            detailsObj.textObj = getPageFooterObj(qnr, currPageNum, numberOfPages);
            detailsObj.objKey = "QnrPageFooter-" + qnr.id + qualifierAppendedToId;

            detailsObj.textObj2 = getPageFooterObj2(qnr, currPageNum, numberOfPages);
            detailsObj.objKey2 = "QnrPageFooter2-" + qnr.id + qualifierAppendedToId;
            detailsObj.currentTemplate = qnr;
            break;
        case TITLE.SOURCE.QNR_METADATA_HEADER:
            detailsObj.textObj = getPageHeaderObj(qnr, currPageNum, numberOfPages);
            detailsObj.objKey = "QnrHeader-" + qnr.id + qualifierAppendedToId;
            detailsObj.currentTemplate = qnr;
            break;
        case TITLE.SOURCE.QNR_METADATA_FOOTER:
            detailsObj.textObj = getPageFooterObj(qnr, currPageNum, numberOfPages);
            detailsObj.objKey = "QnrDeFooter-" + qnr.id + qualifierAppendedToId;
            detailsObj.currentTemplate = qnr;
            break;
        case TITLE.SOURCE.QNR_METADATA_FOOTER2:
            detailsObj.textObj = getPageFooterObj(qnr, currPageNum, numberOfPages);
            detailsObj.objKey = "QnrDeFooter-" + qnr.id + qualifierAppendedToId;

            detailsObj.textObj2 = getPageFooterObj2(qnr, currPageNum, numberOfPages);
            detailsObj.objKey2 = "QnrDeFooter2-" + qnr.id + qualifierAppendedToId;
            detailsObj.currentTemplate = qnr;
            break;
        case TITLE.SOURCE.CAT_NAME:
            detailsObj.categoryId = itemObj[OBJ.KEY.PAGE.SECTION.ITEM.TITLE.ID]
            detailsObj.category = getCategory(qnr, detailsObj.categoryId);
            detailsObj.text = detailsObj.category.name;
            detailsObj.objKey = "CatName-" + detailsObj.categoryId + qualifierAppendedToId;
            detailsObj.currentTemplate = detailsObj.category;
            break;
        case TITLE.SOURCE.CAT_DESC:
            detailsObj.categoryId = itemObj[OBJ.KEY.PAGE.SECTION.ITEM.TITLE.ID]
            detailsObj.category = getCategory(qnr, detailsObj.categoryId);
            detailsObj.text = detailsObj.category.description;
            detailsObj.isDescription = true;
            detailsObj.objKey = "CatDesc-" + detailsObj.categoryId + qualifierAppendedToId;
            detailsObj.currentTemplate = detailsObj.category;
            break;
        case TITLE.SOURCE.CAT_SCRIPT:
            detailsObj.categoryId = itemObj[OBJ.KEY.PAGE.SECTION.ITEM.TITLE.ID]
            detailsObj.category = getCategory(qnr, detailsObj.categoryId);
            detailsObj.text = tag.metadataValue(detailsObj.category, tag.CATEGORY_SCRIPT, "","", true);
            detailsObj.objKey = "CatScript-" + detailsObj.categoryId + qualifierAppendedToId;
            detailsObj.currentTemplate = detailsObj.category;
            detailsObj.isScript = true;
            break;
        case TITLE.SOURCE.CAT_METADATA_TITLE_BAR:
            detailsObj.categoryId = itemObj[OBJ.KEY.PAGE.SECTION.ITEM.TITLE.ID]
            detailsObj.category = getCategory(qnr, detailsObj.categoryId);
            detailsObj.textObj = getCategoryTitleBarObj(qnr, detailsObj.category, currPageNum, numberOfPages);
            detailsObj.objKey = "CatTitleBar-" + detailsObj.categoryId + qualifierAppendedToId;
            detailsObj.currentTemplate = detailsObj.category;
            break;
        case TITLE.SOURCE.GRP_NAME:
            detailsObj.groupId = itemObj[OBJ.KEY.PAGE.SECTION.ITEM.TITLE.ID]
            detailsObj.group = getGroup(qnr, detailsObj.groupId);
            detailsObj.qNum = itemObj[OBJ.KEY.PAGE.SECTION.ITEM.TITLE.SHOW_NUMBER];
            detailsObj.textBase = detailsObj.qNum === "" ? "" : detailsObj.group.name;
            detailsObj.text = detailsObj.qNum === "" ? detailsObj.group.name : (detailsObj.qNum + " " + detailsObj.group.name);
            detailsObj.objKey = "GrpName-" + detailsObj.groupId + qualifierAppendedToId;
            detailsObj.currentTemplate = detailsObj.group;
            break;
        case TITLE.SOURCE.GRP_DESC:
            detailsObj.groupId = itemObj[OBJ.KEY.PAGE.SECTION.ITEM.TITLE.ID]
            detailsObj.group = getGroup(qnr, detailsObj.groupId);
            detailsObj.text = detailsObj.group.description;
            detailsObj.isDescription = true;
            detailsObj.objKey = "GrpDesc-" + detailsObj.groupId + qualifierAppendedToId;
            detailsObj.currentTemplate = detailsObj.group;
            break;
        case TITLE.SOURCE.QUE_NAME:
            detailsObj.questionId = itemObj[OBJ.KEY.PAGE.SECTION.ITEM.TITLE.ID]
            detailsObj.question = getQuestion(qnr, detailsObj.questionId);
            detailsObj.qNum = itemObj[OBJ.KEY.PAGE.SECTION.ITEM.TITLE.SHOW_NUMBER];
            detailsObj.textBase = detailsObj.qNum === "" ? "" : detailsObj.question.name;
            detailsObj.text = detailsObj.qNum === "" ? detailsObj.question.name : (detailsObj.qNum + " " + detailsObj.question.name);
            detailsObj.objKey = "QueName-" + detailsObj.questionId + qualifierAppendedToId;
            detailsObj.currentTemplate = detailsObj.question;
            break;
        case TITLE.SOURCE.QUE_DESC:
            detailsObj.questionId = itemObj[OBJ.KEY.PAGE.SECTION.ITEM.TITLE.ID]
            detailsObj.question = getQuestion(qnr, detailsObj.questionId);
            detailsObj.text = detailsObj.question.description;
            detailsObj.isDescription = true;
            detailsObj.objKey = "QueDesc-" + detailsObj.questionId + qualifierAppendedToId;
            detailsObj.currentTemplate = detailsObj.question;
            break;
        case TITLE.SOURCE.DEFAULT:
            detailsObj.text = convertTitleNameDefault(qnr, itemObj[OBJ.KEY.PAGE.SECTION.ITEM.TITLE.NAME]);
            detailsObj.objKey = "SourceDefaultText" + qualifierAppendedToId;
            detailsObj.currentTemplate = detailsObj.question;
            break;
        default:
            detailsObj.text = "";
            break;
    }

    if (itemObj.format !== TITLE.FORMAT.METADATA) {
        if (typeof detailsObj.text === "string") {
            if (detailsObj.text.indexOf("|")) {
                let tempArray = detailsObj.text.split("|");
                detailsObj.textArray = tempArray;
                detailsObj.text = tempArray[0];
            }
        }
    }

    detailsObj.isLeftAligned = !(tag.metadataExists(qnr, tag.ALIGN_QUESTION_ANSWER_RIGHT) || tag.metadataExists((detailsObj.group !== null) ? detailsObj.group : detailsObj.question, tag.ALIGN_QUESTION_ANSWER_RIGHT));
    detailsObj.alignQuestionsWithAnswers = tag.metadataExists((detailsObj.group !== null) ? detailsObj.group : detailsObj.question, tag.ALIGN_ANSWERS_WITH_QUESTION);

    return detailsObj;
}


// Questionniares can have repeating headers on each page
export function getPageHeaderObj(iObj, currPageNum, numberOfPages) {

    let titleLeft = getTitleBarText(iObj, iObj, tag.PAGE_HEADER_LEFT, currPageNum, numberOfPages);
    let titleCenter = getTitleBarText(iObj, iObj, tag.PAGE_HEADER_CENTER, currPageNum, numberOfPages);
    let titleRight = getTitleBarText(iObj, iObj, tag.PAGE_HEADER_RIGHT, currPageNum, numberOfPages);

    titleLeft.isImage   = tag.metadataExists(iObj, tag.HEADER_IMAGE_LEFT);
    titleCenter.isImage = tag.metadataExists(iObj, tag.HEADER_IMAGE_CENTER);
    titleRight.isImage  = tag.metadataExists(iObj, tag.HEADER_IMAGE_RIGHT);

    if(titleLeft.isImage) {
        titleLeft.text = tag.metadataValue(iObj, HEADER_IMAGE_LEFT)
    }

    if(titleCenter.isImage) {
        titleCenter.text = tag.metadataValue(iObj, HEADER_IMAGE_CENTER)
    }

    if(titleRight.isImage) {
        titleRight.text = tag.metadataValue(iObj, HEADER_IMAGE_RIGHT)
    }

    let titleObj = {
        titleLeft: titleLeft.text,
        titleLeftVariant: titleLeft.variant,
        titleLeftIsImage: titleLeft.isImage,
        titleCenter: titleCenter.text,
        titleCenterVariant: titleCenter.variant,
        titleCenterIsImage: titleCenter.isImage,
        titleRight: titleRight.text,
        titleRightVariant: titleRight.variant,
        titleRightIsImage: titleRight.isImage
    };
    return titleObj;
}


// Questionniares can have repeating footers on each page
export function getPageFooterObj(iObj, currPageNum, numberOfPages) {

    let titleLeft   = getTitleBarText(iObj, iObj, tag.PAGE_FOOTER_LEFT, currPageNum, numberOfPages);
    let titleCenter = getTitleBarText(iObj, iObj, tag.PAGE_FOOTER_CENTER, currPageNum, numberOfPages);
    let titleRight  = getTitleBarText(iObj, iObj, tag.PAGE_FOOTER_RIGHT, currPageNum, numberOfPages);

    titleLeft.isImage   = tag.metadataExists(iObj, tag.FOOTER_IMAGE_LEFT);
    titleCenter.isImage = tag.metadataExists(iObj, tag.FOOTER_IMAGE_CENTER);
    titleRight.isImage  = tag.metadataExists(iObj, tag.FOOTER_IMAGE_RIGHT);

    if(titleLeft.isImage) {
        titleLeft.text = tag.metadataValue(iObj, FOOTER_IMAGE_LEFT)
    }

    if(titleCenter.isImage) {
        titleCenter.text = tag.metadataValue(iObj, FOOTER_IMAGE_CENTER)
    }

    if(titleRight.isImage) {
        titleRight.text = tag.metadataValue(iObj, FOOTER_IMAGE_RIGHT)
    }

    let titleObj = {
        titleLeft: titleLeft.text,
        titleLeftVariant: titleLeft.variant,
        titleLeftIsImage: titleLeft.isImage,
        titleCenter: titleCenter.text,
        titleCenterVariant: titleCenter.variant,
        titleCenterIsImage: titleCenter.isImage,
        titleRight: titleRight.text,
        titleRightVariant: titleRight.variant,
        titleRightIsImage: titleRight.isImage
    };
    return titleObj;
}


// Questionniares can have repeating footers on each page
export function getPageFooterObj2(iObj, currPageNum, numberOfPages) {

    let titleLeft   = getTitleBarText(iObj, iObj, tag.PAGE_FOOTER_LEFT2, currPageNum, numberOfPages);
    let titleCenter = getTitleBarText(iObj, iObj, tag.PAGE_FOOTER_CENTER2, currPageNum, numberOfPages);
    let titleRight  = getTitleBarText(iObj, iObj, tag.PAGE_FOOTER_RIGHT2, currPageNum, numberOfPages);

    titleLeft.isImage   = tag.metadataExists(iObj, tag.FOOTER_IMAGE_LEFT2);
    titleCenter.isImage = tag.metadataExists(iObj, tag.FOOTER_IMAGE_CENTER2);
    titleRight.isImage  = tag.metadataExists(iObj, tag.FOOTER_IMAGE_RIGHT2);

    if(titleLeft.isImage) {
        titleLeft.text = tag.metadataValue(iObj, tag.FOOTER_IMAGE_LEFT2)
    }

    if(titleCenter.isImage) {
        titleCenter.text = tag.metadataValue(iObj, tag.FOOTER_IMAGE_CENTER2)
    }

    if(titleRight.isImage) {
        titleRight.text = tag.metadataValue(iObj, tag.FOOTER_IMAGE_RIGHT2)
    }

    let titleObj = {
        titleLeft: titleLeft.text,
        titleLeftVariant: titleLeft.variant,
        titleLeftIsImage: titleLeft.isImage,
        titleCenter: titleCenter.text,
        titleCenterVariant: titleCenter.variant,
        titleCenterIsImage: titleCenter.isImage,
        titleRight: titleRight.text,
        titleRightVariant: titleRight.variant,
        titleRightIsImage: titleRight.isImage
    };
    return titleObj;
}


export function getCategoryTitleBarObj(qnr, catObj, currPageNum, numberOfPages) {

    // First check for standard metadaata tags
    let useTitleBar = tag.metadataValue(catObj, tag.CATEGORY_TITLE_BAR);
    let titleLeft = getTitleBarText(qnr, catObj, tag.CATEGORY_TITLE_BAR_LEFT, currPageNum, numberOfPages);
    let titleCenter = getTitleBarText(qnr, catObj, tag.CATEGORY_TITLE_BAR_CENTER, currPageNum, numberOfPages);
    let titleRight = getTitleBarText(qnr, catObj, tag.CATEGORY_TITLE_BAR_RIGHT, currPageNum, numberOfPages);

    useTitleBar = (titleLeft || titleCenter || titleRight);

    let titleObj = {
        useTitleBar: useTitleBar,
        categoryCustomObj: null,
        useCustomLeft: false,
        useCustomCenter: false,
        useCustomRight: false,
        titleLeft: titleLeft.text,
        titleLeftVariant: titleLeft.variant,
        titleLeftIsImage: false,
        titleLeftCustomObj: null,
        titleCenter: titleCenter.text,
        titleCenterVariant: titleCenter.variant,
        titleCenterIsImage: false,
        titleCenterCustomObj: null,
        titleRight: titleRight.text,
        titleRightVariant: titleRight.variant,
        titleRightIsImage: false,
        titleRightCustomObj: null,
    };

    // NOTE: if both metatdata and custom metadata defined titles the custom text is used.
    if (tag.metadataExists(catObj, tag.CUSTOM_DDO_CAT_FORMAT)) {
        titleObj.categoryCustomObj = JSON.parse(tag.metadataValue(catObj, tag.CUSTOM_DDO_CAT_FORMAT));
        if (titleObj.categoryCustomObj.titlebar) {

            titleObj.useTitleBar = true;

            if (tag.metadataExists(catObj, tag.CUSTOM_DDO_CATEGORY_TITLE_BAR_LEFT)) {
                titleObj.titleLeftCustomObj = JSON.parse(tag.metadataValue(catObj, tag.CUSTOM_DDO_CATEGORY_TITLE_BAR_LEFT));
                //titleObj.titleLeft = titleObj.titleLeft; // titleObj.titleLeftCustomObj.title !== "" ? titleObj.titleLeftCustomObj.title : titleObj.titleLeft;
                titleObj.useCustomLeft = (titleObj.titleLeftCustomObj.title !== ""); // If no title then don't use the other custom properties
            }
            if (tag.metadataExists(catObj, tag.CUSTOM_DDO_CATEGORY_TITLE_BAR_CENTER)) {
                titleObj.titleCenterCustomObj = JSON.parse(tag.metadataValue(catObj, tag.CUSTOM_DDO_CATEGORY_TITLE_BAR_CENTER));
                //titleObj.titleCenter = titleObj.titleCenter; // titleObj.titleCenterCustomObj.title !== "" ? titleObj.titleCenterCustomObj.title : titleObj.titleCenter;
                titleObj.useCustomCenter =  (titleObj.titleCenterCustomObj.title !== "");  // If no title then don't use the other custom properties
            }
            if (tag.metadataExists(catObj, tag.CUSTOM_DDO_CATEGORY_TITLE_BAR_RIGHT)) {
                titleObj.titleRightCustomObj = JSON.parse(tag.metadataValue(catObj, tag.CUSTOM_DDO_CATEGORY_TITLE_BAR_RIGHT));
                //titleObj.titleRight = titleObj.titleRight; // titleObj.titleRightCustomObj.title !== "" ? titleObj.titleRightCustomObj.title : titleObj.titleRight;
                titleObj.useCustomRight = (titleObj.titleRightCustomObj.title !== ""); // If no title then don't use the other custom properties
            }
        }
    }

    return titleObj;
}





export function convertTitleNameDefault(qnr = {}, titleNameDefault) {

    let name = "";

    switch(titleNameDefault) {
        case TITLE.NAME.DEFAULT1:
            name = "Begin Questionnaire?";
            break;
        case TITLE.NAME.DEFAULT2:
            name = "Start Questionnaire?";
            break;
        case TITLE.NAME.DEFAULT3:
            name = "Begin Survey?";
            break;
        case TITLE.NAME.DEFAULT4:
            name = "Thank you for providing your feedback to us, we greatly appreciate it. Your answers have been recorded, please close your browser window.";
            break;
        case TITLE.NAME.REPLACE_DEFAULT4:
            name = tag.metadataValue(qnr, tag.CLOSE_PAGE_REPLACE_DEFAULT_TEXT, "", "")
            break;
        default:
            name = "Begin Questionnaire?";
            break;
    }

    return name;
}


export function scriptBeforeExists(questionnaire) {

    let qnr = questionnaire;

    let scriptBefore = tag.metadataValue(qnr, "user-script");

    return (scriptBefore !== "")
}


export function scriptAfterExists(questionnaire) {

    let qnr = questionnaire;

    let scriptBefore = tag.metadataValue(qnr, "user-script"); // qi.metadata.metadataItems["user-script-bottom"] !== undefined ? qi.metadata.metadataItems["user-script-bottom"] : "";

    return (scriptBefore !== "")
}


export function convertButtonNameDefault(buttonName, buttonMode) {

    let name = buttonName;

    if (buttonName === BUTTON.NAME.DEFAULT) {

        // Use application default for the button name

        switch(buttonMode) {
            case BUTTON.MODE.START:
                name = "YES";
                break;
            case BUTTON.MODE.CANCEL:
                name = "CANCEL";
                break;
            case BUTTON.MODE.FINISH:
                name = "SUBMIT";
                break;
            case BUTTON.MODE.NEXT:
                name = "NEXT";
                break;
            case BUTTON.MODE.PREVIOUS:
                name = "PREVIOUS";
                break;
            case BUTTON.MODE.CLOSE:
                name = "CLOSE";
                break;
            case BUTTON.MODE.VIEW_PDF:
                name = "VIEW PDF";
                break;
            default:
                name = "";
                break;
        }
    }


    return name;
}


export function convertButtonColor(buttonColor) {

    let color = "";

    switch(buttonColor) {
        case BUTTON.COLOR.NONE:
            color = "";
            break;
        case BUTTON.COLOR.DEFAULT:
            color = "default";
            break;
        case BUTTON.COLOR.INHERIT:
            color = "inherit";
            break;
        case BUTTON.COLOR.PRIMARY:
            color = "primary";
            break;
        case BUTTON.COLOR.SECONDARY:
            color = "secondary";
            break;
        default:
            color = "default";
            break;
    }

    return color;
}


export function getTitleBarText(qnr, iObj, mdPropText, currPageNum, numberOfPages) {

    let tempObj = {text: "", isImage: false, variant: ""};
    let pageNum = "";
    let pageOf = "";

    if (tag.metadataExists(iObj, mdPropText)) {

        tempObj.text = tag.metadataValue(iObj, mdPropText);

        if (typeof tempObj.text === "object") {
            tempObj.text = tempObj.text.title;
            tempObj.variant = tempObj.text;
            return tempObj; // !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!! quick fix for headers and footers
        }

        if (tempObj.text !== undefined) {
            if (tempObj.text.includes("|")) {
                let parsedArray = tempObj.text.split("|");
                tempObj.text = parsedArray[0];
                tempObj.variant = parsedArray[1];
            }
        }

        if ((tempObj.text !== undefined) && (tempObj.text.toUpperCase() === tag.METADATA_VALUE_PREFIX_PAGENUM.toUpperCase())) {

            if (tag.metadataExists(iObj, tag.NEW_PAGE) || currPageNum === 1) {
                pageNum = (tag.metadataValue(iObj, tag.NEW_PAGE) !== "") ? tag.metadataValue(iObj, tag.NEW_PAGE) : currPageNum;
            }

            if (tag.metadataExists(qnr, tag.PAGE_OF)) {
                pageOf = (tag.metadataValue(qnr, tag.PAGE_OF) !== "") ? tag.metadataValue(qnr, tag.PAGE_OF) : numberOfPages;
            }

            if (pageNum !== "") {
                if (pageOf !== "") {
                    tempObj.text = "Page " + pageNum + " of " + pageOf;
                }
                else {
                    tempObj.text = "Page " + pageNum;
                }
            }
        }
        else if (mdPropText === tag.FOOTER_IMAGE_LEFT) {
            tempObj.isImage = true;
            tempObj.text = tag.metadataValue(iObj, tag.FOOTER_IMAGE_LEFT)
        }
        else if (mdPropText === tag.PAGE_FOOTER_RIGHT) {
            tempObj.isImage = false;
            tempObj.text = tag.metadataValue(iObj, tag.PAGE_FOOTER_RIGHT)
        }
        else if (mdPropText === tag.FOOTER_IMAGE_LEFT2) {
            tempObj.isImage = true;
            tempObj.text = tag.metadataValue(iObj, tag.FOOTER_IMAGE_LEFT2)
        }
        else if (mdPropText === tag.PAGE_FOOTER_RIGHT2) {
            tempObj.isImage = false;
            tempObj.text = tag.metadataValue(iObj, tag.PAGE_FOOTER_RIGHT2)
        }
        // else if (tempObj.text.substr(0, tag.METADATA_VALUE_PREFIX_SRC.length).toUpperCase() === tag.METADATA_VALUE_PREFIX_SRC.toUpperCase()) {
        //     tempObj.isImage = true;
        //     tempObj.text = tempObj.text.substr(tag.METADATA_VALUE_PREFIX_SRC.length)
        // }
    }

    return tempObj;
}


export function getCategory(questionnaire, categoryId) {

    let qnr = questionnaire;
    let isInstance = (qnr.questionCategories !== undefined);
    let categoryObj = {};

    let categories = isInstance ? qnr.questionCategories : qnr.questionCategoryTemplates;
    for (let i = 0; i < categories.length; i++) {
        if (categories[i].id === categoryId) {
            categoryObj = categories[i];
        }
    }

    return categoryObj;
}


export function getGroup(questionnaire, groupId) {

    let qnr = questionnaire;
    let isInstance = (qnr.questionGroups !== undefined);
    let groupObj = {};

    let categories = null;
    let groups = null;

    if (isInstance) {
        groups = qnr.questionGroups;

        for (let i = 0; i < groups.length; i++) {
            if (groups[i].id === groupId) {
                groupObj = groups[i];
                break;
            }
        }
    }
    else {
        categories = qnr.questionCategoryTemplates;

        for (let c = 0; c < categories.length; c++) {

            groups = categories[c].groupTemplates;

            for (let i = 0; i < groups.length; i++) {
                if (groups[i].id === groupId) {
                    groupObj = groups[i];
                    break;
                }
            }
        }
    }

    return groupObj;
}


export function getGroupByKey(questionnaireInstance, groupKey) {

    let qi = questionnaireInstance;
    let groupObj = {};

    for (let i = 0; i < qi.questionGroups.length; i++) {
        if (qi.questionGroups[i].templateKey === groupKey) {
            groupObj = qi.questionGroups[i];
        }
    }

    return groupObj;
}


export function getQuestion(questionnaire, questionId) {

    let qnr = questionnaire;
    let isInstance = (qnr.questions !== undefined);

    let questionObj = {};

    let categories = null;
    let groups = null;
    let questions = null;

    if (isInstance) {
        questions = qnr.questions;

        for (let i = 0; i < questions.length; i++) {
            if (questions[i].id === questionId) {
                questionObj = questions[i];
                break;
            }
        }
    }
    else {

        // let isSimple = (qnr.questionTemplates.length > 0);
        //
        // if (isSimple) {
        //     questions = qnr.questionTemplates;
        //
        //     for (let i = 0; i < questions.length; i++) {
        //         if (questions[i].id === questionId) {
        //             questionObj = questions[i];
        //             break;
        //         }
        //     }
        // }
        // else {

            categories = qnr.questionCategoryTemplates;

            for (let c = 0; c < categories.length; c++) {

                groups = categories[c].groupTemplates;

                for (let g = 0; g < groups.length; g++) {

                    questions = groups[g].questionTemplates !== undefined ? groups[g].questionTemplates : [];

                    for (let i = 0; i < questions.length; i++) {
                        if (questions[i].id === questionId) {
                            questionObj = questions[i];
                            break;
                        }
                    }
                }
            }
        //}

    }

    return questionObj;
}


export function getQuestionNumber(questionnaire, currQuestionNumber) {

    let qnr = questionnaire;

    let currNum = "";

    if (tag.metadataExists(qnr, tag.SHOW_QUESTION_NUMBERS)) {

        currNum = currQuestionNumber;
    }

    return currNum;
}


export function getLikertQuestionShowGroupKey(qnr, questionItemObj) {

    let likertShowGroupKey = "";

    let questionId = getObjValue(questionItemObj, OBJ.KEY.PAGE.SECTION.ITEM.QUESTION.ID);
    let question = getQuestion(qnr, questionId);

    // Since a LIKERT group can have multiple question to a single answer set tag.USE_SHOW_GROUP metadata tells portal to ignore any show groups for this question
    if (!tag.metadataExists(question, tag.DO_NOT_USE_SHOW_GROUP)) {

        likertShowGroupKey = questionItemObj.showGroupKey;
    }

    return likertShowGroupKey;
}


export function showGroup(questionnaire, showGroupKey) {

    let show = false;

    let isInstance = questionnaire.questions !== undefined;

    // BUTTONS have a undefined showGroupKey
    if ((showGroupKey === "")||(showGroupKey === undefined)) {
        show = true; // no showGroupKey then always visible
    }
    else {

        let qnr = questionnaire;
        //let isInstance = questionnaire.questions !== undefined;

        let categories = null;
        let groups = null;
        let questions = null;
        let question = null;

        if (isInstance) {

            questions = qnr.questions;

            for (let i = 0; i < questions.length; i++) {

                if (!tag.metadataExists(questions[i], tag.DO_NOT_USE_SHOW_GROUP)) {

                    for (let j = 0; j < questions[i].answerOptions.length; j++) {

                        for (let k = 0; k < questions[i].answerOptions[j].groupKeys.length; k++) {

                            if (questions[i].answerOptions[j].groupKeys[k] === showGroupKey) {

                                // If any answer that triggers the group then make visible
                                if (questions[i].answerOptions[j].selected) {
                                    show = true;
                                    break;
                                }
                            }
                        }
                    }

                }
            }
        }
        else {

            categories = qnr.questionCategoryTemplates;

            for (let c = 0; c < categories.length; c++) {

                groups = categories[c].groupTemplates !== undefined ? categories[c].groupTemplates : [];

                for (let g = 0; g < groups.length; g++) {

                    questions = groups[g].questionTemplates !== undefined ? groups[g].questionTemplates : [];

                    for (let q = 0; q < questions.length; q++) {

                        question = questions[q];

                        if (!tag.metadataExists(question, tag.DO_NOT_USE_SHOW_GROUP)) {

                            for (let j = 0; j < question.answerOptionTemplates.length; j++) {

                                for (let k = 0; k < question.answerOptionTemplates[j].importGroupKeys.length; k++) {

                                    if (question.answerOptionTemplates[j].importGroupKeys[k] === showGroupKey) {

                                        // If any answer that triggers the group then make visible
                                        if ((question.answerOptionTemplates[j].selected !== undefined) && question.answerOptionTemplates[j].selected) {
                                            show = true;
                                            break;
                                        }
                                    }
                                }
                            }

                        }

                    }
                }
            }

        }
    }

    return show;
}


export function getAllShowGroupKeyArray(questionnaire) {

    let allShowGroupKeyArray = [];

    let qnr = questionnaire;
    let isInstance = questionnaire.questions !== undefined;

    let categories = null;
    let groups = null;
    let questions = null;
    let answers = null;
    let groupKeys = null;

    if (isInstance) {
        questions = isInstance ? qnr.questions : qnr.questionTemplates;

        for (let q = 0; q < questions.length; q++) {

            answers = questions[q].answerOptions;

            for (let a = 0; a < answers.length; a++){

                let groupKeys = answers[a].groupKeys;

                for (let k = 0; k < groupKeys.length; k++) {

                    let showGroupTemplateKey = groupKeys[k];
                    if (!allShowGroupKeyArray.includes(showGroupTemplateKey)) {

                        allShowGroupKeyArray.push(showGroupTemplateKey);
                    }
                }
            }
        }
    }
    else {
        categories = qnr.questionCategoryTemplates;

        // Isssues with debugging so using var instead of let
        for (var c = 0; c < categories.length; c++) {

            groups = categories[c].groupTemplates !== undefined ? categories[c].groupTemplates : [];

            for (var g = 0; g < groups.length; g++) {

                questions = groups[g].questionTemplates !== undefined ? groups[g].questionTemplates : [];

                for (var q = 0; q < questions.length; q++) {

                    answers = questions[q].answerOptionTemplates;

                    if (answers !== undefined) {

                        for (var a = 0; a < answers.length; a++){

                            groupKeys = answers[a].importGroupKeys;

                            if (groupKeys !== undefined) {

                                for (var k = 0; k < groupKeys.length; k++) {

                                    let showGroupTemplateKey = groupKeys[k];
                                    if (!allShowGroupKeyArray.includes(showGroupTemplateKey)) {

                                        allShowGroupKeyArray.push(showGroupTemplateKey);
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    return allShowGroupKeyArray;
}


export function addSurveyMetadataSection(pageObj, metadata) {

    let sectionObj = createPageSection(SECTION.JUSTIFY.CENTER, SECTION.ALIGN_ITEMS.STRETCH, SECTION.STYLE_TYPE.ONE, SECTION.CLASS_TYPE.CONTROL);

    for (var key in metadata) {
        if (metadata.hasOwnProperty(key)) {
            addTitleToSection(sectionObj, TITLE.FORMAT.METADATA, TITLE.SOURCE.NONE, TITLE.ID.NONE, key + "|" + metadata[key], font.VARIANT.H5, "", [], "");
        }
    }

    addTitleToSection(sectionObj, TITLE.FORMAT.SPACER, TITLE.SOURCE.NONE, TITLE.ID.NONE, "", font.VARIANT.H5, "", "","");
    addSectionToPage(pageObj, sectionObj);
}


export function addSurveyFilteredMetadataSection(pageObj, metadata, readonly) {

    let addKey = false;

    let sectionObj = createPageSection(SECTION.JUSTIFY.CENTER, SECTION.ALIGN_ITEMS.STRETCH, SECTION.STYLE_TYPE.ONE, SECTION.CLASS_TYPE.CONTROL);

    for (var key in metadata) {
        if (metadata.hasOwnProperty(key)) {
            addKey = false;
            switch(key) {
                case "Date Surveyed":
                    addKey = ((metadata[key] !== "")||(metadata[key] !== null))
                    break;
                case "TypeOfProduct":
                    addKey = ((metadata[key] !== "")||(metadata[key] !== null))
                    break;
                case "OperatorId":
                    addKey = ((metadata[key] !== "")||(metadata[key] !== null))
                    break;
                case "Branch":
                    addKey = ((metadata[key] !== "")||(metadata[key] !== null))
                    break;
                case "NameOfProduct":
                    addKey = ((metadata[key] !== "")||(metadata[key] !== null))
                    break;
                case "DateOfEvent":
                    addKey = ((metadata[key] !== "")||(metadata[key] !== null))
                    break;
                default:
                    console.log("displayDefObjConstants.js  Switch default E01"); // eslint-disable-line
            }

            if (addKey) {
                addTitleToSection(sectionObj, TITLE.FORMAT.METADATA, TITLE.SOURCE.NONE, TITLE.ID.NONE, key + "|" + metadata[key], font.VARIANT.H5, "", "", "");
            }
        }
    }

    addTitleToSection(sectionObj, TITLE.FORMAT.SPACER, TITLE.SOURCE.NONE, TITLE.ID.NONE, "", font.VARIANT.H5, "", "","");
    addSectionToPage(pageObj, sectionObj);
}


export function checkQuestionnaireForPageHeadersAddSection(pageObj, qnr) {

    let titleAdded = false;

    let sectionObj = createPageSection(SECTION.JUSTIFY.CENTER, SECTION.ALIGN_ITEMS.STRETCH, SECTION.STYLE_TYPE.ONE, SECTION.CLASS_TYPE.CONTROL);

    if (tag.metadataExists(qnr, tag.PAGE_HEADER)) {
        titleAdded = true;

        addTitleToSection(sectionObj, TITLE.FORMAT.PAGE_HEADER, TITLE.SOURCE.QNR_METADATA_PAGE_HEADER, qnr.id, TITLE.NAME.USE_SOURCE, font.VARIANT.SUBTITLE1, "", "", "");
        //addTitleToSection(sectionObj, TITLE.FORMAT.SPACER, TITLE.SOURCE.NONE, TITLE.ID.NONE, TITLE.NAME.USE_SOURCE, font.VARIANT.H2, showNumber, showGroupKey);
    }

    // Don't add a section of all the titles are hidden, except a question
    if (titleAdded) {
        addSectionToPage(pageObj, sectionObj);
    }
}


export function checkQuestionnaireForPageFootersAddSection(pageObj, qnr) {

    let titleAdded = false;

    let sectionObj = createPageSection(SECTION.JUSTIFY.CENTER, SECTION.ALIGN_ITEMS.STRETCH, SECTION.STYLE_TYPE.ONE, SECTION.CLASS_TYPE.CONTROL);

    if (tag.metadataExists(qnr, tag.PAGE_FOOTER) && tag.metadataExists(qnr, tag.PAGE_FOOTER2)) {
        titleAdded = true;

        addTitleToSection(sectionObj, TITLE.FORMAT.PAGE_FOOTER2, TITLE.SOURCE.QNR_METADATA_PAGE_FOOTER2, qnr.id, TITLE.NAME.USE_SOURCE, font.VARIANT.SUBTITLE1, "", "", []);
        //addTitleToSection(sectionObj, TITLE.FORMAT.SPACER, TITLE.SOURCE.NONE, TITLE.ID.NONE, TITLE.NAME.USE_SOURCE, font.VARIANT.H2, showNumber, showGroupKey);
    }
    else if (tag.metadataExists(qnr, tag.PAGE_FOOTER))
    {
        titleAdded = true;

        addTitleToSection(sectionObj, TITLE.FORMAT.PAGE_FOOTER, TITLE.SOURCE.QNR_METADATA_PAGE_FOOTER, qnr.id, TITLE.NAME.USE_SOURCE, font.VARIANT.SUBTITLE1, "", "", []);
        //addTitleToSection(sectionObj, TITLE.FORMAT.SPACER, TITLE.SOURCE.NONE, TITLE.ID.NONE, TITLE.NAME.USE_SOURCE, font.VARIANT.H2, showNumber, showGroupKey);
    }

    // Don't add a section of all the titles are hidden, except a question
    if (titleAdded) {
        addSectionToPage(pageObj, sectionObj);
    }
}


export function checkObjForTitlesAndAddSection(pageObj, iObjType, iObj, qNumObj, showGroupKey, showGroupObjArray = [], page = "") {

    let titleAdded = false;

    let sectionObj = createPageSection(SECTION.JUSTIFY.CENTER, SECTION.ALIGN_ITEMS.STRETCH, SECTION.STYLE_TYPE.ONE, SECTION.CLASS_TYPE.CONTROL);

    // If object, Questionnaire, category, Group or Questing is declared as a TITLE_BAR then create, and text will be from metadata tags
    if (tag.metadataExists(iObj, tag.CATEGORY_TITLE_BAR)) {
        titleAdded = true;
        addTitleToSection(sectionObj, TITLE.FORMAT.CATEGORY_TITLE_BAR, TITLE.SOURCE.CAT_METADATA_TITLE_BAR, iObj.id, TITLE.NAME.USE_SOURCE, font.VARIANT.H2, "", showGroupKey, showGroupObjArray);
        addTitleToSection(sectionObj, TITLE.FORMAT.SPACER, TITLE.SOURCE.NONE, TITLE.ID.NONE, TITLE.NAME.USE_SOURCE, font.VARIANT.H2, "", showGroupKey, showGroupObjArray);
    }
        // else if (tag.metadataExists(iObj, tag.FOOTER_TITLE_BAR)) {
        //     titleAdded = true;
        //     addTitleToSection(sectionObj, TITLE.FORMAT.FOOTER, convertObjTypeToSourceENUM(iObjType, false), iObj.id, TITLE.NAME.USE_SOURCE, font.VARIANT.H2, "", showGroupKey, showGroupObjArray);
        //     addTitleToSection(sectionObj, TITLE.FORMAT.SPACER, TITLE.SOURCE.NONE, TITLE.ID.NONE, TITLE.NAME.USE_SOURCE, font.VARIANT.H2, "", showGroupKey, showGroupObjArray);
    // }
    else {

        if (page !== "CLOSE") {
            let qNumFlag = ((iObjType === OBJ_TYPE.QUESTION)||(iObjType === OBJ_TYPE.GROUP)); // Only group and question names can have a question number

            if (iObjType === OBJ_TYPE.QUESTION) {
                // If question is a LIKERT then the question text is rendered as part of the question object
                if (!tag.metadataExists(iObj, tag.DISPLAY_SINGLE_SELECT_AS_LIKERT)) {
                    titleAdded = addTitleIfRequired(tag.DISPLAY_DEF_NAME, sectionObj, iObjType, iObj, OBJ_TEXT_TYPE.NAME, qNumFlag ? qNumObj : "", showGroupKey, showGroupObjArray, titleAdded);
                }
            }
            else {
                // GROUP TITLE ADDED HERE !!!!!!!!!!
                titleAdded = addTitleIfRequired(tag.DISPLAY_DEF_NAME, sectionObj, iObjType, iObj, OBJ_TEXT_TYPE.NAME, qNumFlag ? qNumObj : "", showGroupKey, showGroupObjArray, titleAdded);
            }

            // Category description displays after a category user-script
            if ((iObj.description !== "")&&(iObjType !== OBJ_TYPE.CATEGORY)) {
                // QUESTION or GROUP DESCRIPTION ADDED HERE !!!!!!!!!!!
                titleAdded = addTitleIfRequired(tag.DISPLAY_DEF_DESCRIPTION, sectionObj, iObjType, iObj, OBJ_TEXT_TYPE.DESCRIPTION, "", showGroupKey, showGroupObjArray, titleAdded);
            }
        }

        if (iObjType === OBJ_TYPE.QUESTIONNAIRE) {
            if (page === "START") {
                // Script before is only displayed on a START page, also script before text is stored as metadata, there can also be metadata to define it's format.
                //titleAdded = addTitleIfTagExists(tag.DISPLAY_DEF_SCRIPT_BEFORE, sectionObj, iObjType, iObj, OBJ_TEXT_TYPE.SCRIPT_BEFORE, "", showGroupKey, showGroupObjArray, titleAdded);
                titleAdded = addTitleIfTagExists(tag.SCRIPT_BEFORE, sectionObj, iObjType, iObj, OBJ_TEXT_TYPE.SCRIPT_BEFORE, "", showGroupKey, showGroupObjArray, titleAdded); // Original tag from admin
            }
            if (page === "CLOSE") {
                // Script after is only displayed on a CLOSE page, also script after text is stored as metadata, there can also be metadata to define it's format.
                //titleAdded = addTitleIfTagExists(tag.DISPLAY_DEF_SCRIPT_AFTER, sectionObj, iObjType, iObj, OBJ_TEXT_TYPE.SCRIPT_AFTER, "", showGroupKey, showGroupObjArray, titleAdded);
                titleAdded = addTitleIfTagExists(tag.SCRIPT_AFTER, sectionObj, iObjType, iObj, OBJ_TEXT_TYPE.SCRIPT_AFTER, "", showGroupKey, showGroupObjArray, titleAdded); // Original tag from admin

                // Script after is only displayed on a CLOSE page, also script after text is stored as metadata, there can also be metadata to define it's format.
                titleAdded = addTitleIfTagExists(tag.CLOSE_PAGE_TEXT1, sectionObj, iObjType, iObj, OBJ_TEXT_TYPE.CLOSE_TEXT1, "", showGroupKey, showGroupObjArray, titleAdded);
                titleAdded = addTitleIfTagExists(tag.CLOSE_PAGE_TEXT2, sectionObj, iObjType, iObj, OBJ_TEXT_TYPE.CLOSE_TEXT2, "", showGroupKey, showGroupObjArray, titleAdded);
                titleAdded = addTitleIfTagExists(tag.CLOSE_PAGE_TEXT3, sectionObj, iObjType, iObj, OBJ_TEXT_TYPE.CLOSE_TEXT3, "", showGroupKey, showGroupObjArray, titleAdded);
            }
        }
        else if (iObjType === OBJ_TYPE.CATEGORY) {

            if (tag.metadataExists(iObj, tag.CATEGORY_SCRIPT)) {
                titleAdded = addTitleIfRequired(tag.CATEGORY_SCRIPT, sectionObj, iObjType, iObj, OBJ_TEXT_TYPE.SCRIPT, "", showGroupKey, showGroupObjArray, titleAdded);
            }
            if (iObj.description !== "") {
                titleAdded = addTitleIfRequired(tag.DISPLAY_DEF_DESCRIPTION, sectionObj, iObjType, iObj, OBJ_TEXT_TYPE.DESCRIPTION, "", showGroupKey, showGroupObjArray, titleAdded);
            }
        }
        else if (iObjType === OBJ_TYPE.QUESTION) {
            titleAdded = true;
            addQuestionToSection(sectionObj, iObj, showGroupKey, showGroupObjArray, qNumObj);
        }
    }

    // Don't add a section of all the titles are hidden, except a question
    if (titleAdded) {
        addSectionToPage(pageObj, sectionObj);
    }
}


export function addTitleIfRequired(tagName, sectionObj, iObjType, iObj, iTextType, qNumObj, showGroupKey, showGroupObjArray = [], titleAdded) {

    // Array[0] = text: show | left | center | right | hide
    // Array[1] = icon: none | info | comment | default  --- comment and icon can only be used with text show or left
    // Array[2] = variant: default | 'h1' | 'h2' | 'h3'  | 'h4'  | 'h5'  | 'h6'  | 'subtitle1'  | 'subtitle2'  | 'body1'  | 'body2'  | 'caption'  | 'button'  | 'overline'

    let sourceText = getSourceText(iObjType, iTextType);
    let id = (iObjType === OBJ_TYPE.QUESTIONNAIRE ? TITLE.ID.NONE : iObj.id);
    let formatMode = "";
    let variant = "";
    let added = false;
    let hasDescription = false;

    // Look ahead if group or question template has a visible description then flag it so renderer knows to wait on likret labels
    if  ((iObjType === OBJ_TYPE.GROUP)||(iObjType === OBJ_TYPE.QUESTION)) {
        if (tagName === tag.DISPLAY_DEF_NAME) {
            if (iObj.description !== "") {
                // Check to se if description is hidden or custom
                if (tag.metadataExists(iObj, tag.DISPLAY_DEF_DESCRIPTION)) {
                    hasDescription = (tag.metadataValue(iObj, tag.DISPLAY_DEF_DESCRIPTION) !== "hide");
                }
                else {
                    hasDescription = true;
                }
            }
        }
    }

    // Exclude category scripts, they are metadata but are added thru admin UI and don't become COMMENT types
    if ((tagName !== tag.CATEGORY_SCRIPT) && tag.metadataExists(iObj, tagName)) {

        let mdValue = tag.metadataValue(iObj, tagName);

        if (typeof mdValue === "object") {
            formatMode = TITLE.FORMAT.CUSTOM;
            variant = mdValue;

            addTitleToSection(sectionObj, formatMode, sourceText, id, TITLE.NAME.USE_SOURCE, variant, questionNumber(qNumObj, showGroupKey), showGroupKey, showGroupObjArray, hasDescription);
            added = true;
        }
        else {
            let valueArray = mdValue.split("|");

            if ((valueArray.length > 0) && (valueArray[0] !== "hide")) {

                formatMode = (valueArray.length >= 1) ? convertFormatEntryToENUM(valueArray[0], valueArray[1]) : getTitleDefaultFormat(iObjType, iTextType);  // If specified use otherwise get default // Plain, Info or Comment or custom
                variant = (valueArray.length > 2) ? font.validateVariantEntry(valueArray[2]) : font.getTextDefaultVariant(iObjType, iTextType);  // If specified use otherwise get default

                addTitleToSection(sectionObj, formatMode, sourceText, id, TITLE.NAME.USE_SOURCE, variant, questionNumber(qNumObj, showGroupKey), showGroupKey, showGroupObjArray, hasDescription);
                added = true;
            }
        }
    }
    else if (tagName === tag.CATEGORY_SCRIPT) {

        let mdValue = tag.metadataValue(iObj, tagName);

        // Is there a custom mode for category-script
        if (typeof mdValue === "object") {
            formatMode = TITLE.FORMAT.CUSTOM;
            variant = mdValue;

            addTitleToSection(sectionObj, formatMode, sourceText, id, TITLE.NAME.USE_SOURCE, variant, questionNumber(qNumObj, showGroupKey), showGroupKey, showGroupObjArray, hasDescription);
            added = true;
        }
        else {

            formatMode = getTitleDefaultFormat(iObjType, iTextType);  // Plain, Info or Comment
            variant = font.getTextDefaultVariant(iObjType, iTextType);

            addTitleToSection(sectionObj, formatMode, sourceText, id, TITLE.NAME.USE_SOURCE, variant, questionNumber(qNumObj, showGroupKey), showGroupKey, showGroupObjArray, hasDescription);
            added = true;
        }
    }
    else
    {
        // If no display-def then show text based on defaults

        formatMode = getTitleDefaultFormat(iObjType, iTextType);  // Plain, Info or Comment
        variant = font.getTextDefaultVariant(iObjType, iTextType);

        addTitleToSection(sectionObj, formatMode, sourceText, id, TITLE.NAME.USE_SOURCE, variant, questionNumber(qNumObj, showGroupKey), showGroupKey, showGroupObjArray, hasDescription);
        added = true;
    }

    return (added || titleAdded);
}


export function addTitleIfTagExists(tagName, sectionObj, iObjType, iObj, iTextType, showNumber, showGroupKey, showGroupObjArray = [], titleAdded) {

    // Array[0] = text: show | left | center | right | hide
    // Array[1] = icon: none | info | comment | default  --- comment and icon can only be used with text show or left
    // Array[2] = variant: default | 'h1' | 'h2' | 'h3'  | 'h4'  | 'h5'  | 'h6'  | 'subtitle1'  | 'subtitle2'  | 'body1'  | 'body2'  | 'caption'  | 'button'  | 'overline'

    let sourceText = getSourceText(iObjType, iTextType);
    let id = (iObjType === OBJ_TYPE.QUESTIONNAIRE ? TITLE.ID.NONE : iObj.id);
    let formatMode = "";
    let variant = "";
    let added = false;

    if (tag.metadataExists(iObj, tagName)) {

        let mdValue = tag.metadataValue(iObj, tagName);

        let valueArray = "";

        if (typeof mdValue === "string") {

            valueArray = mdValue.split("|");

            if ((valueArray.length > 0) && (valueArray[0] !== "hide")) {

                formatMode = (valueArray.length >= 1) ? convertFormatEntryToENUM(valueArray[0], valueArray[1]) : getTitleDefaultFormat(iObjType, iTextType);  // If specified use otherwise get default // Plain, Info or Comment
                variant = (valueArray.length >= 2) ? font.validateVariantEntry(valueArray[1]) : font.getTextDefaultVariant(iObjType, iTextType);  // If specified use otherwise get default

                addTitleToSection(sectionObj, formatMode, sourceText, id, TITLE.NAME.USE_SOURCE, variant, showNumber, showGroupKey);
                added = true;
            }
        }
        else if (typeof mdValue === "object") {

            formatMode = TITLE.FORMAT.CUSTOM;
            variant = mdValue;

            addTitleToSection(sectionObj, formatMode, sourceText, id, TITLE.NAME.USE_SOURCE, variant, showNumber, showGroupKey, showGroupObjArray);
            added = true;
        }

    }

    return (added || titleAdded);
}


export function convertFormatEntryToENUM(textEntered, formatEntered) {

    let format = TITLE.FORMAT.PLAIN;


    switch(textEntered) {
        case "center":
            format = TITLE.FORMAT.PLAIN_CENTER;
            break;
        case "right":
            format = TITLE.FORMAT.PLAIN_RIGHT;
            break;
        // no default
    }

    if (textEntered === "show") {

        switch(formatEntered) {
            case "info":
                format = TITLE.FORMAT.INFO;
                break;
            case "comment":
                format = TITLE.FORMAT.COMMENT;
                break;
            // no default
        }
    }

    return format;
}


export function getSourceText(iObjType, iTextType) {

    let sourceText = "";

    switch(iObjType) {
        case OBJ_TYPE.QUESTIONNAIRE:
            switch(iTextType) {
                case OBJ_TEXT_TYPE.NAME:
                    sourceText = TITLE.SOURCE.QNR_NAME;
                    break;
                case OBJ_TEXT_TYPE.DESCRIPTION:
                    sourceText = TITLE.SOURCE.QNR_DESC;
                    break;
                case OBJ_TEXT_TYPE.SCRIPT_BEFORE:
                    sourceText = TITLE.SOURCE.QNR_SCRIPT_BEFORE;
                    break;
                case OBJ_TEXT_TYPE.SCRIPT_AFTER:
                    sourceText = TITLE.SOURCE.QNR_SCRIPT_AFTER;
                    break;
                case OBJ_TEXT_TYPE.CLOSE_TEXT1:
                    sourceText = TITLE.SOURCE.QNR_CLOSE_TEXT1;
                    break;
                case OBJ_TEXT_TYPE.CLOSE_TEXT2:
                    sourceText = TITLE.SOURCE.QNR_CLOSE_TEXT2;
                    break;
                case OBJ_TEXT_TYPE.CLOSE_TEXT3:
                    sourceText = TITLE.SOURCE.QNR_CLOSE_TEXT3;
                    break;
                // no default
            }
            break;
        case OBJ_TYPE.CATEGORY:
            switch(iTextType) {
                case OBJ_TEXT_TYPE.NAME:
                    sourceText = TITLE.SOURCE.CAT_NAME;
                    break;
                case OBJ_TEXT_TYPE.DESCRIPTION:
                    sourceText = TITLE.SOURCE.CAT_DESC;
                    break;
                case OBJ_TEXT_TYPE.SCRIPT:
                    sourceText = TITLE.SOURCE.CAT_SCRIPT;
                    break;
                // no default
            }
            break;
        case OBJ_TYPE.GROUP:
        case OBJ_TYPE.GROUP_LIKERT:
            switch(iTextType) {
                case OBJ_TEXT_TYPE.NAME:
                    sourceText = TITLE.SOURCE.GRP_NAME;
                    break;
                case OBJ_TEXT_TYPE.DESCRIPTION:
                    sourceText = TITLE.SOURCE.GRP_DESC;
                    break;
                // no default
            }
            break;
        case OBJ_TYPE.QUESTION:
            switch(iTextType) {
                case OBJ_TEXT_TYPE.NAME:
                    sourceText = TITLE.SOURCE.QUE_NAME;
                    break;
                case OBJ_TEXT_TYPE.DESCRIPTION:
                    sourceText = TITLE.SOURCE.QUE_DESC;
                    break;
                // no default
            }
            break;
        // no default
    }

    return sourceText;
}


export function getQuestionNameText(question, questionNumber = "") {

    let textObj = {text: "", fontSize: "", variant: "", custom: false, customObj: null, indent: "0px", icon: "", displaySize: "", CustomTopographyStyle: null};

    textObj.text = (questionNumber === "" || questionNumber === "0") ? question.name : (questionNumber + " " + question.name);
    textObj.variant = font.getTextDefaultVariant(OBJ_TYPE.QUESTION, OBJ_TEXT_TYPE.NAME);

    textObj.custom = tag.metadataExists(question, tag.CUSTOM_DDO_NAME);
    textObj.customObj = textObj.custom ? tag.metadataValue(question, tag.CUSTOM_DDO_NAME) : null;
    textObj.customObj = (textObj.customObj === "hide") ? textObj.customObj : JSON.parse(textObj.customObj);
    if (textObj.custom) {
        if (textObj.customObj.fontSizing === "points") {
            textObj.displaySize = textObj.customObj.fontSizeEntry + "pt";
        }
        else if (textObj.customObj.fontSizing === "variant") {
            textObj.displaySize = font.convertVariantToPointsStr(textObj.variant)
        }
        textObj.CustomTopographyStyle = style.getCustomTextStyle(textObj.customObj);
    }

    textObj.fontSize = font.convertVariantToPointsStr(textObj.variant);

    textObj.indent = tag.metadataExists(question, tag.INDENT_NAME) ? tag.metadataValue(question, tag.INDENT_NAME) : "0px";

    let metadataValue = tag.metadataExists(question, tag.DISPLAY_DEF_NAME) ? tag.metadataValue(question, tag.DISPLAY_DEF_NAME) : "";

    if (typeof metadataValue === "object") {
        textObj.variant = metadataValue.variant;
        textObj.icon = "";  // TODO
    }
    else if (metadataValue !== "") {

        let parsedMetadataArray = metadataValue.split("|");

        if (parsedMetadataArray[0] === "hide") {
            textObj.text = "";
            textObj.variant = "subtitle1";
        }

        if (parsedMetadataArray.length > 1) {

            textObj.icon = parsedMetadataArray[1];
        }

        if (parsedMetadataArray.length > 2) {

            textObj.variant = parsedMetadataArray[2];
        }
    }

    return textObj;
}


export const questionNumber = (qNumObj, showGroupKey) => {

    let qNum = "";
    if (qNumObj !== "") {
        if (qNumObj.useQuestionNumbers) {
            if (showGroupKey === "") {
                qNumObj.currQuestionNumber += 1;
                qNum = qNumObj.currQuestionNumber + qNumObj.questionNumberAppend;
            }
            else {
                //qNumObj.currQuestionNumber -= 1;  // If a show group then need to back up one question number
                if (qNumObj.subnumberShowgroupQuestions !== "") {
                    qNumObj.currSubQuestionNumber = 0;
                    qNum = questionSubNumber(qNumObj, qNumObj.subnumberShowgroupQuestions);
                }
                //qNumObj.currQuestionNumber += 1;
            }
        }
    }
    return qNum;
};


export const questionSubNumber = (qNumObj, subNumber) => {

    let qSubNum = "";
    if (qNumObj !== "") {
        if (qNumObj.subnumberLikertQuestions !== "") {
            qNumObj.currSubQuestionNumber += 1;
            switch (subNumber) {
                case "a":
                    qSubNum = convertNumberToLetter(qNumObj.currSubQuestionNumber, false);
                    break;
                case "a.":
                    qSubNum = convertNumberToLetter(qNumObj.currSubQuestionNumber, false) + ".";
                    break;
                case "a)":
                    qSubNum = convertNumberToLetter(qNumObj.currSubQuestionNumber, false) + ")";
                    break;
                case "(a)":
                    qSubNum = "(" + convertNumberToLetter(qNumObj.currSubQuestionNumber, false) + ")";
                    break;
                case "#a":
                    qSubNum = qNumObj.currQuestionNumber + convertNumberToLetter(qNumObj.currSubQuestionNumber, false);
                    break;
                case "#a.":
                    qSubNum = qNumObj.currQuestionNumber + convertNumberToLetter(qNumObj.currSubQuestionNumber, false) + ".";
                    break;
                case "#a)":
                    qSubNum = qNumObj.currQuestionNumber + convertNumberToLetter(qNumObj.currSubQuestionNumber, false) + ")";
                    break;
                case "#(a)":
                    qSubNum = qNumObj.currQuestionNumber + "(" + convertNumberToLetter(qNumObj.currSubQuestionNumber, false) + ")";
                    break;
                case "A":
                    qSubNum = convertNumberToLetter(qNumObj.currSubQuestionNumber, true);
                    break;
                case "A.":
                    qSubNum = convertNumberToLetter(qNumObj.currSubQuestionNumber, true) + ".";
                    break;
                case "A)":
                    qSubNum = convertNumberToLetter(qNumObj.currSubQuestionNumber, true) + ")";
                    break;
                case "(A)":
                    qSubNum = "(" + convertNumberToLetter(qNumObj.currSubQuestionNumber, true) + ")";
                    break;
                case "#A":
                    qSubNum = qNumObj.currQuestionNumber + convertNumberToLetter(qNumObj.currSubQuestionNumber, true);
                    break;
                case "#A.":
                    qSubNum = qNumObj.currQuestionNumber + convertNumberToLetter(qNumObj.currSubQuestionNumber, true) + ".";
                    break;
                case "#A)":
                    qSubNum = qNumObj.currQuestionNumber + convertNumberToLetter(qNumObj.currSubQuestionNumber, true) + ")";
                    break;
                case "#(A)":
                    qSubNum = qNumObj.currQuestionNumber + "(" + convertNumberToLetter(qNumObj.currSubQuestionNumber, true) + ")";
                    break;
                case "#.#":
                    qSubNum = qNumObj.currQuestionNumber + "." + qNumObj.currSubQuestionNumber;
                    break;
                default:
                    qSubNum = "";
                    break;
            }
        }
    }
    return qSubNum;
};


export const convertNumberToLetter = (currSubQuestionNumber, uppercase) => {

    let theLetter = uppercase ? String.fromCharCode(96 + currSubQuestionNumber).toUpperCase() : String.fromCharCode(96 + currSubQuestionNumber);

    return theLetter;
};


export const getAnswerOptions = (objectWithAnswers) => {

    // Answers can be from a group or question instance or template
    let answers = (objectWithAnswers.answerOptions !== undefined) ? objectWithAnswers.answerOptions : objectWithAnswers.answerOptionTemplates;

    return answers;
};


export function getTitleDefaultFormat(iObjType, iTextType) {

    let defaultTitleFormat = TITLE.FORMAT.PLAIN;

    switch(iObjType) {
        case OBJ_TYPE.QUESTIONNAIRE:
            switch(iTextType) {
                case OBJ_TEXT_TYPE.NAME:
                    defaultTitleFormat = TITLE.FORMAT.PLAIN;
                    break;
                case OBJ_TEXT_TYPE.SCRIPT_BEFORE:
                    defaultTitleFormat = TITLE.FORMAT.COMMENT;
                    break;
                case OBJ_TEXT_TYPE.SCRIPT_AFTER:
                    defaultTitleFormat = TITLE.FORMAT.PLAIN;
                    break;
                case OBJ_TEXT_TYPE.CLOSE_TEXT1:
                    defaultTitleFormat = TITLE.FORMAT.PLAIN;
                    break;
                case OBJ_TEXT_TYPE.CLOSE_TEXT2:
                    defaultTitleFormat = TITLE.FORMAT.PLAIN;
                    break;
                case OBJ_TEXT_TYPE.CLOSE_TEXT3:
                    defaultTitleFormat = TITLE.FORMAT.PLAIN;
                    break;
                case OBJ_TEXT_TYPE.DESCRIPTION:
                    defaultTitleFormat = TITLE.FORMAT.INFO;
                    break;
                // no default
            }
            break;
        case OBJ_TYPE.CATEGORY:
            switch(iTextType) {
                case OBJ_TEXT_TYPE.NAME:
                    defaultTitleFormat = TITLE.FORMAT.PLAIN;
                    break;
                case OBJ_TEXT_TYPE.SCRIPT:
                    defaultTitleFormat = TITLE.FORMAT.COMMENT;
                    break;
                case OBJ_TEXT_TYPE.DESCRIPTION:
                    defaultTitleFormat = TITLE.FORMAT.INFO;
                    break;
                // no default
            }
            break;
        case OBJ_TYPE.GROUP:
        case OBJ_TYPE.GROUP_LIKERT:
            switch(iTextType) {
                case OBJ_TEXT_TYPE.NAME:
                    defaultTitleFormat = TITLE.FORMAT.PLAIN;
                    break;
                case OBJ_TEXT_TYPE.DESCRIPTION:
                    defaultTitleFormat = TITLE.FORMAT.INFO;
                    break;
                // no default
            }
            break;
        case OBJ_TYPE.QUESTION:
            switch(iTextType) {
                case OBJ_TEXT_TYPE.NAME:
                    defaultTitleFormat = TITLE.FORMAT.PLAIN;
                    break;
                case OBJ_TEXT_TYPE.DESCRIPTION:
                    defaultTitleFormat = TITLE.FORMAT.INFO;
                    break;
                // no default
            }
            break;
        // no default

    }

    return defaultTitleFormat;
}


//
// let qNumObj = {
//     useQuestionNumbers: tag.metadataExists(qi, tag.SHOW_QUESTION_NUMBERS),
//     questionNumberAppend: tag.metadataValue(qi, tag.SHOW_QUESTION_NUMBERS),   // none | . or )
//     restartNumbersOnEachPage: tag.metadataExists(qi, tag.RESTART_QUESTION_NUMBERS_EACH_PAGE),
//     subnumberLikertQuestions: tag.metadataExists(qi, tag.SUBNUMBER_LIKERT_QUESTIONS), // (Questionnaire) a or (a) or Xa or X.#   ...by default none
//     subnumberShowgroupQuestions: tag.metadataExists(qi, tag.SUBNUMBER_SHOWGROUP_QUESTIONS), // (Questionnaire) a or (a) or Xa or X.#  ...by default none
//     currQuestionNumber: 0
// };
