import TemplateApi from "../api/TemplateApi";
import * as types from "../constants/actionConstants";
import {closeDrawer} from "./drawerActions";
import {initGetTemplateList} from "./templateListActions";
import * as TEMPLATE from "../constants/templateConstants";


export function getQuestionTemplate(questionKey = "", id) {
    let thunk = (dispatch)=>{
        dispatch({type: types.QUESTION_TEMPLATE_GET_INIT, questionKey});

        // If no ID or Key then make no call, new questions have no ID or Key yet.

        // Always get by ID when provided to guarantee you get the exact template spceified
        if (id) {
            TemplateApi.getQuestionById(id).then((questionTemplate) =>{
                dispatch({type: types.QUESTION_TEMPLATE_GET_SUCCESS , questionTemplate});
            });
        }
        else if (questionKey !== "") {
            // There can be no ID, ie.. in the case of the question being the collection relating to another template
            // Then the collection is stored as an array of keys only, that ensures you always get the current version.
            TemplateApi.getCurrentQuestion(questionKey).then((questionTemplate) =>{
                dispatch({type: types.QUESTION_TEMPLATE_GET_SUCCESS , questionTemplate});
            });
        }
    };

    thunk.meta = {
        debounce: {
            time: 2000,
            key: id || questionKey,
            leading: true,
            trailing: false
        }
    };

    return thunk;
}

export function saveQuestionTemplate(questionTemplateObject) {
    return (dispatch)=>{
        TemplateApi.saveQuestionTemplate(questionTemplateObject).then((questionTemplate) =>{
            dispatch({type: types.QUESTION_TEMPLATE_UPDATE_SUCCESS , questionTemplate});
        });
    };
}

export function saveQuestionTemplateAndCloseDrawer(questionTemplateObject, filter, versionsFilter, templateMode) {
    return (dispatch)=>{
        TemplateApi.saveQuestionTemplate(questionTemplateObject).then((questionTemplate) =>{
            dispatch({type: types.QUESTION_TEMPLATE_UPDATE_SUCCESS , questionTemplate});
            dispatch(closeDrawer());

            // Best to load question list page in all cases
            dispatch(initGetTemplateList(filter, TEMPLATE.TYPE.QUESTION, TEMPLATE.MODE.CURRENT));
            if (templateMode === TEMPLATE.MODE.VERSIONS) {
                let tempVersionsFilter = {...versionsFilter};
                tempVersionsFilter.templateName = questionTemplateObject.name;
                tempVersionsFilter.templateKey = questionTemplateObject.templateKey;
                dispatch(initGetTemplateList(tempVersionsFilter, TEMPLATE.TYPE.QUESTION, TEMPLATE.MODE.VERSIONS));
            }
        });
    };
}

export function saveQuestionTemplateTagsAndCloseDrawer(questionTemplateObject) {
    return (dispatch)=>{
        TemplateApi.saveQuestionTemplateTags(questionTemplateObject).then((questionTemplate) =>{
            dispatch({type: types.QUESTION_TEMPLATE_TAGS_UPDATE_SUCCESS , questionTemplate});
            dispatch(closeDrawer());
        });
    };
}

export function createQuestionTemplate(questionTemplateObject, depth) {
    return (dispatch) => {
        TemplateApi.createQuestionTemplate(questionTemplateObject).then((questionTemplate) => {
            dispatch({type: types.QUESTION_TEMPLATE_CREATE_SUCCESS, questionTemplate, depth});
        });
    };
}

export function createQuestionTemplateAndCloseDrawer(questionTemplateObject, depth, filter, onSuccess = ()=>{}) {
    return (dispatch) => {
        TemplateApi.createQuestionTemplate(questionTemplateObject).then((questionTemplate) => {
            dispatch({type: types.QUESTION_TEMPLATE_CREATE_SUCCESS, questionTemplate, depth});
            onSuccess();
            dispatch(closeDrawer());
            dispatch(initGetTemplateList(filter, TEMPLATE.TYPE.QUESTION, TEMPLATE.MODE.CURRENT));
        });
    };
}






