import React, { useState, useEffect } from 'react';
import TextField from "@mui/material/TextField";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";


export default function ArgoCheckboxHook(props) {

    const fileName = props.fileName === undefined ? "NoFileName" : props.fileName;
    const fieldName = props.fieldName === undefined ? "NoFieldName" : props.fieldName;

    const uniqueIdentifier = fileName + "-ArgoCheckboxHook-" + fieldName;

    const variant = props.variant === undefined ? "standard" : props.variant;
    const label = props.label === undefined ? "Label" : props.label;
    const labelColor = props.labelColor === undefined ? "black" : props.labelColor;
    const checked = props.checked === undefined ? "" : props.checked;
    const autoFocus = props.autoFocus === undefined ? true : false;
    const width = props.width === undefined ? "" : "";
    const onChange = props.onChange === undefined ? function () {} : props.onChange;
    const display = props.display === undefined ? "inline" : "none";
    const disabled = props.disabled === undefined ? false : props.disabled;
    const paddingTop = props.paddingTop === undefined ? "3px" : props.paddingTop;
    const bold = props.bold === undefined ? false : props.bold;

    const handleOnChange = (event) => {
        onChange(event, props.fieldName)
    };

    return (
        <div id={uniqueIdentifier + "-containder-div"} style={{paddingTop: paddingTop}}>
            <FormControlLabel
                id={uniqueIdentifier}
                name={uniqueIdentifier}
                label={bold ? <b>{label}</b> : label}
                disabled={disabled}
                sx={{paddingTop: paddingTop, display: display, color: labelColor}}
                control={<Checkbox sx={{paddingTop: "1px"}} checked={checked} onChange={handleOnChange} />}
            />
        </div>
    );
}


