import React from 'react';
import PropTypes from "prop-types";
import ActionViewer from "./ActionViewer";
import ArgoToolbarHook from "../../common/ArgoToolbarHook";


const ActionReviewViewer = (props) => {
    return (
        <div style={{height: "100%"}}>

            <ArgoToolbarHook
                fileName="ActionReviewViewer"
                fieldName="save-or-back"
                buttonTitles={["SAVE","BACK"]}
                onClick={props.handleToolbarButtonClick}
            />

            <div style={{height: "100%", overflowY: "scroll"}}>
                <ActionViewer template={props.template}/>
            </div>
        </div>
    );
};


ActionReviewViewer.propTypes = {
    template: PropTypes.object,
    handleToolbarButtonClick: PropTypes.func,
};

ActionReviewViewer.defaultProps = {
    template: undefined,
};

export default ActionReviewViewer;
