import React, {Component} from 'react';
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import {closeDrawer} from "../../actions/drawerActions";
import ArgoAppBar from "../common/ArgoAppBar";
import ClickAwayListener from '@mui/material/ClickAwayListener';
import QuestionViewer from "../qd/questions/QuestionViewer";
import {getQuestionTemplate} from "../../actions/questionTemplateActions";


class QuestionViewerDrawer extends Component {

    render() {

        return (
            <ClickAwayListener onClickAway={this.props.closeDrawer}>
                <div style={{height: "100%"}}>
                    <ArgoAppBar title="Review Question" showMenuArrowButton="LEFT" onLeftIconButtonTouchTap={this.props.closeDrawer} isDrawer={true}/>

                    <QuestionViewer templateKey={this.props.templateKey} templateId={this.props.templateId}/>
                </div>
            </ClickAwayListener>
        );
    }
}

QuestionViewerDrawer.propTypes = {
    templateKey: PropTypes.string,
    templateId: PropTypes.string
};

function mapDispatchToProps(dispatch) {
    return bindActionCreators({getQuestionTemplate, closeDrawer}, dispatch);
}

const questionViewerDrawerProps = {
    opensecondary: true,
    width: "90%",
    docked: false,
    anchor: "right"
};

export default connect(null, mapDispatchToProps)(QuestionViewerDrawer);
export {questionViewerDrawerProps};
