import React, {Component} from 'react';
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import ClickAwayListener from '@mui/material/ClickAwayListener';
import {closeDrawer} from "../../actions/drawerActions";
import {getQuestionnaireGraph} from "../../actions/questionnaireTemplateActions";
import ArgoAppBar from "../common/ArgoAppBar";
import PortalContainer from '../../portal/PortalContainer';
import * as TEMPLATE from "../../constants/templateConstants";


class QuestionnairePortalPreviewDrawer extends Component {
    constructor(props) {
        super(props);
        this.state = {};
        this.props.getQuestionnaireGraph(this.props.templateId);
    }

    render() {

        let templateObjClone = JSON.parse(JSON.stringify(this.props.questionnaireTemplateGraph));

        // Wait for questionnaire template graph to be returned from the server
        if (templateObjClone?.id) {
            return (
                <ClickAwayListener onClickAway={this.props.closeDrawer}>
                    <div id="QuestionnairePortalPreviewDrawer-container-div" style={{height: "100%"}}>
                        <ArgoAppBar title={templateObjClone.name}
                                    showMenuArrowButton="LEFT"
                                    onLeftIconButtonTouchTap={this.props.closeDrawer}
                                    isDrawer={true}
                        />

                        <div id="QuestionnairePortalPreviewDrawer-div">
                            <PortalContainer
                                portalPreview={true}
                                templateType={TEMPLATE.TYPE.QUESTIONNAIRE_GRAPH}
                                templateObj={templateObjClone}
                                portalCount={this.props.portalCount}
                            />
                        </div>
                    </div>
                </ClickAwayListener>
            )
        }
    }
}

QuestionnairePortalPreviewDrawer.defaultProps = {

};

QuestionnairePortalPreviewDrawer.propTypes = {
    templateId: PropTypes.number
};

function mapStateToProps(state) {
    return {
        questionnaireTemplateGraph: state.questionnaireTemplateGraph,
        portalCount: state.portal.portalCount
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({getQuestionnaireGraph, closeDrawer}, dispatch);
}

const questionnairePortalPreviewDrawerProps = {
    opensecondary: true,
    width: "55%",
    docked: false,
    anchor: "right"
};

export default connect(mapStateToProps, mapDispatchToProps)(QuestionnairePortalPreviewDrawer);
export {questionnairePortalPreviewDrawerProps};
