import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import QuestionViewerDrawer, {questionViewerDrawerProps} from "../../drawers/QuestionViewerDrawer";
import QuestionCreateEditDrawer, {questionCreateEditDrawerProps} from "../../drawers/QuestionCreateEditDrawer";
import {openDrawer} from "../../../actions/drawerActions";
import {getQuestionTemplate} from "../../../actions/questionTemplateActions";
import {removeGroupQuestion, reorderGroupQuestion} from "../../../actions/groupEditorActions";
import {removeTagQuestion} from "../../../actions/tagEditorActions";
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import ArgoIconButtonHook from "../../common/ArgoIconButtonHook";
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import AccordionDetails from "@mui/material/AccordionDetails";
import Accordion from "@mui/material/Accordion";
import HighlightOffOutlinedIcon from '@mui/icons-material/HighlightOffOutlined';
import ArgoReorderArrowsHook from "../../common/ArgoReorderArrowsHook";
import Typography from "@mui/material/Typography";
import UserContext from "../../../UserContext";
import * as TEMPLATE from "../../../constants/templateConstants";
import * as questionTypes from "../../../constants/questionTypeConstants";
import * as expander from "../../../utilities/expander";
import * as portal from "../../../utilities/portal";
import ArgoTemplateListDividerHook from "../../common/ArgoTemplateListDividerHook";
import ArgoExpandCollapseButtonHook from "../../common/ArgoExpandCollapseButtonHook";
import ArgoPortalPreview from "../../common/ArgoPortalPreview";
import statusFormatter from "../../../utilities/StatusFormatter";
import * as screenChangeSize from "../../../utilities/screenChangeSize";


class QuestionViewerExpandable extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            expander: {},
            expandAll: true,
            expanderPreset: false,
            protalPreviewRendered: false,
            questionInfoDrawerOpen: false,
            expanderObjLoaded: false,
            widthChange: false
        };
    }

    static contextType = UserContext;  // From blueTheme.js

    componentDidMount() {
        if (this.props.questionKeys) {
            for (let i = 0; i < this.props.questionKeys.length; i++) {
                this.props.getQuestionTemplate(this.props.questionKeys[i], null);
            }
        }
        this.setExpander(this.props);
    }

    componentWillReceiveProps(nextProps) {
        this.setExpander(nextProps);
    }

    setExpander = (props) => {
        if (!this.state.expanderPreset && this.allRequiredTemplatesLoaded(props)) {
            this.setState({expanderPreset: true, expander: expander.presetExpander(props.questionKeys, props.questionTemplates)});
            props.setStateCallback({questionsLoaded: true, groupTemplates: props.questionTemplates});
        }
    }


    setStateCallback = (stateObj) => {
        this.setState(stateObj);
    }


    allRequiredTemplatesLoaded = (props) => {
        let allRequiredTemplatesLoaded = true;
        for (let i = 0; i < props.questionKeys.length; i++) {
            if (!props.questionTemplates.hasOwnProperty(props.questionKeys[i])) {
                allRequiredTemplatesLoaded = false;
                this.props.getQuestionTemplate(props.questionKeys[i], null);
                break;
            }
        }
        return allRequiredTemplatesLoaded;
    };


    answerCount = (questionTemplate) => {
        // Only radio and checkbox questions have answers, in those cases we want to diaplay an assigned answers count
        let answerCountDisplay = "";

        if (questionTemplate.questionType === questionTypes.RADIO || questionTemplate.questionType === questionTypes.CHECKBOX ) {
            answerCountDisplay = " (Answers Assigned: " + questionTemplate.answerOptionTemplates.length + ")"
        }

        return answerCountDisplay;
    }

    // -----------------------------------------------------------------------------------------------------------------
    // EXPANDER FUNCTIONS ----------------------------------------------------------------------------------------------
    // -----------------------------------------------------------------------------------------------------------------

    onClickIconButtonReorder = (event, fieldName, index, depth, direction) => {
        event.stopPropagation(); // Keep accordion from expand/collapse
        this.props.reorderGroupQuestion((direction === "up"), index, this.props.parentTemplate);
    };

    onClickIconButtonEdit = (questionTemplate, event) => {
        event.stopPropagation(); // Keep accordion from expand/collapse
        this.props.setStateCallback({openQuestionEditor: true, questionTemplate: questionTemplate});
        //this.props.openDrawer(questionCreateEditDrawerProps, <QuestionCreateEditDrawer templateKey={questionTemplate.templateKey} templateId={questionTemplate.id} filter={this.props.filter} templateMode={TEMPLATE.MODE.CURRENT}/>);
    };

    onClickIconButtonRemove = (index, event) => {
        event.stopPropagation(); // Keep accordion from expand/collapse
        if (this.props.parentType === TEMPLATE.TYPE.GROUP) {
            this.props.removeGroupQuestion(index);
        } else {
            this.props.removeTagQuestion(index);
        }
    };

    onClickIconButtonInfo = (questionTemplateKey, event) => {
        event.stopPropagation(); // Keep accordion from expand/collapse
        this.props.setStateCallback({openQuestionViewer: true, selectedQuestionInfoKey: questionTemplateKey, hidePortalPreview: true, showGroupBackButton: false});
    };


    onExpandChange = (questionTemplateKey, event, open) => {
        if(this.props.isLikert) return //prevents expandable from wiggling on click if is likert

        expander.expandCollapseSelected(this.state.expander, questionTemplateKey, this.setStateCallback);

        let template = this.state.expander[questionTemplateKey].template;

        // Don't postMessage portal when expander is closing or NOT displayed
        if (open && !this.props.hidePortalPreview) {
            // portal.sendQuestionToPortal(template, template.id, template.answerOptionTemplates);
        }
    };

    // -----------------------------------------------------------------------------------------------------------------
    // RENDER ----------------------------------------------------------------------------------------------------------
    // -----------------------------------------------------------------------------------------------------------------

    render() {
        //changes state of widthChange so it re-renders the width everytime
        window.addEventListener('resize', this.setStateCallback.bind(this, {widthChange: !this.state.widthChange}))

        const {palette} = this.context;

        let questionTemplates = this.props.questionTemplates;  // List of question templates, could be from parent type Group or Tag

        return (
            this.allRequiredTemplatesLoaded(this.props) ?
                <div key={"QuestionViewerExpandable-container-div"} id={"QuestionViewerExpandable-container-div"} style={{width: "100%"}}>

                    <div>&nbsp;</div>

                    {/*// ===========================================================================================================*/}
                    {/*// QUESTION LIST DIVIDER */}
                    {/*// ===========================================================================================================*/}

                    <ArgoTemplateListDividerHook
                        fileName="QuestionViewerExpandable"
                        parentsLabel={this.props.parentType.charAt(0).toUpperCase() + this.props.parentType.slice(1)}
                        childLabel={["question","questions"]}
                        arrayLength={this.props.questionKeys.length}
                    />

                    {/*// ===========================================================================================================*/}
                    {/*// QUESTION EXPAND/COLLAPSE BUTTON  - NOTE: No expand/collapse button if group is Likert ie.. has answers  */}
                    {/*// ===========================================================================================================*/}

                    {(this.props.questionKeys.length > 1 && !this.props.isLikert) ?
                        <div style={{float: "right", width: 140}}>
                            <ArgoExpandCollapseButtonHook
                                fileName="QuestionViewerExpandable"
                                expandAll={this.state.expandAll}
                                onClick={expander.expandCollapseAll.bind(this, TEMPLATE.TYPE.QUESTION, this.state.expander, this.state.expandAll, this.props.questionKeys, this.props.hidePortalPreview, this.setStateCallback)}
                            />

                            <div>&nbsp;</div>
                        </div>
                        : ""
                    }

                    {/*// ===========================================================================================================*/}
                    {/*// ASSIGNED QUESTIONS LIST */}
                    {/*// ===========================================================================================================*/}

                    <div id={"QuestionViewerExpandable-question-list-container-div-parentId-" + this.props.parentTemplate.id} style={{display: "flex", alignSelf: "flex-start", flexDirection: "column", width: "100%", minHeight: "min-content"}}>
                        {this.props.questionKeys.map((questionTemplateKey, index) => {
                            return (
                                <div key={questionTemplateKey + index} id={"QuestionViewerExpandable-question-container-div" + questionTemplateKey + index} style={{display: "flex", flexDirection: "row", alignItems: "center"}}>

                                    <Accordion key={"Accordion-" + questionTemplateKey + index}
                                               id={"QuestionViewerExpandable" + "-Accordion-" + index}
                                               variant="outlined"
                                               disableGutters={true}
                                               defaultExpanded={false}
                                               onChange={this.onExpandChange.bind(this, questionTemplateKey)}
                                               expanded={this.state.expander[questionTemplateKey]?.open ? this.state.expander[questionTemplateKey]?.open : false}
                                               sx={{width: "100%", minWidth: 940}}>
                                        <AccordionSummary key={"AccordionSummary-" + questionTemplateKey + index}
                                                          id={"QuestionViewerExpandable-AccordionSummary-" + index}
                                                          expandIcon={!this.props.hidePortalPreview ? <ExpandMoreIcon style={{color: expander.ICON_COLOR}}/> : ""}
                                                          sx={{height: "30px", backgroundColor: expander.ACCORDION_BG_COLOR, width: "100%", '&:hover': {bgcolor: expander.ACCORDION_BG_COLOR_HOVER}}}
                                        >
                                            <div style={{display: "flex", flexDirection: "row", alignItems: "center", width: "100%"}}>

                                                {this.props.showIconButtonReorder ?
                                                    <ArgoReorderArrowsHook
                                                        fileName="QuestionViewerExpandable"
                                                        fieldName="container-div"
                                                        onClick={this.onClickIconButtonReorder}
                                                        index={index}
                                                        arrayLength={this.props.questionKeys.length}
                                                        isFirst={index === 0}
                                                        isLast={index === (this.props.questionKeys.length -1)}
                                                        upArrowColor={index === 0 ? expander.ICON_COLOR_HIDDEN : expander.ICON_COLOR}
                                                        downArrowColor={this.props.isLast ? expander.ICON_COLOR_HIDDEN : expander.ICON_COLOR}
                                                    /> : ""
                                                }

                                                <Typography>
                                                    {statusFormatter(questionTemplates[questionTemplateKey], 0, 0)}
                                                </Typography>

                                                <Typography variant="h6" component="div" sx={{color: expander.TEXT_COLOR_SECONDARY}}>
                                                    {"Question:"}
                                                </Typography>

                                                <Typography variant="h6" component="div"
                                                            style={{paddingLeft: "5px",
                                                                color: expander.TEXT_COLOR_PRIMARY,
                                                                whiteSpace: 'nowrap',
                                                                wordWrap: 'break-word',
                                                                overflow: "hidden",
                                                                textOverflow: 'ellipsis',
                                                                minWidth: 50,
                                                                width: screenChangeSize.setWidth.call(this, 1020, [questionTemplates[questionTemplateKey].nameQualifier, this.props.showIconButtonReorder, this.props.showIconButtonEdit, this.props.showIconButtonRemove, this.props.showIconButtonInfo], this.setStateCallback),
                                                            }}>
                                                    {questionTemplates[questionTemplateKey].name}
                                                </Typography>

                                                {questionTemplates[questionTemplateKey].nameQualifier ?
                                                    <div style={{display: "flex"}}>
                                                        <Typography variant="subtitle1" component="div" sx={{paddingLeft: "5px", paddingTop: "3px", color: expander.TEXT_COLOR_SECONDARY}}>
                                                            {questionTemplates[questionTemplateKey].nameQualifier ? "Qualifier:" : ""}
                                                        </Typography>

                                                        <Typography variant="subtitle1" component="div"
                                                                    sx={{
                                                                        paddingLeft: "5px",
                                                                        paddingTop: "3px",
                                                                        color: expander.TEXT_COLOR_PRIMARY,
                                                                        whiteSpace: 'nowrap',
                                                                        wordWrap: 'break-word',
                                                                        overflow: "hidden",
                                                                        textOverflow: 'ellipsis',
                                                                        maxWidth: 120,
                                                                        minWidth: 120
                                                                    }}>
                                                            {questionTemplates[questionTemplateKey].nameQualifier ? questionTemplates[questionTemplateKey].nameQualifier : ""}
                                                        </Typography>
                                                    </div> : ''
                                                }

                                                {!questionTemplates[questionTemplateKey].nameQualifier ? <div style={{minWidth: 15}}>&nbsp;</div> : ''}

                                                <Typography variant="h6" component="div" sx={{paddingLeft: "10px", color: expander.TEXT_COLOR_SECONDARY}}>
                                                    {"Type:"}
                                                </Typography>

                                                <Typography variant="subtitle1" component="div" sx={{paddingLeft: "5px", color: expander.TEXT_COLOR_PRIMARY, minWidth: 94}}>
                                                    {questionTypes.getLabel[questionTemplates[questionTemplateKey].questionType]}
                                                </Typography>

                                                <Typography variant="subtitle1" component="div" sx={{paddingLeft: "20px", color: expander.TEXT_COLOR_ALERT}}>
                                                    {(questionTemplates[questionTemplateKey].optional ? "" : "*required")}
                                                </Typography>

                                                <Typography variant="subtitle1" component="div" sx={{paddingLeft: "20px", color: expander.TEXT_COLOR_SECONDARY, minWidth: 163}}>
                                                    {this.answerCount(questionTemplates[questionTemplateKey])}
                                                </Typography>

                                                <div id={"QuestionViewerExpandable-icon-button-container-div" + index} style={{display: "flex", marginLeft: "auto"}}>

                                                    {this.props.showIconButtonEdit ?
                                                        <ArgoIconButtonHook
                                                            fileName="QuestionViewerExpandable"
                                                            fieldName="edit"
                                                            onClick={this.onClickIconButtonEdit.bind(this, questionTemplates[questionTemplateKey])}
                                                            index={index}
                                                            icon={<ModeEditIcon style={{color: expander.ICON_COLOR}}/>}
                                                            display={this.props.showEditIconButton}
                                                        />  : ""
                                                    }

                                                    <div>&nbsp;</div>

                                                    {this.props.showIconButtonRemove ?
                                                        <ArgoIconButtonHook
                                                            fileName="QuestionViewerExpandable"
                                                            fieldName="remove"
                                                            onClick={this.onClickIconButtonRemove.bind(this, index)}
                                                            index={index}
                                                            icon={<HighlightOffOutlinedIcon style={{color: expander.ICON_COLOR}}/>}
                                                            display={this.props.showRemoveIconButton}
                                                        />  : ""
                                                    }

                                                    <div>&nbsp;</div>

                                                    {this.props.showIconButtonInfo ?
                                                        <ArgoIconButtonHook
                                                            fileName="QuestionViewerExpandable"
                                                            fieldName="info"
                                                            onClick={this.onClickIconButtonInfo.bind(this, questionTemplateKey)}
                                                            index={index}
                                                            icon={<InfoOutlinedIcon style={{color: expander.ICON_COLOR}}/>}
                                                        /> : ""
                                                    }

                                                    <div>&nbsp;</div>

                                                </div>

                                            </div>
                                        </AccordionSummary>
                                        {!this.props.hidePortalPreview ?
                                            <AccordionDetails key={"AccordionDetails-" + questionTemplateKey + index}>

                                                {/*<ArgoPortalPreview*/}
                                                {/*    calledBy="QuestionViewerExpandable"*/}
                                                {/*    templateType={TEMPLATE.TYPE.QUESTION}*/}
                                                {/*    id={questionTemplates[questionTemplateKey].id.toString()}*/}
                                                {/*    questionTemplate={questionTemplates[questionTemplateKey]}*/}
                                                {/*/>*/}

                                            </AccordionDetails>
                                            : ""
                                        }
                                    </Accordion>

                                    {(this.state.expander[questionTemplateKey]?.open ) ? <div id={"spacer-div-" + index}>&nbsp;</div> : ""}

                                </div>
                            );
                        })}
                    </div>

                </div>
                : ""
        );
    }
}

QuestionViewerExpandable.defaultProps = {
    parentType: TEMPLATE.TYPE.GROUP,
    parentTemplate: {},
    showIconButtonReorder: true,
    showIconButtonEdit: true,
    showIconButtonRemove: true,
    showIconButtonInfo: true,
    setStateCallback: function () {},
    hidePortalPreview: false
};

QuestionViewerExpandable.propTypes = {
    parentType: PropTypes.string,
    parentTemplate: PropTypes.object,
    questionKeys: PropTypes.array,
    showIconButtonReorder: PropTypes.bool,
    showIconButtonEdit: PropTypes.bool,
    showIconButtonRemove: PropTypes.bool,
    showIconButtonInfo: PropTypes.bool,
    setStateCallback: PropTypes.func,
    hidePortalPreview: PropTypes.bool
};

function mapStateToProps(state, props) {
    return {
        questionTemplates: state.questionTemplate,
        questionsFilter: state.filter[TEMPLATE.FILTER.current.question],
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({getQuestionTemplate, removeTagQuestion, removeGroupQuestion, reorderGroupQuestion, openDrawer}, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(QuestionViewerExpandable);
