import React, {Component} from 'react';
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
//import WarningIcon from "@mui/icons-material/Warning"; // Triangle with explanition point
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline'; // circle with explanition point
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import {initGetTemplateList} from "../../actions/templateListActions";
import {getOutstandingQuestionnaireTemplateCount} from "../../actions/questionnaireTemplateActions";
//import HelpDrawer, {helpDrawerProps} from "../drawers/HelpDrawer";
import pageNames from "../../utilities/helpMap";
import {QD_HELP_URL_QUESTION, QD_URL} from "../../constants/systemConstants";
import {openDrawer} from "../../actions/drawerActions";
import * as Colors from "@mui/material/colors";
import * as TEMPLATE from "../../constants/templateConstants";
import {withRouter} from "../../utilities/withRouter";


const styles = {
    root: {
        height: "100px",
        border: "1px solid lightgray",
        padding: "5px",
        boxShadow: "2px 4px whitesmoke"
    },
    iconStyle: {
        height: "45px",
        width: "45px",
        color: Colors.orange[500],
        marginRight: "16px"
    },
    helpIcon: {
        color: Colors.grey[500],
    },
    cardTextStyle: {
        paddingTop: "16px", display: "flex", alignItems: "center"
    },
    cardPadding: {
        paddingTop: "0px", paddingBottom: "0px"
    },
    cardActions:
        {display: "flex", justifyContent: "flex-end"}
};


// const useStyles = makeStyles({
//     root: {
//         border: "1px solid",
//         padding: "10px",
//         boxShadow: "5px 10px red"
//     }
// //other styles and classes//
// };

class ArgoOutstandingQuestionnaireCard extends Component {

    constructor(props) {
        super(props);

        this.state = {
            display: true
        }
    }

    componentWillMount(){
        this.props.getOutstandingQuestionnaireTemplateCount();
    }

    handleDismiss = () => {
      this.setState({display: false})
    };

    handleOpenHelp = () => {
        let path = QD_HELP_URL_QUESTION + pageNames.unpublishedQuestionnaires;
        //this.props.openDrawer(helpDrawerProps, <HelpDrawer src={path}/>);
    };

    handleView = () => {
        let filter = {...this.props.filter};
        filter.outstanding = true;
        <withRouter navigate={QD_URL + "/questionnaires/outstanding=true"}/>;
        //this.context.router.history.push(QD_URL + "/questionnaires/outstanding=true");
        this.props.initGetTemplateList(filter, TEMPLATE.TYPE.QUESTIONNAIRE, TEMPLATE.MODE.CURRENT);
    };

    render() {

        return this.state.display && this.props.outstandingQuestionnaireCount.count > 0 &&
            <Card variant="outlined" style={styles.root}>
                <CardContent style={styles.cardPadding}>
                    <div style={styles.cardTextStyle}>
                        <ErrorOutlineIcon style={styles.iconStyle}/>
                        <div>
                            There are outstanding unpublished versions of previously published questionnaires.
                        </div>
                        <div>
                            <IconButton id="outstanding-questionnaire-card-help" name="outstanding-questionnaire-card-help" sx={{color: Colors.grey[500]}} onClick={this.handleOpenHelp}>
                                <HelpOutlineIcon />
                            </IconButton>
                        </div>
                    </div>
                    <div style={styles.cardActions}>
                        {/*<Button id="outstanding-questionnaire-card-dismiss" name="outstanding-questionnaire-card-dismiss" variant="text" onClick={this.handleDismiss} primary >Dismiss</Button>*/}
                        {/*<Button id="outstanding-questionnaire-card-view" name="outstanding-questionnaire-card-view" variant="text" onClick={this.handleView} primary >{"View " + this.props.outstandingQuestionnaireCount.count}</Button>*/}
                    </div>
                </CardContent>
            </Card>
    }
}

ArgoOutstandingQuestionnaireCard.propTypes = {
    history: PropTypes.object,
    filter: PropTypes.object,
};

ArgoOutstandingQuestionnaireCard.contextTypes = {
    router: PropTypes.shape({
        history: PropTypes.shape({
            push: PropTypes.func.isRequired,
            replace: PropTypes.func.isRequired
        }).isRequired,
        staticContext: PropTypes.object
    }).isRequired
};

function mapDispatchToProps(dispatch) {
    return bindActionCreators({initGetTemplateList, getOutstandingQuestionnaireTemplateCount, openDrawer}, dispatch);
}

function mapStateToProps(state) {
    return {
        outstandingQuestionnaireCount: state.outstandingQuestionnaireCount,
        filter: state.filter["questionnaires"]
    };
}


export default connect(mapStateToProps, mapDispatchToProps)(ArgoOutstandingQuestionnaireCard);
