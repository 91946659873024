import * as types from "../constants/actionConstants";
import TemplateApi from "../api/TemplateApi";
import * as portal from "../utilities/portal";
import * as TEMPLATE from "../constants/templateConstants";


const initializeQuestionnaireEditorCreate = (template) => {

    let tempClosePageObj = portal.getQuestionnaireClosePageObj();
    //let tempAlertsObj = portal.getQuestionnaireAlertsObj("survey");

    let tempMetadata = {
        [portal.CUSTOM_DDO_QNR_FORMAT_CLOSE_PAGE]: JSON.stringify(tempClosePageObj),
        //[portal.CUSTOM_DDO_QNR_FORMAT_ALERTS]: JSON.stringify(tempAlertsObj)
    }

    if (!template) {
        template = {
            metadata: {}
        };
    }

    return {
        type: types.QUESTIONNAIRE_EDITOR_INITIALIZE,
        payload: {
            template: {
                templateKey: (template.templateKey)? template.templateKey : "",
                name: (template.name)? template.name : "",
                nameQualifier: (template.nameQualifier)? template.nameQualifier : null,
                description: (template.description)? template.description : "",
                metadata: {
                    ...tempMetadata
                },
                categoryKeys: [],
            },
            categoryKeys: [],
            questionKeys: [],
            options: {
                hasDescription: !!template.description,
                createMode: true,
                edited: true,
                validate: []
            }
        }
    };
};

const initializeQuestionnaireEditorUpdate = (template) => {
    return {
        type: types.QUESTIONNAIRE_EDITOR_INITIALIZE,
        payload: {
            template: {
                ...template,
                startDate: null,
                endDate: null,
                publishedDate: null,
            },
            categoryKeys: template.categoryKeys,
            questionKeys: template.questionKeys,
            options: {
                hasScript: !!template.script,
                hasDescription: !!template.description,
                createMode: false,
                edited: false,
                validate: []
            }
        }
    };
};

const updateQuestionnaireTemplate = (template, options) => {
    return {
        type: types.QUESTIONNAIRE_EDITOR_UPDATE,
        payload: {
            template,
            options: {
                edited: true,
                ...options
            }
        }
    };
};

const clearQuestionnaireEditor = () => {
    return {
        type: types.QUESTIONNAIRE_EDITOR_CLEAR
    };
};



const setDefaultCategory = (templateKey) => {
    return {
        type: types.QUESTIONNAIRE_EDITOR_SET_DEFAULT_CATEGORY,
        defaultCategory: templateKey
    };
};



const checkQuestionnaireValue = (filter) => {
    let thunk = (dispatch) => {
        TemplateApi.checkQuestionnaireExists(filter).then((data) => {

            dispatch({type: types.QUESTIONNAIRE_EDITOR_VALUE_EXISTS, list: data.collection});
        });
    };

    thunk.meta = {
        debounce: {
            time: 1000,
            key: "__questionnaireExistsCheck",
            leading: false,
            trailing: true
        }
    };

    return thunk;
};


const validateQuestionnaireNameAndQualifierUnique = (name, qualifier) => {

    let nameQualifier = qualifier === null ? "" : qualifier;

    let thunk = (dispatch) => {
        TemplateApi.checkTemplateNameAndQualifierExists(TEMPLATE.TYPE.QUESTIONNAIRE, name, nameQualifier).then((data) => {
            dispatch({type: types.CATEGORY_EDITOR_VALUE_EXISTS, list: data.collection, depth: 0});
        });
    };

    thunk.meta = {
        debounce: {
            time: 1000,
            key: "__questionExistsCheck",
            leading: false,
            trailing: true
        }
    };

    return thunk;
};

const checkNameQualifier = (nameQualifier, id) => {
    let thunk = (dispatch) => {
        TemplateApi.checkQuestionnaireNameQualifierExists(nameQualifier, id).then((data) => {

            // NOTE: for name check if there is a dup the dup templates are returned so gonna have to trick the UI
            // this returns {valid: true|false errors: null}
            let results = [];
            if (!data.valid) {
                results = [{name: "", nameQualifier: nameQualifier, templateKey: "", id: id }];
            }

            dispatch({type: types.QUESTIONNAIRE_NAME_QUALIFIER_EXISTS, list: results});
        });
    };

    thunk.meta = {
        debounce: {
            time: 1000,
            key: "__questionnaireExistsCheck",
            leading: false,
            trailing: true
        }
    };

    return thunk;
};

const setQuestionnaireType = (type) => {
    return {
        type: types.QUESTIONNAIRE_EDITOR_SET_TYPE,
        questionnaireType: type
    };
};

// ---------------------------------------------------------------------------------------------------------------------
// Questionnaires will have associated categories
// ---------------------------------------------------------------------------------------------------------------------

const addQuestionnaireCategory = (category) => {
    return {
        type: types.QUESTIONNAIRE_EDITOR_ADD_CATEGORY,
        category
    };
};

const removeQuestionnaireCategory = (index) => {
    return {
        type: types.QUESTIONNAIRE_EDITOR_REMOVE_CATEGORY,
        index
    };
};

const reorderQuestionnaireCategory = (isUp, currentIndex) => {
    return {
        type: types.QUESTIONNAIRE_EDITOR_REORDER,
        isUp,
        currentIndex
    };
};


// ---------------------------------------------------------------------------------------------------------------------
// SIMPLE Questionnaires would have associated questions, I PLAN to REMOVE simple mode
// ---------------------------------------------------------------------------------------------------------------------

// I think therse were for SIMPLE questionnaires
const addQuestion = (questionKey) => {
    return {
        type: types.QUESTIONNAIRE_EDITOR_ADD_QUESTION,
        questionKey
    };
};

const removeQuestion = (index) => {
    return {
        type: types.QUESTIONNAIRE_EDITOR_REMOVE_QUESTION,
        index
    };
};

const reorderQuestion = (isUp, currentIndex) => {
    return {
        type: types.QUESTIONNAIRE_EDITOR_REORDER_QUESTION,
        isUp,
        currentIndex
    };
};


export {
    initializeQuestionnaireEditorCreate,
    initializeQuestionnaireEditorUpdate,
    updateQuestionnaireTemplate,
    clearQuestionnaireEditor,
    checkQuestionnaireValue,
    checkNameQualifier,
    validateQuestionnaireNameAndQualifierUnique,
    setQuestionnaireType,
    setDefaultCategory,
    addQuestionnaireCategory,
    removeQuestionnaireCategory,
    reorderQuestionnaireCategory,
    addQuestion,
    removeQuestion,
    reorderQuestion,
};
