import React from 'react';
import StopIcon from '@mui/icons-material/Stop';

//import COLOR_DOT from '@material-ui/icons/FiberManualRecord';
//import ColorSquare from '@mui/icons-material/Stop';
// import ColorDot from "@mui/icons-material/ColorDot";
// import ColorSquare from "@mui/icons-material/ColorSquare";
// export const COLOR_DOT = ColorDot;
// export const COLOR_SQUARE = ColorSquare;


export const colorDefaultObj = {
        backgroundColor: "",
        backgroundImage: "",
        colorType: "default",
        colorIndex: null,
        colorEntry: "black",
        color: "black",
        opacityEntry: "100",
        opacity: "100%",
        colorTypes: [],
        colorValues: [],
        colorSelectHasFocus: false
};

export const colorEntryTypes =
    [
            {value: "default", text: "Default"},
            {value: "select", text: "Select Color"},
            {value: "hex", text: "Hex Number"},
            {value: "rgb", text: "RGB Value"}
    ]

export const COLOR_CUSTOM = "Custom";

export const COLOR_DEFAULT = "#2497F3";

export const standard =
    [
        {name: "Black", color: "Black", hex: "#000000"},
        {name: "Gray", color: "Gray", hex: "#808080"},
        {name: "Dark Gray", color: "DarkSlateGray", hex: "#2F4F4F"},
        {name: "Brown", color: "Brown", hex: "#A52A2A"},
        {name: "Crimson", color: "Crimson", hex: "#DC143C"},
        {name: "Red", color: "Red", hex: "#FF0000"},
        {name: "Dark Red", color: "DarkRed", hex: "#8B0000"},
        {name: "Orange", color: "Orange", hex: "#FFA500"},
        {name: "Dark Orange", color: "DarkOrange", hex: "#FF8C00"},
        {name: "Gold", color: "Gold", hex: "#FFD700"},
        //{name: "Yellow", color: "Yellow", hex: "#FFFF00"},
        {name: "Yellow", color: "Yellow", hex: "#fff44f"},  // changed per QDII-1117 - Change Yellow Icon Color for Likert Faces.
        //{name: "Yellow", color: "Yellow", hex: "#f5ed00"},
        {name: "Lime", color: "GreenYellow", hex: "#ADFF2F"},
        {name: "Light Green", color: "LightGreen", hex: "#90EE90"},
        {name: "Green", color: "Green", hex: "#008000"},
        {name: "Dark Green", color: "DarkGreen", hex: "#006400"},
        {name: "Cyan", color: "Cyan", hex: "#00FFFF"},
        {name: "Dark Cyan", color: "DarkCyan", hex: "#008B8B"},
        {name: "Light Blue", color: "LightBlue", hex: "#ADD8E6"},
        {name: "Dodger Blue", color: "#2497F3", hex: "#2497F3"},
        {name: "Primary Blue", color: "#01579B", hex: "#01579B"},
        {name: "Blue", color: "Blue", hex: "#0000FF"},
        {name: "Dark Blue", color: "DarkBlue", hex: "#00008B"},
        {name: "Violet", color: "BlueViolet", hex: "#8A2BE2"},
        {name: "Purple", color: "Purple", hex: "#800080"}
    ];


export const standardMenuItems =
    [
            {value: 0, text: <div title={standard[0].name} style={{display: "flex",alignItems: "center"}}><StopIcon style={{color: standard[0].color}}/><div style={{color: standard[0].color}}>{standard[0].name}</div></div>},
            {value: 1, text: <div title={standard[1].name} style={{display: "flex",alignItems: "center"}}><StopIcon style={{color: standard[1].color}}/><div style={{color: standard[1].color}}>{standard[1].name}</div></div>},
            {value: 2, text: <div title={standard[2].name} style={{display: "flex",alignItems: "center"}}><StopIcon style={{color: standard[2].color}}/><div style={{color: standard[2].color}}>{standard[2].name}</div></div>},
            {value: 3, text: <div title={standard[3].name} style={{display: "flex",alignItems: "center"}}><StopIcon style={{color: standard[3].color}}/><div style={{color: standard[3].color}}>{standard[3].name}</div></div>},
            {value: 4, text: <div title={standard[4].name} style={{display: "flex",alignItems: "center"}}><StopIcon style={{color: standard[4].color}}/><div style={{color: standard[4].color}}>{standard[4].name}</div></div>},
            {value: 5, text: <div title={standard[5].name} style={{display: "flex",alignItems: "center"}}><StopIcon style={{color: standard[5].color}}/><div style={{color: standard[5].color}}>{standard[5].name}</div></div>},
            {value: 6, text: <div title={standard[7].name} style={{display: "flex",alignItems: "center"}}><StopIcon style={{color: standard[6].color}}/><div style={{color: standard[6].color}}>{standard[6].name}</div></div>},
            {value: 7, text: <div title={standard[8].name} style={{display: "flex",alignItems: "center"}}><StopIcon style={{color: standard[7].color}}/><div style={{color: standard[7].color}}>{standard[7].name}</div></div>},
            {value: 8, text: <div title={standard[8].name} style={{display: "flex",alignItems: "center"}}><StopIcon style={{color: standard[8].color}}/><div style={{color: standard[8].color}}>{standard[8].name}</div></div>},
            {value: 9, text: <div title={standard[9].name} style={{display: "flex",alignItems: "center"}}><StopIcon style={{color: standard[9].color}}/><div style={{color: standard[9].color}}>{standard[9].name}</div></div>},
            {value: 10, text: <div title={standard[10].name} style={{display: "flex",alignItems: "center"}}><StopIcon style={{color: standard[10].color}}/><div style={{color: standard[10].color}}>{standard[10].name}</div></div>},
            {value: 11, text: <div title={standard[11].name} style={{display: "flex",alignItems: "center"}}><StopIcon style={{color: standard[11].color}}/><div style={{color: standard[11].color}}>{standard[11].name}</div></div>},
            {value: 12, text: <div title={standard[12].name} style={{display: "flex",alignItems: "center"}}><StopIcon style={{color: standard[12].color}}/><div style={{color: standard[12].color}}>{standard[12].name}</div></div>},
            {value: 13, text: <div title={standard[13].name} style={{display: "flex",alignItems: "center"}}><StopIcon style={{color: standard[13].color}}/><div style={{color: standard[13].color}}>{standard[13].name}</div></div>},
            {value: 14, text: <div title={standard[14].name} style={{display: "flex",alignItems: "center"}}><StopIcon style={{color: standard[14].color}}/><div style={{color: standard[14].color}}>{standard[14].name}</div></div>},
            {value: 15, text: <div title={standard[15].name} style={{display: "flex",alignItems: "center"}}><StopIcon style={{color: standard[15].color}}/><div style={{color: standard[15].color}}>{standard[15].name}</div></div>},
            {value: 16, text: <div title={standard[16].name} style={{display: "flex",alignItems: "center"}}><StopIcon style={{color: standard[16].color}}/><div style={{color: standard[16].color}}>{standard[16].name}</div></div>},
            {value: 17, text: <div title={standard[17].name} style={{display: "flex",alignItems: "center"}}><StopIcon style={{color: standard[17].color}}/><div style={{color: standard[17].color}}>{standard[17].name}</div></div>},
            {value: 18, text: <div title={standard[18].name} style={{display: "flex",alignItems: "center"}}><StopIcon style={{color: standard[18].color}}/><div style={{color: standard[18].color}}>{standard[18].name}</div></div>},
            {value: 19, text: <div title={standard[19].name} style={{display: "flex",alignItems: "center"}}><StopIcon style={{color: standard[19].color}}/><div style={{color: standard[19].color}}>{standard[19].name}</div></div>},
            {value: 20, text: <div title={standard[20].name} style={{display: "flex",alignItems: "center"}}><StopIcon style={{color: standard[20].color}}/><div style={{color: standard[20].color}}>{standard[20].name}</div></div>},
            {value: 21, text: <div title={standard[21].name} style={{display: "flex",alignItems: "center"}}><StopIcon style={{color: standard[21].color}}/><div style={{color: standard[21].color}}>{standard[21].name}</div></div>},
            {value: 22, text: <div title={standard[22].name} style={{display: "flex",alignItems: "center"}}><StopIcon style={{color: standard[22].color}}/><div style={{color: standard[22].color}}>{standard[22].name}</div></div>},
            {value: 23, text: <div title={standard[23].name} style={{display: "flex",alignItems: "center"}}><StopIcon style={{color: standard[23].color}}/><div style={{color: standard[23].color}}>{standard[23].name}</div></div>}
    ];





export const selections =
    [
        <div title={standard[0].name} style={{display: "flex"}}>{<StopIcon style={{color: standard[0].color, paddingTop: "16px"}}/>}<div style={{color: standard[0].color}}>{standard[0].name}</div></div>,
        <div title={standard[1].name} style={{display: "flex"}}>{<StopIcon style={{color: standard[1].color, paddingTop: "16px"}}/>}<div style={{color: standard[1].color}}>{standard[1].name}</div></div>,
        <div title={standard[2].name} style={{display: "flex"}}>{<StopIcon style={{color: standard[2].color, paddingTop: "16px"}}/>}<div style={{color: standard[2].color}}>{standard[2].name}</div></div>,
        <div title={standard[3].name} style={{display: "flex"}}>{<StopIcon style={{color: standard[3].color, paddingTop: "16px"}}/>}<div style={{color: standard[3].color}}>{standard[3].name}</div></div>,
        <div title={standard[4].name} style={{display: "flex"}}>{<StopIcon style={{color: standard[4].color, paddingTop: "16px"}}/>}<div style={{color: standard[4].color}}>{standard[4].name}</div></div>,
        <div title={standard[5].name} style={{display: "flex"}}>{<StopIcon style={{color: standard[5].color, paddingTop: "16px"}}/>}<div style={{color: standard[5].color}}>{standard[5].name}</div></div>,
        <div title={standard[6].name} style={{display: "flex"}}>{<StopIcon style={{color: standard[6].color, paddingTop: "16px"}}/>}<div style={{color: standard[6].color}}>{standard[6].name}</div></div>,
        <div title={standard[7].name} style={{display: "flex"}}>{<StopIcon style={{color: standard[7].color, paddingTop: "16px"}}/>}<div style={{color: standard[7].color}}>{standard[7].name}</div></div>,
        <div title={standard[8].name} style={{display: "flex"}}>{<StopIcon style={{color: standard[8].color, paddingTop: "16px"}}/>}<div style={{color: standard[8].color}}>{standard[8].name}</div></div>,
        <div title={standard[9].name} style={{display: "flex"}}>{<StopIcon style={{color: standard[9].color, paddingTop: "16px"}}/>}<div style={{color: standard[9].color}}>{standard[9].name}</div></div>,
        <div title={standard[10].name} style={{display: "flex"}}>{<StopIcon style={{color: standard[10].color, paddingTop: "16px"}}/>}<div style={{color: standard[10].color}}>{standard[10].name}</div></div>,
        <div title={standard[11].name} style={{display: "flex"}}>{<StopIcon style={{color: standard[11].color, paddingTop: "16px"}}/>}<div style={{color: standard[11].color}}>{standard[11].name}</div></div>,
        <div title={standard[12].name} style={{display: "flex"}}>{<StopIcon style={{color: standard[12].color, paddingTop: "16px"}}/>}<div style={{color: standard[12].color}}>{standard[12].name}</div></div>,
        <div title={standard[13].name} style={{display: "flex"}}>{<StopIcon style={{color: standard[13].color, paddingTop: "16px"}}/>}<div style={{color: standard[13].color}}>{standard[13].name}</div></div>,
    ];


export const standardFont =
    [
        {name: "Black", color: "Black", hex: "#000000"},
        {name: "Gray", color: "Gray", hex: "#808080"},
        {name: "Dark Gray", color: "DarkSlateGray", hex: "#2F4F4F"},
        {name: "Brown", color: "Brown", hex: "#A52A2A"},
        {name: "Crimson", color: "Crimson", hex: "#DC143C"},
        {name: "Red", color: "Red", hex: "#FF0000"},
        {name: "Dark Red", color: "DarkRed", hex: "#8B0000"},
        {name: "Orange", color: "Orange", hex: "#FFA500"},
        {name: "Dark Orange", color: "DarkOrange", hex: "#FF8C00"},
        {name: "Gold", color: "Gold", hex: "#FFD700"},
        {name: "Yellow", color: "Yellow", hex: "#fff44f"},
        {name: "Lime", color: "GreenYellow", hex: "#ADFF2F"},
        {name: "Light Green", color: "LightGreen", hex: "#90EE90"},
        {name: "Green", color: "Green", hex: "#008000"},
        {name: "Dark Green", color: "DarkGreen", hex: "#006400"},
        {name: "Cyan", color: "Cyan", hex: "#00FFFF"},
        {name: "Dark Cyan", color: "DarkCyan", hex: "#008B8B"},
        {name: "Light Blue", color: "LightBlue", hex: "#ADD8E6"},
        {name: "Blue", color: "Blue", hex: "#0000FF"},
        {name: "Dark Blue", color: "DarkBlue", hex: "#00008B"},
        {name: "Violet", color: "BlueViolet", hex: "#8A2BE2"},
        {name: "Purple", color: "Purple", hex: "#800080"}
    ];

export const selectionsFont =
    [
            <div title={standard[0].name} style={{display: "flex"}}>{<StopIcon style={{color: standard[0].color, paddingTop: "16px"}}/>}<div style={{color: standard[0].color}}>{standard[0].name}</div></div>,
            <div title={standard[1].name} style={{display: "flex"}}>{<StopIcon style={{color: standard[1].color, paddingTop: "16px"}}/>}<div style={{color: standard[1].color}}>{standard[1].name}</div></div>,
            <div title={standard[2].name} style={{display: "flex"}}>{<StopIcon style={{color: standard[2].color, paddingTop: "16px"}}/>}<div style={{color: standard[2].color}}>{standard[2].name}</div></div>,
            <div title={standard[3].name} style={{display: "flex"}}>{<StopIcon style={{color: standard[3].color, paddingTop: "16px"}}/>}<div style={{color: standard[3].color}}>{standard[3].name}</div></div>,
            <div title={standard[4].name} style={{display: "flex"}}>{<StopIcon style={{color: standard[4].color, paddingTop: "16px"}}/>}<div style={{color: standard[4].color}}>{standard[4].name}</div></div>,
            <div title={standard[5].name} style={{display: "flex"}}>{<StopIcon style={{color: standard[5].color, paddingTop: "16px"}}/>}<div style={{color: standard[5].color}}>{standard[5].name}</div></div>,
            <div title={standard[6].name} style={{display: "flex"}}>{<StopIcon style={{color: standard[6].color, paddingTop: "16px"}}/>}<div style={{color: standard[6].color}}>{standard[6].name}</div></div>,
            <div title={standard[7].name} style={{display: "flex"}}>{<StopIcon style={{color: standard[7].color, paddingTop: "16px"}}/>}<div style={{color: standard[7].color}}>{standard[7].name}</div></div>,
            <div title={standard[8].name} style={{display: "flex"}}>{<StopIcon style={{color: standard[8].color, paddingTop: "16px"}}/>}<div style={{color: standard[8].color}}>{standard[8].name}</div></div>,
            <div title={standard[9].name} style={{display: "flex"}}>{<StopIcon style={{color: standard[9].color, paddingTop: "16px"}}/>}<div style={{color: standard[9].color}}>{standard[9].name}</div></div>,
            <div title={standard[10].name} style={{display: "flex"}}>{<StopIcon style={{color: standard[10].color, paddingTop: "16px"}}/>}<div style={{color: standard[10].color}}>{standard[10].name}</div></div>,
            <div title={standard[11].name} style={{display: "flex"}}>{<StopIcon style={{color: standard[11].color, paddingTop: "16px"}}/>}<div style={{color: standard[11].color}}>{standard[11].name}</div></div>,
            <div title={standard[12].name} style={{display: "flex"}}>{<StopIcon style={{color: standard[12].color, paddingTop: "16px"}}/>}<div style={{color: standard[12].color}}>{standard[12].name}</div></div>,
            <div title={standard[13].name} style={{display: "flex"}}>{<StopIcon style={{color: standard[13].color, paddingTop: "16px"}}/>}<div style={{color: standard[13].color}}>{standard[13].name}</div></div>,
    ];


// http://w3schools.sinsixx.com/css/css_colornames.asp.htm

// CSS Color Names
// The table below provides a list of the color names that are supported by all major browsers.

export const full =
    [
        {name: "AliceBlue", color: "AliceBlue", hex: "#F0F8FF"},
        {name: "AntiqueWhite", color: "AntiqueWhite", hex: "#FAEBD7"},
        {name: "Aqua", color: "Aqua", hex: "#00FFFF"},
        {name: "Aquamarine", color: "Aquamarine", hex: "#7FFFD4"},
        {name: "Azure", color: "Azure", hex: "#F0FFFF"},
        {name: "Beige", color: "Beige", hex: "#F5F5DC"},
        {name: "Bisque", color: "Bisque", hex: "#FFE4C4"},
        {name: "Black", color: "Black", hex: "#000000"},
        {name: "BlanchedAlmond", color: "BlanchedAlmond", hex: "#FFEBCD"},
        {name: "Blue", color: "Blue", hex: "#0000FF"},
        {name: "BlueViolet", color: "BlueViolet", hex: "#8A2BE2"},
        {name: "Brown", color: "Brown", hex: "#A52A2A"},
        {name: "BurlyWood", color: "BurlyWood", hex: "#DEB887"},
        {name: "CadetBlue", color: "CadetBlue", hex: "#5F9EA0"},
        {name: "Chartreuse", color: "Chartreuse", hex: "#7FFF00"},
        {name: "Chocolate", color: "Chocolate", hex: "#D2691E"},
        {name: "Coral", color: "Coral", hex: "#FF7F50"},
        {name: "CornflowerBlue", color: "CornflowerBlue", hex: "#6495ED"},
        {name: "Cornsilk", color: "Cornsilk", hex: "#FFF8DC"},
        {name: "Crimson", color: "Crimson", hex: "#DC143C"},
        {name: "Cyan", color: "Cyan", hex: "#00FFFF"},
        {name: "DarkBlue", color: "DarkBlue", hex: "#00008B"},
        {name: "DarkCyan", color: "DarkCyan", hex: "#008B8B"},
        {name: "DarkGoldenRod", color: "DarkGoldenRod", hex: "#B8860B"},
        {name: "DarkGray", color: "DarkGray", hex: "#A9A9A9"},
        {name: "DarkGreen", color: "DarkGreen", hex: "#006400"},
        {name: "DarkKhaki", color: "DarkKhaki", hex: "#BDB76B"},
        {name: "DarkMagenta", color: "DarkMagenta", hex: "#8B008B"},
        {name: "DarkOliveGreen", color: "DarkOliveGreen", hex: "#556B2F"},
        {name: "Darkorange", color: "Darkorange", hex: "#FF8C00"},
        {name: "DarkOrchid", color: "DarkOrchid", hex: "#9932CC"},
        {name: "DarkRed", color: "DarkRed", hex: "#8B0000"},
        {name: "DarkSalmon", color: "DarkSalmon", hex: "#E9967A"},
        {name: "DarkSeaGreen", color: "DarkSeaGreen", hex: "#8FBC8F"},
        {name: "DarkSlateBlue", color: "DarkSlateBlue", hex: "#483D8B"},
        {name: "DarkSlateGray", color: "DarkSlateGray", hex: "#2F4F4F"},
        {name: "DarkTurquoise", color: "DarkTurquoise", hex: "#00CED1"},
        {name: "DarkViolet", color: "DarkViolet", hex: "#9400D3"},
        {name: "DeepPink", color: "DeepPink", hex: "#FF1493"},
        {name: "DeepSkyBlue", color: "DeepSkyBlue", hex: "#00BFFF"},
        {name: "DimGray", color: "DimGray", hex: "#696969"},
        {name: "DodgerBlue", color: "DodgerBlue", hex: "#1E90FF"},
        {name: "PrimaryBlue", color: "PrimaryBlue", hex: "#01579B"},
        {name: "FireBrick", color: "FireBrick", hex: "#B22222"},
        {name: "FloralWhite", color: "FloralWhite", hex: "#FFFAF0"},
        {name: "ForestGreen", color: "ForestGreen", hex: "#228B22"},
        {name: "Fuchsia", color: "Fuchsia", hex: "#FF00FF"},
        {name: "Gainsboro", color: "Gainsboro", hex: "#DCDCDC"},
        {name: "GhostWhite", color: "GhostWhite", hex: "#F8F8FF"},
        {name: "Gold", color: "Gold", hex: "#FFD700"},
        {name: "GoldenRod", color: "GoldenRod", hex: "#DAA520"},
        {name: "Gray", color: "Gray", hex: "#808080"},
        {name: "Green", color: "Green", hex: "#008000"},
        {name: "GreenYellow", color: "GreenYellow", hex: "#ADFF2F"},
        {name: "HoneyDew", color: "HoneyDew", hex: "#F0FFF0"},
        {name: "HotPink", color: "HotPink", hex: "#FF69B4"},
        {name: "IndianRed", color: "IndianRed", hex: "#CD5C5C"},
        {name: "Indigo", color: "Indigo", hex: "#4B0082"},
        {name: "Ivory", color: "Ivory", hex: "#FFFFF0"},
        {name: "Khaki", color: "Khaki", hex: "#F0E68C"},
        {name: "Lavender", color: "Lavender", hex: "#E6E6FA"},
        {name: "LavenderBlush", color: "LavenderBlush", hex: "#FFF0F5"},
        {name: "LawnGreen", color: "LawnGreen", hex: "#7CFC00"},
        {name: "LemonChiffon", color: "LemonChiffon", hex: "#FFFACD"},
        {name: "LightBlue", color: "LightBlue", hex: "#ADD8E6"},
        {name: "LightCoral", color: "LightCoral", hex: "#F08080"},
        {name: "LightCyan", color: "LightCyan", hex: "#E0FFFF"},
        {name: "LightGoldenRodYellow", color: "LightGoldenRodYellow", hex: "#FAFAD2"},
        {name: "LightGrey", color: "LightGrey", hex: "#D3D3D3"},
        {name: "LightGreen", color: "LightGreen", hex: "#90EE90"},
        {name: "LightPink", color: "LightPink", hex: "#FFB6C1"},
        {name: "LightSalmon", color: "LightSalmon", hex: "#FFA07A"},
        {name: "LightSeaGreen", color: "LightSeaGreen", hex: "#20B2AA"},
        {name: "LightSkyBlue", color: "LightSkyBlue", hex: "#87CEFA"},
        {name: "LightSlateGray", color: "LightSlateGray", hex: "#778899"},
        {name: "LightSteelBlue", color: "LightSteelBlue", hex: "#B0C4DE"},
        {name: "LightYellow", color: "LightYellow", hex: "#FFFFE0"},
        {name: "Lime", color: "Lime", hex: "#00FF00"},
        {name: "LimeGreen", color: "LimeGreen", hex: "#32CD32"},
        {name: "Linen", color: "Linen", hex: "#FAF0E6"},
        {name: "Magenta", color: "Magenta", hex: "#FF00FF"},
        {name: "Maroon", color: "Maroon", hex: "#800000"},
        {name: "MediumAquaMarine", color: "MediumAquaMarine", hex: "#66CDAA"},
        {name: "MediumBlue", color: "MediumBlue", hex: "#0000CD"},
        {name: "MediumOrchid", color: "MediumOrchid", hex: "#BA55D3"},
        {name: "MediumPurple", color: "MediumPurple", hex: "#9370D8"},
        {name: "MediumSeaGreen", color: "MediumSeaGreen", hex: "#3CB371"},
        {name: "MediumSlateBlue", color: "MediumSlateBlue", hex: "#7B68EE"},
        {name: "MediumSpringGreen", color: "MediumSpringGreen", hex: "#00FA9A"},
        {name: "MediumTurquoise", color: "MediumTurquoise", hex: "#48D1CC"},
        {name: "MediumVioletRed", color: "MediumVioletRed", hex: "#C71585"},
        {name: "MidnightBlue", color: "MidnightBlue", hex: "#191970"},
        {name: "MintCream", color: "MintCream", hex: "#F5FFFA"},
        {name: "MistyRose", color: "MistyRose", hex: "#FFE4E1"},
        {name: "Moccasin", color: "Moccasin", hex: "#FFE4B5"},
        {name: "NavajoWhite", color: "NavajoWhite", hex: "#FFDEAD"},
        {name: "Navy", color: "Navy", hex: "#000080"},
        {name: "OldLace", color: "OldLace", hex: "#FDF5E6"},
        {name: "Olive", color: "Olive", hex: "#808000"},
        {name: "OliveDrab", color: "OliveDrab", hex: "#6B8E23"},
        {name: "Orange", color: "Orange", hex: "#FFA500"},
        {name: "OrangeRed", color: "OrangeRed", hex: "#FF4500"},
        {name: "Orchid", color: "Orchid", hex: "#DA70D6"},
        {name: "PaleGoldenRod", color: "PaleGoldenRod", hex: "#EEE8AA"},
        {name: "PaleGreen", color: "PaleGreen", hex: "#98FB98"},
        {name: "PaleTurquoise", color: "PaleTurquoise", hex: "#AFEEEE"},
        {name: "PaleVioletRed", color: "PaleVioletRed", hex: "#D87093"},
        {name: "PapayaWhip", color: "PapayaWhip", hex: "#FFEFD5"},
        {name: "PeachPuff", color: "PeachPuff", hex: "#FFDAB9"},
        {name: "Peru", color: "Peru", hex: "#CD853F"},
        {name: "Pink", color: "Pink", hex: "#FFC0CB"},
        {name: "Plum", color: "Plum", hex: "#DDA0DD"},
        {name: "PowderBlue", color: "PowderBlue", hex: "#B0E0E6"},
        {name: "Purple", color: "Purple", hex: "#800080"},
        {name: "Red", color: "Red", hex: "#FF0000"},
        {name: "RosyBrown", color: "RosyBrown", hex: "#BC8F8F"},
        {name: "RoyalBlue", color: "RoyalBlue", hex: "#4169E1"},
        {name: "SaddleBrown", color: "SaddleBrown", hex: "#8B4513"},
        {name: "Salmon", color: "Salmon", hex: "#FA8072"},
        {name: "SandyBrown", color: "SandyBrown", hex: "#F4A460"},
        {name: "SeaGreen", color: "SeaGreen", hex: "#2E8B57"},
        {name: "SeaShell", color: "SeaShell", hex: "#FFF5EE"},
        {name: "Sienna", color: "Sienna", hex: "#A0522D"},
        {name: "Silver", color: "Silver", hex: "#C0C0C0"},
        {name: "SkyBlue", color: "SkyBlue", hex: "#87CEEB"},
        {name: "SlateBlue", color: "SlateBlue", hex: "#6A5ACD"},
        {name: "SlateGray", color: "SlateGray", hex: "#708090"},
        {name: "Snow", color: "Snow", hex: "#FFFAFA"},
        {name: "SpringGreen", color: "SpringGreen", hex: "#00FF7F"},
        {name: "SteelBlue", color: "SteelBlue", hex: "#4682B4"},
        {name: "Tan", color: "Tan", hex: "#D2B48C"},
        {name: "Teal", color: "Teal", hex: "#008080"},
        {name: "Thistle", color: "Thistle", hex: "#D8BFD8"},
        {name: "Tomato", color: "Tomato", hex: "#FF6347"},
        {name: "Turquoise", color: "Turquoise", hex: "#40E0D0"},
        {name: "Violet", color: "Violet", hex: "#EE82EE"},
        {name: "Wheat", color: "Wheat", hex: "#F5DEB3"},
        {name: "White", color: "White", hex: "#FFFFFF"},
        {name: "WhiteSmoke", color: "WhiteSmoke", hex: "#F5F5F5"},
        {name: "Yellow", color: "Yellow", hex: "#FFFF00"},
        {name: "YellowGreen", color: "YellowGreen", hex: "#9ACD32"},
        ];





