import React, {Component} from 'react';
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import {getTag} from "../../../actions/tagActions";
import QuestionViewerExpandable from "../questions/QuestionViewerExpandable";
import * as TEMPLATE from "../../../constants/templateConstants";
import ArgoToolbarHook from "../../common/ArgoToolbarHook";
import QuestionViewer from "../questions/QuestionViewer";
import Typography from "@mui/material/Typography";


class TagViewer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            openQuestionViewer: false,
            selectedQuestionInfoKey: ""
        };
    }

    componentDidMount() {
        if (this.props.id) {
            this.props.getTag(this.props.id);
        }
    }

    setStateCallback = (stateObj) => {
        this.setState(stateObj);
    }

    handleToolbarButtonClick = (event, fieldName, buttonTitle) => {

        switch (buttonTitle) {
            case "BACK":
                // Used on info icon button from question accordions
                this.setState({openQuestionViewer: false});
                break;
            // no default
        }
        event.preventDefault(); // Fix for the enter key propagating to the next focusable element
    }

    openQuestionViewer = () => {

        return (
            <div style={{height: "100%"}}>

                <ArgoToolbarHook
                    fileName="TagEditor"
                    fieldName="back"
                    buttonTitles={["BACK"]}
                    onClick={this.handleToolbarButtonClick}
                />

                <div style={{height: "100%", overflowY: "scroll"}}>
                    <QuestionViewer templateKey={this.state.selectedQuestionInfoKey}/>
                </div>
            </div>
        );
    };

    // -----------------------------------------------------------------------------------------------------------------
    // RENDER ----------------------------------------------------------------------------------------------------------
    // -----------------------------------------------------------------------------------------------------------------

    render() {

        if (this.state.openQuestionViewer) {
            return this.openQuestionViewer();
        }

        const template = (this.props.template !== null) ? this.props.template : {};

        return (
            template.hasOwnProperty("questionKeys") ?
                <div id="tag-viewer-container-div" style={{padding: "30px"}}>

                    <Typography variant="h6" component="div">
                        Tag Information:
                    </Typography>

                    <div>&nbsp;</div>

                    <div style={{display: "flex", width: "40%"}}>
                        <div id="tag-viewer-label-div" style={{float: "left", paddingBottom: "12px", fontSize: "20px", color: "silver"}}>Name:</div>

                        <div id="tag-viewer-spacer1-div" style={{float: "left", width: "20px"}}></div>

                        <div id="tag-viewer-name-div" style={{float: "left", paddingBottom: "12px", fontSize: "20px", fontWeight: "500"}}>{template.name}</div>

                    </div>

                    {/*// ===========================================================================================================*/}
                    {/*// ASSIGNED QUESTIONS LIST */}
                    {/*// ===========================================================================================================*/}

                    <QuestionViewerExpandable
                        parentType={TEMPLATE.TYPE.TAG}
                        parentTemplate={template}
                        questionKeys={template.questionKeys}
                        showIconButtonReorder={false}
                        showIconButtonEdit={false}
                        showIconButtonRemove={false}
                        setStateCallback={this.setStateCallback}
                    />

                </div>
                : ""
        );
    }
}

TagViewer.propTypes = {
    id: PropTypes.number,
};

function mapStateToProps(state, props) {
    return {
        template: state.tag.hasOwnProperty(props.id) ? state.tag[props.id] : {},
        questionTemplates: state.questionTemplate
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({getTag}, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(TagViewer);
