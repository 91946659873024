import React from 'react';
import PropTypes from "prop-types";
import IntegrationViewer from "./IntegrationViewer";
import ArgoToolbarHook from "../../common/ArgoToolbarHook";


const IntegrationReviewViewer = (props) => {
    return (
        <div style={{height: "100%"}}>

            <ArgoToolbarHook
                fileName="integration-review-viewer"
                fieldName="save-or-back"
                buttonTitles={["SAVE","BACK"]}
                onClick={props.handleToolbarButtonClick}
            />

            <div style={{height: "100%", overflowY: "scroll"}}>
                <IntegrationViewer integration={props.integration}/>
            </div>
        </div>
    );
};


IntegrationReviewViewer.propTypes = {
    integration: PropTypes.object,
    handleToolbarButtonClick: PropTypes.func
};

IntegrationReviewViewer.defaultProps = {
    integration: undefined,
};

export default IntegrationReviewViewer;
