import TemplateApi from "../api/TemplateApi";
import * as types from "../constants/actionConstants";
import {closeDrawer} from "./drawerActions";
import {setDefaultCategory} from "./questionnaireEditorActions";
import {initGetTemplateList} from "./templateListActions";
import * as TEMPLATE from "../constants/templateConstants";


export function getCategoryTemplate(questionKey, id) {
    let thunk = (dispatch)=>{

        if (id) {
            TemplateApi.getCategoryById(id).then((categoryTemplate) =>{
                dispatch({type: types.CATEGORY_TEMPLATE_GET_SUCCESS, categoryTemplate});
            });
        }
        else {
            TemplateApi.getCurrentCategory(questionKey).then((categoryTemplate) =>{
                dispatch({type: types.CATEGORY_TEMPLATE_GET_SUCCESS, categoryTemplate});
            });
        }
    };

    thunk.meta = {
        debounce: {
            time: 2000,
            key: id || questionKey,
            leading: true,
            trailing: false
        }
    };

    return thunk;
}

export function saveCategoryTemplate(categoryTemplateObject, persistDrawer = false, setAsDefault = false) {
    return (dispatch)=>{
        TemplateApi.updateCategoryTemplate(categoryTemplateObject).then((categoryTemplate) =>{
            dispatch({type: types.CATEGORY_TEMPLATE_UPDATE_SUCCESS , categoryTemplate});
            if (!persistDrawer) {
                dispatch(closeDrawer());
            }
            if (setAsDefault){
                dispatch(setDefaultCategory(categoryTemplate.templateKey));
            }
        });
    };
}

export function saveCategoryTemplateAndCloseDrawer(categoryTemplate, filter, versionsFilter, templateMode) {
    return (dispatch)=>{
        TemplateApi.updateCategoryTemplate(categoryTemplate).then((categoryTemplate) =>{
            dispatch({type: types.CATEGORY_TEMPLATE_UPDATE_SUCCESS , categoryTemplate});
            dispatch(closeDrawer());
            // Best to load category list page in all cases
            dispatch(initGetTemplateList(filter, TEMPLATE.TYPE.CATEGORY, TEMPLATE.MODE.CURRENT));
            if (templateMode === TEMPLATE.MODE.VERSIONS) {
                let tempVersionsFilter = {...versionsFilter};
                tempVersionsFilter.templateName = categoryTemplate.name;
                tempVersionsFilter.templateKey = categoryTemplate.templateKey;
                dispatch(initGetTemplateList(tempVersionsFilter, TEMPLATE.TYPE.CATEGORY, TEMPLATE.MODE.VERSIONS));
            }
        });
    };
}

export function createCategoryTemplate(categoryTemplate, persistDrawer = false, setAsDefault = false) {
    return (dispatch) => {
        TemplateApi.createCategoryTemplate(categoryTemplate).then((categoryTemplate) => {
            dispatch({type: types.CATEGORY_TEMPLATE_CREATE_SUCCESS, categoryTemplate});
            if (!persistDrawer) {
                dispatch(closeDrawer());
            }
            if (setAsDefault){
                dispatch(setDefaultCategory(categoryTemplate.templateKey));
            }
        });
    };
}

export function createCategoryTemplateAndCloseDrawer(categoryTemplate, filter, onSuccess = ()=>{}) {
    return (dispatch) => {
        TemplateApi.createCategoryTemplate(categoryTemplate).then((categoryTemplate) => {
            dispatch({type: types.CATEGORY_TEMPLATE_CREATE_SUCCESS, categoryTemplate});
            onSuccess();
            dispatch(closeDrawer());
            dispatch(initGetTemplateList(filter, TEMPLATE.TYPE.CATEGORY, TEMPLATE.MODE.CURRENT));
        });
    };
}

