import React, {Component} from 'react';
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import {closeDrawer} from "../../actions/drawerActions";
import IntegrationEditor from "../qd/integrations/IntegrationEditor";
import {createIntegrationAndCloseDrawer, updateIntegrationAndCloseDrawer} from "../../actions/integrationActions";
import {updatePage, updateSort} from "../../actions/pageAndSortActions";
import * as TEMPLATE from "../../constants/templateConstants";
import ArgoAppBar from "../common/ArgoAppBar";


class IntegrationCreateEditDrawer extends Component {

    closeDrawer = () => {
        this.props.closeDrawer();
    };

    handleRequestSave = (integration) => {

        if (typeof this.props.setLoadingState === "function") {
            this.props.setLoadingState(this.save, integration);
        }
        else {
            this.save(integration);
        }
    };

    save = async (integration) => {

        if (this.props.createMode) {
            this.props.createIntegrationAndCloseDrawer(integration, this.props.filter);
        }
        else {
            await this.props.updateIntegrationAndCloseDrawer(integration, this.props.filter.title, this.props.filter.updated, this.props.filter.integrationType,
                this.props.filter.pageNumber, this.props.filter.pageSize,
                this.props.filter.name, this.props.filter.direction);
        }
    };

    render() {
        return (
            <div style={{height: "100%"}}>
                <ArgoAppBar title={this.props.createMode ? "Create Integration" : "Edit Integration"} showMenuIconButton={false} noIcon={true} isDrawer={true}/>

                <IntegrationEditor
                    save={this.handleRequestSave}
                    cancel={this.closeDrawer}
                    id={this.props.id}
                />
            </div>
        );
    }
}

IntegrationCreateEditDrawer.defaultProps = {
    id: "",
    createMode: false
};

IntegrationCreateEditDrawer.propTypes = {
    id: PropTypes.number,
    saveIntrgration: PropTypes.func,
    filter: PropTypes.object,
    createMode: PropTypes.bool,
    setLoadingState: PropTypes.func,
};

function mapDispatchToProps(dispatch) {
    return bindActionCreators({closeDrawer, createIntegrationAndCloseDrawer, updateIntegrationAndCloseDrawer, updatePage, updateSort}, dispatch);
}

function mapStateToProps(state) {
    return {
        filter: state.filter[TEMPLATE.FILTER.all.integration] || {}
    };
}

const integrationCreateEditDrawerProps = {
    opensecondary: true,
    width: "50%",
    docked: false,
    anchor: "right"
};

export default connect(mapStateToProps, mapDispatchToProps)(IntegrationCreateEditDrawer);
export {integrationCreateEditDrawerProps};
