import React, {Component} from "react";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import {
    updateQuestionnaireTemplate,
    addQuestionnaireCategory,
    removeQuestionnaireCategory,
    reorderQuestionnaireCategory
} from "../../../actions/questionnaireEditorActions";
import {createCategoryTemplate, getCategoryTemplate} from "../../../actions/categoryTemplateActions";
import IconButton from "@mui/material/IconButton";
import RadioGroup from "@mui/material/RadioGroup";
import Radio from "@mui/material/Radio";
import InfoIcon from "@mui/icons-material/Info";
import CloseIcon from "@mui/icons-material/Close";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import CategoryViewer from "./CategoryViewer";
import CategoryEditor from "./CategoryEditor";
import style from "../../../blueTheme";
import * as TEMPLATE from "../../../constants/templateConstants";
import ArgoSelectExisting from "../../common/ArgoSelectExisting";
import ArgoRadioHook from "../../common/ArgoRadioHook";
import ArgoAppBar from "../../common/ArgoAppBar";
import ArgoToolbarHook from "../../common/ArgoToolbarHook";
import ArgoRadioGroupHook from "../../common/ArgoRadioGroupHook";


class CategorySearchAdd extends Component {
    constructor(props) {
        super(props);
        this.state = {
            reviewMode: false,
            createMode: false,
            categoryTemplateSearch: "",
            categoryDetail: null,
            categoryList: []
        };
    }

    componentDidMount() {
        this.props.questionnaireCategoryKeys.forEach(key => {
            if (!this.props.categoryTemplate[key]){
                this.props.getCategoryTemplate(key);
            }
        });
    }

    componentWillReceiveProps(nextProps) {
        this.setState({categoryList: nextProps.categoryList});
    }

    handleToolbarButtonClick = (event, fieldName, buttonTitle) => {
        switch (buttonTitle) {
            case "DONE":
                this.props.cancel();
                break;
            case "BACK":
                this.setState({categoryDetail: null});
                break;
            // no default
        }
    }

    pickCategory = (categoryTemplate) => {
        if (!this.props.questionnaireCategoryKeys.includes(categoryTemplate.templateKey)) {
            this.setState({categoryTemplateSearch: ""});
            this.props.addQuestionnaireCategory(categoryTemplate.templateKey);
            this.props.getCategoryTemplate(categoryTemplate.templateKey);
        }
    };

    onUpdateCategoryInput = (searchText) => {
        this.setState({categoryTemplateSearch: searchText});
    };

    clearCategoryInput = () => {
        this.setState({categoryTemplateSearch: ""});
    };

    openCategoryDetail = (templateKey) => {
        this.setState({categoryDetail: templateKey});
    };


    displayGroupDetail = () => {
        return (
            <div style={{height: "100%"}}>

                <ArgoToolbarHook
                    fileName="category-search-add"
                    fieldName="back"
                    buttonTitles={["BACK"]}
                    onClick={this.handleToolbarButtonClick}
                />

                <div style={{height: "100%", width: "100%", overflowY: "scroll"}}>
                    <CategoryViewer templateKey={this.state.categoryDetail}/>
                </div>
            </div>
        );
    };

    removeCategory = (index) => {
        this.props.removeQuestionnaireCategory(index);
    };


    saveNewCategory = (template) => {
        this.props.createCategoryTemplate(template, true);
        this.setState({createMode: false, categoryTemplateSearch: ""});
    };

    closeNewCategory = () => {
        this.setState({createMode: false, categoryTemplateSearch: ""});
    };

    toggleCreate = (event, value) => {
        let newState = {
            createMode: value,
            categoryTemplateSearch: (value)? this.state.categoryTemplateSearch: ""
        };

        this.setState(newState);
    };

    createModeSelector = () => {
        return (<ArgoRadioGroupHook
                fileName="category-search-add"
                fieldName="createMode"
                label="Is this an existing category?"
                value={this.state.createMode}
                onChange={this.toggleCreate}
                checked={true}
            />
        );
    };

    categoryDisplayRow = (categoryKey) => {
        let name = this.props.categoryTemplate[categoryKey] ? this.props.categoryTemplate[categoryKey].name : "";

        let categoryList = [];
        let rowRemoveFlag = false;
        for (let i = 0; i < this.state.categoryList.length; i++) {
            if (this.state.categoryList[i].templateKey !== categoryKey) {
                categoryList.push(this.state.categoryList[i]);  // CLONE this.state.questionList minus selected question
            }
            else
            {
                rowRemoveFlag = true;
            }
        }

        // Only set if there was a change to keep from getting react errors
        if (rowRemoveFlag) {
            this.setState({categoryList: categoryList});  // If there was a row removed then setState with new array of questions
        }

        return (
            <div>{name}</div>
        );
    };

    render() {
        if (this.state.reviewMode) {
            return <div>Add review components, but what is being reviewed?</div>;
        }

        if (this.state.createMode) {
            let template = {
                name: this.state.categoryTemplateSearch
            };

            return <CategoryEditor save={this.saveNewCategory}
                                   cancel={this.closeNewCategory}
                                   showCreateToggle={this.createModeSelector}
                                   createInitialTemplate={template}/>;
        }

        if (this.state.categoryDetail) {
            return this.displayGroupDetail();
        }

        let self = this;

        return (<div style={{height: "100%"}}>

            <ArgoToolbarHook
                fileName="category-search-add"
                fieldName="done"
                buttonTitles={["DONE"]}
                onClick={this.handleToolbarButtonClick}
            />

            <div style={{height: "100%", overflowY: "scroll"}}>
                <div style={{justifyContent: "space-between"}}>
                    <div style={{flexGrow: "2", paddingLeft: "32px", paddingRight: "32px"}}>
                        <div style={{
                            overflow: "hidden",
                            display: "flex",
                            alignSelf: "flex-start",
                            flexDirection: "column",
                            width: "100%",
                            minHeight: "min-content"
                        }}>
                            <div>
                                {this.createModeSelector()}
                            </div>

                            <ArgoSelectExisting
                                mode={TEMPLATE.TYPE.CATEGORY}
                                onAddTemplate={this.pickCategory.bind(this)}
                            />

                            {this.props.questionnaireCategoryKeys.map(function (categoryKey, index) {
                                return (
                                    <div key={categoryKey} style={{paddingTop: "16px", paddingLeft: "16px"}}>
                                        <div>
                                            <div>

                                                {(index !== 0) &&
                                                <IconButton id={"category-" + index + "arrow-up"} name={"category-" + index + "arrow-up"}
                                                            onClick={self.props.reorderQuestionnaireCategory.bind(self, true, index)}
                                                    iconStyle={{
                                                        backgroundColor: style.palette.primary2Color,
                                                        borderRadius: "50%"
                                                    }}>
                                                    <ArrowUpwardIcon color={style.palette.alternateTextColor}/>
                                                </IconButton>}
                                            </div>
                                            <div>
                                                {!(self.props.questionnaireCategoryKeys.length - 1 === index) &&
                                                <IconButton id={"category-" + index + "arrow-down"} name={"category-" + index + "arrow-down"}
                                                    onClick={self.props.reorderQuestionnaireCategory.bind(self, false, index)}
                                                    iconStyle={{
                                                        backgroundColor: style.palette.primary2Color,
                                                        borderRadius: "50%"
                                                    }}>
                                                    <ArrowDownwardIcon color={style.palette.alternateTextColor}/>
                                                </IconButton>}
                                            </div>
                                        </div>
                                        <div style={{
                                            display: "flex",
                                            alignSelf: "center",
                                            maxWidth: "70%",
                                            flexGrow: "2"
                                        }}>
                                            {self.categoryDisplayRow(categoryKey)}
                                        </div>
                                        <div style={{justifySelf: "flex-end", alignSelf: "center"}}>
                                            <IconButton id={"category-" + index + "close"} name={"category-" + index + "close"} onClick={self.removeQuestionnaireCategory.bind(self, index)}>
                                                <CloseIcon/>
                                            </IconButton>
                                            <IconButton id={"category-" + index + "info"} name={"category-" + index + "info"} onClick={self.openCategoryDetail.bind(self, categoryKey)}>
                                                <InfoIcon/>
                                            </IconButton>
                                        </div>
                                    </div>);
                            })}
                        </div>
                    </div>
                </div>
            </div>
        </div>);
    }
}

CategorySearchAdd.propTypes = {
    createCategoryTemplate: PropTypes.func,
    cancel: PropTypes.func,
    questionnaireTemplate: PropTypes.object,
    questionnaireCategoryKeys: PropTypes.array,
    categoryTemplate: PropTypes.object,
    categoryList: PropTypes.array,
    getCategoryTemplate: PropTypes.func
};

function mapStateToProps(state) {
    return {
        questionnaireTemplate: state.questionnaireEditor.template,
        questionnaireCategoryKeys: state.questionnaireEditor.categoryKeys,
        categoryList: state.categoryTemplateList,
        categoryTemplate: state.categoryTemplate
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        updateQuestionnaireTemplate,
        createCategoryTemplate,
        getCategoryTemplate,
        addQuestionnaireCategory,
        removeQuestionnaireCategory,
        reorderQuestionnaireCategory
    }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(CategorySearchAdd);
