import React, {Component} from 'react';
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import {getTag} from "../../../actions/tagActions";
import {
    initializeTagEditorCreate,
    initializeTagEditorEdit,
    updateTagTemplate,
    clearTagEditor,
    checkValue,
    checkTagExists,
    addTagQuestion,
    removeTagQuestion,
} from "../../../actions/tagEditorActions";
import {getQuestionTemplate} from "../../../actions/questionTemplateActions";
import {getTemplateListForSelectFilter} from "../../../actions/templateListActions";
import QuestionViewerExpandable from "../questions/QuestionViewerExpandable";
import QuestionViewer from "../questions/QuestionViewer";
import ArgoSelectExisting from "../../common/ArgoSelectExisting";
import ConfirmPrompt from "../../dialogs/ConfirmPrompt";
import ArgoToolbarHook from "../../common/ArgoToolbarHook";
import ArgoTextFieldHook from "../../common/ArgoTextFieldHook";
import * as validate from "../../../utilities/validate";
import * as TEMPLATE from "../../../constants/templateConstants";


class TagEditor extends Component {
    constructor(props) {
        super(props);
        this.state = {
            questionTemplateSearch: "",
            openTagReviewViewer: false,
            openQuestionViewer: false,
            selectedQuestionInfoKey: "",
            nameErrorText: " ",
            isLoading: false,
            toolbarReviewButtonDisabled: false,
            questionList: [],
            tagNameEditDialogOpen: false,
            originalTagName: props.template.name,
            questionRenderedArray: [],
            initialized: false
        };
    }

    componentDidMount() {
        if (this.props.id) {
            this.setState({isLoading: true});
            this.props.getTag(this.props.id);
        } else {
            this.props.initializeTagEditorCreate(this.props.template);
        }
    }

    componentWillReceiveProps(nextProps) {

        let isTemplateLoaded = (nextProps.tag && (nextProps.tag.id === nextProps.id));

        if (isTemplateLoaded && !this.state.initialized) {
            this.setState({
                isLoading: false,
                originalName: nextProps.templateOriginal.name,
                initialized: true
            });
            nextProps.initializeTagEditorEdit(nextProps.templateOriginal);
        }

        this.checkForErrorsOnScreen(nextProps);
    }

    componentWillUnmount() {
        this.props.clearTagEditor();
    }

    setStateCallback = (stateObj) => {
        this.setState(stateObj);
    }

    handleToolbarButtonClick = (event, fieldName, buttonTitle) => {

        switch (buttonTitle) {
            case "SAVE":

                let template = {
                    ...this.props.template,
                    questionKeys: [...this.props.questionKeys]
                };

                this.props.save(template);
                break;
            case "CANCEL":
                this.props.cancel();
                break;
            case "BACK":
                // Used on info icon button from question accordions
                this.setState({openQuestionViewer: false});
                break;
            // no default
        }
        event.preventDefault(); // Fix for the enter key propagating to the next focusable element
    }

    onTextChange = (event, fieldName) => {

        let value = event.target.value;

        // When the tag name changes must check to see if tag is not blank or already exists to toggle review button enable/disable
        this.handleChange(fieldName, value);
    };


    handleChange = (fieldName, value) => {
        let updatedTemplate = {...this.props.template};  // Clone template so name and/or related question templates can be changed

        updatedTemplate[fieldName] = value; // Change the name

        updatedTemplate.questionKeys = this.props.questionKeys;  // Update related questions by questions TemplateKey

        this.props.updateTagTemplate(updatedTemplate); // Update the search tag in DB

        if (fieldName === "name") {

            this.setState({toolbarReviewButtonDisabled: false}); // Disable the review button when the name changes. It will be re-enabled once the validation is complete.

            this.props.checkTagExists(updatedTemplate.name); // Check DB to see if search tag already exists
        }
    };

    handleTagTitleEditOnBlur = () => {
        const isEdit = !!this.props.id;

        if (isEdit && this.state.isLoading === false && this.state.originalName !== "") {
            if (this.props.template.name !== this.state.originalName) {
                this.setState({tagNameEditDialogOpen: true});
            }
        }
    };

    handleCloseTagNameEditConfirm = () => {
        this.setState({tagNameEditDialogOpen: false});
    };

    checkForErrorsOnScreen = (props) => {

        let nameErrorText = validate.templateNameUnique(props, "Name", props.template.title === undefined ? "name" : "title")

        this.setState({nameErrorText: nameErrorText, toolbarReviewButtonDisabled: (nameErrorText !== " ")});
    };

    addQuestionToTag = (questionTemplateKeyObj) => {

        let questionTemplateKey = questionTemplateKeyObj.templateKey;

        // questionKeys is modified if questions are added or removed
        if (!this.props.questionKeys.includes(questionTemplateKey)) {
            this.setState({questionTemplateSearch: ""});
            this.props.addTagQuestion(questionTemplateKey);
        }
        this.setState({questionTemplateSearch: ""});
    };

    onUpdateQuestionInput = (searchText) => {
        this.setState({questionTemplateSearch: searchText});
    };

    openQuestionViewer = () => {

        return (
            <div style={{height: "100%"}}>

                <ArgoToolbarHook
                    fileName="TagEditor"
                    fieldName="back"
                    buttonTitles={["BACK"]}
                    onClick={this.handleToolbarButtonClick}
                />

                <div style={{height: "100%", overflowY: "scroll"}}>
                    <QuestionViewer templateKey={this.state.selectedQuestionInfoKey}/>
                </div>
            </div>
        );
    };

    // -----------------------------------------------------------------------------------------------------------------
    // RENDER ----------------------------------------------------------------------------------------------------------
    // -----------------------------------------------------------------------------------------------------------------

    render() {

        if (this.state.isLoading) {
            //TODO place a loading screen here
            return null;
        }

        if (this.state.openQuestionViewer) {
            return this.openQuestionViewer();
        }

        const tag = (this.props.tag !== null) ? this.props.tag : {};

        return (
            this.props.createMode || tag.hasOwnProperty("questionKeys") ?
                <div id="TagEditor-page-container-div" style={{height: "100%"}}>

                    <ArgoToolbarHook
                        fileName="TagEditor"
                        fieldName="save-or-cancel"
                        leftButtonDisabled={this.state.toolbarReviewButtonDisabled}
                        onClick={this.handleToolbarButtonClick}
                    />

                    <div id="TagEditor-container-div1" style={{height: "100%", overflowY: "scroll"}}>

                        <div id="TagEditor-container-div2" style={{justifyContent: "space-between"}}>

                            <div id="TagEditor-container-div3" style={{flexGrow: "2", paddingLeft: "32px", paddingRight: "32px"}}>

                                <div id="TagEditor-spacer-div1" style={{height: "10px"}}>&nbsp;</div>

                                <div id="TagEditor-container-div4" style={{display: "flex", alignItems: "center", width: "100%"}}>

                                    <ArgoTextFieldHook
                                        fileName="TagEditor"
                                        fieldName="name"
                                        label="Name"
                                        value={this.props.template.name}
                                        onChange={this.onTextChange}
                                        onBlur={this.handleTagTitleEditOnBlur}
                                        maxLength={15}
                                        width="210px"
                                        errorText={this.state.nameErrorText}
                                    />

                                </div>

                                <div style={{height: "20px"}}>&nbsp;</div>

                                {/*// ===========================================================================================================*/}
                                {/*// ARGO SELECT EXISTING QUESTION TO ADD TO TAG */}
                                {/*// ===========================================================================================================*/}

                                {!this.props.createMode ?
                                    <div id="TagEditor-argo-select-existing-div" style={{paddingRight: "30px"}}>
                                        {tag.id !== null ? <ArgoSelectExisting mode={TEMPLATE.TYPE.QUESTION} onAddTemplate={this.addQuestionToTag}/> : ""}
                                    </div> : ""
                                }

                                <div style={{height: "20px"}}>&nbsp;</div>

                                {/*// ===========================================================================================================*/}
                                {/*// ASSIGNED QUESTIONS LIST */}
                                {/*// ===========================================================================================================*/}

                                {!this.props.createMode ?
                                    <QuestionViewerExpandable
                                        parentType={TEMPLATE.TYPE.TAG}
                                        parentTemplate={tag}
                                        questionKeys={this.props.questionKeys.length > 0 ? this.props.questionKeys : tag.questionKeys}
                                        showIconButtonReorder={false}
                                        showIconButtonEdit={false}
                                        setStateCallback={this.setStateCallback}
                                    /> : ""
                                }

                                {/*// ===========================================================================================================*/}
                                {/*// CONFIRM CHNAGE PROMPT */}
                                {/*// ===========================================================================================================*/}

                                <ConfirmPrompt open={this.state.tagNameEditDialogOpen}
                                               title={"Changing the tag name may have unintended consequences."}
                                               message={"Any questions that this tag is associated with in the system will now have a different meaning. " +
                                               "Are you sure you want to do this?"}
                                               ok={this.handleCloseTagNameEditConfirm}
                                               cancel={this.handleCloseTagNameEditConfirm}
                                />
                            </div>
                        </div>
                    </div>

                </div> : ""
        );
    }
}

TagEditor.propTypes = {
    id: PropTypes.number,
    createMode: PropTypes.bool,
    save: PropTypes.func,
    cancel: PropTypes.func,
};

function mapStateToProps(state, props) {
    // NOTE: when a question is removed questionKeys is updated.
    return {
        template: state.tagEditor.template, // Empty Template used for create
        questionKeys: state.tagEditor.questionKeys, // If questions are addeed or removed they are returned in questionKeys
        options: state.tagEditor.options,
        tag: state.tag.hasOwnProperty(props.id) ? state.tag[props.id] : {},
        templateOriginal: state.tag.hasOwnProperty(props.id) ? state.tag[props.id] : {},
        questionTemplates: state.questionTemplate
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        initializeTagEditorCreate,
        initializeTagEditorEdit,
        updateTagTemplate,
        clearTagEditor,
        getQuestionTemplate,
        getTemplateListForSelectFilter,
        getTag,
        checkValue,
        checkTagExists,
        addTagQuestion,
        removeTagQuestion,
    }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(TagEditor);
