//////////APP CONSTANTS//////////
export const SET_QD_SERVER_HOST = "SET_QD_SERVER_HOST";
export const SET_QD_SERVER_PORT = "SET_QD_SERVER_PORT";
export const BATCH_UPLOAD_POST = "BATCH_UPLOAD_POST";
export const BATCH_UPLOAD_SUCCESS = "BATCH_UPLOAD_SUCCESS";
export const BATCH_UPLOAD_FAIL = "BATCH_UPLOAD_FAIL";
export const BATCH_UPLOAD_CLEAR = "BATCH_UPLOAD_CLEAR";

//////////TEMPLATE CONSTANTS//////////
export const ADD_QUESTIONNAIRE_TEMPLATE = "ADD_QUESTIONNAIRE_TEMPLATE";
export const ADD_CATEGORY_TEMPLATE = "ADD_CATEGORY_TEMPLATE";
export const ADD_GROUP_TEMPLATE = "ADD_GROUP_TEMPLATE";
export const ADD_QUESTION_TEMPLATE = "ADD_QUESTION_TEMPLATE";

// INTEGRATION
export const INTEGRATION_GET_INIT = "INTEGRATION_GET_INIT";
export const INTEGRATION_GET_SUCCESS = "INTEGRATION_GET_SUCCESS";
export const INTEGRATION_UPDATE_SUCCESS = "INTEGRATION_UPDATE_SUCCESS";
export const INTEGRATION_DELETE_SUCCESS = "INTEGRATION_DELETE_SUCCESS";
export const INTEGRATION_CREATE_SUCCESS = "INTEGRATION_CREATE_SUCCESS";
export const INTEGRATION_LIST_CLEAR = "INTEGRATION_LIST_CLEAR";
export const INTEGRATION_LIST_GET_SUCCESS = "INTEGRATION_LIST_GET_SUCCESS";

// INTEGRATION LOGS
export const INTEGRATION_LOG_GET_INIT = "INTEGRATION_LOG_GET_INIT";
export const INTEGRATION_LOG_GET_SUCCESS = "INTEGRATION_LOG_GET_SUCCESS";
export const INTEGRATION_LOGS_LIST_CLEAR = "INTEGRATION_LOGS_LIST_CLEAR";
export const INTEGRATION_LOGS_LIST_GET_SUCCESS = "INTEGRATION_LOGS_LIST_GET_SUCCESS";

// ACTION TEMPLATES
export const ACTION_TEMPLATE_LIST_CLEAR = "ACTION_TEMPLATE_LIST_CLEAR";
export const ACTION_TEMPLATE_LIST_GET_SUCCESS = "ACTION_TEMPLATE_LIST_GET_SUCCESS";
export const ACTION_TEMPLATE_CURRENT_LIST_GET_SUCCESS = "ACTION_TEMPLATE_CURRENT_LIST_GET_SUCCESS";
export const ACTION_TEMPLATE_GET_SUCCESS = "ACTION_TEMPLATE_GET_SUCCESS";
export const ACTION_TEMPLATE_UPDATE_SUCCESS = "ACTION_TEMPLATE_UPDATE_SUCCESS";
export const ACTION_TEMPLATE_CREATE_SUCCESS = "ACTION_TEMPLATE_CREATE_SUCCESS";
export const ACTION_HISTORY_LIST_CLEAR = "ACTION_HISTORY_LIST_CLEAR";
export const ACTION_HISTORY_LIST_GET_SUCCESS = "ACTION_HISTORY_LIST_GET_SUCCESS";
export const ACTION_TEMPLATE_CLEAR = "ACTION_TEMPLATE_CLEAR";
export const ACTION_TEMPLATE_LIST_CURRENT_GET_SUCCESS = "ACTION_TEMPLATE_LIST_CURRENT_GET_SUCCESS";
export const ACTION_TEMPLATE_LIST_VERSIONS_GET_SUCCESS = "ACTION_TEMPLATE_LIST_VERSIONS_GET_SUCCESS";

// QUESTION TEMPLATES
export const QUESTION_TEMPLATE_GET_INIT = "QUESTION_TEMPLATE_GET_INIT";
export const QUESTION_TEMPLATE_GET_SUCCESS = "QUESTION_TEMPLATE_GET_SUCCESS";
export const QUESTION_TEMPLATE_UPDATE_SUCCESS = "QUESTION_TEMPLATE_UPDATE_SUCCESS";
export const QUESTION_TEMPLATE_TAGS_UPDATE_SUCCESS = "QUESTION_TEMPLATE_TAGS_UPDATE_SUCCESS";
export const QUESTION_TEMPLATE_LIST_GET_SUCCESS = "QUESTION_TEMPLATE_LIST_GET_SUCCESS";
export const QUESTION_HISTORY_LIST_CLEAR = "QUESTION_HISTORY_LIST_CLEAR";
export const QUESTION_HISTORY_LIST_GET_SUCCESS = "QUESTION_HISTORY_LIST_GET_SUCCESS";
export const QUESTION_TEMPLATE_CREATE_SUCCESS = "QUESTION_TEMPLATE_CREATE_SUCCESS";
export const QUESTION_TEMPLATE_LIST_CURRENT_GET_SUCCESS = "QUESTION_TEMPLATE_LIST_CURRENT_GET_SUCCESS";
export const QUESTION_TEMPLATE_LIST_VERSIONS_GET_SUCCESS = "QUESTION_TEMPLATE_LIST_VERSIONS_GET_SUCCESS";

// GROUP TEMPLATES
export const GROUP_TEMPLATE_GET_SUCCESS = "GROUP_TEMPLATE_GET_SUCCESS";
export const GROUP_TEMPLATE_UPDATE_SUCCESS = "GROUP_TEMPLATE_UPDATE_SUCCESS";
export const GROUP_TEMPLATE_LIST_GET_SUCCESS = "GROUP_TEMPLATE_LIST_SUCCESS";
export const GROUP_HISTORY_LIST_CLEAR = "GROUP_HISTORY_LIST_CLEAR";
export const GROUP_HISTORY_LIST_GET_SUCCESS = "GROUP_HISTORY_LIST_GET_SUCCESS";
export const GROUP_TEMPLATE_CREATE_SUCCESS = "GROUP_TEMPLATE_CREATE_SUCCESS";
export const GROUP_TEMPLATE_LIST_CURRENT_GET_SUCCESS = "GROUP_TEMPLATE_LIST_CURRENT_GET_SUCCESS";
export const GROUP_TEMPLATE_LIST_VERSIONS_GET_SUCCESS = "GROUP_TEMPLATE_LIST_VERSIONS_GET_SUCCESS";

// CATEGORY TEMPLATES
export const CATEGORY_TEMPLATE_GET_SUCCESS = "CATEGORY_TEMPLATE_GET_SUCCESS";
export const CATEGORY_TEMPLATE_UPDATE_SUCCESS = "CATEGORY_TEMPLATE_UPDATE_SUCCESS";
export const CATEGORY_HISTORY_LIST_CLEAR = "CATEGORY_HISTORY_LIST_CLEAR";
export const CATEGORY_HISTORY_LIST_GET_SUCCESS = "CATEGORY_HISTORY_LIST_GET_SUCCESS";
export const CATEGORY_TEMPLATE_CREATE_SUCCESS = "CATEGORY_TEMPLATE_CREATE_SUCCESS";
export const CATEGORY_TEMPLATE_LIST_GET_SUCCESS = "CATEGORY_TEMPLATE_LIST_GET_SUCCESS";
export const CATEGORY_TEMPLATE_LIST_CURRENT_GET_SUCCESS = "CATEGORY_TEMPLATE_LIST_CURRENT_GET_SUCCESS";
export const CATEGORY_TEMPLATE_LIST_VERSIONS_GET_SUCCESS = "CATEGORY_TEMPLATE_LIST_VERSIONS_GET_SUCCESS";

// QUESTIONNAIRE TEMPLATES
export const QUESTIONNAIRE_TEMPLATE_GET_SUCCESS = "QUESTIONNAIRE_TEMPLATE_GET_SUCCESS";
export const QUESTIONNAIRE_TEMPLATE_LIST_CURRENT_GET_SUCCESS = "QUESTIONNAIRE_TEMPLATE_LIST_CURRENT_GET_SUCCESS";
export const QUESTIONNAIRE_TEMPLATE_UPDATE_SUCCESS = "QUESTIONNAIRE_TEMPLATE_UPDATE_SUCCESS";
export const QUESTIONNAIRE_TEMPLATE_CREATE_SUCCESS = "QUESTIONNAIRE_TEMPLATE_CREATE_SUCCESS";
export const QUESTIONNAIRE_TEMPLATE_CLONE_SUCCESS = "QUESTIONNAIRE_TEMPLATE_CLONE_SUCCESS";
export const QUESTIONNAIRE_TEMPLATE_CLEAR = "QUESTIONNAIRE_TEMPLATE_CLEAR";
export const QUESTIONNAIRE_TEMPLATE_LIST_GET_SUCCESS = "QUESTIONNAIRE_TEMPLATE_LIST_SUCCESS";

export const QUESTIONNAIRE_REFERENCE_GET_SUCCESS = "QUESTIONNAIRE_REFERENCE_GET_SUCCESS";
export const QUESTIONNAIRE_REQUIRED_TOKENS_GET_SUCCESS = "QUESTIONNAIRE_REQUIRED_TOKENS_GET_SUCCESS"
export const QUESTIONNAIRE_REFERENCE_CLEAR = "QUESTIONNAIRE_REFERENCE_CLEAR";

export const QUESTIONNAIRE_TEMPLATE_INIT = "QUESTIONNAIRE_TEMPLATE_INIT";
export const QUESTIONNAIRE_TEMPLATE_INIT_CLEAR = "QUESTIONNAIRE_TEMPLATE_INIT_CLEAR";
export const QUESTIONNAIRE_TEMPLATE_CLONE = "QUESTIONNAIRE_TEMPLATE_CLONE";
export const QUESTIONNAIRE_TEMPLATE_CLONE_CLEAR = "QUESTIONNAIRE_TEMPLATE_CLONE_CLEAR";

// QUESTIONNAIRE TEMPLATE VERSIONS
export const QUESTIONNAIRE_VERSIONS_GET_SUCCESS = "QUESTIONNAIRE_VERSIONS_GET_SUCCESS";
export const QUESTIONNAIRE_TEMPLATE_LIST_VERSIONS_GET_SUCCESS = "QUESTIONNAIRE_TEMPLATE_LIST_VERSIONS_GET_SUCCESS";
export const QUESTIONNAIRE_VERSIONS_GET_FAILURE = "QUESTIONNAIRE_VERSIONS_GET_FAILURE";
export const QUESTIONNAIRE_TEMPLATE_VERSIONS_GET_FAILURE = "QUESTIONNAIRE_TEMPLATE_VERSIONS_GET_FAILURE";

// TEMPLATE VERSIONS
export const TEMPLATE_VERSIONS_LIST_CLEAR = "TEMPLATE_VERSIONS_LIST_CLEAR";
export const TEMPLATE_VERSIONS_LIST_GET_SUCCESS = "TEMPLATE_VERSIONS_LIST_GET_SUCCESS";

// QUESTIONNAIRE TEMPLATE NOTES
export const QUESTIONNAIRE_NOTES_GET_SUCCESS = "QUESTIONNAIRE_NOTES_GET_SUCCESS";

// QUESTIONNAIRE TEMPLATE STATISTICS (Single published version templete id and All published versions for a templete key
export const QUESTIONNAIRE_STATISTICS_GET_SUCCESS = "QUESTIONNAIRE_STATISTICS_GET_SUCCESS";
export const QUESTIONNAIRE_STATISTICS_GET_ALL_SUCCESS = "QUESTIONNAIRE_STATISTICS_GET_ALL_SUCCESS";

// QUESTIONNAIRE TEMPLATE OUTSTANDING COUNT
export const QUESTIONNAIRE_OUTSTANDING_GET_SUCCESS = "QUESTIONNAIRE_OUTSTANDING_GET_SUCCESS";

// CREATE ACTION DRAWER
export const CREATE_ACTION_DRAWER_OPEN = "CREATE_ACTION_DRAWER_OPEN";
export const CREATE_ACTION_DRAWER_CLOSE = "CREATE_ACTION_DRAWER_CLOSE";

//NOTIFICATION ACTIONS
export const NOTIFICATION_OPEN = "NOTIFICATION_OPEN";
export const NOTIFICATION_CLOSED = "NOTIFICATION_CLOSED";

//DRAWER ACTIONS
export const OPEN_DRAWER = "OPEN_DRAWER";
export const CLOSE_DRAWER = "CLOSE_DRAWER";

//PUBLISH ACTIONS
export const PUBLISH_SUCCESS = "PUBLISH_SUCCESS";
export const PUBLISH_FAIL = "PUBLISH_FAIL";

//RETIRE ACTIONS
export const RETIRE_SUCCESS = "RETIRE_SUCCESS";
export const RETIRE_FAIL = "RETIRE_FAIL";

//DELETE ACTIONS
export const DELETE_SUCCESS = "DELETE_SUCCESS";
export const DELETE_FAIL = "DELETE_FAIL";

//SCORING ACTIONS
export const GET_QUESTIONNAIRE_GRAPH_SUCCESS = "GET_QUESTIONNAIRE_GRAPH_SUCCESS";
export const GET_QUESTIONNAIRE_GRAPH_FAILURE = "GET_QUESTIONNAIRE_GRAPH_FAILURE";
export const SCORE_QUESTIONNAIRE_SUCCESS = "SCORE_QUESTIONNAIRE_SUCCESS";
export const SCORE_QUESTIONNAIRE_FAILURE = "SCORE_QUESTIONNAIRE_FAILURE";
export const QUESTIONNAIRE_SCORING_TEMPLATE_CLEAR = "QUESTIONNAIRE_SCORING_TEMPLATE_CLEAR";


//CHANGE DATE ACTIONS
export const CHANGE_DATE_SUCCESS = "CHANGE_DATE_SUCCESS";
export const CHANGE_DATE_FAIL = "CHANGE_DATE_FAIL";

//SCORING UPDATE ACTIONS
export const SCORING_UPDATE_SUCCESS = "SCORING_UPDATE_SUCCESS";
export const  SCORING_UPDATE_FAIL = " SCORING_UPDATE_FAIL";


//ARGO SELECT EXISTING COMPONENT
export const GET_SELECT_EXISTING_DDLB_LIST = "GET_SELECT_EXISTING_DDLB_LIST";
export const CLEAR_SELECT_EXISTING_DDLB_LIST = "CLEAR_SELECT_EXISTING_DDLB_LIST";


//QUESTION EDITOR
export const QUESTION_EDITOR_INITIALIZE = "QUESTION_EDITOR_INITIALIZE";
export const QUESTION_EDITOR_UPDATE_TEMPLATE = "QUESTION_EDITOR_UPDATE_TEMPLATE";
export const QUESTION_EDITOR_CLEAR = "QUESTION_EDITOR_CLEAR";
export const QUESTION_EDITOR_UPDATE_EDITOR = "QUESTION_EDITOR_UPDATE_EDITOR";
export const QUESTION_EDITOR_VALUE_EXISTS = "QUESTION_EDITOR_VALUE_EXISTS";
export const QUESTION_EDITOR_CHECK_REFERENCE = "QUESTION_EDITOR_CHECK_REFERENCE";
export const QUESTION_EDITOR_CHECK_VERSION = "QUESTION_EDITOR_CHECK_VERSION";
export const QUESTION_EDITOR_VALUE_VALIDATING = "QUESTION_EDITOR_VALUE_VALIDATING";
export const QUESTION_EDITOR_ADD_GROUP = "QUESTION_EDITOR_ADD_GROUP";
export const QUESTION_EDITOR_ADD_ACTION = "QUESTION_EDITOR_ADD_ACTION";

//ANSWER EDITOR
export const ANSWER_EDITOR_ADD_QUESTION_ANSWER = "ANSWER_EDITOR_ADD_QUESTION_ANSWER";
export const ANSWER_EDITOR_UPDATE_QUESTION_ANSWER = "ANSWER_EDITOR_UPDATE_QUESTION_ANSWER";
export const ANSWER_EDITOR_REMOVE_QUESTION_ANSWER = "ANSWER_EDITOR_REMOVE_QUESTION_ANSWER";
export const ANSWER_EDITOR_REORDER_QUESTION_ANSWER = "ANSWER_EDITOR_REORDER_QUESTION_ANSWER";

export const ANSWER_EDITOR_ADD_GROUP_ANSWER = "ANSWER_EDITOR_ADD_GROUP_ANSWER";
export const ANSWER_EDITOR_UPDATE_GROUP_ANSWER = "ANSWER_EDITOR_UPDATE_GROUP_ANSWER";
export const ANSWER_EDITOR_REMOVE_GROUP_ANSWER = "ANSWER_EDITOR_REMOVE_GROUP_ANSWER";
export const ANSWER_EDITOR_REORDER_GROUP_ANSWER = "ANSWER_EDITOR_REORDER_GROUP_ANSWER";


export const ACTION_TYPES_GET_LIST_SUCCESS = "ACTION_TYPES_GET_LIST_SUCCESS";

export const GROUP_EDITOR_INITIALIZE = "GROUP_EDITOR_INITIALIZE";
export const GROUP_EDITOR_UPDATE = "GROUP_EDITOR_UPDATE";
export const GROUP_EDITOR_UPDATE_TYPE = "GROUP_EDITOR_UPDATE_TYPE";
export const GROUP_EDITOR_CLEAR = "GROUP_EDITOR_CLEAR";
export const GROUP_EDITOR_ADD_QUESTION = "GROUP_EDITOR_ADD_QUESTION";
export const GROUP_EDITOR_REMOVE_QUESTION = "GROUP_EDITOR_REMOVE_QUESTION";
export const GROUP_EDITOR_REORDER = "GROUP_EDITOR_REORDER";
export const GROUP_EDITOR_VALUE_EXISTS = "GROUP_EDITOR_VALUE_EXISTS";
export const GROUP_EDITOR_VALUE_VALIDATING = "GROUP_EDITOR_VALUE_VALIDATING";


export const CATEGORY_EDITOR_INITIALIZE = "CATEGORY_EDITOR_INITIALIZE";
export const CATEGORY_EDITOR_UPDATE = "CATEGORY_EDITOR_UPDATE";
export const CATEGORY_EDITOR_CLEAR = "CATEGORY_EDITOR_CLEAR";
export const CATEGORY_EDITOR_ADD_GROUP = "CATEGORY_EDITOR_ADD_GROUP";
export const CATEGORY_EDITOR_REMOVE_GROUP = "CATEGORY_EDITOR_REMOVE_GROUP";
export const CATEGORY_EDITOR_REORDER = "CATEGORY_EDITOR_REORDER";
export const CATEGORY_EDITOR_VALUE_EXISTS = "CATEGORY_EDITOR_VALUE_EXISTS";
export const CATEGORY_EDITOR_VALUE_VALIDATING = "CATEGORY_EDITOR_VALUE_VALIDATING";

export const QUESTIONNAIRE_EDITOR_INITIALIZE = "QUESTIONNAIRE_EDITOR_INITIALIZE";
export const QUESTIONNAIRE_EDITOR_UPDATE = "QUESTIONNAIRE_EDITOR_UPDATE";
export const QUESTIONNAIRE_EDITOR_CLEAR = "QUESTIONNAIRE_EDITOR_CLEAR";
export const QUESTIONNAIRE_EDITOR_ADD_CATEGORY = "QUESTIONNAIRE_EDITOR_ADD_CATEGORY";
export const QUESTIONNAIRE_EDITOR_REMOVE_CATEGORY = "QUESTIONNAIRE_EDITOR_REMOVE_CATEGORY";
export const QUESTIONNAIRE_EDITOR_REORDER = "QUESTIONNAIRE_EDITOR_REORDER";
export const QUESTIONNAIRE_EDITOR_SET_DEFAULT_CATEGORY = "QUESTIONNAIRE_EDITOR_SET_DEFAULT_CATEGORY";
export const QUESTIONNAIRE_EDITOR_ADD_QUESTION = "QUESTIONNAIRE_EDITOR_ADD_QUESTION";
export const QUESTIONNAIRE_EDITOR_REMOVE_QUESTION = "QUESTIONNAIRE_EDITOR_REMOVE_QUESTION";
export const QUESTIONNAIRE_EDITOR_REORDER_QUESTION = "QUESTIONNAIRE_EDITOR_REORDER_QUESTION";
export const QUESTIONNAIRE_EDITOR_VALUE_EXISTS = "QUESTIONNAIRE_EDITOR_VALUE_EXISTS";
export const QUESTIONNAIRE_NAME_QUALIFIER_EXISTS = "QUESTIONNAIRE_NAME_QUALIFIER_EXISTS";
export const QUESTIONNAIRE_EDITOR_SET_TYPE = "QUESTIONNAIRE_EDITOR_SET_TYPE";

export const ACTION_EDITOR_INITIALIZE = "ACTION_EDITOR_INITIALIZE";
export const ACTION_EDITOR_UPDATE = "ACTION_EDITOR_UPDATE";
export const ACTION_EDITOR_CLEAR = "ACTION_EDITOR_CLEAR";
export const ACTION_EDITOR_CLEAR_TEMPLATE = "ACTION_EDITOR_CLEAR_TEMPLATE";
export const ACTION_EDITOR_VALUE_EXISTS = "ACTION_EDITOR_VALUE_EXISTS";
export const ACTION_EDITOR_VALUE_VALIDATING = "ACTION_EDITOR_VALUE_VALIDATING";

export const INTEGRATION_EDITOR_INITIALIZE = "INTEGRATION_EDITOR_INITIALIZE";
export const INTEGRATION_EDITOR_CHECK_NAME_FOR_DUP = "INTEGRATION_EDITOR_CHECK_NAME_FOR_DUP";
export const INTEGRATION_EDITOR_UPDATE_STORE = "INTEGRATION_EDITOR_UPDATE_STORE";
export const INTEGRATION_EDITOR_ADD = "INTEGRATION_EDITOR_ADD";
export const INTEGRATION_EDITOR_CLEAR = "INTEGRATION_EDITOR_CLEAR";
export const INTEGRATION_EDITOR_VALUE_EXISTS = "INTEGRATION_EDITOR_VALUE_EXISTS";
export const INTEGRATION_EDITOR_VALUE_VALIDATING = "INTEGRATION_EDITOR_VALUE_VALIDATING";
export const INTEGRATION_EDITOR_GET_INIT = "INTEGRATION_EDITOR_GET_INIT";
export const INTEGRATION_EDITOR_GET_SUCCESS = "INTEGRATION_EDITOR_GET_SUCCESS";


//SYSTEM Settings
export const SET_SYSTEM_SETTING = "SET_SYSTEM_SETTING";

export const REFERENCE_LOAD_SUCCESS = "REFERENCE_LOAD_SUCCESS";
export const REFERENCE_CLEAR = "REFERENCE_CLEAR";

export const SET_FILTER = "SET_FILTER";
export const CLEAR_FILTER = "CLEAR_FILTER";

export const TOKEN_SET = "TOKEN_SET";
export const TOKEN_GET = "TOKEN_GET";
export const TOKEN_CLEAR = "TOKEN_CLEAR";
export const TOKEN_REJECT_LOGIN = "TOKEN_REJECT_LOGIN";
export const TOKEN_INITIALIZE = "TOKEN_INITIALIZE";

// TAG ACTIONS
export const TAG_GET_INIT = "TAG_GET_INIT";
export const TAG_GET_SUCCESS = "TAG_GET_SUCCESS";
export const TAG_UPDATE_SUCCESS = "TAG_UPDATE_SUCCESS";
export const TAG_CREATE_SUCCESS = "TAG_CREATE_SUCCESS";
export const TAG_LIST_GET_SUCCESS = "TAG_LIST_GET_SUCCESS";
export const TAG_DDLB_GET_SUCCESS = "TAG_DDLB_GET_SUCCESS";
export const TAG_LIST_CLEAR= "TAG_LIST_CLEAR";

export const TAG_EDITOR_INITIALIZE = "TAG_EDITOR_INITIALIZE";
export const TAG_EDITOR_UPDATE = "TAG_EDITOR_UPDATE";
export const TAG_EDITOR_CLEAR = "TAG_EDITOR_CLEAR";
export const TAG_EDITOR_ADD_QUESTION = "TAG_EDITOR_ADD_QUESTION";
export const TAG_EDITOR_REMOVE_QUESTION = "TAG_EDITOR_REMOVE_QUESTION";
export const TAG_EDITOR_VALUE_EXISTS = "TAG_EDITOR_VALUE_EXISTS";
export const TAG_EDITOR_VALUE_VALIDATING = "TAG_EDITOR_VALUE_VALIDATING";

export const PAGE_CHANGE = "PAGE_CHANGE";
export const PAGE_CHANGE_SIZE_ALL_LISTS = "PAGE_CHANGE_SIZE_ALL_LISTS";
export const SORT_CHANGE = "SORT_CHANGE";

export const UPDATE_PAGE_SORT_AND_FILTER = "UPDATE_PAGE_SORT_AND_FILTER";


// METADATA_DEFINITION ACTIONS
export const METADATA_DEFINITION_LIST_GET_INIT = "METADATA_DEFINITION_LIST_GET_INIT";
export const METADATA_DEFINITION_LIST_GET_SUCCESS = "METADATA_DEFINITION_LIST_GET_SUCCESS";

export const ANSWER_SHOW_GROUP_SUCCESS = "ANSWER_SHOW_GROUP_SUCCESS";
export const ANSWER_SHOW_GROUP_CLEAR = "ANSWER_SHOW_GROUP_CLEAR"

