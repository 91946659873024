import React, {Component} from 'react';
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import {closeDrawer} from "../../actions/drawerActions";
import QuestionEditor from "../qd/questions/QuestionEditor";
import {createQuestionTemplateAndCloseDrawer, saveQuestionTemplateAndCloseDrawer} from "../../actions/questionTemplateActions";
import {QD_URL} from "../../constants/systemConstants";
import * as TEMPLATE from "../../constants/templateConstants";
import ArgoAppBar from "../common/ArgoAppBar";
import {withRouter} from "../../utilities/withRouter";
import ActionEditor from "../qd/actions/ActionEditor";


const QUESTION_DEPTH = 0;


class QuestionCreateEditDrawer extends Component {

    closeDrawer = (e) => {
        this.props.closeDrawer();
    };

    handleRequestSave = (questionTemplate) => {
        if ((typeof this.props.setLoadingState === "function") && (this.props.templateMode !== "versions")) {
            this.props.setLoadingState(this.save, questionTemplate);
        }
        else {
            this.save(questionTemplate);
        }
    };

    save = (questionTemplate) => {
        if (this.props.createMode) {
            this.props.createQuestionTemplateAndCloseDrawer(questionTemplate, QUESTION_DEPTH, this.props.versionsFilter, this.onCreateSuccess);
        }
        else {
            this.props.saveQuestionTemplateAndCloseDrawer(questionTemplate, this.props.filter, this.props.versionsFilter, this.props.templateMode);
        }

    };

    onCreateSuccess = () => {
        <withRouter navigate={QD_URL + "/questions"}/>;
    };

    render() {

        return (
            <div style={{height: "100%"}}>
                <ArgoAppBar title={this.props.createMode ? "Create Question" : "Edit Question"} showMenuIconButton={false} noIcon={true} isDrawer={true}/>

                <QuestionEditor
                    createMode={this.props.createMode}
                    cancel={this.closeDrawer}
                    save={this.handleRequestSave}
                    questionDepth={QUESTION_DEPTH}
                    row={this.props.row}
                    templateKey={this.props.templateKey}
                    templateId={this.props.templateId}
                    templateMode={this.props.templateMode}
                />
            </div>
        );
    }
}

QuestionCreateEditDrawer.defaultProps = {
    createMode: false,
    templateKey: "",
    templateId: null,
    templateMode: "",
};

QuestionCreateEditDrawer.propTypes = {
    createMode: PropTypes.bool,
    setLoadingState: PropTypes.func,
    row: PropTypes.object,
    templateKey: PropTypes.string,
    templateId: PropTypes.number,
    templateMode: PropTypes.string,
    filter: PropTypes.object
};


function mapDispatchToProps(dispatch) {
    return bindActionCreators({closeDrawer, createQuestionTemplateAndCloseDrawer, saveQuestionTemplateAndCloseDrawer}, dispatch);
}

function mapStateToProps(state) {
    return {versionsFilter: state.filter[TEMPLATE.FILTER.current.question]};
}

const questionCreateEditDrawerProps = {
    opensecondary: true,
    width: "90%",
    docked: false,
    anchor: "right",
    setLoadingState: PropTypes.func,
};

export default connect(mapStateToProps, mapDispatchToProps)(QuestionCreateEditDrawer);
export {questionCreateEditDrawerProps};
