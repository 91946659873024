import React, {Component} from "react";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import TextField from "@mui/material/TextField";
import {
    initializeEditorCreate,
    initializeEditorUpdate,
    updateTemplate,
    clearEditor,
    checkValue
} from "../../../actions/actionEditorActions";
import {getActionTemplate, clearActionTemplate} from "../../../actions/actionTemplateActions";
import {getActionTypesList} from "../../../actions/questionnaireActionTypes";
import ActionReviewViewer from "./ActionReviewViewer";
import ArgoMetadataEditor from "../../common/ArgoMetadataEditor";
import * as metadataTemplateTypes from "../../../constants/metadataTemplateTypes";
import ConfirmPrompt from "../../dialogs/ConfirmPrompt";
import ArgoToolbarHook from "../../common/ArgoToolbarHook";
import ArgoTextFieldHook from "../../common/ArgoTextFieldHook";
import * as validate from "../../../utilities/validate";
import ArgoTextFieldSelectHook from "../../common/ArgoTextFieldSelectHook";
import ArgoAutocompleteWithSearchHook from "../../common/ArgoAutocompleteWithSearchHook";
import ArgoSelectWithRenderValueHook from "../../common/ArgoSelectWithRenderValueHook";
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import NativeSelect from '@mui/material/NativeSelect';
import StopIcon from '@mui/icons-material/Stop';


class ActionEditor extends Component {
    constructor(props) {
        super(props);
        this.state = {
            nameErrorText: " ",
            isLoading: false,
            toolbarSaveButtonDisabled: true,
            toolbarErrorText: "",
            //metadataErrorText: " ",
            openActionNameEditDialog: false,
            originalName: props.template.name,
            actionType: ""
        };
    }

    componentDidMount() {

        if (this.props.templateKey || this.props.templateId) {

            if (this.props.actionTemplate[this.props.templateKey] && (this.props.actionTemplate[this.props.templateKey].id === this.props.templateId)) {

                this.props.initializeEditorUpdate(this.props.actionTemplate[this.props.templateKey]);
            } else if (this.props.templateKey) {
                this.setState({isLoading: true});
                this.props.getActionTemplate(this.props.templateKey, this.props.templateId);
            }
        } else {
            this.props.initializeEditorCreate();
        }

        this.props.getActionTypesList();
    }

    componentWillReceiveProps(nextProps) {

        // if templateOriginal is loaded and the templateKey matches the current templateKey and is different from the loaded template (check id here?)
        let isTemplateLoaded = (nextProps.templateOriginal && nextProps.templateOriginal.templateKey === nextProps.templateKey && nextProps.template.templateKey !== nextProps.templateKey);

        // The loaded template is stale and the correct template is loaded
        let editTemplateReady = nextProps.template.id !== nextProps.templateId &&
            (nextProps.actionTemplate[nextProps.templateKey] && nextProps.actionTemplate[nextProps.templateKey].id === nextProps.templateId);

        if (isTemplateLoaded && editTemplateReady) {
            this.setState({
                isLoading: false,
                originalName: nextProps.templateOriginal.name
            });
            nextProps.initializeEditorUpdate(nextProps.templateOriginal);
        }

        this.checkForErrorsOnScreen(nextProps);
    }

    componentWillUnmount() {
        this.props.clearActionTemplate();
        this.props.clearEditor();
    }

    handleToolbarButtonClick = (event, fieldName, buttonTitle) => {

        switch (buttonTitle) {
            case "REVIEW":
                //debugger; //ADP
                // this.setState({openActionReviewViewer: true});
                break;
            case "CANCEL":
                this.props.cancel();
                break;
            case "SAVE":
                let template = {...this.props.template};
                this.props.save(template);
                break;
            case "BACK":
                //debugger; //ADP
                // this.setState({openActionReviewViewer: false});
                break;
            // no default
        }
        event.preventDefault(); // Fix for the enter key propagating to the next focusable element
    }

    onTextChange = (event, fieldName) => {

        let value = event.target.value;

        this.handleChange(event, fieldName, value);
    };

    onSelectChange = (event, fieldName, menuItemText) => {

        let value = event.target.value;
        this.setState({actionType: value});
        this.handleChange(event, fieldName, value);
    }

    handleChange = (event, fieldName, value) => {

        let updatedTemplate = {...this.props.template};

        updatedTemplate[fieldName] = value;

        this.props.updateTemplate(updatedTemplate);

        if (fieldName === "name") {
            // Disable the review button when the header changes. It will be re-enabled once the validation is complete.
            this.setState({toolbarSaveButtonDisabled: true}, this.props.checkValue({name: updatedTemplate.name, templateKey: updatedTemplate.templateKey}));
        }
    };

    onBlurActionName = (event, fieldName) => {

        const isEdit = this.props.template.templateKey !== "";

        if (isEdit && this.state.isLoading === false && this.state.originalName !== "") {
            if (this.props.template.name !== this.state.originalName) {
                this.setState({openActionNameEditDialog: true});
            }
        }
    };

    checkForErrorsOnScreen = (props) => {

        let nameErrorText = validate.templateNameUnique(props, "Name")

        this.setState({nameErrorText: nameErrorText, toolbarSaveButtonDisabled: (nameErrorText !== " ")});
    };

    // Note: Not sure actions need metadata, so currently commented out
    // handleMetaChange = (metadata) => {
    //     let updatedTemplate = {...this.props.template};
    //     updatedTemplate.metadata = metadata;
    //     this.props.updateTemplate(updatedTemplate, this.props.questionDepth);
    // };

    handleCloseActionNameEditCancel = () => {
        this.setState({openActionNameEditDialog: false});
        this.props.clearEditor();
    };

    handleCloseActionNameEditConfirm = () => {
        this.setState({openActionNameEditDialog: false});
    };

    render() {

        if (this.state.isLoading) {
            //TODO add a loading screen here
            return null;
        }

        return (
            <div id="ActionEditor-page-container-div" style={{height: "100%"}}>

                <ArgoToolbarHook
                    fileName="ActionEditor"
                    fieldName="save-or-cancel"
                    leftButtonDisabled={this.state.toolbarSaveButtonDisabled}
                    toolbarErrorText={this.state.toolbarErrorText}
                    onClick={this.handleToolbarButtonClick}
                />

                <div id="ActionEditor-container-div1" style={{height: "100%", overflowY: "scroll"}}>

                        <div id="ActionEditor-container-div2" style={{flexGrow: "2", paddingLeft: "32px", paddingRight: "32px"}}>

                            <div id="ActionEditor-spacer-div1" style={{height: "10px"}}>&nbsp;</div>

                                <ArgoTextFieldHook
                                    fileName="ActionEditor"
                                    fieldName="name"
                                    label="Name"
                                    value={this.props.template.name}
                                    onChange={this.onTextChange}
                                    onBlur={this.onBlurActionName}
                                    maxLength={255}
                                    multiline={true}
                                    errorText={this.state.nameErrorText}
                                />

                                <div>&nbsp;</div>

                                <ArgoTextFieldHook
                                    fileName="ActionEditor"
                                    fieldName="description"
                                    label="Description/Instructions"
                                    value={this.props.template.description}
                                    onChange={this.onTextChange}
                                    maxLength={255}
                                    multiline={true}
                                />

                                <div>&nbsp;</div>

                                <ArgoTextFieldSelectHook
                                    fileName="ActionEditor"
                                    fieldName="type"
                                    label="Action type"
                                    value={this.state.actionType}
                                    onChange={this.onSelectChange}
                                    menuItems={this.props.actionTypeList}
                                    useTextForValue={true}
                                />

                                {/*<div>Not sure actions need metadata so blocking for now</div>*/}
                                {/*<div style={{width: "100%", paddingBottom: "40px"}}>*/}
                                {/*    <ArgoMetadataEditor*/}
                                {/*        entityType={metadataTemplateTypes.ActionTemplate}*/}
                                {/*        onChange={this.handleMetaChange}*/}
                                {/*        metadata={this.props.template.metadata}*/}
                                {/*        errorText={this.state.metadataErrorText}*/}
                                {/*        questionNumber={0}*/}
                                {/*    />*/}
                                {/*</div>*/}

                                <ConfirmPrompt open={this.state.openActionNameEditDialog}
                                               title={"Changing the action name may have unintended consequences."}
                                               message={"Any answer options that this action is associated with in the system will now have a different meaning. " +
                                               "This will also affect the admin when attempting to select the action from a list. " +
                                               "Are you sure you want to do this?"}
                                               ok={this.handleCloseActionNameEditConfirm}
                                               cancel={this.handleCloseActionNameEditCancel}
                                />
                    </div>
                </div>
            </div>
        );
    }
}


ActionEditor.defaultProps = {
    createMode: false
};

ActionEditor.propTypes = {
    createMode: PropTypes.string,
    templateKey: PropTypes.string,
    templateId: PropTypes.number,
    save: PropTypes.func,
    cancel: PropTypes.func,
    showCreateToggle: PropTypes.func,
};

function mapStateToProps(state, props) {
    return {
        template: state.actionEditor.template,
        options: state.actionEditor.options,
        actionTypeList: state.actionTypeList,
        actionTemplate: state.actionTemplate,
        templateOriginal: state.actionTemplate[props.templateKey]
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        initializeEditorCreate,
        initializeEditorUpdate,
        updateTemplate,
        clearEditor,
        clearActionTemplate,
        getActionTemplate,
        getActionTypesList,
        checkValue
    }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(ActionEditor);
