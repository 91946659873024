import React, {Component} from 'react';
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import Chip from "@mui/material/Chip";
import Avatar from "@mui/material/Avatar";
import {updateQuestionTemplate} from "../../../actions/questionEditorActions";
import {getTagList} from "../../../actions/tagActions";
import {checkValue} from "../../../actions/tagEditorActions";
import style from "../../../blueTheme";
import ArgoRadioGroupHook from "../../common/ArgoRadioGroupHook";
import ArgoTextFieldHook from "../../common/ArgoTextFieldHook";
import ArgoButtonHook from "../../common/ArgoButtonHook";
import ArgoAutocompleteWithSearchHook2 from "../../common/ArgoAutocompleteWithSearchHook2";
import ArgoChipHook from "../../common/ArgoChipHook";
import ArgoSelectHook from "../../common/ArgoSelectHook";
import ArgoSelectHook2 from "../../common/ArgoSelectHook2";
import ArgoTextFieldSelectHook from "../../common/ArgoTextFieldSelectHook";


class TagSelectOrCreate extends Component {
    constructor(props) {
        super(props);
        this.state={
            tagSelectValue: "",
            tagName: "",
            tagNameErrorText: "",
            isExistingTag: "0",
            tagList: [],
            radioChecked: true
        };
    }

    componentDidMount(){
        this.props.getTagList({pageNumber: 0, pageSize: 100, name: "Title", direction: "asc"}, this.props.filter);
    }

    componentWillReceiveProps(nextProps) {
        if (this.state.tagList.length === 0) {
            let tagList = [];
            for (let i = 0; i < nextProps.tagList.length; i++) {
                let foundRow = nextProps.template.searchTags.filter(function(row, index){return nextProps.tagList[i].name === row.name});  // Don't add tag if already assigned to questions
                if (foundRow.length === 0) {
                    tagList.push({value: i.toString(), text: nextProps.tagList[i].name});
                }
            }
            this.setState({tagList: tagList});
        }
    }

    onSelectChangeIsExistingTag = (event, fieldName) => {
        let value = event.target.value; // Yes = "0", No = "1"

        if (value === "1") {
            this.setState({radioChecked: false})
            this.validateTag(""); // Tag should be empty string on change to existing tag = NO
        } else this.setState({radioChecked: true})

        this.setState({isExistingTag: value});
    };

    onTextChangeNewTagName = (event, fieldName) => {

        let value = event.target.value;

        this.props.checkValue(value);
        this.setState({tagName: value}, this.validateTag(value));
    };

    validateTag = (name) => {

        let tagNameErrorText = " ";

        if (name.length === 0) {
            tagNameErrorText = "Tag name cannot be blank.";
        } else {
            let nameExists = false;
            this.props.tagList.forEach(tag => {
                if (tag.name.toUpperCase() === name.toUpperCase()) {
                    nameExists = true;
                }
            });
            if (nameExists) {
                tagNameErrorText = "Tag already exists.";
            }
        }

        this.setState({tagNameErrorText: tagNameErrorText});
    };

    createNewTagAndAddToQuestion = (event, fieldName) => {

        let newTag = {
            id: "",
            name: this.state.tagName
        };

        let assignedTags = this.props.template.searchTags;  // List of current tags assigned to question.

        if (assignedTags.find(tag => {
            return tag.name === newTag.name;
        })){
            this.setState({tagName: ""});
            return; // It's already in the list assigned to the question so don't add!!!!!!
        }

        let found = this.state.tagList.find(tag => {
            return tag.name === newTag.name; // The tag is already in the current list of availble tags
        });

        // If tag found in the list of existing tags
        if (found) {
            this.addExistingTagToQuestion(null, "", newTag.name);
        } else {
            // Assign new tag to question
            let updatedTemplate = {...this.props.template};
            updatedTemplate.searchTags = [...updatedTemplate.searchTags, newTag];
            this.props.updateQuestionTemplate(updatedTemplate, this.props.questionDepth);
        }

        this.setState({isExistingTag: "0"});
    };

    onSelectTagChange = (event, fieldName, menuItemText, index) => {

        let value = event.target.value;

        this.addExistingTagToQuestion(event, fieldName, menuItemText, index)

    }

    addExistingTagToQuestion = (event, fieldName, tagName, index) => {

        // Note: this adds a tag to the question but the tag is NOT create unless the question is saved.

        let tagList = [...this.state.tagList];  // Clone available tags array

        let tagObj = null;
        let found = false; // Some conditions trigger call, like blanking out entry
        for (let i=0; i < tagList.length; i++) {
            if (tagName === tagList[i].text) {
                tagObj = this.props.tagList.filter(function(row, index){return tagList[i].text === row.name});  // Get object from master list
                tagObj = tagObj[0]; // needed to strip obj from array
                found = true;
                tagList.splice(i,1); // Remove selection from available list
            }
        }

        // If tagName was found in avaliable tag list then add to question tag list
        if (found) {
            let updatedTemplate = {...this.props.template};
            updatedTemplate.searchTags = [...updatedTemplate.searchTags, tagObj];
            this.props.updateQuestionTemplate(updatedTemplate, this.props.questionDepth);
        }

        for (let i = 0; i < tagList.length; i++) {
            tagList[i].value = i.toString(); // Set values to new index location
        }

        this.setState({tagList: tagList, isExistingTag: "0"});
    };

    // updateInput = (tagName) => {
    //     this.setState({tagName}, this.validateTag(tagName));
    // };

    removeTagAssignedToQuestion = (event, tagLabel, tagIndex) => {

        let updatedTemplate = {...this.props.template}; // Clone question template
        let tags = [...updatedTemplate.searchTags]; // Clone tags arrray

        let removedTagObj = tags[tagIndex]; // Save tag to be removed incase it needs added back to available list (ie.. not a new tag)

        updatedTemplate.searchTags = tags.filter(function(value, index){return index != tagIndex}); // Remove tag from assigned tags array using index NOTE: .splice(tagIndex, 1); was not working

        this.props.updateQuestionTemplate(updatedTemplate, this.props.questionDepth);

        // Add removed tag back to list if not a new created tag
        if (removedTagObj.id !== "") {

            let tagList = [...this.state.tagList];
            tagList.push({value: "", text: removedTagObj.name});

            // Sort availble tag list
            tagList.sort((a, b) => {
                let fa = a.text.toLowerCase(),
                    fb = b.text.toLowerCase();

                if (fa < fb) {
                    return -1;
                }
                if (fa > fb) {
                    return 1;
                }
                return 0;
            });

            for (let i = 0; i < tagList.length; i++) {
                tagList[i].value = i.toString(); // Set values to new index location
            }

            this.setState({tagList: tagList});
        }
    };


    render() {

        let tagsAssignedToQuestion = ((this.props !== undefined) && (this.props.template !== undefined) && (this.props.template.searchTags !== undefined)) ? this.props.template.searchTags : [];

        return (
            <div id="TagSelectOrCreate-container-div">

                {this.props.allowCreate ?
                    <ArgoRadioGroupHook
                        fileName="TagSelectOrCreate"
                        fieldName="isExistingTag"
                        label="Is this an existing tag?"
                        value={this.state.isExistingTag}
                        fontSize="18px"
                        onChange={this.onSelectChangeIsExistingTag}
                        checked={this.state.radioChecked}
                    />
                    : ""
                }

                {(this.state.isExistingTag === "0") ?

                     this.state.tagList.length > 0 ?
                        <ArgoTextFieldSelectHook
                            fileName="TagSelectOrCreate"
                            fieldName="tagSelectValue"
                            label="Select a tag"
                            value={this.state.tagSelectValue}
                            menuItems={this.state.tagList}
                            onChange={this.onSelectTagChange}
                        /> : <div style={{color: "gray"}}>There are no existing tags.</div>
                    :
                    <div id="TagSelectOrCreate-create-container-div" style={{display: "flex", flexDirection: "row", alignItems: "center", height: "72px", marginLeft: "32px", width: "100%"}}>

                        <div style={{display: "flex", width: "100%"}}>

                            <ArgoTextFieldHook
                                fileName="TagSelectOrCreate"
                                fieldName="tagName"
                                label='Name'
                                value={this.state.tagName}
                                onChange={this.onTextChangeNewTagName}
                                width="250px"
                                maxLength={15}
                                errorText={this.state.tagNameErrorText}
                            />

                            <div style={{width: "20px"}}>&nbsp;</div>

                            <ArgoButtonHook
                                fileName="TagSelectOrCreate"
                                fieldName="add"
                                variant="contained"
                                label="ADD"
                                onClick={this.createNewTagAndAddToQuestion}
                                disabled={this.state.tagNameErrorText !== " "}
                                width="100px"
                                paddingTop="10px"
                            />

                            <div style={{width: "15px"}}>&nbsp;</div>

                            <div style={{color: "darkgray", paddingTop: "18px"}}>Note: Tag is not created unless question is saved.</div>

                        </div>

                    </div>
                }

                <div style={{width: "20px"}}>&nbsp;</div>

                <div id="TagSelectOrCreate-assigned-tag-chips-container-div" style={{display: "flex", flexDirection: "row", alignItems: "center", width: "100%"}}>

                    {tagsAssignedToQuestion.map((tag, index) => {
                        return(<div>
                                <ArgoChipHook
                                    fileName="TagSelectOrCreate"
                                    fieldName={index.toString()}
                                    index={index}
                                    label={tag.name}
                                    onDelete={this.removeTagAssignedToQuestion}
                                />
                                <div>&nbsp;</div>
                            </div>
                        )
                    })}

                </div>

            </div>
        );
    }
}

TagSelectOrCreate.defaultProps = {
    questionDepth: 0,
    allowCreate: true
};

TagSelectOrCreate.propTypes = {
    questionDepth: PropTypes.number,
    allowCreate: PropTypes.bool
};

function mapStateToProps(state, props) {
    return {
        tagList: state.tagList,
        template: props.questionDepth === undefined ? {searchTags: []} : state.questionEditor[props.questionDepth].template,
        options: state.tagEditor.options,
        filter: state.filter["defaultfilter"]
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({checkValue, getTagList, updateQuestionTemplate}, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(TagSelectOrCreate);
