import React, {Component} from 'react';
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import InfoIcon from "@mui/icons-material/Info";
import GroupViewer from "../groups/GroupViewer";
import QuestionViewer from "../questions/QuestionViewer";
import {getCategoryTemplate} from "../../../actions/categoryTemplateActions";
import {getQuestionTemplate} from "../../../actions/questionTemplateActions";
import Typography from "@mui/material/Typography";
import GroupViewerExpandable from "../groups/GroupViewerExpandable";
import ArgoToolbarHook from "../../common/ArgoToolbarHook";
import * as expander from "../../../utilities/expander";
import ArgoPortalPreview from "../../common/ArgoPortalPreview";
import * as portal from "../../../utilities/portal";
import statusFormatter from "../../../utilities/StatusFormatter";
import * as metadataUtils from "../../../utilities/metadata";
import * as TEMPLATE from "../../../constants/templateConstants";


class CategoryViewer extends Component {

    constructor(props) {
        super(props);
        this.state = {
            expander: {},
            expandAll: true,
            openGroupViewer: false,
            selectedGroupInfoKey: "",
            openQuestionViewer: false,
            selectedQuestionInfoKey: "",
            groupsLoaded: false,
            showCategoryBackButton: true,
            showGroupBackButton: true
        };
    }

    componentDidMount() {
        if (this.props.templateKey || this.props.templateId) {
            this.props.getCategoryTemplate(this.props.templateKey, this.props.templateId);
        }
    }

    componentWillReceiveProps(nextProps) {

        // let tempExpanderObj = {};
        // for (let i=0; i<nextProps.groups.length; i++) {
        //     tempExpanderObj[nextProps.groups[i]] = {open: false, viewerExpandableObj: {}};
        // }

        if ((nextProps.templateKey && nextProps.templateKey !== this.props.templateKey)
            || (nextProps.templateId && nextProps.templateId !== this.props.templateId)) {
            nextProps.getCategoryTemplate(nextProps.templateKey, nextProps.templateId);
        }
        this.setState({expander: expander.presetExpander(nextProps.groupKeys, nextProps.groupTemplates, this.state.expander)});
    }

    handleToolbarButtonClick = (event, fieldName, buttonTitle) => {
        if(buttonTitle === 'BACK') this.setState({openGroupViewer: false, openQuestionViewer: false, showCategoryBackButton: true})
    }

    setStateCallback = (stateObj) => {
        this.setState(stateObj);
    }

    openQuestionViewer = () => {

        return (
            <div style={{height: "100%"}}>

                <ArgoToolbarHook
                    fileName="CategoryViewer"
                    fieldName="back"
                    buttonTitles={["BACK"]}
                    onClick={this.handleToolbarButtonClick}
                />

                <div className="layout vertical" style={{height: "100%", overflowY: "scroll"}}>
                    <QuestionViewer templateKey={this.state.selectedQuestionInfoKey}/>
                </div>
            </div>
        );
    };


    render() {
        this.props.setStateCallBack({showCategoryBackButton: this.state.showCategoryBackButton})
        const template = this.props.template;
        const metadata = template?.metadata;

        if (this.state.openGroupViewer) {
            return (
                <div style={{height: "100%"}}>

                    {this.state.showGroupBackButton ?
                        <ArgoToolbarHook
                            fileName="CategoryViewer"
                            fieldName="back"
                            buttonTitles={["BACK"]}
                            onClick={this.handleToolbarButtonClick}
                        /> : ''
                    }

                    <div style={{height: "100%", width: "100%", overflowY: "scroll"}}>
                        <GroupViewer templateKey={this.state.selectedGroupInfoKey} showToolbar={false} setStateCallBack={this.setStateCallback}/>;
                    </div>
                </div>
            );
        }

        if (this.state.openQuestionViewer) {
            return this.openQuestionViewer();
        }

        return (
            template ?
                <div style={{flexGrow: "2", padding: "32px"}}>
                    <div style={{minHeight: "min-content"}}>

                        <Typography variant="h6" component="div">
                            Category Information:
                        </Typography>

                        <div>&nbsp;</div>

                        <div id="CategoryViewer-complex-question-type-container-div" style={{width: "100%", display: "flex", justifyContent: "flex-start", alignItems: "center", overflowWrap: "break-word"}}>
                            <div style={{color: "darkgray"}}>Status:</div>
                            <div>&nbsp;</div>
                            <div>{statusFormatter(this.props.template, 0, 0, true)}</div>
                        </div>

                        <div>&nbsp;</div>

                        <div id="CategoryViewer-portal-page-break-container-div" style={{width: "100%", display: "flex", justifyContent: "flex-start", alignItems: "center", overflowWrap: "break-word"}}>
                            <div style={{color: "darkgray"}}>Portal Page Break:</div>
                            <div>&nbsp;</div>
                            <div>{metadata[portal.CUSTOM_DDO_CAT_FORMAT ] ? (JSON.parse(metadata[portal.CUSTOM_DDO_CAT_FORMAT ]).portalPageBreak ? 'Yes' : 'No') : 'No'}</div>
                        </div>

                        <div>&nbsp;</div>

                        <div id="CategoryViewer-title-bar-container-div" style={{width: "100%", display: "flex", justifyContent: "flex-start", alignItems: "center", overflowWrap: "break-word"}}>
                            <div style={{color: "darkgray"}}>Portal Title Bar:</div>
                            <div>&nbsp;</div>
                            <div>{metadata[portal.CUSTOM_DDO_CAT_FORMAT ] ? (JSON.parse(metadata[portal.CUSTOM_DDO_CAT_FORMAT ]).titlebar ? 'Yes' : 'No') : 'No'}</div>
                        </div>

                        <div>&nbsp;</div>

                        <div id="CategoryViewer-complex-question-name-container-div" style={{width: "100%", display: "flex", justifyContent: "flex-start", alignItems: "center", overflowWrap: "break-word"}}>
                            <div style={{color: "darkgray"}}>Category:</div>
                            <div>&nbsp;</div>
                            <div>{template.name}</div>
                        </div>

                        <div>&nbsp;</div>

                        <div id="CategoryViewer-complex-question-qualifier-container-div" style={{width: "100%", display: "flex", justifyContent: "flex-start", alignItems: "center", overflowWrap: "break-word"}}>
                            <div style={{color: "darkgray"}}>Qualifier:</div>
                            <div>&nbsp;</div>
                            <div>{template.nameQualifier}</div>
                        </div>

                        <div>&nbsp;</div>

                        {metadata[portal.CAT_SCRIPT] && <div style={{display: 'flex'}}>
                            <div style={{color: "darkgray"}}>Script:</div>
                            <div>&nbsp;</div>
                            <div style={{paddingBottom: "12px", fontStyle: "italic"}}>"{metadata[portal.CAT_SCRIPT]}"</div>
                        </div>}

                        <div>&nbsp;</div>

                        <div id="CategoryViewer-complex-question-description-container-div" style={{width: "100%", display: "flex", justifyContent: "flex-start", alignItems: "center", overflowWrap: "break-word"}}>
                            <div style={{color: "darkgray"}}>Description:</div>
                            <div>&nbsp;</div>
                            <div>
                                {template.description && <div style={{width: "100%", display: "flex", justifyContent: "flex-start", alignItems: "center", overflowWrap: "break-word"}}>
                                    <InfoIcon style={{minWidth: "36px"}}/>
                                    <div>&nbsp;</div>
                                    <div>{template.description}</div>
                                </div>}
                            </div>
                        </div>

                        <div>&nbsp;</div>

                        <div>
                            {metadataUtils.listMetadata(template, "Category")}
                        </div>
                    </div>

                    {/*// ===========================================================================================================*/}
                    {/*// ASSIGNED GROUPS LIST */}
                    {/*// ===========================================================================================================*/}

                    <GroupViewerExpandable
                        categoryTemplate={this.props.template}
                        groupKeys={this.props.template.groupKeys}
                        showIconButtonReorder={false}
                        showIconButtonEdit={false}
                        showIconButtonRemove={false}
                        setStateCallback={this.setStateCallback}
                        hidePortalPreview={true}
                    />

                    {/*// ===========================================================================================================*/}
                    {/*// CATEGORY PORTAL PREVIEW */}
                    {/*// ===========================================================================================================*/}

                    <div id="CategoryViewer-space-above-portal-preview" style={{height: "20px"}}>&nbsp;</div>

                    <ArgoPortalPreview
                        calledBy="CategoryViewer"
                        templateType={TEMPLATE.TYPE.CATEGORY}
                        categoryTemplate={this.props.template}
                        groupTemplates={this.props.groupTemplates}
                        groupKeys={this.props.groupKeys}
                        questionTemplates={this.props.questionTemplates}
                        showButton={true}
                    />

                </div>
                : ""
        );
    }
}

CategoryViewer.defaultProps = {
    showToolbar: true,
    setStateCallBack: function () {}
};

CategoryViewer.propTypes = {
    templateKey: PropTypes.string,
    groupTemplate: PropTypes.object,
    getCategoryTemplate: PropTypes.func,
    templateId: PropTypes.string,
    showToolbar: PropTypes.bool,
    setStateCallBack: PropTypes.func
};

function mapStateToProps(state, props) {
    return {
        template: state.categoryTemplate[props.templateKey],
        groupTemplates: state.groupTemplate,
        groupKeys: state.categoryTemplate[props.templateKey]?.groupKeys || [],
        questionTemplates: state.questionTemplate
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({getCategoryTemplate, getQuestionTemplate}, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(CategoryViewer);

