import React, { useState, useEffect } from 'react';
import ArgoExpandIcon from "./ArgoExpandIcon";
import ArgoToggleSwitchHook from "./ArgoToggleSwitchHook";
import ArgoIconButtonHook from "./ArgoIconButtonHook";
import ExpandLessOutlinedIcon from '@mui/icons-material/ExpandLessOutlined';
import ExpandMoreOutlinedIcon from '@mui/icons-material/ExpandMoreOutlined';


export default function ArgoSectionOpenCloseHook(props) {

    const fileName = props.fileName === undefined ? "file-name" : props.fileName;
    const fieldName = props.fieldName === undefined ? "field-name" : props.fieldName;
    const index = props.index === undefined ? "" : props.index; // If field is built in a loop index is required to know which field to match up with

    const uniqueIdentifier = fileName + "-section-open-close-" + fieldName + index;

    const sectionTitle = props.sectionTitle === undefined ? "" : props.sectionTitle;
    //const toggleTitle = props.toggleTitle === undefined ? "" : props.toggleTitle;


    const onClick = props.onClick === undefined ? function () {} : props.onClick;

    const open = props.open === undefined ? false : props.open;

    const display = props.display === undefined ? "inline" : (props.display ? "inline" : "none");

    const handleOnClick = (event) => {
        onClick(event, fieldName, index);
    };

    return (
        <div id={uniqueIdentifier} name={uniqueIdentifier} style={{display: display}}>

            <div id={"argo-section-open-close-hook-div" + index} style={{display: "flex", flexDirection: "row", alignItems: "center", width: "100%"}}>

                <div id={"argo-section-open-close-hook-expand-icon-div" + index} style={{justifyContent: "flex-start"}}>
                    <ArgoIconButtonHook
                        fileName="argo-section-open-close-hook"
                        fieldName="expand-icon"
                        onClick={handleOnClick}
                        index={index}
                        icon={open ? <ExpandLessOutlinedIcon/> : <ExpandMoreOutlinedIcon/>}
                    />
                </div>

                <div id={"argo-section-open-close-hook-title-div" + index} style={{justifyContent: "flex-start", width: "200px"}}><b>{sectionTitle}</b></div>

                {/*<div id={"argo-section-open-close-hook-spacer-div" + index} style={{width: "70%"}}></div>*/}

                {/*<div id={"argo-section-open-close-hook-switch-div" + index} style={{marginLeft: "auto"}}>*/}

                {/*    <ArgoToggleSwitchHook*/}
                {/*        fileName="argo-section-open-close-hook"*/}
                {/*        fieldName="toggle-icon"*/}
                {/*        label={toggleTitle}*/}
                {/*        labelOn="Open"*/}
                {/*        labelOff="Closed"*/}
                {/*        onChange={handleOnClick}*/}
                {/*        checked={open}*/}
                {/*    />*/}

                {/*</div>*/}
            </div>

        </div>
    );
}



// From argoMetadataEditor

// <div id="argo-metadata-editor-toggle-container-div" style={{display: "flex", flexDirection: "row", alignItems: "center", width: "100%"}}>
//
//     <div id="argo-metadata-editor-icon-div" style={{justifyContent: "flex-start", paddingBottom: "10px"}}>
//         <ArgoExpandIcon
//             fileName="argo-metadata-editor"
//             fieldName="expand-icon"
//             onClick={this.toggleAdditionalPropsMetadataMode}
//             isExpanded={this.state.additionalPropsMetadtataOpen}/>
//     </div>
//
//     <div id="argo-metadata-edditor-title-div" style={{justifyContent: "flex-start"}}><b>Additional Properties</b></div>
//
//     <div style={{width: "70%"}}></div>
//
//     <div id="argo-metadata-edditor-switch-div" style={{marginLeft: "auto"}}>
//
//         <ArgoToggleSwitchHook
//             fileName="argo-metadata-editor"
//             fieldName="toggle"
//             label="Metadata"
//             labelOn="Open"
//             labelOff="Closed"
//             onChange={this.toggleAdditionalPropsMetadataMode}
//             checked={this.state.additionalPropsMetadtataOpen}
//         />
//
//     </div>
// </div>

