import React, {Component} from 'react';
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import {getQuestionnaireTemplate} from "../../../actions/questionnaireTemplateActions";
import {
    initializeQuestionnaireEditorUpdate,
    initializeQuestionnaireEditorCreate,
    clearQuestionnaireEditor,
    updateQuestionnaireTemplate,
    checkQuestionnaireValue,
    checkNameQualifier,
    validateQuestionnaireNameAndQualifierUnique,
    addQuestionnaireCategory,
} from "../../../actions/questionnaireEditorActions";
import {createCategoryTemplate, getCategoryTemplate, saveCategoryTemplate} from "../../../actions/categoryTemplateActions";
import CategoryViewerExpandable from "../categories/CategoryViewerExpandable";
import ArgoMetadataEditor from "../../common/ArgoMetadataEditor";
import * as validate from "../../../utilities/validate";
import * as metadataTemplateTypes from "../../../constants/metadataTemplateTypes"
import QuestionnaireViewer from "../../qd/questionnaires/QuestionnaireViewer";
import ArgoTextDisplayDefination from "../../common/ArgoTextDisplayDefination";
import ArgoTextDisplayCustom from "../../common/ArgoTextDisplayCustom";
import ArgoButtonDisplayCustom from "../../common/ArgoButtonDisplayCustom";
import ArgoTextFieldHook from "../../common/ArgoTextFieldHook";
import ArgoTextFieldSelectHook from "../../common/ArgoTextFieldSelectHook";
import ArgoTextEntryWithCustomOverride from "../../common/ArgoTextEntryWithCustomOverride";
import {ALLOWED_TOKEN} from "../../../constants/dataConstants";
import * as portal from "../../../utilities/portal";
import * as metadata from "../../../utilities/metadata";
import * as expander from "../../../utilities/expander";
import * as TEMPLATE from "../../../constants/templateConstants";
import {getMetadataDefinitions} from "../../../actions/metadataDefinitionActions";
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import UserContext from "../../../UserContext";
import ArgoSelectExisting from "../../common/ArgoSelectExisting";
import ArgoRadioGroupHook from "../../common/ArgoRadioGroupHook";
import CategoryEditor from "../categories/CategoryEditor";
import ArgoToolbarHook from "../../common/ArgoToolbarHook";
import ArgoAutocompleteHook from "../../common/ArgoAutocompleteHook";
import CategoryViewer from "../categories/CategoryViewer";
import * as metadataUtils from "../../../utilities/metadata";
import QuestionEditor from "../questions/QuestionEditor";
import ArgoPortalPreview from "../../common/ArgoPortalPreview";


const arrayNoOrYes = [
    {value: "no", text: "No"},
    {value: "yes", text: "Yes"}
];

const arrayDefaultOrCustom = [
    {value: "default", text: "Default"},
    {value: "custom", text: "Custom"}
];

const arrayNoneOrTextOrImage = [
    {value: "none", text: "None"},
    {value: "text", text: "Text"},
    {value: "image", text: "Image"}
];

const arrayQuestionSubNumberFormats = [
    {value: "none", text: "None"},
    {value: "a", text: "a"},
    {value: "a.", text: "a."},
    {value: "a)", text: "a)"},
    {value: "(a)", text: "(a)"},
    {value: "#a", text: "#a"},
    {value: "#a.", text: "#a."},
    {value: "#a)", text: "#a)"},
    {value: "#(a)", text: "#(a)"},
    {value: "A", text: "A"},
    {value: "A.", text: "A."},
    {value: "A)", text: "A)"},
    {value: "(A)", text: "(A)"},
    {value: "#A", text: "#A"},
    {value: "#A.", text: "#A."},
    {value: "#A)", text: "#A)"},
    {value: "#(A)", text: "#(A)"},
    {value: "#.#", text: "#.#"}
];


class QuestionnaireEditor extends Component {

    constructor(props) {
        super(props);

        this.state = {
            showCategoryBackButton: true,
            expander: {},
            expandAll: true,
            review: false,
            isLoading: false,
            openCategoryViewer: false,
            selectedCategoryInfoKey: "",
            isThisAnExistingCategory: "0",
            categoryTemplateSearch: "",
            toolbarErrorText: "",
            //isExpanded: false,
            nameErrorText: " ",
            metadataErrorText: "",
            nameDisplayDefination: "default",
            nameDisplayMetadataValue: "",
            nameDisplayMetadataValueObj: null,
            descDisplayDefination: "default",
            descDisplayMetadataValue: "",
            descDisplayMetadataValueObj: null,
            scriptBeforeDisplayDefination: "default",
            scriptBeforeDisplayMetadataValue: "",
            scriptBeforeDisplayMetadataValueObj: null,
            scriptAfterDisplayDefination: "default",
            scriptAfterDisplayMetadataValue: "",
            scriptAfterDisplayMetadataValueObj: null,
            reportingCategories: [],
            reportingCategory: 0,
            selectedTabNumber: 0,
            tab0: "THEME (Primary)",
            tab1: "HEADER (No)",
            tab2: "START PAGE (No)",
            tab3: "MAIN PAGES (Default)",
            tab4: "CLOSE PAGE (Default)",
            // tab5: "ALERTS (Survey Defaults)",
            theme: "primary",
            themeObj: {
                theme: "primary",
                defaultText: "default",
                defaultTextCustomObj: "",
                defaultButton1: "default",
                defaultButton1CustomObj: "",
                defaultButton2: "default",
                defaultButton2CustomObj: "",
            },
            appHeader: "no", // custom
            appHeaderObj: {
                format: 'default',
                border: "",
                padding: "",
                backgroundColor: "",
                backgroundImage: "",
                boxShaadow: "",
                textLeftType: "none", // text, icon
                textLeft: "",
                textLeftFormat: "default",
                textLeftCustomObj: "",
                textCenterType: "none", // text, icon
                textCenter: "",
                textCenterFormat: "default",
                textCenterCustomObj: "",
                textRightType: "none", // text, icon
                textRight: "",
                textRightFormat: "default",
                textRightCustomObj: ""
            },
            startPage: "no",
            startPageObj: {
                format: "default"
            },
            mainPages: "default",
            mainPagesObj: {
                pagingMode: "full",
                answeredFirstQuestion: "hide", // show
                questionNumbering: "none",
                questionNums: "#",
                questionNumsLikert: "none",
                questionNumsShowGroup: "none",
                pageHeader: "no",
                pageHeaderTextLeft: "",
                pageHeaderTextLeftFormat: "default",
                pageHeaderTextLeftCustomObj: "",
                pageHeaderTextCenter: "",
                pageHeaderTextCenterFormat: "default",
                pageHeaderTextCenterCustomObj: "",
                pageHeaderTextRight: "",
                pageHeaderTextRightFormat: "default",
                pageHeaderTextRightCustomObj: "",
                pageFooter: "no",
                pageFooterTextLeft: "",
                pageFooterTextLeftFormat: "default",
                pageFooterTextLeftCustomObj: "",
                pageFooterTextCenter: "",
                pageFooterTextCenterFormat: "default",
                pageFooterTextCenterCustomObj: "",
                pageFooterTextRight: "",
                pageFooterTextRightFormat: "default",
                pageFooterTextRightCustomObj: "",
                pageFooter2: "no",
                pageFooter2TextLeft: "",
                pageFooter2TextLeftFormat: "default",
                pageFooter2TextLeftCustomObj: "",
                pageFooter2TextCenter: "",
                pageFooter2TextCenterFormat: "default",
                pageFooter2TextCenterCustomObj: "",
                pageFooter2TextRight: "",
                pageFooter2TextRightFormat: "default",
                pageFooter2TextRightCustomObj: "",
            },
            closePage: "yes",
            closePageObj: {
                format: "default",
                image: "greencheck",
                text: "default1",
            },
            allAccordionsExpanded: false,
            openCategoryEditor: false,
            categoryTemplate: {}
            // alerts: "survey",  //  questionnaire, poll, quiz, test, opinion poll, exam, survey form
            // alertsObj: {
            //     alerts: "survey",
            //     //invalidKeyText: portal.getInvalidKeyAlertText("survey"),
            //     compleatedText: portal.getCompleatedAlertText("survey"),
            //     deactivatedText: portal.getDeactivatedAlertText("survey"),
            //     expiredText: portal.getExpiredAlertText("survey"),
            //     canceledText: portal.getCanceledAlertText("survey"),
            // }
        };

    }

    static contextType = UserContext;  // From blueTheme.js

    componentDidMount() {

        let currURL = window.location.href;

        this.props.getMetadataDefinitions(metadataTemplateTypes.QuestionnaireTemplate);

        if (this.props.templateKey || this.props.templateId) {
            if (this.props.questionnaireTemplate[this.props.templateKey] &&
                (this.props.questionnaireTemplate[this.props.templateKey].id === this.props.templateId)) {

                this.props.initializeQuestionnaireEditorUpdate(this.props.questionnaireTemplate[this.props.templateKey]);
            }
            else if (this.props.templateKey) {
                this.setState({isLoading: true});
                this.props.getQuestionnaireTemplate(this.props.templateKey, this.props.templateId);
            }

            this.pageInit(this.props);
        }
        else {
            this.props.initializeQuestionnaireEditorCreate();
        }

        expander.initializeExpander(this.state.expander, this.props.template.categoryKeys, this.setStateCallback);

        if (this.props.templateOriginal) {
            this.pageInit(this.props);
        }
    }


    componentWillReceiveProps(nextProps) {

        // let tempExpanderObj = {};
        // for (let i=0; i<nextProps.categoryKeys.length; i++) {
        //     tempExpanderObj[nextProps.categoryKeys[i]] = {open: false, viewerExpandableObj: {}};
        // }

        let tempReportingCategories = [];
        if (this.state.reportingCategories.length === 0) {

            if (nextProps.metadataDefinition !== undefined) {

                tempReportingCategories.push("None");
                for (let i = 0; i < nextProps.metadataDefinition.length; i++) {
                    if (nextProps.metadataDefinition[i].name === metadata.REPORTING_CATEGORY_QUESTIONNAIRE) {
                        for (let j = 0; j < nextProps.metadataDefinition[i].listValues.length; j++) {
                            tempReportingCategories.push(nextProps.metadataDefinition[i].listValues[j]);
                        }
                    }
                }
                this.setState({reportingCategories: tempReportingCategories});

            }
        }
        else {
            tempReportingCategories = this.state.reportingCategories;
        }


        let isTemplateLoaded = (nextProps.templateOriginal && nextProps.templateOriginal.templateKey === nextProps.templateKey
            && nextProps.template.templateKey !== nextProps.templateKey);
        let editTemplateReady = nextProps.template.id !== nextProps.templateId &&
            (nextProps.questionnaireTemplate[nextProps.templateKey] && nextProps.questionnaireTemplate[nextProps.templateKey].id === nextProps.templateId);

        if (nextProps.cloneMode) {

            // if (true) {
            this.props.checkNameQualifier(nextProps.template.nameQualifier, nextProps.template.id);
            // }
            // else {
            //     this.validateInput(nextProps.template, nextProps.options, nextProps.cloneMode);
            // }
        }

        if (isTemplateLoaded && editTemplateReady && (tempReportingCategories.length > 0)) {

            this.props.initializeQuestionnaireEditorUpdate(nextProps.templateOriginal);

            this.pageInit(nextProps);

            return;
        }

        this.setState({expander: expander.presetExpander(nextProps.categoryKeys, this.state.expander), reportingCategories: tempReportingCategories, isLoading: (isTemplateLoaded && editTemplateReady)});




        // FOr edit on a pre-Portal UI questionnaire template we need to add alerts
        // if (!nextProps.template.metadata.hasOwnProperty(portal.CUSTOM_DDO_QNR_FORMAT_ALERTS)) {
        //
        //     let tempMetadata = {...this.props.template.metadata};
        //
        //     tempMetadata[portal.CUSTOM_DDO_QNR_FORMAT_ALERTS] = JSON.stringify(portal.getQuestionnaireAlertsObj("survey"));
        //
        //     this.handleMetadataChange(tempMetadata);
        // }


        expander.initializeExpander(this.state.expander, nextProps.template.categoryKeys, this.setStateCallback);

        let options = this.validateInput(nextProps.template, nextProps.options, nextProps.cloneMode);

        if (options.hasError !== nextProps.options.hasError || (nextProps.options.nameErrorText !== options.nameErrorText)) {
            options.edited = nextProps.options.edited;
            this.props.updateQuestionnaireTemplate(nextProps.template, options);
        }
    }

    componentWillUnmount() {
        this.props.clearQuestionnaireEditor();
    }

    // Allow child components to set errors on this page.
    setStateCallback = (stateObj, saveCategoryAfter = false) => {
        if (saveCategoryAfter) {
            this.setState(stateObj, () => this.categoryEditorSave());
        } else {
            this.setState(stateObj);
        }
    }

    pageInit = (currProps) => {

        let tempReportingCategory = "0";
        //let tempTheme = "primary";
        let tempThemeObj = null;
        let tempAppHeader = "no";
        let tempAppHeaderObj = null;
        let tempStartPage = "no";
        let tempStartPageObj = null;
        let tempMainPages = "default";
        let tempMainPagesObj = null;
        let tempClosePage = "yes";
        let tempClosePageObj = null;
        // let tempAlerts = "survey";
        // let tempAlertsObj = portal.getQuestionnaireAlertsObj("survey");
        let tempTab0 = "THEME (Primary)";
        let tempTab1 = "HEADER (No)";
        let tempTab2 = "START PAGE (No)";
        let tempTab3 = "MAIN PAGES (Default)";
        let tempTab4 = "CLOSE PAGE (Default)";
        //let tempTab5 = "ALERTS (Survey Defaults)";

        let metadataObj = currProps.templateOriginal.metadata;

        let tempNameObj = metadata.setMetadataDefinationAndValueAndValueObj(metadataObj, portal.CUSTOM_DDO_NAME, metadata.definationAndValueAndValueObj);

        let tempDescObj = metadata.setMetadataDefinationAndValueAndValueObj(metadataObj, portal.CUSTOM_DDO_DESC, metadata.definationAndValueAndValueObj);

        let tempScriptBeforeObj = metadata.setMetadataDefinationAndValueAndValueObj(metadataObj, portal.CUSTOM_DDO_QNR_SCRIPT_BEFORE, metadata.definationAndValueAndValueObj);

        let tempScriptAfterObj = metadata.setMetadataDefinationAndValueAndValueObj(metadataObj, portal.CUSTOM_DDO_QNR_SCRIPT_AFTER, metadata.definationAndValueAndValueObj);

        if (metadataObj.hasOwnProperty(metadata.REPORTING_CATEGORY_QUESTIONNAIRE)) {

            let tempReportingCategoryText = metadataObj[metadata.REPORTING_CATEGORY_QUESTIONNAIRE];
            for (let idx = 0; idx < this.state.reportingCategories.length; idx++) {
                if (this.state.reportingCategories[idx] === tempReportingCategoryText) {
                    tempReportingCategory = idx;
                    break;
                }
            }
        }
        if (metadataObj.hasOwnProperty(portal.CUSTOM_DDO_QNR_FORMAT_THEME)) {
            tempThemeObj = metadataObj[portal.CUSTOM_DDO_QNR_FORMAT_THEME] === "" ? "" : JSON.parse(metadataObj[portal.CUSTOM_DDO_QNR_FORMAT_THEME]);
            switch (tempThemeObj.theme) {
                case "primary":
                    tempTab0 = "THEME (Primary)";
                    break;
                case "secondary":
                    tempTab0 = "THEME (Secondary)";
                    break;
                case "custom":
                    tempTab0 = "THEME (Custom)";
                    break;
                // no default
            }
        }
        if (metadataObj.hasOwnProperty(portal.CUSTOM_DDO_QNR_FORMAT_APP_HEADER)) {
            tempAppHeader = "yes";
            tempTab1 = "HEADER (Yes)";
            tempAppHeaderObj = metadataObj[portal.CUSTOM_DDO_QNR_FORMAT_APP_HEADER] === "" ? "" : JSON.parse(metadataObj[portal.CUSTOM_DDO_QNR_FORMAT_APP_HEADER]);
        }
        if (metadataObj.hasOwnProperty(portal.CUSTOM_DDO_QNR_FORMAT_START_PAGE)) {
            tempStartPage = "yes";
            tempTab2 = "START PAGE (Yes)";
            tempStartPageObj = metadataObj[portal.CUSTOM_DDO_QNR_FORMAT_START_PAGE] === "" ? "" : JSON.parse(metadataObj[portal.CUSTOM_DDO_QNR_FORMAT_START_PAGE]);
        }
        if (metadataObj.hasOwnProperty(portal.CUSTOM_DDO_QNR_FORMAT_MAIN_PAGES)) {
            tempMainPages = "custom";
            tempTab3 = "MAIN PAGES (Custom)";
            tempMainPagesObj = metadataObj[portal.CUSTOM_DDO_QNR_FORMAT_MAIN_PAGES] === "" ? "" : JSON.parse(metadataObj[portal.CUSTOM_DDO_QNR_FORMAT_MAIN_PAGES]);
        }
        if (metadataObj.hasOwnProperty(portal.CUSTOM_DDO_QNR_FORMAT_CLOSE_PAGE)) {
            tempClosePage = "yes";
            tempClosePageObj = metadataObj[portal.CUSTOM_DDO_QNR_FORMAT_CLOSE_PAGE] === "" ? "" : JSON.parse(metadataObj[portal.CUSTOM_DDO_QNR_FORMAT_CLOSE_PAGE]);
            tempTab4 = tempClosePageObj.format === "default" ? "CLOSE PAGE (Default)" : "CLOSE PAGE (Custom)";
        }
        else {
            tempClosePage = "no";
            tempTab4 = "CLOSE PAGE (No)";
        }
        // if (metadataObj.hasOwnProperty(portal.CUSTOM_DDO_QNR_FORMAT_ALERTS)) {
        //
        //     tempAlertsObj = JSON.parse(metadataObj[portal.CUSTOM_DDO_QNR_FORMAT_ALERTS]);
        //
        //     let alertType = tempAlertsObj.alerts;
        //
        //     switch (alertType) {
        //         case "custom":
        //             tempTab5 = "ALERTS (Custom)";
        //             break;
        //         case "survey":
        //             tempTab5 = "ALERTS (Survey Deafults)";
        //             break;
        //         case "questionnaire":
        //             tempTab5 = "ALERTS (Questionnaire Deafults)";
        //             break;
        //         case "poll":
        //             tempTab5 = "ALERTS (Poll Deafults)";
        //             break;
        //         default:
        //             tempTab5 = "ALERTS ()";
        //             break;
        //     }
        //
        // }
        // else {
        //     tempTab5 = "ALERTS (Survey Defaults)";
        // }

        this.setState({
            nameDisplayDefination: tempNameObj.displayDefination,
            nameDisplayMetadataValue: tempNameObj.displayMetadataValue,
            nameDisplayMetadataValueObj: tempNameObj.displayMetadataValueObj,
            descDisplayDefination : tempDescObj.displayDefination,
            descDisplayMetadataValue: tempDescObj.displayMetadataValue,
            descDisplayMetadataValueObj: tempDescObj.displayMetadataValueObj,
            scriptBeforeDisplayDefination : tempScriptBeforeObj.displayDefination,
            scriptBeforeDisplayMetadataValue: tempScriptBeforeObj.displayMetadataValue,
            scriptBeforeDisplayMetadataValueObj: tempScriptBeforeObj.displayMetadataValueObj,
            scriptAfterDisplayDefination : tempScriptAfterObj.displayDefination,
            scriptAfterDisplayMetadataValue: tempScriptAfterObj.displayMetadataValue,
            scriptAfterDisplayMetadataValueObj: tempScriptAfterObj.displayMetadataValueObj,
            reportingCategory: tempReportingCategory,
            tab0: tempTab0,
            tab1: tempTab1,
            tab2: tempTab2,
            tab3: tempTab3,
            tab4: tempTab4,
            //tab5: tempTab5,
            theme: tempThemeObj !== null ? tempThemeObj.theme : "primary",
            themeObj: tempThemeObj,
            appHeader: tempAppHeader,
            appHeaderObj: tempAppHeaderObj,
            startPage: tempStartPage,
            startPageObj: tempStartPageObj,
            mainPages: tempMainPages,
            mainPagesObj: tempMainPagesObj,
            closePage: tempClosePage,
            closePageObj: tempClosePageObj,
            // alerts: tempAlerts,
            // alertsObj: tempAlertsObj,
        });

    };

    checkForErrorsOnScreen = () => {
        let updatedTemplate = {...this.props.template};

        this.validateInput(updatedTemplate, this.props.options, this.props.cloneMode);
    }


    validateInput = (template, options, cloneMode) => {

        const name = (template) ? template.name : "";
        //const templateKey = (template) ? template.templateKey : "";
        let nameIsValid = (name.trim() !== "");

        // When in clone mode name qualifier is required
        if (cloneMode && (template.nameQualifier !== null && template.nameQualifier.trim() !== "")) {
            nameIsValid = false;
        }

        // const templateKeyIsValid = !templateKey || (templateKey.length < 51 && templateKey.match(/^([a-zA-Z0-9._-])+$/));
        let errorText = {
            hasError: false,
            nameErrorText: " "
        };

        let nameExists = false;
        //let keyExists = false;
        options.validate.forEach(template => {
            if (this.props.cloneMode) {
                // If anything in the array then there is a dup
                nameExists = true;
            }
            else
            {
                if (template.name.toUpperCase() === validate.removeExtraWhitespace(name).toUpperCase()) {
                    nameExists = true;
                }
                // if (template.templateKey.toUpperCase() === templateKey.toUpperCase() && options.createMode) {
                //     keyExists = true;
                // }
            }
        });

        if (this.props.cloneMode && (template.nameQualifier === "" || template.nameQualifier === null)) {
            nameExists = true;
        }

        if (nameExists){
            if (this.props.cloneMode) {
                errorText.nameErrorText = "This questionnaire name qualifier is already in use";
            }
            else {
                errorText.nameErrorText = "This questionnaire name is already in use";
            }
            errorText.hasError = true;
        }
        else if (!nameIsValid && name.trim() === ""){
            errorText.nameErrorText = "Questionnaire name required";
            errorText.hasError = true;
        }
        // else if (!nameIsValid){
        //     errorText.nameErrorText = "Header too long.";
        //     errorText.hasError = true;
        // }

        if (!cloneMode) {

            // This data cannot change in clone mode so do not validate

            // if (templateKeyIsValid && keyExists ){
            //     errorText.templateKeyErrorText = "Key already in use.";
            //     errorText.hasError = true;
            // }
            // else if (!templateKeyIsValid ){
            //     errorText.templateKeyErrorText = "Questionnaire key invalid.";
            //     errorText.hasError = true;
            // }

            let metadataValid = validate.metadata(template.metadata === undefined ? {} : template.metadata, errorText);
            if (!metadataValid) {
                errorText.hasError = true;
            }
        }

        this.setState({nameErrorText: errorText.nameErrorText});

        let toolbarErrorTextArray = [];
        this.state.toolbarErrorText = "";
        if (errorText.nameErrorText.trim() !== "") {
            toolbarErrorTextArray.push(errorText.nameErrorText);
        }
        if (this.state.metadataErrorText.trim() !== "") {
            toolbarErrorTextArray.push(this.state.metadataErrorText);
        }
        this.state.toolbarErrorText = toolbarErrorTextArray.join(",  "); // Create a comma delimited array of all error text
        return errorText;
    };


    onTextChange = (event, fieldName) => {
        let value = event.target.value;

        if ((fieldName === "nameQualifier") && (value==="")) {
            value = null;
        }

        let updatedTemplate = {...this.props.template};

        updatedTemplate[fieldName] = value;

        let options = this.validateInput(updatedTemplate, this.props.options, this.props.cloneMode);

        this.props.updateQuestionnaireTemplate(updatedTemplate, options);

        if (fieldName === "templateKey" || fieldName === "name" || fieldName === "nameQualifier")
        {
            if (this.props.cloneMode) {
                this.props.checkNameQualifier(updatedTemplate.nameQualifier, updatedTemplate.id);
            }
            else {
                this.props.checkQuestionnaireValue({name: updatedTemplate.name, templateKey: updatedTemplate.templateKey});
            }
        }
    };

    // handleChange = (questionProp, event, key, payload) => {
    //
    //     let updatedTemplate = {...this.props.template};
    //
    //     updatedTemplate[questionProp] = payload;
    //
    //     let options = this.validateInput(updatedTemplate, this.props.options, this.props.cloneMode);
    //
    //     this.props.updateQuestionnaireTemplate(updatedTemplate, options);
    //
    //     if(questionProp === "templateKey" || questionProp === "name" || questionProp === "nameQualifier")
    //     {
    //         if (this.props.cloneMode) {
    //             this.props.checkNameQualifier(updatedTemplate.nameQualifier, updatedTemplate.id);
    //         }
    //         else if (questionProp === "name" || questionProp === "nameQualifier")
    //         {
    //             this.props.validateQuestionnaireNameAndQualifierUnique(updatedTemplate.name, updatedTemplate.nameQualifier);
    //         }
    //         else {
    //             this.props.checkQuestionnaireValue({name: updatedTemplate.name, templateKey: updatedTemplate.templateKey});
    //         }
    //     }
    // };

    onChangeArgoAutocompleteHook = (event, fieldName, index, validationType) => {

        let value = event.target.value;

        switch(fieldName) {
            case "name":
            case "nameQualifier":
            case "description":

                let updatedTemplate = {...this.props.template};

                updatedTemplate[fieldName] = value;

                let options = this.validateInput(updatedTemplate, this.props.options, this.props.cloneMode);

                this.props.updateQuestionnaireTemplate(updatedTemplate, options);

                if (this.props.cloneMode) {
                    this.props.checkNameQualifier(updatedTemplate.nameQualifier, updatedTemplate.id);
                }
                else if (fieldName === "name" || fieldName === "nameQualifier")
                {
                    this.props.validateQuestionnaireNameAndQualifierUnique(updatedTemplate.name, updatedTemplate.nameQualifier);
                }
                else {
                    this.props.checkQuestionnaireValue({name: updatedTemplate.name, templateKey: updatedTemplate.templateKey});
                }

                break;
            case "user-script":
            case "user-script-after":
                // These fields are stored in metadata user-script is before
                this.handleMetaChange(fieldName, value);
                break;
            // No Default
        }

    }

    // handleTextChange = (questionProp, event) => {
    //     let tempValue = event.target.value;
    //
    //     if ((questionProp === "nameQualifier")&&(event.target.value==="")) {
    //         tempValue = null;
    //     }
    //     this.handleChange(questionProp, event, questionProp, tempValue);
    // };

    handleMetaChange = (fieldName, value) => {
        let updatedTemplate = {...this.props.template};  // Copy template
        let metadata = {...updatedTemplate.metadata};  // Copy metadata from template
        metadata[fieldName] = value; // Assign new value to metadata
        updatedTemplate.metadata = metadata; // Update template with new metadata
        this.props.updateQuestionnaireTemplate(updatedTemplate); // Update questionnaire with new metadata
    };


    handleMetadataChange = (metadata) => {
        let updatedTemplate = {...this.props.template, metadata: metadata};
        let options = this.validateInput(updatedTemplate, this.props.options, this.props.cloneMode);
        this.props.updateQuestionnaireTemplate(updatedTemplate, options);
    };


    onClickExpandCollapseSingleCategory = (templateKey, newState) => {

        // change single accordion state


        // loop thru all accordions and set state of collapse all expand all

        // let expander = {...this.state.expander};
        // expander[templateKey] = newState;
        //
        // let isExpanded = this.props.categoryKeys.reduce((accumulator, value) => {
        //     return accumulator && expander[value];
        // }, true);
        //
        // // if (this.props.questionKeys) {
        // //     isExpanded = isExpanded && expander[IQ_QUESTIONS_CATEGORY];
        // // }
        //
        // this.setState({expander, isExpanded});
    };


    setCustomMetadata = (fieldName, selected, metadataTag, metadataValueObj) => {
        let updatedTemplate = {...this.props.template};
        let tempMetadata = {...updatedTemplate.metadata};

        let metadataValueStr = (typeof metadataValueObj === "string") ? metadataValueObj : JSON.stringify(metadataValueObj);

        if(fieldName !== 'textLeftCustomObj' && fieldName !== 'textCenterCustomObj' && fieldName !== 'textRightCustomObj') {
            if ((selected === "default") || (selected === "None")) {
                if (tempMetadata.hasOwnProperty(metadataTag)) {
                    delete tempMetadata[metadataTag];
                }
            } else {
                tempMetadata[metadataTag] = metadataValueStr;
            }
        }

        let metadataSaveName = "";
        let metadataSaveObjName = "";
        let setStateFlag = true;
        switch(fieldName) {
            case "nameDisplayDefination":
                metadataSaveName = "nameDisplayMetadataValue";
                metadataSaveObjName = "nameDisplayMetadataValueObj";
                break;
            case "descDisplayDefination":
                metadataSaveName = "descDisplayMetadataValue";
                metadataSaveObjName = "descDisplayMetadataValueObj";
                break;
            case "scriptBeforeDisplayDefination":
                metadataSaveName = "scriptBeforeDisplayMetadataValue";
                metadataSaveObjName = "scriptBeforeDisplayMetadataValueObj";
                break;
            case "scriptAfterDisplayDefination":
                metadataSaveName = "scriptAfterDisplayMetadataValue";
                metadataSaveObjName = "scriptAfterDisplayMetadataValueObj";
                break;
            case "reportingCategory":
                setStateFlag = false;
                break;
            case "portalBypass":
                setStateFlag = false;
                break;
            case "textLeftCustomObj":
            case "textCenterCustomObj":
            case "textRightCustomObj":

                let tempValueObj = JSON.parse(tempMetadata[metadataTag]);
                tempValueObj[fieldName] = metadataValueObj;
                tempMetadata[metadataTag] = JSON.stringify(tempValueObj);
                //let tempName = "appHeaderObj." + fieldName;
                this.setState({tempName: metadataValueObj});
                setStateFlag = false;
                break;
            case "theme":
                setStateFlag = false;
                break;
            case "appHeader":
                setStateFlag = false;
                break;
            case "startPage":
                setStateFlag = false;
                break;
            case "mainPages":
                setStateFlag = false;
                break;
            case "closePage":
                setStateFlag = false;
                break;
            case "alerts":
                setStateFlag = false;
                break;
            // no default
        }

        if (setStateFlag) {
            this.setState({[fieldName]: selected, [metadataSaveName]: metadataValueStr, [metadataSaveObjName]: metadataValueObj});
        }

        this.handleMetadataChange(tempMetadata);

    };


    setCustomMetadataLayered = (template, updateTemplateFunction, setStateCallbackFunction, fieldName, selected, metadataTag, metadataValueObj, depth) => {
        let updatedTemplate = {...this.props.template};
        let tempMetadata = {...updatedTemplate.metadata};

        let tempValueObj = JSON.parse(tempMetadata[metadataTag]);
        tempValueObj[fieldName] = metadataValueObj;
        tempMetadata[metadataTag] = JSON.stringify(tempValueObj);
        //let tempName = "";

        switch(fieldName) {
            case "textLeftCustomObj":
            case "textCenterCustomObj":
            case "textRightCustomObj":

                // tempName = "appHeaderObj." + fieldName;
                break;

            case "pageHeaderTextLeftCustomObj":
            case "pageHeaderTextCenterCustomObj":
            case "pageHeaderTextRightCustomObj":
            case "pageFooterTextLeftCustomObj":
            case "pageFooterTextCenterCustomObj":
            case "pageFooterTextRightCustomObj":
            case "pageFooter2TextLeftCustomObj":
            case "pageFooter2TextCenterCustomObj":
            case "pageFooter2TextRightCustomObj":

                // tempName = "mainPagesObj." + fieldName;
                break;

            // no default
        }

        this.setState({tempName: metadataValueObj});

        this.handleMetadataChange(tempMetadata);
    };


    checkboxChange = (entryName, e, selected) => {

        if (selected) {
            this.setState({[entryName]: true}, () => {this.setMetadata(portal.ALIGN_QUESTION_ANSWER_RIGHT, "")});
        }else {
            this.setState({[entryName]: false}, () => {this.deleteMetadata(portal.ALIGN_QUESTION_ANSWER_RIGHT, "")});
        }

    };


    onSelectChange = (event, fieldName, menuItemText) => {

        let selected = event.target.value;

        let tempObj = null;
        let tempObjName = "";
        let relatedTempObj = null;
        let tempSelected = "custom";

        let tempTab0 = this.state.tab0;
        let tempTab1 = this.state.tab1;
        let tempTab2 = this.state.tab2;
        let tempTab3 = this.state.tab3;
        let tempTab4 = this.state.tab4;
        let tempTab5 = this.state.tab5;

        let tempMetadataField = "questionnaireCustom";
        let tempMetadataName = "custom-ddo-questionnaire-format";

        if (fieldName.includes(".")) {
            let parsedArray = fieldName.split(".");

            tempObjName = parsedArray[0];

            tempObj = {...this.state[tempObjName]};

            tempObj[parsedArray[1]] = selected;

            switch(tempObjName) {
                case "themeObj":
                    tempMetadataName = portal.CUSTOM_DDO_QNR_FORMAT_THEME;
                    break;
                case "appHeaderObj":
                    tempMetadataName = portal.CUSTOM_DDO_QNR_FORMAT_APP_HEADER;
                    break;
                case "startPageObj":
                    tempMetadataName = portal.CUSTOM_DDO_QNR_FORMAT_START_PAGE;
                    break;
                case "mainPagesObj":
                    tempMetadataName = portal.CUSTOM_DDO_QNR_FORMAT_MAIN_PAGES;
                    break;
                case "closePageObj":
                    tempMetadataName = portal.CUSTOM_DDO_QNR_FORMAT_CLOSE_PAGE;
                    break;
                case "alertsObj":
                    tempMetadataName = portal.CUSTOM_DDO_QNR_FORMAT_ALERTS;
                    break;
                // no default
            }
        }

        switch(fieldName) {

            case "theme":
                tempTab0 = "THEME (" + selected.charAt(0).toUpperCase() + selected.slice(1) + ")";
                tempMetadataField = "theme";
                tempMetadataName = portal.CUSTOM_DDO_QNR_FORMAT_THEME;

                relatedTempObj = (selected === "primary") ? "" : portal.getQuestionnaireThemeObj(selected);

                tempSelected = (selected === "primary") ? "default" : "custom"; // Override for setMetadata to remove metadata
                break;

            case "themeObj.defaultText":
                tempObj["defaultTextCustomObj"] = (selected === "custom") ? portal.getTextFormatCustomObj() : "";
                break;

            case "themeObj.defaultButton1":
                tempObj["defaultButton1CustomObj"] = (selected === "custom") ? portal.getButtonFormatCustomObj() : "";
                break;

            case "themeObj.defaultButton2":
                tempObj["defaultButton2CustomObj"] = (selected === "custom") ? portal.getButtonFormatCustomObj() : "";
                break;

            case "appHeader":
                tempTab1 = "HEADER (" + selected.charAt(0).toUpperCase() + selected.slice(1) + ")";
                tempMetadataField = "appHeader";
                tempMetadataName = portal.CUSTOM_DDO_QNR_FORMAT_APP_HEADER;

                relatedTempObj = (selected === "no") ? "" : portal.getQuestionnaireAppHeaderObj();

                tempSelected = (selected === "no") ? "default" : "custom"; // Override for setMetadata to remove metadata
                break;

            case "appHeaderObj.textLeftType":
                if (selected !== "image") {
                    this.deleteMetadata(portal.IMAGE_APP_TITLE_BAR_LEFT);
                }
                break;
            case "appHeaderObj.textCenterType":
                if (selected !== "image") {
                    this.deleteMetadata(portal.IMAGE_APP_TITLE_BAR_CENTER);
                }
                break;
            case "appHeaderObj.textRightType":
                if (selected !== "image") {
                    this.deleteMetadata(portal.IMAGE_APP_TITLE_BAR_RIGHT);
                }
                break;

            case "appHeaderObj.textLeftFormat":
                tempObj["textLeftCustomObj"] = (selected === "custom") ? portal.getTextFormatCustomObj() : "";
                break;
            case "appHeaderObj.textCenterFormat":
                tempObj["textCenterCustomObj"] = (selected === "custom") ? portal.getTextFormatCustomObj() : "";
                break;
            case "appHeaderObj.textRightFormat":
                tempObj["textRightCustomObj"] = (selected === "custom") ? portal.getTextFormatCustomObj() : "";
                break;

            case "mainPagesObj.pageHeaderTextLeftFormat":
                tempObj["pageHeaderTextLeftCustomObj"] = (selected === "custom") ? portal.getTextFormatCustomObj() : "";
                break;
            case "mainPagesObj.pageHeaderTextCenterFormat":
                tempObj["pageHeaderTextCenterCustomObj"] = (selected === "custom") ? portal.getTextFormatCustomObj() : "";
                break;
            case "mainPagesObj.pageHeaderTextRightFormat":
                tempObj["pageHeaderTextRightCustomObj"] = (selected === "custom") ? portal.getTextFormatCustomObj() : "";
                break;

            case "mainPagesObj.pageFooterTextLeftFormat":
                tempObj["pageFooterTextLeftCustomObj"] = (selected === "custom") ? portal.getTextFormatCustomObj() : "";
                break;
            case "mainPagesObj.pageFooterTextCenterFormat":
                tempObj["pageFooterTextCenterCustomObj"] = (selected === "custom") ? portal.getTextFormatCustomObj() : "";
                break;
            case "mainPagesObj.pageFooterTextRightFormat":
                tempObj["pageFooterTextRightCustomObj"] = (selected === "custom") ? portal.getTextFormatCustomObj() : "";
                break;

            case "mainPagesObj.pageFooter2TextLeftFormat":
                tempObj["pageFooter2TextLeftCustomObj"] = (selected === "custom") ? portal.getTextFormatCustomObj() : "";
                break;
            case "mainPagesObj.pageFooter2TextCenterFormat":
                tempObj["pageFooter2TextCenterCustomObj"] = (selected === "custom") ? portal.getTextFormatCustomObj() : "";
                break;
            case "mainPagesObj.pageFooter2TextRightFormat":
                tempObj["pageFooter2TextRightCustomObj"] = (selected === "custom") ? portal.getTextFormatCustomObj() : "";
                break;

            case "startPage":
                if (selected === "no") {
                    tempTab2 = "START PAGE (No)"
                    tempSelected = "None" // Force removal of metadata
                } else {
                    tempTab2 = "START PAGE (Default)"
                }
                tempMetadataField = "startPage";
                tempMetadataName = portal.CUSTOM_DDO_QNR_FORMAT_START_PAGE;

                relatedTempObj = (selected === "no") ? "" : portal.getQuestionnaireStartPageObj();
                break;

            case "startPageObj.format":
                if (this.state.startPage === "no") {
                    tempTab2 = "START PAGE (No)"
                } else {
                    tempTab2 = "START PAGE (" + selected.charAt(0).toUpperCase() + selected.slice(1) + ")";
                }
                break;

            case "mainPages":
                tempTab3 = "MAIN PAGES (" + selected.charAt(0).toUpperCase() + selected.slice(1) + ")";
                tempMetadataField = "mainPages";
                tempMetadataName = portal.CUSTOM_DDO_QNR_FORMAT_MAIN_PAGES;

                relatedTempObj = (selected === "defaut") ? "" : portal.getQuestionnaireMainPagesObj();
                break;

            case "closePage":
                if (selected === "no") {
                    tempTab4 = "CLOSE PAGE (No)";
                    tempSelected = "None" // Force removal of metadata
                } else {
                    tempTab4 = "CLOSE PAGE (Default)";
                }
                tempMetadataField = "closePage";
                tempMetadataName = portal.CUSTOM_DDO_QNR_FORMAT_CLOSE_PAGE;

                relatedTempObj = (selected === "no") ? "" : portal.getQuestionnaireClosePageObj();
                break;

            case "closePageObj.format":
                if (this.state.closePage === "no") {
                    tempTab4 = "CLOSE PAGE (No)";
                } else {
                    tempTab4 = "CLOSE PAGE (" + selected.charAt(0).toUpperCase() + selected.slice(1) + ")";
                }
                break;

            // case "alerts":
            //     if (selected === "custom") {
            //         tempTab5 = "ALERTS (Custom)";
            //     } else {
            //         tempTab5 = "ALERTS (" + selected.charAt(0).toUpperCase() + selected.slice(1) + " Defaults)";
            //     }
            //     tempMetadataField = "alerts";
            //     tempMetadataName = portal.CUSTOM_DDO_QNR_FORMAT_ALERTS;
            //
            //     relatedTempObj = portal.getQuestionnaireAlertsObj(selected);
            //     break;


            case "mainPagesObj.questionNumbering":
                if (selected === "none") {
                    tempObj["questionNums"] = "#";
                    tempObj["questionNumsLikert"] = "none";
                    tempObj["questionNumsShowGroup"] = "none";
                }
                break;

            // no default

        }

        if (tempObj === null) {
            this.setState({tab0: tempTab0, tab1: tempTab1, tab2: tempTab2, tab3: tempTab3, tab4: tempTab4, tab5: tempTab5, [fieldName]: selected, [fieldName + "Obj"]: relatedTempObj}, () => this.setCustomMetadata(tempMetadataField, tempSelected, tempMetadataName, relatedTempObj));
        }
        else {
            this.setState({tab0: tempTab0, tab1: tempTab1, tab2: tempTab2, tab3: tempTab3, tab4: tempTab4, tab5: tempTab5, [tempObjName]: tempObj}, () => this.setCustomMetadata(tempMetadataField, tempSelected, tempMetadataName, tempObj));
        }

    };

    deleteMetadata = (metadataTag) => {

        let updatedTemplate = {...this.props.template};
        let tempMetadata = {...updatedTemplate.metadata};

        if (tempMetadata.hasOwnProperty(metadataTag)) {
            delete tempMetadata[metadataTag];
        }

        this.handleMetadataChange(tempMetadata);
    };


    setImageMetadata = (metadataTag, metadataValueStr) => {

        let updatedTemplate = {...this.props.template};
        let tempMetadata = {...updatedTemplate.metadata};


        tempMetadata[metadataTag] = metadataValueStr;


        this.handleMetadataChange(tempMetadata);
    };


    onChangeReportingCategory = (event, fieldName, menuItemText) => {
        let index = event.target.value;
        let selected = this.state.reportingCategories[index];  // Note: "None: has been added to thee metadata list this.state.reportingCategories, so metadata needs to adjust

        this.setState({[fieldName]: index}, () => metadataUtils.setCustomMetadata(this.props.template, this.props.updateQuestionnaireTemplate, this.setStateCallback,"reportingCategory", selected, metadata.REPORTING_CATEGORY_QUESTIONNAIRE, selected));
    };


    onObjDotTextChange = (event, objDotFieldName) => {

        let value = event.target.value;

        let arrayObjAndFieldName = objDotFieldName.split("."); // make array with objName and fieldName

        let objName = arrayObjAndFieldName[0];
        let fieldName = arrayObjAndFieldName[1];

        let tempMetadataName = "";

        let tempObj = {...this.state[objName]}

        tempObj[fieldName] = value;

        switch(objName) {
            case "themeObj":
                tempMetadataName = portal.CUSTOM_DDO_QNR_FORMAT_THEME;
                break;
            case "appHeaderObj":
                tempMetadataName = portal.CUSTOM_DDO_QNR_FORMAT_APP_HEADER;
                break;
            case "startPageObj":
                tempMetadataName = portal.CUSTOM_DDO_QNR_FORMAT_START_PAGE;
                break;
            case "mainPagesObj":
                tempMetadataName = portal.CUSTOM_DDO_QNR_FORMAT_MAIN_PAGES;
                break;
            case "closePaqeObj":
                tempMetadataName = portal.CUSTOM_DDO_QNR_FORMAT_CLOSE_PAGE;
                break;
            case "alertsObj":
                tempMetadataName = portal.CUSTOM_DDO_QNR_FORMAT_ALERTS;
                break;
            // no default
        }

        this.setState({[objName]: tempObj}, () => this.setCustomMetadata("portalBypass", "custom", tempMetadataName, tempObj));
    };

    // -----------------------------------------------------------------------------------------------------------------
    // IMAGE UPLOAD FROM ArgoMetadataEditor  ---------------------------------------------------------------------------
    // -----------------------------------------------------------------------------------------------------------------

    handleLogoUploadOnChange = (e, metadataTag) => {
        console.log("file to upload:", e.target.files[0]);
        let file = e.target.files[0];

        console.log("file type:", typeof file);

        if (file) {
            const reader = new FileReader();
            reader.onload = this._handleReaderLoader.bind(this, metadataTag);

            reader.readAsBinaryString(file);
        }

        // this.setState({
        //     currentEntryIndex: entryIndex
        // });
    };

    _handleReaderLoader = (metadataTag, readerEvt) => {
        let binaryString = readerEvt.target.result;

        let base64String = btoa(binaryString);

        this.setState({
            base64TextString: base64String
        });

        this.setImageMetadata(metadataTag, base64String);
        //this.handleOnChange("value", this.state.currentEntryIndex, null, base64String);
    };

    setMetadataErrorText = (metadataErrorText) => {

        this.setState({metadataErrorText: metadataErrorText}, () => this.checkForErrorsOnScreen(this.props));
    }

    onChangeIsThisAnExistingCategory = (event, fieldName, index) => {
        let value = event.target.value;
        let newState = {
            isThisAnExistingCategory: value,
            categoryTemplateSearch: (value)? this.state.categoryTemplateSearch: ""
        };

        this.setState(newState);
    }

    handleTabChange = (event, selectedTabNumber) => {
        this.setState({ selectedTabNumber: selectedTabNumber });
    };

    getTab0_Theme(propsObj) {

        return(
            <div id="QuestionnaireEditor-ThemeTab-container-div" style={{paddingLeft: "20px", paddingBottom: "20px"}}>

                <div>&nbsp;</div>

                <ArgoTextFieldSelectHook
                    fileName="QuestionnaireEditor"
                    fieldName="theme"
                    label="Theme"
                    value={this.state.theme}
                    onChange={this.onSelectChange}
                    width="150px"
                    menuItems={[
                        {value: "primary", text: "Primary"},
                        {value: "secondary", text: "Secondary"},
                        {value: "custom", text: "Custom"}
                    ]}
                />

                <div style={{width: "20px"}}></div>

                {this.state.theme === "custom" ?
                    <React.Fragment>
                        <div style={{width: '95%', padding: '20px 20px 0 20px'}}>
                            <div style={{display: "flex", flexDirection: "row", alignItems: "center", width: "99%", backgroundColor: 'white'}}>

                                <div style={{width: "20px"}}></div>

                                <div style={{padding: '10px 10px 10px 0'}}>
                                    <ArgoTextFieldSelectHook
                                        fileName="QuestionnaireEditor"
                                        fieldName="themeObj.defaultText"
                                        label="Default Text"
                                        value={this.state.themeObj.defaultText}
                                        onChange={this.onSelectChange}
                                        width="150px"
                                        menuItems={arrayDefaultOrCustom}/>
                                </div>

                                <div style={{width: "20px"}}></div>

                                <div style={{width: '99%'}}>
                                    {(this.state.themeObj.defaultText === "custom") ?
                                        <ArgoTextDisplayCustom
                                            fieldName="defaultTextCustomObj"
                                            initToObj={this.state.themeObj.defaultTextCustomObj}
                                            metadataTag={portal.CUSTOM_DDO_QNR_FORMAT_THEME}
                                            showDisplay={false}
                                            backgroundColorOverride={"white"}
                                            opacityFlag={false}
                                            metadataPropsObj={Object.assign({metadataTag: "custom-ddo-questionnaire-format-theme"}, propsObj)}
                                        />
                                        : ""
                                    }
                                </div>
                            </div>

                            <div style={{display: "flex", flexDirection: "row", alignItems: "left", width: "99%", backgroundColor: 'white'}}>

                                <div style={{width: "20px"}}></div>

                                <div style={{padding: '10px 10px 10px 0'}}>
                                    <ArgoTextFieldSelectHook
                                        fileName="QuestionnaireEditor"
                                        fieldName="themeObj.defaultButton1"
                                        label="Buttons (Primary)"
                                        value={this.state.themeObj.defaultButton1}
                                        onChange={this.onSelectChange}
                                        width="150px"
                                        menuItems={arrayDefaultOrCustom}/>
                                </div>

                                <div style={{width: "20px"}}></div>

                                <div style={{width: '99%'}}>
                                    {(this.state.themeObj.defaultButton1 === "custom") ?
                                        <ArgoButtonDisplayCustom
                                            fieldName="defaultButton1CustomObj"
                                            initToObj={this.state.themeObj.defaultButton1CustomObj}
                                            metadataTag={portal.CUSTOM_DDO_QNR_FORMAT_THEME}
                                            showDisplay={true}
                                            backgroundColorOverride={"white"}
                                            opacityFlag={false}
                                            setCustomMetadata={this.setCustomMetadataLayered.bind(this)}/>
                                        : ""
                                    }
                                </div>
                            </div>

                            <div style={{display: "flex", flexDirection: "row", alignItems: "left", width: "99%", backgroundColor: 'white'}}>

                                <div style={{width: "20px"}}></div>

                                <div style={{padding: '10px 10px 10px 0'}}>
                                    <ArgoTextFieldSelectHook
                                        fileName="QuestionnaireEditor"
                                        fieldName="themeObj.defaultButton2"
                                        label="Buttons (Secondary)"
                                        value={this.state.themeObj.defaultButton2}
                                        onChange={this.onSelectChange}
                                        width="150px"
                                        menuItems={arrayDefaultOrCustom}/>
                                </div>

                                <div style={{width: "20px"}}></div>

                                <div style={{width: '99%'}}>
                                    {(this.state.themeObj.defaultButton2 === "custom") ?
                                        <ArgoButtonDisplayCustom
                                            fieldName="defaultButton2CustomObj"
                                            initToObj={this.state.themeObj.defaultButton2CustomObj}
                                            metadataTag={portal.CUSTOM_DDO_QNR_FORMAT_THEME}
                                            showDisplay={true}
                                            backgroundColorOverride={"white"}
                                            setCustomMetadata={this.setCustomMetadataLayered.bind(this)}/>
                                        : ""
                                    }
                                </div>
                            </div>
                        </div>
                    </React.Fragment> : ""
                }
            </div>
        );
    }

    getTab1_Header(propsObj) {

        let appHeaderLeftImage = null;
        let appHeaderCenterImage = null;
        let appHeaderRightImage = null;

        let tempMetadata = {...this.props.template.metadata};

        if (tempMetadata.hasOwnProperty(portal.IMAGE_APP_TITLE_BAR_LEFT)) {
            appHeaderLeftImage = tempMetadata[portal.IMAGE_APP_TITLE_BAR_LEFT];
        }

        if (tempMetadata.hasOwnProperty(portal.IMAGE_APP_TITLE_BAR_CENTER)) {
            appHeaderCenterImage = tempMetadata[portal.IMAGE_APP_TITLE_BAR_CENTER];
        }

        if (tempMetadata.hasOwnProperty(portal.IMAGE_APP_TITLE_BAR_RIGHT)) {
            appHeaderRightImage = tempMetadata[portal.IMAGE_APP_TITLE_BAR_RIGHT];
        }

        return (
            <div id="QuestionnaireEditor-HeaderTab-container-div" style={{paddingLeft: "20px", paddingBottom: "20px"}}>

                <div>&nbsp;</div>

                <ArgoTextFieldSelectHook
                    fileName="QuestionnaireEditor"
                    fieldName="appHeader"
                    label="Application Header"
                    value={this.state.appHeader}
                    onChange={this.onSelectChange}
                    width="150px"
                    menuItems={arrayNoOrYes}
                />

                <div style={{width: "20px"}}></div>

                {this.state.appHeader === "yes" ?
                    <React.Fragment>
                        <div style={{width: '95%', padding: "20px 20px 0 20px"}}>
                            <div style={{display: "flex", alignItems: "left", width: '99%', backgroundColor: 'white'}}>
                                <div style={{width: "20px"}}></div>

                                <div style={{padding: '10px 10px 10px 0'}}>
                                    <ArgoTextFieldSelectHook
                                        fileName="QuestionnaireEditor"
                                        fieldName="appHeaderObj.textLeftType"
                                        label="Left"
                                        value={this.state.appHeaderObj.textLeftType}
                                        onChange={this.onSelectChange}
                                        width="80px"
                                        menuItems={arrayNoneOrTextOrImage}
                                    /></div>

                                <div style={{width: "20px"}}></div>

                                {this.state.appHeaderObj.textLeftType === "none" ?
                                    <div style={{paddingTop: "20px", display: "flex", alignItems: "left", width: '99%', backgroundColor: 'white'}}>
                                    </div> : ""
                                }

                                {this.state.appHeaderObj.textLeftType === "image" ?
                                    <div style={{paddingTop: "30px", display: "flex", alignItems: "left", width: '99%', backgroundColor: 'white'}}>

                                        <form onChange={(e) => this.handleLogoUploadOnChange(e, portal.IMAGE_APP_TITLE_BAR_LEFT)}>
                                            <input
                                                type="file"
                                                name="image"
                                                id="file"
                                                accept=".jpeg, .png, .jpg"
                                            />
                                        </form>
                                        {appHeaderLeftImage === null ? "" :
                                            <img src={`data:image/png;base64,${appHeaderLeftImage}`}
                                                 alt="" style={{
                                                verticalAlign: 'middle',
                                                height: '60px'
                                            }}/>
                                        }

                                        {/*<Button id="QuestionnaireEditor-app-header-image-left-upload" name="QuestionnaireEditor-app-header-image-left-upload" variant="contained" */}
                                        {/*              onClick={function() {}}*/}
                                        {/*              style={{minWidth: "100px"}}>Upload</Button>*/}
                                    </div> : ""
                                }

                                {this.state.appHeaderObj.textLeftType === "text" ?
                                    <div style={{display: "flex", alignItems: "left", width: '99%', backgroundColor: 'white', paddingTop: '10px'}}>

                                        <ArgoTextFieldHook
                                            fileName="QuestionnaireEditor"
                                            fieldName="appHeaderObj.textLeft"
                                            label="Left Text"
                                            value={this.state.appHeaderObj.textLeft}
                                            onChange={this.onObjDotTextChange}
                                            width="200px"
                                            maxLength={40}
                                        />

                                        <div style={{width: "15px"}}></div>

                                        <ArgoTextFieldSelectHook
                                            fileName="QuestionnaireEditor"
                                            fieldName="appHeaderObj.textLeftFormat"
                                            label="Format"
                                            value={this.state.appHeaderObj.textLeftFormat}
                                            onChange={this.onSelectChange}
                                            width="90px"
                                            menuItems={arrayDefaultOrCustom}
                                        />

                                        <div style={{width: '99%'}}>
                                            {(this.state.appHeaderObj.textLeftFormat === 'custom') ?
                                                <ArgoTextDisplayCustom
                                                    fieldName="textLeftCustomObj"
                                                    initToObj={this.state.appHeaderObj.textLeftCustomObj}
                                                    metadataTag={portal.CUSTOM_DDO_QNR_FORMAT_APP_HEADER}
                                                    showDisplay={false}
                                                    backgroundColorOverride={"white"}
                                                    metadataPropsObj={Object.assign({metadataTag: "custom-ddo-questionnaire-format-app-header"}, propsObj)}
                                                    useMetadataPropsObjAltFunction={true}
                                                    largeHexEntry={false}
                                                    paddingTop={'0'}
                                                    setCustomMetadata={this.setCustomMetadata}
                                                />
                                                : ''
                                            }
                                        </div>
                                    </div> : ""
                                }
                            </div>

                            <div style={{display: "flex", alignItems: "left", width: '99%', backgroundColor: 'white'}}>
                                <div style={{width: "20px"}}></div>

                                <div style={{padding: '10px 10px 10px 0'}}>
                                    <ArgoTextFieldSelectHook
                                        fileName="QuestionnaireEditor"
                                        fieldName="appHeaderObj.textCenterType"
                                        label="Center"
                                        value={this.state.appHeaderObj.textCenterType}
                                        onChange={this.onSelectChange}
                                        width="80px"
                                        menuItems={arrayNoneOrTextOrImage}
                                    /></div>

                                <div style={{width: "20px"}}></div>

                                {this.state.appHeaderObj.textCenterType === "none" ?
                                    <div style={{paddingTop: "22px", display: "flex", alignItems: "left", width: '99%', backgroundColor: 'white'}}>
                                    </div> : ""
                                }

                                {this.state.appHeaderObj.textCenterType === "image" ?
                                    <div style={{paddingTop: "30px", display: "flex", alignItems: "left", width: '99%', backgroundColor: 'white'}}>

                                        <form onChange={(e) => this.handleLogoUploadOnChange(e, portal.IMAGE_APP_TITLE_BAR_CENTER)}>
                                            <input
                                                type="file"
                                                name="image"
                                                id="file"
                                                accept=".jpeg, .png, .jpg"
                                            />
                                        </form>
                                        {appHeaderCenterImage === null ? "" :
                                            <img src={`data:image/png;base64,${appHeaderCenterImage}`}
                                                 alt="" style={{
                                                verticalAlign: 'middle',
                                                height: '60px'
                                            }}/>
                                        }

                                        {/*<Button id="QuestionnaireEditor-app-header-image-center-upload" name="QuestionnaireEditor-app-header-image-center-upload" variant="contained" */}
                                        {/*              onClick={function() {}}*/}
                                        {/*              style={{minWidth: "100px"}}>Upload</Button>*/}
                                    </div> : ""
                                }

                                {this.state.appHeaderObj.textCenterType === "text" ?

                                    <div style={{display: "flex", alignItems: "left", width: '99%', backgroundColor: 'white', paddingTop: '10px'}}>

                                        <ArgoTextFieldHook
                                            fileName="QuestionnaireEditor"
                                            fieldName="appHeaderObj.textCenter"
                                            label="Center Text"
                                            value={this.state.appHeaderObj.textCenter}
                                            onChange={this.onObjDotTextChange}
                                            width="200px"
                                            maxLength={40}
                                        />

                                        <div style={{width: "15px"}}></div>

                                        <ArgoTextFieldSelectHook
                                            fileName="QuestionnaireEditor"
                                            fieldName="appHeaderObj.textCenterFormat"
                                            label="Format"
                                            value={this.state.appHeaderObj.textCenterFormat}
                                            onChange={this.onSelectChange}
                                            width="90px"
                                            menuItems={arrayDefaultOrCustom}
                                        />

                                        <div style={{width: '99%'}}>
                                            {(this.state.appHeaderObj.textCenterFormat === 'custom') ?
                                                <ArgoTextDisplayCustom
                                                    fieldName="textCenterCustomObj"
                                                    initToObj={this.state.appHeaderObj.textCenterCustomObj}
                                                    metadataTag={portal.CUSTOM_DDO_QNR_FORMAT_APP_HEADER}
                                                    showDisplay={false}
                                                    backgroundColorOverride={"white"}
                                                    metadataPropsObj={Object.assign({metadataTag: "custom-ddo-questionnaire-format-app-header"}, propsObj)}
                                                    useMetadataPropsObjAltFunction={true}
                                                    largeHexEntry={false}
                                                    paddingTop={'0'}
                                                    setCustomMetadata={this.setCustomMetadata}
                                                />
                                                :
                                                <div></div>
                                            }
                                        </div>
                                    </div> : ""
                                }
                            </div>

                            <div style={{display: "flex", alignItems: "left", width: '99%', backgroundColor: 'white'}}>
                                <div style={{width: "20px"}}></div>

                                <div style={{padding: '10px 10px 10px 0'}}>
                                    <ArgoTextFieldSelectHook
                                        fileName="QuestionnaireEditor"
                                        fieldName="appHeaderObj.textRightType"
                                        label="Right"
                                        value={this.state.appHeaderObj.textRightType}
                                        onChange={this.onSelectChange}
                                        width="80px"
                                        menuItems={arrayNoneOrTextOrImage}
                                    /></div>

                                <div style={{width: "20px"}}></div>

                                {this.state.appHeaderObj.textRightType === "none" ?
                                    <div style={{paddingTop: "20px", display: "flex", alignItems: "left", width: '99%', backgroundColor: 'white'}}>
                                    </div> : ""
                                }

                                {this.state.appHeaderObj.textRightType === "image" ?
                                    <div style={{paddingTop: "30px", display: "flex", alignItems: "left", width: '99%', backgroundColor: 'white'}}>

                                        <form onChange={(e) => this.handleLogoUploadOnChange(e, portal.IMAGE_APP_TITLE_BAR_RIGHT)}>
                                            <input
                                                type="file"
                                                name="image"
                                                id="file"
                                                accept=".jpeg, .png, .jpg"
                                            />
                                        </form>
                                        {appHeaderRightImage === null ? "" :
                                            <img src={`data:image/png;base64,${appHeaderRightImage}`}
                                                 alt="" style={{
                                                verticalAlign: 'middle',
                                                height: '60px'
                                            }}/>
                                        }

                                        {/*<Button id="QuestionnaireEditor-app-header-icon-right-upload" name="QuestionnaireEditor-app-header-icon-right-upload" variant="contained" */}
                                        {/*              label="Upload"*/}
                                        {/*              onClick={function() {}}*/}
                                        {/*              style={{minWidth: "100px"}}/>*/}
                                    </div> : ""
                                }

                                {this.state.appHeaderObj.textRightType === "text" ?

                                    <div style={{display: "flex", alignItems: "left", width: '99%', backgroundColor: 'white', paddingTop: '10px'}}>

                                        <ArgoTextFieldHook
                                            fileName="QuestionnaireEditor"
                                            fieldName="appHeaderObj.textRight"
                                            label="Right Text"
                                            value={this.state.appHeaderObj.textRight}
                                            onChange={this.onObjDotTextChange}
                                            width="200px"
                                            maxLength={40}
                                        />

                                        <div style={{width: "15px"}}></div>

                                        <ArgoTextFieldSelectHook
                                            fileName="QuestionnaireEditor"
                                            fieldName="appHeaderObj.textRightFormat"
                                            label="Format"
                                            value={this.state.appHeaderObj.textRightFormat}
                                            onChange={this.onSelectChange}
                                            width="90px"
                                            menuItems={arrayDefaultOrCustom}
                                        />

                                        <div style={{width: '99%'}}>
                                            {(this.state.appHeaderObj.textRightFormat === 'custom') ?
                                                <ArgoTextDisplayCustom
                                                    fieldName="textRightCustomObj"
                                                    initToObj={this.state.appHeaderObj.textRightCustomObj}
                                                    metadataTag={portal.CUSTOM_DDO_QNR_FORMAT_APP_HEADER}
                                                    showDisplay={false}
                                                    backgroundColorOverride={"white"}
                                                    metadataPropsObj={Object.assign({metadataTag: "custom-ddo-questionnaire-format-app-header"}, propsObj)}
                                                    useMetadataPropsObjAltFunction={true}
                                                    largeHexEntry={false}
                                                    paddingTop={'0'}
                                                    setCustomMetadata={this.setCustomMetadata}
                                                />
                                                :
                                                <div></div>
                                            }
                                        </div>

                                    </div> : ""
                                }
                            </div>
                        </div>
                    </React.Fragment> : ""
                }

            </div>
        );
    }

    getTab2_StartPage(propsObj) {

        return (
            <div id="QuestionnaireEditor-StartPageTab-container-div" style={{paddingLeft: "20px", paddingBottom: "20px"}}>

                <div>&nbsp;</div>

                <div style={{display: "flex", alignItems: "left", width: '99%'}}>

                    <ArgoTextFieldSelectHook
                        fileName="QuestionnaireEditor"
                        fieldName="startPage"
                        label="Start Page"
                        value={this.state.startPage}
                        onChange={this.onSelectChange}
                        width="150px"
                        menuItems={arrayNoOrYes}
                    />

                    <div style={{width: "20px"}}></div>

                    {this.state.startPage === "yes" ?
                        <div>
                            <ArgoTextFieldSelectHook
                                fileName="QuestionnaireEditor"
                                fieldName="startPageObj.format"
                                label="Format"
                                value={this.state.startPageObj.format}
                                onChange={this.onSelectChange}
                                width="150px"
                                menuItems={arrayDefaultOrCustom}
                            />
                        </div> : ""
                    }
                </div>

                {this.state.startPageObj !== null && this.state.startPageObj.format === "custom" ?
                    <React.Fragment>
                        <div style={{width: '95%', padding: "20px"}}>

                            <div style={{display: "flex", alignItems: "left", backgroundColor: 'white'}}>
                                <div style={{width: "20px"}}></div>

                                <div style={{flexGrow: 2, padding: '10px 0'}}>

                                    <div style={{display: "flex", alignItems: "left"}}>
                                        <ArgoAutocompleteHook
                                            fileName={'QuestionnaireEditor'}
                                            fieldName={'description'}
                                            label={'Description/Instructions'}
                                            errorText={this.state.descErrorText === " " ? " " : this.state.descErrorText}
                                            variant={'standard'}
                                            maxLength={'255'}
                                            multiline={true}
                                            onChange={this.onChangeArgoAutocompleteHook}
                                            value={this.props.template.description === null ? "" : this.props.template.description}
                                            menuItems={ALLOWED_TOKEN}
                                            width='100%'
                                        />


                                        <ArgoTextDisplayDefination
                                            fieldName="descDisplayDefination"
                                            initTo={this.state.descDisplayDefination}
                                            templateType={TEMPLATE.TYPE.QUESTIONNAIRE}
                                            titleType="description"
                                            metadataPropsObj={Object.assign({metadataTag: portal.CUSTOM_DDO_DESC}, propsObj)}
                                        />
                                    </div>
                                </div>

                            </div>

                            {(this.state.descDisplayDefination === 'custom') ?
                                <ArgoTextDisplayCustom
                                    fieldName="descDisplayDefination"
                                    initToObj={this.state.descDisplayMetadataValueObj}
                                    metadataTag={portal.CUSTOM_DDO_DESC}
                                    showDisplay={false}
                                    backgroundColorOverride={"white"}
                                    displayLabel={"Display (Description)"}
                                    metadataPropsObj={Object.assign({metadataTag: portal.CUSTOM_DDO_CAT_TITLE_BAR_LEFT}, propsObj)}
                                />
                                :
                                <div></div>
                            }

                            <div style={{display: "flex", alignItems: "left", backgroundColor: 'white'}}>

                                <div style={{width: "20px"}}></div>

                                <div style={{display: "flex", alignItems: "left", width: '99%', padding: '10px 0'}}>
                                    <ArgoAutocompleteHook
                                        fileName={'QuestionnaireEditor'}
                                        fieldName={'user-script'}
                                        label={'Script before'}
                                        variant={'standard'}
                                        maxLength={'4095'}
                                        multiline={true}
                                        onChange={this.onChangeArgoAutocompleteHook}
                                        value={this.props.template.metadata["user-script"] || ""}
                                        menuItems={ALLOWED_TOKEN}
                                        width='100%'
                                    />

                                    <ArgoTextDisplayDefination
                                        fieldName="scriptBeforeDisplayDefination"
                                        initTo={this.state.scriptBeforeDisplayDefination}
                                        templateType={TEMPLATE.TYPE.QUESTIONNAIRE}
                                        titleType="scriptBefore"
                                        metadataPropsObj={Object.assign({metadataTag: portal.CUSTOM_DDO_QNR_SCRIPT_BEFORE}, propsObj)}
                                    />
                                </div>
                            </div>

                            {(this.state.scriptBeforeDisplayDefination === 'custom') ?
                                <ArgoTextDisplayCustom
                                    fieldName="scriptBeforeDisplayDefination"
                                    initToObj={this.state.scriptBeforeDisplayMetadataValueObj}
                                    metadataTag={portal.CUSTOM_DDO_QNR_SCRIPT_BEFORE}
                                    showDisplay={false}
                                    backgroundColorOverride={"white"}
                                    displayLabel={"Display (Script Before)"}
                                    metadataPropsObj={Object.assign({metadataTag: portal.CUSTOM_DDO_CAT_TITLE_BAR_LEFT}, propsObj)}
                                />
                                :
                                ""
                            }

                        </div>
                    </React.Fragment> : ""
                }

            </div>
        );
    }

    getTab3_MainPages(propsObj) {

        return (
            <div id="QuestionnaireEditor-MainPagesTab-container-div" style={{paddingLeft: "20px", paddingBottom: "20px"}}>

                <div>&nbsp;</div>

                <ArgoTextFieldSelectHook
                    fileName="QuestionnaireEditor"
                    fieldName="mainPages"
                    label="Main Pages Format"
                    value={this.state.mainPages}
                    onChange={this.onSelectChange}
                    width="150px"
                    menuItems={arrayDefaultOrCustom}
                />

                <div style={{width: "20px"}}></div>

                {this.state.mainPages === "custom" ?
                    <React.Fragment>
                        <div style={{width: '95%', padding: "20px 20px 0 20px"}}>
                            <div style={{display: "flex", alignItems: "left", width: '99%', backgroundColor: 'white'}}>
                                <div style={{width: "20px"}}></div>

                                <div style={{display: "flex", alignItems: "left", width: '99%', backgroundColor: 'white'}}>
                                    <div style={{padding: '10px 10px 10px 0'}}>
                                        <ArgoTextFieldSelectHook
                                            fileName="QuestionnaireEditor"
                                            fieldName="mainPagesObj.pagingMode"
                                            label="Paging"
                                            value={this.state.mainPagesObj.pagingMode}
                                            onChange={this.onSelectChange}
                                            width="220px"
                                            menuItems={[
                                                {value: "full", text: "Full Page"},
                                                {value: "one", text: "One Question Per Page"}
                                            ]}/>
                                    </div>

                                    <div style={{width: '20px'}}></div>

                                    <div style={{paddingTop: '10px'}}>
                                        <ArgoTextFieldSelectHook
                                            fileName="QuestionnaireEditor"
                                            fieldName="mainPagesObj.answeredFirstQuestion"
                                            label="Answered First Question"
                                            value={this.state.mainPagesObj.answeredFirstQuestion}
                                            onChange={this.onSelectChange}
                                            width="220px"
                                            menuItems={[
                                                {value: "hide", text: "Hide"},
                                                {value: "show", text: "Show"}
                                            ]}/>
                                    </div>

                                    <div style={{width: '20px'}}></div>

                                    <div style={{paddingTop: '10px'}}>
                                        <ArgoTextFieldSelectHook
                                            fileName="QuestionnaireEditor"
                                            fieldName="mainPagesObj.questionNumbering"
                                            label="Question Numbering"
                                            value={this.state.mainPagesObj.questionNumbering}
                                            onChange={this.onSelectChange}
                                            width="200px"
                                            menuItems={[
                                                {value: "none", text: "None"},
                                                {value: "all", text: "All Pages"},
                                                {value: "page", text: "Reset Each Page"}
                                            ]}/>
                                    </div>

                                    <div style={{width: '20px'}}></div>

                                    {this.state.mainPagesObj.questionNumbering !== "none" ?
                                        <React.Fragment>

                                            <div style={{paddingTop: '10px'}}>
                                                <ArgoTextFieldSelectHook
                                                    fileName="QuestionnaireEditor"
                                                    fieldName="mainPagesObj.questionNums"
                                                    label="Question Numbers"
                                                    value={this.state.mainPagesObj.questionNums}
                                                    onChange={this.onSelectChange}
                                                    width="200px"
                                                    menuItems={[
                                                        {value: "#", text: "#"},
                                                        {value: "#.", text: "#."},
                                                        {value: "#)", text: "#)"}
                                                    ]}/>
                                            </div>

                                            <div style={{width: '15px'}}></div>

                                            <div style={{paddingTop: '10px'}}>
                                                <ArgoTextFieldSelectHook
                                                    fileName="QuestionnaireEditor"
                                                    fieldName="mainPagesObj.questionNumsLikert"
                                                    label="Likert Sub-Numbers"
                                                    value={this.state.mainPagesObj.questionNumsLikert}
                                                    onChange={this.onSelectChange}
                                                    width="200px"
                                                    menuItems={arrayQuestionSubNumberFormats}/>
                                            </div>

                                            <div style={{width: '15px'}}></div>

                                            <div style={{paddingTop: '10px'}}>
                                                <ArgoTextFieldSelectHook
                                                    fileName="QuestionnaireEditor"
                                                    fieldName="mainPagesObj.questionNumsShowGroup"
                                                    label="Condition Sub-Numbers"
                                                    value={this.state.mainPagesObj.questionNumsShowGroup}
                                                    onChange={this.onSelectChange}
                                                    width="200px"
                                                    menuItems={arrayQuestionSubNumberFormats}/>
                                            </div>
                                        </React.Fragment> : ""
                                    }
                                </div>
                            </div>

                            <div style={{display: "flex", alignItems: "left", width: '99%', backgroundColor: 'white'}}>
                                <div style={{width: "20px"}}></div>

                                <div style={{padding: '10px 10px 10px 0'}}>
                                    <ArgoTextFieldSelectHook
                                        fileName="QuestionnaireEditor"
                                        fieldName="mainPagesObj.pageHeader"
                                        label="Header"
                                        value={this.state.mainPagesObj.pageHeader}
                                        onChange={this.onSelectChange}
                                        width="120px"
                                        menuItems={arrayNoOrYes}/>
                                </div>

                                <div style={{width: "20px"}}></div>

                                <div style={{width: '99%'}}>

                                    {this.state.mainPagesObj.pageHeader === "yes" ?
                                        <React.Fragment>
                                            <div style={{width: '99%'}}>
                                                <div style={{display: "flex", alignItems: "left", width: '99%', backgroundColor: 'white'}}>
                                                    <div style={{width: "20px"}}></div>

                                                    <div style={{paddingTop: '10px'}}>
                                                        <ArgoTextFieldHook
                                                            fileName="QuestionnaireEditor"
                                                            fieldName="mainPagesObj.pageHeaderTextLeft"
                                                            label="Left Text"
                                                            value={this.state.mainPagesObj.pageHeaderTextLeft}
                                                            onChange={this.onObjDotTextChange}
                                                            width="200px"
                                                            maxLength={40}/>
                                                    </div>

                                                    <div style={{width: "20px"}}></div>

                                                    <div style={{paddingTop: '10px'}}>
                                                        <ArgoTextFieldSelectHook
                                                            fileName="QuestionnaireEditor"
                                                            fieldName="mainPagesObj.pageHeaderTextLeftFormat"
                                                            label="Format"
                                                            value={this.state.mainPagesObj.pageHeaderTextLeftFormat}
                                                            onChange={this.onSelectChange}
                                                            width="150px"
                                                            menuItems={arrayDefaultOrCustom}/>
                                                    </div>

                                                    <div style={{width: '99%'}}>
                                                        {(this.state.mainPagesObj.pageHeaderTextLeftFormat === 'custom') ?
                                                            <ArgoTextDisplayCustom
                                                                fieldName="pageHeaderTextLeftCustomObj"
                                                                initToObj={this.state.mainPagesObj.pageHeaderTextLeftCustomObj}
                                                                metadataTag={portal.CUSTOM_DDO_QNR_FORMAT_MAIN_PAGES}
                                                                showDisplay={false}
                                                                backgroundColorOverride={"white"}
                                                                metadataPropsObj={Object.assign({metadataTag: "custom-ddo-questionnaire-format-main-pages"}, propsObj)}
                                                                useMetadataPropsObjAltFunction={true}
                                                            />
                                                            : ""
                                                        }
                                                    </div>
                                                </div>

                                                <div style={{display: "flex", alignItems: "left", width: '99%', backgroundColor: 'white'}}>
                                                    <div style={{width: "20px"}}></div>

                                                    <div style={{paddingTop: '10px'}}>
                                                        <ArgoTextFieldHook
                                                            fileName="QuestionnaireEditor"
                                                            fieldName="mainPagesObj.pageHeaderTextCenter"
                                                            label="Center Text"
                                                            value={this.state.mainPagesObj.pageHeaderTextCenter}
                                                            onChange={this.onObjDotTextChange}
                                                            width="200px"
                                                            maxLength={40}/>
                                                    </div>

                                                    <div style={{width: "20px"}}></div>

                                                    <div style={{paddingTop: '10px'}}>
                                                        <ArgoTextFieldSelectHook
                                                            fileName="QuestionnaireEditor"
                                                            fieldName="mainPagesObj.pageHeaderTextCenterFormat"
                                                            label="Format"
                                                            value={this.state.mainPagesObj.pageHeaderTextCenterFormat}
                                                            onChange={this.onSelectChange}
                                                            width="150px"
                                                            menuItems={arrayDefaultOrCustom}/>
                                                    </div>

                                                    <div style={{width: '99%'}}>
                                                        {(this.state.mainPagesObj.pageHeaderTextCenterFormat === 'custom') ?
                                                            <ArgoTextDisplayCustom
                                                                fieldName="pageHeaderTextCenterCustomObj"
                                                                initToObj={this.state.mainPagesObj.pageHeaderTextCenterCustomObj}
                                                                metadataTag={portal.CUSTOM_DDO_QNR_FORMAT_MAIN_PAGES}
                                                                showDisplay={false}
                                                                backgroundColorOverride={"white"}
                                                                metadataPropsObj={Object.assign({metadataTag: "custom-ddo-questionnaire-format-main-pages"}, propsObj)}
                                                                useMetadataPropsObjAltFunction={true}
                                                            />
                                                            :
                                                            <div></div>
                                                        }
                                                    </div>
                                                </div>

                                                <div style={{display: "flex", alignItems: "left", width: '99%', backgroundColor: 'white'}}>
                                                    <div style={{width: "20px"}}></div>

                                                    <div style={{paddingTop: '10px'}}>
                                                        <ArgoTextFieldHook
                                                            fileName="QuestionnaireEditor"
                                                            fieldName="mainPagesObj.pageHeaderTextRight"
                                                            label="Right Text"
                                                            value={this.state.mainPagesObj.pageHeaderTextRight}
                                                            onChange={this.onObjDotTextChange}
                                                            width="200px"
                                                            maxLength={40}/>
                                                    </div>

                                                    <div style={{width: "20px"}}></div>

                                                    <div style={{paddingTop: '10px'}}>
                                                        <ArgoTextFieldSelectHook
                                                            fileName="QuestionnaireEditor"
                                                            fieldName="mainPagesObj.pageHeaderTextRightFormat"
                                                            label="Format"
                                                            value={this.state.mainPagesObj.pageHeaderTextRightFormat}
                                                            onChange={this.onSelectChange}
                                                            width="150px"
                                                            menuItems={arrayDefaultOrCustom}/>
                                                    </div>

                                                    <div style={{width: '99%'}}>
                                                        {(this.state.mainPagesObj.pageHeaderTextRightFormat === 'custom') ?
                                                            <ArgoTextDisplayCustom
                                                                fieldName="pageHeaderTextRightCustomObj"
                                                                initToObj={this.state.mainPagesObj.pageHeaderTextRightCustomObj}
                                                                metadataTag={portal.CUSTOM_DDO_QNR_FORMAT_MAIN_PAGES}
                                                                showDisplay={false}
                                                                backgroundColorOverride={"white"}
                                                                metadataPropsObj={Object.assign({metadataTag: "custom-ddo-questionnaire-format-main-pages"}, propsObj)}
                                                                useMetadataPropsObjAltFunction={true}
                                                            />
                                                            :
                                                            <div></div>
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </React.Fragment> : ""
                                    }
                                </div>
                            </div>

                            <div style={{display: "flex", alignItems: "left", width: '99%', backgroundColor: 'white'}}>
                                <div style={{width: "20px"}}></div>

                                <div style={{padding: '10px 10px 10px 0'}}>
                                    <ArgoTextFieldSelectHook
                                        fileName="QuestionnaireEditor"
                                        fieldName="mainPagesObj.pageFooter"
                                        label="Footer 1"
                                        value={this.state.mainPagesObj.pageFooter}
                                        onChange={this.onSelectChange}
                                        width="120px"
                                        menuItems={arrayNoOrYes}/>
                                </div>

                                <div style={{width: "15px"}}></div>

                                <div style={{width: '99%'}}>

                                    {this.state.mainPagesObj.pageFooter === "yes" ?
                                        <React.Fragment>
                                            <div style={{width: '99%'}}>
                                                <div style={{display: "flex", alignItems: "left", width: '99%', backgroundColor: 'white'}}>
                                                    <div style={{width: "20px"}}></div>

                                                    <div style={{paddingTop: '10px'}}>
                                                        <ArgoTextFieldHook
                                                            fileName="QuestionnaireEditor"
                                                            fieldName="mainPagesObj.pageFooterTextLeft"
                                                            label="Left Text"
                                                            value={this.state.mainPagesObj.pageFooterTextLeft}
                                                            onChange={this.onObjDotTextChange}
                                                            width="200px"
                                                            maxLength={40}/>
                                                    </div>

                                                    <div style={{width: "20px"}}></div>

                                                    <div style={{paddingTop: '10px'}}>
                                                        <ArgoTextFieldSelectHook
                                                            fileName="QuestionnaireEditor"
                                                            fieldName="mainPagesObj.pageFooterTextLeftFormat"
                                                            label="Format"
                                                            value={this.state.mainPagesObj.pageFooterTextLeftFormat}
                                                            onChange={this.onSelectChange}
                                                            width="150px"
                                                            menuItems={arrayDefaultOrCustom}/>
                                                    </div>

                                                    <div style={{width: '99%'}}>
                                                        {(this.state.mainPagesObj.pageFooterTextLeftFormat === 'custom') ?
                                                            <ArgoTextDisplayCustom
                                                                fieldName="pageFooterTextLeftCustomObj"
                                                                initToObj={this.state.mainPagesObj.pageFooterTextLeftCustomObj}
                                                                metadataTag={portal.CUSTOM_DDO_QNR_FORMAT_MAIN_PAGES}
                                                                showDisplay={false}
                                                                backgroundColorOverride={"white"}
                                                                metadataPropsObj={Object.assign({metadataTag: "custom-ddo-questionnaire-format-main-pages"}, propsObj)}
                                                                useMetadataPropsObjAltFunction={true}
                                                            />
                                                            : ""
                                                        }
                                                    </div>
                                                </div>

                                                <div style={{display: "flex", alignItems: "left", width: '99%', backgroundColor: 'white'}}>
                                                    <div style={{width: "20px"}}></div>

                                                    <div style={{paddingTop: '10px'}}>
                                                        <ArgoTextFieldHook
                                                            fileName="QuestionnaireEditor"
                                                            fieldName="mainPagesObj.pageFooterTextCenter"
                                                            label="Center Text"
                                                            value={this.state.mainPagesObj.pageFooterTextCenter}
                                                            onChange={this.onObjDotTextChange}
                                                            width="200px"
                                                            maxLength={40}/>
                                                    </div>

                                                    <div style={{width: "20px"}}></div>

                                                    <div style={{paddingTop: '10px'}}>
                                                        <ArgoTextFieldSelectHook
                                                            fileName="QuestionnaireEditor"
                                                            fieldName="mainPagesObj.pageFooterTextCenterFormat"
                                                            label="Format"
                                                            value={this.state.mainPagesObj.pageFooterTextCenterFormat}
                                                            onChange={this.onSelectChange}
                                                            width="150px"
                                                            menuItems={arrayDefaultOrCustom}/>
                                                    </div>

                                                    <div style={{width: '99%'}}>
                                                        {(this.state.mainPagesObj.pageFooterTextCenterFormat === 'custom') ?
                                                            <ArgoTextDisplayCustom
                                                                fieldName="pageFooterTextCenterCustomObj"
                                                                initToObj={this.state.mainPagesObj.pageFooterTextCenterCustomObj}
                                                                metadataTag={portal.CUSTOM_DDO_QNR_FORMAT_MAIN_PAGES}
                                                                showDisplay={false}
                                                                backgroundColorOverride={"white"}
                                                                metadataPropsObj={Object.assign({metadataTag: "custom-ddo-questionnaire-format-main-pages"}, propsObj)}
                                                                useMetadataPropsObjAltFunction={true}
                                                            />
                                                            :
                                                            <div></div>
                                                        }
                                                    </div>
                                                </div>

                                                <div style={{display: "flex", alignItems: "left", width: '99%', backgroundColor: 'white'}}>
                                                    <div style={{width: "20px"}}></div>

                                                    <div style={{paddingTop: '10px'}}>
                                                        <ArgoTextFieldHook
                                                            fileName="QuestionnaireEditor"
                                                            fieldName="mainPagesObj.pageFooterTextRight"
                                                            label="Right Text"
                                                            value={this.state.mainPagesObj.pageFooterTextRight}
                                                            onChange={this.onObjDotTextChange}
                                                            width="200px"
                                                            maxLength={40}/>
                                                    </div>

                                                    <div style={{width: "20px"}}></div>

                                                    <div style={{paddingTop: '10px'}}>
                                                        <ArgoTextFieldSelectHook
                                                            fileName="QuestionnaireEditor"
                                                            fieldName="mainPagesObj.pageFooterTextRightFormat"
                                                            label="Format"
                                                            value={this.state.mainPagesObj.pageFooterTextRightFormat}
                                                            onChange={this.onSelectChange}
                                                            width="150px"
                                                            menuItems={arrayDefaultOrCustom}/>
                                                    </div>

                                                    <div style={{width: '99%'}}>
                                                        {(this.state.mainPagesObj.pageFooterTextRightFormat === 'custom') ?
                                                            <ArgoTextDisplayCustom
                                                                fieldName="pageFooterTextRightCustomObj"
                                                                initToObj={this.state.mainPagesObj.pageFooterTextRightCustomObj}
                                                                metadataTag={portal.CUSTOM_DDO_QNR_FORMAT_MAIN_PAGES}
                                                                showDisplay={false}
                                                                backgroundColorOverride={"white"}
                                                                metadataPropsObj={Object.assign({metadataTag: "custom-ddo-questionnaire-format-main-pages"}, propsObj)}
                                                                useMetadataPropsObjAltFunction={true}
                                                            />
                                                            :
                                                            <div></div>
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </React.Fragment> : ""
                                    }
                                </div>
                            </div>

                            <div style={{display: "flex", alignItems: "left", width: '99%', backgroundColor: 'white'}}>
                                <div style={{width: "20px"}}></div>

                                <div style={{padding: '10px 10px 10px 0'}}>
                                    <ArgoTextFieldSelectHook
                                        fileName="QuestionnaireEditor"
                                        fieldName="mainPagesObj.pageFooter2"
                                        label="Footer 2"
                                        value={this.state.mainPagesObj.pageFooter2}
                                        onChange={this.onSelectChange}
                                        width="120px"
                                        menuItems={arrayNoOrYes}/>
                                </div>

                                <div style={{width: "15px"}}></div>

                                <div style={{width: '99%'}}>

                                    {this.state.mainPagesObj.pageFooter2 === "yes" ?
                                        <React.Fragment>
                                            <div style={{width: '99%'}}>
                                                <div style={{display: "flex", alignItems: "left", width: '99%', backgroundColor: 'white'}}>
                                                    <div style={{width: "20px"}}></div>

                                                    <div style={{paddingTop: '10px'}}>
                                                        <ArgoTextFieldHook
                                                            fileName="QuestionnaireEditor"
                                                            fieldName="mainPagesObj.pageFooter2TextLeft"
                                                            label="Left Text"
                                                            value={this.state.mainPagesObj.pageFooter2TextLeft}
                                                            onChange={this.onObjDotTextChange}
                                                            width="200px"
                                                            maxLength={40}/>
                                                    </div>

                                                    <div style={{width: "20px"}}></div>

                                                    <div style={{paddingTop: '10px'}}>
                                                        <ArgoTextFieldSelectHook
                                                            fileName="QuestionnaireEditor"
                                                            fieldName="mainPagesObj.pageFooter2TextLeftFormat"
                                                            label="Format"
                                                            value={this.state.mainPagesObj.pageFooter2TextLeftFormat}
                                                            onChange={this.onSelectChange}
                                                            width="150px"
                                                            menuItems={arrayDefaultOrCustom}/>
                                                    </div>

                                                    <div style={{width: '99%'}}>
                                                        {(this.state.mainPagesObj.pageFooter2TextLeftFormat === 'custom') ?
                                                            <ArgoTextDisplayCustom
                                                                fieldName="pageFooter2TextLeftCustomObj"
                                                                initToObj={this.state.mainPagesObj.pageFooter2TextLeftCustomObj}
                                                                metadataTag={portal.CUSTOM_DDO_QNR_FORMAT_MAIN_PAGES}
                                                                showDisplay={false}
                                                                backgroundColorOverride={"white"}
                                                                metadataPropsObj={Object.assign({metadataTag: "custom-ddo-questionnaire-format-main-pages"}, propsObj)}
                                                                useMetadataPropsObjAltFunction={true}
                                                            />
                                                            :
                                                            <div></div>
                                                        }
                                                    </div>
                                                </div>

                                                <div style={{display: "flex", alignItems: "left", width: '99%', backgroundColor: 'white'}}>
                                                    <div style={{width: "20px"}}></div>

                                                    <div style={{paddingTop: '10px'}}>
                                                        <ArgoTextFieldHook
                                                            fileName="QuestionnaireEditor"
                                                            fieldName="mainPagesObj.pageFooter2TextCenter"
                                                            label="Center Text"
                                                            value={this.state.mainPagesObj.pageFooter2TextCenter}
                                                            onChange={this.onObjDotTextChange}
                                                            width="200px"
                                                            maxLength={40}/>
                                                    </div>

                                                    <div style={{width: "20px"}}></div>

                                                    <div style={{paddingTop: '10px'}}>
                                                        <ArgoTextFieldSelectHook
                                                            fileName="QuestionnaireEditor"
                                                            fieldName="mainPagesObj.pageFooter2TextCenterFormat"
                                                            label="Format"
                                                            value={this.state.mainPagesObj.pageFooter2TextCenterFormat}
                                                            onChange={this.onSelectChange}
                                                            width="150px"
                                                            menuItems={arrayDefaultOrCustom}/>
                                                    </div>

                                                    <div style={{width: '99%'}}>
                                                        {(this.state.mainPagesObj.pageFooter2TextCenterFormat === 'custom') ?
                                                            <ArgoTextDisplayCustom
                                                                fieldName="pageFooter2TextCenterCustomObj"
                                                                initToObj={this.state.mainPagesObj.pageFooter2TextCenterCustomObj}
                                                                metadataTag={portal.CUSTOM_DDO_QNR_FORMAT_MAIN_PAGES}
                                                                showDisplay={false}
                                                                backgroundColorOverride={"white"}
                                                                metadataPropsObj={Object.assign({metadataTag: "custom-ddo-questionnaire-format-main-pages"}, propsObj)}
                                                                useMetadataPropsObjAltFunction={true}
                                                            />
                                                            :
                                                            <div></div>
                                                        }
                                                    </div>
                                                </div>

                                                <div style={{display: "flex", alignItems: "left", width: '99%', backgroundColor: 'white'}}>
                                                    <div style={{width: "20px"}}></div>

                                                    <div style={{paddingTop: '10px'}}>
                                                        <ArgoTextFieldHook
                                                            fileName="QuestionnaireEditor"
                                                            fieldName="mainPagesObj.pageFooter2TextRight"
                                                            label="Right Text"
                                                            value={this.state.mainPagesObj.pageFooter2TextRight}
                                                            onChange={this.onObjDotTextChange}
                                                            width="200px"
                                                            maxLength={40}/>
                                                    </div>

                                                    <div style={{width: "20px"}}></div>

                                                    <div style={{paddingTop: '10px'}}>
                                                        <ArgoTextFieldSelectHook
                                                            fileName="QuestionnaireEditor"
                                                            fieldName="mainPagesObj.pageFooter2TextRightFormat"
                                                            label="Format"
                                                            value={this.state.mainPagesObj.pageFooter2TextRightFormat}
                                                            onChange={this.onSelectChange}
                                                            width="150px"
                                                            menuItems={arrayDefaultOrCustom}/>
                                                    </div>

                                                    <div style={{width: '99%'}}>
                                                        {(this.state.mainPagesObj.pageFooter2TextRightFormat === 'custom') ?
                                                            <ArgoTextDisplayCustom
                                                                fieldName="pageFooter2TextRightCustomObj"
                                                                initToObj={this.state.mainPagesObj.pageFooter2TextRightCustomObj}
                                                                metadataTag={portal.CUSTOM_DDO_QNR_FORMAT_MAIN_PAGES}
                                                                showDisplay={false}
                                                                backgroundColorOverride={"white"}
                                                                metadataPropsObj={Object.assign({metadataTag: "custom-ddo-questionnaire-format-main-pages"}, propsObj)}
                                                                useMetadataPropsObjAltFunction={true}
                                                            />
                                                            : ""
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </React.Fragment> : ""
                                    }
                                </div>
                            </div>

                        </div>
                    </React.Fragment> : ""
                }

            </div>
        );
    }

    getTab4_ClosePage(propsObj) {

        return (
            <div id="QuestionnaireEditor-ClosePageTab-container-div" style={{paddingLeft: "20px", paddingBottom: "20px"}}>

                <div>&nbsp;</div>

                <div style={{display: "flex", alignItems: "left", width: '99%'}}>

                    <ArgoTextFieldSelectHook
                        fileName="QuestionnaireEditor"
                        fieldName="closePage"
                        label="Close Page"
                        value={this.state.closePage}
                        onChange={this.onSelectChange}
                        width="150px"
                        menuItems={arrayNoOrYes}
                    />

                    <div style={{width: "20px"}}></div>

                    {this.state.closePage === "yes" ?
                        <div>
                            <ArgoTextFieldSelectHook
                                fileName="QuestionnaireEditor"
                                fieldName="closePageObj.format"
                                label="Format"
                                value={this.state.closePageObj.format}
                                onChange={this.onSelectChange}
                                width="150px"
                                menuItems={arrayDefaultOrCustom}
                            />
                        </div> : ""
                    }
                </div>

                {this.state.closePageObj !== null && this.state.closePageObj.format === "custom" ?
                    <div style={{width: '95%', padding: "20px 20px 0 20px"}}>
                        <React.Fragment>
                            <div style={{width: '99%'}}>

                                <div style={{display: "flex", alignItems: "left", width: '99%', backgroundColor: 'white'}}>
                                    <div style={{width: "20px"}}></div>

                                    <ArgoTextFieldSelectHook
                                        fileName="QuestionnaireEditor"
                                        fieldName="closePageObj.image"
                                        label="Image"
                                        value={this.state.closePageObj.image}
                                        onChange={this.onSelectChange}
                                        width="150px"
                                        menuItems={[
                                            {value: "none", text: "None"},
                                            {value: "greencheck", text: "Green Check"}
                                        ]}
                                    />

                                    <div style={{width: "20px"}}></div>

                                    <ArgoTextFieldSelectHook
                                        fileName="QuestionnaireEditor"
                                        fieldName="closePageObj.text"
                                        label="Text"
                                        value={this.state.closePageObj.text}
                                        onChange={this.onSelectChange}
                                        width="150px"
                                        menuItems={[
                                            {value: "none", text: "None"},
                                            {value: "default1", text: "default 1"},
                                            {value: "custom", text: "Custom"}
                                        ]}
                                    />

                                    <div style={{width: "20px"}}></div>

                                    {((this.state.closePageObj.text !== "none") && (this.state.closePageObj.text !== "custom")) ?
                                        <div style={{overflow: 'hidden'}}>
                                            <ArgoTextFieldHook
                                                fileName="QuestionnaireEditor"
                                                fieldName="selected-close-text"
                                                label="Selected Close Text"
                                                value={portal.getDefaultCloseText(this.state.closePageObj.text)}
                                                onChange={function () {}}
                                                width="1100px"
                                                multiline={true}
                                                disabled={true}
                                                style={{overflow: 'hidden'}}/>
                                        </div>
                                        : ""
                                    }
                                </div>

                                <div style={{display: "flex", alignItems: "left", width: '99%', backgroundColor: 'white'}}>
                                    <div style={{width: "20px"}}></div>

                                    {(this.state.closePageObj.text === "custom") &&
                                        <React.Fragment>
                                            <div style={{flexGrow: 2}}>

                                                <div style={{display: "flex", alignItems: "left", width: '99%'}}>
                                                    <ArgoAutocompleteHook
                                                        fileName={'QuestionnaireEditor'}
                                                        fieldName={'user-script-after'}
                                                        label={'Script after'}
                                                        variant={'standard'}
                                                        maxLength={'4095'}
                                                        multiline={true}
                                                        onChange={this.onChangeArgoAutocompleteHook}
                                                        value={this.props.template.metadata["user-script-after"] || ""}
                                                        menuItems={ALLOWED_TOKEN}
                                                        width='100%'
                                                    />

                                                    <ArgoTextDisplayDefination
                                                        fieldName="scriptAfterDisplayDefination"
                                                        initTo={this.state.scriptAfterDisplayDefination}
                                                        templateType={TEMPLATE.TYPE.QUESTIONNAIRE}
                                                        titleType="scriptAfter"
                                                        metadataPropsObj={Object.assign({metadataTag: portal.CUSTOM_DDO_QNR_SCRIPT_AFTER}, propsObj)}
                                                    />
                                                </div>
                                            </div>
                                        </React.Fragment>
                                    }
                                </div>

                                {(this.state.scriptAfterDisplayDefination === 'custom') ?
                                    <ArgoTextDisplayCustom
                                        fieldName="scriptAfterDisplayDefination"
                                        initToObj={this.state.scriptAfterDisplayMetadataValueObj}
                                        metadataTag={portal.CUSTOM_DDO_QNR_SCRIPT_AFTER}
                                        showDisplay={false}
                                        backgroundColorOverride={"white"}
                                        displayLabel={"Display (Script After)"}
                                        metadataPropsObj={Object.assign({metadataTag: "custom-ddo-questionnaire-format-close-page"}, propsObj)}
                                    />
                                    : ""
                                }

                            </div>
                        </React.Fragment>
                    </div>  : ""
                }
            </div>
        );
    }

    // -----------------------------------------------------------------------------------------------------------------
    // EXPANDER FUNCTIONS ----------------------------------------------------------------------------------------------
    // -----------------------------------------------------------------------------------------------------------------

    initializeExpander = () => {
        if (this.props.template.categoryKeys !== undefined) {
            let expanderObj = expander.initializeExpander(this.state.expander, this.props.template.categoryKeys);
            this.setState({expander: expanderObj});
        }
    };

    onClickExpandCollapseAll = () => {
        let stateObj = expander.expandCollapseAll(this.state.expander, this.state.expandAll);
        this.setState(stateObj);
    };

    expandCollapseSelected = (templateKey) => {
        let stateObj = expander.expandCollapseSelected(this.state.expander, templateKey);
        this.setState(stateObj);
    };

    addSelectedCategory = (categoryTemplate) => {
        if (!this.props.template.categoryKeys.includes(categoryTemplate.templateKey)) {
            this.setState({categoryTemplateSearch: ""});
            this.props.addQuestionnaireCategory(categoryTemplate.templateKey);
            this.props.getCategoryTemplate(categoryTemplate.templateKey);
        }
    };

    saveNewCategory = (template) => {
        this.props.createCategoryTemplate(template, true);
        this.setState({isThisAnExistingCategory: "0", categoryTemplateSearch: ""});
    }

    closeNewCategory = () => {
        this.setState({isThisAnExistingCategory: "0", categoryTemplateSearch: ""});
    }

    handleToolbarBackButtonClick = (event, fieldName, buttonTitle) => {
        if (buttonTitle === 'BACK') this.setState({openCategoryViewer: false});
    }

    // -----------------------------------------------------------------------------------------------------------------
    // GROUP EDITOR FUNCTIONS ------------------------------------------------------------------------------------------
    // -----------------------------------------------------------------------------------------------------------------

    categoryEditorSave = () => {
        this.props.saveCategoryTemplate(this.state.categoryTemplate);
        this.setState({openCategoryEditor: false});
    };

    categoryEditorCancel = () => {
        this.setState({openCategoryEditor: false});
    };

    // -----------------------------------------------------------------------------------------------------------------
    // RENDER ----------------------------------------------------------------------------------------------------------
    // -----------------------------------------------------------------------------------------------------------------

    render() {
        if (this.state.isLoading) {
            //TODO place a loading screen here
            return null;
        }

        // Edit from question expander
        if (this.state.openCategoryEditor) {

            return <CategoryEditor title="Edit Category"
                                   save={this.categoryEditorSave}
                                   cancel={this.categoryEditorCancel}
                                   templateKey={this.state.categoryTemplate.templateKey}
                                   templateId={this.state.categoryTemplate.id}
                                   filter={this.props.filter}
                                   templateMode={TEMPLATE.MODE.CURRENT}
                                   categoryExpanderEdit={true}
                                   categoryExpanderSetStateCallback={this.setStateCallback}
            />;
        }

        const metadataObj = (this.props.template && this.props.template.metadata) ? this.props.template.metadata : {};

        let propsObj = {
            fileName: "QuestionnaireEditor",
            onTextChange: this.onTextChange,
            templateType: TEMPLATE.TYPE.QUESTIONNAIRE,
            template: this.props.template,
            metadata: metadataObj,
            templateUpdateFunction: this.props.updateQuestionnaireTemplate,
            setStateCallbackFunction: this.setStateCallback,
            setCustomMetadataFunction: metadataUtils.setCustomMetadata,
            setCustomMetadataFunctionAlt: this.setCustomMetadataLayered,
            depth: 0
        }

        const {palette} = this.context;  // From blueTheme.js

        //let portalPath = portal.getPortalPath(portal.QUESTIONNAIRE_URL_SUFFIX);

        if (this.state.openCategoryViewer) {
            return (
                <div style={{height: "100%"}}>
                    {this.state.showCategoryBackButton ?
                        <ArgoToolbarHook
                            fileName="CategoryEditor"
                            fieldName="category-info-back"
                            buttonTitles={["BACK"]}
                            onClick={this.handleToolbarBackButtonClick}
                        />
                        : ''
                    }

                    <div style={{height: "100%", width: "100%", overflowY: "scroll"}}>
                        <CategoryViewer templateKey={this.state.selectedCategoryInfoKey} showToolbar={false} setStateCallBack={this.setStateCallback}/>;
                    </div>
                </div>
            );
        }

        if (this.state.isThisAnExistingCategory === "1") {
            let template = {
                name: this.state.categoryTemplateSearch
            };

            return <CategoryEditor title="Create Category"
                                   editMode={false}
                                   save={this.saveNewCategory.bind(this)}
                                   cancel={this.closeNewCategory.bind(this)}
                                   createInitialTemplate={template}/>;
        }

        return (
            (this.props.cloneMode) ?
                <div id="QuestionnaireEditor-clone-container-div">
                    <div>
                        <div style={{justifyContent: "space-between"}}>
                            <div style={{flexGrow: "2", paddingLeft: "32px", paddingRight: "32px"}}>

                                <ArgoTextFieldHook
                                    fileName="QuestionnaireEditor"
                                    fieldName="nameQualifier"
                                    label="Questionnaire qualifier"
                                    value={this.props.template.nameQualifier}
                                    maxLength={40}
                                    onChange={this.onTextChange}
                                    errorText={this.state.nameQualifierErrorText}
                                />

                            </div>
                        </div>
                    </div>

                    {(this.props.template.templateKey !== "") ?

                        <div style={{height: "100%"}}>
                            <div style={{height: '20px'}}>

                            </div>
                            {(this.props.categoryKeys && this.props.questionKeys) ?
                                <QuestionnaireViewer template={this.props.template}
                                                     categories={this.props.categoryKeys}
                                                     questionKeys={this.props.questionKeys} cloneMode={true}/>
                                : ""
                            }

                        </div>
                        :
                        ""}
                </div>
                :
                <div id="QuestionnaireEditor-default-container-div" className="layout vertical" style={{height: "100%"}}>
                    {/*<div style={{justifyContent: "space-between"}}>*/}

                    <ArgoToolbarHook
                        fileName="QuestionnaireEditor"
                        fieldName="save-or-cancel"
                        toolbarErrorText={this.state.toolbarErrorText}
                        onClick={this.props.handleToolbarButtonClick}
                    />

                    <div style={{flexGrow: "2", paddingLeft: "32px", paddingRight: "32px", marginTop: '30px'}}>

                        <ArgoTextEntryWithCustomOverride
                            fieldName="name"
                            label="Questionnaire Name"
                            labelCustom="Display (Name)"
                            titleType="name"
                            templateType={TEMPLATE.TYPE.QUESTIONNAIRE}
                            value={this.props.template.name}
                            maxlength={255}
                            errorText={this.state.nameErrorText}
                            initTo={this.state.nameDisplayDefination}
                            initToObj={this.state.nameDisplayMetadataValueObj}
                            propsObj={Object.assign({metadataTag: portal.CUSTOM_DDO_NAME}, propsObj)}
                        />

                        <ArgoTextFieldHook
                            fileName="QuestionnaireEditor"
                            fieldName="nameQualifier"
                            label="Name Qualifier"
                            value={this.props.template.nameQualifier}
                            maxlength={255}
                            onChange={this.onTextChange}
                            maxLength={40}
                        />

                        {/*// ===========================================================================================================*/}
                        {/*// REPORTING CATEGORY */}
                        {/*// ===========================================================================================================*/}

                        <ArgoTextFieldSelectHook
                            fileName="QuestionnaireEditor"
                            fieldName="reportingCategory"
                            label="Key Metric Category"
                            value={this.state.reportingCategory}
                            onChange={this.onChangeReportingCategory}
                            width="300px"
                            menuItems={this.state.reportingCategories}
                        />

                        <div style={{width: "20px"}}>&nbsp;</div>


                        {/*// ===========================================================================================================*/}
                        {/*// PORTAL TABS */}
                        {/*// ===========================================================================================================*/}

                        {/*<div>Link to tab Styling Tutorial: https://smartdevpreneur.com/the-best-mui-tabs-tutorial-hover-active-focus-and-onclick/</div>*/}
                        {/*<div>Link to tab Styling Tutorial: https://www.youtube.com/watch?v=0IPnrdnagBM</div>*/}

                        <div id="QuestionnaireEditor-tabs-container-div">
                            <Tabs
                                TabIndicatorProps={{
                                    style: {
                                        backgroundColor: "white",
                                        borderColor: "black"
                                    }
                                }}
                                sx={{
                                    "& button": {color: "gray", border: "solid", borderWidth: "thin", width: "20%"},
                                    "& button:hover": {backgroundColor: "#E8E8E8", color: "black", borderColor: "black"},
                                    "& button:active": {backgroundColor: "white", color: "black"},
                                    "& button:Mui-selected": {backgroundColor: "white"},
                                }}
                                value={this.state.selectedTabNumber}
                                onChange={this.handleTabChange.bind(this)}
                            >
                                <Tab value={0} sx={{textTransform: "none", backgroundColor: (this.state.selectedTabNumber === 0 ? "white" : "#E8E8E8"), color: (this.state.selectedTabNumber === 0 ? "black" : "#989898")}} label={this.state.tab0}/>

                                <Tab value={1} sx={{textTransform: "none", backgroundColor: (this.state.selectedTabNumber === 1 ? "white" : "#E8E8E8"), color: (this.state.selectedTabNumber === 1 ? "black" : "#989898")}} label={this.state.tab1}/>

                                <Tab value={2} sx={{textTransform: "none", backgroundColor: (this.state.selectedTabNumber === 2 ? "white" : "#E8E8E8"), color: (this.state.selectedTabNumber === 2 ? "black" : "#989898")}} label={this.state.tab2}/>

                                <Tab value={3} sx={{textTransform: "none", backgroundColor: (this.state.selectedTabNumber === 3 ? "white" : "#E8E8E8"), color: (this.state.selectedTabNumber === 3 ? "black" : "#989898")}} label={this.state.tab3}/>

                                <Tab value={4} sx={{textTransform: "none", backgroundColor: (this.state.selectedTabNumber === 4 ? "white" : "#E8E8E8"), color: (this.state.selectedTabNumber === 4 ? "black" : "#989898")}} label={this.state.tab4}/>

                            </Tabs>

                            {/*// ===========================================================================================================*/}
                            {/*// Tab Sections */}
                            {/*// ===========================================================================================================*/}

                            <div id="QuestionnaireEditor-portal-tab-contents-container-div" style={{paddingBotton: "20px", borderLeft: "solid", borderBottom: "solid", borderRight: "solid", borderWidth: "thin", borderColor: "#1976d2"}}>
                                <div style={{display: this.state.selectedTabNumber === 0 ? "inline" : "none"}} id="QuestionnaireEditor-Tab0-container-div">
                                    {this.getTab0_Theme(propsObj)}
                                </div>
                                <div style={{display: this.state.selectedTabNumber === 1 ? "inline" : "none"}} id="QuestionnaireEditor-Tab1-container-div">
                                    {this.getTab1_Header(propsObj)}
                                </div>
                                <div style={{display: this.state.selectedTabNumber === 2 ? "inline" : "none"}} id="QuestionnaireEditor-Tab2-container-div">
                                    {this.getTab2_StartPage(propsObj)}
                                </div>
                                <div style={{display: this.state.selectedTabNumber === 3 ? "inline" : "none"}} id="QuestionnaireEditor-Tab3-container-div">
                                    {this.getTab3_MainPages(propsObj)}
                                </div>
                                <div style={{display: this.state.selectedTabNumber === 4 ? "inline" : "none"}} id="QuestionnaireEditor-Tab4-container-div">
                                    {this.getTab4_ClosePage(propsObj)}
                                </div>
                            </div>
                        </div>

                        {/*// ===========================================================================================================*/}
                        {/*// METADATA */}
                        {/*// ===========================================================================================================*/}

                        <div>&nbsp;</div>

                        <div style={{width: "98%", paddingBottom: "40px"}}>
                            <ArgoMetadataEditor
                                entityType={metadataTemplateTypes.QuestionnaireTemplate}
                                template={this.props.template}
                                onChange={this.props.updateQuestionnaireTemplate}
                                metadata={this.props.template.metadata}
                                setMetadataErrorText={this.setMetadataErrorText}
                            />
                        </div>

                        {/*// ===========================================================================================================*/}
                        {/*// ADD CATEGORY TO QUESTIONNAIRE */}
                        {/*// ===========================================================================================================*/}

                        <div style={{width: "100%"}}>

                            <ArgoRadioGroupHook
                                fileName="QuestionnaireEditor"
                                fieldName="createMode"
                                label="Is this an existing category?"
                                value={this.state.isThisAnExistingCategory}
                                onChange={this.onChangeIsThisAnExistingCategory}
                                checked={true}
                            />

                            <ArgoSelectExisting
                                mode={TEMPLATE.TYPE.CATEGORY}
                                paddingRight="0px"
                                onAddTemplate={this.addSelectedCategory}
                            />

                            <div>&nbsp;</div>

                        </div>

                        {/*// ===========================================================================================================*/}
                        {/*// ASSIGNED CATEGORIES LIST */}
                        {/*// ===========================================================================================================*/}

                        <CategoryViewerExpandable
                            questionnaireTemplate={this.props.template}
                            categoryKeys={this.props.categoryKeys}
                            setStateCallback={this.setStateCallback}
                            hidePortalPreview={true}
                        />

                        {/*// ===========================================================================================================*/}
                        {/*// PORTAL PREVIEW */}
                        {/*// ===========================================================================================================*/}

                        <div id="CategoryEditor-space-above-portal-preview" style={{height: "20px"}}>&nbsp;</div>

                        {!this.props.cloneMode || false ?
                            <ArgoPortalPreview
                                calledBy="QuestionnaireEditor"
                                templateType={TEMPLATE.TYPE.QUESTIONNAIRE}
                                createMode={this.props.createMode}
                                questionnaireTemplate={this.props.template}
                                categoryKeys={this.props.categoryKeys}
                                categoryTemplates={this.props.categoryTemplate}
                                groupTemplates={this.props.groupTemplate}
                                questionTemplates={this.props.questionTemplate}
                                //answerOptionTemplates={this.props.answerOptionTemplates}
                                //showButton={true}
                            /> : ""
                        }

                    </div>
                </div>
        );
    }
}

QuestionnaireEditor.defaultProps = {
    createMode: false,
    cloneMode: false
};

QuestionnaireEditor.propTypes = {
    createMode: PropTypes.bool,
    templateKey: PropTypes.string,
    createCategoryTemplate: PropTypes.func,
    handleToolbarButtonClick: PropTypes.func,
    cloneMode: PropTypes.bool,
};

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        getQuestionnaireTemplate,
        initializeQuestionnaireEditorUpdate,
        initializeQuestionnaireEditorCreate,
        clearQuestionnaireEditor,
        updateQuestionnaireTemplate,
        checkQuestionnaireValue,
        checkNameQualifier,
        validateQuestionnaireNameAndQualifierUnique,
        getMetadataDefinitions,
        addQuestionnaireCategory,
        createCategoryTemplate,
        getCategoryTemplate,
        saveCategoryTemplate
    }, dispatch);
}

function mapStateToProps(state, props) {
    return {
        questionnaireType: state.questionnaireEditor.questionnaireType,
        options: state.questionnaireEditor.options,
        template: state.questionnaireEditor.template,
        templateOriginal: state.questionnaireTemplate[props.templateKey],
        questionnaireTemplate: state.questionnaireTemplate,
        categoryKeys: state.questionnaireEditor.categoryKeys, // Added category keys are returned here
        categoryTemplate: state.categoryTemplate,
        groupTemplate: state.groupTemplate,
        questionTemplate: state.questionTemplate,
        metadataDefinition: state.metadataDefinition[metadataTemplateTypes.QuestionnaireTemplate]
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(QuestionnaireEditor);
