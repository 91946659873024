import React, {Component} from 'react';
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import InfoIcon from "@mui/icons-material/Info";
import {getQuestionTemplate} from "../../../actions/questionTemplateActions";
import {getGroupTemplate} from "../../../actions/groupTemplateActions";
import QuestionViewerExpandable from "../questions/QuestionViewerExpandable";
import Typography from "@mui/material/Typography";
import AnswerViewer from "../answers/AnswerViewer";
import * as TEMPLATE from "../../../constants/templateConstants";
import * as portal from "../../../utilities/portal";
import ArgoToolbarHook from "../../common/ArgoToolbarHook";
import QuestionViewer from "../questions/QuestionViewer";
import ArgoPortalPreview from "../../common/ArgoPortalPreview";
import statusFormatter from "../../../utilities/StatusFormatter";
import * as metadataUtils from "../../../utilities/metadata";


class GroupViewer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            openQuestionViewer: false,
            selectedQuestionInfoKey: "",
            groupLoaded: false,
            questionExpandedArray: [], // For questions assigned to group
            isExpanded: true,
            buttonTitleSelected: "",
            hidePortalPreview: false,
            questionTemplates: {},
            portalPreviewLoaded: false,
            questionsLoaded: false,
            showGroupBackButton: true
        };
    }

    componentDidMount() {
        if (this.props.templateKey || this.props.templateId) {
            this.props.getGroupTemplate(this.props.templateKey, this.props.templateId);
        }
    }


    setStateCallback = (stateObj) => {
        this.setState(stateObj);
    }


    openQuestionViewer = () => {
        return (
            <div style={{height: "100%"}}>
                <ArgoToolbarHook
                    fileName="GroupViewer"
                    fieldName="back"
                    buttonTitles={["BACK"]}
                    onClick={this.handleToolbarButtonClick}
                />

                <div className="layout vertical" style={{height: "100%", overflowY: "scroll"}}>
                    <QuestionViewer templateKey={this.state.selectedQuestionInfoKey}/>
                </div>
            </div>
        );
    };


    handleToolbarButtonClick = (event, fieldName, buttonTitle) => {
        if (buttonTitle === 'BACK') this.setState({openQuestionViewer: false, showGroupBackButton: true});
    }

    // -----------------------------------------------------------------------------------------------------------------
    // RENDER ----------------------------------------------------------------------------------------------------------
    // -----------------------------------------------------------------------------------------------------------------

    render() {

        this.props.setStateCallBack({showGroupBackButton: this.state.showGroupBackButton})
        if (this.state.openQuestionViewer) {
            return this.openQuestionViewer();
        }

        const template = this.props.template;

        return (
            template ?
                <div style={{flexGrow: "2", padding: "32px"}}>

                    <div style={{minHeight: "min-content"}}>

                        <Typography variant="h6" component="div">
                            Group Information:
                        </Typography>

                        <div>&nbsp;</div>

                        <div id="GroupViewer-complex-question-type-container-div" style={{width: "100%", display: "flex", justifyContent: "flex-start", alignItems: "center", overflowWrap: "break-word"}}>
                            <div style={{color: "darkgray"}}>Status:</div>
                            <div>&nbsp;</div>
                            <div>{statusFormatter(this.props.template, 0, 0, true)}</div>
                        </div>

                        <div>&nbsp;</div>

                        <div id="GroupViewer-complex-question-type-container-div" style={{width: "100%", display: "flex", justifyContent: "flex-start", alignItems: "center", overflowWrap: "break-word"}}>
                            <div style={{color: "darkgray"}}>Likert:</div>
                            <div>&nbsp;</div>
                            <div>{template.answerOptionTemplates.length ? "Yes" : "No"}</div>
                        </div>

                        <div>&nbsp;</div>

                        <div id="GroupViewer-complex-question-name-container-div" style={{width: "100%", display: "flex", justifyContent: "flex-start", alignItems: "center", overflowWrap: "break-word"}}>
                            <div style={{color: "darkgray"}}>Group:</div>
                            <div>&nbsp;</div>
                            <div>{template.name}</div>
                        </div>

                        <div>&nbsp;</div>

                        <div id="GroupViewer-complex-question-qualifier-container-div" style={{width: "100%", display: "flex", justifyContent: "flex-start", alignItems: "center", overflowWrap: "break-word"}}>
                            <div style={{color: "darkgray"}}>Qualifier:</div>
                            <div>&nbsp;</div>
                            <div>{template.nameQualifier}</div>
                        </div>

                        <div>&nbsp;</div>
                        <div>&nbsp;</div>

                        <div id="GroupViewer-complex-question-description-container-div" style={{width: "100%", display: "flex", justifyContent: "flex-start", alignItems: "center", overflowWrap: "break-word"}}>
                            <div style={{color: "darkgray"}}>Description:</div>
                            <div>&nbsp;</div>
                            <div>
                                {template.description && <div style={{width: "100%", display: "flex", justifyContent: "flex-start", alignItems: "center", overflowWrap: "break-word"}}>
                                    <InfoIcon style={{minWidth: "36px"}}/>
                                    <div>&nbsp;</div>
                                    <div>{template.description}</div>
                                </div>}
                            </div>
                        </div>

                        <div>&nbsp;</div>

                        <div>
                            {metadataUtils.listMetadata(this.props.template, "Group")}
                        </div>

                    </div>

                    {/*// ===========================================================================================================*/}
                    {/*// GROUP LIKERT ANSWERS */}
                    {/*// ===========================================================================================================*/}

                    {(this.props.template.answerOptionTemplates.length > 0) ?
                        <AnswerViewer
                            answerOptionTemplates={this.props.template.answerOptionTemplates}
                            parentsLabel="Group"
                        />
                        : ""
                    }

                    {/*// ===========================================================================================================*/}
                    {/*// ASSIGNED QUESTIONS LIST - NOTE: If (this.props.template.answerOptionTemplates.length > 0) Group is a LIKERT */}
                    {/*// ===========================================================================================================*/}

                    <QuestionViewerExpandable
                        parentType={TEMPLATE.TYPE.GROUP}
                        parentTemplate={this.props.template}
                        questionKeys={this.props.questionKeys}
                        showIconButtonReorder={false}
                        showIconButtonEdit={false}
                        showIconButtonRemove={false}
                        setStateCallback={this.setStateCallback}
                        hidePortalPreview={(this.props.template.answerOptionTemplates.length > 0)}
                        isLikert={this.props.template.answerOptionTemplates.length > 0}
                    />

                    {/*// ===========================================================================================================*/}
                    {/*// PORTAL PREVIEW */}
                    {/*// ===========================================================================================================*/}

                    <div id="GroupViewer-space-above-portal-preview" style={{height: "20px"}}>&nbsp;</div>

                    <ArgoPortalPreview
                        calledBy="GroupViewer"
                        templateType={TEMPLATE.TYPE.GROUP}
                        groupTemplate={this.props.template}
                        questionTemplates={this.props.questionTemplates}
                        questionKeys={this.props.questionKeys}
                        answerOptionTemplates={this.props.template.answerOptionTemplates}
                        showButton={true}
                    />

                </div>
                : ""

        );
    }
}

GroupViewer.defaultProps = {
    template: undefined,
    showToolbar: true,
    setStateCallBack: function () {}
};

GroupViewer.propTypes = {
    questionKeys: PropTypes.array,
    templateKey: PropTypes.string,
    templateId: PropTypes.string,
    style: PropTypes.object,
    showToolbar: PropTypes.bool,
    setStateCallBack: PropTypes.func
};

function mapStateToProps(state, props) {
    let mappedState = {
        questionTemplates: state.questionTemplate
    };

    if (props.templateKey && state.groupTemplate[props.templateKey]) {
        mappedState.template = state.groupTemplate[props.templateKey];
        mappedState.questionKeys = state.groupTemplate[props.templateKey].questionKeys || [];
    }

    return mappedState;
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({getGroupTemplate, getQuestionTemplate}, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(GroupViewer);
