import React from 'react';
import { MdThumbUp, MdThumbDown, MdMoodBad, MdSentimentDissatisfied, MdSentimentNeutral, MdSentimentSatisfied, MdMood, MdStarBorder, MdStar  } from 'react-icons/md';
import * as tag from "../constants/customMetadataConstants";

// Constants

// ----------------------------------- Circle plus/minus SVGs -----------------------------------
// Unselected / Outlined
import SvgUnselectedDoubleNegative from "./SVGs/components/CirclesUnselected/UnselectedDoubleNegative";
import SvgUnselectedNegative from "./SVGs/components/CirclesUnselected/UnselectedNegative";
import SvgUnselectedNeutral from "./SVGs/components/CirclesUnselected/UnselectedNeutral";
import SvgUnselectedPositive from "./SVGs/components/CirclesUnselected/UnselectedPositive";
import SvgUnselectedDoublePositive from "./SVGs/components/CirclesUnselected/UnselectedDoublePositive";
// Selected / Filled
import SvgSelectedDoubleNegative from "./SVGs/components/CirclesSelected/SelectedDoubleNegative";
import SvgSelectedNegative from "./SVGs/components/CirclesSelected/SelectedNegative";
import SvgSelectedNeutral from "./SVGs/components/CirclesSelected/SelectedNeutral";
import SvgSelectedPositive from "./SVGs/components/CirclesSelected/SelectedPositive";
import SvgSelectedDoublePositive from "./SVGs/components/CirclesSelected/SelectedDoublePositive";
// -----------------------------------------------------------------------------------------------

export const IconThumbUp = MdThumbUp;
export const IconThumbDown = MdThumbDown;

export const IconStarEmpty = MdStarBorder;
export const IconStarFilled = MdStar;
//export const IconStarFilledHalf = MdStarHalf;

export const IconSentiment1 = MdMoodBad;
export const IconSentiment2 = MdSentimentDissatisfied;
export const IconSentiment3 = MdSentimentNeutral;
export const IconSentiment4 = MdSentimentSatisfied;
export const IconSentiment5 = MdMood;

// Metadata tag constants
export const ICON_POSITION = "icon-position";
export const ICON_TYPE = "icon-type";
export const ICON_COLOR = "icon-color";
export const ICON_TITLES = "icon-titles";

export const LIKERT_ICON_TITLES_ALL = "As title above";  // Thumbs, Stars and Faces
export const LIKERT_ICON_TITLES_LR = "As title above (LR) only";  // Stars and Faces
export const LIKERT_ICON_TITLES_LCR = "As title above (LCR) only)";  // Stars and Faces with odd number of answers
export const LIKERT_ICON_TITLES_NONE = "Hidden";  // Thumbs, Stars and Faces
export const LIKERT_ICON_TITLES_EMBEDDED = "Embedded";  // Circles and Squares Answer Text 1 or 2 characters embedded in icon

// Position constants
export const POSITION_NONE = "None";
export const POSITION_BEFORE = "Before";
export const POSITION_AFTER = "After";
export const POSITION_ONLY = "Only";

// Position constants LIKERT
export const POSITION_ICON = "Icon";
export const POSITION_ICON_NO_TITLE = "Icon (No Title)";
export const POSITION_ICON_FILL = "Icon Fill";
export const POSITION_ICON_FILL_NO_TITLE = "Icon Fill (No Title)";

// Type constants
export const TYPE_THUMBS_UP = "Thumbs Up";
export const TYPE_THUMBS_DOWN = "Thumbs Down";
//export const TYPE_THUMBS_UP_DOWN = "Thumbs Up/Down";

export const TYPE_RADIO = "Radio";

export const TYPE_SQUARE = "Square";

export const TYPE_CIRCLE = "Circle";  // Circles
export const TYPE_CIRCLE_MODIFIER_COLORS = "Colors";  // Multi-colored circles (Single Select or Pyramid)

export const TYPE_STAR_FILLED = "Star Filled";
export const TYPE_STAR = "Star";

export const TYPE_SENTIMENT_VERY_DISSATISFIED = "Very Dissatisfied";
export const TYPE_SENTIMENT_DISSATISFIED = "Dissatisfied";
export const TYPE_SENTIMENT_NEUTRAL = "Neutral";
export const TYPE_SENTIMENT_SATISFIED = "Satisfied";
export const TYPE_SENTIMENT_VERY_SATISFIED = "Very Satisfied";

export const TYPE_OUTLINED_DOUBLE_NEGATIVE = "Outlined Double Negative";
export const TYPE_OUTLINED_NEGATIVE = "Outlined Negative";
export const TYPE_OUTLINED_NEUTRAL = "Outlined Neutral";
export const TYPE_OUTLINED_POSITIVE = "Outlined Positive";
export const TYPE_OUTLINED_DOUBLE_POSITIVE = "Outlined Double Positive";

export const TYPE_SELECTED_DOUBLE_NEGATIVE = "Selected Double Negative";
export const TYPE_SELECTED_NEGATIVE = "Selected Negative";
export const TYPE_SELECTED_NEUTRAL = "Selected Neutral";
export const TYPE_SELECTED_POSITIVE = "Selected Positive";
export const TYPE_SELECTED_DOUBLE_POSITIVE = "Selected Double Positive";

export const DEFAULT_ICON_COLOR_NOT_SELECTED = "#8A8A8A" //  '#7F7F7F';      //QQQQQQ "#7F7F7F";  // "#F0F0F0"; // "#D0D0D0"; // "#2F4F4F";  #8A8A8A
export const DEFAULT_ICON_COLOR_SELECTED = "#2196F3"; // QQQQQQ"#2196f3";  // Default Light Blue (before it was "#2F4F4F";)
export const DEFAULT_ICON_COLOR_UNSELECTED = "gray";


// It appears that a answerOption structure is different between templates and instances.
// An instance adds an extra level "metatdataItems".
// NOTE: Metadata JSON structure for an instance is different then for a template. (answerOption.metadata.metadataItems)
export function metadataAnswerOptionInstanceFormatToTemplateFormat(answerMetadata) {

    let convertedMetadata = answerMetadata.metadataItems !== undefined ? answerMetadata.metadataItems : answerMetadata;

    return convertedMetadata;
}


// Metadata is stored in answer and question templates
export function formatLabel(answerOption, mode = "STANDARD") {

    let label = answerOption.optionText;

    if (hasIcon(answerOption)) {

        label = formatIcon(answerOption, mode);
    }

    return label;
}


// Place holder for surveyJs Icon Support
export function hasIcon(answerOption) {

    let hasIcon = false;

    let items = metadataAnswerOptionInstanceFormatToTemplateFormat(answerOption.metadata); // NOTE: Metadata JSON structure for an instance is different then for a template. (answerOption.metadata.metadataItems)

    // Check for icon position props in the metadata
    if (items.hasOwnProperty(ICON_POSITION)) {

        // and must have an icon selected
        if (items.hasOwnProperty(ICON_TYPE)) {

            switch(items[ICON_POSITION]) {
                case POSITION_BEFORE:
                case POSITION_AFTER:
                case POSITION_ONLY:
                case POSITION_ICON:
                    hasIcon = true;
                    break;
                default:
                    break;
            }
        }
    }

    return hasIcon;
}

// 0 is no icon, 1 = before, 2 = after and 3 = only
export function hasIconByPosition(metadata) {

    let hasIconPosition = 0;

    let items = metadataAnswerOptionInstanceFormatToTemplateFormat(metadata); // NOTE: Metadata JSON structure for an instance is different then for a template. (answerOption.metadata.metadataItems)

    // Check for icon position props in the metadata
    if (items.hasOwnProperty(ICON_POSITION)) {

        // and must have an icon selected
        if (items.hasOwnProperty(ICON_TYPE)) {

            switch(items[ICON_POSITION]) {
                case POSITION_BEFORE:
                    hasIconPosition = 1;
                    break;
                case POSITION_AFTER:
                    hasIconPosition = 2;
                    break;
                case POSITION_ONLY:
                    hasIconPosition = 3;
                    break;
                default:
                    break;
            }
        }
    }

    return hasIconPosition;
}


export function formatIcon(answerOption, mode = "STANDARD") {

    let formated = answerOption.optionText;

    let items = metadataAnswerOptionInstanceFormatToTemplateFormat(answerOption.metadata); // NOTE: Metadata JSON structure for an instance is different then for a template. (answerOption.metadata.metadataItems)

    // Check for icon position props in the metadata
    if (items.hasOwnProperty(ICON_POSITION)) {

        // and must have an icon selected
        if (items.hasOwnProperty(ICON_TYPE)) {

            let marginAdjustment = "";

            if (mode === "CHECKBOX") {
                marginAdjustment = "3px";
            }
            else {
                switch (items[ICON_TYPE]) {
                    case TYPE_THUMBS_UP:
                    case TYPE_THUMBS_DOWN:
                        marginAdjustment = "-5px";
                        break;
                    case TYPE_STAR:
                        marginAdjustment = "-10px";
                        break;
                    case TYPE_SENTIMENT_VERY_DISSATISFIED:
                    case TYPE_SENTIMENT_DISSATISFIED:
                    case TYPE_SENTIMENT_NEUTRAL:
                    case TYPE_SENTIMENT_SATISFIED:
                    case TYPE_SENTIMENT_VERY_SATISFIED:
                        marginAdjustment = "-5px";
                        break;
                    default:
                        marginAdjustment = "-10px";
                }
            }

            switch(items[ICON_POSITION]) {
                case POSITION_BEFORE:
                    formated = <span><div style={{display: "flex", alignItems: "center", marginTop: marginAdjustment}}>{iconType(answerOption)}<div style={{width: '10px'}}/><div style={{marginTop: '2px'}}>{formated}</div></div></span>;
                    break;
                case POSITION_AFTER:
                    formated = <span><div style={{display: "flex", alignItems: "center", marginTop: marginAdjustment}}><div>{formated}</div><div style={{width: '10px'}}/>{iconType(answerOption)}</div></span>;
                    break;
                case POSITION_ONLY:
                    formated = <span><div style={{display: "flex", alignItems: "center", marginTop: marginAdjustment}}>{iconType(answerOption)}</div></span>;
                    break;
                default:
                    break;
            }

        }
    }

    return formated;
}

export function getButtonIcon(metadata) {

    let items = metadataAnswerOptionInstanceFormatToTemplateFormat(metadata); // NOTE: Metadata JSON structure for an instance is different then for a template. (answerOption.metadata.metadataItems)

    let iconName = items[ICON_TYPE];
    let iconColor = items.hasOwnProperty(ICON_COLOR) ? items[ICON_COLOR] : '#2F4F4F' // QQQQQ "#2F4F4F"; // Default to black
    let selectedIcon = "";

    switch (iconName) {

        case TYPE_THUMBS_UP:
            selectedIcon = <IconThumbUp size="28" style={{color: iconColor}}/>;
            break;
        case TYPE_THUMBS_DOWN:
            selectedIcon = <IconThumbDown size="28" style={{color: iconColor}}/>;
            break;
        case TYPE_STAR:
            selectedIcon = <IconStarEmpty size="42" style={{color: iconColor}}/>;
            break;
        case TYPE_SENTIMENT_VERY_DISSATISFIED:
            selectedIcon = <IconSentiment1 size="32" style={{color: iconColor}}/>;
            break;
        case TYPE_SENTIMENT_DISSATISFIED:
            selectedIcon = <IconSentiment2 size="32" style={{color: iconColor}}/>;
            break;
        case TYPE_SENTIMENT_NEUTRAL:
            selectedIcon = <IconSentiment3 size="32" style={{color: iconColor}}/>;
            break;
        case TYPE_SENTIMENT_SATISFIED:
            selectedIcon = <IconSentiment4 size="32" style={{color: iconColor}}/>;
            break;
        case TYPE_SENTIMENT_VERY_SATISFIED:
            selectedIcon = <IconSentiment5 size="32" style={{color: iconColor}}/>;
            break;
        default:
            break;
    }

    return selectedIcon;
}


export function fillMode(metadata) {

    let fillMode = false;

    switch(metadata[ICON_POSITION]) {
        case POSITION_ICON_FILL:
        case POSITION_ICON_FILL_NO_TITLE:
            fillMode = true;
            break;
        default:
            break;
    }
    return fillMode;
}

// ---------------------------------------------------------------------------------------------------------------------
// ---------------------------------------------------------------------------------------------------------------------
// LIKERT
// ---------------------------------------------------------------------------------------------------------------------
// ---------------------------------------------------------------------------------------------------------------------

// Look at first answer, whatever icon-type it is will determine all inputs for that likert
export function getLikertInputType(answerOptions) {

    let iconType = TYPE_RADIO; // radio is default type

    if (answerOptions.length > 0) {

        let md = metadataAnswerOptionInstanceFormatToTemplateFormat(answerOptions[0].metadata); // NOTE: Metadata JSON structure for an instance is different then for a template. (answerOption.metadata.metadataItems)

        if (md.hasOwnProperty(ICON_TYPE)) {

            iconType = md[ICON_TYPE];
        }
    }

    return iconType;
}


export function useIconButtonsForLikertOld(answerOptions) {

    let useIcons = true;

    for (let i = 0; i < answerOptions.length; i++) {

        let md = metadataAnswerOptionInstanceFormatToTemplateFormat(answerOptions[i].metadata); // NOTE: Metadata JSON structure for an instance is different then for a template. (answerOption.metadata.metadataItems)

        // Must have an icon selected
        if (md.hasOwnProperty(ICON_TYPE)) {
            switch(md[ICON_POSITION]) {
                case POSITION_BEFORE:
                case POSITION_AFTER:
                case POSITION_ONLY:
                case POSITION_ICON:
                case POSITION_ICON_NO_TITLE:
                case POSITION_ICON_FILL:
                case POSITION_ICON_FILL_NO_TITLE:
                    break;
                default:
                    useIcons = false;
                    break;
            }
        }
        else {
            useIcons = false;
        }
    }

    return useIcons;
}


export function getLikertColumnLabel(answerOption) {

    let label = answerOption.optionText;

    let md = metadataAnswerOptionInstanceFormatToTemplateFormat(answerOption.metadata); // NOTE: Metadata JSON structure for an instance is different then for a template. (answerOption.metadata.metadataItems)

    switch(md[ICON_POSITION]) {
        case POSITION_ICON_NO_TITLE:
        case POSITION_ICON_FILL_NO_TITLE:
            label = "";
            break;
        default:
            break;
    }

    return label;
}


export function getLikertIconButton(savedObjs, answer, objId, objName, onClickFunction) {

    let md = metadataAnswerOptionInstanceFormatToTemplateFormat(answer.metadata); // NOTE: Metadata JSON structure for an instance is different then for a template. (answerOption.metadata.metadataItems)

    let iconName = md[ICON_TYPE];  // Already checked and confirmed to have an icon-type
    let iconColor = tag.metadataValue(answer, tag.ICON_UNSELECTED_COLOR, "", DEFAULT_ICON_COLOR_NOT_SELECTED);
    let iconOpacity = 1;
    let iconSelectedColor = tag.metadataValue(answer, tag.ICON_SELECTED_COLOR, "", DEFAULT_ICON_COLOR_SELECTED);
    if (iconSelectedColor === DEFAULT_ICON_COLOR_SELECTED) {
        iconSelectedColor = tag.metadataValue(answer, ICON_COLOR, "", DEFAULT_ICON_COLOR_SELECTED);
    }
    let iconSelectedOpacity = 1;

    // check question level overrides
    iconColor = tag.metadataValue(savedObjs.question, tag.OVERRIDE_ICON_UNSELECTED_COLOR, "", iconColor);
    iconOpacity = parseFloat(tag.metadataValue(savedObjs.question, tag.OVERRIDE_ICON_UNSELECTED_OPACITY, "", iconOpacity));
    iconSelectedColor = tag.metadataValue(savedObjs.question, tag.OVERRIDE_ICON_SELECTED_COLOR, "", iconSelectedColor);
    iconSelectedOpacity = parseFloat(tag.metadataValue(savedObjs.question, tag.OVERRIDE_ICON_SELECTED_OPACITY, "", iconSelectedOpacity));

    let fillMode = false;
    let selectedAnswerIndex = -1;
    let selectedAnswerIcon = "";
    let selectedAnswerColor = iconSelectedColor;
    let selectedAnswerOpacity = iconSelectedOpacity;

    switch(md[ICON_POSITION]) {
        case POSITION_ICON_FILL:
        case POSITION_ICON_FILL_NO_TITLE:
            fillMode = true;
            break;
        default:
            break;
    }

    if (fillMode) {
        // look and see if any answer has been selected
        for (let i = 0; i < savedObjs.answers.length; i++) {
            if (savedObjs.answers[i].selected) {

                let tempMd = metadataAnswerOptionInstanceFormatToTemplateFormat(savedObjs.answers[i].metadata); // NOTE: Metadata JSON structure for an instance is different then for a template. (answerOption.metadata.metadataItems)
                selectedAnswerIndex = i;
                selectedAnswerIcon = tempMd[ICON_TYPE];
                if (tempMd.hasOwnProperty(ICON_COLOR)) {
                    selectedAnswerColor = tempMd[ICON_COLOR];
                }
                else {
                    selectedAnswerColor = iconSelectedColor;  // default selected color (Light Blue)
                }
            }
        }

        // if ((selectedAnswerIndex !== -1) && (selectedAnswerIcon === TYPE_STAR)) {
        //     selectedAnswerIcon = TYPE_STAR_FILLED;
        // }
    }


    // Get the index of where the current selected answer
    let currentAnswerIndex = -1;
    for (let i = 0; i < savedObjs.answers.length; i++) {
        if (savedObjs.answers[i].id === answer.id) {
            currentAnswerIndex = i;
        }
    }


    // LIKERT answer icon buttons are always black and get color if selected and specified
    if (selectedAnswerIndex !== -1 && (currentAnswerIndex <= selectedAnswerIndex)) {
        iconName = (selectedAnswerIcon === TYPE_STAR) ? TYPE_STAR_FILLED : selectedAnswerIcon;
        iconOpacity = selectedAnswerOpacity;
        iconColor = selectedAnswerColor;
    }
    else {
        if (answer.selected) {
            iconName = (iconName === TYPE_STAR) ? TYPE_STAR_FILLED : iconName; // Selected star icons display as filled

            iconName = (iconName === TYPE_OUTLINED_DOUBLE_NEGATIVE) ? TYPE_SELECTED_DOUBLE_NEGATIVE : iconName;
            iconName = (iconName === TYPE_OUTLINED_NEGATIVE)        ? TYPE_SELECTED_NEGATIVE        : iconName;
            iconName = (iconName === TYPE_OUTLINED_NEUTRAL)         ? TYPE_SELECTED_NEUTRAL         : iconName;
            iconName = (iconName === TYPE_OUTLINED_POSITIVE)        ? TYPE_SELECTED_POSITIVE        : iconName;
            iconName = (iconName === TYPE_OUTLINED_DOUBLE_POSITIVE) ? TYPE_SELECTED_DOUBLE_POSITIVE : iconName;

            // if (md.hasOwnProperty(ICON_COLOR)) {
            //     iconColor = md[ICON_COLOR];
            // }
            // else {
            iconColor = iconSelectedColor;
            // }
        }
    }

    const iconSize = 40;

    let icon = "";

    switch (iconName) {

        case TYPE_THUMBS_UP:
            icon = <IconThumbUp size={iconSize} style={{color: iconColor, opacity: iconOpacity}} id={objId} name={objName} onClick={onClickFunction} />;
            break;
        case TYPE_THUMBS_DOWN:
            icon = <IconThumbDown size={iconSize} style={{color: iconColor, opacity: iconOpacity}} id={objId} name={objName} onClick={onClickFunction}/>;
            break;
        case TYPE_STAR:
            icon = <IconStarEmpty size={iconSize} style={{color: iconColor, opacity: iconOpacity}} id={objId} name={objName} onClick={onClickFunction}/>; //
            break;
        case TYPE_STAR_FILLED:
            icon = <IconStarFilled size={iconSize} style={{color: iconColor, opacity: iconOpacity}} id={objId} name={objName} onClick={onClickFunction}/>;
            break;
        case TYPE_SENTIMENT_VERY_DISSATISFIED:
            icon = <IconSentiment1 size={iconSize} style={{color: iconColor, opacity: iconOpacity}} id={objId} name={objName} onClick={onClickFunction}/>;
            break;
        case TYPE_SENTIMENT_DISSATISFIED:
            icon = <IconSentiment2 size={iconSize} style={{color: iconColor, opacity: iconOpacity}} id={objId} name={objName} onClick={onClickFunction}/>;
            break;
        case TYPE_SENTIMENT_NEUTRAL:
            icon = <IconSentiment3 size={iconSize} style={{color: iconColor, opacity: iconOpacity}} id={objId} name={objName} onClick={onClickFunction}/>;
            break;
        case TYPE_SENTIMENT_SATISFIED:
            icon = <IconSentiment4 size={iconSize} style={{color: iconColor, opacity: iconOpacity}} id={objId} name={objName} onClick={onClickFunction}/>;
            break;
        case TYPE_SENTIMENT_VERY_SATISFIED:
            icon = <IconSentiment5 size={iconSize} style={{color: iconColor, opacity: iconOpacity}} id={objId} name={objName} onClick={onClickFunction}/>;
            break;
        case TYPE_OUTLINED_DOUBLE_NEGATIVE:
            icon = <SvgUnselectedDoubleNegative fontSize={iconSize} fill={iconColor} opacity={iconOpacity} id={objId} name={objName} onClick={onClickFunction}/>;
            break;
        case TYPE_SELECTED_DOUBLE_NEGATIVE:
            icon = <SvgSelectedDoubleNegative fontSize={iconSize} fill={iconColor} opacity={iconOpacity}  id={objId} name={objName} onClick={onClickFunction}/>;
            break;
        case TYPE_OUTLINED_NEGATIVE:
            icon = <SvgUnselectedNegative fontSize={iconSize} fill={iconColor} opacity={iconOpacity} id={objId} name={objName} onClick={onClickFunction}/>;
            break;
        case TYPE_SELECTED_NEGATIVE:
            icon = <SvgSelectedNegative fontSize={iconSize} fill={iconColor} opacity={iconOpacity} id={objId} name={objName} onClick={onClickFunction}/>;
            break;
        case TYPE_OUTLINED_NEUTRAL:
            icon = <SvgUnselectedNeutral fontSize={iconSize} fill={iconColor} opacity={iconOpacity} id={objId} name={objName} onClick={onClickFunction}/>;
            break;
        case TYPE_SELECTED_NEUTRAL:
            icon = <SvgSelectedNeutral fontSize={iconSize} fill={iconColor} opacity={iconOpacity} id={objId} name={objName} onClick={onClickFunction}/>;
            break;
        case TYPE_OUTLINED_POSITIVE:
            icon = <SvgUnselectedPositive fontSize={iconSize} fill={iconColor} opacity={iconOpacity} id={objId} name={objName} onClick={onClickFunction}/>;
            break;
        case TYPE_SELECTED_POSITIVE:
            icon = <SvgSelectedPositive fontSize={iconSize} fill={iconColor} opacity={iconOpacity} id={objId} name={objName} onClick={onClickFunction}/>;
            break;
        case TYPE_OUTLINED_DOUBLE_POSITIVE:
            icon = <SvgUnselectedDoublePositive fontSize={iconSize} fill={iconColor} opacity={iconOpacity} id={objId} name={objName} onClick={onClickFunction}/>;
            break;
        case TYPE_SELECTED_DOUBLE_POSITIVE:
            icon = <SvgSelectedDoublePositive fontSize={iconSize} fill={iconColor} opacity={iconOpacity} id={objId} name={objName} onClick={onClickFunction}/>;
            break;
        default:
            break;
    }

    return icon;
}


export function formatIconForLikert(metadata, index, isSelected, answers, iconsSelectedArray) {

    let icon = iconType({metadata: metadata}, 'LIKERT', isSelected, answers, true, index, iconsSelectedArray);

    return icon;
}


function iconType(answerOption, mode = 'STANDARD', isSelected = false, answers, isFillMode, index, iconsSelectedArray) {

    let items = metadataAnswerOptionInstanceFormatToTemplateFormat(answerOption.metadata); // NOTE: Metadata JSON structure for an instance is different then for a template. (answerOption.metadata.metadataItems)

    let selectedIconName = items[ICON_TYPE];  // Alredy checked and confirmed to have an icon-type
    let selectedIconColor = "#2F4F4F";

    // Get the selected icon
    let selectedIndex = index;

    // Only satisfaction icons swap out depending on selected icon during fill mode
    switch (selectedIconName) {

        case TYPE_SENTIMENT_VERY_DISSATISFIED:
        case TYPE_SENTIMENT_DISSATISFIED:
        case TYPE_SENTIMENT_NEUTRAL:
        case TYPE_SENTIMENT_SATISFIED:
        case TYPE_SENTIMENT_VERY_SATISFIED:
            if (mode === 'LIKERT') {
                let itemsSelected = metadataAnswerOptionInstanceFormatToTemplateFormat(answers[selectedIndex].metadata); // NOTE: Metadata JSON structure for an instance is different then for a template. (answerOption.metadata.metadataItems)

                if (iconsSelectedArray !== undefined) {

                    if (iconsSelectedArray.length !== 0) {

                        for (let i = 0; i < iconsSelectedArray.length; i++) {
                            if (iconsSelectedArray[i]) {
                                selectedIndex = i;
                            }
                        }
                    }

                    // Swap out icons for fill mode

                    if (isFillMode) {
                        if (index <= selectedIndex) {
                            selectedIconName = itemsSelected[ICON_TYPE];
                            selectedIconColor = itemsSelected.hasOwnProperty(ICON_COLOR) ? itemsSelected[ICON_COLOR] : "#2F4F4F";
                        }
                    }
                }
            }
            break;
        default:
            break;
    }


    let selectedIcon = "";
    let iconColor = "#2F4F4F"; // Default to black NOTE: LIKERT are always displayed as black, and any color is applied when selected (Black #000000)

    if (mode === 'STANDARD' && items.hasOwnProperty(ICON_COLOR)) {
        iconColor = items[ICON_COLOR];
    }

    if (mode === 'LIKERT' && isSelected && items.hasOwnProperty(ICON_COLOR)) {
        iconColor = items[ICON_COLOR];
    }

    if (mode === 'LIKERT' && isFillMode && isSelected && items.hasOwnProperty(ICON_COLOR) && (selectedIconName !== TYPE_STAR)) {
        iconColor = selectedIconColor;
    }

    switch (selectedIconName) {

        case TYPE_THUMBS_UP:
            selectedIcon = <IconThumbUp size="28" style={{marginTop: mode==='LIKERT' ? '0px' : '0px', color: iconColor}}/>;
            break;
        case TYPE_THUMBS_DOWN:
            selectedIcon = <IconThumbDown size="28" style={{marginTop: mode==='LIKERT' ? '0px' : '0px', color: iconColor}}/>;
            break;
        case TYPE_STAR:
            // Star icons always swap to star filled, if fill mode all will swap up to selected
            if (isSelected) {
                selectedIcon = <IconStarFilled size="42" style={{marginTop: mode==='LIKERT' ? '-10px' : '0px', color: iconColor}}/>;
            }
            else {
                selectedIcon = <IconStarEmpty size="42" style={{marginTop: mode==='LIKERT' ? '-10px' : '0px', color: iconColor}}/>;
            }
            break;
        case TYPE_SENTIMENT_VERY_DISSATISFIED:
            selectedIcon = <IconSentiment1 size="32" style={{marginTop: mode==='LIKERT' ? '-3px' : '0px', color: iconColor}}/>;
            break;
        case TYPE_SENTIMENT_DISSATISFIED:
            selectedIcon = <IconSentiment2 size="32" style={{marginTop: mode==='LIKERT' ? '-3px' : '0px', color: iconColor}}/>;
            break;
        case TYPE_SENTIMENT_NEUTRAL:
            selectedIcon = <IconSentiment3 size="32" style={{marginTop: mode==='LIKERT' ? '-3px' : '0px', color: iconColor}}/>;
            break;
        case TYPE_SENTIMENT_SATISFIED:
            selectedIcon = <IconSentiment4 size="32" style={{marginTop: mode==='LIKERT' ? '-3px' : '0px', color: iconColor}}/>;
            break;
        case TYPE_SENTIMENT_VERY_SATISFIED:
            selectedIcon = <IconSentiment5 size="32" style={{marginTop: mode==='LIKERT' ? '-3px' : '0px', color: iconColor}}/>;
            break;
        default:
            break;
    }

    return selectedIcon;
}




