import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import {getQuestionnaireTemplate, createQuestionnaireTemplateAndCloseDrawer, saveQuestionnaireTemplateAndCloseDrawer, cloneQuestionnaireTemplateAndCloseDrawer, questionnaireTemplateInit, questionnaireTemplateClone} from "../../../actions/questionnaireTemplateActions";
import {openDrawer} from "../../../actions/drawerActions";
import {notificationOpen} from "../../../actions/notificationActions";
import AppBar from "@mui/material/AppBar";
import Card from "@mui/material/Card";
import CardHeader from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import IconButton from "@mui/material/IconButton";
//import IconMenu from "@mui/material/IconMenu";
import MenuItem from "@mui/material/MenuItem";
import MoreVertIcon from '@mui/icons-material/MoreVert';
import AddIcon from "@mui/icons-material/Add";
import QuestionnaireEditor from "./QuestionnaireEditor";
import QuestionnaireViewerDrawer, {questionnaireViewerDrawerProps} from "../../drawers/QuestionnaireViewerDrawer";
import QuestionnaireAddCategoryDrawer, {addCategoryProps} from "../../drawers/QuestionnaireAddCategoryDrawer";
import QuestionnaireAddQuestionDrawer, {addQuestionProps} from "../../drawers/QuestionnaireAddQuestionDrawer";
import QuestionnairePublishOrChangeDateDrawer, {questionnairePublishOrChangeDateDrawerProps} from "../../drawers/QuestionnairePublishOrChangeDateDrawer";
import {QD_URL} from "../../../constants/systemConstants";
import {QUESTIONNAIRE_COMPLEX} from "../../../constants/questionnaireType";
import * as TEMPLATE from "../../../constants/templateConstants";
import ArgoAppBar from "../../common/ArgoAppBar";
import ArgoIconButtonHook from "../../common/ArgoIconButtonHook";
import ArgoIconMenuMoreVertHook from "../../common/ArgoIconMenuMoreVertHook";
import ArgoTabPanelHook from "../../common/ArgoTabPanelHook";
import Fab from "@mui/material/Fab";
import CardMedia from '@mui/material/CardHeader';
import Typography from '@mui/material/Typography';
import { Button, CardActionArea, CardActions } from '@mui/material';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import MenuIcon from '@mui/icons-material/Menu';
import Toolbar from '@mui/material/Toolbar';
import UserContext from "../../../UserContext";
import ArgoCheckboxHook from "../../common/ArgoCheckboxHook";
import ArgoTemplateListCreateFabHook from "../../common/ArgoTemplateListCreateFabHook";
import style from "../../../blueTheme";
import {useNavigate} from "react-router-dom";
import ArgoToolbarHook from "../../common/ArgoToolbarHook";


class QuestionnaireContainer extends Component {
    constructor(props) {
        super(props);

        this.state = {
            templateKey: "",
            createMode: true,
            cloneMode: false,
            saveToolbarButtonDisabled: true,
            toolbarErrorText: ""
        };
    }

    static contextType = UserContext;  // From blueTheme.js

    componentDidMount() {

        const currURL = window.location.href;
        const objURL = new URL(currURL);

        let pathnameArray = objURL.pathname.split("/");

        let createMode = (pathnameArray[3] === ""); // Must be create mode
        let templateKey = pathnameArray[3];
        let templateId = createMode ? null : parseInt(pathnameArray[4]);
        let cloneMode = createMode ? false : (pathnameArray[5] === "1"); // Clone mode "0" = false, "1" = true

        if (!createMode) {
            this.props.getQuestionnaireTemplate(templateKey, templateId);
        }

        this.setState({templateKey, templateId, createMode, cloneMode});
    }

    openQuestionnaireViewerDrawer = () => {
        let template = this.props.templateEditor;
        let categories = this.props.categoryKeysEditor;

        this.props.openDrawer(questionnaireViewerDrawerProps, <QuestionnaireViewerDrawer templateKey={template.templateKey} />);
    };

    saveClone = (event, fileName, fieldName, index, changeDTO = null) => {
        if (this.props.options.hasError) {
            this.props.notificationOpen("Validate fields before saving.");
            return;
        }

        let updatedTemplate = {
            ...this.props.templateEditor,
            categoryKeys: this.props.categoryKeysEditor
        };

        if (changeDTO) {
            if (!changeDTO.notes || !changeDTO.startDate) {
                this.props.notificationOpen("Validate fields before saving.");
                return;
            }

            updatedTemplate.startDate = changeDTO.startDate;
            updatedTemplate.endDate = changeDTO.endDate;
            updatedTemplate.notes = changeDTO.notes;
        }

        this.props.questionnaireTemplateClone();
        this.props.cloneQuestionnaireTemplateAndCloseDrawer(updatedTemplate, this.props.filter, this.props.versionsFilter, TEMPLATE.MODE.CURRENT, this.navigateToQuestionnaireList);

    };

    save = (event, changeDTO = null) => {
        this.props.questionnaireTemplateInit();
        if (this.props.options.hasError) {
            this.props.notificationOpen("Validate fields before saving.");
            return;
        }

        let updatedTemplate = {
            ...this.props.templateEditor,
            categoryKeys: this.props.categoryKeysEditor,
        };

        // -------------------------------------------------------------------------------------------------------------
        // If Save and Publish this section sets the publish dates and note
        if (changeDTO) {
            if (!changeDTO.notes || !changeDTO.startDate) {
                this.props.notificationOpen("Validate fields before saving.");
                return;
            }

            updatedTemplate.startDate = changeDTO.startDate;
            updatedTemplate.endDate = changeDTO.endDate;
            updatedTemplate.notes = changeDTO.notes;
        }
        // -------------------------------------------------------------------------------------------------------------

        if (this.state.createMode) {
            updatedTemplate.questionnaireType = this.props.questionnaireType;

            this.props.createQuestionnaireTemplateAndCloseDrawer(updatedTemplate, this.props.filter, this.navigateToQuestionnaireList);
        }
        else {
            this.props.saveQuestionnaireTemplateAndCloseDrawer(updatedTemplate, this.props.filter, this.props.versionsFilter, TEMPLATE.MODE.CURRENT, this.navigateToQuestionnaireList);
        }
    };


    saveAndPublish = (event, changeDTO) => {
        this.props.questionnaireTemplateInit();
        this.save(null, changeDTO);
    };

    openSaveAndPublish = () => {
        if (this.props.options.hasError) {
            this.props.notificationOpen("Validate fields before saving.");
            return;
        }

        let templateKey = Object.keys(this.props.template)[0];

        this.props.openDrawer(questionnairePublishOrChangeDateDrawerProps, <QuestionnairePublishOrChangeDateDrawer
            title={"Publish " + this.props.templateEditor.name}
            buttonLabel="PUBLISH"
            template={this.props.templateEditor}
            currentStartDate={templateKey !== null ? (this.props.template[templateKey] !== undefined ? this.props.template[templateKey].startDate : "") : ""}
            action={this.saveAndPublish}
        />);
    };

    handleToolbarButtonClick = (event, fieldName, buttonTitle) => {
        switch (buttonTitle) {
            case "SAVE":
                if (this.state.cloneMode) {
                    this.saveClone();
                }
                else {
                    this.save();
                }
                break;
            case "CANCEL":
                this.navigateToQuestionnaireList();
                break;
            // no default
        }
    }

    navigateToQuestionnaireList = () => {
        //this.props.history.push(QD_URL + "/questionnaires");

        this.props.navHook(QD_URL + "/questionnaires", {replace: true})
    };

    openAddCategory = (e) => {
        this.props.openDrawer(addCategoryProps, <QuestionnaireAddCategoryDrawer/>);

        // Fix for the enter key propagating to the next focusable element
        //e.preventDefault();
    };

    render() {

        let displayName = "";
        if (!this.state.templateKey || this.props.templateEditor.templateKey === this.state.templateKey) {
            displayName = this.props.templateEditor.name;
        } else if (this.props.template[this.state.templateKey]) {
            displayName = this.props.template[this.state.templateKey].name;
        }

        // let iconMenuMoreVertMenuItems = [];
        //
        // if (this.state.cloneMode) {
        //     iconMenuMoreVertMenuItems.push({value: 0, text: "Save", onClick: this.saveClone});
        // }
        // else
        // {
        //     iconMenuMoreVertMenuItems.push({value: 0, text: "Save", onClick: this.save.bind(this)});
        //     iconMenuMoreVertMenuItems.push({value: 1, text: "Save and publish", onClick: this.openSaveAndPublish});
        //     iconMenuMoreVertMenuItems.push({value: 2, text: "Preview", onClick: this.openQuestionnaireViewerDrawer});
        // }

        const {palette} = this.context;

        let templateObjectLength = Object.keys(this.props.template).length;

        let templateKey = (templateObjectLength > 0) ? Object.keys(this.props.template)[0] : "";

        return (
            <div id="QuestionnaireContainer-main-div">

                <div  id="QuestionnaireContainer-container-div" style={{paddingTop: "60px", paddingLeft: "60px", paddingRight: "60px", paddingBottom: "10px", overflow: "visible"}}>

                    {/*{(templateObjectLength > 0) ?*/}
                    <Card style={{display: "flex", flexDirection: "column", height: "auto"}}>

                        {/*<CardHeader style={{display: "flex", flexDirection: "row", alignItems: "center", height: "65px", backgroundColor: palette.accent3Color}}>*/}
                        {/*    <Typography variant="h6" component="div" sx={{paddingLeft: "20px", color: "white"}}>*/}
                        {/*        {"Questionnaire: " + this.props.template[templateKey].name}*/}
                        {/*    </Typography>*/}
                        {/*    <div style={{paddingRight: "20px", marginLeft: "auto"}}>*/}
                        {/*        <ArgoIconMenuMoreVertHook*/}
                        {/*            fileName="QuestionContainer"*/}
                        {/*            fieldName="IconMenu"*/}
                        {/*            menuItems={iconMenuMoreVertMenuItems}*/}
                        {/*        />*/}
                        {/*    </div>*/}
                        {/*</CardHeader>*/}

                        <CardContent style={{height: "auto"}}>
                            <QuestionnaireEditor
                                createMode={this.props.createMode}
                                templateKey={this.state.templateKey}
                                templateId={this.state.templateId}
                                cloneMode={this.state.cloneMode}
                                handleToolbarButtonClick={this.handleToolbarButtonClick}
                            />
                        </CardContent>

                    </Card>

                    {/*}*/}

                    {/*<ArgoTemplateListCreateFabHook*/}
                    {/*    fileName="QuestionContainer"*/}
                    {/*    fieldName="fab"*/}
                    {/*    hoverText="Add/Remove Category"*/}
                    {/*    onClick={this.openAddCategory}*/}
                    {/*/>*/}

                </div>
            </div>
        );
    }
}

QuestionnaireContainer.defaultProps = {
    createMode: false,
    match: {},
    questionnaireTemplateActions: {},
    templateKey: "",
    template: undefined
};

QuestionnaireContainer.propTypes = {
    createMode: PropTypes.bool,
    templateKey: PropTypes.string,
    templateId: PropTypes.number,
    match: PropTypes.object,
};

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        getQuestionnaireTemplate,
        createQuestionnaireTemplateAndCloseDrawer,
        saveQuestionnaireTemplateAndCloseDrawer,
        cloneQuestionnaireTemplateAndCloseDrawer,
        openDrawer,
        notificationOpen,
        questionnaireTemplateInit,
        questionnaireTemplateClone
    }, dispatch);
}

function mapStateToProps(state, props) {
    //debugger;
    return {
        templateEditor: state.questionnaireEditor.template,
        categoryKeysEditor: state.questionnaireEditor.categoryKeys,
        options: state.questionnaireEditor.options,
        questionnaireType: state.questionnaireEditor.questionnaireType,
        template: state.questionnaireTemplate,
        filter: state.filter[TEMPLATE.FILTER.current.questionnaire],
        versionsFilter: state.filter[TEMPLATE.FILTER.versions.questionnaire]
    };
}

function myParams(Component,props) {
    return props => <Component {...props} navHook={useNavigate()} />;
}

export default connect(mapStateToProps, mapDispatchToProps)(myParams(QuestionnaireContainer));
