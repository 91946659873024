import * as React from "react";

function SvgUnselectedDoublePositive(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 24 24" {...props}>
      <defs>
        <path
          d="M3 0v1.799l1.8.001V3L3 2.999V4.8H1.8L1.799 3H0V1.8l1.799-.001L1.8 0H3z"
          id="unselected_doublePositive_svg__c"
        />
        <path
          d="M3 0v1.799l1.8.001V3L3 2.999V4.8H1.8L1.799 3H0V1.8l1.799-.001L1.8 0H3z"
          id="unselected_doublePositive_svg__e"
        />
        <rect
          id="unselected_doublePositive_svg__a"
          x={0}
          y={0}
          width={21.6}
          height={21.6}
          rx={10.8}
        />
      </defs>
      <g transform="translate(1.2 1.2)" fill="none" fillRule="evenodd">
        <mask id="unselected_doublePositive_svg__b" fill="#fff">
          <use xlinkHref="#unselected_doublePositive_svg__a" />
        </mask>
        <use fill="#FFF" xlinkHref="#unselected_doublePositive_svg__a" />
        <g
          mask="url(#unselected_doublePositive_svg__b)"
          fill="#000"
          fillOpacity={1}
        >
          <path d="M0 0h21.6v21.6H0z" />
        </g>
        <rect
          fill="#FFF"
          fillRule="nonzero"
          mask="url(#unselected_doublePositive_svg__b)"
          x={1.08}
          y={1.08}
          width={19.44}
          height={19.44}
          rx={9.72}
        />
        <g mask="url(#unselected_doublePositive_svg__b)">
          <g transform="translate(4.2 8.4)">
            <mask id="unselected_doublePositive_svg__d" fill="#fff">
              <use xlinkHref="#unselected_doublePositive_svg__c" />
            </mask>
            <use fill="#F4F4F4" xlinkHref="#unselected_doublePositive_svg__c" />
            <g
              mask="url(#unselected_doublePositive_svg__d)"
              fill="#000"
              fillOpacity={1}
            >
              <path d="M-1.2-1.2H6V6h-7.2z" />
            </g>
          </g>
        </g>
        <g mask="url(#unselected_doublePositive_svg__b)">
          <g transform="translate(12.6 8.4)">
            <mask id="unselected_doublePositive_svg__f" fill="#fff">
              <use xlinkHref="#unselected_doublePositive_svg__e" />
            </mask>
            <use fill="#F4F4F4" xlinkHref="#unselected_doublePositive_svg__e" />
            <g
              mask="url(#unselected_doublePositive_svg__f)"
              fill="#000"
              fillOpacity={1}
            >
              <path d="M-1.2-1.2H6V6h-7.2z" />
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}

export default SvgUnselectedDoublePositive;
