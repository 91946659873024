import React, {useContext} from "react";
import PropTypes from "prop-types";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import UserContext from "../../UserContext";

const styles = {
    sortIcon: {
        width: 18,
        height: 18,
        marginLeft: 0,
    },
    sortable: {
        cursor: 'pointer',
        float: 'left',
        minWidth: "15px",
        fontSize: 'small',
        paddingLeft: '0px',
        paddingRight: '0px',
        textTransform: 'none',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        display: 'inline-block'
    },
    unsortable: {
        cursor: 'pointer',
        float: 'left',
        fontSize: 'small',
        paddingRight: '0px',
        textTransform: 'none',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        display: 'inline-block',
        marginRight: '-10px'
    }
};


export default class ArgoListSortColumn extends React.PureComponent {
    constructor(props, context) {
        super(props, context);
        this.titleClickHandler = this.titleClickHandler.bind(this);
    }

    static contextType = UserContext;  // From blueTheme.js

    titleClickHandler(e) {
        let direction = this.props.gridState.direction === "asc"? "desc" : "asc";
        if (this.props.title !== this.props.gridState.name) {
            direction = "asc";
        }
        this.props.onSortChange(this.props.title, direction );
    }

    render() {

        let theme = this.context; // From blueTheme.js

        //let vLabelColor = this.props.sortable ? this.context.muiTheme.palette.primary1Color : this.context.muiTheme.palette.secondaryTextColorDark;
        let vLabelColor = this.props.sortable ? "#01579B" : "gray";
        let html;
        let icon;

        if (this.props.title === this.props.gridState.name)
            switch (this.props.gridState.direction) {
                case 'desc':
                    icon = <ArrowDownwardIcon style={{...styles.sortIcon, color: "rgba(0, 0, 0, 0.54 )"}}/>;
                    break;
                case 'asc':
                    icon = <ArrowUpwardIcon style={{...styles.sortIcon, color: "rgba(0, 0, 0, 0.54 )"}}/>;
                    break;
                default:
                    icon = '';
                    break;
            }

        if (this.props.sortable) {
            html = (<div style={{textAlign: "left", height: 0, width: "100%"}}>
                <div style={{
                    ...styles.sortable,
                    color: vLabelColor,
                    textAlign: this.props.align,
                    width: this.props.width}}
                     onClick={this.titleClickHandler}>
                    <div style={{marginLeft: icon === '' ? '-3px' : '-7px'}}>
                        <table>
                            <tbody>
                            {icon === '' ?
                                <tr height={this.props.height}>
                                    <td id={this.props.id} style={{color: theme.palette.listSortColumnTextColor}}>
                                        {this.props.title}
                                    </td>
                                </tr>
                                :
                                <tr height={this.props.height}>
                                    <td id="SortIcon" name="SortIcon">
                                        {icon}
                                    </td>
                                    <td id={this.props.id} name={this.props.title}>
                                        {this.props.icon? this.props.icon : this.props.title}
                                    </td>
                                </tr>
                            }
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>);
        }
        else // NOT Sortable
        {
            html = (
                <div id={this.props.id} name={this.props.title} style={{
                    ...styles.unsortable,
                    color: vLabelColor,
                    textAlign: this.props.align,
                    width: this.props.width,
                    minWidth: (this.props.title === "Pin") ? "15px" : "0px",
                    paddingLeft: (this.props.title === "Pin") ? "15px" : "0px",}}>
                    {this.props.title}
                </div>);
        }
        return (html);
    }
}

ArgoListSortColumn.defaultProps = {
    title: "Name",
    sortable: true,
    gridState: {},
    onSortChange: function () {},
    listIdentifier: "",
    align: 'left',
    width: '100%',
    height: '40px'
};

ArgoListSortColumn.propTypes = {
    title: PropTypes.string,
    sortable: PropTypes.bool,
    gridState: PropTypes.object,
    onSortChange: PropTypes.func,
    listIdentifier: PropTypes.string,
    align: PropTypes.oneOf(['left', 'right', 'center']),
    width: PropTypes.string,
    height: PropTypes.string,
    icon: PropTypes.object,
};
