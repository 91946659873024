import * as types from "../constants/actionConstants";

const initialState = {
    createdBy: null,
    createdDate: null,
    lastModifiedBy: null,
    lastModifiedDate: null,
    questionnaireTypeDisplayed: "default",
    showAdditionalProperties: true // Always show metadata sections in editors
};

function systemSettingReducer(state = initialState, action) {
    switch (action.type) {
        case types.SET_SYSTEM_SETTING:
            return {
                ...state,
                ...action.payload
            };
        default:
            return state;
    }
}

export default systemSettingReducer;
