import React, {Component} from 'react';
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import {closeDrawer} from "../../actions/drawerActions";
import GroupViewer from "../qd/groups/GroupViewer";
import ArgoAppBar from "../common/ArgoAppBar";
import ClickAwayListener from '@mui/material/ClickAwayListener';


class GroupViewerDrawer extends Component {

    render() {

        return (
            <ClickAwayListener onClickAway={this.props.closeDrawer}>
                <div>
                    <ArgoAppBar title="Review Group" showMenuArrowButton="LEFT" onLeftIconButtonTouchTap={this.props.closeDrawer} isDrawer={true}/>

                    <div>
                        <GroupViewer templateKey={this.props.templateKey} templateId={this.props.templateId}/>
                    </div>
                </div>
            </ClickAwayListener>
        );
    }
}

GroupViewerDrawer.propTypes = {
    templateKey: PropTypes.string,
    templateId: PropTypes.string,
};

function mapDispatchToProps(dispatch) {
    return bindActionCreators({closeDrawer}, dispatch);
}

const groupViewerDrawerProps = {
    opensecondary: true,
    width: "90%",
    docked: false,
    anchor: "right"
};

export default connect(null, mapDispatchToProps)(GroupViewerDrawer);
export {groupViewerDrawerProps};
