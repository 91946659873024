import React, {Component} from 'react';
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import {closeDrawer} from "../../actions/drawerActions";
import CategoryViewer from "../qd/categories/CategoryViewer";
import ArgoAppBar from "../common/ArgoAppBar";
import ClickAwayListener from '@mui/material/ClickAwayListener';


class CategoryViewerDrawer extends Component {

    render() {

        return (
            <ClickAwayListener onClickAway={this.props.closeDrawer}>
                <div>
                    <ArgoAppBar title="Review Category" showMenuArrowButton="LEFT" onLeftIconButtonTouchTap={this.props.closeDrawer} isDrawer={true}/>

                    <div>
                        <CategoryViewer templateKey={this.props.templateKey} templateId={this.props.templateId}/>
                    </div>
                </div>
            </ClickAwayListener>
        );
    }
}

CategoryViewerDrawer.propTypes = {
    templateKey: PropTypes.string,
    templateId: PropTypes.string
};


function mapDispatchToProps(dispatch) {
    return {close: bindActionCreators(closeDrawer, dispatch)};
}

const categoryViewerDrawerProps = {
    opensecondary: true,
    width: "90%",
    docked: false,
    anchor: "right"
};

export default connect(null, mapDispatchToProps)(CategoryViewerDrawer);
export {categoryViewerDrawerProps};
