import React, {Component} from 'react';
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import {ALLOWED_TOKEN} from "../../constants/dataConstants";
import ArgoAutocompleteHook from "./ArgoAutocompleteHook";
import ArgoTextDisplayDefination from "./ArgoTextDisplayDefination";
import ArgoTextDisplayCustom from "./ArgoTextDisplayCustom";
import * as TEMPLATE from "../../constants/templateConstants";


class ArgoTextEntryWithCustomOverride extends Component {
    constructor(props) {
        super(props);

        this.state = {

        };

    }

    componentDidMount() {

    }


    componentDidUpdate(nextProps) {

    }

    render() {

        let fileName = this.props.propsObj.fileName;
        let fieldName = this.props.fieldName;

        return (

            <div id="ArgoTextEntryWithCustomOverride-container-div" style={{width: "100%"}}>

                <div id={"ArgoTextEntryWithCustomOverride-" + fileName + "-" + fieldName + "-container-div"} style={{display: "flex", flexDirection: "row", width: '100%'}}>

                    <ArgoAutocompleteHook
                        fileName={fileName}
                        fieldName={fieldName}
                        index={this.props.index}
                        label={this.props.label}
                        onChange={this.props.propsObj.onTextChange}
                        value={this.props.titleType === "script" ? this.props.propsObj.metadata[fieldName] : this.props.propsObj.template[fieldName]}
                        menuItems={ALLOWED_TOKEN}
                        maxLength={this.props.maxlength}
                        error={this.props.errorText !== " "}
                        errorText={this.props.errorText === " " ? " " : this.props.errorText}
                        multiline={true}
                        width="96%"
                    />

                    <ArgoTextDisplayDefination
                        fieldName={this.props.titleType + "DisplayDefination"}
                        index={this.props.index}
                        initTo={this.props.initTo}
                        templateType={this.props.templateType}
                        titleType={this.props.titleType}
                        hideOnly={this.props.hideOnly}
                        metadataPropsObj={this.props.propsObj}
                    />

                </div>

                <div id={fileName + "-script-container-div"}>
                    {(this.props.initTo === 'custom') ?
                        <ArgoTextDisplayCustom
                            fieldName={this.props.titleType + "DisplayDefination"}
                            index={this.props.index}
                            initToObj={this.props.initToObj}
                            displayLabel={this.props.labelCustom}
                            metadataPropsObj={this.props.propsObj}
                        />
                        :
                        <div></div>
                    }
                </div>

                <div>&nbsp;</div>

            </div>
        );
    }
}

ArgoTextEntryWithCustomOverride.defaultProps = {
    maxlength: 255,
    errorText: " ",
    hideOnly: false,
    index: 0
};

ArgoTextEntryWithCustomOverride.propTypes = {
    fileName: PropTypes.string,
    fieldName: PropTypes.string,
    errorText: PropTypes.string,
    maxlength: PropTypes.number,
    label: PropTypes.string,
    labelCustom: PropTypes.string,
    value: PropTypes.string,
    titleType: PropTypes.string, // Template Type and titleType determine the text default properties.
    templateType: PropTypes.string,
    initTo: PropTypes.string,
    initToObj: PropTypes.object,
    hideOnly: PropTypes.bool,  // Categories only, if catagory is a titlebar, then all the text entries can be only HIDDEN
    propsObj: PropTypes.object,
    index: PropTypes.number,
};

function mapStateToProps(state, props) {
    return {};
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({}, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(ArgoTextEntryWithCustomOverride);
