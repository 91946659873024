import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import Popover from "@mui/material/Popover";
import Divider from '@mui/material/Divider';
import Button from "@mui/material/Button";
import Autocomplete from '@mui/material/Autocomplete';
import Avatar from "@mui/material/Avatar";
import Chip from "@mui/material/Chip";
import IconButton from "@mui/material/IconButton";
import { createTheme } from '@mui/material/styles';
import FilterListIcon from '@mui/icons-material/FilterList';
import FlashOnIcon from "@mui/icons-material/FlashOn"; // trackableIcon
import {setFilter, clearFilter} from "../../../actions/setFilter";
import {initialPageSortFilterObject} from "../../../constants/filterConstants";
import {pageableObjFromFilterSetPageOne, pageableObjFromFilter} from "../../../utilities/PageableAndFilterUtilities";
import {getTagDDLB} from "../../../actions/tagActions";
import * as TEMPLATE from "../../../constants/templateConstants";
import ArgoTextFieldHook from "../../common/ArgoTextFieldHook";
import ArgoTextFieldSelectHook from "../../common/ArgoTextFieldSelectHook";
import ArgoCheckboxHook from "../../common/ArgoCheckboxHook";
import TagSelectOrCreate from "../../qd/tags/TagSelectOrCreate";
import ArgoButtonHook from "../../common/ArgoButtonHook";
import ArgoUseEscapeKeyHook from "../../common/ArgoUseEscapeKeyHook";
import ArgoIconButtonHook from "../../common/ArgoIconButtonHook";
import {IntegrationTypes} from "../../../constants/integrationConstants";


// This fixes the DDLB transparency issue
const theme = createTheme({
    backgroundColor: "white"
});


const filterStyles = {
    gridStyle: {
        margin: 0
    },
    gridTile: {
        padding: 12
    },
    popoverStyle: {
        width: "700px",
    },
    cardStyle: {
        backgroundColor: "#f2f2f2",
        paddingTop:0,
        paddingBottom: 0
    }
};

let intiialFilterClear = Object.assign({}, initialPageSortFilterObject);  // Clone initialFilterObject object.

let initialFilter = Object.assign({}, initialPageSortFilterObject);  // Clone initialFilterObject object.

class FilterPopOver extends Component {
    constructor(props) {
        super(props);

        this.state = {
            open: false,
            tagName: "",
            selectedTags: [],
            typesArray: [],
            ...initialFilter
        };
    }

    componentDidMount() {
        this.props.getTagDDLB(pageableObjFromFilter(this.props.filterTags), this.props.filterTags);

        // Add All menu item to question types
        let questionTypesObj = {All: "All", ...this.props.types}; //Add All to questions types object
        let typesArray = [];
        Object.keys(questionTypesObj).map(type => typesArray.push({value: type, text: questionTypesObj[type]}));
        this.setState({typesArray: typesArray});
    }

// {Object.keys(this.props.types).map(type =>
// <MenuItem key={type} value={type} primaryText={self.props.types[type]}/>
// )}

    componentWillReceiveProps (newProps) {

        let tagsObjList = [];

        for (let i = 0; i < newProps.filter.tags.length; i++) {
            for (let ii = 0; ii < newProps.filterTags.list.length; ii++) {
                if (newProps.filter.tags[i] === newProps.filterTags.list[ii].id)
                    tagsObjList.push(newProps.filterTags.list[ii]);
            }
        }


        let newState = {
            tagName: "",
            selectedTags: tagsObjList,
            ...newProps.filter
        };

        newState.outstanding = (newProps.filter !== undefined) ? newProps.filter.outstanding : false;

        this.setState(newState)
    }

    handleIconButtonClick = (event) => {
        // This prevents ghost click.
        event.preventDefault();

        this.setState({
            open: true,
            anchorEl: event.currentTarget,
        });
    };

    onTextChange = (event, fieldName) => {
        this.setState({[fieldName]: event.target.value});
    };

    onSelectChange = (event, fieldName, menuItemText) => {
        this.setState({[fieldName]: event.target.value});
    };

    onCheckboxChange = (event, fieldName) => {
        this.setState({[fieldName]: event.target.checked});
    };


    // handleClearFilter = () => {
    //     if (typeof this.props.setLoadingState === "function") {
    //         this.props.setLoadingState(this.handleClear);
    //     }
    //     else {
    //         this.handleClear();
    //     }
    // };

    handleClearFilter = () => {

        if (typeof this.props.setLoadingState === "function") {
            this.props.setLoadingState(this.clearFilter, null, "FILTER"); // Added filter param for category window for fix filter dows not turn off render animation.
        }
        else {
            this.closeWindow();
        }

    };

    clearFilter = () => {
        let filter = {...this.props.filter};

        this.props.clearFilter(pageableObjFromFilterSetPageOne(this.props.filter), filter, this.props.templateType, this.props.templateMode);
        this.setState(intiialFilterClear);

        if (typeof this.props.setLoadingState === "function") {
            this.props.setLoadingState(this.closeWindow, null, "FILTER"); // Added filter param for category window for fix filter dows not turn off render animation.
        }
        else {
            this.closeWindow();
        }
    }

    submitFilter = () => {

        let tags = [];
        for (let i = 0; i < this.state.selectedTags.length; i++) {
            tags.push(this.state.selectedTags[i].id);
        }

        let filter = {...this.props.filter};

        filter.title = this.state.title;
        filter.nameQualifier = this.state.nameQualifier;
        filter.updatedBy = this.state.updatedBy;
        filter.status = this.state.status;
        filter.type = this.state.questionType ? this.state.questionType : this.state.actionType;
        filter.integrationType = this.state.integrationType;
        filter.outstanding = this.state.outstanding;
        filter.tags = tags;

        this.props.setFilter(pageableObjFromFilterSetPageOne(this.props.filter), filter, this.props.templateType, this.props.templateMode);

        if (typeof this.props.setLoadingState === "function") {
            this.props.setLoadingState(this.closeWindow, null, "FILTER"); // Added filter param for category window for fix filter dows not turn off render animation.
        }
        else {
            this.closeWindow();
        }
    };

    // onEscapeKey = (event) => {
    //     debugger;
    //     if (event.key === "Escape") {
    //         this.closeWindow();
    //     }
    // };

    closeWindow = () => {
        this.setState({open: false});
    }

    pickTag = (tagObj) => {
        let tagList = this.state.selectedTags;

        if (tagList.includes(tagObj)) {
            return;
        }

        tagList.push(tagObj);
        this.setState({tagName: "", selectedTags: tagList});
    };

    updateInput = (searchText) => {
        this.setState({tagName: searchText});
    };

    removeTag = (id) => {
        //debugger;
        let tagList = this.state.selectedTags.filter((tag) => {
            return tag.id !== id;
        });
        this.setState({tagName: "", selectedTags: tagList});
    };

    render() {
        if (!this.props.filter) {
            return null;
        }

        //let self = this; ZZZZZ

        const {
            style
        } = this.props;

        const {
            name,
            nameQualifier,
            updatedBy,
            status,
            type,
            integrationType,
            outstanding
        } = this.state;

        <ArgoUseEscapeKeyHook callbackFunction={this.onEscapeKey}/>

        // <EventListener target="window" onKeyUp={this.onEscapeKey} />

        return (
            <div style={{width: "100%", paddingRight: "5px"}}>

                <ArgoIconButtonHook
                    fileName="FilterPopOver"
                    fieldName="FilterListIcon"
                    onClick={this.handleIconButtonClick}
                    icon={<FilterListIcon/>}
                />

                <Popover
                    anchorOrigin = {{ vertical: 'bottom', horizontal: 'right'}}
                    // targetOrigin = {{ vertical: 'top', horizontal: 'right'}}
                    open={this.state.open}
                    anchorEl={this.state.anchorEl}
                    PaperProps={{style: {height: "430px", width: "700px", backgroundColor: "white"}}}
                    onRequestClose={this.closeWindow.bind(this)}
                >

                    <div style={{width: "100%", paddingTop: "5px", paddingLeft: "15px", height: "28px", backgroundColor: "#2497F3"}}>
                        <div style={{color: "white"}}>Filter Options</div>
                    </div>

                    <div style={{width: "100%", paddingTop: "15px", paddingLeft: "15px", paddingBottom: "15px", paddingRight: "15px"}}>

                        <div style={{height: "290px", width: "320px", float: "left"}}>

                            {this.props.showName ?
                                <ArgoTextFieldHook
                                    fileName="FilterPopOver"
                                    fieldName="title"
                                    label="Name"
                                    value={this.state.title}
                                    onChange={this.onTextChange}
                                />
                                : ""}

                            <div style={{height: "30px"}}>&nbsp;</div>

                            {this.props.showUpdatedBy ?
                                <ArgoTextFieldHook
                                    fileName="FilterPopOver"
                                    fieldName="updatedBy"
                                    label="Updated by"
                                    value={this.state.updatedBy}
                                    onChange={this.onTextChange}
                                />
                                : ""}

                            <div style={{height: "30px"}}>&nbsp;</div>

                            {this.props.showStatus ?
                                <ArgoTextFieldSelectHook
                                    fileName="FilterPopOver"
                                    fieldName="status"
                                    label="Status"
                                    value={this.state.status}
                                    onChange={this.onSelectChange}
                                    menuItems={this.props.statuses}
                                />
                                : ""}

                            <div style={{height: "30px"}}>&nbsp;</div>

                            {/*{this.props.showTitle ?*/}
                            {/*    <ArgoTextFieldHook*/}
                            {/*        fileName="FilterPopOver"*/}
                            {/*        fieldName="title"*/}
                            {/*        label="Title"*/}
                            {/*        value={this.state.title}*/}
                            {/*        onChange={this.onTextChange}*/}
                            {/*    />*/}
                            {/*: ""}*/}

                            {/*<div style={{height: "30px"}}>&nbsp;</div>*/}

                            {this.props.showOutstandingQuestionnaires ?
                                <ArgoCheckboxHook
                                    fileName="FilterPopOver"
                                    fieldName="outstanding"
                                    label="Outstanding questionnaires"
                                    onChange={this.onCheckboxChange}
                                    checked={outstanding}
                                />
                                : ""}

                            <div style={{height: "30px"}}>&nbsp;</div>

                        </div>

                        <div style={{height: "327px", width: "35px", float: "left"}}></div>

                        <div style={{height: "327px", width: "320px", float: "left"}}>

                            <div style={{height: "290px", width: "320px", float: "left"}}>

                                {this.props.showQualifier ?
                                    <ArgoTextFieldHook
                                        fileName="FilterPopOver"
                                        fieldName="nameQualifier"
                                        label="Qualifier"
                                        value={this.state.nameQualifier}
                                        onChange={this.onTextChange}

                                    />
                                    : ""}

                                <div style={{height: "30px"}}>&nbsp;</div>

                                {this.props.showQuestionType ?
                                    <ArgoTextFieldSelectHook
                                        fileName="FilterPopOver"
                                        fieldName="questionType"
                                        label="Question Type"
                                        value={this.state.questionType}
                                        onChange={this.onSelectChange}
                                        menuItems={this.state.typesArray}
                                    />
                                    : ""}

                                <div style={{height: "30px"}}>&nbsp;</div>

                                {this.props.showActionType ?
                                    <ArgoTextFieldSelectHook
                                        fileName="FilterPopOver"
                                        fieldName="actionType"
                                        label="Action Type"
                                        value={this.state.actionType}
                                        onChange={this.onSelectChange}
                                        menuItems={this.state.typesArray}
                                    />
                                    : ""}

                                {this.props.showIntegrationTypes ?
                                    <ArgoTextFieldSelectHook
                                        fileName="FilterPopOver"
                                        fieldName="integrationType"
                                        label="Integration type"
                                        value={integrationType}
                                        onChange={this.onSelectChange}
                                        menuItems={IntegrationTypes}
                                    />
                                    : ""}

                                <div style={{height: "20px"}}>&nbsp;</div>

                                <div>
                                    {this.props.showSelectTag ? <TagSelectOrCreate allowCreate={false}/> : <div cols="0"/>}
                                </div>

                            </div>

                        </div>

                    </div>

                    <div style={{clear: "both"}}></div>

                    <Divider variant="fullWidth" light/>

                    <div id="FilterPopOver-button-container-div" style={{display: "flex", flexDirection: "row", alignItems: "center", width: "100%", paddingLeft: "15px", paddingRight: "15px", paddingTop: "15px"}}>

                        <ArgoButtonHook
                            fileName="FilterPopOver"
                            fieldName="close"
                            variant="text"
                            label="Close"
                            width={100}
                            onClick={this.closeWindow}
                        />

                        <div id={"FilterPopOver-right-buttons-container-div"} style={{display: "flex", marginLeft: "auto"}}>

                            <ArgoButtonHook
                                fileName="FilterPopOver"
                                fieldName="clear"
                                variant="text"
                                label="Clear"
                                width={100}
                                onClick={this.handleClearFilter}
                            />


                            <div style={{width: "20px"}}>&nbsp;</div>

                            <ArgoButtonHook
                                fileName="FilterPopOver"
                                fieldName="search"
                                variant="outlined"
                                label="Search"
                                width={100}
                                onClick={this.submitFilter}
                            />

                            <div style={{width: "40px"}}>&nbsp;</div>

                        </div>

                    </div>

                </Popover>
            </div>
        );
    }
}


FilterPopOver.defaultProps = {
    showName: true,
    showUpdatedBy: true,
    showStatus: true,
    showQualifier: true,
    showSelectTag: false,
    showQuestionType: false,
    showOutstandingQuestionnaires: false,
    showIntegrationTypes: false,
    showActionType: false
};


FilterPopOver.propTypes = {
    style: PropTypes.object,
    submitValue: PropTypes.func.isRequired,
    page: PropTypes.string,
    templateType: PropTypes.string.isRequired,
    templateMode: PropTypes.string.isRequired,
    filterName: PropTypes.string.isRequired,
    types: PropTypes.object,
    tags: PropTypes.bool,
    outstanding: PropTypes.bool,
    integrationTypes: PropTypes.array,
    setLoadingState: PropTypes.func,
    showName: PropTypes.bool,
    showUpdatedBy: PropTypes.bool,
    showStatus: PropTypes.bool,
    statuses: PropTypes.array,
    showQualifier: PropTypes.bool,
    showSelectTag: PropTypes.bool,
    showQuestionType: PropTypes.bool,
    showOutstandingQuestionnaires: PropTypes.bool,
    showIntegrationTypes: PropTypes.bool,
    showActionType: PropTypes.bool
};

function mapStateToProps(state, props) {
    return {
        filter: state.filter[props.filterName],
        filterTags: state.filter[TEMPLATE.FILTER.all.selectExistingTags] || {}
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({setFilter, clearFilter, getTagDDLB}, dispatch);
}
export default connect(mapStateToProps, mapDispatchToProps)(FilterPopOver);


