import React, {Component} from 'react';
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import {closeDrawer} from "../../actions/drawerActions";
import QuestionnaireViewer from "../qd/questionnaires/QuestionnaireViewer";
import ArgoAppBar from "../common/ArgoAppBar";
import ClickAwayListener from '@mui/material/ClickAwayListener';


class QuestionnaireViewerDrawer extends Component {
    constructor(props) {
        super(props);

    }

    render() {

        return (
            <ClickAwayListener onClickAway={this.props.closeDrawer}>
                <div style={{height: "100%"}}>
                    <ArgoAppBar title="Review Questionnaire" showMenuArrowButton="LEFT" onLeftIconButtonTouchTap={this.props.closeDrawer} isDrawer={true}/>

                    <QuestionnaireViewer templateKey={this.props.templateKey}/>
                </div>
            </ClickAwayListener>
        );
    }
}

QuestionnaireViewerDrawer.propTypes = {
    templateKey: PropTypes.string,
};

function mapDispatchToProps(dispatch) {
    return bindActionCreators({closeDrawer}, dispatch);
}

const questionnaireViewerDrawerProps = {
    opensecondary: true,
    width: "90%",
    docked: false,
    anchor: "right"
};

export default connect(null, mapDispatchToProps)(QuestionnaireViewerDrawer);
export {questionnaireViewerDrawerProps};
