import * as types from "../constants/actionConstants";

const initialState = {};

function questionnaireRequiredTokensReducer(state = initialState, action) {
    switch (action.type) {
        case types.QUESTIONNAIRE_REQUIRED_TOKENS_GET_SUCCESS:
            return {
                ...action.requiredTokens
            };
        default:
            return state;
    }
}

export default questionnaireRequiredTokensReducer;
