import React, {Component} from 'react';
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import ArgoButtonHook from "./ArgoButtonHook";
import PortalContainer from "../../portal/PortalContainer";
import {getQuestionTemplate} from "../../actions/questionTemplateActions";
import * as TEMPLATE from "../../constants/templateConstants";


class ArgoPortalPreview extends Component {
    constructor(props) {
        super(props);

        this.state = {
            templateObj: null,
            initComponent: true,
            portalCount: 0
        };
    }

    componentWillReceiveProps(nextProps, nextContext) {
        // this.createPortalPreview(nextProps, nextProps.templateType, nextProps.id);
        //
        // this.setState({forceUpdate: false});
    }

    initComponent = () => {

        if (this.props.createMode) {
            switch(this.props.templateType) {
                case TEMPLATE.TYPE.QUESTIONNAIRE:
                    break;
                case TEMPLATE.TYPE.CATEGORY:
                    if (this.props.groupKeys?.length) {
                        // NOTE: Groups can be added or removed, so make sure ALL groups are loaded before sending to Portal Preview
                        let readyForPreview = true;
                        for (let i = 0; i < this.props.groupKeys.length; i++) {
                            if (!this.props.groupTemplates.hasOwnProperty(this.props.groupKeys[i])) {
                                readyForPreview = false;
                            }
                        }

                        if (readyForPreview) {

                            for (let j= 0; j < this.props.groupKeys.length; j++) {
                                if (this.props.groupTemplates.hasOwnProperty(this.props.groupKeys[j])) {
                                    // NOTE: Questions can be added or removed, so make sure ALL questions are loaded before sending to Portal Preview
                                    let questionKeys = this.props.groupTemplates[this.props.groupKeys[j]].questionKeys;
                                    if (questionKeys?.length) {
                                        for (let k = 0; k < questionKeys.length; k++) {
                                            if (!this.props.questionTemplates.hasOwnProperty(questionKeys[k])) {
                                                readyForPreview = false;
                                            }
                                        }
                                    }
                                }
                            }
                            if (readyForPreview) {
                                this.createPortalPreview(this.props, this.props.templateType, null);
                            }
                        }
                    }
                    else {
                        this.createPortalPreview(this.props, this.props.templateType, null);
                    }
                    break;
                case TEMPLATE.TYPE.GROUP:
                        // NOTE: Questions can be added or removed, so make sure ALL questions are loaded before sending to Portal Preview
                    if (this.props.questionKeys?.length) {
                        let readyForPreview = true;
                        for (let i = 0; i < this.props.questionKeys.length; i++) {
                            if (!this.props.questionTemplates.hasOwnProperty(this.props.questionKeys[i])) {
                                readyForPreview = false;
                            }
                        }
                        if (readyForPreview) {
                            this.createPortalPreview(this.props, this.props.templateType, null);
                        }
                    }
                    else {
                        this.createPortalPreview(this.props, this.props.templateType, null);
                    }
                    break;
                case TEMPLATE.TYPE.QUESTION:
                    this.createPortalPreview(this.props, this.props.templateType, null);
                    break;
                // no default
            }
        } else {
            // If edit mode then wait for a valid Template with a templateKey
            switch(this.props.templateType) {
                case TEMPLATE.TYPE.QUESTIONNAIRE:
return; // NOT ready to implement
                    if (this.props.questionnaireTemplate.templateKey !== "") {
                        // NOTE: Categories can be added or removed, so make sure ALL categories are loaded before sending to Portal Preview
                        let readyForPreview = true;
                        for (let i = 0; i < this.props.categoryKeys.length; i++) {
                            if (!this.props.categoryTemplates.hasOwnProperty(this.props.categoryKeys[i])) {
                                readyForPreview = false; // Wait for all categories to be loaded
                            }
                        }

                        if (readyForPreview) {
                            // All categories loaded now check for groups
                            for (let j = 0; j < this.props.categoryKeys.length; j++) {
                                // Check each category groupKeys
                                let groupKeys = this.props.categoryTemplates[this.props.categoryKeys[j]].groupKeys;
                                for (let k= 0; k < groupKeys.length; k++) {
                                    if (!this.props.groupTemplates.hasOwnProperty(groupKeys[k])) {
                                        readyForPreview = false; // Wait for all groups to be loaded
                                    }
                                }
                            }

                            if (readyForPreview) {
                                // All categories and groups loaded, now check for all questions loaded
                                for (let jj = 0; jj < this.props.categoryKeys.length; jj++) {
                                    // Check each category groupKeys
                                    let groupKeys = this.props.categoryTemplates[this.props.categoryKeys[jj]].groupKeys;
                                    for (let kk= 0; kk < groupKeys.length; kk++) {
                                        let questionKeys = this.props.groupTemplates[groupKeys[kk]].questionKeys
                                        for (let ll = 0; ll < questionKeys.length; ll++) {
                                            if (!this.props.questionTemplates.hasOwnProperty(questionKeys[ll])) {
                                                readyForPreview = false; // Wait for all questions to be loaded
                                            }
                                        }
                                    }
                                }
                            }

                            if (readyForPreview) {
                                this.createPortalPreview(this.props, this.props.templateType, this.props.questionnaireTemplate.id);
                            }
                        }
                    }
                    break;
                case TEMPLATE.TYPE.CATEGORY:
                    if (this.props.categoryTemplate.templateKey !== "") {
                        // NOTE: Groups can be added or removed, so make sure ALL groups are loaded before sending to Portal Preview
                        let readyForPreview = true;
                        for (let i = 0; i < this.props.groupKeys.length; i++) {
                            if (!this.props.groupTemplates.hasOwnProperty(this.props.groupKeys[i])) {
                                readyForPreview = false;
                            }
                        }

                        if (readyForPreview) {

                            for (let j= 0; j < this.props.groupKeys.length; j++) {
                                if (this.props.groupTemplates.hasOwnProperty(this.props.groupKeys[j])) {
                                    // NOTE: Questions can be added or removed, so make sure ALL questions are loaded before sending to Portal Preview
                                    let questionKeys = this.props.groupTemplates[this.props.groupKeys[j]].questionKeys;
                                    for (let k = 0; k < questionKeys.length; k++) {
                                        if (!this.props.questionTemplates.hasOwnProperty(questionKeys[k])) {
                                            readyForPreview = false;
                                        }
                                    }
                                }
                            }
                            if (readyForPreview) {
                                this.createPortalPreview(this.props, this.props.templateType, this.props.categoryTemplate.id);
                            }
                        }
                    }
                    break;
                case TEMPLATE.TYPE.GROUP:
                    if (this.props.groupTemplate.templateKey !== "") {
                        // NOTE: Questions can be added or removed, so make sure ALL questions are loaded before sending to Portal Preview
                        let readyForPreview = true;
                        for (let i = 0; i < this.props.questionKeys.length; i++) {
                            if (!this.props.questionTemplates.hasOwnProperty(this.props.questionKeys[i])) {
                                readyForPreview = false;
                            }
                        }
                        if (readyForPreview) {
                            this.createPortalPreview(this.props, this.props.templateType, this.props.groupTemplate.id);
                        }
                    }
                    break;
                case TEMPLATE.TYPE.QUESTION:
                    if (this.props.questionTemplate.templateKey !== "") {
                        this.createPortalPreview(this.props, this.props.templateType, this.props.questionTemplate.id);
                    }
                    break;
                // no default
            }

        }
    }

    createPortalPreview = (props, templateType, templateId) => {

        let templateObj = null;

        switch(templateType) {
            case TEMPLATE.TYPE.QUESTIONNAIRE:
                templateObj = this.createQuestionnaireTemplateObjForPortal(props.questionnaireTemplate, props.id, props.categoryKeys, props.categoryTemplates, props.groupKeys, props.groupTemplates, props.questionTemplates);
                break;
            case TEMPLATE.TYPE.CATEGORY:
                templateObj = this.createCategoryTemplateObjForPortal(props.categoryTemplate, props.id, props.groupKeys, props.groupTemplates, props.questionTemplates);
                break;
            case TEMPLATE.TYPE.GROUP:
                templateObj = this.createGroupTemplateObjForPortal(props.groupTemplate, props.id, props.answerOptionTemplates, props.questionTemplates, props.questionKeys)
                break;
            case TEMPLATE.TYPE.QUESTION:
                templateObj = this.createQuestionTemplateObjForPortal(props.questionTemplate, props.id, props.answerOptionTemplates);
                break;
            // no default
        }

        this.setState({initComponent: false, templateObj: templateObj, portalCount: props.portalCount});
    }

    createQuestionnaireTemplateObjForPortal = (updatedTemplate, id, categoryKeys, categoryTemplates, groupKeys, groupTemplates, questionTemplates) => {

        // Build list of all question keys needed
        let questionKeys = [];
        for (let i = 0; i < groupKeys.length; i++) {

            if (groupTemplates.hasOwnProperty(groupKeys[i])) {
                for (let j = 0; j < groupTemplates[groupKeys[i]].questionKeys.length; j++) {

                    questionKeys.push(groupTemplates[groupKeys[i]].questionKeys[j]);
                    this.props.getQuestionTemplate(groupTemplates[groupKeys[i]].questionKeys[j], null);
                }
            }
        }

        // -------------------------------------------------------------------------------------------------------------
        // -------------------------------------------------------------------------------------------------------------


        let questionCount = 0;
        let tempCategoryTemplate = {...updatedTemplate};

        tempCategoryTemplate.groupTemplates = [];

        if (groupTemplates.hasOwnProperty(groupKeys[0])) {

            for (let i = 0; i < groupKeys.length; i++) {

                tempCategoryTemplate.groupTemplates.push({});
                tempCategoryTemplate.groupTemplates[i] = {...groupTemplates[groupKeys[i]]};

                tempCategoryTemplate.groupTemplates[i].visible = true;
                tempCategoryTemplate.groupTemplates[i].questionTemplates = [];

                if (tempCategoryTemplate.groupTemplates[i].hasOwnProperty("questionKeys")) {

                    for (let j = 0; j < tempCategoryTemplate.groupTemplates[i].questionKeys.length; j++) {

                        if (tempCategoryTemplate.groupTemplates[i].questionKeys[j] !== undefined) {

                            if (questionTemplates.hasOwnProperty(tempCategoryTemplate.groupTemplates[i].questionKeys[j])) {
                                questionCount += 1;
                                tempCategoryTemplate.groupTemplates[i].questionTemplates.push({});
                                tempCategoryTemplate.groupTemplates[i].questionTemplates[j] = {...questionTemplates[tempCategoryTemplate.groupTemplates[i].questionKeys[j]]};
                            }
                        }
                    }
                }
            }
        }

        if (questionKeys.length === (questionCount)) {
            return(tempCategoryTemplate);
        }

    }

    createCategoryTemplateObjForPortal = (updatedTemplate, id, groupKeys, groupTemplates, questionTemplates) => {

        // Build list of all question keys needed
        let questionKeys = [];
        for (let i = 0; i < groupKeys.length; i++) {

            if (groupTemplates.hasOwnProperty(groupKeys[i])) {
                for (let j = 0; j < groupTemplates[groupKeys[i]].questionKeys.length; j++) {

                    questionKeys.push(groupTemplates[groupKeys[i]].questionKeys[j]);
                    this.props.getQuestionTemplate(groupTemplates[groupKeys[i]].questionKeys[j], null);
                }
            }
        }

        // -------------------------------------------------------------------------------------------------------------
        // -------------------------------------------------------------------------------------------------------------

        let questionCount = 0;
        let tempCategoryTemplate = {...updatedTemplate};

        tempCategoryTemplate.groupTemplates = [];

        if (groupTemplates.hasOwnProperty(groupKeys[0])) {

            for (let i = 0; i < groupKeys.length; i++) {

                tempCategoryTemplate.groupTemplates.push({});
                tempCategoryTemplate.groupTemplates[i] = {...groupTemplates[groupKeys[i]]};

                tempCategoryTemplate.groupTemplates[i].visible = true;
                tempCategoryTemplate.groupTemplates[i].questionTemplates = [];

                if (tempCategoryTemplate.groupTemplates[i].hasOwnProperty("questionKeys")) {

                    for (let j = 0; j < tempCategoryTemplate.groupTemplates[i].questionKeys.length; j++) {

                        if (tempCategoryTemplate.groupTemplates[i].questionKeys[j] !== undefined) {

                            if (questionTemplates.hasOwnProperty(tempCategoryTemplate.groupTemplates[i].questionKeys[j])) {
                                questionCount += 1;
                                tempCategoryTemplate.groupTemplates[i].questionTemplates.push({});
                                tempCategoryTemplate.groupTemplates[i].questionTemplates[j] = {...questionTemplates[tempCategoryTemplate.groupTemplates[i].questionKeys[j]]};
                            }
                        }
                    }
                }
            }
        }

        if (questionKeys.length === (questionCount)) {
            return(tempCategoryTemplate);
        }

    }


    createGroupTemplateObjForPortal = (updatedTemplate, id, answerOptionTemplates, updatedQuestionTemplate, updatedQuestionKeys) => {

        let tempGroupTemplate = {...updatedTemplate};

        tempGroupTemplate.answerOptionTemplates = answerOptionTemplates === undefined ? [] : answerOptionTemplates

        tempGroupTemplate.questionKeys = updatedQuestionKeys;

        tempGroupTemplate.questionTemplates = [];

        // Loop thru the question keys to get the question templates - NOTE: templates can contain more than Keys, so loop to only send the associated templates
        for (let j = 0; j < updatedQuestionKeys.length; j++) {
            for (let key in updatedQuestionTemplate) {
                if (updatedQuestionKeys[j] === key) {
                    tempGroupTemplate.questionTemplates.push(updatedQuestionTemplate[key]);
                    break;
                }
            }
        }

        return(tempGroupTemplate);
    }


    createQuestionTemplateObjForPortal = (questionTemplate, id, answerTemplates = []) => {

        // Make sure there is a question template
        if (questionTemplate) {

            let tempQuestionTemplate = {...questionTemplate};

            tempQuestionTemplate.answerOptionTemplates = answerTemplates; // Add answer templates to question template

            return(tempQuestionTemplate);
        }
    }

    render() {

        if (this.state.initComponent || (this.state.portalCount !== this.props.portalCount)) {
            this.initComponent();
        }

        let height = (this.props.templateType === TEMPLATE.TYPE.QUESTIONNAIRE) ? "100%" : "1000px";

        let templateObjClone = JSON.parse(JSON.stringify(this.state.templateObj)); // Remove mutation possibilities

        return (
            (this.state.templateObj && !this.state.initComponent) &&
            <div id={"ArgoPortalPreview-iFrame-container-div" + this.props.id} style={{display: this.props.hidePortalPreview ? "none" : "inline"}}>

                <div id="PortalPreview-container-div" style={{overflow: "hidden"}}>

                    <div style={{height: '20px'}}>&nbsp;</div>

                    <div>

                        <div id={"portalPreviewUniqueIdentifier"} style={{fontSize: "large", fontWeight: "bold", paddingBottom: "24px"}}>Portal Preview</div>

                        {(!this.state.initComponent) &&
                            <div style={{overflowY: "auto"}}>
                                <div>&nbsp;</div>
                                <PortalContainer
                                    portalPreview={true}
                                    templateType={this.props.templateType}
                                    templateObj={templateObjClone}
                                    portalCount={this.props.portalCount}
                                />
                            </div>
                        }

                        <div>&nbsp;</div>
                        <div>&nbsp;</div>
                        <div>&nbsp;</div>

                        {/*<iframe*/}
                        {/*    id={iFrameUniqueIdentifier}*/}
                        {/*    name={iFrameUniqueIdentifier}*/}
                        {/*    title={"Portal Preview " + templateSuffix}*/}
                        {/*    src={portalPath}*/}
                        {/*    height="100%"*/}
                        {/*    width="900px"*/}
                        {/*    style={{overflow: "hidden"}}*/}
                        {/*    scrolling={templateSuffix === QUESTIONNAIRE_URL_SUFFIX ? "No" : "Yes"}>*/}
                        {/*    onLoad={sendJson(iFrameUniqueIdentifier)}*/}
                        {/*</iframe>*/}

                    </div>

                    <div style={{height: '20px'}}>&nbsp;</div>

                </div>

            </div>
        );
    }
}

ArgoPortalPreview.defaultProps = {
    createMode: false,
    id: "",
    showButton: false,
    hidePortalPreview: false
};

ArgoPortalPreview.propTypes = {
    calledBy: PropTypes.string,
    templateType: PropTypes.string,
    createMode: PropTypes.bool,
    id: PropTypes.string,
    questionnaireTemplate: PropTypes.object,
    categoryTemplate: PropTypes.object,
    categoryTemplates: PropTypes.object,
    groupKeys: PropTypes.array,
    groupTemplate: PropTypes.object,
    groupTemplates: PropTypes.object,
    questionKeys: PropTypes.array,
    questionTemplate: PropTypes.object,
    questionTemplates: PropTypes.object,
    answerOptionTemplates: PropTypes.array,
    showButton: PropTypes.bool,
    hidePortalPreview: PropTypes.bool,
};


function mapStateToProps(state, props) {
    return {
        portalCount: state.portal.portalCount
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({getQuestionTemplate}, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(ArgoPortalPreview);

