import React, { useState, useEffect } from 'react';
import {Link} from 'react-router-dom';
import MenuItem from "@mui/material/MenuItem";
import {QD_URL} from "../../constants/systemConstants";
import style from "../../blueTheme";


export default function ArgoLeftNavLinkHook(props) {

    const linkTo = props.linkTo === undefined ? "" : QD_URL + props.linkTo;
    const title = props.title === undefined ? "undefined" : props.title;

    const uniqueIdentifier = "ArgoLeftNavLinkHook-" + title.toLowerCase().replaceAll(" ", "-");

    const onClick = props.onClick === undefined ? function () {} : props.onClick;

    const handleOnClick = (event) => {
        onClick(event)
    };

    useEffect(() => {

    })

    return (
        linkTo === "" ?
            <MenuItem style={{color: "black"}} onClick={handleOnClick}>{title}</MenuItem>
            :
            <Link id={uniqueIdentifier}
                  style={{color: "black", textDecoration: 'none'}}
                  to={linkTo}>
                <MenuItem onClick={handleOnClick}>{title}</MenuItem>
            </Link>
    );
}


