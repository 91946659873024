import React, {Component} from 'react';
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import {closeDrawer} from "../../actions/drawerActions";
import IntegrationViewer from "../qd/integrations/IntegrationViewer";
import ArgoAppBar from "../common/ArgoAppBar";
import ClickAwayListener from '@mui/material/ClickAwayListener';


class IntegrationViewerDrawer extends Component {

    render() {

        return (
            <ClickAwayListener onClickAway={this.props.closeDrawer}>
                <div>
                    <ArgoAppBar title="Review Integration" showMenuArrowButton="LEFT" onLeftIconButtonTouchTap={this.props.closeDrawer} isDrawer={true}/>

                    <div>
                        <IntegrationViewer id={this.props.id}/>
                    </div>
                </div>
            </ClickAwayListener>
        );
    }
}

IntegrationViewerDrawer.propTypes = {
    id: PropTypes.string
};

function mapDispatchToProps(dispatch) {
    return bindActionCreators({closeDrawer}, dispatch);
}

const integrationViewerDrawerProps = {
    opensecondary: true,
    width: "50%",
    docked: false,
    anchor: "right"
};

export default connect(null, mapDispatchToProps)(IntegrationViewerDrawer);
export {integrationViewerDrawerProps};
