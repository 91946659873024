
// from https://www.geeksforgeeks.org/calculate-the-width-of-the-text-in-javascript/
// Used to set width of parent div to allow for more accurate margin calculations
export function getLabelWidth(label) {
    let text = document.createElement("span");
    document.body.appendChild(text);

    text.style.font = "Roboto";
    text.style.fontSize = .75 + "rem";
    text.style.height = 'auto';
    text.style.fontWeight = '500';
    text.style.width = 'auto';
    text.style.position = 'absolute';
    text.style.whiteSpace = 'no-wrap';
    text.innerHTML = label;

    let labelWidth = Math.ceil(text.clientWidth);
    document.body.removeChild(text);

    return labelWidth;
}


export function getLabelWidth2(label) {
    let text = document.createElement("span");
    document.body.appendChild(text);

    text.style.font = "Roboto";
    text.style.fontSize = .75 + "rem";
    text.style.height = 'auto';
    text.style.fontWeight = '500';
    text.style.width = 'auto';
    text.style.position = 'absolute';
    text.style.whiteSpace = 'no-wrap';
    text.innerHTML = label;

    let labelWidth = Math.ceil(text.clientWidth);
    document.body.removeChild(text);

    return labelWidth;
}


// // from https://www.geeksforgeeks.org/calculate-the-width-of-the-text-in-javascript/
// function getLabelWidth(label) {
//     let text = document.createElement("span");
//     document.body.appendChild(text);
//
//     text.style.font = "times new roman";
//     text.style.fontSize = 16 + "px";
//     text.style.height = 'auto';
//     text.style.width = 'auto';
//     text.style.position = 'absolute';
//     text.style.whiteSpace = 'no-wrap';
//     text.innerHTML = label;
//
//     let labelWidth = Math.ceil(text.clientWidth) + 2;
//     document.body.removeChild(text);
//
//     return labelWidth;
// }



// from https://www.geeksforgeeks.org/calculate-the-width-of-the-text-in-javascript/
// Used to set width of parent div to allow for more accurate margin calculations
// export function getLabelWidth(label) {
//     let text = document.createElement("span");
//     document.body.appendChild(text);
//
//     text.style.font = "Roboto";
//     text.style.fontSize = .75 + "rem";
//     text.style.height = 'auto';
//     text.style.fontWeight = '500';
//     text.style.width = 'auto';
//     text.style.position = 'absolute';
//     text.style.whiteSpace = 'no-wrap';
//     text.innerHTML = label;
//
//     let labelWidth = Math.ceil(text.clientWidth);
//     document.body.removeChild(text);
//
//     return labelWidth;
// }
