import React, { useState, useEffect } from 'react';
import TextField from "@mui/material/TextField";


export default function ArgoTextFieldHook(props) {
    const [error, setError] = useState(null);

    const fileName = props.fileName === undefined ? "NoFileName" : props.fileName;
    const fieldName = props.fieldName === undefined ? "NoFieldName" : props.fieldName;

    const index = props.index === undefined ? "" : props.index; // If field is built in a loop index is required to know which field to match up with

    const uniqueIdentifier = fileName + "-ArgoTextFieldHook-" + fieldName + index;

    const variant = props.variant === undefined ? "standard" : props.variant;
    const type = props.type === undefined ? "text" : props.type;

    const label = props.label === undefined ? "" : props.label;
    const placeholder = props.placeholder === undefined ? "" : props.placeholder;

    const value = props.value === undefined ? "" : props.value;
    const maxLength = props.maxLength === undefined ? 255 : props.maxLength;

    const width = props.width === undefined ? 0 : props.width;
    const fullWidth = props.fullWidth === undefined ? ((width !== 0) ? false : true) : false;

    const autoFocus = props.autoFocus === undefined ? false : props.autoFocus;


    const onChange = props.onChange === undefined ? function () {} : props.onChange;
    const onKeyDown = props.onKeyDown === undefined ? function () {} : props.onKeyDown;
    const onBlur = props.onBlur === undefined ? function () {} : props.onBlur;

    const display = props.display === undefined ? "inline" : "none";
    const multiline = props.multiline === undefined ? false : true;
    const paddingBottom = props.style === undefined ? "-5px" : props.paddingBottom;

    const divContainerHeight = props.divContainerHeight === undefined ? "auto" : props.divContainerHeight;

    const errorText = props.errorText === undefined ? " " : props.errorText;

    const validationType = props.validationType === undefined ? "" : props.validationType;

    const disabled = props.disabled === undefined ? false : props.disabled;

    const style = props.style === undefined ? {} : props.style;

    const handleOnChange = (event) => {
        onChange(event, fieldName, index, validationType);
    };

    const handleOnKeyDown = (event) => {
        onKeyDown(event, fieldName, index, validationType);
    };

    const handleOnBlur = (event) => {
        onBlur(event, fieldName, index, validationType);
    };

    useEffect(() => {
        setError(errorText !== " ");
    }, [value, errorText, error])

    return (
        <div id={uniqueIdentifier + "-container-div"} style={{paddingBottom: paddingBottom, height: divContainerHeight, width: fullWidth ? "100%" : width}}>
            <TextField  id={uniqueIdentifier}
                        name={uniqueIdentifier}
                        variant={variant}
                        type={type}
                        label={label}
                        value={value === null ? "" : value}
                        placeholder={placeholder}
                        autoFocus={autoFocus}
                        onChange={handleOnChange}
                        onKeyDown={handleOnKeyDown}
                        onBlur={handleOnBlur}
                        inputProps={{autoComplete: "off", maxLength: maxLength}}
                        fullWidth={fullWidth}
                        sx={{width: fullWidth ? "100%" : width, paddingBottom: paddingBottom}}
                        multiline={multiline}
                        error={error}
                        helperText={errorText}
                        disabled={disabled}
            />
        </div>
    );
}

// sx={{ marginBottom: 4}}

// onKeyDown={handleOnKeyDown}
// sx={{display: display}}

// const textfieldStyling = {
//     "& label": {
//         //display: "none",
//         //visibility: "hidden",
//         //whiteSpace: "normal",
//         color: "secondary.main",
//         marginLeft: "65%",
//         width: 100,
//         "&.Mui-focused": {
//             marginLeft: 0
//             //display: "none"
//         }
//     }
//     // "& legend": {
//     //   display: "none"
//     // }
// };


// const handleOnKeyDown = (event) => {
//     onChange(event, props.fieldName)
// };
