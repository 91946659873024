import React, {PureComponent} from "react";
import Countdown from 'react-countdown';
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import {styled} from "@mui/material/styles";
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Hidden from '@mui/material/Hidden';
import Modal from '@mui/material/Modal';
import Button from '@mui/material/Button';
import {
    getQuestionnaire,
    startQuestionnaire,
    completeQuestionnaire,
    getQuestionnaireInstance,
    getQuestionnaireReadonlyInstance,
    setQuestionnaireTemplate,
    setTemplateObj,
    setQuestionnaireInstanceNull,
    currentPage,
    nextPage,
    prevPage,
    getNewTokenId,
    clearPortalPreviewMode
} from "./actions/portalActions";
import {setTheme} from "./actions/themeActions";
import Content from "./Content";
import ErrorPage from "./common/ErrorPage";
import {createCustomTheme} from "./PortalStyle";
import PortalPageRenderer from "./PortalPageRenderer";
import * as mode from "./utilities/displayOneQuestionPerPage";
import {isRunningInInternetExplorer} from "./utilities/misc";
import * as ddoUtil from "./utilities/createPortalJsonObj";
import * as tag from "./constants/customMetadataConstants";
import * as ddo from "./constants/customObjConstants";
import { ThemeProvider, createTheme } from '@mui/material/styles';

import {STORAGE_TOKEN} from "./constants/systemConstants";
import {createJsonObjFromQuestionnaire} from "./utilities/createPortalJsonObj";
import {
    PORTAL_PREVIEW_CATEGORY,
    PORTAL_PREVIEW_GROUP,
    PORTAL_PREVIEW_QUESTION
} from "./constants/customMetadataConstants";
//import {theme as themeActions} from "../actions";
//import Switch from '@mui/material/Switch';
//import jwt_decode from "jwt-decode";
//import Backdrop from '@mui/material/Backdrop';
//import Fade from '@mui/material/Fade';

const PRIMARY_BLUE = "#01579B"; // For AppBar, need to allow override color

const theme = createTheme({
    palette: {
        primary: {
            main: "#01579B"
        },
    }
});

class PortalContainer extends PureComponent {

    constructor(props) {
        super(props);

        this.state = {
            ddo: null, //Display Definition Object
            currPageObjIndex: 0,
            canceled: false,
            displayMode: mode.INSTANT_MODE,
            firstQuestionMode: mode.SHOW_FIRST_QUESTION,
            surveyEditMode: mode.EDITABLE_SURVEY,
            surveyReadonlyHeaderMode: mode.READONLY_SURVEY_URL_SHOW_HEADER_NONE,
            switchOn: false,
            useCustomTheme: false,
            darkMode: false,
            pageTitle: "",
            clearPage: false,
            showTokenExpirationModal: false,
            tokenValidationExpired: false,
            refKey: "",
            parsedJwtExp: 3540000,
            initComponent: true
        };
    }


    componentWillReceiveProps(nextProps) {

        if (nextProps.templateObj !== null) {
            let templateObjString = JSON.stringify(nextProps.templateObj);
            let templateObjFromStoreString = JSON.stringify(nextProps.templateObjFromStore);

            // When a questionnaire is created for portal previw it will be set into the redux store
            if (nextProps.portalPreview && (nextProps.templateType !== tag.PORTAL_PREVIEW_QUESTIONNAIRE)
                && ((templateObjString !== templateObjFromStoreString) || (this.state.ddo === null))) {

                // Portal Preview mode and the templateObj has changed from the one in the redux store
                this.portalPreviewMain(nextProps);
            }
        }
        else if (this.state.ddo === null) {
            if (nextProps.questionnaireInstance !== null) {
                let ddo = ddoUtil.createJsonObjFromQuestionnaire(nextProps.questionnaireInstance, mode.INSTANT_MODE, this.state.firstQuestionMode, this.state.surveyEditMode, this.state.surveyReadonlyHeaderMode, nextProps.metadata);

                this.setPageTitle({
                    title: nextProps.questionnaireInstance.name,
                });
                this.setState({ddo: ddo});  // Set the Display Definition Object
            }
            else if (nextProps.questionnaireTemplate !== null) {

                // If questionnaire portal preview mode or admin override then wait for template from server to build ddo (Display Defination Object)
                let ddo = ddoUtil.createJsonObjFromQuestionnaire(nextProps.questionnaireTemplate, mode.TEMPLATE_MODE, mode.SHOW_FIRST_QUESTION, mode.EDITABLE_SURVEY);

                this.setState({ddo: ddo});  // Set the Display Definition Object
            }
        }
    }

    componentWillUnmount() {
        this.props.clearPortalPreviewMode(); // Clear portal preview redux store props
    }

    initComponent = () => {

        this.setState({initComponent: false});

        if (this.props.portalPreview) {
            this.portalPreviewMain(this.props);
        } else {
            this.portalMain();
        }

    }

    // from https://stackoverflow.com/questions/58917064/dynamic-page-title-with-reactjs
    setPageTitle (data = {}) {
        data.title = data.title || 'QD Portal Questionnaire';
        document.title = data.title;
    }

    portalMain = () => {

        this.setPageTitle({
            title: this.state.pageTitle,
        });

        let currURL =  new URL(window.location.href);

        let parsedURL = currURL.pathname.split("/");
        let refKey = parsedURL[2];

        //let refKey = this.props.match.params.key;

        // -------------------------------------------------------------------------------------------------------------
        // Get and PARSE the JWT (JSON Web Token) Security Token to get the timeout amount (JWT Security Token)
        let parsedJWT = this.state.parsedJwtExp;

        let token = localStorage.id_token; // getItem(STORAGE_TOKEN);

        if (localStorage.id_token !== undefined) {

            var base64Url = token.split('.')[1];
            var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
            var jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function (c) {
                return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
            }).join(''));

            parsedJWT = JSON.parse(jsonPayload); // 1659472470
        }

        let parsedJwtExp = (parsedJWT.exp === undefined) ? this.state.parsedJwtExp : parsedJWT.exp;

        // -------------------------------------------------------------------------------------------------------------

        this.setState({refKey: refKey, parsedJwtExp: parsedJwtExp});

        if (refKey !== undefined) {

            // If a ref key then a query string can answer the first question key?firstQuestionIndex=2
            if(token === "") {
                this.props.getNewTokenId(refKey);
            }

            // show warning message modal for expiring token
            setTimeout(() => {
                this.setState({showTokenExpirationModal: true});
            }, parsedJwtExp);  // default was 3540000


            // Trying to figure out a way to accurately get remaining time for expiring token
            // if(token !== '') {
            //     // let decodedToken = jwt_decode(token);
            //
            //     // let tokenTimeout = decodedToken.exp;
            //     //
            //     // const d = new Date(0);
            //     // d.setUTCSeconds(tokenTimeout);
            //     //
            //     // let currTime = Date().toLocaleString();
            //
            //     // let curTimeNew = new Date(currTime);
            //     // let expTimeNew = new Date(d);
            //
            //     // let timeUntilTokenExpiration = ((expTimeNew - curTimeNew));
            //
            //     setTimeout(() => {
            //         this.setState({showTokenExpirationModal: true});
            //     }, 3480000);
            // }

            // If a ref key then a query string can answer the first question key?firstAnswer=2
            //let currURL =  new URL(window.location.href);



            let urlParams = currURL.search;
            let query = new URLSearchParams(currURL.search); //need new ways to get ?readonly=true and other query options

            let answerIndex = null;
            let surveyEditMode = mode.EDITABLE_SURVEY;
            let surveyReadonlyHeaderMode = mode.READONLY_SURVEY_URL_SHOW_HEADER_NONE;



            // NOTE: there are 2 types of refKeys, the instance and the readonly instance, if readonly parameter is used you must use the readonly refKey
            // example URL http://localhost:3001/qd2portal/ExIB5E2kqqz95ASxp5mT?readonly=true for readonly mode
            // .get is a function in the new URLSearchParams
            if ((query !== "") && (query.get("readonly") === "true")) {
                // &header=false|filtered parameter NOTE: the questionnare can use metadata for this HIDE_READONLY_HEADER or FILTERED_READONLY_HEADER
                surveyEditMode = mode.READONLY_SURVEY;
                if (query.get("header") === "false") {
                    surveyReadonlyHeaderMode = mode.READONLY_SURVEY_URL_SHOW_HEADER_FALSE;
                }

                // NOTE: show header is by default is filtered, but this will OVERRIDE questionnaire hide or show metadata tag.
                if (query.get("header") === "filter") {
                    surveyReadonlyHeaderMode = mode.READONLY_SURVEY_URL_SHOW_HEADER_FILTERED;
                }

                if (query.get("header") === "true") {
                    surveyReadonlyHeaderMode = mode.READONLY_SURVEY_URL_SHOW_HEADER_TRUE;
                }
            }
            else {

                // I think the way this works is ?answer=###  where ### is the ID of the first answer on the survey
                answerIndex = urlParams.match(/\d+/);
                if(answerIndex) {
                    answerIndex = answerIndex[0];
                } else{
                    answerIndex = null;
                }
            }

            let firstQuestionMode = (answerIndex === null) ? mode.SHOW_FIRST_QUESTION : mode.SKIP_FIRST_QUESTION;

            // If the first question is answered then start questionnaire will be triggered with get instance and first question answered
            this.setState({firstQuestionMode: firstQuestionMode, displayMode: mode.INSTANT_MODE, surveyEditMode: surveyEditMode, surveyReadonlyHeaderMode: surveyReadonlyHeaderMode});

            // NOTE: there are 2 types of refKeys, the instance andn the readonly instance, if readonly parameter is used you must use the readonly refKey
            if (surveyEditMode === mode.READONLY_SURVEY) {
                this.props.getQuestionnaireReadonlyInstance(refKey, answerIndex); // Get the instance ID
            }
            else {
                this.props.getQuestionnaireInstance(refKey, answerIndex); // Get the instance ID
            }

        }

        if (this.props.questionnaireInstanceId !== null) {
            this.props.getQuestionnaire(this.props.questionnaireInstanceId);  // Get the full instance
        }
    }

    portalPreviewMain = (props) => {

        let clonedTemplateObj = null;
        let ddo = null;

        // See if portal received a questionnaire template graph.
        if ((props.templateType === tag.PORTAL_PREVIEW_QUESTIONNAIRE_GRAPH) && (this.state.initComponent)) {
            // Graph mode is passed a complete questionnaire graph tempalte which is in a different structure then a standard template
            clonedTemplateObj = this.adjustQuestionnaireTemplateGraph(JSON.parse(JSON.stringify(props.templateObj)));

            // Since this is graph mode and we already have a complete questionnaire template graph no further processing is required, so save them to store.
            props.setQuestionnaireTemplate(clonedTemplateObj);
            props.setTemplateObj(clonedTemplateObj);
            props.currentPage(0);

            // Convert questionniare template graph to a survey ddo (Display Definition Object)
            ddo = ddoUtil.createJsonObjFromQuestionnaire(clonedTemplateObj, mode.TEMPLATE_MODE, mode.SHOW_FIRST_QUESTION, mode.EDITABLE_SURVEY);

            this.setState({initComponent: false, ddo: ddo, displayMode: tag.PORTAL_PREVIEW_QUESTIONNAIRE_GRAPH});
        }
        else if ((props.templateType !== tag.PORTAL_PREVIEW_QUESTIONNAIRE_GRAPH) && (props.templateObj !== null)) {

            // Build a complete questionnaire template from category, group or question child template
            clonedTemplateObj = JSON.parse(JSON.stringify(props.templateObj)); // Make a clean copy to avoid mutations

            // Create a questionnaire template graph from the template provided, parent and children templates will be fabracated
            let questionnaireTemplateGraph = ddoUtil.buildQuestionnaireTemplateForPreview(clonedTemplateObj, props.templateType);

            // Simulate getting a questionnaire template from the server.  Set the created questionnaire into the redux store
            props.setQuestionnaireTemplate(questionnaireTemplateGraph);
            props.setTemplateObj(props.templateObj);

            // Create the ddo (Display Definition Object) JSON object for the portal renderer to use to display survey
            ddo = ddoUtil.createJsonObjFromQuestionnaire(questionnaireTemplateGraph, mode.TEMPLATE_MODE, mode.SHOW_FIRST_QUESTION, mode.EDITABLE_SURVEY);

            this.setState({displayMode: props.templateType, ddo: ddo});
        }
    }

    adjustQuestionnaireTemplateGraph = (questionnaireTemplateGraph) => {
        // NOTE: I'm NOT sure why these adjustments are made.
        let tempQnr = questionnaireTemplateGraph;

        for (let c = 0; c < tempQnr.questionCategoryTemplates.length; c++) {

            let category = tempQnr.questionCategoryTemplates[c];

            for (let g = 0; g < category.groupTemplates.length; g++) {

                let group = category.groupTemplates[g];

                if (group.questionGroupType ===  "LIKERT") {

                    for (let i = 0; i < group.questionTemplates.length; i++) {

                        let question = group.questionTemplates[i];

                        question.answerOptionTemplates = group.answerOptionTemplates;  // Apply answers to LIKERT question

                        for (let a = 0; a < question.answerOptionTemplates.length; a++) {
                            question.answerOptionTemplates[a].selected = false; // add selected property to object
                            question.answerOptionTemplates[a].key = question.answerOptionTemplates[a].templateKey; // add selected property to object
                        }
                    }
                }
                else {
                    for (let q = 0; q < group.questionTemplates.length; q++) {

                        let question = group.questionTemplates[q];

                        if (question.answerOptionTemplates.length > 0) {
                            for (let a = 0; a < question.answerOptionTemplates.length; a++) {
                                question.answerOptionTemplates[a].selected = false; // add selected property to object
                                question.answerOptionTemplates[a].key = question.answerOptionTemplates[a].templateKey; // add selected property to object
                            }
                        }
                        else {
                            question.answerText = "";
                        }
                    }
                }
            }
        }

        return tempQnr;
    }

    startSurvey = () => {

        let isInstance = (this.props.questionnaireInstance !== null);
        let qnr =  isInstance ? this.props.questionnaireInstance : this.props.questionnaireTemplate;

        if (isInstance && !qnr.started) {
            this.props.startQuestionnaire(qnr.id);
        }
        this.props.nextPage(this.props.currPageIndex);
    };


    cancelSurvey = () => {
        this.setState({canceled: true});
    };


    finishSurvey = (e) => {

        let isInstance = (this.props.questionnaireInstance !== null);
        let qnr =  isInstance ? this.props.questionnaireInstance : this.props.questionnaireTemplate;

        // TODO ZZZZZ ShiftKey is temporary, REMOVE before releasing product ZZZZZ
        if (!e.shiftKey) {
            if (isInstance) {
                this.props.completeQuestionnaire(qnr.id);
            }
        }

        // Check the last category defined, IF it is a new-page "close" then after submit advance to close page
        let categories = isInstance ? qnr.questionCategories : qnr.questionCategoryTemplates;
        let i = categories.length-1;

        if (!tag.metadataExists(qnr, tag.NO_CLOSE_PAGE)) {
            this.props.nextPage(this.props.currPageIndex);
        }
        else if (tag.metadataExists(categories[i], tag.NEW_PAGE)) {
            if (tag.metadataValue(categories[i], tag.NEW_PAGE) === 'close') {
                this.props.nextPage(this.props.currPageIndex);
            }
        }
        else {
            this.setState({clearPage: true});
        }
    };


    nextPage = () => {
        this.props.nextPage(this.props.currPageIndex);
    };


    prevPage = () => {
        this.props.prevPage(this.props.currPageIndex);
    };


    closeSurvey = () => {
        //debugger;
    };

    setCustomTheme = (color) => {

        if(this.state.useCustomTheme === false) {
            this.setState({useCustomTheme: true});

            this.props.setTheme(createCustomTheme(color));
        }
    };

    toggleDarkModeSwitch = (name, customColor) => event => {
        this.setState(
            {
                [name]: event.target.checked,
                darkMode: !this.state.darkMode
            }
        );

        if(this.state.darkMode === false) {
            this.props.setTheme(createCustomTheme("", true));
        } else {
            this.props.setTheme(createCustomTheme(customColor, false));
        }
    };

    onlyCloseExpirationModal = () => {
        this.setState({showTokenExpirationModal: false});
    };

    closeExpirationModalAndGetNewToken = () => {
        this.props.getNewTokenId(this.state.refKey);

        this.setState({
            showTokenExpirationModal: false,
            tokenValidationExpired: false
        });

        setTimeout(() => {
            this.setState({showTokenExpirationModal: true});
        }, this.state.parsedJwtExp); // 3540000
    };

    disableTokenExpirationModalOkButton = () => {
        this.setState({tokenValidationExpired: true});
    };

    render() {
        //const {classes} = this.props;

        if (this.state.initComponent) {
            this.initComponent();
        }

        console.log('QQQQQQ-C:\\src\\com.argodata.cdt.qd2.portal\\portal\\src\\qd-common\\components\\questionnaires\\active\\PortalContainer.js');

        let currURL =  new URL(window.location.href);
        let calledBy = this.props.calledBy;
        console.log('QQQQQQ-C:\\src\\com.argodata.cdt.qd2.portal\\portal\\src\\qd-common\\components\\questionnaires\\active\\PortalContainer.js - calledBy = ' +  this.props.calledBy);

        const MainComponent = isRunningInInternetExplorer() ? `div` : `main`;

        let currPageDefObj = null;
        let totalNumberOfPages = 0;

        let customColor = "";

        let uploadedImageStyles = {
            verticalAlign: 'middle',
            height: '60px'
        };

        // Check to make sure the ddo (Display Definition Object) has been added to the local state
        if (this.state.ddo !== null && this.props.currPageIndex !== undefined) {
            currPageDefObj = this.state.ddo[ddo.OBJ.KEY.DDO.PAGE_ARRAY][this.props.currPageIndex];
            totalNumberOfPages = this.state.ddo[ddo.OBJ.KEY.DDO.PAGE_ARRAY].length;
        }

        let isInstance = (this.props.questionnaireInstance !== null);
        let qnr =  isInstance ? this.props.questionnaireInstance : this.props.questionnaireTemplate;

        let showAppBar = tag.metadataExists(qnr , tag.APPBAR);
        let showImageLeft = false;
        let stringLeft = "";
        let variantLeft = "h2";
        let showImageCenter = false;
        let stringCenter = "";
        let variantCenter = "h2";
        let showImageRight = false;
        let stringRight = "";
        let variantRight = "h2";

        let leftImage   = "";
        let centerImage = "";
        let rightImage  = "";

        let stringLeftObj = null;
        let stringCenterObj = null;
        let stringRightObj = null;

        let CustomTypographyLeft = null;
        let CustomTypographyCenter = null;
        let CustomTypographyRight = null;

        if (showAppBar) {

            // Check if 1 or more images are to be used in the app bar header/footer
            showImageLeft   = tag.metadataExists(qnr, tag.APPBAR_IMAGE_LEFT);
            showImageCenter = tag.metadataExists(qnr, tag.APPBAR_IMAGE_CENTER);
            showImageRight  = tag.metadataExists(qnr, tag.APPBAR_IMAGE_RIGHT);

            leftImage   = tag.metadataValue(qnr, tag.APPBAR_IMAGE_LEFT);
            centerImage = tag.metadataValue(qnr, tag.APPBAR_IMAGE_CENTER);
            rightImage  = tag.metadataValue(qnr, tag.APPBAR_IMAGE_RIGHT);

            stringLeft   = tag.metadataValue(qnr, tag.APPBAR_TITLE_LEFT);
            stringCenter = tag.metadataValue(qnr, tag.APPBAR_TITLE_CENTER);
            stringRight  = tag.metadataValue(qnr, tag.APPBAR_TITLE_RIGHT);

            let parsedArray = [];

            if (typeof stringLeft === "string") {

                parsedArray = stringLeft.split("|");

                stringLeft = (parsedArray.length > 0) ? parsedArray[0] : "";
                variantLeft = (parsedArray.length > 1) ? parsedArray[1] : variantLeft;

            } else {
                stringLeftObj = stringLeft;
                stringLeft = stringLeftObj.title;

                CustomTypographyLeft = styled(Typography)({
                    fontFamily: stringLeftObj.fontFamily,
                    fontWeight: stringLeftObj.fontWeight,
                    color: stringLeftObj.colorType === "default" ? "white" : stringLeftObj.color,
                    fontSize: stringLeftObj.fontSize,  // 2em, 18pt
                    fontStyle: stringLeftObj.fontStyle,
                    textAlign: 'left',  // left center right
                    opacity: stringLeftObj.opacity,
                    //backgroundColor: customObj.fontFamily,
                    //paddingTop: customObj.fontFamily,
                    //boxShadow: customObj.fontFamily,
                    //paddingBottom: customObj.fontFamily
                });
            }

            if (typeof stringCenter === "string") {

                parsedArray = stringCenter.split("|");

                stringCenter = (parsedArray.length > 0) ? parsedArray[0] : "";
                variantCenter = (parsedArray.length > 1) ? parsedArray[1] : variantCenter;

            } else {
                stringCenterObj = stringCenter;
                stringCenter = stringCenterObj.title;

                CustomTypographyCenter = styled(Typography)({
                    fontFamily: stringCenterObj.fontFamily,
                    fontWeight: stringCenterObj.fontWeight,
                    color: stringCenterObj.colorType === "default" ? "white" : stringCenterObj.color,
                    fontSize: stringCenterObj.fontSize,  // 2em, 18pt
                    fontStyle: stringCenterObj.fontStyle,
                    textAlign: 'center',  // left center right
                    opacity: stringCenterObj.opacity,
                    //backgroundColor: customObj.fontFamily,
                    //paddingTop: customObj.fontFamily,
                    //boxShadow: customObj.fontFamily,
                    //paddingBottom: customObj.fontFamily
                });
            }

            if (typeof stringRight === "string") {

                parsedArray = stringRight.split("|");

                stringRight = (parsedArray.length > 0) ? parsedArray[0] : "";
                variantRight = (parsedArray.length > 1) ? parsedArray[1] : variantRight;

            } else {
                stringRightObj = stringRight;
                stringRight = stringRightObj.title;

                CustomTypographyRight = styled(Typography)({
                    fontFamily: stringRightObj.fontFamily,
                    fontWeight: stringRightObj.fontWeight,
                    color: stringRightObj.colorType === "default" ? "white" : stringRightObj.color,
                    fontSize: stringRightObj.fontSize,  // 2em, 18pt
                    fontStyle: stringRightObj.fontStyle,
                    textAlign: 'right',  // left center right
                    opacity: stringRightObj.opacity,
                    //backgroundColor: customObj.fontFamily,
                    //paddingTop: customObj.fontFamily,
                    //boxShadow: customObj.fontFamily,
                    //paddingBotton: customObj.fontFamily
                });

            }

        }

        if (!tag.metadataExists(qnr, tag.HAS_START_PAGE)) {
            if (qnr !== null) {
                if ((isInstance) && ((qnr.started !== undefined) && !qnr.started)) {
                    this.props.startQuestionnaire(qnr.id);
                }
            }
        }

        if (tag.metadataExists(qnr, tag.USE_SECONDARY_THEME)) {
            customColor = tag.metadataValue(qnr, tag.USE_SECONDARY_THEME);

            this.setCustomTheme(customColor)
        }

        let mainPadding = "20px 0px 20px 0px";
        if (!this.props.portalPreview && tag.metadataExists(qnr, tag.APPBAR))  {
            // Portal Preview will NEVER have an AppBar
            mainPadding = "80px 0px 20px 0px"; // If there is an AppBar then adjust padding to push survey below AppBar
        }

        // let appBarColor = PRIMARY_BLUE;
        // if (false) {
        //     appBarColor = ""; // AppBar Color override
        // }

        return (
            (this.props.questionnaireInstance || this.props.questionnaireTemplate) &&

            <div style={{height: '100%', width: '100%', display: 'flex', flexDirection: 'column'}}>
                {((!this.props.portalPreview) && (tag.metadataExists(qnr, tag.APPBAR)))  ?
                    mode.displayOneQuestionPerPage() ?
                        <ThemeProvider theme={theme}>
                        <AppBar position="fixed" component="div" color="primary" elevation={0}>
                            <Toolbar>
                                <div style={{display: "inline-flex", width: "100%"}}>
                                    {/* desktop -- show all uploaded images */}
                                    <Hidden xsDown>
                                        <div style={{width: '45%', alignSelf: 'center'}}>
                                            {showImageLeft ?
                                                <img src={`data:image/png;base64,${leftImage}`} alt="" style={uploadedImageStyles}/>
                                                :
                                                <Typography variant={"h3"} color="inherit">
                                                    {stringLeft}
                                                </Typography>
                                            }
                                        </div>
                                        <div style={{width: '40%', textAlign: "center", alignSelf: 'center'}}>
                                            {showImageCenter ?
                                                <img src={`data:image/png;base64,${centerImage}`} alt="" style={uploadedImageStyles}/>
                                                :
                                                <Typography variant={"h5"} color="inherit">
                                                    {stringCenter}
                                                </Typography>
                                            }
                                        </div>
                                        <div style={{width: '55%', textAlign: "right", alignSelf: 'center'}}>
                                            {showImageRight ?
                                                <img src={`data:image;base64,${rightImage}`} alt="" style={uploadedImageStyles}/>
                                                :

                                                <Typography variant={"caption"} color="inherit">
                                                    {stringRight}
                                                </Typography>
                                            }
                                        </div>
                                    </Hidden>
                                    {/* mobile -- only show the left uploaded image in appbar to avoid overlap*/}
                                    <Hidden smUp>
                                        <div style={{width: '45%', alignSelf: 'center'}}>
                                            {showImageLeft ?
                                                <img src={`data:image/png;base64,${leftImage}`} alt="" style={uploadedImageStyles}/>
                                                :
                                                <Typography variant={"h3"} color="inherit">
                                                    {stringLeft}
                                                </Typography>
                                            }
                                        </div>
                                        <div style={{width: '40%', textAlign: "center", alignSelf: 'center'}}>
                                            <Typography variant={"h5"} color="inherit">
                                                {stringCenter}
                                            </Typography>
                                        </div>
                                        <div style={{width: '55%', textAlign: "right", alignSelf: 'center'}}>
                                            <Typography variant={"caption"} color="inherit">
                                                {stringRight}
                                            </Typography>
                                        </div>
                                    </Hidden>
                                </div>
                            </Toolbar>
                        </AppBar>
                        </ThemeProvider>
                        :
                        <ThemeProvider theme={theme}>
                        <AppBar position="fixed" component="div" color="primary" elevation={0}>
                            <Toolbar>
                                {/* desktop */}
                                <Hidden xsDown>
                                    <div style={{display: "inline-flex", width: "100%"}}>
                                        <div style={{width: '30%', alignSelf: 'center'}}>
                                            {showImageLeft ?
                                                <img src={`data:image/png;base64,${leftImage}`} alt="" style={uploadedImageStyles}/>
                                                :
                                                (stringLeftObj !== null) ?
                                                    <CustomTypographyLeft>{stringLeft}</CustomTypographyLeft>
                                                    :
                                                    <Typography variant={variantLeft} color="inherit">
                                                        {stringLeft}
                                                    </Typography>
                                            }
                                        </div>
                                        <div style={{width: '40%', textAlign: "center", alignSelf: 'center'}}>
                                            {showImageCenter ?
                                                <img src={`data:image/png;base64,${centerImage}`} alt="" style={uploadedImageStyles}/>
                                                :
                                                (stringCenterObj !== null) ?
                                                    <CustomTypographyCenter>{stringCenter}</CustomTypographyCenter>
                                                    :
                                                    <Typography variant={variantCenter} color="inherit">
                                                        {stringCenter}
                                                    </Typography>
                                            }
                                        </div>
                                        <div style={{width: '30%', textAlign: "right", alignSelf: 'center'}}>
                                            {showImageRight ?
                                                <img src={`data:image;base64,${rightImage}`} alt="" style={uploadedImageStyles}/>
                                                :
                                                (stringRightObj !== null) ?
                                                    <CustomTypographyRight>{stringRight}</CustomTypographyRight>
                                                    :
                                                    <Typography variant={variantRight} color="inherit">
                                                        {stringRight}
                                                    </Typography>

                                            }
                                        </div>
                                    </div>
                                </Hidden>
                                {/* mobile */}
                                <Hidden smUp>
                                    <div style={{display: "inline-flex", width: "100%"}}>
                                        <div style={{width: '30%', alignSelf: 'center'}}>
                                            {showImageLeft ?
                                                <img src={`data:image/png;base64,${leftImage}`} alt="" style={uploadedImageStyles}/>
                                                :
                                                (stringLeftObj !== null) ?
                                                    <CustomTypographyLeft>{stringLeft}</CustomTypographyLeft>
                                                    :
                                                    <Typography variant={variantLeft} color="inherit">
                                                        {stringLeft}
                                                    </Typography>
                                            }
                                        </div>
                                        <div style={{width: '40%', textAlign: "center", alignSelf: 'center'}}>
                                            {(stringCenterObj !== null) ?
                                                <CustomTypographyCenter>{stringCenter}</CustomTypographyCenter>
                                                :
                                                <Typography variant={variantCenter} color="inherit">
                                                    {stringCenter}
                                                </Typography>
                                            }
                                        </div>
                                        <div style={{width: '30%', textAlign: "right", alignSelf: 'center'}}>
                                            {(stringRightObj !== null) ?
                                                <CustomTypographyRight>{stringRight}</CustomTypographyRight>
                                                :
                                                <Typography variant={variantRight} color="inherit">
                                                    {stringRight}
                                                </Typography>
                                            }
                                        </div>
                                    </div>
                                </Hidden>
                                {/*<div>*/}
                                {/*    <Switch*/}
                                {/*        checked={this.state.switchOn}*/}
                                {/*        onChange={this.toggleDarkModeSwitch('switchOn', customColor)}*/}
                                {/*        name="checkedA"*/}
                                {/*        inputProps={{ 'aria-label': 'secondary checkbox' }}*/}
                                {/*    />*/}
                                {/*</div>*/}
                            </Toolbar>
                        </AppBar>
                        </ThemeProvider>
                    :
                    ""
                }

                <MainComponent style={{backgroundColor: '#FAFAFA', display: 'flex', overflowY: 'auto', overflowX: 'hidden', alignItems: 'center', padding: mainPadding}}>
                    {this.state.clearPage ? <div></div> :
                        <Content>

                            {(currPageDefObj !== null) && (!this.state.canceled) ?
                                <PortalPageRenderer pageDefObj={currPageDefObj}
                                                    pageDefObjCurrIndex={this.props.currPageIndex}
                                                    totalPages={totalNumberOfPages}
                                                    questionnaireInstance={this.props.questionnaireInstance}
                                                    questionnaireTemplate={this.props.questionnaireTemplate}
                                                    style={{width: '1000px'}}
                                                    start={this.startSurvey}
                                                    cancel={this.cancelSurvey.bind(this)}
                                                    finish={this.finishSurvey.bind(this)}
                                                    next={this.nextPage}
                                                    previous={this.prevPage}
                                                    close={this.closeSurvey}
                                                    answerQuestionOptions={this.props.answerQuestionOptions}
                                                    answerQuestionTemplateOptions={this.props.answerQuestionTemplateOptions}
                                                    answerQuestionText={this.props.answerQuestionText}
                                                    answerQuestionTemplateText={this.props.answerQuestionTemplateText}
                                                    displayMode={this.state.displayMode}
                                                    surveyEditMode={this.state.surveyEditMode}
                                                    templateType={this.props.templateType}
                                                    portalCount={this.props.portalCount}
                                />
                                :
                                <ErrorPage hide={false} canceled={this.state.canceled} qnr={qnr}/>
                            }

                        </Content>
                    }
                </MainComponent>
                {
                    this.state.showTokenExpirationModal ?
                        <div style={{position: 'absolute'}}>
                            <Modal
                                aria-labelledby="simple-modal-title"
                                aria-describedby="simple-modal-description"
                                open={this.state.showTokenExpirationModal}
                                onClose={this.onlyCloseExpirationModal}
                                style={{display:'flex',alignItems:'center',justifyContent:'center'}}
                            >
                                <div style={{backgroundColor: 'white', width: '500px', height: '200px', padding: '10px'}}>
                                    {
                                        this.state.tokenValidationExpired ?
                                            <Typography>Session Expired</Typography> :
                                            <div>
                                                <Typography variant="h6" id="modal-title"
                                                            style={{color: "red", marginBottom: '10px'}}>
                                                    Warning: Your sessions is about to expire
                                                </Typography>
                                                <Typography variant="subtitle1" id="simple-modal-description"
                                                            style={{marginBottom: '20px'}}>
                                                    Click "OK" to continue your session, otherwise your session will
                                                    expire in:
                                                </Typography>
                                                <Countdown date={Date.now() + 60000}
                                                           onComplete={this.disableTokenExpirationModalOkButton}/>
                                                <div style={{display: 'flex', justifyContent: 'center'}}>
                                                    <Button disabled={this.state.tokenValidationExpired} style={{border: 'solid', borderColor: 'black', borderWidth: '2px', marginTop: '30px'}}
                                                            onClick={this.closeExpirationModalAndGetNewToken}
                                                    >
                                                        OK
                                                    </Button>
                                                </div>
                                            </div>
                                    }
                                </div>
                            </Modal>
                        </div>
                        : null
                }
            </div>
        );
    }
}


PortalContainer.defaultProps = {
    portalPreview: false,
    templateType: "",
    templateId: null,
    templateObj: null,
    questionnaireInstanceId: null,
    questionnaireInstance: null,
    questionnaireTemplateId: null,
    questionnaireTemplate: null,
    calledBy: "PortalPreview",
};


PortalContainer.propTypes = {
    portalPreview: PropTypes.bool,
    templateType: PropTypes.string,
    templateId: PropTypes.number,
    templateObj: PropTypes.object,
    calledBy: PropTypes.string,
    portalCount: PropTypes.number,
};


function mapStateToProps(state, props) {
    return {
        theme: state.portalTheme,
        refKey: state.portal.refKey,
        metadata: state.portal.metadata,
        questionnaireInstanceId: state.portal.questionnaireInstanceId,
        questionnaireInstance: state.portal.questionnaireInstance,
        questionnaireTemplateId: state.portal.questionnaireTemplateId,
        questionnaireTemplate: state.portal.questionnaireTemplate,
        currPageIndex: state.portal.currPageIndex,
        templateObjFromStore: state.portal.templateObj,
        portal: state.portal
    };
}


function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        dispatch,
        startQuestionnaire,
        completeQuestionnaire,
        getQuestionnaire,
        getQuestionnaireInstance,
        getNewTokenId,
        getQuestionnaireReadonlyInstance,
        setQuestionnaireTemplate,
        setTemplateObj,
        setQuestionnaireInstanceNull,
        currentPage,
        nextPage,
        prevPage,
        setTheme,
        clearPortalPreviewMode
    }, dispatch);
}


// const style = (theme) => {
//     return {
//         ...LayoutStyles(theme),
//         ...CadencePageStyles(theme)
//     };
// };

export default connect(mapStateToProps, mapDispatchToProps)(PortalContainer);
