import * as React from "react";

function SvgUnselectedPositive(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 24 24" {...props}>
      <defs>
        <rect
          id="unselected_positive_svg__a"
          x={0.3}
          y={0.3}
          width={21.6}
          height={21.6}
          rx={10.8}
        />
        <path
          d="M3 0v1.799l1.8.001V3L3 2.999V4.8H1.8L1.799 3H0V1.8l1.799-.001L1.8 0H3z"
          id="unselected_positive_svg__c"
        />
      </defs>
      <g fill="none" fillRule="evenodd">
        <g transform="translate(.9 .9)">
          <mask id="unselected_positive_svg__b" fill="#fff">
            <use xlinkHref="#unselected_positive_svg__a" />
          </mask>
          <use fill="#FFF" xlinkHref="#unselected_positive_svg__a" />
          <g
            mask="url(#unselected_positive_svg__b)"
            fill="#000"
            fillOpacity={1}
          >
            <path d="M-.9-.9h24v24h-24z" />
          </g>
          <rect
            fill="#FFF"
            fillRule="nonzero"
            mask="url(#unselected_positive_svg__b)"
            x={1.5}
            y={1.5}
            width={19.44}
            height={19.44}
            rx={9.72}
          />
        </g>
        <g transform="translate(9.6 9.6)">
          <mask id="unselected_positive_svg__d" fill="#fff">
            <use xlinkHref="#unselected_positive_svg__c" />
          </mask>
          <use fill="#F4F4F4" xlinkHref="#unselected_positive_svg__c" />
          <g
            mask="url(#unselected_positive_svg__d)"
            fill="#000"
            fillOpacity={1}
          >
            <path d="M-1.2-1.2H6V6h-7.2z" />
          </g>
        </g>
      </g>
    </svg>
  );
}

export default SvgUnselectedPositive;
