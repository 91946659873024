import React, {Component} from 'react';
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import Typography from "@mui/material/Typography";
import CategoryViewer from "../categories/CategoryViewer";
import QuestionViewer from "../questions/QuestionViewer";
import GroupViewer from "../groups/GroupViewer";
import {getQuestionnaireTemplate} from "../../../actions/questionnaireTemplateActions";
import CategoryViewerExpandable from "../categories/CategoryViewerExpandable";
import * as portal from "../../../utilities/portal";
import * as metadataUtils from "../../../utilities/metadata";
import statusFormatter from "../../../utilities/StatusFormatter";
import ArgoDividerWithLabelHook from "../../common/ArgoDividerWithLabelHook";
import ArgoToolbarHook from "../../common/ArgoToolbarHook";
import * as metadata from "../../../utilities/metadata";


class QuestionnaireViewer extends Component {
    constructor(props) {
        super(props);

        this.state = {
            openCategoryViewer: false,
            selectedCategoryInfoKey: "",
            openGroupViewer: false,
            selectedGroupInfoKey: "",
            openQuestionViewer: false,
            selectedQuestionInfoKey: "",
            categoriesLoaded: false,
            categoryTemplates: null,
            showCategoryBackButton: true
        };
    }

    componentDidMount() {
        if (this.props.templateKey || this.props.templateId) {
            this.props.getQuestionnaireTemplate(this.props.templateKey, this.props.templateId);
        }
    }

    handleToolbarBackButtonClick = (event, fieldName, buttonTitle) => {
        if (buttonTitle === 'BACK') this.setState({openCategoryViewer: false, openGroupViewer: false, openQuestionViewer: false})
    }

    setStateCallback = (stateObj) => {
        this.setState(stateObj);
    }

    openQuestionViewer = () => {

        return (
            <div style={{height: "100%"}}>

                <ArgoToolbarHook
                    fileName="GroupViewer"
                    fieldName="back"
                    buttonTitles={["BACK"]}
                    onClick={this.handleToolbarButtonClick}
                />

                <div className="layout vertical" style={{height: "100%", overflowY: "scroll"}}>
                    <QuestionViewer templateKey={this.state.selectedQuestionInfoKey}/>
                </div>
            </div>
        );
    };

    render() {
        let template = this.props.template;

        // This creates the URL ie.. http://serv816/qd2portal/AdminModeUseTemplateQnr56  which portal will do a graph call to load into iFrame
        // NO postMessage is required
        //let portalPath = portal.getPortalPath(portal.QUESTIONNAIRE_URL_SUFFIX, this.props.template?.id);

        if (this.state.openCategoryViewer) {
            return (
                <div style={{height: "100%"}}>
                    {this.state.showCategoryBackButton ?
                        <ArgoToolbarHook
                            fileName="QuestionnaireViewer"
                            fieldName="category-info-back"
                            buttonTitles={["BACK"]}
                            onClick={this.handleToolbarBackButtonClick}
                        />
                        : ''
                    }

                    <div style={{height: "100%", width: "100%", overflowY: "scroll"}}>
                        <CategoryViewer templateKey={this.state.selectedCategoryInfoKey} showToolbar={false} setStateCallBack={this.setStateCallback}/>;
                    </div>
                </div>
            );
        }

        if (this.state.openGroupViewer) {
            return (
                <div style={{height: "100%"}}>
                    {this.props.showToolbar ?
                        <ArgoToolbarHook
                            fileName="GroupViewer"
                            fieldName="back"
                            buttonTitles={["BACK"]}
                            onClick={this.handleToolbarButtonClick}
                        />
                        : ""
                    }

                    <div style={{height: "100%", width: "100%", overflowY: "scroll"}}>
                        <GroupViewer templateKey={this.state.selectedGroupInfoKey} showToolbar={false}/>;
                    </div>
                </div>
            );
        }

        if (this.state.openQuestionViewer) {
            return this.openQuestionViewer();
        }

        return (
            template ?
                <div id="QuestionnaireViewer-container-div" style={{flexGrow: "2", padding: "32px"}}>
                    <div style={{minHeight: "min-content"}}>

                        <Typography variant="h6" component="div">
                            Questionnaire Information:
                        </Typography>

                        <div>&nbsp;</div>

                        <div id="QuestionnaireViewer-complex-question-type-container-div" style={{width: "100%", display: "flex", justifyContent: "flex-start", alignItems: "center", overflowWrap: "break-word"}}>
                            <div style={{color: "darkgray"}}>Status:</div>
                            <div>&nbsp;</div>
                            <div>{statusFormatter(this.props.template, 0, 0, true)}</div>
                        </div>

                        <div>&nbsp;</div>

                        <div id="QuestionnaireViewer-complex-question-name-container-div" style={{width: "100%", display: "flex", justifyContent: "flex-start", alignItems: "center", overflowWrap: "break-word"}}>
                            <div style={{color: "darkgray"}}>Questionnaire:</div>
                            <div>&nbsp;</div>
                            <div>{template.name}</div>
                        </div>

                        <div>&nbsp;</div>

                        <div id="QuestionnaireViewer-complex-question-qualifier-container-div" style={{width: "100%", display: "flex", justifyContent: "flex-start", alignItems: "center", overflowWrap: "break-word"}}>
                            <div style={{color: "darkgray"}}>Qualifier:</div>
                            <div>&nbsp;</div>
                            <div>{template.nameQualifier}</div>
                        </div>

                        <div>&nbsp;</div>

                        <div style={{display: 'flex'}}>
                            <div style={{color: "darkgray"}}>Key Metric Category:</div>
                            <div>&nbsp;</div>
                            <div style={{paddingBottom: "12px"}}>{!template.metadata.hasOwnProperty(metadata.REPORTING_CATEGORY_QUESTIONNAIRE) ? 'None' : template.metadata[metadata.REPORTING_CATEGORY_QUESTIONNAIRE]}</div>
                        </div>

                        <div>&nbsp;</div>

                        <div>&nbsp;</div>

                        <div>
                            {metadataUtils.listMetadata(this.props.template, "Questionnaire")}
                        </div>
                    </div>

                    {/*// ===========================================================================================================*/}
                    {/*// ASSIGNED CATEGORIES LIST */}
                    {/*// ===========================================================================================================*/}

                    <CategoryViewerExpandable
                        questionnaireTemplate={template}
                        categoryKeys={template.categoryKeys}
                        showIconButtonReorder={false}
                        showIconButtonEdit={false}
                        showIconButtonRemove={false}
                        hidePortalPreview={true}
                        setStateCallback={this.setStateCallback}
                    />

                    <div id="QuestionnaireViewer-space-1" style={{height: "20px"}}>&nbsp;</div>

                    {/*// ===========================================================================================================*/}
                    {/*// PORTAL PREVIEW DIVIDER */}
                    {/*// ===========================================================================================================*/}

                    <ArgoDividerWithLabelHook
                        fileName="QuestionnaireViewer"
                        label="Portal Preview"
                    />

                    {/*// ===========================================================================================================*/}
                    {/*// QUESTIONNAIRE PORTAL PREVIEW */}
                    {/*// ===========================================================================================================*/}

                    <div id="QuestionnaireViewer-space-2" style={{height: "10px"}}>&nbsp;</div>

                    <div style={{height: "900px"}}>
                        {/*{portal.createPortaliFrame("QuestionnaireViewer", portal.QUESTIONNAIRE_URL_SUFFIX, portalPath, "",  function () {}, false)}*/}
                    </div>

                </div>
                : ""
        );
    }
}

QuestionnaireViewer.propTypes = {
    templateKey: PropTypes.string,
    templateId: PropTypes.number
};

function mapStateToProps(state, props) {
    return {template: state.questionnaireTemplate.hasOwnProperty(props.templateKey) ?  state.questionnaireTemplate[props.templateKey] : null};
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({getQuestionnaireTemplate}, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(QuestionnaireViewer);
