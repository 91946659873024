import React, {Component} from 'react';
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import {closeDrawer} from "../../actions/drawerActions";
import IntegrationLogDetails from "../qd/integrations/IntegrationLogDetails";
import ArgoAppBar from "../common/ArgoAppBar";


class IntegrationLogDetailsDrawer extends Component {

    closeDrawer = () => {
        this.props.closeDrawer(); // TODO FOr some reason this drawer requires 2 closes to work?
    };

     render() {

        return (
            <div>
                <ArgoAppBar title="Integration Log Details" showMenuArrowButton="LEFT" onLeftIconButtonTouchTap={this.closeDrawer} isDrawer={true}/>

                <div>
                    <IntegrationLogDetails id={this.props.id}/>
                </div>
            </div>
        );
    }
}

IntegrationLogDetailsDrawer.propTypes = {
    id: PropTypes.number
};

function mapDispatchToProps(dispatch) {
    return bindActionCreators({closeDrawer}, dispatch);
}

const integrationLogDetailsDrawerProps = {
    opensecondary: true,
    width: "70%",
    docked: false,
    anchor: "right",
    setLoadingState: PropTypes.func,
};

export default connect(null, mapDispatchToProps)(IntegrationLogDetailsDrawer);
export {integrationLogDetailsDrawerProps};
