import React, {Component} from 'react';
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import {closeDrawer} from "../../actions/drawerActions";
import {createCategoryTemplate} from "../../actions/categoryTemplateActions";
import CategorySearchAdd from "../qd/categories/CategorySearchAdd";
import ArgoAppBar from "../common/ArgoAppBar";


class QuestionnaireAddCategoryDrawer extends Component {

    render() {
        return (
            <div style={{height: "100%"}}>
                <ArgoAppBar title="Add/Remove category to questionnaire" showMenuArrowButton="LEFT" onLeftIconButtonTouchTap={this.props.closeDrawer} isDrawer={true}/>

                <CategorySearchAdd cancel={this.close}/>
            </div>
        );
    }
}

QuestionnaireAddCategoryDrawer.propTypes = {
    close: PropTypes.func,
    createCategoryTemplate: PropTypes.func
};

QuestionnaireAddCategoryDrawer.defaultProps = {
    close: () => {}
};

function mapDispatchToProps(dispatch) {
    return bindActionCreators({close: closeDrawer, createCategoryTemplate}, dispatch);
}

const addCategoryProps = {
    opensecondary: true,
    width: "90%",
    docked: false,
    anchor: "right"
};
export default connect(null, mapDispatchToProps)(QuestionnaireAddCategoryDrawer);
export {addCategoryProps};
