import React, { useState, useEffect } from 'react';
import Chip from "@mui/material/Chip";
import FlashOnIcon from "@mui/icons-material/FlashOn";
import ClearIcon from '@mui/icons-material/Clear';


export default function ArgoTextFieldHook(props) {

    const fileName = props.fileName === undefined ? "file-name" : props.fileName;
    const fieldName = props.fieldName === undefined ? "field-name" : props.fieldName;
    const index = props.index === undefined ? "" : props.index; // If field is built in a loop index is required to know which field to match up with

    const uniqueIdentifier = fileName + "-chip-" + fieldName + index;

    const variant = props.variant === undefined ? "filled" : props.variant; //NOTE: use "outlined" for chip with white fill
    const label = props.label === undefined ? "" : props.label;
    const useTrackableAvatar = props.useTrackableAvatar === undefined ? false : props.useTrackableAvatar;
    const title = props.title === undefined ? "" : props.title;

    const onDelete = props.onDelete === undefined ? function () {} : props.onDelete;

    const handelOnDelete = (event) => {
        onDelete(event, label, index);
    };

    return (
        <div title={title} style={{paddingRight: "10px"}}>
            <Chip   key={uniqueIdentifier}
                    id={uniqueIdentifier}
                    name={uniqueIdentifier}
                    variant={variant}
                    label={label}
                    onDelete={handelOnDelete}
                    avatar={useTrackableAvatar ? <FlashOnIcon/> : ""}
            />
        </div>
    );
}



