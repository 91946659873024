import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import {getActionTemplate} from "../../../actions/actionTemplateActions";
import {getGroupTemplate} from "../../../actions/groupTemplateActions";
import * as metadataTemplateTypes from "../../../constants/metadataTemplateTypes";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ArgoTemplateListDividerHook from "../../common/ArgoTemplateListDividerHook";
import * as expander from "../../../utilities/expander";
import Typography from "@mui/material/Typography";
import * as TEMPLATE from "../../../constants/templateConstants";
import ArgoExpandCollapseButtonHook from "../../common/ArgoExpandCollapseButtonHook";
import * as metadataUtils from "../../../utilities/metadata";
import * as screenChangeSize from "../../../utilities/screenChangeSize";


class AnswerViewer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            expander: {},
            expandAll: true,
            expanderPreset: false,
        };
    }

    componentDidMount() {
        const answerKeys = Object.values(this.props.answerOptionTemplates).map(template => template.templateKey)
        if (!this.state.expanderPreset) {
            this.setState({expanderPreset: true, expander: expander.presetExpander(answerKeys, this.props.answerOptionTemplates, this.state.expander)});
        }
    }

    listActions = (answerOption) => {

        const actionTypes = ["Goal", "Intervention"];
        // self = this;
        let selectedActions = {other: []};

        actionTypes.forEach((type) => {
            selectedActions[type] = [];
        });

        answerOption.importActionKeys.forEach((templateKey) => {
            if (!this.props.actionTemplate[templateKey]) {
                return <div>{templateKey} not found</div>;
            }

            let type = (this.props.actionTemplate[templateKey].type) ? this.props.actionTemplate[templateKey].type : "other";
            if (selectedActions[type]) {
                selectedActions[type].push(this.props.actionTemplate[templateKey]);
            }
            else {
                return <div>{type} action type is missing</div>;
            }
        });

        return (
            <div>
                <div>Actions:</div>
                {Object.keys(selectedActions).map((type) => {
                        if (selectedActions[type].length > 0) {
                            return (
                                <div key={type + this.props.index} style={{width: "100%"}}>
                                    <div key={type + "action_label_" + this.props.index} style={{paddingLeft: "15px", textTransform: "capitalize"}}>{type + ":"}</div>
                                    {selectedActions[type].map((action) => {
                                        return (
                                            <div key={action.templateKey} style={{paddingLeft: "30px", color: "rgb(158,158,158)"}}>
                                                {action.name}
                                            </div>
                                        );
                                    })}
                                </div>
                            );
                        }
                        return null;
                    }
                )}
                <div>&nbsp;</div>
            </div>
        );
    };


    listGroups = (answerOption) => {
        let html = <div><div>Show Group:</div><div>&nbsp;</div></div>;
        let key = answerOption.importGroupKeys[0];  // Note we only allow 1 show group at this time

        if (key !== undefined) {
            if (this.props.groupTemplate[key]) {
                let group = this.props.groupTemplate[key];
                html = <div id={"AnswerViewer-answer-" + answerOption.id + "showgroup-container-div"}>
                    <div>Show Group:</div>
                    <div key={key} style={{paddingLeft: "15px", color: "rgb(158,158,158)"}}>
                        {group.name + (group.nameQualifier === null ? "" : " Qualifier: " + group.nameQualifier)}
                    </div>
                    <div>&nbsp;</div>
                </div>;
            } else {
                this.props.getGroupTemplate(key);  // Group not loaded, so get group from key
            }
        }

        return html;
    };


    setStateCallback = (stateObj) => {
        this.setState(stateObj);
    }


    render() {

        let answerOptionTemplates = this.props.answerOptionTemplates;

        return (
            Object.keys(this.state.expander).length > 0 ?
                <div style={{flexGrow: "2", paddingBottom: "32px"}}>

                    <div style={{minHeight: "min-content"}}>

                        {/*// ===========================================================================================================*/}
                        {/*// ANSWERS LIST DIVIDER */}
                        {/*// ===========================================================================================================*/}

                        <ArgoTemplateListDividerHook
                            fileName="AnswerViewer"
                            parentsLabel={this.props.parentsLabel}
                            childLabel={["answer","answers"]}
                            arrayLength={answerOptionTemplates.length}
                        />

                        {/*// ===========================================================================================================*/}
                        {/*// ANSWERS EXPAND/COLLAPSE ALL BUTTON */}
                        {/*// ===========================================================================================================*/}

                        <div id="AnswerViewer-Answer-ExpandCollapseButton-container-div1" style={{display: "flex", flexDirection: "row", alignItems: "center", width: "100%"}}>

                            <div id="AnswerViewer-Answer-ExpandCollapseButton-container-div2"
                                 style={{display: "flex", marginLeft: "auto"}}>
                                <ArgoExpandCollapseButtonHook
                                    fileName="AnswerViewer"
                                    expandAll={this.state.expandAll}
                                    onClick={expander.expandCollapseAll.bind(this, TEMPLATE.TYPE.ANSWER, this.state.expander, this.state.expandAll, Object.keys(this.state.expander), false, this.setStateCallback)}
                                />
                            </div>

                        </div>

                        <div>&nbsp;</div>

                        {/*// ===========================================================================================================*/}
                        {/*// ANSWERS LIST: Note: Question Types RADIO and CHECKBOX, and LIKERT Groups */}
                        {/*// ===========================================================================================================*/}

                        <div id={"AnswerViewer-answers-container-div-" + answerOptionTemplates.id}>
                            {answerOptionTemplates.map((answerOption, index) => (
                                    <div key={"AnswerViewer-answer-container-div-" + answerOption.id} id={"AnswerViewer-answer-container-div-" + answerOption.id} style={{width: "100%"}}>

                                        <Accordion id={"AnswerViewer" + "-Accordion-" + index}
                                                   variant="outlined"
                                                   disableGutters={true}
                                                   defaultExpanded={true}
                                                   expanded={this.state.expander[answerOption.templateKey]?.open ? this.state.expander[answerOption.templateKey]?.open : false}
                                                   onChange={expander.expandCollapseSelected.bind(this, this.state.expander, answerOption.templateKey, this.setStateCallback)}
                                        >
                                            <AccordionSummary
                                                id={"AnswerViewer-AccordionSummary-" + index}
                                                expandIcon={<ExpandMoreIcon style={{color: expander.ICON_COLOR}}/>}
                                                sx={{height: "30px", backgroundColor: expander.ACCORDION_BG_COLOR, width: "100%", '&:hover': {bgcolor: expander.ACCORDION_BG_COLOR_HOVER}}}
                                            >
                                                <div style={{display: "flex", flexDirection: "row", alignItems: "center", width: "100%"}}>
                                                    <Typography variant="subtitle1" component="div" sx={{color: expander.TEXT_COLOR_SECONDARY, minWidth: 94}}>
                                                        {"Answer Text:"}
                                                    </Typography>

                                                    <Typography variant="h6" component="div"
                                                                sx={{paddingLeft: "20px",
                                                                    color: expander.TEXT_COLOR_PRIMARY,
                                                                    whiteSpace: 'nowrap',
                                                                    wordWrap: 'break-word',
                                                                    overflow: "hidden",
                                                                    textOverflow: 'ellipsis',
                                                                    minWidth: 50,
                                                                    width: screenChangeSize.setWidth.call(this, 1020, [], this.setStateCallback)
                                                                }}>
                                                        {answerOption.optionText}
                                                    </Typography>

                                                    <Typography variant="subtitle1" component="div" sx={{paddingLeft: "20px", color: expander.TEXT_COLOR_SECONDARY, paddingTop: "3px", minWidth: 60}}>
                                                        {"Actions:"}
                                                    </Typography>

                                                    <Typography variant="subtitle1" component="div" sx={{paddingLeft: "5px", color: expander.ICON_COLOR, paddingTop: "3px"}}>
                                                        {"(" + answerOption.importActionKeys.length + ")"}
                                                    </Typography>

                                                    <Typography variant="subtitle1" component="div" sx={{paddingLeft: "20px", color: expander.TEXT_COLOR_SECONDARY, paddingTop: "3px", minWidth: 93}}>
                                                        {"Show Group:"}
                                                    </Typography>

                                                    <Typography variant="subtitle1" component="div" sx={{paddingLeft: "5px", color: expander.ICON_COLOR, paddingTop: "3px"}}>
                                                        {"(" + (answerOption.importGroupKeys.length ? "Yes": "No") + ")"}
                                                    </Typography>

                                                    <Typography variant="subtitle1" component="div" sx={{paddingLeft: "20px", color: expander.TEXT_COLOR_SECONDARY, paddingTop: "3px", minWidth: 74}}>
                                                        {"Metadata:"}
                                                    </Typography>

                                                    <Typography variant="subtitle1" component="div" sx={{paddingLeft: "5px", color: expander.ICON_COLOR, paddingTop: "3px"}}>
                                                        {"(" + metadataUtils.countNumberOfVisibleMetadataEntries(answerOption.metadata) + ")"}
                                                    </Typography>


                                                </div>
                                            </AccordionSummary>
                                            <AccordionDetails>
                                                {this.listActions(answerOption)}
                                                {this.listGroups(answerOption)}
                                                {metadataUtils.listMetadata(answerOption, "", "rgba(0, 0, 0, 0.87)")}
                                            </AccordionDetails>
                                        </Accordion>

                                        {this.state.expander[answerOption.templateKey]?.open ? <div id={"spacer-div-" + index}>&nbsp;</div> : ""}

                                    </div>
                                )
                            )}
                        </div>

                        {this.state.expanded ? <div id={"spacer-div-" + this.props.index}>&nbsp;</div> : ""}

                    </div>
                </div>
                : ""
        );
    }
}

AnswerViewer.defaultProps = {
    parentsLabel: "Question"
};

AnswerViewer.propTypes = {
    answerOptionTemplates: PropTypes.array,
    parentsLabel: PropTypes.string
};

function mapStateToProps(state, props) {
    return {
        actionTemplate: state.actionTemplate,
        groupTemplate: state.groupTemplate,
        questionTemplate: state.questionTemplate
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({getActionTemplate, getGroupTemplate}, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(AnswerViewer);
