import React, {Component} from 'react';
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import PropTypes from 'prop-types';
import * as questionTemplateActions from "../../../actions/questionTemplateActions";
import QuestionEditor from "./QuestionEditor";


class QuestionContainer extends Component {

    constructor(props) {
        super(props);

        this.state = {
            templateKey: ""
        };
    }

    componentDidMount() {
        let templateKey = "";
        if (!this.props.templateKey) {
            templateKey = this.props.match.params.templateKey;
        } else {
            templateKey = this.props.templateKey;
        }
        this.setState({templateKey: templateKey});
        // this.props.dispatch(questionTemplateActions.getQuestionTemplate(templateKey));
        this.props.questionTemplateActions.getQuestionTemplate(templateKey);
    }

    getHtml() {
        let html;

        if (this.props.template !== undefined) {
            html = (
                <div>
                    <Card>
                        <CardHeader
                            title={"Question: " + (this.props.template[this.state.templateKey] ? this.props.template[this.state.templateKey].name : "")}

                            subtitle={"Key: "+this.state.templateKey}
                            actAsExpander
                        >
                            {/*<QuestionViewerExpandable template={this.props.template[this.state.templateKey]} />*/}
                        </CardHeader>
                        <CardContent expandable>
                            <QuestionEditor
                                template={this.props.template[this.state.templateKey]}
                                templateKey={this.state.templateKey}
                                questionDepth={0}
                            />
                        </CardContent>
                    </Card>
                </div>);
        } else {
            html = <div />;
        }
        return html;
    }

    render() {

        return (
            <div>
                {
                    this.getHtml()
                }
            </div>
        );
    }

}

QuestionContainer.defaultProps = {
    match: {},
    questionTemplateActions: {},
    templateKey: "",
    template: undefined
};

QuestionContainer.propTypes = {
    templateKey: PropTypes.string,
    template: PropTypes.object,
    match : PropTypes.object,
    questionTemplateActions: PropTypes.object
};

function mapDispatchToProps(dispatch) {
    return {
        questionTemplateActions: bindActionCreators(questionTemplateActions, dispatch)
    };
}

function mapStateToProps(state) {
    return {
        template: state.questionTemplate
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(QuestionContainer);
