import {combineReducers} from "redux";
import actionTemplate from "./actionTemplateReducer";
import questionTemplate from "./questionTemplateReducer";
import groupTemplate from "./groupTemplateReducer";
import categoryTemplate from "./categoryTemplateReducer";
import questionnaireTemplate from "./questionnaireTemplateReducer";
import questionnaireInit from "./questionnaireInitReducer";
import questionnaireTemplateGraph from "./questionnaireTemplateGraphReducer";
import questionnaireReference from "./questionnaireReferenceReducer";
import questionnaireRequiredTokens from "./questionnaireRequiredTokensReducer";
import notification from "./notificationReducer";
import integrationEditor from "./integrationEditorReducer";
import integrationList from "./integrationListReducer";
import integrationLogsList from "./integrationLogsListReducer";
import integrationLog from "./integrationLogReducer";
import integration from "./integrationReducer";
import actionTemplateList from "./actionTemplateListReducer";
import questionTemplateList from "./questionTemplateListReducer";
import groupTemplateList from "./groupTemplateListReducer";
import categoryTemplateList from "./categoryTemplateListReducer";
import questionnaireTemplateList from "./questionnaireTemplateListReducer";
import uploadedFiles from "./uploadedFilesReducer";
import openCloseSecondaryDrawer from "./openCloseSecondaryDrawerReducer";
import templateVersions from "./templateVersionsReducer";
import questionEditor from "./questionEditorReducer";
import actionTypeList from "./questionnaireActionTypesReducer";
import groupEditor from "./groupEditorReducer";
import settings from "./systemSettingReducer";
import categoryEditor from "./categoryEditorReducer";
import actionEditor from "./actionEditorReducer";
import questionnaireEditor from "./questionnaireEditorReducer";
import references from "./referenceReducer";
import notes from "./noteReducer";
import statistics from "./statisticsReducer";
import statisticsAll from "./statisticsAllReducer";
import filter from "./filterReducer";
import login from "./loginReducer";
import tag from "./tagReducer";
import tagList from "./tagListReducer";
import tagEditor from "./tagEditorReducer";
import metadataDefinition from "./metadataDefinitionReducer";
import outstandingQuestionnaireCount from "./outstandingQuestionnaireTemplateReducer";
import showGroup from './showGroupReducer';
import portalNotification from "../portal/reducers/portalNotificationReducer";
import portalTheme from "../portal/reducers/portalThemeReducer";
import portal from "../portal/reducers/portalReducer";
import portalResponse from "../portal/reducers/portalResponseReducer";
import errorResponse from "../portal/reducers/portalResponseReducer";


const rootReducer = combineReducers({
    actionTemplate,
    questionTemplate,
    questionnaireInit,
    groupTemplate,
    categoryTemplate,
    questionnaireTemplate,
    questionnaireTemplateGraph,
    questionnaireReference,
    questionnaireRequiredTokens,
    notification,
    integrationEditor,
    integrationList,
    integrationLogsList,
    integrationLog,
    integration,
    actionTemplateList,
    actionTypeList,
    questionTemplateList,
    groupTemplateList,
    categoryTemplateList,
    questionnaireTemplateList,
    uploadedFiles,
    openCloseSecondaryDrawer,
    templateVersions,
    questionEditor,
    groupEditor,
    settings,
    categoryEditor,
    actionEditor,
    questionnaireEditor,
    references,
    notes,
    statistics,
    statisticsAll,
    filter,
    login,
    tag,
    tagList,
    tagEditor,
    metadataDefinition,
    outstandingQuestionnaireCount,
    showGroup,
    portalNotification,
    portalTheme,
    portal,
    portalResponse,
    errorResponse
});

export default rootReducer;
