import React from "react";
import style from "../../blueTheme";
import PropTypes from "prop-types";
import IconButton from "@mui/material/IconButton";
import ArrowCircleDownIcon from '@mui/icons-material/ArrowCircleDown';
import ArrowCircleUpIcon from '@mui/icons-material/ArrowCircleUp';


const styles = {
    expandIconStyle: {
        width: "16px",
        height: "16px",
        borderRadius: "50%",
        color: style.palette.primary2Color,
        borderColor: style.palette.primary2Color,
        borderWidth: "2px",
        borderStyle: "solid"
    },
    iconButtonStyle: {
        marginTop: "12px"
    }
};

const ArgoExpandIcon = (props) => {

    let uniqueIdentifier = props.fileName + "-argo-expand-icon-" + props.fieldName;

    function onClick(fieldName, event) {
        props.onClick(event, fieldName);
    }

    return <IconButton
            id={uniqueIdentifier}
            name={uniqueIdentifier}
            onClick={onClick.bind(this, props.fieldName)}
            style={styles.iconButtonStyle} iconStyle={styles.expandIconStyle}>
            {props.isExpanded ?
                <ArrowCircleDownIcon />
                :
                <ArrowCircleUpIcon />
            }
        </IconButton>;
};

ArgoExpandIcon.propTypes = {
    fileName: PropTypes.string,
    fieldName: PropTypes.string,
    isExpanded: PropTypes.bool,
    onClick: PropTypes.func,
};


export default ArgoExpandIcon;
