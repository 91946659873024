import React, { useState, useEffect } from 'react';
import TextField from "@mui/material/TextField";
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';


export default function ArgoAutocompleteHook(props) {
    const [error, setError] = useState(false);
    const [value, setValue] = useState(props.value || "");

    const fileName = props.fileName === undefined ? "NoFileName" : props.fileName;
    const fieldName = props.fieldName === undefined ? "NoFieldName" : props.fieldName;

    const index = props.index === undefined ? "" : props.index; // Index is needed of this input is created in a loop

    const uniqueIdentifier = fileName + "-ArgoAutocompleteHook-" + fieldName + index;

    const variant = props.variant === undefined ? "standard" : props.variant;

    const label = props.label === undefined ? "Label" : props.label;

    const maxLength = props.maxLength === undefined ? 255 : props.maxLength;

    const width = props.width === undefined ? 0 : props.width;
    const fullWidth = props.fullWidth === undefined ? ((width !== 0) ? false : true) : false;

    const paddingTop = props.paddingTop === undefined ? "0px" : props.paddingTop;
    const paddingBottom = props.paddingBottom === undefined ? "0px" : props.paddingBottom;

    const autoFocus = props.autoFocus === undefined;

    const onChange = props.onChange === undefined ? function () {} : props.onChange;

    const display = props.display === undefined ? "inline" : "none";
    const multiline = props.multiline === undefined ? false : true;
    const style = props.style === undefined ? {} : props.style;

    const options = props.dataSource === undefined ? [] : props.dataSource
    const openOnFocus = props.openOnFocus !== undefined
    const errorText = props.errorText === undefined ? " " : props.errorText;

    const menuItems = props.menuItems === undefined ? [] : props.menuItems;
    const menuItemsIsStrings = menuItems.length > 0 ? (typeof menuItems[0] === "string" ? true : false) : false; // if not array of strings, must be objects {value: "", text: ""}

    const valueProperty = props.valueProperty === undefined ? "value" : props.valueProperty;
    const textProperty = props.textProperty === undefined ? "text" : props.textProperty;

    const validationType = ""; // This is required by metadata editor



    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);

    const handleClick = (event) => {

        setAnchorEl(event.currentTarget);
    };

    const handleClose = (selectedValue) => {

        setAnchorEl(null);

        if (typeof selectedValue == "string"){

            triggerInput(uniqueIdentifier, (openOnFocus ? selectedValue : value + selectedValue + '}'))
        }
    };

    const triggerInput = (enteredName, enteredValue) => {

        const input = document.getElementById(enteredName);

        const lastValue = input.value;

        input.value = enteredValue;

        const event = new Event("input", { bubbles: true });

        const tracker = input._valueTracker;

        if (tracker) {
            tracker.setValue(lastValue);
        }

        input.dispatchEvent(event);
    }

    const handleKeyDown = (event) => {
        if (event.key == '{' && !openOnFocus){
            setAnchorEl(event.currentTarget);

            triggerInput(uniqueIdentifier,value+'{')
        }
    }

    useEffect(() => {
        setError((errorText !== " "));
    }, [value, errorText, error])

    return (
        <div id={uniqueIdentifier + "-container-div"} style={{paddingTop: paddingTop, width: width}}>
            <TextField
                id={uniqueIdentifier}
                name={uniqueIdentifier}
                variant={variant}
                label={label}
                value={props.value}
                multiline={multiline}
                onKeyDown={handleKeyDown}
                onClick = {(event)=>{if (openOnFocus )setAnchorEl(event.currentTarget)}}
                onChange={event => {setValue(event.target.value); onChange(event, fieldName, index, validationType)}}
                inputProps={{autoComplete: "off", maxLength: maxLength}}
                fullWidth
                error={error}
                helperText={errorText}
            />
                <Menu
                    id="demo-positioned-menu"
                    aria-labelledby="demo-positioned-button"
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}

                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                    }}
                >
                    {menuItems.map((item, index) => {
                        return (<MenuItem
                            key={uniqueIdentifier + index}
                            value={menuItemsIsStrings ? index : item[valueProperty]}
                            onClick={()=>handleClose(menuItemsIsStrings ? item : item[textProperty])}
                        >
                            {menuItemsIsStrings ? item : item[textProperty]}
                        </MenuItem>);
                    })}
                </Menu>
        </div>
    );
}
