import React, {Component} from 'react';
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import ArgoToolbarHook from "../../common/ArgoToolbarHook";
import ArgoTextFieldHook from "../../common/ArgoTextFieldHook";
import ArgoTextFieldSelectHook from "../../common/ArgoTextFieldSelectHook";
import ArgoIconButtonToggleViewFieldTextHook from "../../common/ArgoIconButtonToggleViewFieldTextHook";
import * as validate from "../../../utilities/validate";
import {
    initializeEditorCreate,
    initializeEditorEdit,
    addIntegration,
    updateIntegrationStore,
    clearEditor,
    getIntegration,
    checkIntegrationNameForDup
} from "../../../actions/integrationEditorActions";
import IntegrationReviewViewer from "./IntegrationReviewViewer";
import {IntegrationTypes} from "../../../constants/integrationConstants";


const NAME_ERROR_TEXT = "nameErrorText";
const URL_ERROR_TEXT = "urlErrorText";
const APIKEY1_ERROR_TEXT = "apiKey1ErrorText";
const APIKEY2_ERROR_TEXT = "apiKey2ErrorText";


class IntegrationEditor extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            toolbarSaveButtonDisabled: false,
            startName: "",
            viewApiKey1: false,
            viewApiKey2: false,
            errorText: {}
        };
    }

    componentWillMount() {
        if (this.props.id) {
            this.setState({isLoading: true});
            this.props.getIntegration(this.props.id);
        }
        else {
            this.props.initializeEditorCreate();
        }

        this.checkForErrorsOnPage(this.props);
    }

    componentWillReceiveProps(nextProps) {
        if (this.state.isLoading && nextProps.integration) {
            this.setState({isLoading: false, startName: nextProps.integration.name});
            nextProps.initializeEditorEdit(nextProps.integration);
        }

        this.checkForErrorsOnPage(nextProps);
    }

    componentWillUnmount() {
        this.props.clearEditor();
    }

    handleToolbarButtonClick = (event, fieldName, buttonTitle) => {
        switch (buttonTitle) {
            case "SAVE":
                this.props.save(this.props.integration);
                break;
            case "CANCEL":
                this.props.cancel();
                break;
            // no default
        }
    }

    onTextChange = (event, fieldName) => {

        let value = event.target.value;

        let updatedIntegration = {...this.props.integration}; // copy integration template

        // Because of issues with the ApiKeys being treated as passwords, I modified the way change is stored to keep prev entries and not the ****
        if ((fieldName === "apiKey1") || (fieldName === "apiKey2"))
        {
            let newPayload = "";
            if (value !== "") {
                for (let i = 0; i < value.length; i++) {
                    if (value.substr(i, 1) === "*") {
                        newPayload = newPayload + updatedIntegration[fieldName].substr(i, 1);
                    }
                    else {
                        newPayload = newPayload + value.substr(i, 1);
                        updatedIntegration[fieldName] = updatedIntegration[fieldName].substr(0,i) + " " + updatedIntegration[fieldName].substr(i);
                    }
                }
            }

            updatedIntegration[fieldName] = newPayload.replace(/\s/g, '');
            //updatedIntegration[fieldName] = value.length < updatedIntegration[fieldName].length ? value : updatedIntegration[fieldName] + value.substr(updatedIntegration[fieldName].length);
        }
        else
        {
            updatedIntegration[fieldName] = value;
        }
        this.props.updateIntegrationStore(updatedIntegration);
        if (fieldName === "name")
        {
            // Disable the save button when the header changes. It will be re-enabled once the validation is complete.
            this.setState({toolbarSaveButtonDisabled: false});

            this.props.checkIntegrationNameForDup(updatedIntegration.name);
        }

    };

    checkForErrorsOnPage = (props) => {

        let errorsOnPage = false;

        const name = props.integration.name || "";
        const url = props.integration.integrationUrl || "";
        const apiKey1 = props.integration.apiKey1 || "";
        const apiKey2 = props.integration.apiKey2 || "";

        const isValidating = props.options.isValidating;

        let errorText = {}; // Create an object for errors if they exist

        let nameExists = false;

        props.options.validate.forEach(integration => {
            if (integration.name.toUpperCase() === validate.removeExtraWhitespace(name).toUpperCase()) {
                nameExists = true;
            }

        });

        if ((nameExists) && (name !== this.state.startName)) {
            errorText[NAME_ERROR_TEXT] = "This integration name is already in use.";
        }
        else {
            errorText[NAME_ERROR_TEXT] = (name.length === 0) ? "Name is required." : " ";
        }

        errorText[URL_ERROR_TEXT] = (url.length === 0) ? "URL is required." : " ";

        errorText[APIKEY1_ERROR_TEXT] = (apiKey1.length === 0) ? "Api Key 1 is required." : " ";

        errorText[APIKEY2_ERROR_TEXT] = (apiKey2.length === 0) ? "Api Key 2 is required." : " ";

        for (const key in errorText) {
            if (errorText[key] !== " ") {
                errorsOnPage = true;
                break;
            }
        }

        this.setState({errorText: errorText, toolbarSaveButtonDisabled: errorsOnPage});
    };




    toggleViewApiKey = (fieldName, value) => {
        this.setState({[fieldName]: value});
    };

    // For future use when Test Connection Button is ready
    // testConnection = (id, DTO) => {
    //     // Test Connection
    // };

    render() {
        if (this.state.isLoading) {
            //TODO place a loading screen here
            return null;
        }

        if (this.state.openIntegrationReviewViewer) {
            return <IntegrationReviewViewer integration={this.props.integration} handleToolbarButtonClick={this.handleToolbarButtonClick}/>
        }

        return (
            <div style={{height: "100%"}}>

                <ArgoToolbarHook
                    fileName="IntegrationEditor"
                    fieldName="save-or-cancel"
                    leftButtonDisabled={this.state.toolbarSaveButtonDisabled}
                    toolbarErrorText={this.state.toolbarErrorText}
                    onClick={this.handleToolbarButtonClick}
                />

                <div style={{height: "100%", overflowY: "scroll"}}>
                    <div style={{justifyContent: "space-between"}}>
                        <div style={{flexGrow: "2", paddingLeft: "32px", paddingRight: "32px"}}>

                            <ArgoTextFieldHook
                                fileName="IntegrationEditor"
                                fieldName="name"
                                label="Name"
                                value={this.props.integration.name}
                                multiline
                                autoFocus
                                onChange={this.onTextChange}
                                errorText={this.state.errorText[NAME_ERROR_TEXT]}
                            />

                            <ArgoTextFieldSelectHook
                                fileName="IntegrationEditor"
                                fieldName="integrationType"
                                label="Type"
                                value={this.props.integration.integrationType}
                                menuItems={IntegrationTypes}
                                onChange={this.onTextChange}
                            />

                            <ArgoTextFieldHook
                                fileName="IntegrationEditor"
                                fieldName="integrationUrl"
                                label="URL"
                                value={this.props.integration.integrationUrl}
                                multiline
                                autoFocus
                                onChange={this.onTextChange}
                                errorText={this.state.errorText[URL_ERROR_TEXT]}
                            />

                            <div style={{width: "100%", display: "flex"}}>

                                <ArgoTextFieldHook
                                    fileName="IntegrationEditor"
                                    fieldName="apiKey1"
                                    label="API Key 1"
                                    value={this.state.viewApiKey1 ? this.props.integration.apiKey1 : "*".repeat(this.props.integration.apiKey1.length)}
                                    multiline
                                    autoFocus
                                    onChange={this.onTextChange}
                                    errorText={this.state.errorText[APIKEY1_ERROR_TEXT]}
                                />

                                <ArgoIconButtonToggleViewFieldTextHook
                                    fileName="IntegrationEditor"
                                    fieldName="viewApiKey1"
                                    onClick={this.toggleViewApiKey}
                                />

                            </div>

                            <div style={{width: "100%", display: "flex"}}>

                                <ArgoTextFieldHook
                                    fileName="IntegrationEditor"
                                    fieldName="apiKey2"
                                    label="API Key 2"
                                    value={this.state.viewApiKey2 ? this.props.integration.apiKey2 : "*".repeat(this.props.integration.apiKey2.length)}
                                    multiline
                                    autoFocus
                                    onChange={this.onTextChange}
                                    errorText={this.state.errorText[APIKEY2_ERROR_TEXT]}
                                />

                                <ArgoIconButtonToggleViewFieldTextHook
                                    fileName="IntegrationEditor"
                                    fieldName="viewApiKey2"
                                    onClick={this.toggleViewApiKey}
                                />

                            </div>

                            {/*<Button id="IntegrationEditor-test-connection" name="IntegrationEditor-test-connection" variant="contained" primary style={IntegrationEditorStyle.button}*/}
                                          {/*onClick={this.testConnection.bind(this, "", "")} tabIndex={1}>TEST CONNECTION</Button>*/}

                        </div>

                    </div>
                </div>
            </div>
        );
    }
}

IntegrationEditor.propTypes = {
    id: PropTypes.number,
    integration: PropTypes.object,
    options: PropTypes.object,
    initializeEditorCreate: PropTypes.func,
    initializeEditorEdit: PropTypes.func,
    updateIntegrationStore: PropTypes.func,
    clearEditor: PropTypes.func,
    getIntegration: PropTypes.func,
    save: PropTypes.func,
    cancel: PropTypes.func
};

function mapStateToProps(state) {
    return {
        integration: state.integrationEditor.integration,
        options: state.integrationEditor.options
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        initializeEditorCreate,
        initializeEditorEdit,
        addIntegration,
        updateIntegrationStore,
        clearEditor,
        getIntegration,
        checkIntegrationNameForDup
    }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(IntegrationEditor);
